/*
   _____                      _       _____        _
  |  __ \                    | |     |  __ \      | |
  | |__) |___ _ __ ___   ___ | |_ ___| |  | | __ _| |_ __ _
  |  _  // _ \ '_ ` _ \ / _ \| __/ _ \ |  | |/ _` | __/ _` |
  | | \ \  __/ | | | | | (_) | ||  __/ |__| | (_| | || (_| |
  |_|  \_\___|_| |_| |_|\___/ \__\___|_____/ \__,_|\__\__,_|
*/

import { show } from "sanctuary";
import $ from "sanctuary-def";
import Z from "sanctuary-type-classes";
import type from "sanctuary-type-identifiers";

var RemoteData = {};

// Constructors

// Success :: a -> RemoteData a
const Success$prototype = {
  constructor: RemoteData,
  isSuccess: true,
  isFailure: false,
  isNotAsked: false,
  isLoading: false,
  "@@show": Success$prototype$show,
  "fantasy-land/map": Success$prototype$map,
  "fantasy-land/bimap": Success$prototype$bimap,
  "fantasy-land/ap": Success$prototype$ap,
  "fantasy-land/chain": Success$prototype$chain,
  "fantasy-land/extend": Success$prototype$extend,
  "fantasy-land/reduce": Success$prototype$reduce,
  "fantasy-land/traverse": Success$prototype$traverse
};

export const Success = value => {
  const success = Object.create(Success$prototype);
  success.value = value;
  if (Z.Setoid.test(value)) {
    success["fantasy-land/equals"] = Success$prototype$equals;
  }
  return success;
};
RemoteData.Success = Success;

// Failure :: e -> RemoteData e
const Failure$prototype = {
  constructor: RemoteData,
  isSuccess: false,
  isFailure: true,
  isNotAsked: false,
  isLoading: false,
  "@@show": Failure$prototype$show,
  "fantasy-land/map": Failure$prototype$map,
  "fantasy-land/bimap": Failure$prototype$bimap,
  "fantasy-land/ap": Failure$prototype$ap,
  "fantasy-land/chain": Failure$prototype$chain,
  "fantasy-land/extend": Failure$prototype$extend,
  "fantasy-land/reduce": Failure$prototype$reduce,
  "fantasy-land/traverse": Failure$prototype$traverse
};

export const Failure = value => {
  const failure = Object.create(Failure$prototype);
  failure.value = value;
  if (Z.Setoid.test(value)) {
    failure["fantasy-land/equals"] = Failure$prototype$equals;
  }
  return failure;
};
RemoteData.Failure = Failure;

// NotAsked :: RemoteData
const NotAsked$prototype = {
  constructor: RemoteData,
  isSuccess: false,
  isFailure: false,
  isNotAsked: true,
  isLoading: false,
  "@@show": NotAsked$prototype$show,
  "fantasy-land/map": NotAsked$prototype$map,
  "fantasy-land/bimap": NotAsked$prototype$bimap,
  "fantasy-land/equals": NotAsked$prototype$equals,
  "fantasy-land/ap": NotAsked$prototype$ap,
  "fantasy-land/chain": NotAsked$prototype$chain,
  "fantasy-land/extend": NotAsked$prototype$extend,
  "fantasy-land/reduce": NotAsked$prototype$reduce,
  "fantasy-land/traverse": NotAsked$prototype$traverse
};
export const NotAsked = Object.create(NotAsked$prototype);
RemoteData.NotAsked = NotAsked;

// Loading :: RemoteData
const Loading$prototype = {
  constructor: RemoteData,
  isSuccess: false,
  isFailure: false,
  isNotAsked: false,
  isLoading: true,
  "@@show": Loading$prototype$show,
  "fantasy-land/map": Loading$prototype$map,
  "fantasy-land/bimap": Loading$prototype$bimap,
  "fantasy-land/equals": Loading$prototype$equals,
  "fantasy-land/ap": Loading$prototype$ap,
  "fantasy-land/chain": Loading$prototype$chain,
  "fantasy-land/extend": Loading$prototype$extend,
  "fantasy-land/reduce": Loading$prototype$reduce,
  "fantasy-land/traverse": Loading$prototype$traverse
};
export const Loading = Object.create(Loading$prototype);
RemoteData.Loading = Loading;

RemoteData["@@type"] = "citybase-sanctuary/RemoteData@1";
RemoteData["fantasy-land/of"] = Success;

// Show Impls
function Success$prototype$show() {
  return `Success (${show(this.value)})`;
}

function Failure$prototype$show() {
  return `Failure (${show(this.value)})`;
}

function NotAsked$prototype$show() {
  return "NotAsked";
}

function Loading$prototype$show() {
  return "Loading";
}

// Functor Impls
function Success$prototype$map(f) {
  return Success(f(this.value));
}

function Failure$prototype$map(f) {
  return Failure(f(this.value));
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$map(f) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$map(f) {
  return this;
}

// BiFunctor Impls
function Success$prototype$bimap(f, g) {
  return Success(g(this.value));
}

// eslint-disable-next-line no-unused-vars
function Failure$prototype$bimap(f, g) {
  return Failure(f(this.value));
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$bimap(f, g) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$bimap(f, g) {
  return this;
}

// Setoid Impls
function Success$prototype$equals(other) {
  return other.isSuccess && Z.equals(other.value, this.value);
}

// eslint-disable-next-line no-unused-vars
function Failure$prototype$equals(other) {
  return other.isFailure && Z.equals(other.value, this.value);
}

function NotAsked$prototype$equals(other) {
  return other.isNotAsked;
}

function Loading$prototype$equals(other) {
  return other.isLoading;
}

// Apply
function Success$prototype$ap(other) {
  return other.isSuccess ? Success(other.value(this.value)) : other;
}

function Failure$prototype$ap(other) {
  return other.isFailure ? other : this;
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$ap(other) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$ap(other) {
  return this;
}

// Chain
function Success$prototype$chain(f) {
  return f(this.value);
}

// eslint-disable-next-line no-unused-vars
function Failure$prototype$chain(f) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$chain(f) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$chain(f) {
  return this;
}

// Monad
function Success$prototype$extend(f) {
  return Success(f(this));
}

// eslint-disable-next-line no-unused-vars
function Failure$prototype$extend(f) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$extend(f) {
  return this;
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$extend(f) {
  return this;
}

// Foldable
function Success$prototype$reduce(f, x) {
  return f(x, this.value);
}

// eslint-disable-next-line no-unused-vars
function Failure$prototype$reduce(f, x) {
  return x;
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$reduce(f, x) {
  return x;
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$reduce(f, x) {
  return x;
}

// Traversable
function Success$prototype$traverse(typeRep, f) {
  return Z.map(Success, f(this.value));
}

// eslint-disable-next-line no-unused-vars
function Failure$prototype$traverse(typeRep, f) {
  return Z.of(typeRep, this);
}

// eslint-disable-next-line no-unused-vars
function NotAsked$prototype$traverse(typeRep, f) {
  return Z.of(typeRep, this);
}

// eslint-disable-next-line no-unused-vars
function Loading$prototype$traverse(typeRep, f) {
  return Z.of(typeRep, this);
}

//    RemoteData :: Type -> Type -> Type
export const RemoteDataType = $.BinaryType("RemoteData")("no url sorry")([])(
  x => type(x) === "citybase-sanctuary/RemoteData@1"
)(function(remoteData) {
  return remoteData.isSuccess || remoteData.isError ? [remoteData.value] : [];
})(function(remoteData) {
  return remoteData.isSuccess || remoteData.isError ? [remoteData.value] : [];
});

export default RemoteData;
