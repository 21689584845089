import {
  WHITE,
  ATHENS_GREY,
  STORM_GREY,
  MINESHAFT_GREY,
  GHOST_GREY,
  BOSTON_BLUE
} from "../../../constants/colors";

const listBackgroundColor = {
  default: `${ATHENS_GREY}`,
  disabled: `${WHITE}`
};
const listItemColor = {
  default: `${MINESHAFT_GREY}`,
  disabled: `${STORM_GREY}`
};
const listItemBackgroundColor = {
  default: `${WHITE}`,
  disabled: `${ATHENS_GREY}`
};
const listItemBorderColor = {
  default: `${GHOST_GREY}`,
  disabled: `${GHOST_GREY}`
};
const radioButtonActive = {
  default: `${BOSTON_BLUE}`,
  disabled: `${GHOST_GREY}`
};
const radioButtonInactive = {
  default: `${GHOST_GREY}`,
  disabled: `${GHOST_GREY}`
};

export const fallbackValues = {
  listBackgroundColor,
  listItemColor,
  listItemBackgroundColor,
  listItemBorderColor,
  radioButtonActive,
  radioButtonInactive
};
