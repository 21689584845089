import React from "react";

import { Center, TableCell, TableRow, Text } from "@thecb/components";

export const NoTransactionsFound = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Center intrinsic>
          <Text>No History Found</Text>
        </Center>
      </TableCell>
    </TableRow>
  );
};
