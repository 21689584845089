import React from "react";
import AccordionBlock from "./accordion-block";
import AlertBlock from "./alert-block";
import ArticleBlock from "./article-block";
import AttachedFileBlock from "./attached-file-block";
import ContentCardBlock from "./content-card-block";
import FeaturedActivitiesBlock from "./featured-activities-block";
import FeaturedContentBlock from "./featured-content-block";
import HighlightBlock from "./highlight-block";
import LocationBlock from "./location-block";
import NewsListBlock from "./news-list-block";
import QuickLinksBlock from "./quick-links-block";
import RelatedContentBlock from "./related-content-block";
import ScreendoorBlock from "./screendoor-block";
import TaglineBlock from "./tagline-block";
import TaskBlock from "./task-block";

export const blockRegistry = {
  Accordion: props => <AccordionBlock {...props} />,
  Alert: props => <AlertBlock {...props} />,
  Article: props => <ArticleBlock {...props} />,
  AttachedFile: props => <AttachedFileBlock {...props} />,
  ContentCard: props => <ContentCardBlock {...props} />,
  FeaturedActivity: props => <FeaturedActivitiesBlock {...props} />,
  FeaturedContent: props => <FeaturedContentBlock {...props} />,
  GetInTouch: props => <LocationBlock {...props} />,
  Highlight: props => <HighlightBlock {...props} />,
  NewsList: props => <NewsListBlock {...props} />,
  QuickLinksList: props => <QuickLinksBlock {...props} />,
  RelatedContent: props => <RelatedContentBlock {...props} />,
  ScreendoorForm: props => <ScreendoorBlock {...props} />,
  Tagline: props => <TaglineBlock {...props} />,
  Task: props => <TaskBlock {...props} />
};
