import React, { Fragment, forwardRef } from "react";
import { MotionWrapper } from "./Motion.styled";
import { safeChildren } from "../../../util/general";

/*
  Catch-all component for creating animations with Framer Motion library
  Wraps the framer motion "motion.div" with styled components and allows for passing
  of style props, like with all the other layout primitives.

  Compatible with other layout primitives, you can wrap non-animating primitives in a Motion
  to animate items. See the construction of the Collapsible Section and Radio Section for examples.

  Motion is intentionally more flexible than our other layout primitives - this was done to avoid
  creating motion versions of each primitive. Usually, the correct approach will be to wrap
  other layout primitives with a Motion in order to animate: 

    <Motion>
      <Cluster>
        stuff...
      </Cluster>
    </Motion>

    Motion components can be nested in order to animate both a primitive container as well as its
    children:

    <Motion>
      <Stack>
        <Motion>
          <Box>cool stuff</Box>
        </Motion>
        <Motion>
          <Box>other stuff</Box>
        </Motion>
      </Stack>
    </Motion>
  
  This should be sufficient for most scenarios, but if you need to, you have the ability to make Motion act like all of the other primitives using the props provided.
*/

export const Motion = forwardRef(
  ({ position = "relative", padding = "0", children, ...rest }, ref) => (
    <MotionWrapper position={position} padding={padding} ref={ref} {...rest}>
      {safeChildren(children, <Fragment />)}
    </MotionWrapper>
  )
);

export default Motion;
