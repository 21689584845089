import React from "react";
import { useNavigate } from "react-router";
import {
  Modal,
  ButtonWithAction,
  Box,
  Stack,
  Paragraph,
  FormattedBankAccount,
  FormattedCreditCard,
  constants
} from "@thecb/components";
import { BANK_ACCOUNT_KIND } from "../../Profile.state";

export const RemovePaymentLink = toggleModal => (
  <Box
    padding="0 0.5rem"
    border="2px solid transparent"
    extraStyles={`:not(:first-child) { border-left: 2px solid ${constants.colors.BOSTON_BLUE};}`}
  >
    <ButtonWithAction
      text="Remove"
      action={() => toggleModal(true)}
      variant="smallGhost"
      extraStyles={`min-width: 0;`}
    />
  </Box>
);

export const RemovePaymentModal = ({
  item,
  autoPayMethods,
  toggleModal,
  modalOpen,
  modalDestination,
  removePaymentMethod
}) => {
  let navigate = useNavigate();
  const { id, kind, lastFour, accountType } = item;
  const isAutoPayMethod = autoPayMethods.some(methodID => methodID === id);
  const modalExtraProps = {
    modalHeaderText: isAutoPayMethod
      ? "Payment Method in Use"
      : "Remove Payment Method",
    modalBodyText: isAutoPayMethod ? (
      "To remove this payment method, you must first turn off autopay payments scheduled with it. Go to your Account Details page to turn off autopay for these payments."
    ) : (
      <Box padding="0">
        <Stack childGap="1.5rem">
          <Paragraph variant="p">
            Are you sure you want to remove this payment method?
          </Paragraph>
          <Box
            background={constants.colors.WHITE}
            padding="1.5rem 1rem"
            boxShadow={`0 0 5px 0 ${constants.colors.GHOST_GREY}`}
            borderRadius="3px"
          >
            <Box padding="0">
              {kind === BANK_ACCOUNT_KIND ? (
                <FormattedBankAccount
                  lastFour={lastFour}
                  accountType={accountType}
                />
              ) : (
                <FormattedCreditCard lastFour={lastFour} />
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
    ),
    continueButtonText: isAutoPayMethod
      ? "Go to Account Details"
      : "Remove Method",
    useDangerButton: !isAutoPayMethod,
    continueAction: isAutoPayMethod
      ? () => {
          navigate(modalDestination);
        }
      : () => {
          removePaymentMethod(id);
          toggleModal(false);
        }
  };

  return (
    <Modal
      showModal={() => toggleModal(true)}
      hideModal={() => toggleModal(false)}
      defaultWrapper={isAutoPayMethod}
      modalOpen={modalOpen}
      {...modalExtraProps}
    >
      {RemovePaymentLink(toggleModal)}
    </Modal>
  );
};
