import {
  FONT_WEIGHT_REGULAR,
  LINK_TEXT_DECORATION
} from "../../../constants/style_constants";

const linkColor = { default: "#3176AA" };
const fontSize = { default: "1rem" };
const lineHeight = { default: "1.5rem" };
const fontWeight = {
  default: FONT_WEIGHT_REGULAR
};
const modalLinkHoverFocus = {
  default: `outline: none; text-decoration: underline;`
};

const linkTextDecoration = {
  default: LINK_TEXT_DECORATION
};

export const fallbackValues = {
  linkColor,
  fontSize,
  lineHeight,
  fontWeight,
  modalLinkHoverFocus,
  linkTextDecoration
};
