import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { fallbackValues } from "./CheckboxList.theme";
import { themeComponent } from "../../../util/themeUtils";
import { RadioButton } from "../../../deprecated";
import Text from "../text";
import { noop } from "../../../util/general";
import { Box, Cluster, Switcher } from "../layouts";

export const CheckboxItemIcon = styled.img`
  width: 2rem;
`;

const HiddenCheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -2;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  position: relative;
  z-index: 5;
  &:focus {
    outline: none;
  }
`;

const CheckboxListItem = ({
  text,
  render,
  isSelected,
  onSelect,
  index,
  name,
  imageUrl,
  image,
  imageAlt = "checkbox icon",
  disabled,
  borderColor,
  color,
  background,
  radioButtonActive,
  radioButtonInactive
}) => (
  <Box
    padding="0.875rem"
    borderRadius="4px"
    minWidth="30%"
    onClick={disabled ? noop : onSelect}
    borderColor={borderColor}
    borderSize="1px"
    color={color}
    background={background}
    extraStyles={`cursor: pointer;`}
  >
    <Cluster justify="flex-start" align="center">
      <HiddenCheckboxInput id={`checkbox-${name}-${index}`} />
      <CheckboxLabel
        name={name}
        aria-label={name}
        htmlFor={`checkbox-${name}-${index}`}
        onClick={disabled ? noop : onSelect}
        onKeyDown={disabled ? noop : onSelect}
        tabIndex={0}
      >
        <ThemeProvider
          theme={{
            accentColor: radioButtonActive,
            inactiveColor: radioButtonInactive
          }}
        >
          <RadioButton name={name} isSelected={isSelected} />
        </ThemeProvider>
      </CheckboxLabel>
      <Box padding="0rem 0.5rem 0rem 0rem" hiddenStyles={!imageUrl}>
        <Cluster justify="flex-start" align="center">
          <CheckboxItemIcon src={imageUrl} alt={imageAlt} />
        </Cluster>
      </Box>
      <Box padding="0rem 0.5rem 0rem 0rem" hiddenStyles={!image}>
        <Cluster justify="flex-start" align="center">
          {image}
        </Cluster>
      </Box>
      <Text variant="p" isSelected={isSelected}>
        {text ? text : render()}
      </Text>
    </Cluster>
  </Box>
);

const CheckboxList = ({
  items,
  selectedName,
  onSelect,
  variant,
  breakpoint = "30rem",
  themeValues
}) => (
  <Box padding="0rem" background={themeValues.listBackgroundColor}>
    <Switcher childGap="0.5rem" breakpoint={breakpoint} maxChildren={0}>
      {items.map((item, index) => (
        <CheckboxListItem
          color={themeValues.listItemColor}
          background={themeValues.listItemBackgroundColor}
          borderColor={themeValues.listItemBorderColor}
          key={`checkbox-item-${item.name}`}
          text={item.text}
          render={item.render}
          isSelected={item.name === selectedName}
          onSelect={evt => onSelect(evt, item)}
          index={index}
          imageUrl={item.imageUrl}
          image={item.image}
          name={item.name}
          disabled={variant === "disabled"}
          radioButtonActive={themeValues.radioButtonActive}
          radioButtonInactive={themeValues.radioButtonInactive}
        />
      ))}
    </Switcher>
  </Box>
);

export default themeComponent(
  CheckboxList,
  "CheckboxList",
  fallbackValues,
  "default"
);
