import React, { useContext, forwardRef } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "./Link.theme";
import { createThemeValues } from "../../../util/themeUtils";
import { StyledExternalLink } from "./ExternalLink.styled";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { safeChildren } from "../../../util/general";
import { Box } from "../layouts";

const ExternalLink = forwardRef(
  (
    {
      href = "",
      newTab = false,
      size = "1rem",
      lineHeight = "1.5rem",
      weight = FONT_WEIGHT_REGULAR,
      extraStyles = ``,
      variant = "primary",
      containerTabIndex = "-1",
      tabIndex = "0",
      dataQa,
      ariaLabel,
      children
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);
    const themeValues = createThemeValues(
      themeContext,
      fallbackValues,
      "Link",
      variant
    );
    const handleKeyDown = event => {
      // This is to address an issue on Safari where the link's tab order and Enter key were not being recognized
      if (event.key === "Enter" || event.keyCode === 13) {
        if (newTab) {
          window.open(href, "_blank");
        } else {
          window.location.href = href;
        }
      }
    };

    return (
      <Box padding="0" tabIndex={containerTabIndex} onKeyDown={handleKeyDown}>
        <StyledExternalLink
          href={href}
          target={newTab ? "_blank" : ""}
          color={themeValues.externalLinkColor}
          size={size}
          lineheight={lineHeight}
          weight={weight}
          hovercolor={themeValues.hoverColor}
          activeColor={themeValues.activeColor}
          fontFamily={themeValues.fontFamily}
          tabIndex={tabIndex}
          extrastyles={extraStyles}
          rel={newTab ? "noopener" : ""}
          data-qa={dataQa}
          aria-label={ariaLabel}
          ref={ref}
        >
          {safeChildren(children, <span />)}
        </StyledExternalLink>
      </Box>
    );
  }
);

export default ExternalLink;
