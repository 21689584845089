import React, { Fragment, useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconChevron } from "../../../deprecated/icons";
import { createThemeValues } from "../../../util/themeUtils";
import { fallbackValues } from "./Breadcrumb.theme";
import { InternalLink } from "../link";
import Cluster from "../layouts/Cluster";
import Box from "../layouts/Box";

const Breadcrumbs = ({ breadcrumbsList = [] }) => {
  const themeContext = useContext(ThemeContext);
  const themeValues = createThemeValues(
    themeContext,
    fallbackValues,
    "Breadcrumb"
  );
  return (
    <Box padding="0" as="nav" aria-label="Breadcrumb navigation">
      <Cluster justify="flex-start" align="center">
        {breadcrumbsList.map(
          ({ linkText, linkDestination, isActive }, index) => (
            <Fragment key={`breadcrumb-${linkText}`}>
              <InternalLink
                aria-current={
                  index === breadcrumbsList.length - 1 ? "location" : ""
                }
                to={linkDestination}
                active={isActive.toString()}
                color={themeValues.color}
                fontSize={themeValues.fontSize}
                lineheight={themeValues.lineHeight}
                fontWeight={themeValues.fontWeight}
                margin={themeValues.margin}
                extraStyles={`
                          text-transform: uppercase;
                          text-decoration: none;
                          ${isActive.toString() === "true" &&
                            `color: ${themeValues.activeBreadcrumbColor};
                             pointer-events: none;
                            `}
                          &:first-child {
                            margin-left: 0;
                          }
                          &:active {
                            color: ${themeValues.activeColor};
                          }`}
              >
                {linkText}
              </InternalLink>
              {index < breadcrumbsList.length - 1 ? (
                <IconChevron />
              ) : (
                <Fragment />
              )}
            </Fragment>
          )
        )}
      </Cluster>
    </Box>
  );
};

export default Breadcrumbs;
