import React from "react";

const SuccessfulIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -640.000000)"
        fill="#317D4F"
      >
        <path
          d="M80,672 C88.836556,672 96,664.836556 96,656 C96,647.163444 88.836556,640 80,640 C71.163444,640 64,647.163444 64,656 C64,664.836556 71.163444,672 80,672 Z M89.6923882,650.464466 C89.8876503,650.659728 89.8876503,650.976311 89.6923882,651.171573 L78.3859015,662.477912 C78.3835247,662.480387 78.3811174,662.482844 78.3786797,662.485281 L77.6715729,663.192388 C77.4763107,663.38765 77.1597282,663.38765 76.9644661,663.192388 L76.2573593,662.485281 L76.2502882,662.476796 L71.3076118,657.535534 C71.1123497,657.340272 71.1123497,657.023689 71.3076118,656.828427 L72.0147186,656.12132 C72.2099808,655.926058 72.5265633,655.926058 72.7218254,656.12132 L77.3180195,660.7161 L88.2781746,649.757359 C88.4734367,649.562097 88.7900192,649.562097 88.9852814,649.757359 L89.6923882,650.464466 Z"
          id="status-icon---successful"
        ></path>
      </g>
    </g>
  </svg>
);

export default SuccessfulIcon;
