import React from "react";
import { SEA_GREEN } from "../../../constants/colors";

const AutopayOnIcon = ({ color = SEA_GREEN }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        className="autopayIcon"
        fill={color}
        fillRule="nonzero"
        d="M1.898 5.75c.079 0 .141-.02.188-.059a.281.281 0 00.094-.152 3.825 3.825 0 011.394-2.144A3.838 3.838 0 016 2.563c.5 0 .98.09 1.441.27.461.179.88.44 1.254.784l-.984.985A.542.542 0 007.547 5c0 .156.055.29.164.398.11.11.242.165.398.165h3.141c.156 0 .289-.055.398-.165A.542.542 0 0011.812 5V1.86a.542.542 0 00-.164-.399.542.542 0 00-.398-.164.542.542 0 00-.398.164l-.844.844A5.699 5.699 0 006 .688c-.938 0-1.809.207-2.613.62-.805.415-1.48.981-2.028 1.7A5.726 5.726 0 00.281 5.422a.265.265 0 00.059.223c.055.07.129.105.222.105h1.336zM6 12.312c.937 0 1.809-.207 2.613-.62a5.919 5.919 0 002.028-1.7 5.726 5.726 0 001.078-2.414.265.265 0 00-.059-.223.267.267 0 00-.223-.105h-1.335c-.079 0-.141.02-.188.059a.281.281 0 00-.094.152 3.825 3.825 0 01-1.394 2.144c-.711.555-1.52.833-2.426.833-.5 0-.98-.09-1.441-.27a3.985 3.985 0 01-1.254-.785l.984-.985A.542.542 0 004.453 8a.542.542 0 00-.164-.398.542.542 0 00-.398-.164H.75a.542.542 0 00-.398.164A.542.542 0 00.187 8v3.14c0 .157.055.29.165.4.109.108.242.163.398.163.156 0 .29-.055.398-.164l.844-.844A5.699 5.699 0 006 12.312z"
      ></path>
    </g>
  </svg>
);

export default AutopayOnIcon;
