import React from "react";
import { Box, Alert, withWindowSize } from "@thecb/components";

const AlertBlock = ({ heading, text }) => {
  return (
    <Box padding="0" minWidth="100%" maxWidth="330px">
      <Alert
        heading={heading}
        text={text}
        variant="info"
        showQuitLink={false}
      />
    </Box>
  );
};

export default withWindowSize(AlertBlock);
