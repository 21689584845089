//

import actions from "../actions/index";
import { createDynamicLocationPath } from "../utils";
import { insertComponent, removeComponent } from "../parser";

import * as R from "ramda";
import { combineReducers } from "redux";

const initialRenderWorkflowState = { stage: "not-asked" };
export function renderWorkflow(state = initialRenderWorkflowState, action) {
  let result;
  switch (action.type) {
    case actions.INITIAL_WORKFLOW_RECEIVED:
      return action.payload.workflow;

    case actions.RESET_WORKFLOW_STATE:
      return initialRenderWorkflowState;

    case actions.INSERT_DYNAMIC_COMPONENT:
      result = Object.assign({}, state);
      insertComponent(action.value, action.screenIndex, action.id, result);
      return result;

    case actions.CHANGE_DYNAMIC_COMPONENT:
      result = Object.assign({}, state);
      removeComponent(action.id, action.screenIndex, result);
      return result;

    default:
      return state;
  }
}

const initialInputState = { phase: "not-asked" };

export function userInput(state = initialInputState, action) {
  let result;
  switch (action.type) {
    case "INITIAL_USER_INPUT_RECEIVED":
      return action.payload.userinput;

    case actions.RESET_WORKFLOW_STATE:
      return initialInputState;

    case actions.ADD_COMPONENT:
      result = Object.assign({}, state);
      result[action.screenIndex][action.id] = "";
      return result;

    case actions.COMPONENT_CHANGED:
      result = Object.assign({}, state);
      if (!result[action.screenIndex][action.id]) {
        result[action.screenIndex][action.id] = { value: "" };
      }
      if (action.name) {
        result[action.screenIndex][action.id].display = action.name;
      }
      result[action.screenIndex][action.id].value = action.value;
      return result;

    case actions.INSERT_DYNAMIC_COMPONENT:
      result = Object.assign({}, state);
      if (typeof action.value === "object") {
        const component = action.value;
        if (!result[action.screenIndex][component.id]) {
          result[action.screenIndex][component.id] = component;
          result[action.screenIndex][component.id].child_of = action.id;
        }
      }
      return result;

    default:
      return state;
  }
}

const initialCurrentScreenState = {
  screenIndexHistory: [],
  currentScreenIndex: 0
};
export function currentScreen(state = initialCurrentScreenState, action) {
  switch (action.type) {
    case actions.RESET_WORKFLOW_STATE:
      return initialCurrentScreenState;
    case actions.CHANGE_SCREEN:
      return {
        screenIndexHistory: action.isBack
          ? R.init(state.screenIndexHistory)
          : [...state.screenIndexHistory, state.currentScreenIndex],
        currentScreenIndex: action.screenIndex
      };
    default:
      return state;
  }
}

export function apiResponse(prevState = {}, action) {
  switch (action.type) {
    case actions.STORE_API_RESPONSE:
      const actionState = { data: action.data };
      const newState = R.mergeDeepRight(prevState, actionState);
      return newState;

    default:
      return prevState;
  }
}

const initialBindDataState = {};
export function bindData(prevState = initialBindDataState, action) {
  switch (action.type) {
    case actions.INITIAL_WORKFLOW_RECEIVED:
      return R.reduce(
        (stateAcc, { location, value }) =>
          R.assocPath(location, value, stateAcc),
        {},
        action.payload.workflow.config.initial_state
      );
    case actions.RESET_WORKFLOW_STATE:
      return initialBindDataState;
    case actions.BIND_DATA:
      const mergedLocation = createDynamicLocationPath(
        action.location,
        prevState
      );
      const prevData = { data: R.path(mergedLocation, prevState) };
      const newData = { data: action.data };
      const mergedData = R.mergeDeepRight(prevData, newData);
      return R.assocPath(mergedLocation, mergedData.data, prevState);

    case actions.CLEAR_BOUND_DATA:
      return R.dissocPath(
        action.payload.component.options.bind.location,
        prevState
      );

    case actions.CLEAR_NETWORK_ERROR:
      return {
        ...prevState,
        unexpectedErrorFlag: undefined,
        notFoundErrorFlag: undefined,
        catchAllLookupErrorFlag: undefined
      };

    case actions.DISPLAY_ITEM_WARNING:
      return {
        ...prevState,
        itemWarningStatus: action.payload.warningStatus
      };

    default:
      return prevState;
  }
}

const initialIntermediaryState = {};
export function intermediaryState(
  state = initialIntermediaryState,
  { type, payload }
) {
  switch (type) {
    case actions.RESET_WORKFLOW_STATE:
      return initialIntermediaryState;
    case actions.SAVE_PAYMENT_ITEMS:
      return { ...state, paymentItems: payload.paymentItems };
    case actions.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentId: payload.response.payment.id,
        serviceFees: payload.response.payment.serviceFees
      };
    case actions.SUBMIT_PAYMENT_SUCCESS:
      return { ...state, transactionId: payload.transactionId };
    default:
      return state;
  }
}

export function workflowMetadata(state = { status: "not-asked" }, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_WORKFLOW:
      return action.payload;
    case actions.WORKFLOW_NOT_FOUND:
      return { ...state, status: "not-found" };
    case actions.SET_WORKFLOW_PAGE_NAME:
      return { ...state, workflowPageName: action.payload.workflowPageName };
    default:
      return state;
  }
}

const reducer = combineReducers({
  workflow: renderWorkflow,
  userinput: userInput,
  currentScreen: currentScreen,
  apiResponse: apiResponse,
  boundData: bindData,
  intermediaryState,
  workflowMetadata
});

export default reducer;
