import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import { combineEpics } from "redux-observable";
import { get, compose, prop } from "partial.lenses";
import { createEmail, updateContact } from "../../graphql/Queries";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  EMAIL_SETTING,
  SUBMIT_OPERATIONS,
  SUBMIT_CHANGE,
  requestSuccess,
  requestFailure,
  addEmailAlertBarAction,
  upsertEmail
} from "../../Profile.state";
import {
  getEmailForm,
  getConstituentID,
  getCustomerManagement
} from "../../Profile.selectors";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const _formFieldRaw = fieldName =>
  compose(getEmailForm, prop(fieldName), prop("rawValue"));

const CHECKOUT_KEY = "checkout";

const getCreateEmailArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  constituentID: getConstituentID(state),
  email: get(_formFieldRaw("email"), state[key])
});

const getUpdateEmailArgs = (key, selectors, state, id) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  contactId: id,
  value: get(_formFieldRaw("email"), state[key])
});

const addEmailEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === EMAIL_SETTING && operation === SUBMIT_OPERATIONS.ADD
    ),
    RxOp.flatMap(action =>
      Rx.from(
        createEmail(
          action.payload.inWallet
            ? getCreateEmailArgs(CHECKOUT_KEY, selectors, state$.value)
            : getCreateEmailArgs(profileKey, selectors, state$.value)
        )
      ).pipe(
        RxOp.mergeMap(result =>
          Rx.of(
            addEmailAlertBarAction(clearAlerts()),
            requestSuccess(EMAIL_SETTING, SUBMIT_OPERATIONS.ADD),
            upsertEmail(result.createContact),
            !action.payload.inWallet &&
              push(
                configureEndpoint(
                  getCustomerManagement(state$.value[profileKey]),
                  "/profile/wallet"
                )
              )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addEmailAlertBarAction(clearAlerts()),
            addEmailAlertBarAction(
              addAlert({
                heading: "Unable to Add Email",
                text:
                  "We are sorry, we were unable to add your email at this time. Please try again later.",
                variant: "error"
              })
            ),
            requestFailure(EMAIL_SETTING, err)
          )
        )
      )
    )
  );

const updateEmailEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === EMAIL_SETTING && operation === SUBMIT_OPERATIONS.UPDATE
    ),
    RxOp.flatMap(({ payload: { id } }) =>
      Rx.from(
        updateContact(
          getUpdateEmailArgs(profileKey, selectors, state$.value, id)
        )
      ).pipe(
        RxOp.mergeMap(result =>
          Rx.of(
            addEmailAlertBarAction(clearAlerts()),
            requestSuccess(EMAIL_SETTING, SUBMIT_OPERATIONS.UPDATE),
            upsertEmail(result.updateContact),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[profileKey]),
                "/profile/wallet"
              )
            )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addEmailAlertBarAction(clearAlerts()),
            addEmailAlertBarAction(
              addAlert({
                heading: "Unable to update Email",
                text:
                  "We are sorry, we were unable to update your email at this time. Please try again later.",
                variant: "error"
              })
            ),
            requestFailure(EMAIL_SETTING, err)
          )
        )
      )
    )
  );

export default (key, selectors) =>
  combineEpics(addEmailEpic(key, selectors), updateEmailEpic(key, selectors));
