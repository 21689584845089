import React, { Fragment } from "react";
import Stack from "../layouts/Stack";
import Box from "../layouts/Box";
import Text from "../text";
import Paragraph from "../paragraph";
import Cluster from "../layouts/Cluster";
import ButtonWithAction from "../button-with-action";
import ButtonWithLink from "../button-with-link";
import { WHITE, CHARADE_GREY, STORM_GREY } from "../../../constants/colors";
import Popover from "../../molecules/popover";

const DisplayCard = ({
  title,
  item,
  buttonText,
  buttonAction,
  url,
  link = false,
  helpText,
  hasPopover = false,
  popoverTriggerText = "",
  popoverContent = "",
  popoverExtraStyles,
  popoverTextExtraStyles
}) => (
  <Box padding="0 0 16px">
    <Stack childGap="0rem">
      <Box padding="0 0 8px 0">
        <Cluster justify="space-between" align="center" overflow>
          <Paragraph
            variant="pL"
            color={CHARADE_GREY}
            extraStyles={`letter-spacing: 0.29px`}
          >
            {title}
          </Paragraph>
          {hasPopover && (
            <Popover
              triggerText={popoverTriggerText}
              content={popoverContent}
              popoverExtraStyles={popoverExtraStyles}
              popoverTextExtraStyles={popoverTextExtraStyles}
            />
          )}
        </Cluster>
      </Box>
      <Box padding="0">
        <Box
          padding="24px"
          borderSize="1px"
          borderRadius="4px"
          background={WHITE}
          boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
            0px 3px 8px 0px rgb(202, 206, 216)"
        >
          <Cluster justify="space-between" align="center">
            <Text color={CHARADE_GREY}>{item}</Text>
            {link ? (
              <ButtonWithLink
                text={buttonText}
                url={url}
                variant="smallGhost"
                dataQa={buttonText}
                extraStyles={`min-width: 0;`}
              />
            ) : buttonAction ? (
              <ButtonWithAction
                text={buttonText}
                action={buttonAction}
                variant="smallGhost"
                dataQa={buttonText}
                extraStyles={`min-width: 0;`}
              />
            ) : helpText ? (
              <Text color={STORM_GREY} extraStyles={`font-style: italic;`}>
                {helpText}
              </Text>
            ) : (
              <Fragment />
            )}
          </Cluster>
        </Box>
      </Box>
    </Stack>
  </Box>
);

export default DisplayCard;
