import React from "react";
import styled from "styled-components";

import { SearchIcon } from "../icons";
import { Box } from "../layouts";
import Text from "../text";

import { themeValues } from "./TypeaheadInput.theme";

export const TypeaheadInputWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  background: ${themeValues.background};

  input {
    border: 0;
    height: 72px;
    width: 100%;
    padding: 1.5rem;
    padding-left: 1rem;
    min-width: 100px;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    font-size: 1.1rem;
    line-height: 2rem;
    font-weight: 400;
    background-color: ${themeValues.background};
    color: ${themeValues.black};
    box-shadow: none;
    border: 1px solid transparent;

    &:focus {
      border: 1px solid ${themeValues.focusBorder};
    }
  }
`;

const TypeaheadInput = ({ handleChange, placeholder, id, labelText }) => {
  const onChange = el => handleChange(el.currentTarget.value);
  return (
    <TypeaheadInputWrapper>
      <Box padding="1.5rem 0 1.5rem 1.5rem">
        <SearchIcon />
      </Box>

      <Text
        as="label"
        color={"black"}
        variant="pS"
        weight={500}
        extraStyles={`
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        `}
        id={id}
      >
        {labelText}
      </Text>
      <input
        aria-labelledby={id}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
      />
    </TypeaheadInputWrapper>
  );
};

export default TypeaheadInput;
