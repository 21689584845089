import React from "react";
import Modal from "../modal";
import Text from "../../atoms/text";
import { Box } from "../../atoms/layouts";
import { fallbackValues } from "./TermsAndConditionsModal.theme";
import { themeComponent } from "../../../util/themeUtils";

const TermsAndConditionsModal = ({
  link,
  title = "Terms & Conditions",
  isOpen,
  toggleOpen,
  toggleAccepted,
  acceptText,
  terms,
  variant,
  linkVariant = "p",
  themeValues,
  initialFocusSelector = ""
}) => (
  <Modal
    modalOpen={isOpen}
    hideModal={() => toggleOpen(false)}
    showModal={() => toggleOpen(true)}
    modalHeaderText={title}
    modalBodyText={
      <Box
        background={themeValues.backgroundColor}
        border={`1px solid ${themeValues.border}`}
        borderRadius="3px"
        extraStyles="overflow: scroll; max-height: 20rem;"
        id="terms-body-text"
      >
        <Text variant="p" extraStyles={`& a { text-decoration: underline; }`}>
          {terms}
        </Text>
      </Box>
    }
    defaultWrapper={false}
    onlyCloseButton={!acceptText}
    continueButtonText={acceptText}
    continueAction={() => {
      toggleAccepted(true);
      toggleOpen(false);
    }}
    initialFocusSelector={initialFocusSelector}
  >
    <Text
      variant={linkVariant}
      onClick={() => toggleOpen(true)}
      onKeyPress={e => e.key === "Enter" && toggleOpen(true)}
      tabIndex="0"
      color={themeValues.linkColor}
      weight={themeValues.fontWeight}
      hoverStyles={themeValues.modalLinkHoverFocus}
      textDecoration={themeValues.modalLinkTextDecoration}
      extraStyles={`display: inline-block; width: fit-content; cursor: pointer`}
      role="button" // This should always be a "button" since it opens a modal
      className="modal-trigger"
    >
      {link}
    </Text>
  </Modal>
);

export default themeComponent(
  TermsAndConditionsModal,
  "TermsAndConditionsModal",
  fallbackValues,
  "default"
);
