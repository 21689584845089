import React, { useEffect } from "react";
import styled from "styled-components";
import { Alert, util } from "@thecb/components";

const AlertBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AlertBar = ({
  alerts = [],
  deleteAlert = util.general.noop,
  clearAlerts = util.general.noop
}) => {
  useEffect(() => () => clearAlerts(), []);
  return (
    <AlertBarContainer>
      {alerts.map((alert, i) => (
        <Alert
          key={alert.heading}
          alertPosition={i}
          {...alert}
          onLinkClick={() => deleteAlert(i)}
        />
      ))}
    </AlertBarContainer>
  );
};

export default AlertBar;
