import theme from "styled-theming";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
/*
themeContext: { name: "default", values: {JSON_THEME_VALUES_HERE} }

fallbackValues: {
  padding: {
    primary: "2rem 1rem",
    secondary: "1rem 0.5rem"
  },
  fontWeight: "600"
}

componentName: "Button"

Iterates over a component's fallback values, checks to see if any of those values
are present in the theme values available in the app's theme context.
If they are, those values override the fallback value.

We use the resuting values as an argument to the styled-theming lib functions
to create new functions that can be called with theme props to produce values.
Typically, those functions are passed back to components and used in styled-component files.
We do things a bit differently...

Before returning, we call the styled-theming functions with relevant theme props (name, variant...)
The styled-theming API then returns the value
Ultimately, an object of property values ({ color: "red", fontWeight: "600" })
is returned to the component, which can be passed
to whatever layout atoms / other atoms the component is using to build itself

Why do things this way? It's powerful. Right now we're always using "default" as our theme name
And only expecting one set of theme value in our context (one theme per client)

However, this function can easily be modified to allow for multiple themes per client and
allow for toggling those themes on the fly via state values

Future use cases for the above: 
  -Different themes per agency/sub-division of client
  -Extra themes designed for accessibility (high contrast / color blindness)
  -Superfluous extra themes (dark mode)
  -Toggling between many client themes / proposed client themes in a live demo

The above functionality is not available right now, but it is trivial to enable it with this approach.
*/
export const createThemeValues = (
  themeContext,
  fallbackValues,
  componentName,
  variant
) => {
  const { values: themeValues } = themeContext;
  const themeProps = { theme: { name: "default", ...themeContext }, variant };

  const mergedValues = Object.entries(fallbackValues).reduce(
    (acc, [propName, propValue]) => {
      if (typeof propValue === "object" && !Array.isArray(propValue)) {
        const mergedPropValues = Object.entries(propValue).reduce(
          (innerAcc, [variantName, defaultVariantValue]) => {
            const variantValue =
              themeValues?.[componentName]?.[propName]?.[variantName] ??
              defaultVariantValue;
            return {
              ...innerAcc,
              [variantName]: {
                default: variantValue
              }
            };
          },
          {}
        );
        // Always using "default" as our theme name right now
        // In the future if we want to toggle themes on the fly, change this
        return {
          ...acc,
          [propName]: mergedPropValues
        };
      }
      const mergedSinglePropValue =
        themeValues?.[componentName]?.[propName] ?? propValue;
      return {
        ...acc,
        [propName]: { default: mergedSinglePropValue }
      };
    },
    {}
  );

  const createdTheme = Object.entries(mergedValues).reduce(
    (acc, [propName, propValue]) => {
      if (propValue[variant]) {
        return {
          ...acc,
          [propName]: theme.variants("name", "variant", propValue)(themeProps)
        };
      }
      return {
        ...acc,
        [propName]: theme("name", propValue)(themeProps)
      };
    },
    {}
  );
  return createdTheme;
};

export const themeComponent = (
  component,
  componentThemeId,
  fallbackValues,
  defaultVariant
) => ({ variant = defaultVariant, ...props }) => {
  const themeContext = useContext(ThemeContext);
  const metadata = themeContext?.metadata ?? {};
  const themeValues = themeContext
    ? createThemeValues(themeContext, fallbackValues, componentThemeId, variant)
    : fallbackValues;

  return component({
    ...props,
    themeValues,
    variant,
    metadata
  });
};

/**
 * Indicates whether the current width of the browser
 * window is less than or equal to a portrait sized tablet
 * at 1048px. It may come in handy when tablet and mobile
 * styles are the same, but distinct from desktop styles.
 * @returns {boolean}
 */
export const isPortraitTabletOrSmaller =
  window?.innerWidth && window.innerWidth <= 1048;

/**
 * Detect if an item is the last in a list of items given
 * the item's index and the length of the list
 * @param {number} index
 * @param {Array} list
 * @returns {boolean}
 */
export const isLastInList = (index, list) => index === list.length - 1;

/*
Keeping this around for now in case we need it later...

export const generateImgSrc = (
  configAssetsEndpoint,
  clientSlug,
  agency = ""
) => filename => {
  if (configAssetsEndpoint === "http://localhost:8080/assets") {
    return agency !== ""
      ? `${configAssetsEndpoint}/${clientSlug}/${agency}/${filename}`
      : `${configAssetsEndpoint}/${clientSlug}/${filename}`;
  }
  return agency !== ""
    ? `${configAssetsEndpoint}/${agency}/${filename}`
    : `${configAssetsEndpoint}/${filename}`;
};
*/
