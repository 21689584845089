import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import {
  SUBMIT_RESET_PASSWORD_FORM,
  resetPasswordSuccess,
  resetPasswordError,
  resetPasswordAlertBarAction
} from "./ResetPassword.state";
import { addAlert } from "../../../../components/alert-bar/AlertBar.state";
import { createPasswordResetRequest } from "./graphql/Queries";

const createParams = state => ({
  endpoint: state.config.graphqlServiceEndpoint,
  clientSlug: state.config.defaultClientSlug,
  id: state.resetPassword.authenticationId,
  key: state.resetPassword.passwordResetKey,
  password: state.resetPassword.forms.resetPasswordForm.password.rawValue
});

const resetPasswordSubmitEpic = (action$, state$) =>
  action$.ofType(SUBMIT_RESET_PASSWORD_FORM).pipe(
    RxOp.mergeMap(() =>
      Rx.from(createPasswordResetRequest(createParams(state$.value))).pipe(
        RxOp.flatMap(() =>
          Rx.of(resetPasswordSuccess(), push("/reset-success"))
        ),
        RxOp.catchError(error =>
          Rx.of(
            resetPasswordError(error),
            resetPasswordAlertBarAction(
              addAlert({
                heading: "Failed to reset your password",
                text: "An error occurred while trying to reset your password.",
                variant: "error"
              })
            )
          )
        )
      )
    )
  );

export default resetPasswordSubmitEpic;
