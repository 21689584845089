import React from "react";

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="21"
      viewBox="0 0 32 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-870 -426)">
          <g transform="translate(132 332)">
            <g transform="translate(0 70)">
              <g transform="translate(738 24)">
                <rect
                  width="32"
                  height="21"
                  x="0"
                  y="0"
                  fill="#CFE5F4"
                  rx="1"
                ></rect>
                <path
                  fill="#184390"
                  d="M20.9 8.536a.292.292 0 00.213-.086.282.282 0 00.087-.209v-.295h.6c.05 0 .096-.02.137-.061A.186.186 0 0022 7.75v-.393c0-.082-.042-.139-.125-.172l-5.8-2.16a.127.127 0 00-.15 0l-5.8 2.16c-.083.033-.125.09-.125.172v.393c0 .05.02.094.063.135.041.041.087.061.137.061h.6v.295c0 .082.03.151.087.209.059.057.13.086.213.086h9.8zm.3 5.893v-.492a.282.282 0 00-.087-.208.292.292 0 00-.213-.086H20V8.929h-1.6v4.714h-1.6V8.929h-1.6v4.714h-1.6V8.929H12v4.714h-.9a.292.292 0 00-.213.086.282.282 0 00-.087.209v.49h10.4zM21.8 16c.05 0 .096-.02.137-.061a.186.186 0 00.063-.135v-.393a.563.563 0 00-.175-.418.584.584 0 00-.425-.172H10.6a.584.584 0 00-.425.172.563.563 0 00-.175.418v.393c0 .049.02.094.063.135.041.04.087.061.137.061h11.6z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckIcon;
