import React, { useState, useContext } from "react";
import { ThemeContext } from "styled-components";

import {
  Box,
  Stack,
  Cluster,
  Heading,
  Paragraph,
  ButtonWithAction,
  WalletIcon,
  LoginForm,
  util,
  constants
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../../constants/style_constants";
import AlertBar from "../../../../../../components/alert-bar";
import FocusTrap from "react-focus-trap";

const LoginPanel = ({
  walletForm,
  walletFormActions,
  walletLoginAlertBar,
  walletLoginAlertBarActions,
  submitting,
  submitLoginForm,
  closeSlider,
  panelIsOpen,
  changePanel,
  isMobile,
  handleFocusErrors,
  themeValues
}) => {
  const [errors, handleErrors] = useState(false);
  const handleSubmit = util.general.generateClickHandler(
    walletForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitLoginForm({ inWallet: true })
  );
  const { metadata } = useContext(ThemeContext);
  const clientName = metadata?.data?.clientDisplayName ?? "";

  return (
    <Box
      padding="0"
      minHeight="100%"
      width="100%"
      background={constants.colors.ATHENS_GREY}
      extraStyles={`.focus-trap-wrapper:focus, .focus-trap:focus { outline: none; }`}
    >
      <FocusTrap active={panelIsOpen} onExit={closeSlider}>
        <Stack childGap="0">
          <Box
            key="wallet-login-header"
            background={themeValues.bannerBackgroundColor}
            padding="1.5rem 2rem"
          >
            <Cluster justify="flex-start" align="center" nowrap childGap="2rem">
              <Box padding="0" extraStyles={`display: flex; flex: 1;`}>
                <Stack childGap="0.5rem">
                  <Box padding="0">
                    <Heading variant="h4" as="h2" weight={FONT_WEIGHT_SEMIBOLD}>
                      Welcome to {clientName} Wallet
                    </Heading>
                  </Box>
                  <Box padding="0">
                    <Paragraph>Please log in to continue</Paragraph>
                  </Box>
                </Stack>
              </Box>
              <Box
                padding="0"
                maxWidth="280px"
                extraStyles={`display: flex; flex: 1; svg { min-width: 100%; }`}
              >
                <WalletIcon />
              </Box>
            </Cluster>
          </Box>
          <Box
            key="wallet-login-form"
            padding={
              walletLoginAlertBar.alerts.length > 0
                ? "1.5rem 2rem 2rem"
                : "2rem"
            }
            background={constants.colors.ATHENS_GREY}
          >
            {walletLoginAlertBar.alerts.length > 0 && (
              <Box padding="0 0 1.5rem">
                <AlertBar
                  {...walletLoginAlertBar}
                  {...walletLoginAlertBarActions}
                />
              </Box>
            )}
            <LoginForm
              {...walletForm}
              {...walletFormActions}
              handleSubmit={handleSubmit}
              submitting={submitting}
              showErrors={errors}
              isMobile={isMobile}
              clearOnDismount
            />
            <Box padding="1.5rem 0">
              <Cluster justify="center" align="center">
                <ButtonWithAction
                  text="Log in"
                  isLoading={submitting}
                  variant="primary"
                  action={handleSubmit}
                  extraStyles={`width: 100%;`}
                  dataQa="Wallet log in"
                />
              </Cluster>
            </Box>
            <Box padding="0 0 1.5rem 0">
              <Cluster justify="flex-end" align="center">
                <ButtonWithAction
                  action={() => {
                    changePanel("login", "next");
                    changePanel("forgotPassword", "onScreen");
                  }}
                  variant="smallGhost"
                  text="Forgot password?"
                  dataQa="Forgot password"
                  extraStyles={`&:hover, &:focus { text-decoration: underline; }`}
                />
              </Cluster>
            </Box>
            <Box
              padding="1rem"
              background={constants.colors.WHITE}
              boxShadow={
                "0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
              }
              borderRadius="4px"
            >
              <Stack childGap="1rem">
                <Paragraph variant="pL" weight={FONT_WEIGHT_SEMIBOLD}>
                  Want to register?
                </Paragraph>
                <Paragraph>
                  After checking out as a guest, there is a link to register on
                  the receipt page.
                </Paragraph>
                <ButtonWithAction
                  text="Close and check out as guest"
                  variant="secondary"
                  action={closeSlider}
                  extraStyles={`width: 100%;`}
                  dataQa="Close wallet"
                />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </FocusTrap>
    </Box>
  );
};

export default LoginPanel;
