import React, { useContext, forwardRef } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "./Link.theme";
import { createThemeValues } from "../../../util/themeUtils";
import { StyledInternalLink } from "./InternalLink.styled";
import { safeChildren } from "../../../util/general";

const InternalLink = forwardRef(
  (
    {
      to = "",
      color,
      children,
      active,
      fontSize,
      lineheight,
      fontWeight,
      variant = "primary",
      margin,
      tabIndex = "0",
      dataQa,
      extraStyles = ``
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);
    const themeValues = createThemeValues(
      themeContext,
      fallbackValues,
      "Link",
      variant
    );

    return (
      <StyledInternalLink
        to={to}
        color={color}
        lineheight={lineheight}
        active={active}
        fontWeight={fontWeight}
        fontSize={fontSize}
        fontFamily={themeValues.fontFamily}
        margin={margin}
        hoverColor={themeValues.hoverColor}
        activeColor={themeValues.activeColor}
        tabIndex={tabIndex}
        extrastyles={extraStyles}
        data-qa={dataQa}
        ref={ref}
      >
        {safeChildren(children, <span />)}
      </StyledInternalLink>
    );
  }
);

export default InternalLink;
