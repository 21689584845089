import React, { Fragment } from "react";
import { SPACING, FONT_SIZE, CART_LAYOUT_PADDING } from "../MultiCart.theme";
import { STANDARD_ITEM_TYPE } from "../state/ShoppingCart.selectors";
import {
  Box,
  Cluster,
  KebabMenuIcon,
  ObligationIcons,
  Paragraph,
  PopupMenu,
  Stack,
  Text,
  Title,
  constants,
  util
} from "@thecb/components";
import { StyledCartItem, StyledIconContainer } from "../MultiCart.styled";
import RemoveButton from "./RemoveButton";

/* 
    Per direction of the design team, this kebab menu component is temporarily unused. 
    It will be reintroduced once additional profile work is complete to make the 
    menu items functional. 
*/
const KebabMenu = ({ menuItems, isMobile }) => {
  return (
    <Box padding="0">
      <PopupMenu
        hasIcon="true"
        menuItems={menuItems}
        icon={KebabMenuIcon}
        minWidth={"50px"}
        maxWidth={"208px"}
        position={{
          top: "5px",
          left: "auto",
          right: `${isMobile ? "30px" : "40px"}`
        }}
        menuId={"menuId"}
        containerExtraStyles={`
            justify-content: flex-end;
            min-width: auto;
            #menuId {
                padding: 0;
                ${isMobile && `svg { height: 24px;}`}
            }
        `}
        buttonExtraStyles={`
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0; 
            min-width: auto; 
            height: auto; 
            width: ${isMobile ? "20px" : "24px"};
        `}
        popupExtraStyles={`padding: 8px; z-index: 9999;`}
      />
    </Box>
  );
};

const CartItem = ({
  cartId,
  checkoutInProgress,
  index,
  inSlider,
  isMobile,
  item,
  removeFromMultiCart,
  linkColor
}) => {
  const {
    description = "",
    subDescription = "",
    identifier = "",
    amount,
    icon
  } = item;
  const {
    colors: { CHARADE_GREY },
    fontWeights: { FONT_WEIGHT_SEMIBOLD }
  } = constants;
  const { displayCurrency } = util.general;
  const ItemIcon =
    ObligationIcons?.[icon] ?? ObligationIcons["ACCOUNTS_GENERIC"];

  return (
    <StyledCartItem
      key={index}
      isMobile={isMobile}
      padding={CART_LAYOUT_PADDING}
      role="listitem"
      aria-label={`Cart Item: ${description}, ${subDescription || identifier}`}
    >
      <Stack padding="0" childGap="1px">
        <Cluster
          justify="space-between"
          align="center"
          childGap="8px"
          minWidth="100%"
          overflow={true}
          extraStyles={`
              width: 100%;
              overflow: visible;
              margin: 0;
            `}
          nowrap
        >
          <Cluster
            justify="flex-start"
            align="center"
            childGap={isMobile ? "0.75rem" : SPACING.MD}
            padding="0"
            extraStyles={isMobile && `margin: 0;`}
            nowrap
          >
            {item.itemType === STANDARD_ITEM_TYPE ? (
              <StyledIconContainer
                height={isMobile ? "28px" : "40px"}
                width={isMobile ? "28px" : "40px"}
                aria-hidden="true"
              >
                <ItemIcon color={linkColor} />
              </StyledIconContainer>
            ) : (
              <Fragment />
            )}
            <Box padding="0">
              <Title
                variant="small"
                as={inSlider ? "h4" : "h3"}
                color={CHARADE_GREY}
                weight={FONT_WEIGHT_SEMIBOLD}
                extraStyles={`font-size: ${
                  isMobile ? FONT_SIZE.MD : FONT_SIZE.LG
                };`}
              >
                {description}
              </Title>
              <Paragraph variant="p" color={CHARADE_GREY}>
                {subDescription}
              </Paragraph>
              <Paragraph variant="p" color={CHARADE_GREY}>
                {identifier}
              </Paragraph>
            </Box>
          </Cluster>
          <Cluster
            justify="flex-end"
            align="center"
            childGap={isMobile ? SPACING.XS : SPACING.SM}
            overflow={true}
            padding="0"
            nowrap
          >
            <Box padding="0">
              <Text
                variant="p"
                weight={FONT_WEIGHT_SEMIBOLD}
                color={CHARADE_GREY}
              >
                {displayCurrency(amount)}
              </Text>
            </Box>
            {!item?.isBundled && !checkoutInProgress && (
              <RemoveButton
                ariaLabel={`Delete ${description}`}
                isMobile={isMobile}
                removeAction={() => removeFromMultiCart({ cartId, item })}
                data-qa={`${cartId}-delete-item-${index}`}
              />
            )}
          </Cluster>
        </Cluster>
      </Stack>
    </StyledCartItem>
  );
};

export default CartItem;
