import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const AccountsAddIcon = ({ themeValues }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <defs>
        <path id="a" d="M0 0H100V100H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <mask id="b" fill={themeValues.primaryColor}>
          <use xlinkHref="#a"></use>
        </mask>
        <path
          fill={themeValues.primaryColor}
          d="M66.728 11.967c.566 0 1.024.466 1.024 1.042v18.508h6.362c.527 0 .962.406 1.018.928l.006.114v42.7c-2.433 2.351-4.911 4.22-7.386 5.687v.024s-.311.19-.898.49c-17.526 9.718-34.635-.176-34.635-.176v-1.02c-4.533-2.49-7.386-5.006-7.386-5.006V13.01c0-.576.459-1.042 1.025-1.042h40.87z"
          mask="url(#b)"
        ></path>
        <path
          fill={themeValues.accentColor}
          fillRule="nonzero"
          d="M63.551 84.435l-.22.087A36.867 36.867 0 0150 87c-20.435 0-37-16.588-37-37.05a36.96 36.96 0 0110.865-26.227V13.01c0-1.056.81-1.927 1.843-2.004l.15-.005h40.87a2 2 0 011.986 1.86l.006.15v8.085L68.746 18a37.09 37.09 0 014.464 3.095A36.994 36.994 0 0187 49.95a36.915 36.915 0 01-5.216 18.978 12.433 12.433 0 00-1.743-2.803 34.02 34.02 0 004.062-16.175A34.09 34.09 0 0071.39 23.353a34.268 34.268 0 00-2.67-1.952l-.002 9.536c.185-.227.477-.387.89-.387h4.505c1.053 0 1.91.822 1.986 1.86l.005.149.002 30.394a12.38 12.38 0 00-1.936-.796L74.17 39.74h-5.451l.001 21.957c-.663.09-1.31.233-1.935.424V20.19H25.8v53.82C31.978 80.24 40.539 84.1 50 84.1c3.871 0 7.592-.646 11.06-1.837.73.83 1.567 1.562 2.491 2.173zm-4.016-4.331l-11.28-.001V76.38h9.926a12.374 12.374 0 001.354 3.724zm-35.67-52.095a34.047 34.047 0 00-7.968 21.941 34.039 34.039 0 007.968 21.939v-43.88zM61.68 64.1c.31 0 .586.146.763.373a12.463 12.463 0 00-1.731 1.76v-.199H31.873v2.931h27.166c-.276.62-.504 1.266-.677 1.934H30.906a.967.967 0 01-.96-.854l-.007-.113v-4.865c0-.496.373-.904.854-.96l.113-.007h30.773zm0-8.734c.496 0 .905.373.96.855l.007.112v4.865a.967.967 0 01-.854.961l-.113.007H30.906a.967.967 0 01-.96-.855l-.007-.113v-4.865c0-.496.373-.905.854-.96l.113-.007h30.773zM60.71 57.3H31.873v2.931h28.838V57.3zm-14.418-6.751a.967.967 0 01.113 1.928l-.113.006H30.906a.967.967 0 01-.113-1.928l.113-.006h15.387zm15.386-11.266c.496 0 .905.374.96.855l.007.113v4.865a.967.967 0 01-.854.96l-.113.007H30.906a.967.967 0 01-.96-.855l-.007-.112V40.25c0-.496.373-.905.854-.961l.113-.007h30.773zm-.968 1.934H31.873v2.931h28.838v-2.931zm.968-10.667c.496 0 .905.374.96.855l.007.112v4.865a.967.967 0 01-.854.961l-.113.007H30.906a.967.967 0 01-.96-.855l-.007-.113v-4.865c0-.496.373-.904.854-.96l.113-.007h30.773zm-.968 1.934H31.873v2.931h28.838v-2.931zm-14.418-6.752a.967.967 0 01.113 1.928l-.113.007H30.906a.967.967 0 01-.113-1.928l.113-.007h15.387z"
          mask="url(#b)"
        ></path>
        <path
          fill={themeValues.subIconColor}
          d="M70.42 61.582c6.88 0 12.458 5.578 12.458 12.459 0 6.88-5.577 12.458-12.458 12.458-6.88 0-12.458-5.578-12.458-12.458 0-6.881 5.578-12.459 12.458-12.459zm.82 6.721H69.6v4.918h-4.917v1.64H69.6v4.917h1.639V74.86h4.918v-1.64H71.24v-4.917z"
          mask="url(#b)"
        ></path>
      </g>
    </svg>
  );
};

export default themeComponent(AccountsAddIcon, "Icons", fallbackValues, "info");
