import * as R from "ramda";
import React, { useEffect, useContext } from "react";
import queryString from "query-string";
import { useLocation, useRoutes, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Box, CenterSingle, Loading, withWindowSize } from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";
import { routes, VERIFY_ACCOUNT_INDEX, VERIFY_ACCOUNT_SUCCESS } from "./routes";
import { buildRoutes } from "../../../../util/router-utils";
import {
  ACCOUNT_VERIFICATION_CHECKING,
  ACCOUNT_VERIFICATION_EXPIRED,
  ACCOUNT_VERIFICATION_PENDING,
  ACCOUNT_VERIFICATION_SUCCESS,
  ACCOUNT_VERIFICATION_RESENDING
} from "./AccountVerification.state";

const AccountVerificationContent = ({
  alertBar,
  alertBarActions,
  resendVerification,
  isMobile,
  email,
  verificationStatus,
  showLoader,
  useRoutes
}) => {
  let subRoutes = [];
  if (verificationStatus === ACCOUNT_VERIFICATION_PENDING) {
    subRoutes = buildRoutes(
      [{ path: "/", index: true, key: VERIFY_ACCOUNT_INDEX }],
      routes,
      { email, resendVerification }
    );
  } else if (verificationStatus === ACCOUNT_VERIFICATION_SUCCESS) {
    subRoutes = buildRoutes([VERIFY_ACCOUNT_SUCCESS], routes, {});
  }

  return (
    <Box data-testid="account-verification-content" padding={0}>
      <Box
        padding={isMobile ? "0 0 48px" : "32px 0 64px"}
        maxWidth={isMobile ? "auto" : "576px"}
      >
        {alertBar.alerts.length > 0 && (
          <Box padding={isMobile ? "1rem 0" : "0 0 1rem"}>
            <AlertBar {...alertBar} {...alertBarActions} />
          </Box>
        )}
        {showLoader ? (
          <Box data-testid="loading-spinner" padding={0}>
            <Loading />
          </Box>
        ) : (
          useRoutes(subRoutes)
        )}
      </Box>
    </Box>
  );
};

const AccountVerification = ({
  activateUser,
  alertBar,
  alertBarActions,
  email,
  resendVerification,
  verificationStatus
}) => {
  const { isMobile } = useContext(ThemeContext);
  let location = useLocation();
  let navigate = useNavigate();
  const query = queryString.parse(location.search);
  const verificationKey = query.verification_key;

  // verification key states
  const isPending = verificationStatus === ACCOUNT_VERIFICATION_PENDING;
  const isChecking = verificationStatus === ACCOUNT_VERIFICATION_CHECKING;
  const isExpired = verificationStatus === ACCOUNT_VERIFICATION_EXPIRED;
  const isResending = verificationStatus === ACCOUNT_VERIFICATION_RESENDING;

  // behaviour conditionals
  const shouldActivateUser = verificationKey && isPending;
  const shouldShowLoader =
    shouldActivateUser || isChecking || isExpired || isResending;

  const isInvalidState = R.not(verificationKey) && isChecking;

  useEffect(() => {
    if (shouldActivateUser) {
      activateUser(verificationKey);
    }
  }, [shouldActivateUser]);

  useEffect(() => {
    if (isInvalidState) {
      navigate("/login");
    }
  }, [isInvalidState]);

  return (
    <CenterSingle
      content={
        <AccountVerificationContent
          alertBar={alertBar}
          alertBarActions={alertBarActions}
          email={email}
          isMobile={isMobile}
          resendVerification={resendVerification}
          showLoader={shouldShowLoader}
          useRoutes={useRoutes}
          verificationKey={verificationKey}
          verificationStatus={verificationStatus}
        />
      }
    />
  );
};

export default withWindowSize(AccountVerification);
