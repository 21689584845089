import React from "react";

import { Box, Cluster, Popover, Text } from "@thecb/components";
import { SEA_GREEN, WHITE } from "/constants/colors";

const TransactionAutopayPopover = ({
  id,
  childGap = "5px",
  displayText,
  fontSize,
  Icon,
  position,
  textColor
}) => {
  const tooltipText = "Automatic Payment";
  const screenReaderText = "Autopay enabled";

  return (
    <Popover
      popoverID={id}
      position={position}
      popoverFocus={true}
      hasIcon={false}
      triggerText={
        <Cluster childGap={childGap} align="center" justify="left">
          <Icon />
          <Box padding="0" srOnly>
            <Text id={`btnPopover${id}_info`}>{screenReaderText}</Text>
          </Box>
          <Text color={textColor} fontSize={fontSize}>
            {displayText}
          </Text>
        </Cluster>
      }
      content={
        <Text color={WHITE} fontSize="12px" extraStyles="white-space: nowrap">
          {tooltipText}
        </Text>
      }
      backgroundColor={SEA_GREEN}
      minWidth={0}
      buttonExtraStyles="min-width: 0; margin: 0; &:hover { text-decoration: none; }"
      popoverExtraStyles="padding: 4px 8px; color: white"
      borderColor={SEA_GREEN}
      arrowPosition={{
        arrowTop: "auto",
        arrowRight: "50px",
        arrowBottom: "-8px",
        arrowLeft: "auto"
      }}
    />
  );
};

export default TransactionAutopayPopover;
