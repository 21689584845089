let currentPage = "";

// eslint-disable-next-line no-unused-vars
export const googleAnalytics = store => next => action => {
  // `SET_TENANT_SLUG` is the first action that is hit. so for tracking navigating to a landing page.
  if (["SET_TENANT_SLUG", "@@router/LOCATION_CHANGE"].includes(action.type)) {
    const origin = window.location.origin;
    const analyticsIDs =
      store?.getState()?.global?.clientMetadata?.data?.analytics ?? {};
    const { trackingId, clientTrackingId } = analyticsIDs; // internal and external GA 4 datastream IDs
    const nextPage = window.location.pathname + window.location.search;

    if (currentPage !== nextPage) {
      currentPage = nextPage;

      // use new Google Analytics 4 tracking method
      if (trackingId && window.gtag) {
        window.gtag("config", trackingId, { page_path: nextPage });
        window.gtag("event", "page_view", { page: nextPage, origin });
      }

      if (clientTrackingId && window.gtag) {
        window.gtag("config", clientTrackingId, { page_path: nextPage });
        window.gtag("event", "page_view", { page: nextPage, origin });
      }
    }
  }

  return next(action);
};
