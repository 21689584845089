import React from "react";

const ChargebackReversalIconMedium = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ChargebackReversalIconMedium-${iconIndex}`;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="7"
        width="16"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 8C5 7.44772 5.44772 7 6 7H16C16.5523 7 17 7.44772 17 8V10V12V16C17 16.5523 16.5523 17 16 17H6C5.44772 17 5 16.5523 5 16V12V10V8ZM16.25 7.83333V10H5.75V9.91667V7.83333H16.25ZM5.75 16.1667V12H16.25V16.1667H5.75ZM18 13.7929C18 14.2383 18.5386 14.4614 18.8536 14.1464L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L18.8536 9.85355C18.5386 9.53857 18 9.76165 18 10.2071V13.7929ZM13.1875 14C12.9114 14 12.6875 14.2239 12.6875 14.5C12.6875 14.7761 12.9114 15 13.1875 15H14.4375C14.7136 15 14.9375 14.7761 14.9375 14.5C14.9375 14.2239 14.7136 14 14.4375 14H13.1875Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="3" y="3" width="18" height="18" fill="white" />
      </g>
    </svg>
  );
};

export default ChargebackReversalIconMedium;
