import React, { memo, useState, useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Cover,
  Stack,
  Cluster,
  Modal,
  Text,
  ButtonWithAction,
  NavFooter,
  ExternalLink,
  InternalLink,
  TermsAndConditionsModal,
  withWindowSize,
  util,
  constants,
  Switcher,
  ExternalLinkIcon
} from "@thecb/components";
import { test } from "ramda";
import parse from "html-react-parser";
import dompurify from "dompurify";
import { rgba } from "polished";
import { v4 as uuidv4 } from "uuid";

import { fallbackValues } from "./Footer.theme";
import { themeComponent } from "../../util/themeUtils";
import { URL_TEST } from "../../constants/regex_constants";

const Footer = ({
  metadata,
  themeValues,
  showLogout = false,
  onLogout = util.general.noop,
  footerWidth
}) => {
  const [termsModalState, setTermsModalState] = useState(false);
  const [privacyModalState, setPrivacyModalState] = useState(false);
  const { isMobile } = useContext(ThemeContext);
  const footerLogoSrc = metadata?.data?.footerLogo ?? "";
  const footerLogoLink = metadata?.data?.footerLogoLink;
  const displaySupportLink =
    metadata?.data?.contactAndSupport?.displaySupportLink ?? false;
  const subfooterLinkList = metadata?.data?.subfooterLinks ?? [];
  const cityName = metadata?.data?.name ?? "";

  const FooterLogoWrapper = ({ children }) =>
    footerLogoLink ? (
      test(URL_TEST, footerLogoLink) ? (
        <ExternalLink
          extraStyles={`&:focus { 
          outline: 3px solid ${themeValues.accessibilityColor}; 
          outline-offset: 2px; 
        }`}
          href={footerLogoLink}
        >
          {children}
        </ExternalLink>
      ) : (
        <InternalLink
          extraStyles={`&:focus { 
          outline: 3px solid ${themeValues.accessibilityColor}; 
          outline-offset: 2px; 
        }`}
          to={footerLogoLink}
        >
          {children}
        </InternalLink>
      )
    ) : (
      <Fragment>{children}</Fragment>
    );
  const footerLogo = (
    <FooterLogoWrapper key="footer-logo-wrapper">
      <Box padding="0">
        <Cover singleChild>
          <Box padding="0">
            <img
              src={footerLogoSrc}
              height={isMobile ? "40px" : themeValues.logoHeight}
              alt="footer logo"
            />
          </Box>
        </Cover>
      </Box>
    </FooterLogoWrapper>
  );

  const footerLeftContent = (
    <Switcher key="footer content left" breakpoint="25rem">
      <Box minWidth="230px" padding="0">
        {footerLogo}
      </Box>
      {displaySupportLink && showLogout && (
        <Box padding="0" key="footer left content">
          <Cover singleChild minHeight="100%">
            <InternalLink
              to="/support"
              color={themeValues.linkColor}
              extraStyles={`text-decoration: none;
              &:hover { ${themeValues.hoverFocusStyles} } 
              &:focus { 
                outline: 3px solid ${themeValues.accessibilityColor}; 
                outline-offset: 2px; 
              }`}
            >
              Contact and Support
            </InternalLink>
          </Cover>
        </Box>
      )}
    </Switcher>
  );

  const tcContent = metadata?.data?.termsAndConditions?.content ?? "";
  const tcTitle =
    metadata?.data?.termsAndConditions?.title ?? "Terms and Conditions";
  const privacyContent = metadata?.data?.privacyPolicy?.content ?? "";
  const privacyTitle = metadata?.data?.privacyPolicy?.title ?? "";
  const logoutButton = showLogout ? (
    <Box padding="0" key="logout">
      <ButtonWithAction
        variant="whitePrimary"
        action={onLogout}
        text="Log Out"
        extraStyles={`
          padding: 0.75rem 2rem; 
          border: 2px solid ${themeValues.linkColor}; 
          color: ${themeValues.linkColor};
          &:hover {
            background-color: ${rgba(themeValues.linkColor, 0.1)};
            > * > span {
              color: ${themeValues.linkColor};
              background-color: transparent;
            }
          }
          &:active { 
            border: 2px solid ${themeValues.linkColor};
            background-color: ${rgba(themeValues.linkColor, 0.25)};
            > * > span {
              color: ${themeValues.linkColor};
              background-color: transparent;
            }
          };
          &:focus { 
            outline: 3px solid ${themeValues.accessibilityColor}; 
            outline-offset: 2px; 
          }
          > * > span {
            color: ${themeValues.linkColor};
          }
          `}
      />
    </Box>
  ) : (
    <Fragment key="logout" />
  );

  const termsAndConditions = tcContent ? (
    <Box padding="0" key="terms">
      <Cover singleChild minHeight="100%">
        <TermsAndConditionsModal
          link={tcTitle}
          variant="footer"
          linkVariant="pXS"
          title={tcTitle}
          linkColor={themeValues.linkColor}
          terms={parse(dompurify.sanitize(tcContent))}
          isOpen={termsModalState}
          toggleOpen={setTermsModalState}
        />
      </Cover>
    </Box>
  ) : (
    <Fragment key="terms" />
  );

  const privacyPolicy = privacyContent ? (
    <Box padding="0" key="privacy">
      <Cover singleChild minHeight="100%">
        <Modal
          modalOpen={privacyModalState}
          hideModal={() => setPrivacyModalState(false)}
          showModal={() => setPrivacyModalState(true)}
          modalHeaderText={privacyTitle || "Privacy Policy"}
          modalBodyText={
            <Box padding="0rem 1rem">
              {parse(dompurify.sanitize(privacyContent))}
            </Box>
          }
          defaultWrapper={false}
          onlyCloseButton={true}
          maxHeight="500px"
        >
          <Text
            variant="pXS"
            onClick={() => setPrivacyModalState(true)}
            color={themeValues.linkColor}
            hoverStyles={themeValues.hoverFocusStyles}
            extraStyles={`cursor: pointer;  
            &:focus { 
              outline: 3px solid ${themeValues.accessibilityColor}; 
              outline-offset: 2px; 
            }`}
            weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
            onKeyPress={e => e.key === "Enter" && setPrivacyModalState(true)}
          >
            Privacy Policy
          </Text>
        </Modal>
      </Cover>
    </Box>
  ) : (
    <Fragment key="privacy" />
  );

  const subfooterLinks = subfooterLinkList.map(item => (
    <Box
      padding="0"
      key={`link-${item.text}`}
      extraStyles={`max-height: 1.25rem;`}
    >
      <ExternalLink
        containerTabIndex="0"
        tabIndex="-1"
        newTab
        href={item.link}
        color={themeValues.lightText}
        hoverColor={themeValues.lightText}
        extraStyles={`text-decoration: none;
        &:hover { ${themeValues.hoverFocusStyles} } 
        &:focus { 
          outline: 3px solid ${themeValues.accessibilityColor}; 
          outline-offset: 2px; 
        }`}
        size="0.875rem"
        lineHeight="1.25rem"
        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
        ariaLabel={`Link to ${item.text} (opens in a new window)`}
      >
        <Cluster childGap="0.5rem" justify="center" align="center">
          <Text
            variant="pXS"
            color={themeValues.linkColor}
            weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
            extraStyles={`cursor: pointer;   
          &:focus { 
            outline: 3px solid ${themeValues.accessibilityColor}; 
            outline-offset: 2px; 
          }`}
          >
            {item.text}
          </Text>
          <ExternalLinkIcon
            linkColor={themeValues.linkColor}
            text={`${uuidv4()}`}
          />
        </Cluster>
      </ExternalLink>
    </Box>
  ));

  const currentYear = new Date().getFullYear();

  const copyright =
    cityName === "Cityville" ? (
      <Box key="copyright" padding="0">
        <Text variant="pXS" color={themeValues.linkColor}>
          &copy; {currentYear} City of Cityville
        </Text>
      </Box>
    ) : (
      <Fragment key="copyright" />
    );

  const subfooterBreak = subfooterLinks.length * 150 + 500 > window.innerWidth;

  const footerRightContent = !isMobile
    ? [logoutButton]
    : [<Fragment key="logout" />];
  const subfooterRightContent = (
    <Box padding={isMobile ? "1.5rem 1rem" : "0.5rem"} key="subfooter-right">
      <Stack>{!isMobile ? [copyright] : [copyright, logoutButton]}</Stack>
    </Box>
  );
  const subfooterLeftContent = (
    <Cluster justify="flex-start" align="center" key="subfooter-left">
      <Box padding={isMobile ? "1.5rem 1rem" : "0.5rem"} minWidth="100%">
        <Stack
          direction={subfooterBreak ? "column" : "row"}
          childGap={isMobile ? "1rem" : "3rem"}
        >
          {[termsAndConditions, privacyPolicy, ...subfooterLinks]}
        </Stack>
      </Box>
    </Cluster>
  );

  return (
    <footer>
      <NavFooter
        key="main-footer"
        aria-label="footer"
        leftContent={footerLeftContent}
        rightContent={footerRightContent}
        backgroundColor={themeValues.backgroundColor}
        isMobile={isMobile}
        footerWidth={footerWidth}
      />
      <NavFooter
        key="sub-footer"
        aria-label="subfooter"
        leftContent={subfooterLeftContent}
        rightContent={subfooterRightContent}
        largeSide="left"
        largeSideSize="5"
        backgroundColor={themeValues.subfooterColor}
        isMobile={isMobile || subfooterBreak}
        footerMinHeight="45px"
        footerPadding="0 1rem"
        footerWidth={footerWidth}
      />
    </footer>
  );
};

export default withWindowSize(
  memo(themeComponent(Footer, "Footer", fallbackValues))
);
