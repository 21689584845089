import React, { Fragment } from "react";
import { CenterWrapper } from "./Center.styled";
import { safeChildren } from "../../../util/general";

/*
  Component to horizontally center itself (with auto right and left margins)
  Use intrinsic prop if you want its children that are narrower than the max-width
  to also be centered within the center wrapper itself
*/

const Center = ({
  maxWidth,
  gutters,
  intrinsic = false,
  children,
  ...rest
}) => (
  <CenterWrapper
    maxWidth={maxWidth}
    gutters={gutters}
    intrinsic={intrinsic}
    {...rest}
  >
    {safeChildren(children, <Fragment />)}
  </CenterWrapper>
);

export default Center;
