import * as RxOp from "rxjs/operators";
import * as R from "ramda";

const getPathFromState = R.path(["router", "location", "pathname"]);
const getPastLocation = R.path(["global", "locationHistory", "paths"]);

export const trackLocationChange = action => (action$, state$) => {
  return action$.ofType("@@router/LOCATION_CHANGE").pipe(
    RxOp.map(action => ({ lastRouterAction: action.payload.action })),
    RxOp.flatMap(payload => {
      const statePairs$ = state$.pipe(RxOp.pairwise());
      return statePairs$.pipe(
        RxOp.map(([oldState, newState]) => {
          return [
            R.last(getPastLocation(oldState)),
            getPathFromState(newState)
          ];
        }),
        RxOp.filter(([oldPath, newPath]) => oldPath !== newPath),
        RxOp.map(paths => ({ ...payload, paths }))
      );
    }),
    RxOp.map(payload => action(payload))
  );
};
