import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import styled, { ThemeContext } from "styled-components";
import parse from "html-react-parser";
import dompurify from "dompurify";
import {
  Alert,
  BankIcon,
  Box,
  Cluster,
  GenericCard,
  Module,
  PartialAmountForm,
  PaymentButtonBar,
  PaymentFormACH,
  PaymentFormCard,
  RadioSection,
  Spinner,
  Stack,
  Text,
  Title,
  WarningIconXS,
  constants,
  util,
  createPartialAmountFormValidators
} from "@thecb/components";
import { is, omit } from "ramda";
import { NEW_ACH, NEW_CC, SAVED_ACH, SAVED_CC } from "../../Payment.state.js";
import { calculateFeeAmount } from "../../Payment.selectors.js";
import { calculatePaymentMaximumInCentsForPaymentType } from "../../../../../../util/paymentUtils.js";
import ScreenreaderOnlyText from "../../../../../../components/screenreder-only-text";

const PaymentAmountMethodContainer = styled.div``;

const EXPIRED = "EXPIRED";
const CASH = "cash";
const CARD = "card";
const BANK = "bank";
const BANK_TITLE = "New Bank Account";
const CARD_TITLE = "New Card";
const VISA = "VISA";
const MASTERCARD = "MASTERCARD";
const DISCOVER = "DISCOVER";
const AMEX = "AMEX";
const FULL_AMOUNT = "Full amount";
const PARTIAL_AMOUNT = "Other amount";

const { displayCurrency, formatPercent } = util.general;
const { renderCardStatus } = util.formats;

const alertProps = (minAmt, maxAmt) => {
  return {
    min: {
      heading: `${minAmt} Minimum Payment`,
      text: `There is a minimum payment of ${minAmt}.`
    },
    max: {
      heading: `${maxAmt} Maximum Payment`,
      text: `There is a maximum payment of ${maxAmt}.
    To pay your full bill, you must make multiple payments.`
    },
    minAndMax: {
      heading: "Maximum and Minimum Payments",
      text: `Your total payment cannot be more than ${maxAmt} or less than
    ${minAmt}. To pay your full bill, you must make multiple payments.`
    }
  };
};

const MinMaxPaymentAmountInfoAlert = ({
  paymentMaximumInCents,
  paymentMinimumInCents = 0,
  partialPaymentMinimumInCents = 0,
  partialAmountSelected
}) => {
  const showPartialAlert =
    partialAmountSelected && partialPaymentMinimumInCents;
  if (!paymentMaximumInCents && !paymentMinimumInCents && !showPartialAlert) {
    return null;
  }
  const minAmt =
    showPartialAlert && partialPaymentMinimumInCents > paymentMinimumInCents
      ? partialPaymentMinimumInCents
      : paymentMinimumInCents;
  const formattedMin = minAmt && minAmt > 0 ? displayCurrency(minAmt) : "";
  const formattedMax = paymentMaximumInCents
    ? displayCurrency(paymentMaximumInCents)
    : "";
  const alertPropOption = formattedMax
    ? formattedMin
      ? "minAndMax"
      : "max"
    : "min";
  const formattedProps = alertProps(formattedMin, formattedMax)[
    alertPropOption
  ];

  return (
    <Alert
      {...formattedProps}
      variant="info"
      showQuitLink={false}
      onLinkClick={util.general.noop}
    />
  );
};

const PaymentAmountMethod = ({
  selectNewACHPaymentMethod,
  selectNewCreditCardPaymentMethod,
  selectedPaymentMethodType,
  selectedSavedCreditCardId,
  selectedSavedACHId,
  selectSavedCardMethod,
  selectSavedAchMethod,
  isLoggedIn,
  creditCardForm,
  creditCardFormActions,
  achForm,
  achFormActions,
  navigate,
  potentialFees,
  savedCreditCards,
  savedBankAccounts,
  allowedPaymentMethods,
  allowBankAccountType,
  goToNextPage,
  amountEnabled,
  cancelURL,
  isInvoice,
  isMobile,
  hasPaymentFlag,
  walletEnabled,
  saveToWallet,
  wallet,
  subClientPaymentConfig,
  cartEnabled,
  subtotal,
  handleFocusErrors,
  stepCounter,
  fullAmountSelected,
  partialAmountSelected,
  selectPartialAmount,
  selectFullAmount,
  lineItems,
  partialAmountForm,
  partialAmountFormActions,
  partialAmountFormProps,
  paymentMaximumInCents,
  paymentMinimumInCents,
  fullAmountTotal,
  allowFullPayment,
  partialPaymentMinimumInCents,
  blockPartialPaymentOverpay,
  configForPaymentTypes,
  loadPaymentAmount,
  loadPaymentDetails,
  paymentAmountIsLoading,
  paymentMaximumInCentsForPaymentType,
  updatePaymentMaximumInCentsForPaymentType,
  isGuestCheckout,
  walletScreenV2Enabled,
  useCustomerInformationEmail,
  currentTabIndex
}) => {
  const [methodErrors, setMethodErrors] = useState(false);
  const [amountErrors, setAmountErrors] = useState(false);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(false);
  /*
    This subtotal is unaffected by the amounts entered in the partial amount form.
  */
  const originalSubtotal = useMemo(
    () =>
      lineItems
        .filter(item => is(Object, item))
        .reduce((pre, cur) => pre + cur.amount, 0),
    [lineItems]
  );

  const paymentMaximumInCentsForCreditCard = useMemo(
    () =>
      calculatePaymentMaximumInCentsForPaymentType({
        configForPaymentTypes,
        globalPaymentMaximumInCents: paymentMaximumInCents,
        selectedPaymentMethodType: SAVED_CC
      }),
    [configForPaymentTypes, paymentMaximumInCents]
  );

  const paymentMaximumInCentsForBankAccount = useMemo(
    () =>
      calculatePaymentMaximumInCentsForPaymentType({
        configForPaymentTypes,
        globalPaymentMaximumInCents: paymentMaximumInCents,
        selectedPaymentMethodType: SAVED_ACH
      }),
    [configForPaymentTypes, paymentMaximumInCents]
  );

  const fullAmountIsDisabled = useMemo(() => {
    if (!selectedPaymentMethodType || !allowFullPayment) {
      return true;
    } else if (
      paymentMaximumInCentsForPaymentType &&
      originalSubtotal > paymentMaximumInCentsForPaymentType
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    allowFullPayment,
    originalSubtotal,
    paymentMaximumInCentsForPaymentType,
    selectedPaymentMethodType
  ]);

  const isNewACH = selectedPaymentMethodType === NEW_ACH;
  const isNewCreditCard = selectedPaymentMethodType === NEW_CC;
  const isCard = isNewCreditCard || !!selectedSavedCreditCardId;
  const isACH = isNewACH || !!selectedSavedACHId;
  const isFormEmpty =
    (!isNewACH &&
      !isNewCreditCard &&
      !selectedSavedCreditCardId &&
      !selectedSavedACHId) ||
    (!fullAmountSelected && !partialAmountSelected);
  const savedPayment = selectedSavedCreditCardId || selectedSavedACHId;
  const filteredZipCode = omit(["zipCode"], creditCardForm.fields);
  const filteredCreditCardForm = { ...creditCardForm, fields: filteredZipCode };
  const methodForm = isNewCreditCard ? filteredCreditCardForm : achForm;
  const deniedCards = subClientPaymentConfig?.options?.deniedCards ?? [];

  const bankIsAllowedPaymentMethod = allowedPaymentMethods.includes(BANK);
  const cardIsAllowedPaymentMethod = allowedPaymentMethods.includes(CARD);

  const { metadata } = useContext(ThemeContext);
  const terms = metadata?.data?.termsAndConditions;
  const termsContent = parse(dompurify.sanitize(terms?.content));
  const termsTitle = terms?.title;

  const handleSubmit = () => {
    if (isFormEmpty) {
      return util.general.noop;
    }

    if (!savedPayment) {
      return util.general.generateClickHandler(
        methodForm,
        () => {
          setMethodErrors(true);
          handleFocusErrors(true);
        },
        () => goToNextPage()
      );
    }

    if (partialAmountSelected && isNextStepDisabled) {
      return () => {
        setAmountErrors(true);
        handleFocusErrors(true);
      };
    }

    return () => goToNextPage();
  };

  /**
   * computeFormHasErrors returns boolean indicating if the form has an error
   * @param {object} form
   * @returns {boolean}
   */
  const computeFormHasErrors = form =>
    Object.values(form.fields).reduce(
      (acc, curr) => acc || curr.hasErrors,
      false
    );

  /**
   * Compute whether the next step in checkout is allowed or not.
   */
  useEffect(() => {
    let amountFormHasErrors = false;

    if (amountEnabled && partialAmountSelected) {
      // Partial amount selected, check if partialAmountForm contains errors at this point
      const partialAmountFormHasErrors = computeFormHasErrors(
        partialAmountForm
      );
      // handle displaying form errors properly
      if (partialAmountFormHasErrors) {
        setAmountErrors(true);
      }
      // error due to partial amount being less than minimum required for partial payments
      const partialPaymentBelowMin =
        (partialPaymentMinimumInCents &&
          subtotal < partialPaymentMinimumInCents) ||
        (paymentMinimumInCents && subtotal < paymentMinimumInCents);
      // error due to partial amount being more than maximum allowed for partial payments
      const partialPaymentAboveMax =
        paymentMaximumInCentsForPaymentType &&
        subtotal > paymentMaximumInCentsForPaymentType;
      // Determine if partial amount form has errors
      amountFormHasErrors =
        partialAmountFormHasErrors ||
        partialPaymentBelowMin ||
        partialPaymentAboveMax;
    }
    // Full payment amount is selected: the below errors are unusual but possible for full payment
    else if (amountEnabled && fullAmountSelected) {
      // error due to full payment being below minimum required
      const fullPaymentBelowMin =
        paymentMinimumInCents && fullAmountTotal < paymentMinimumInCents;
      // error due to full payment being above maximum allowed
      const fullPaymentAboveMax =
        paymentMaximumInCentsForPaymentType &&
        fullAmountTotal > paymentMaximumInCentsForPaymentType;
      // Determine if partial amount form has errors
      amountFormHasErrors = fullPaymentBelowMin || fullPaymentAboveMax;
    }

    // Check if method form contains errors at this point
    const methodFormHasErrors =
      computeFormHasErrors(methodForm) &&
      !selectedSavedCreditCardId &&
      !selectedSavedACHId;

    // These steps must be wrapped in conditionals to avoid infinite re-render
    // Prevent advancing by disabling `Next` button
    if (!isNextStepDisabled && (amountFormHasErrors || methodFormHasErrors)) {
      setIsNextStepDisabled(true);
    }
    // Allow advancing when no errors
    else if (
      isNextStepDisabled &&
      !(amountFormHasErrors || methodFormHasErrors) &&
      selectedPaymentMethodType &&
      (!amountEnabled || fullAmountSelected || partialAmountSelected)
    ) {
      setIsNextStepDisabled(false);
    }
  }, [
    amountEnabled,
    fullAmountSelected,
    fullAmountTotal,
    isNextStepDisabled,
    methodForm,
    partialAmountForm,
    partialAmountSelected,
    partialPaymentMinimumInCents,
    paymentMaximumInCentsForPaymentType,
    paymentMinimumInCents,
    selectedSavedACHId,
    selectedSavedCreditCardId,
    subtotal
  ]);

  const { creditCardNumber } = creditCardForm.fields;
  const cardIconsFixture = [
    {
      img: "/Visa.svg",
      enabled:
        !creditCardNumber.dirty ||
        util.general.checkCardBrand(creditCardNumber.rawValue) == VISA,
      altText: "visa",
      name: VISA
    },
    {
      img: "/MasterCardDark.svg",
      enabled:
        !creditCardNumber.dirty ||
        util.general.checkCardBrand(creditCardNumber.rawValue) == MASTERCARD,
      altText: "master card",
      name: MASTERCARD
    },
    {
      img: "/DiscoverDark.svg",
      enabled:
        !creditCardNumber.dirty ||
        util.general.checkCardBrand(creditCardNumber.rawValue) == DISCOVER,
      altText: "discover",
      name: DISCOVER
    },
    {
      img: "/AmEx.svg",
      enabled:
        !creditCardNumber.dirty ||
        util.general.checkCardBrand(creditCardNumber.rawValue) == AMEX,
      altText: "american express",
      name: AMEX
    }
  ];
  const cardIcons = cardIconsFixture.filter(
    card => !deniedCards.includes(card.name)
  );
  const cardIconsLabel = `Accepting ${cardIcons
    .filter(ci => ci.enabled)
    .map((cardIcon, index) =>
      index === cardIcons.length - 1
        ? ` and ${cardIcon.altText}.`
        : ` ` + cardIcon.altText
    )}`;

  const maximumSubtotalWarning = (paymentType, maximum) => (
    <Cluster align="center" childGap="4px">
      <WarningIconXS />
      <Text variant="pXS" color={constants.colors.FIRE_YELLOW}>
        Maximum {paymentType} subtotal of {displayCurrency(maximum)}
        <ScreenreaderOnlyText>.</ScreenreaderOnlyText>
      </Text>
    </Cluster>
  );

  const maximumSubtotalWarningForCreditCard = maximumSubtotalWarning(
    "card",
    paymentMaximumInCentsForCreditCard
  );

  const maximumSubtotalWarningForBankAccount = maximumSubtotalWarning(
    "e-check",
    paymentMaximumInCentsForBankAccount
  );

  const titleWithWarning = ({ title, showWarning, warning }) => (
    <Stack childGap="4px">
      <Text
        as="label"
        color={showWarning ? constants.colors.FIRE_YELLOW : undefined}
      >
        {title}
        <ScreenreaderOnlyText>.</ScreenreaderOnlyText>
      </Text>
      {showWarning && warning}
    </Stack>
  );

  const maximumSubtotalWarningForPaymentMethod = () => {
    if (isCard) {
      return maximumSubtotalWarningForCreditCard;
    } else {
      return maximumSubtotalWarningForBankAccount;
    }
  };

  const paymentMethodIsDisabled = ({
    maximum,
    amountEnabled,
    paymentMethod = {},
    subtotal
  }) => {
    if (paymentMethod?.expirationStatus === EXPIRED) {
      return true;
    } else if (maximum && subtotal > maximum && !amountEnabled) {
      return true;
    } else {
      return false;
    }
  };

  const storedCards =
    savedCreditCards && Object.keys(savedCreditCards).length > 0
      ? Object.values(savedCreditCards).map(item => {
          const expireDate = `${item.expiryMonth}/${item.expiryYear}`;
          return {
            id: item.id,
            title: (
              <Cluster direction="row" align="center" nowrap childGap="16px">
                <GenericCard />
                {titleWithWarning({
                  title: `Card Number ${item.lastFour}`,
                  showWarning:
                    paymentMaximumInCentsForCreditCard &&
                    originalSubtotal > paymentMaximumInCentsForCreditCard,
                  warning: maximumSubtotalWarningForCreditCard
                })}
              </Cluster>
            ),
            dataQa: "saved-card",
            hidden: !cardIsAllowedPaymentMethod,
            disabled: paymentMethodIsDisabled({
              maximum: paymentMaximumInCentsForCreditCard,
              paymentMethod: item,
              amountEnabled,
              subtotal
            }),
            expirationStatus: item.expirationStatus,
            expireDate,
            rightTitleContent:
              !!item.expirationStatus &&
              renderCardStatus(item.expirationStatus, expireDate)
          };
        })
      : [];
  const storedBankAccounts =
    savedBankAccounts && Object.keys(savedBankAccounts).length > 0
      ? Object.values(savedBankAccounts).map(item => ({
          id: item.id,
          title: (
            <Cluster direction="row" align="center" nowrap childGap="16px">
              <BankIcon />
              {titleWithWarning({
                title: `${
                  item.accountType === "CHECKING" ? "Checking" : "Savings"
                } Account ${item.lastFour}`,
                showWarning:
                  paymentMaximumInCentsForBankAccount &&
                  originalSubtotal > paymentMaximumInCentsForBankAccount,
                warning: maximumSubtotalWarningForBankAccount
              })}
            </Cluster>
          ),
          dataQa: "saved-bank",
          hidden: !bankIsAllowedPaymentMethod,
          disabled: paymentMethodIsDisabled({
            maximum: paymentMaximumInCentsForBankAccount,
            paymentMethod: item,
            amountEnabled,
            subtotal
          })
        }))
      : [];
  const hasStoredCards = storedCards.length > 0;
  const hasStoredBankAccounts = storedBankAccounts.length > 0;
  const hasSavedPaymentMethods =
    (isLoggedIn && cardIsAllowedPaymentMethod && hasStoredCards) ||
    (bankIsAllowedPaymentMethod && hasStoredBankAccounts);
  const selectACHPaymentMethod =
    !storedBankAccounts && !cardIsAllowedPaymentMethod ? true : isNewACH;
  const selectCardPaymentMethod =
    !storedCards && !bankIsAllowedPaymentMethod ? true : isNewCreditCard;
  const cashOnly =
    !cardIsAllowedPaymentMethod &&
    !bankIsAllowedPaymentMethod &&
    allowedPaymentMethods.includes(CASH);

  const toggleMethodSection = id => {
    if (savedBankAccounts && id in savedBankAccounts) {
      selectSavedAchMethod(id);
    } else if (savedCreditCards && id in savedCreditCards) {
      selectSavedCardMethod(id);
    } else if (id === BANK_TITLE) {
      selectNewACHPaymentMethod();
    } else {
      selectNewCreditCardPaymentMethod();
    }
  };

  const toggleAmountSection = id => {
    if (id === FULL_AMOUNT && allowFullPayment) {
      selectFullAmount();
    } else if (id === PARTIAL_AMOUNT) {
      selectPartialAmount();
    }
  };

  useEffect(() => {
    if (!selectedPaymentMethodType) return;

    loadPaymentAmount();
    loadPaymentDetails();
  }, [selectedPaymentMethodType]);

  useEffect(() => {
    const paymentAmountIsSelected = partialAmountSelected || fullAmountSelected;
    if (!paymentAmountIsSelected) return;

    loadPaymentDetails();
  }, [partialAmountSelected, fullAmountSelected]);

  // Display a spinner on payment details if the user enters partial amount form input
  useEffect(() => {
    loadPaymentDetails();
  }, [partialAmountForm.fields]);

  useEffect(() => {
    if (!bankIsAllowedPaymentMethod && !hasStoredCards) {
      selectNewCreditCardPaymentMethod();
    } else if (!cardIsAllowedPaymentMethod && !hasStoredBankAccounts) {
      selectNewACHPaymentMethod();
    }
  }, [
    cardIsAllowedPaymentMethod,
    bankIsAllowedPaymentMethod,
    hasStoredBankAccounts,
    hasStoredCards
  ]);

  useEffect(() => {
    const payload = calculatePaymentMaximumInCentsForPaymentType({
      configForPaymentTypes,
      selectedPaymentMethodType,
      globalPaymentMaximumInCents: paymentMaximumInCents
    });
    updatePaymentMaximumInCentsForPaymentType(payload);
  }, [configForPaymentTypes, selectedPaymentMethodType, paymentMaximumInCents]);

  useEffect(() => {
    updatePartialAmountFormValidators();
  }, [paymentMaximumInCentsForPaymentType]);

  useEffect(() => {
    if (!selectedPaymentMethodType) return;

    if (fullAmountIsDisabled) {
      selectPartialAmount();
    } else if (!partialAmountSelected) {
      selectFullAmount();
    }
  }, [fullAmountIsDisabled, selectedPaymentMethodType, partialAmountSelected]);

  /*
    Clear all the validators in the partial amount forms and re-create
    with the updated partialAmountFormProps value, which contains the updated
    paymentMaximumInCentsForPaymentType.
  */
  const updatePartialAmountFormValidators = () => {
    const fields = partialAmountFormActions?.actions?.fields ?? {};
    lineItems.forEach(item => {
      const field = fields[item.id];
      if (!field) return;

      const validators = createPartialAmountFormValidators(
        item,
        ...partialAmountFormProps
      );

      field.clear();
      validators.forEach(validator => {
        field.addValidator(validator);
      });
    });
  };

  const formattedPartialMin = util.general
    .convertCentsToMoneyInt(partialPaymentMinimumInCents)
    .toString();
  const fullPaymentRequired =
    partialPaymentMinimumInCents && subtotal < partialPaymentMinimumInCents;
  const newPaymentMethodSections = [
    {
      id: CARD_TITLE,
      title: titleWithWarning({
        title: CARD_TITLE,
        showWarning:
          paymentMaximumInCentsForCreditCard &&
          originalSubtotal > paymentMaximumInCentsForCreditCard,
        warning: maximumSubtotalWarningForCreditCard
      }),
      dataQa: CARD_TITLE,
      disabled: paymentMethodIsDisabled({
        maximum: paymentMaximumInCentsForCreditCard,
        amountEnabled,
        subtotal
      }),
      required: true,
      rightIcons: cardIcons,
      rightIconsLabel: cardIconsLabel,
      hidden: !cardIsAllowedPaymentMethod,
      hideRadioButton: !bankIsAllowedPaymentMethod && !hasStoredCards,
      content: (
        <PaymentFormCard
          variant="checkout"
          hideTitle
          hideZipCode
          fields={creditCardForm.fields}
          {...creditCardFormActions}
          showErrors={selectCardPaymentMethod && methodErrors ? true : false}
          fees={potentialFees?.creditCard?.[0]}
          showWalletCheckbox={walletEnabled && isLoggedIn}
          saveToWallet={() =>
            saveToWallet({
              bankPayment: false,
              cardPayment: !wallet.savedWalletData.cardPayment
            })
          }
          walletCheckboxMarked={wallet.savedWalletData.cardPayment}
          deniedCards={deniedCards}
          termsContent={termsContent}
          termsTitle={termsTitle}
        />
      )
    },
    {
      id: BANK_TITLE,
      title: titleWithWarning({
        title: BANK_TITLE,
        showWarning:
          paymentMaximumInCentsForBankAccount &&
          originalSubtotal > paymentMaximumInCentsForBankAccount,
        warning: maximumSubtotalWarningForBankAccount
      }),
      dataQa: BANK_TITLE,
      disabled: paymentMethodIsDisabled({
        maximum: paymentMaximumInCentsForBankAccount,
        amountEnabled,
        subtotal
      }),
      required: true,
      hidden: !bankIsAllowedPaymentMethod,
      hideRadioButton: !cardIsAllowedPaymentMethod && !hasStoredBankAccounts,
      content: (
        <PaymentFormACH
          variant="checkout"
          fields={achForm.fields}
          allowBankAccountType={allowBankAccountType}
          {...achFormActions}
          showErrors={selectACHPaymentMethod && methodErrors ? true : false}
          fees={potentialFees?.ach?.[0]}
          showWalletCheckbox={walletEnabled && isLoggedIn}
          saveToWallet={() =>
            saveToWallet({
              bankPayment: !wallet.savedWalletData.bankPayment,
              cardPayment: false
            })
          }
          walletCheckboxMarked={wallet.savedWalletData.bankPayment}
          termsContent={termsContent}
          termsTitle={termsTitle}
        />
      )
    }
  ];

  /*
    Gets the ID of the open radio section based on the selected payment method.
  */
  const getOpenSectionId = ({
    selectedPaymentMethodType,
    cardIsAllowedPaymentMethod,
    bankIsAllowedPaymentMethod,
    hasStoredCards,
    hasStoredBankAccounts,
    isNewCreditCard,
    isNewACH,
    SAVED_CC,
    SAVED_ACH,
    NEW_CC,
    NEW_ACH
  }) => {
    const isSavedCardSelection = selectedPaymentMethodType === SAVED_CC;
    const isStoredBankSelection = selectedPaymentMethodType === SAVED_ACH;
    const forceNewCardSelection = !(
      bankIsAllowedPaymentMethod || hasStoredCards
    );
    const forceNewACHSelection = !(
      cardIsAllowedPaymentMethod || hasStoredBankAccounts
    );
    const isNewCardSelection =
      isNewCreditCard ||
      forceNewCardSelection ||
      selectedPaymentMethodType === NEW_CC;
    const isNewACHSelection =
      isNewACH || forceNewACHSelection || selectedPaymentMethodType === NEW_ACH;

    if (isNewACHSelection) {
      return BANK_TITLE;
    } else if (isNewCardSelection) {
      return CARD_TITLE;
    } else if (isSavedCardSelection) {
      return selectedSavedCreditCardId;
    } else if (isStoredBankSelection) {
      return selectedSavedACHId;
    } else {
      return false;
    }
  };

  const openSectionId = getOpenSectionId({
    selectedPaymentMethodType,
    cardIsAllowedPaymentMethod,
    bankIsAllowedPaymentMethod,
    hasStoredCards,
    hasStoredBankAccounts,
    isNewCreditCard,
    isNewACH,
    SAVED_CC,
    SAVED_ACH,
    NEW_CC,
    NEW_ACH
  });

  useEffect(() => {
    // If this is the landing page for checkout, we use the Customer Information screen to
    // house the Receipt Email form, since the Contact screen has been skipped
    useCustomerInformationEmail(walletScreenV2Enabled && currentTabIndex === 0);
  }, [currentTabIndex, walletScreenV2Enabled]);

  return (
    <PaymentAmountMethodContainer>
      <Cluster justify="space-between" align="center" nowrap>
        <Box
          padding={isMobile ? "0 0 1rem" : "1rem 0"}
          width={isMobile ? "60%" : "100%"}
        >
          <Title
            weight={constants.fontWeights.FONT_WEIGHT_BOLD}
            extraStyles={`font-size: 1.75rem;`}
            id="checkout-section-title"
            as="h1"
          >
            Payment Methods
          </Title>
        </Box>
        {isMobile && (
          <Box padding={isMobile ? "0 0 1rem 0" : "1rem 0"} width={"40%"}>
            {stepCounter}
          </Box>
        )}
      </Cluster>
      {cashOnly ? (
        <Box
          padding="0"
          extraStyles={`margin: ${isMobile ? "0 0 1.5rem" : "0 0 2.5rem"}`}
        >
          <Alert
            showQuitLink={false}
            variant="warn"
            text={`This account is restricted from making payments using bank accounts and credit/debit cards. You can only pay with cash. Please contact support for more information.`}
          />
        </Box>
      ) : (
        <Module
          padding={isMobile ? "1rem" : "1.5rem"}
          spacingBottom={"0"}
          margin={isMobile ? "0 0 1.5rem" : "0 0 2.5rem"}
        >
          {hasSavedPaymentMethods && !isGuestCheckout && (
            <Fragment>
              <RadioSection
                isSectionRequired={true}
                groupedSections={[
                  storedCards.concat(storedBankAccounts),
                  newPaymentMethodSections
                ]}
                toggleOpenSection={id => toggleMethodSection(id)}
                openSection={openSectionId}
                staggeredAnimation
                isMobile={isMobile}
                initiallyOpen={false}
                containerStyles={`border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                  border-top-width: 0;

                  &:nth-child(1) {
                    border-top-width: 1px;
                  }
                `}
                aria-labelledby="checkout-section-title"
              />
            </Fragment>
          )}
          {(!hasSavedPaymentMethods || isGuestCheckout) && (
            <RadioSection
              isSectionRequired={true}
              sections={newPaymentMethodSections}
              toggleOpenSection={id => toggleMethodSection(id)}
              openSection={openSectionId}
              staggeredAnimation
              isMobile={isMobile}
              initiallyOpen={false}
              aria-labelledby="checkout-section-title"
            />
          )}
        </Module>
      )}
      {hasPaymentFlag &&
        (!cardIsAllowedPaymentMethod || !bankIsAllowedPaymentMethod) && (
          <Alert
            showQuitLink={false}
            variant="warn"
            text={`This account is restricted from making payments using ${
              !bankIsAllowedPaymentMethod
                ? `checking accounts`
                : `credit/debit cards`
            }. For more information, please contact support using the link below.`}
          />
        )}
      <div role="alert" aria-atomic={true}>
        {isCard &&
          !!potentialFees?.creditCard?.[0]?.value &&
          !paymentAmountIsLoading && (
            <Alert
              heading={`Card Processing Fee - ${displayCurrency(
                calculateFeeAmount(potentialFees?.creditCard?.[0], subtotal)
              )}`}
              text={`There is a processing fee of ${
                potentialFees.creditCard[0].type === "FLAT"
                  ? `${displayCurrency(potentialFees.creditCard[0].value)}`
                  : `${formatPercent(potentialFees.creditCard[0].value * 100)}%`
              } ${
                !!potentialFees.creditCard[0].minimumInCents
                  ? `with a minimum of ${displayCurrency(
                      potentialFees.creditCard[0].minimumInCents
                    )}`
                  : ""
              } on all card payments.`}
              variant="info"
              showQuitLink={false}
            />
          )}
      </div>
      <div role="alert" aria-atomic={true}>
        {isACH &&
          !!potentialFees?.ach?.[0]?.value &&
          !paymentAmountIsLoading && (
            <Alert
              heading={`ACH Processing Fee - ${displayCurrency(
                calculateFeeAmount(potentialFees?.ach?.[0], subtotal)
              )}`}
              text={`There is a processing fee of ${
                potentialFees.ach[0].type === "FLAT"
                  ? `${displayCurrency(potentialFees.ach[0].value)}`
                  : `${formatPercent(potentialFees.ach[0].value * 100)}%`
              } ${
                potentialFees.ach[0].minimumInCents
                  ? `with a minimum of ${displayCurrency(
                      potentialFees.ach[0].minimumInCents
                    )}`
                  : ""
              } on all checking account payments.`}
              variant="info"
              showQuitLink={false}
            />
          )}
      </div>
      {amountEnabled && (
        <Fragment>
          <Box padding="0">
            {paymentAmountIsLoading && (
              <Box
                padding="0"
                extraStyles={`position: absolute;
                  height: 238px;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;`}
              >
                <Spinner size="100" centerSpinner />
              </Box>
            )}
            <Module
              heading={"Payment Amount"}
              disabled={!selectedPaymentMethodType || paymentAmountIsLoading}
              spacing={isMobile ? "1.5rem" : "2.5rem"}
              spacingBottom={"0"}
              padding={isMobile ? "1rem" : "1.5rem"}
            >
              <RadioSection
                isSectionRequired={true}
                sections={[
                  {
                    id: FULL_AMOUNT,
                    title: titleWithWarning({
                      title: FULL_AMOUNT,
                      showWarning:
                        paymentMaximumInCentsForPaymentType &&
                        originalSubtotal > paymentMaximumInCentsForPaymentType,
                      warning: maximumSubtotalWarningForPaymentMethod()
                    }),
                    disabled: fullAmountIsDisabled,
                    dataQa: "full-amount",
                    required: true
                  },
                  {
                    id: PARTIAL_AMOUNT,
                    title: PARTIAL_AMOUNT,
                    content: (
                      <PartialAmountForm
                        variant="checkout"
                        lineItems={lineItems}
                        maximum={paymentMaximumInCentsForPaymentType}
                        minimum={paymentMinimumInCents}
                        fields={{ ...partialAmountForm.fields }}
                        {...partialAmountFormActions}
                        showErrors={amountErrors}
                        blockPartialPaymentOverpay={blockPartialPaymentOverpay}
                      />
                    ),
                    disabled:
                      !selectedPaymentMethodType ||
                      fullPaymentRequired ||
                      paymentAmountIsLoading,
                    dataQa: "partial-amount",
                    required: true
                  }
                ]}
                toggleOpenSection={id => toggleAmountSection(id)}
                openSection={
                  selectedPaymentMethodType && fullAmountSelected
                    ? FULL_AMOUNT
                    : partialAmountSelected
                    ? PARTIAL_AMOUNT
                    : false
                }
                staggeredAnimation
                initiallyOpen={false}
                isMobile={isMobile}
              />
            </Module>
          </Box>
          {fullPaymentRequired && !partialAmountSelected && (
            <Box
              padding={isMobile ? "1.5rem 0 0" : "2.5rem 0 0"}
              role="alert"
              aria-atomic={true}
            >
              <Alert
                variant="info"
                showQuitLink={false}
                onLinkClick={util.general.noop}
                heading={"Full Payment Required"}
                text={`Balances under $${formattedPartialMin} must be paid in full.`}
              />
            </Box>
          )}
          <Box
            padding={isMobile ? "1.5rem 0 0" : "2.5rem 0 0"}
            role="alert"
            aria-atomic={true}
          >
            <MinMaxPaymentAmountInfoAlert
              {...{
                paymentMaximumInCents:
                  paymentMaximumInCentsForPaymentType < subtotal
                    ? paymentMaximumInCentsForPaymentType
                    : undefined,
                paymentMinimumInCents,
                partialPaymentMinimumInCents,
                partialAmountSelected
              }}
            />
          </Box>
        </Fragment>
      )}
      <PaymentButtonBar
        nextButtonTestId="Next"
        backButtonTestId="Back"
        cancelURL={!amountEnabled && cancelURL?.url}
        cancelText={!amountEnabled && cancelURL?.label}
        backButtonAction={
          !amountEnabled && isInvoice
            ? null
            : !amountEnabled && cartEnabled
            ? () => navigate("/cart")
            : () => navigate(-1)
        }
        hideForwardButton={cashOnly}
        forwardButtonAction={handleSubmit()}
        forwardButtonAriaRole="link"
        forwardButtonText={
          walletScreenV2Enabled ? "Next: Billing information" : "Next"
        }
        isForwardButtonDisabled={isNextStepDisabled}
      />
    </PaymentAmountMethodContainer>
  );
};

export default React.memo(PaymentAmountMethod);
