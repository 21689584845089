import React from "react";

import Box from "../layouts/Box";
import Title from "../title";

export const CardHeader = ({
  backgroundColor,
  borderRadius,
  as,
  headerText,
  headerColor,
  headerVariant,
  padding
}) => {
  return (
    <Box
      padding={padding}
      background={backgroundColor}
      borderRadius={`${borderRadius} ${borderRadius} 0 0`}
    >
      <Title as={as} variant={headerVariant} color={headerColor}>
        {headerText}
      </Title>
    </Box>
  );
};

export default CardHeader;
