import React, { Fragment } from "react";
import { safeChildren } from "../../../util/general";
import { ReelStyled } from "./Reel.styled";

/*
  Safari has a bug where it refuses to recognize <ul /> tags as lists when list-style: none is used
  This affects the apple voice over (mainly used on iOS devices)
  The solution is to explicitly include "role=List"
*/

const Reel = ({
  childGap = "1rem",
  height = "auto",
  childWidth = "auto",
  padding = "1rem",
  justifyContent = "flex-start",
  disableScroll = false,
  useOrderedList = false,
  useUnorderedList = false,
  children,
  ...rest
}) => {
  const elementType = useOrderedList ? "ol" : useUnorderedList ? "ul" : "div";
  return (
    <ReelStyled
      childGap={childGap}
      height={height}
      childWidth={childWidth}
      padding={padding}
      justifyContent={justifyContent}
      disableScroll={disableScroll}
      as={elementType}
      useOrderedList={useOrderedList}
      useUnorderedList={useUnorderedList}
      {...rest}
      role={useOrderedList || useUnorderedList ? "list" : "section"}
    >
      {safeChildren(children, <Fragment />)}
    </ReelStyled>
  );
};

export default Reel;
