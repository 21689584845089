import React, { useContext } from "react";
import { Box, withWindowSize, constants } from "@thecb/components";
import { ThemeContext } from "styled-components";
import HeaderItem from "./HeaderItem";

export const NavLabel = ({ text, navLevel, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);
  // only for mobile right now
  const styles = [
    {
      variant: "pL",
      fontWeight: constants.fontWeights.FONT_WEIGHT_SEMIBOLD,
      padding: "8px 2rem"
    },
    {
      variant: "p",
      fontWeight: constants.fontWeights.FONT_WEIGHT_SEMIBOLD,
      padding: "0 24px"
    }
  ];

  return (
    <Box padding={styles[navLevel].padding}>
      <HeaderItem
        name={text}
        showArrow={false}
        themeValues={themeValues}
        color={themeValues.linkColor}
        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
        variant={styles[navLevel].variant}
      />
    </Box>
  );
};

export default withWindowSize(NavLabel);
