import {
  WHITE,
  BRIGHT_GREY,
  SEA_GREEN,
  BLUSH_RED,
  ROYAL_BLUE_VIVID
} from "../../../constants/colors";

const primaryColor = {
  info: `${WHITE}`,
  success: `${WHITE}`,
  error: `${WHITE}`
};

const accentColor = {
  info: `${BRIGHT_GREY}`,
  success: `${BRIGHT_GREY}`,
  error: `${BRIGHT_GREY}`
};

const subIconColor = {
  info: `${ROYAL_BLUE_VIVID}`,
  success: `${SEA_GREEN}`,
  error: `${BLUSH_RED}`
};

const singleIconColor = {
  primary: `${ROYAL_BLUE_VIVID}`,
  secondary: `${BRIGHT_GREY}`,
  darkMode: `${WHITE}`
};

export const fallbackValues = {
  primaryColor,
  accentColor,
  subIconColor,
  singleIconColor
};
