import React, { useState } from "react";
import Checkbox from "../../atoms/checkbox";
import { Box, Stack, Cluster } from "../../atoms/layouts";
import Text from "../../atoms/text";
import TermsAndConditionsModal from "../terms-and-conditions-modal";
import {
  ATHENS_GREY,
  CHARADE_GREY,
  ERROR_COLOR
} from "../../../constants/colors";
import { generateShadows } from "../../../util/generateShadows";
import { useScrollTo } from "../../../hooks";

const TermsAndConditionsTitleDivId = "terms-and-conditions-title";

const TermsAndConditionsControlV2 = ({
  showCheckbox = true,
  onCheck,
  isChecked,
  hasError,
  errorMessage = "Please accept Terms and Conditions",
  description = "",
  linkText = "Terms and Conditions",
  html,
  terms,
  id = "terms-and-conditions",
  displayInline = true,
  modalVariant = "default",
  containerBackground = ATHENS_GREY,
  checkboxMargin = "4px 8px 4px 4px",
  modalTitle = "Terms and Conditions",
  initialFocusSelector = ""
}) => {
  const [showTerms, toggleShowTerms] = useState(false);
  const standardBoxShadow = generateShadows().standard.base;

  const toggleTerms = termsOpen => {
    toggleShowTerms(termsOpen);
    if (termsOpen) {
      useScrollTo("terms-body-text", 0, 0, "smooth", 100);
    }
  };

  return (
    <Box
      padding={displayInline ? "0" : "1.5rem"}
      minWidth={displayInline ? "0" : "100%"}
      background={displayInline ? "transparent" : containerBackground}
      boxShadow={displayInline ? "none" : standardBoxShadow}
      borderRadius={displayInline ? "0" : "4px"}
    >
      <Stack childGap="0">
        {html && <Box padding="0">{html}</Box>}
        <Cluster justify="flex-start" align="center" nowrap>
          {showCheckbox && (
            <Checkbox
              name={id}
              error={hasError}
              checked={isChecked}
              onChange={onCheck}
              checkboxMargin={checkboxMargin}
              extraStyles={`align-self: flex-start;`}
              labelledById={TermsAndConditionsTitleDivId}
            />
          )}
          <Stack childGap="0.25rem" fullHeight>
            <Cluster
              justify="flex-start"
              align="center"
              nowrap
              extraStyles={`padding-right: 2px; > div > * { margin: 4px 2px; };`}
              id={TermsAndConditionsTitleDivId}
            >
              {description && <Text color={CHARADE_GREY}>{description}</Text>}
              {terms && (
                <TermsAndConditionsModal
                  link={linkText}
                  terms={terms}
                  isOpen={showTerms}
                  toggleOpen={toggleTerms}
                  linkVariant={modalVariant}
                  title={modalTitle}
                  initialFocusSelector={initialFocusSelector}
                />
              )}
            </Cluster>
            <div aria-live="polite" aria-atomic={true}>
              {showCheckbox && hasError && (
                <Text
                  variant="pXS"
                  color={ERROR_COLOR}
                  id={`${id}-error-message`}
                >
                  {errorMessage}
                </Text>
              )}
            </div>
          </Stack>
        </Cluster>
      </Stack>
    </Box>
  );
};

export default TermsAndConditionsControlV2;
