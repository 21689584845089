import React, { Fragment, useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Module,
  Box,
  Cluster,
  Stack,
  Text,
  Paragraph,
  ButtonWithAction,
  PaymentButtonBar,
  WalletIcon,
  util
} from "@thecb/components";
import Slider from "../../../../../../components/slider";
import SliderPanel from "../../../../../../components/slider-panel";
import LoginPanel from "./LoginPanel";
import VerifyAccountPanel from "./VerifyAccountPanel";
import ForgotPasswordPanel from "./ForgotPasswordPanel";
import ConfirmForgotPasswordPanel from "./ConfirmForgotPasswordPanel";
import { fallbackValues } from "./PaymentWallet.theme";

const PaymentWallet = ({
  navigate,
  goToNextPage,
  wallet,
  walletLoginForm,
  walletLoginFormActions,
  walletLoginAlertBar,
  walletLoginAlertBarActions,
  walletVerifyAlertBar,
  walletVerifyAlertBarActions,
  openWalletSlider,
  closeWalletSlider,
  changePanel,
  submitLoginForm,
  resendVerification,
  isLoggedIn,
  guestCheckout,
  submitForgotPasswordForm,
  walletForgotPasswordForm,
  walletForgotPasswordFormActions,
  walletForgotPasswordAlertBar,
  walletForgotPasswordAlertBarActions,
  isInvoice,
  isMobile,
  cancelURL,
  cartEnabled,
  handleFocusErrors,
  clearPaymentContactInformation,
  stepCounter
}) => {
  const { sliderOpen, panels, submitting, walletEmail } = wallet;
  const themeContext = useContext(ThemeContext);
  const { metadata } = themeContext;
  const themeValues = util.theme.createThemeValues(
    themeContext,
    fallbackValues,
    "PaymentWallet"
  );
  const clientName = metadata?.data?.clientDisplayName ?? "";

  return (
    <Fragment>
      <Module
        heading="Express Checkout"
        padding="0"
        spacing={!isMobile ? "1rem" : "0"}
        spacingBottom="1.5rem"
        as="h2"
        fontSize="1.5rem"
        titleID="checkout-section-title"
        rightTitleContent={isMobile && stepCounter}
      >
        <Box
          background={themeValues.bannerBackgroundColor}
          padding={isMobile ? "1rem" : "2.5rem 1.5rem"}
          minWidth="100%"
        >
          <Stack
            justify="flex-start"
            direction="row"
            childGap="1rem"
            fullHeight
          >
            <Box
              padding="0"
              extraStyles={`display: flex; flex: 1; svg { min-width: 100%; }`}
            >
              <WalletIcon />
            </Box>
            <Box padding="0" extraStyles={`display: flex; flex: 1;`}>
              <Stack>
                <Cluster justify="flex-start" align="center">
                  {isLoggedIn ? (
                    <Fragment>
                      {walletEmail && (
                        <Paragraph>
                          Hello{" "}
                          <Text extraStyles={`font-style: italic;`}>
                            {walletEmail}
                          </Text>
                        </Paragraph>
                      )}
                      <Paragraph>
                        Use your saved payment methods, or add new ones with{" "}
                        {clientName} Wallet for a quicker checkout!
                      </Paragraph>
                    </Fragment>
                  ) : (
                    <Text>
                      Pay quicker using saved payment methods and personal
                      information using {clientName} Wallet.
                    </Text>
                  )}
                </Cluster>
                {!isMobile && (
                  <ButtonWithAction
                    text="Check out with Wallet"
                    variant="primary"
                    dataQa={"Check out with Wallet"}
                    action={() => {
                      isLoggedIn ? goToNextPage() : openWalletSlider();
                    }}
                  />
                )}
              </Stack>
            </Box>
          </Stack>
          {isMobile && (
            <ButtonWithAction
              text="Check out with Wallet"
              variant="smallPrimary"
              dataQa={"Check out with Wallet"}
              action={() => {
                isLoggedIn ? goToNextPage() : openWalletSlider();
              }}
              extraStyles={`width: 100%; margin-top: 1rem;`}
            />
          )}
        </Box>
      </Module>
      <Module
        heading="Guest Checkout"
        padding={isMobile ? "1rem" : "1.5rem"}
        spacing="0"
        spacingBottom="0"
        as="h2"
        fontSize="1.5rem"
      >
        <Cluster justify="center" align="center" childGap="1rem">
          <Box
            padding="0"
            extraStyles={`display: flex; ${
              isMobile ? `width: 100%` : `flex: 1;`
            }`}
          >
            {isLoggedIn ? (
              <Paragraph>
                Make a payment without your previously saved payment methods and
                addresses.
              </Paragraph>
            ) : (
              <Paragraph>
                You can create a wallet after you finish checking out
              </Paragraph>
            )}
          </Box>
          <Box
            padding="0"
            extraStyles={`display: flex; ${
              isMobile ? `width: 100%` : `flex: 1;`
            }`}
          >
            <ButtonWithAction
              text="Check out as Guest"
              variant={isMobile ? "smallSecondary" : "secondary"}
              dataQa={"Check out as Guest"}
              action={() => {
                if (isLoggedIn) {
                  clearPaymentContactInformation();
                }
                guestCheckout();
                goToNextPage();
              }}
              aria-labelledby="guest-checkout-button"
              role="link"
              extraStyles={`width: 100%;`}
            />
          </Box>
        </Cluster>
      </Module>
      <PaymentButtonBar
        backButtonAction={
          isInvoice && cancelURL?.url
            ? () => (window.location = cancelURL?.url)
            : cartEnabled
            ? () => navigate("/cart")
            : () => navigate(-1)
        }
        cancelURL={cancelURL?.url}
        cancelText={cancelURL?.label}
        backButtonVariant={isMobile ? "smallSecondary" : "secondary"}
        hideBackButton={isInvoice && !cancelURL}
      />

      <Slider isOpen={sliderOpen}>
        <SliderPanel status={panels.login.status} key="login-panel">
          <LoginPanel
            walletForm={walletLoginForm}
            walletFormActions={walletLoginFormActions}
            submitLoginForm={submitLoginForm}
            submitting={submitting}
            closeSlider={closeWalletSlider}
            panelIsOpen={sliderOpen && panels.login.status === "onScreen"}
            walletLoginAlertBar={walletLoginAlertBar}
            walletLoginAlertBarActions={walletLoginAlertBarActions}
            changePanel={changePanel}
            handleFocusErrors={handleFocusErrors}
            themeValues={themeValues}
          />
        </SliderPanel>
        <SliderPanel
          status={panels.verifyAccount.status}
          key="verify-account-panel"
        >
          <VerifyAccountPanel
            closeSlider={closeWalletSlider}
            panelIsOpen={
              sliderOpen && panels.verifyAccount.status === "onScreen"
            }
            changePanel={changePanel}
            email={walletEmail}
            resendVerification={resendVerification}
            walletVerifyAlertBar={walletVerifyAlertBar}
            walletVerifyAlertBarActions={walletVerifyAlertBarActions}
          />
        </SliderPanel>
        <SliderPanel
          status={panels.forgotPassword.status}
          key="forgot-password-panel"
        >
          <ForgotPasswordPanel
            closeSlider={closeWalletSlider}
            panelIsOpen={
              sliderOpen && panels.forgotPassword.status === "onScreen"
            }
            changePanel={changePanel}
            submitForgotPasswordForm={submitForgotPasswordForm}
            walletForgotPasswordForm={walletForgotPasswordForm}
            walletForgotPasswordFormActions={walletForgotPasswordFormActions}
            submitting={submitting}
            walletForgotPasswordAlertBar={walletForgotPasswordAlertBar}
            walletForgotPasswordAlertBarActions={
              walletForgotPasswordAlertBarActions
            }
            handleFocusErrors={handleFocusErrors}
            themeValues={themeValues}
          />
        </SliderPanel>
        <SliderPanel
          status={panels.confirmForgotPassword.status}
          key="confirm-forgot-password-panel"
        >
          <ConfirmForgotPasswordPanel
            closeSlider={closeWalletSlider}
            panelIsOpen={
              sliderOpen && panels.confirmForgotPassword.status === "onScreen"
            }
            changePanel={changePanel}
            themeValues={themeValues}
          />
        </SliderPanel>
      </Slider>
    </Fragment>
  );
};

export default React.memo(PaymentWallet);
