import React from "react";

const ChargebackIconMedium = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ChargebackIconMedium-${iconIndex}`;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="7"
        width="17"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99999 8C7.99999 7.44772 8.4477 7 8.99999 7H19C19.5523 7 20 7.44772 20 8V10V12V16C20 16.5523 19.5523 17 19 17H8.99999C8.4477 17 7.99999 16.5523 7.99999 16V12V10V8ZM19.25 7.83333V10H8.74999V9.91667V7.83333H19.25ZM8.74999 16.1667V12H19.25V16.1667H8.74999ZM5.79295 10.2071C5.79295 9.76165 5.25438 9.53857 4.9394 9.85355L3.14651 11.6464C2.95125 11.8417 2.95125 12.1583 3.14651 12.3536L4.9394 14.1464C5.25438 14.4614 5.79295 14.2383 5.79295 13.7929V10.2071ZM16.1875 14C15.9113 14 15.6875 14.2239 15.6875 14.5C15.6875 14.7761 15.9113 15 16.1875 15H17.4375C17.7136 15 17.9375 14.7761 17.9375 14.5C17.9375 14.2239 17.7136 14 17.4375 14H16.1875Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="3" y="3" width="18" height="18" fill="white" />
      </g>
    </svg>
  );
};

export default ChargebackIconMedium;
