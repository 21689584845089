/* These are constants used by nav frontend components */
const MAX_CONTENT_WIDTH = "1224px";

const HEADER_HEIGHT = "104px";
const FOOTER_HEIGHT = "100px";
const SPACER_HEIGHT = "65px";
const JUMBO_HEIGHT = "300px";
const COMPACT_JUMBO_HEIGHT = "65px";
const FONT_WEIGHT_REGULAR = "400";
const FONT_WEIGHT_BOLD = "700";
const FONT_WEIGHT_SEMIBOLD = "600";
const SOFT_LAYERED_SHADOW = `
    0 0 0 rgba(41, 42, 51, 0.1),
    0 1px 2px rgba(41, 42, 51, 0.1),
    0 3px 3px rgba(41, 42, 51, 0.09),
    0 7px 4px rgba(41, 42, 51, 0.05),
    0 13px 5px rgba(41, 42, 51, 0.01),
    0 20px 6px rgba(41, 42, 51, 0);
`;

export {
  MAX_CONTENT_WIDTH,
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  SPACER_HEIGHT,
  JUMBO_HEIGHT,
  COMPACT_JUMBO_HEIGHT,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_SEMIBOLD,
  SOFT_LAYERED_SHADOW
};
