import React from "react";

const DashIcon = ({ size = 20 }) => {
  const height = size;
  const width = height * 1.05;
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.70959" y="9" width="12" height="2" rx="0.5" fill="#3B414D" />
    </svg>
  );
};

export default DashIcon;
