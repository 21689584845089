import React, { useRef, useState, useLayoutEffect, Fragment } from "react";
import { GridWrapper } from "./Grid.styled";
import { safeChildren } from "../../../util/general";

const Grid = ({
  columnGap,
  minColWidth = 20,
  maxColWidth = "1fr",
  children,
  ...rest
}) => {
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const [aboveMin, setAboveMin] = useState(true);
  useLayoutEffect(() => {
    const currentWidth = ref.current.getBoundingClientRect().width;
    setWidth(currentWidth);
    setAboveMin(currentWidth >= minColWidth * 16);
  }, [ref.current, width]);
  return (
    <GridWrapper
      ref={ref}
      columnGap={columnGap}
      aboveMin={aboveMin}
      minColWidth={
        typeof minColWidth === "number" ? `${minColWidth}rem` : minColWidth
      }
      maxColWidth={
        typeof maxColWidth === "number" ? `${maxColWidth}rem` : maxColWidth
      }
      {...rest}
    >
      {safeChildren(children, <Fragment />)}
    </GridWrapper>
  );
};

export default Grid;
