import React from "react";
import { Box, Stack } from "../../../atoms/layouts";
import Title from "../../../atoms/title";
import Detail from "../../../atoms/detail";
import { BLACK, CHARADE_GREY, ZEST_ORANGE } from "../../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";

const InactiveTitleModule = ({ title, subtitle, autoPayEnabled }) => (
  <Box padding="0">
    <Stack childGap="0">
      <Title
        variant="small"
        as="h4"
        color={CHARADE_GREY}
        weight={FONT_WEIGHT_SEMIBOLD}
      >
        {title}
      </Title>
      <Detail variant="small" as="h5" color={CHARADE_GREY}>
        {subtitle}
      </Detail>
      <Detail
        variant="small"
        as="p"
        color={ZEST_ORANGE}
        weight={FONT_WEIGHT_SEMIBOLD}
      >
        Unable to load account details
      </Detail>
      <Detail variant="extraSmall" as="p" color={BLACK}>
        {`This may mean that the balance has been paid, or there was an error loading it.${
          autoPayEnabled
            ? " You may disable autopay for this account by pressing the 'Turn off Autopay' button."
            : ""
        }`}
      </Detail>
    </Stack>
  </Box>
);

export default InactiveTitleModule;
