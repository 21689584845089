import React from "react";

const FailedIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -400.000000)"
        fill="#D11053"
      >
        <path
          d="M80,432 C88.836556,432 96,424.836556 96,416 C96,407.163444 88.836556,400 80,400 C71.163444,400 64,407.163444 64,416 C64,424.836556 71.163444,432 80,432 Z M75.4038059,409.989592 L80.0001458,414.586146 L84.5961941,409.989592 C84.7914562,409.79433 85.1080387,409.79433 85.3033009,409.989592 L86.0104076,410.696699 C86.2056698,410.891961 86.2056698,411.208544 86.0104076,411.403806 L81.4141458,416.000146 L86.0104076,420.596194 C86.2056698,420.791456 86.2056698,421.108039 86.0104076,421.303301 L85.3033009,422.010408 C85.1080387,422.20567 84.7914562,422.20567 84.5961941,422.010408 L80.0001458,417.414146 L75.4038059,422.010408 C75.2085438,422.20567 74.8919613,422.20567 74.6966991,422.010408 L73.9895924,421.303301 C73.7943302,421.108039 73.7943302,420.791456 73.9895924,420.596194 L78.5861458,416.000146 L73.9895924,411.403806 C73.7943302,411.208544 73.7943302,410.891961 73.9895924,410.696699 L74.6966991,409.989592 C74.8919613,409.79433 75.2085438,409.79433 75.4038059,409.989592 Z"
          id="status-icon---failed"
        ></path>
      </g>
    </g>
  </svg>
);

export default FailedIcon;
