import {
  APRICOT_ORANGE,
  INFO_BLUE,
  BLUSH_RED,
  HINT_GREEN,
  ZEST_ORANGE,
  MATISSE_BLUE,
  RAZZMATAZZ_RED,
  EMERALD_GREEN,
  MUSTARD_YELLOW,
  ONAHAU_BLUE,
  COSMOS_RED,
  ICE_GREEN,
  CARROT_ORANGE,
  CONGRESS_BLUE,
  THUNDERBIRD_RED,
  HAZE_GREEN,
  ZODIAC_BLUE,
  SCIENCE_BLUE
} from "../../../constants/colors";

const background = {
  info: `${INFO_BLUE}`,
  warn: `${APRICOT_ORANGE}`,
  error: `${BLUSH_RED}`,
  success: `${HINT_GREEN}`
};

const border = {
  info: `${MATISSE_BLUE}`,
  warn: `${ZEST_ORANGE}`,
  error: `${RAZZMATAZZ_RED}`,
  success: `${EMERALD_GREEN}`
};

const iconBackground = {
  info: `${ONAHAU_BLUE}`,
  warn: `${MUSTARD_YELLOW}`,
  error: `${COSMOS_RED}`,
  success: `${ICE_GREEN}`
};

const iconFill = {
  info: `${CONGRESS_BLUE}`,
  warn: `${CARROT_ORANGE}`,
  error: `${THUNDERBIRD_RED}`,
  success: `${HAZE_GREEN}`
};

const text = {
  info: `${ZODIAC_BLUE}`,
  warn: `${ZODIAC_BLUE}`,
  error: `${ZODIAC_BLUE}`,
  success: `${ZODIAC_BLUE}`
};

const link = {
  info: `${SCIENCE_BLUE}`,
  warn: `${SCIENCE_BLUE}`,
  error: `${SCIENCE_BLUE}`,
  success: `${SCIENCE_BLUE}`
};

export const fallbackValues = {
  background,
  border,
  iconBackground,
  iconFill,
  text,
  link
};
