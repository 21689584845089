import React, { useState } from "react";
import {
  EditableListItem,
  EditableListItemControls
} from "./EditableList.styled";
import { Box, Stack } from "../../atoms/layouts";
import Placeholder from "../../atoms/placeholder";
import ButtonWithAction from "../../atoms/button-with-action";
import Text from "../../atoms/text";
import Title from "../../atoms/title";
import {
  STORM_GREY,
  BOSTON_BLUE,
  CHARADE_GREY
} from "../../../constants/colors";
import { createIdFromString } from "../../../util/general";

const ACTIVE = "ACTIVE";
const EXPIRED = "EXPIRED";

const EditableList = ({
  title = "",
  titleWeight = "400",
  canAdd = true,
  addItem,
  removeItem,
  editItem,
  itemName,
  renderItem,
  items,
  canEdit = true,
  canRemove = true,
  listItemSize = "small",
  maxItems,
  useModal = false,
  modal: Modal,
  modalProps,
  autoPayMethods,
  as = "p",
  listPadding = "0rem 0rem 1.5rem 0rem",
  qaPrefix,
  ariaLabel,
  editItemAriaRole = "",
  disablePlaceholder = false
}) => {
  const addText = `Add a${
    itemName[0].match(/[aieouAIEOU]/) ? "n" : ""
  } ${itemName}`;
  return (
    <Box
      padding={listPadding}
      as="section"
      aria-labelledby={
        typeof title === "string"
          ? `editable-list-${createIdFromString(title)}`
          : ""
      }
      aria-label={
        !title && typeof itemName === "string"
          ? `editable-list-${createIdFromString(itemName)}`
          : ""
      }
    >
      <Stack childGap="0rem">
        {title !== "" && (
          <Box padding="0rem 0rem 0.5rem 0rem">
            <Title
              as={as}
              weight={titleWeight}
              color={CHARADE_GREY}
              extraStyles="letter-spacing: 0.29px; font-size: 1.125rem;"
              id={
                typeof title === "string"
                  ? `editable-list-${createIdFromString(title)}`
                  : ""
              }
            >
              {title}
            </Title>
          </Box>
        )}
        <Box
          padding="0"
          borderRadius="4px"
          extraStyles={`box-shadow: 0px 2px 14px 0px rgb(246, 246, 249),
    0px 3px 8px 0px rgb(202, 206, 216);`}
        >
          {items.map(item => {
            const [modalOpen, toggleModal] = useState(false);
            const expiredItem = item?.expirationStatus ?? ACTIVE;
            return (
              <EditableListItem
                listItemSize={
                  !!item.id &&
                  autoPayMethods?.some(methodID => methodID === item.id)
                    ? "big"
                    : listItemSize
                }
                key={item.id || item}
                disabled={expiredItem === EXPIRED}
              >
                <Text variant="p" color={CHARADE_GREY}>
                  {!!renderItem &&
                    typeof renderItem === "function" &&
                    renderItem(item)}
                </Text>
                <EditableListItemControls>
                  {item.isPrimary && (
                    <Text
                      variant="p"
                      color={STORM_GREY}
                      extraStyles={`font-style: italic;`}
                      key={`Default ${itemName}`}
                    >
                      Default {itemName}
                    </Text>
                  )}
                  {canRemove && (
                    <Box
                      padding="0 0.5rem"
                      border="2px solid transparent"
                      extraStyles={`:not(:first-child) { border-left: 2px solid ${BOSTON_BLUE};}`}
                      dataQa={qaPrefix + " Remove"}
                      key={`Remove ${item.id}`}
                    >
                      {useModal ? (
                        <Modal
                          item={{ ...item }}
                          {...modalProps}
                          modalOpen={modalOpen}
                          toggleModal={toggleModal}
                        />
                      ) : (
                        <ButtonWithAction
                          variant="smallGhost"
                          text="Remove"
                          action={() => removeItem(item.id)}
                          extraStyles={`min-width: 0;`}
                          aria-label={`Remove ${ariaLabel || itemName}`}
                        />
                      )}
                    </Box>
                  )}
                  {canEdit && (
                    <Box
                      padding="0 0.5rem"
                      border="2px solid transparent"
                      extraStyles={`:not(:first-child) { border-left: 2px solid ${BOSTON_BLUE};}`}
                      dataQa={qaPrefix + " Edit"}
                      key={`Edit ${item.id}`}
                    >
                      <ButtonWithAction
                        variant="smallGhost"
                        text="Edit"
                        action={() => editItem(item.id)}
                        extraStyles={`min-width: 0;`}
                        aria-label={`Edit ${ariaLabel || itemName}`}
                        role={editItemAriaRole}
                      />
                    </Box>
                  )}
                </EditableListItemControls>
              </EditableListItem>
            );
          })}
        </Box>
        {canAdd && (!maxItems || items.length < maxItems) && (
          <Box padding={items.length === 0 ? "0" : "1rem 0 0"}>
            <Placeholder
              text={addText}
              action={addItem}
              dataQa={"Add " + qaPrefix}
              aria-label={addText}
              isDisabled={disablePlaceholder}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default EditableList;
