import React, { useState, Fragment } from "react";
import { Stack, Cluster, Box, Motion } from "../../atoms/layouts";
import { fallbackValues } from "./PaymentDetails.theme";

import { displayCurrency } from "../../../util/general";
import { themeComponent } from "../../../util/themeUtils";
import CollapsibleSection from "../collapsible-section";

import LoadingLine from "../../atoms/loading-line";
import LabeledAmount from "../../atoms/labeled-amount";
import LineItem from "../../atoms/line-item";
import Title from "../../atoms/title";
import SolidDivider from "../../atoms/solid-divider";
import {
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_SEMIBOLD
} from "../../../constants/style_constants";
import { ATHENS_GREY } from "../../../constants/colors";
import ButtonWithAction from "../../atoms/button-with-action";
import Text from "../../atoms/text";
import Alert from "../../atoms/alert";
import Spinner from "../../atoms/spinner";
import { noop } from "../../../util/general";

const PaymentDetailsContent = ({
  lineItemElems,
  feeElems,
  subtotal,
  total,
  variant,
  themeValues,
  hasVoidablePaymentsSection,
  voidableTransactionDetails,
  voidableAmountPaid,
  partialVoidAction,
  remainingBalance,
  agencySubheading,
  isCollapsible
}) => (
  <Stack childGap="16px">
    {isCollapsible && agencySubheading}
    {lineItemElems}
    {hasVoidablePaymentsSection && (
      <Box background={ATHENS_GREY}>
        <Text
          variant="p"
          color={themeValues.text}
          weight="400"
          fontSize="0.875rem"
        >
          Paid
        </Text>
        {voidableTransactionDetails.map(t => (
          <Cluster key={t.id} justify="space-between" align="center">
            <Cluster
              padding="0"
              align="center"
              extraStyles={`font-size: 14px;`}
            >
              <Box padding="0">{t.voidText}</Box>
              <ButtonWithAction
                variant="ghost"
                action={() => partialVoidAction(t)}
                text="Void"
                padding="0"
                extraStyles={`
                  min-width: 65px;
                  margin: 0px;
                  min-height: 0px;
                `}
                textExtraStyles={`font-weight: ${FONT_WEIGHT_REGULAR}; font-size: 14px;`}
              />
            </Cluster>
            <Box padding="0" key={t.id}>
              {displayCurrency(t.amount_processed)}
            </Box>
          </Cluster>
        ))}
        <Box padding="16px 0px">
          <SolidDivider />
        </Box>
        <LabeledAmount
          version="v2"
          as="h3"
          variant="small"
          extraStyles={`font-weight: ${FONT_WEIGHT_REGULAR}; font-size: 14px;`}
          label="Amount paid"
          amount={displayCurrency(voidableAmountPaid)}
        />
      </Box>
    )}
    {!hasVoidablePaymentsSection && !!lineItemElems.length && (
      <SolidDivider /> // avoids duplicate dividers -> case of voidable payment section, that acts as divider
    )}
    {subtotal ? (
      <>
        <Box padding="0.5rem 0">
          <LabeledAmount
            version="v2"
            variant={themeValues.labeledAmountSubtotal}
            label="Subtotal"
            amount={displayCurrency(subtotal)}
            as="h3"
          />
          {feeElems}
        </Box>
        <SolidDivider />
      </>
    ) : (
      <></>
    )}
    <LabeledAmount
      version="v2"
      as="h3"
      variant={themeValues.labeledAmountTotal}
      label={hasVoidablePaymentsSection ? "Remaining amount due" : "Total"}
      amount={displayCurrency(
        typeof remainingBalance === "number" ? remainingBalance : total
      )}
      extraStyles={`margin-top: 1rem;`}
    />
  </Stack>
);

const LoadingDetails = () => (
  <Box padding="0" minHeight="196px">
    <Box
      padding="0"
      extraStyles={`position: absolute;
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;`}
    >
      <Spinner size="100" centerSpinner />
    </Box>
    <Stack childGap="16px">
      <Cluster nowrap justify="space-between" align="start">
        <LoadingLine exactWidth="110" height="27px" />
        <LoadingLine exactWidth="60" height="27px" />
      </Cluster>
      <SolidDivider />
      <Box padding="0.5rem 0">
        <Cluster justify="space-between" align="start">
          <LoadingLine exactWidth="80" height="27px" />
          <LoadingLine exactWidth="60" height="27px" />
        </Cluster>
        <Box padding="4px 0" />
        <Cluster justify="space-between" align="start">
          <LoadingLine exactWidth="100" height="27px" />
          <LoadingLine exactWidth="50" height="27px" />
        </Cluster>
      </Box>
      <SolidDivider />
      <Stack justify="space-between" direction="row">
        <LoadingLine exactWidth="55" height="30px" />
        <LoadingLine exactWidth="70" height="30px" />
      </Stack>
    </Stack>
  </Box>
);

const ErrorDetails = () => (
  <Box padding="0">
    <Alert
      variant="error"
      heading="Error Loading Payment"
      text="Please go back and try again."
      showQuitLink={false}
      noBorder
      enableBoxShadow
      enableSmallText
      innerContentPadding="0 0 0 0.75rem"
      iconPadding="0 0 0 0"
      height="67px"
      minHeight="67px"
      padding={"0.75rem"}
    />
  </Box>
);

const getLoadingOrErrorContent = (isLoading = false, isError = false) => {
  if (isLoading) {
    return <LoadingDetails />;
  } else if (isError) {
    return <ErrorDetails />;
  }
  return <Fragment />;
};

const Collapsible = ({
  content,
  title,
  supportsTouch,
  isOpen,
  setIsOpen,
  isLoading = false,
  isError = false
}) => (
  <CollapsibleSection
    isMobile={true}
    supportsTouch={supportsTouch}
    isOpen={isOpen}
    title={title}
    initiallyOpen={false}
    toggleSection={() => setIsOpen(!isOpen)}
    customTitle={true}
    name="Collapsed Payment Details"
  >
    <Motion
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 }
      }}
      positionTransition
      initial={"closed"}
    >
      {isLoading || isError
        ? getLoadingOrErrorContent(isLoading, isError)
        : content}
    </Motion>
  </CollapsibleSection>
);

const NonCollapsible = ({
  title,
  content,
  isLoading = false,
  isError = false
}) => (
  <Stack>
    {title}
    {isLoading || isError
      ? getLoadingOrErrorContent(isLoading, isError)
      : content}
  </Stack>
);

const PaymentDetails = ({
  lineItems: _lineItems,
  fees: _fees,
  subtotal,
  total,
  variant = "default",
  hideTitle = false,
  titleText = "Payment Details",
  initiallyOpen = false,
  collapsibleOnMobile = true,
  isMobile,
  supportsTouch,
  themeValues,
  // partial void section (optional)
  hasVoidablePaymentsSection = false,
  voidableTransactionDetails = [],
  partialVoidAction = noop,
  voidableAmountPaid = 0,
  remainingBalance = false,
  // end partial void section
  isLoading = false,
  isError = false,
  agencyDisplayName
}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const fees = _fees
    .filter(fee => fee.amount > 0)
    .map(({ amount, ...rest }) => ({
      ...rest,
      amount: displayCurrency(amount)
    }));
  const lineItems = _lineItems.map(({ amount, ...rest }) => ({
    ...rest,
    amount: displayCurrency(amount)
  }));
  const isCollapsible = isMobile && collapsibleOnMobile;
  const lineItemElems = lineItems.map((item, id) => (
    <LineItem key={id} variant={themeValues.lineItem} {...item} />
  ));
  const feeElems = fees.map(fee => (
    <Fragment key={fee.label}>
      <Box padding="4px 0" />
      <LabeledAmount
        version="v2"
        as="h3"
        key={fee.label}
        variant={themeValues.labeledAmountSubtotal}
        {...fee}
      />
    </Fragment>
  ));
  const agencySubheading = (
    <>
      {agencyDisplayName && (
        <Cluster
          justify="space-between"
          align="center"
          style={{ margin: isCollapsible ? "0" : "0.5rem 0" }}
        >
          <Title
            weight={FONT_WEIGHT_REGULAR}
            as="h3"
            extraStyles={`font-size: 1rem;`}
          >
            {agencyDisplayName}
          </Title>
          {lineItems?.length && (
            <Text variant="pS" color={themeValues.text} weight="400">
              {`${lineItems.length}  item${lineItems.length > 1 ? "s" : ""}`}
            </Text>
          )}
        </Cluster>
      )}
    </>
  );
  const content = isCollapsible ? (
    <Stack childGap="0">
      <Box padding="4.5px" />
      <SolidDivider />
      <Box padding="8px" />
      <PaymentDetailsContent
        {...{
          lineItemElems,
          feeElems,
          subtotal,
          total,
          themeValues,
          variant,
          hasVoidablePaymentsSection,
          voidableTransactionDetails,
          voidableAmountPaid,
          partialVoidAction,
          remainingBalance,
          agencySubheading,
          isCollapsible
        }}
      />
    </Stack>
  ) : (
    <PaymentDetailsContent
      {...{
        lineItemElems,
        feeElems,
        subtotal,
        total,
        themeValues,
        variant,
        hasVoidablePaymentsSection,
        voidableTransactionDetails,
        voidableAmountPaid,
        partialVoidAction,
        remainingBalance,
        agencySubheading,
        isCollapsible
      }}
    />
  );
  const title = hideTitle ? (
    <Fragment />
  ) : isCollapsible ? (
    <Box width="100%" padding="none">
      <Cluster justify="space-between" align="center">
        <Title
          weight={FONT_WEIGHT_SEMIBOLD}
          as="h2"
          extraStyles={`font-size: 1.5rem; line-height: ${
            isMobile ? "2.2857rem" : "2.25rem"
          };`}
          id="payment-details-title"
        >
          {titleText}
        </Title>
        <Title
          weight={FONT_WEIGHT_BOLD}
          as="p"
          extraStyles={`font-size: 1.375rem;`}
        >
          {displayCurrency(total)}
        </Title>
      </Cluster>
    </Box>
  ) : (
    <>
      <Title
        as="h2"
        weight={FONT_WEIGHT_SEMIBOLD}
        margin="1rem 0 0 0"
        extraStyles={`font-size: 1.5rem; line-height: ${
          isMobile ? "2.2857rem" : "2.25rem"
        };`}
        id="payment-details-title"
      >
        {titleText}
      </Title>
      {agencySubheading}
    </>
  );
  return isCollapsible ? (
    <Collapsible
      {...{
        title,
        content,
        isOpen,
        setIsOpen,
        isMobile,
        supportsTouch,
        isLoading,
        isError
      }}
    />
  ) : (
    <NonCollapsible {...{ title, content, isLoading, isError }} />
  );
};

export default themeComponent(
  PaymentDetails,
  "PaymentDetails",
  fallbackValues,
  "default"
);
