import React from "react";
import { Stack } from "../layouts";
import Heading from "../heading";
import Paragraph from "../paragraph";

const LabeledAmountV1 = ({
  variant = "pL",
  label,
  amount,
  themeValues,
  as,
  extraStyles
}) => {
  const LabeledAmountText = variant === "h6" ? Heading : Paragraph;
  return (
    <Stack direction="row">
      <LabeledAmountText
        variant={variant}
        as={as}
        weight={themeValues.fontWeight}
        extraStyles={`${extraStyles}; text-align: start; flex: 3;`}
      >
        {label}:
      </LabeledAmountText>
      <LabeledAmountText
        variant={variant}
        as={as}
        weight={themeValues.fontWeight}
        extraStyles={`${extraStyles}; text-align: end; flex: 1;`}
      >
        {amount}
      </LabeledAmountText>
    </Stack>
  );
};

export default LabeledAmountV1;
