import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { get, compose, prop } from "partial.lenses";
import { push } from "connected-react-router";
import { toUpper } from "sanctuary";
import { createBankAccount } from "../../graphql/Queries";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  addAchAlertBarAction,
  upsertAch,
  ACH_RESOURCE,
  SUBMIT_OPERATIONS,
  SUBMIT_CHANGE,
  requestFailure,
  requestSuccess,
  setPaymentFormType
} from "../../Profile.state";
import {
  getAddAchForm,
  getCustomerManagement,
  getCheckoutBankAccountForm
} from "../../Profile.selectors";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const CHECKOUT_KEY = "checkout";

const _formFieldRaw = fieldName =>
  compose(getAddAchForm, prop(fieldName), prop("rawValue"));

const _formFieldRawWallet = fieldName =>
  compose(getCheckoutBankAccountForm, prop(fieldName), prop("rawValue"));

const getCreateBankAccountArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  userID: isInCustomerManagement
    ? state.profile.customerManagement.profileId
    : selectors.getUserID(state),
  accountNumber: get(_formFieldRaw("accountNumber"), state[key]),
  accountType: toUpper(get(_formFieldRaw("accountType"), state[key])),
  routingNumber: get(_formFieldRaw("routingNumber"), state[key]),
  nameOnAccount: get(_formFieldRaw("name"), state[key])
});

const getWalletBankAccountArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  userID: isInCustomerManagement
    ? state.profile.customerManagement.profileId
    : selectors.getUserID(state),
  accountNumber: get(_formFieldRawWallet("accountNumber"), state[key]),
  accountType: toUpper(get(_formFieldRawWallet("accountType"), state[key])),
  routingNumber: get(_formFieldRawWallet("routingNumber"), state[key]),
  nameOnAccount: get(_formFieldRawWallet("name"), state[key])
});

export const createBankAccountEpic = (profileKey, selectors) => (
  action$,
  state$
) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === ACH_RESOURCE && operation === SUBMIT_OPERATIONS.ADD
    ),
    RxOp.flatMap(action =>
      Rx.from(
        createBankAccount(
          action.payload.inWallet
            ? getWalletBankAccountArgs(CHECKOUT_KEY, selectors, state$.value)
            : getCreateBankAccountArgs(profileKey, selectors, state$.value)
        )
      ).pipe(
        RxOp.flatMap(response =>
          Rx.of(
            addAchAlertBarAction(clearAlerts()),
            requestSuccess(ACH_RESOURCE, SUBMIT_OPERATIONS.ADD),
            upsertAch(response.createBankAccount),
            setPaymentFormType(""),
            !action.payload.inWallet &&
              push(
                configureEndpoint(
                  getCustomerManagement(state$.value[profileKey]),
                  "/profile/wallet"
                )
              )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addAchAlertBarAction(clearAlerts()),
            addAchAlertBarAction(
              addAlert({
                heading: "Unable to Save Checking Account",
                text:
                  "Unable to process checking account registration. Please check your payment information and try again.",
                variant: "error"
              })
            ),
            requestFailure(ACH_RESOURCE, err)
          )
        )
      )
    )
  );

export default createBankAccountEpic;
