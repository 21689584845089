import React, { useState, useEffect, Fragment } from "react";
import * as R from "ramda";
import {
  Box,
  Cluster,
  Stack,
  FormInput,
  ButtonWithAction,
  Heading,
  Text
} from "@thecb/components";
import { useLocation } from "react-router";
import { isSmallWord } from "./searchUtils";
import {
  FONT_WEIGHT_SEMIBOLD,
  FONT_WEIGHT_REGULAR
} from "../../../constants/style_constants";
import SearchIconBig from "./SearchIconBig";
import SearchResults from "../search-results";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "../Content.theme";
import SearchIcon from "../../icons/SearchIcon";

const KEY_TAB = 9;
const KEY_ENTER = 13;

const Search = ({
  searchResults: { retrievingSearchResults, content, searchTerm = "" },
  retrieveSearchResults,
  meta,
  inHeaderMenu = false,
  themeValues,
  isMobile,
  isInHamburger = false,
  setSearchTerm,
  inHero,
  onClose = null
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
  const [invalidSearch, setInvalidSearch] = useState(false);
  const redirect = true; // force URL to be updated with query string
  const [searchPerformed, setSearchPerformed] = useState(false);

  useEffect(() => {
    if (!searchTerm) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryParam = urlSearchParams.get("q");
      if (queryParam) {
        setLocalSearchTerm(decodeURIComponent(queryParam).replace("+", " "));
      }
    }
  }, [searchTerm]);

  const formatSearchQuery = search =>
    !R.isEmpty(search)
      ? search
          .split(" ")
          .map(word =>
            isSmallWord(word.toLowerCase()) ? "" : { _search: word }
          )
          .filter(item => item !== "")
      : "";

  const handleKeyDown = e => {
    if (e.keyCode === KEY_ENTER) {
      setInvalidSearch(false);
      const results = formatSearchQuery(e.target.value);
      setSearchTerm(localSearchTerm);
      setSearchPerformed(true);
      !R.isEmpty(results)
        ? retrieveSearchResults(results, meta, localSearchTerm, redirect)
        : setInvalidSearch(true);
    }
  };
  const handleSearchButtonKeyDown = e => {
    if (e.keyCode === KEY_TAB && onClose) {
      onClose();
    }
  };
  const tagResults =
    content?.tagsConnection?.edges.reduce(
      (acc, curr) => [
        ...acc,
        ...curr?.node?.actionPage,
        ...curr?.node?.landingPage,
        ...curr?.node?.sectionPage,
        ...curr?.node?.subjectPage
      ],
      []
    ) ?? [];
  const {
    actionPages = [],
    landingPages = [],
    sectionPages = [],
    subjectPages = [],
    articles = []
  } = content;
  const formatDescription = description => {
    description = description.replace(/(\\n)+/g, " ");
    let cleanedDescription = description.slice(0, 200);
    if (description.length > cleanedDescription.length) {
      return cleanedDescription + "...";
    }
    return cleanedDescription;
  };
  const articleTranslator = (acc, article) => {
    const description = formatDescription(article.description.text);
    article.actionPage.forEach(actionPage => {
      actionPage.metaDescription = description;
      acc.push(actionPage);
    });
    article.sectionPage.forEach(sectionPage => {
      sectionPage.description = description;
      sectionPage.metaDescription = description;
      acc.push(sectionPage);
    });
    article.subjectPage.forEach(subjectPage => {
      subjectPage.metaDescription = description;
      acc.push(subjectPage);
    });
    return acc;
  };
  const pageTranslator = page => {
    const description = formatDescription(
      page.blocks?.[0]?.description?.text || ""
    );
    if (!!description && !page.metaDescription) {
      page.metaDescription = description;
    }
    return page;
  };
  const translatedArticles = articles.reduce(articleTranslator, []);
  const translatedActionPages = actionPages.map(pageTranslator);
  const translatedSubjectPages = subjectPages.map(pageTranslator);
  const translatedSectionPages = sectionPages.map(pageTranslator);
  const formattedResults = [
    ...translatedActionPages,
    ...landingPages,
    ...translatedSectionPages,
    ...translatedSubjectPages,
    ...tagResults,
    ...translatedArticles
  ].reduce((acc, curr) => ({ ...acc, [curr?.slug[0]?.slug]: curr }), {});

  useEffect(() => {
    if (localSearchTerm !== searchTerm) {
      setLocalSearchTerm(searchTerm);
    }
  }, [searchTerm]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/search") {
      setLocalSearchTerm("");
    }
  }, [location.pathname]);

  const SearchResultsComponent = (
    <Fragment>
      {!inHeaderMenu && (
        <Box padding="0">
          {(searchPerformed && R.isEmpty(formattedResults)) || invalidSearch ? (
            <Stack childGap="1.5rem">
              <Box padding="0">
                <Text
                  color={themeValues.darkText}
                  weight={FONT_WEIGHT_SEMIBOLD}
                >
                  {"0 results "}
                </Text>
                <Text color={themeValues.darkText} weight={FONT_WEIGHT_REGULAR}>
                  {`for "${searchTerm}"`}
                </Text>
              </Box>
              <Box
                borderRadius="1px"
                boxShadow={`inset 0px 3px 0px 0px ${themeValues.accents},
0px 1px 3px 0px rgba(0, 0, 0, 0.5)`}
                padding="3.5rem 0"
                minWidth="100%"
              >
                <Cluster justify="center" align="center">
                  <Stack childGap="1.5rem">
                    <Box padding="0">
                      <Cluster justify="center" align="center">
                        <SearchIconBig />
                      </Cluster>
                    </Box>
                    <Box padding="0" maxWidth="327px">
                      <Heading
                        variant="h5"
                        color={themeValues.darkText}
                        textAlign="center"
                      >
                        {`No results to show. Please edit your search and try again.`}
                      </Heading>
                    </Box>
                  </Stack>
                </Cluster>
              </Box>
            </Stack>
          ) : (
            <SearchResults
              isLoading={retrievingSearchResults}
              pages={formattedResults}
              themeValues={themeValues}
              showDescription
              showPagination
            />
          )}
        </Box>
      )}
    </Fragment>
  );

  const formInputWidth = inHero ? 600 : 889;

  return (
    <Box padding="0">
      {isMobile || isInHamburger ? (
        <Fragment>
          <Box padding="1rem 0 0">
            <Box padding={0} extraStyles={`position: relative;`}>
              <FormInput
                placeholder="What are you looking for?"
                field={{ rawValue: localSearchTerm }}
                fieldActions={{ set: value => setLocalSearchTerm(value) }}
                onKeyDown={handleKeyDown}
                extraStyles={`-webkit-appearance: none; min-width: 312px; padding-right: 4rem; ::placeholder { font-style: italic; color: #6D717E; }`}
                customHeight="40px"
              />
            </Box>
            <Box
              padding="0"
              extraStyles={`position: absolute; right: 0; top: 40%; margin-right: 2.25rem; color: ${themeValues.linkColor};`}
            >
              <SearchIcon />
            </Box>
          </Box>
          {SearchResultsComponent}
        </Fragment>
      ) : (
        <Stack childGap={isMobile ? "1.5rem" : "2rem"}>
          <Box padding={isMobile || inHeaderMenu ? "0" : "4rem 2rem 0 2rem"}>
            <Stack childGap={isMobile ? "1rem" : "2.5rem"}>
              <Box padding="0" minWidth="100%">
                <Cluster justify="center" align="center" overflow>
                  <FormInput
                    type="search"
                    placeholder="What are you looking for?"
                    field={{ rawValue: localSearchTerm }}
                    fieldActions={{
                      set: value => setLocalSearchTerm(value)
                    }}
                    onKeyDown={handleKeyDown}
                    extraStyles={`-webkit-appearance: none; min-width: ${formInputWidth}px; padding-right: 11rem; ::placeholder { font-style: italic; color: #6D717E; }`}
                    customHeight="76px"
                  />
                  <ButtonWithAction
                    variant="primary"
                    text="Search"
                    borderRadius="0px 4px 4px 0px"
                    onKeyDown={handleSearchButtonKeyDown}
                    action={() => {
                      setInvalidSearch(false);
                      const results = formatSearchQuery(localSearchTerm);
                      setSearchTerm(localSearchTerm);
                      setSearchPerformed(true);
                      !R.isEmpty(results)
                        ? retrieveSearchResults(
                            results,
                            meta,
                            localSearchTerm,
                            redirect
                          )
                        : setInvalidSearch(true);
                    }}
                    extraStyles={`height: 76px; width: 160px; margin: -1rem 0 0 0; margin-left: -10rem; * {font-size: 1.125rem; font-weight: 600;}`}
                  />
                </Cluster>
              </Box>
              {SearchResultsComponent}
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default themeComponent(Search, "Content", fallbackValues, "default");
