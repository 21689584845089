import React from "react";

import FormSelect from "../form-select";
import { options } from "./options";

const CountryDropdown = ({
  labelTextWhenNoError = "Country",
  errorMessages,
  field,
  fieldActions,
  showErrors,
  onChange,
  isRequired = false,
  dataQa = null
}) => (
  <FormSelect
    options={options}
    field={field}
    fieldActions={fieldActions}
    labelTextWhenNoError={labelTextWhenNoError}
    dataQa={dataQa || "CountryDropdown"}
    errorMessages={errorMessages}
    showErrors={showErrors}
    onChange={onChange}
    autocompleteValue="country-name"
    isRequired={isRequired}
  />
);
export default CountryDropdown;
