import React from "react";

const AccountGasIcon = ({ color }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill={color}
    />
    <path
      d="M23.9253 35.394C25.4776 35.394 26.9104 35.0059 28.2238 34.2298C29.5373 33.4537 30.5821 32.4089 31.3582 31.0955C32.1343 29.7821 32.5223 28.3492 32.5223 26.797C32.5223 25.4836 32.2985 24.2597 31.8507 23.1254C31.4626 22.2298 30.8955 21.3493 30.1492 20.4836C29.7015 19.9761 29 19.2746 28.0447 18.3791C27 17.4239 26.2985 16.6776 25.9403 16.1403C25.3134 15.3045 25 14.4388 25 13.5433C25 13.1851 24.8806 12.909 24.6418 12.7149C24.403 12.5209 24.1194 12.4388 23.791 12.4687C23.4627 12.4985 23.2089 12.6627 23.0298 12.9612C21.8955 14.5732 21.2537 16.006 21.1045 17.2597C20.9552 18.2746 21.1343 19.2 21.6418 20.0358C21.9403 20.5433 22.5224 21.2149 23.388 22.0507C24.0448 22.7075 24.4925 23.2149 24.7313 23.5731C25.1492 24.1403 25.3582 24.7373 25.3582 25.3642C25.3582 26.1701 25.0746 26.8492 24.5074 27.4015C23.9403 27.9537 23.2612 28.2298 22.4701 28.2298C21.6791 28.2298 21.0074 27.9463 20.4552 27.3791C19.903 26.8119 19.6269 26.1254 19.6269 25.3194V25.3194V21.5134C19.6269 21.1851 19.5224 20.9239 19.3134 20.7299C19.1045 20.5358 18.8507 20.4313 18.5522 20.4164C18.2537 20.4015 18 20.5134 17.791 20.7522C17.1045 21.4687 16.5373 22.3045 16.0895 23.2597C15.5821 24.394 15.3284 25.5731 15.3284 26.797C15.3284 28.3492 15.7164 29.7821 16.4925 31.0955C17.2686 32.4089 18.3134 33.4537 19.6269 34.2298C20.9403 35.0059 22.3731 35.394 23.9253 35.394Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default AccountGasIcon;
