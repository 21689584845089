import React from "react";

const AllocatedIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -112.000000)"
        fill="#D11053"
      >
        <path
          d="M80,112 C88.836556,112 96,119.163444 96,128 C96,136.836556 88.836556,144 80,144 C71.163444,144 64,136.836556 64,128 C64,119.163444 71.163444,112 80,112 Z M83,120 L73,120 C72.4477153,120 72,120.447715 72,121 L72,121 L72,131 C72,131.552285 72.4477153,132 73,132 L73,132 L76,132 L76,135 C76,135.552285 76.4477153,136 77,136 L77,136 L87,136 C87.5522847,136 88,135.552285 88,135 L88,135 L88,125 C88,124.447715 87.5522847,124 87,124 L87,124 L84,124 L84,121 C84,120.447715 83.5522847,120 83,120 L83,120 Z M86.5,126.56 L86.5,134.5 L78.56,134.5 L86.5,126.56 Z M85.438,125.5 L77.5,133.438 L77.5,125.5 L85.438,125.5 Z M82.5,121.5 L82.5,124 L77,124 C76.4477153,124 76,124.447715 76,125 L76,125 L76,130.5 L73.5,130.5 L73.5,121.5 L82.5,121.5 Z"
          id="status-icon---allocated"
        ></path>
      </g>
    </g>
  </svg>
);

export default AllocatedIcon;
