import React from "react";
import { CHARADE_GREY } from "../../../constants/colors";

const SearchIcon = ({ color = CHARADE_GREY, size = "22px" }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} version="1.1">
    <g
      id="Debt-Search"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Debt-Search---Expanded"
        transform="translate(-155.000000, -258.000000)"
        stroke={color}
        strokeWidth="2"
      >
        <g id="debt-search" transform="translate(132.000000, 189.000000)">
          <g id="search" transform="translate(0.000000, 44.000000)">
            <g id="search_icon" transform="translate(24.000000, 26.000000)">
              <circle
                id="Oval-2"
                transform="translate(7.407407, 7.407407) scale(-1, 1) translate(-7.407407, -7.407407) "
                cx="7.40740741"
                cy="7.40740741"
                r="7.40740741"
              ></circle>
              <line
                x1="19.6296296"
                y1="12.962963"
                x2="12.9219369"
                y2="19.6706557"
                id="Line"
                strokeLinecap="round"
                transform="translate(16.296296, 16.296296) scale(-1, 1) translate(-16.296296, -16.296296) "
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SearchIcon;
