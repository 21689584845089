import * as R from "ramda";
import React from "react";
import NotFound from "../apps/common/pages/not-found";
import Redirect from "../apps/common/pages/redirect";

export const CHECKOUT_WALLET = "wallet";
export const CHECKOUT_PAYMENT = "payment";
export const CHECKOUT_INFO = "customer-information";
export const CHECKOUT_CONFIRM = "confirmation";
export const CHECKOUT_EXPIRED_SESSION = "expired-session";

const DEFAULT_LANDING_PAGE = "/login";
const DEFAULT_NOT_FOUND = "/notfound";

const checkForNilOrRoot = R.curry(path =>
  R.or(R.isNil(path), R.equals(path, "/"))
);

const checkDisabledForDefault = R.contains(DEFAULT_LANDING_PAGE);

const checkNilAndDefaultDisabled = R.curry((landingPage, disabledPaths) =>
  R.and(checkForNilOrRoot(landingPage), checkDisabledForDefault(disabledPaths))
);

const getDefaultNotFound = R.always(DEFAULT_NOT_FOUND);

const getDefaultLandingPage = R.always(DEFAULT_LANDING_PAGE);

const flattenRoutes = routesConfig =>
  routesConfig.reduce((acc, curr) => [...acc, ...curr], []);

const defaultRoute = props => <NotFound {...props} />;

const buildRoutes = (routesConfig, routes, props) => {
  const validateRoute = (routeValue, path) => {
    if (typeof routeValue !== "function") {
      console.groupCollapsed(
        `%cError: mismatch between routes tables. Path "${path}" not found. Expand for FCS and NFE route configs.`,
        "background-color: rgba(237, 150, 32, 0.15); color: #ED9620; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #ED9620;"
      );
      console.log("FCS routes config:");
      console.table(routesConfig, ["Value", "path", "topLevel"]);
      console.log("NFE routes config:");
      console.dir(routes);
      console.groupEnd(
        `Error: mismatch between routes tables. Path "${path}" not found. Expand for FCS and NFE route configs.`
      );
      return defaultRoute;
    }
    return routeValue;
  };

  const result = routesConfig.reduce((acc, routePath) => {
    let element;
    if (typeof routePath === "object") {
      if (routePath.redirect) {
        return [
          ...acc,
          {
            path: routePath.path,
            element: <Redirect redirectTo={routePath.redirectTo} />
          }
        ];
      } else if (routePath.index) {
        element = validateRoute(routes[routePath.key], routePath.path);
        return [
          ...acc,
          {
            path: routePath.path,
            element: element(props)
          }
        ];
      }
      element = validateRoute(routes[routePath.topLevel], routePath.topLevel);
      return [
        ...acc,
        {
          path: `${routePath.topLevel}`,
          element: element(props, routePath.children)
        }
      ];
    }
    element = validateRoute(routes[routePath], routePath);
    return [...acc, { path: routePath, element: element(props) }];
  }, []);
  return result;
};

const orderedTabs = [
  "find",
  "accounts",
  "properties",
  "wallet",
  "history",
  "settings"
];
const tabIndex = tab => orderedTabs.indexOf(tab);
const getTabs = route => tabIndex(route) != -1;
const configureTabNavigation = subRoute => subRoute.split("/").filter(getTabs);
const getSubRoute = route => route.topLevel;
const buildTabNavigation = R.compose(
  R.uniq,
  R.map(configureTabNavigation),
  R.map(getSubRoute)
);

const sortRoutes = R.compose(R.sortBy(tabIndex), R.flatten);
const configureRoutes = R.compose(
  sortRoutes,
  R.compose(buildTabNavigation, R.flatten)
);

const injectScreendoorScript = (projectId, { scriptTags }) => {
  Promise.all(
    scriptTags.map(script => new Promise(resolve => (script.onload = resolve)))
  ).then(() => {
    const script = document.createElement("script");

    script.innerHTML = `
        // Uncomment this line and set it to the CSS class that your website uses for buttons:
        FormRenderer.BUTTON_CLASS = 'btn themePrimaryButton';
  
        // Uncomment this line if you want respondents to be able to access a permalink to their draft:
        // FormRenderer.addPlugin('BookmarkDraft');
  
        new FormRenderer({"project_id":"${projectId}"});
      `;

    document.body.appendChild(script);
  });
};

const isInCustomerManagement =
  window.location.href.split("/")[3] === "admin" ?? false;

const configureEndpoint = (profileId, endpoint) =>
  `${
    isInCustomerManagement && !!profileId ? `/admin/${profileId}` : ""
  }${endpoint}`;

const getProfileChildRoutes = routes =>
  routes
    .filter(route => route.some(subRoute => subRoute.path === "/profile"))
    .map(subRoute => subRoute.filter(path => path.topLevel !== undefined));

// Don't include Find tab if there are no findable accounts.
const filterRoutesByFindableAccounts = findableAccounts => routes =>
  routes.filter(
    route =>
      (findableAccounts && findableAccounts.length > 0) || route !== "find"
  );

// A customer management path is one that starts with /admin
// e.g. "/admin/:profileId/profile/settings/*"
const isCustomerManagementPath = path => {
  const firstUrlPart = R.pipe(
    R.defaultTo(""),
    R.split("/"),
    R.reject(R.equals("")),
    R.propOr(null, 0)
  )(path);

  return firstUrlPart === "admin";
};

// If we are in customer management, then only include routes with a
// customer management topLevel path.
const filterRoutesByCustomerManagement = isInCustomerManagement => routes => {
  if (isInCustomerManagement) {
    return routes.map(subRoute =>
      subRoute.filter(path => isCustomerManagementPath(path.topLevel))
    );
  } else {
    return routes;
  }
};

const checkoutLandingPage = ({ walletEnabled, isProfilePayment }) =>
  walletEnabled && !isProfilePayment ? CHECKOUT_WALLET : CHECKOUT_PAYMENT;

const isServiceRoute = path => R.match(/\/service\/.+/g, path).length > 0;

export {
  checkForNilOrRoot,
  checkNilAndDefaultDisabled,
  getDefaultNotFound,
  getDefaultLandingPage,
  flattenRoutes,
  buildRoutes,
  configureRoutes,
  injectScreendoorScript,
  isCustomerManagementPath,
  isInCustomerManagement,
  configureEndpoint,
  getProfileChildRoutes,
  filterRoutesByCustomerManagement,
  filterRoutesByFindableAccounts,
  checkoutLandingPage,
  isServiceRoute
};
