import React from "react";
import { Box, Stack } from "../../../atoms/layouts";
import Title from "../../../atoms/title";
import Detail from "../../../atoms/detail";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";

const TitleModule = ({ title, subtitle, titleColor, subtitleColor }) => (
  <Box padding="0">
    <Stack childGap="0">
      <Title
        variant="small"
        weight={FONT_WEIGHT_SEMIBOLD}
        color={titleColor}
        as="h4"
        id="obligation-title"
      >
        {title}
      </Title>
      <Detail
        variant="small"
        color={subtitleColor}
        as="h5"
        id="obligation-subtitle"
      >
        {subtitle}
      </Detail>
    </Stack>
  </Box>
);

export default TitleModule;
