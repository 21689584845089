import {
  ROYAL_BLUE_VIVID,
  CORNFLOWER_BLUE,
  RAZZMATAZZ_RED,
  BLUSH_RED,
  WHITE
} from "../../../../constants/colors";

const menuItemBackgroundColor = WHITE;
const menuItemColor = ROYAL_BLUE_VIVID;
const menuItemColorDelete = RAZZMATAZZ_RED;
const menuItemHoverBackgroundColor = CORNFLOWER_BLUE;
const menuItemHoverBackgroundColorDelete = BLUSH_RED;
const menuItemHoverColor = ROYAL_BLUE_VIVID;

export const fallbackValues = {
  menuItemBackgroundColor,
  menuItemColor,
  menuItemColorDelete,
  menuItemHoverBackgroundColor,
  menuItemHoverBackgroundColorDelete,
  menuItemHoverColor
};
