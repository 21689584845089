import React from "react";
import { LoadingContainer } from "./Loading.styled";
import { Box, Center, Cover, Spinner } from "@thecb/components";
import { Helmet } from "react-helmet";

const Loading = ({ title = "Loading...", faviconURL }) => (
  <LoadingContainer>
    <Helmet>
      <title>{title}</title>
      <link rel="shortcut icon" href={faviconURL} />
    </Helmet>
    <Cover singleChild>
      <Box>
        <Cover minHeight="100%" singleChild>
          <Center intrinsic>
            <Box>
              <Spinner size="100" />
            </Box>
          </Center>
        </Cover>
      </Box>
    </Cover>
  </LoadingContainer>
);

export default Loading;
