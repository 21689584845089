import React, { Fragment } from "react";
import { ImposterWrapper } from "./Imposter.styled";
import { safeChildren } from "../../../util/general";

/*
  The imposter is used to position content over a parent node.
  Great for tooltips, modals, dialogs, etc.
  For this to work, the parent *must* have position: relative in it styles.
  If using a <Box /> component, this can be specified using the extraStyles prop.

  Imposter takes a few props:
    -fixed (defaults to false): if true, position imposter relative to the viewport, not its parent
    -verticalMargin (defaults to "0px"): space between edge of imposter and edge of its parent
    -horizontalMargin (defaults to "0px"): space between edge of imposter and edge of its parent
    -breakout (defaults to true): if false, contain imposter within parent. margins do not apply if      breakout is true.
    -top (defaults to 50%): position from top of parent element
    -left (defaults to 50%): position from left of parent element
    -centered (defaults to false): if true, applies a transform such that the center of the imposter is
     over the center of the parent element
  
  NOTE: Imposter is not accessible by default. If building a modal/dialog/tooltip, make sure your content has the appropriate aria-roles and is focusable via keyboard.

*/

const Imposter = ({
  fixed = false,
  verticalMargin = "0px",
  horizontalMargin = "0px",
  breakout = true,
  top = "50%",
  left = "50%",
  centered = false,
  visible = true,
  children,
  ...rest
}) => (
  <ImposterWrapper
    fixed={fixed}
    verticalMargin={verticalMargin}
    horizontalMargin={horizontalMargin}
    breakout={breakout}
    top={top}
    left={left}
    centered={centered}
    visible={visible}
    {...rest}
  >
    {safeChildren(children, <Fragment />)}
  </ImposterWrapper>
);

export default Imposter;
