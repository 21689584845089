import React from "react";
import { Paragraph } from "@thecb/components";
import { IoIosPin } from "@react-icons/all-files/io/IoIosPin";
import LocationSection from "./LocationSection";
import { v4 as uuidv4 } from "uuid";

const Address = ({ address, themeValues }) => {
  const { title, addressLines, city, state, postalCode } = address;

  return (
    <LocationSection
      key="address"
      Icon={IoIosPin}
      iconName="address icon"
      title={title}
      themeValues={themeValues}
    >
      {addressLines.map(line => {
        return (
          <Paragraph key={uuidv4()} variant="pL">
            {line}
          </Paragraph>
        );
      })}
      <Paragraph variant="pL">
        {city}, {state} {postalCode}
      </Paragraph>
    </LocationSection>
  );
};

export default Address;
