import React, { Fragment } from "react";
import { fallbackValues } from "./CollapsibleSection.theme";
import { AnimatePresence } from "framer-motion";
import { themeComponent } from "../../../util/themeUtils";
import Title from "../../atoms/title";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { ENTER } from "../../../constants/keyboard";
import { Box, Cluster, Stack, Motion } from "../../atoms/layouts";
import { ChevronIcon } from "../../atoms/icons";
import { noop } from "../../../util/general";

const CollapsibleSection = ({
  isOpen,
  toggleSection,
  themeValues,
  isMobile,
  supportsTouch,
  title,
  customPadding,
  initiallyOpen = true,
  openHeight = "auto",
  children,
  customTitle = false,
  stackTitle = false,
  stackTitleContent,
  sectionGap = "0.5rem",
  name = "",
  index = 1,
  extraStyles = ""
}) => {
  const handleKeyDown = e => {
    if (e.keyCode === ENTER) {
      toggleSection();
    }
  };

  const numChildren = typeof children === "Array" ? children.length : 1;
  const label =
    name !== "" ? name : !customTitle ? title : "collapsible section";
  const id = `${label?.replaceAll(" ", "-")?.toLowerCase()}-${index}`;

  const wrapper = {
    open: {
      height: openHeight,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: 0.15
      }
    },
    closed: {
      height: 0,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: 0.15,
        staggerDirection: -1,
        delay: numChildren * 0.35
      }
    }
  };

  const icon = {
    open: {
      rotate: "90deg"
    },
    closed: {
      rotate: "0deg"
    }
  };

  return (
    <Motion
      padding="0"
      hoverStyles={`outline: none;`}
      animate={isOpen ? "open" : "closed"}
      positionTransition
    >
      {stackTitle && <Fragment>{stackTitleContent}</Fragment>}
      <Stack childGap={isOpen && !isMobile ? sectionGap : "0rem"}>
        <Box padding="0" role="heading" aria-label={label} aria-level={3}>
          <Box
            padding={customPadding ? customPadding : "0"}
            background={themeValues.headingBackgroundColor}
            onClick={isMobile && supportsTouch ? noop : toggleSection}
            onTouchEnd={isMobile && supportsTouch ? toggleSection : noop}
            key="header"
            hoverStyles={`cursor: pointer;`}
            tabIndex="0"
            onKeyDown={handleKeyDown}
            extraStyles={`z-index: 25; ${extraStyles}`}
            role="button"
            aria-expanded={isOpen.toString()}
            aria-controls={id}
            id={`${id}-button`}
          >
            <Cluster justify="space-between" align="center">
              {customTitle ? (
                <Box width="calc(100% - 36px)" padding="0px">
                  {title}
                </Box>
              ) : (
                <Title
                  weight={FONT_WEIGHT_SEMIBOLD}
                  color={themeValues.titleColor}
                  as="h6"
                  variant="small"
                >
                  {title}
                </Title>
              )}
              <Motion
                variants={icon}
                extraStyles={`display: flex; align-items: center;`}
              >
                <ChevronIcon />
              </Motion>
            </Cluster>
          </Box>
        </Box>
        <AnimatePresence initial={false}>
          {isOpen && (
            <Motion
              padding="0"
              background={themeValues.bodyBackgroundColor}
              key="content"
              positionTransition
              initial={initiallyOpen ? "open" : "closed"}
              exit="closed"
              variants={wrapper}
              extraStyles={`transform-origin: 100% 0; overflow-y: hidden;`}
              id={`${id}-content`}
              role={"region"}
              aria-labelledby={`${id}-button`}
            >
              {children}
            </Motion>
          )}
        </AnimatePresence>
      </Stack>
    </Motion>
  );
};

export default themeComponent(
  CollapsibleSection,
  "CollapsibleSection",
  fallbackValues
);
