import React from "react";
import {
  BORDER_RADIUS,
  FONT_SIZE
} from "../../../../constants/style_constants";
import { noop } from "../../../../util/general";
import ButtonWithAction from "../../../atoms/button-with-action/ButtonWithAction";

export const CancelButton = ({
  buttonExtraStyles = "",
  cancelAction = noop,
  cancelButtonText = "",
  cancelButtonVariant = "secondary",
  hideModal = noop,
  isMobile = false
}) => {
  const fontSize = `font-size: ${isMobile ? FONT_SIZE.XS : FONT_SIZE.SM};`;
  const width = isMobile ? "width: 100%;" : "";

  return (
    <ButtonWithAction
      action={cancelAction ? cancelAction : hideModal}
      borderRadius={BORDER_RADIUS.MD}
      className="modal-cancel-button"
      dataQa={cancelButtonText}
      extraStyles={`${buttonExtraStyles}; margin: 0; ${width}`}
      name={cancelButtonText}
      role="button"
      text={cancelButtonText}
      textExtraStyles={`${fontSize}`}
      variant={cancelButtonVariant}
    />
  );
};

export default CancelButton;
