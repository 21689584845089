import React from "react";

const RefundIconMedium = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `RefundIconMedium-${iconIndex}`;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0975 16.8351V18H13.4122V16.8416C11.5797 16.6399 10.396 15.7549 10.2154 14.1085L12.2485 14.0694C12.4558 14.9284 13.2049 15.3839 14.2749 15.3839C15.2379 15.3839 15.9268 14.8829 15.9268 14.2126C15.9268 13.5163 15.4787 13.2299 14.5357 13.0217L12.9507 12.6898C11.4727 12.397 10.523 11.5575 10.503 10.1128C10.4896 8.57701 11.7202 7.5423 13.4456 7.2885V6H15.1376V7.29501C16.823 7.58134 17.8462 8.70065 17.8261 10.0477L15.8532 10.0803C15.7328 9.24729 15.345 8.7397 14.315 8.7397C13.2517 8.7397 12.5963 9.20824 12.5963 9.89154C12.5963 10.4382 12.9039 10.7115 13.9539 10.9783L15.5121 11.3492C17.271 11.7592 18 12.7874 18 14.1996C18 15.6182 16.8831 16.6074 15.0975 16.8351ZM9.00001 13.8125C9.00001 14.2245 8.52963 14.4597 8.20001 14.2125L5.53334 12.2125C5.26668 12.0125 5.26668 11.6125 5.53334 11.4125L8.20001 9.4125C8.52963 9.16529 9.00001 9.40048 9.00001 9.8125V13.8125Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="6"
        width="13"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0975 16.8351V18H13.4122V16.8416C11.5797 16.6399 10.396 15.7549 10.2154 14.1085L12.2485 14.0694C12.4558 14.9284 13.2049 15.3839 14.2749 15.3839C15.2379 15.3839 15.9268 14.8829 15.9268 14.2126C15.9268 13.5163 15.4787 13.2299 14.5357 13.0217L12.9507 12.6898C11.4727 12.397 10.523 11.5575 10.503 10.1128C10.4896 8.57701 11.7202 7.5423 13.4456 7.2885V6H15.1376V7.29501C16.823 7.58134 17.8462 8.70065 17.8261 10.0477L15.8532 10.0803C15.7328 9.24729 15.345 8.7397 14.315 8.7397C13.2517 8.7397 12.5963 9.20824 12.5963 9.89154C12.5963 10.4382 12.9039 10.7115 13.9539 10.9783L15.5121 11.3492C17.271 11.7592 18 12.7874 18 14.1996C18 15.6182 16.8831 16.6074 15.0975 16.8351ZM9.00001 13.8125C9.00001 14.2245 8.52963 14.4597 8.20001 14.2125L5.53334 12.2125C5.26668 12.0125 5.26668 11.6125 5.53334 11.4125L8.20001 9.4125C8.52963 9.16529 9.00001 9.40048 9.00001 9.8125V13.8125Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="3" y="3" width="18" height="18" fill="white" />
      </g>
    </svg>
  );
};

export default RefundIconMedium;
