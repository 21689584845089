import React from "react";

const PropertyCarIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill="#3B5BDB"
    />
    <path
      d="M36.8919 19.7729H33.7284L32.8492 17.5748C31.9457 15.3149 29.7893 13.855 27.3551 13.855H20.6446C18.2108 13.855 16.054 15.3149 15.1499 17.5748L14.2707 19.7729H11.1077C10.6951 19.7729 10.3923 20.1607 10.4927 20.5607L10.8097 21.8288C10.88 22.111 11.1336 22.3091 11.4248 22.3091H12.4852C11.7756 22.9289 11.3186 23.8298 11.3186 24.8454V27.3816C11.3186 28.2334 11.6441 29.0022 12.164 29.5972V32.4541C12.164 33.3878 12.9212 34.145 13.8548 34.145H15.5457C16.4793 34.145 17.2365 33.3878 17.2365 32.4541V30.7633H30.7631V32.4541C30.7631 33.3878 31.5203 34.145 32.454 34.145H34.1448C35.0785 34.145 35.8356 33.3878 35.8356 32.4541V29.5972C36.3556 29.0027 36.6811 28.2339 36.6811 27.3816V24.8454C36.6811 23.8298 36.224 22.9289 35.5149 22.3091H36.5754C36.8665 22.3091 37.1202 22.111 37.1904 21.8288L37.5075 20.5607C37.6073 20.1607 37.3046 19.7729 36.8919 19.7729ZM18.2896 18.8308C18.6747 17.8681 19.6073 17.2366 20.6446 17.2366H27.3551C28.3923 17.2366 29.3249 17.8681 29.7101 18.8308L30.7631 21.4637H17.2365L18.2896 18.8308ZM15.5457 27.3711C14.5312 27.3711 13.8548 26.6969 13.8548 25.6855C13.8548 24.6742 14.5312 24 15.5457 24C16.5601 24 18.0819 25.517 18.0819 26.5283C18.0819 27.5396 16.5601 27.3711 15.5457 27.3711ZM32.454 27.3711C31.4395 27.3711 29.9177 27.5396 29.9177 26.5283C29.9177 25.517 31.4395 24 32.454 24C33.4685 24 34.1448 24.6742 34.1448 25.6855C34.1448 26.6969 33.4685 27.3711 32.454 27.3711Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default PropertyCarIcon;
