import React from "react";
import PaymentContact from "./sub-pages/payment-contact";
import PaymentWallet from "./sub-pages/payment-wallet";
import PaymentConfirmation from "./sub-pages/payment-confirmation";
import PaymentCustomerInfo from "./sub-pages/payment-customer-information";
import PaymentAmountMethod from "./sub-pages/payment-amount-method";
import PaymentExpiredSession from "./sub-pages/payment-expired-session";
import {
  CHECKOUT_CONFIRM,
  CHECKOUT_EXPIRED_SESSION,
  CHECKOUT_INFO,
  CHECKOUT_PAYMENT,
  CHECKOUT_WALLET
} from "../../../../util/router-utils";

export const withCommonSubpageArgs = (
  Component,
  { payment, subPageActions, forms, ...commonProps }
) => (args = {}) => (
  <Component
    {...{
      ...payment,
      ...subPageActions,
      ...forms,
      ...commonProps,
      ...args
    }}
  />
);
export const routes = {
  [CHECKOUT_WALLET]: ({
    actions,
    goToPage,
    payment,
    walletLoginAlertBar,
    walletVerifyAlertBar,
    walletForgotPasswordAlertBar,
    ...rest
  }) =>
    withCommonSubpageArgs(
      rest.walletScreenV2Enabled ? PaymentContact : PaymentWallet,
      rest
    )({
      emailForm: rest.forms.emailForm,
      emailFormActions: actions.forms.emailForm,
      payment: payment,
      walletLoginFormActions: actions.forms.walletLoginForm,
      walletLoginAlertBar,
      walletLoginAlertBarActions: actions.walletLoginAlertBar,
      walletVerifyAlertBar,
      walletVerifyAlertBarActions: actions.walletVerifyAlertBar,
      walletForgotPasswordFormActions: actions.forms.walletForgotPasswordForm,
      walletForgotPasswordAlertBar,
      walletForgotPasswordAlertBarActions: actions.walletForgotPasswordAlertBar,
      goToNextPage: goToPage("payment"),
      isInvoice: payment.isInvoice,
      cancelURL: payment.cancelURL
    }),
  [CHECKOUT_PAYMENT]: ({ actions, goToPage, ...rest }) =>
    withCommonSubpageArgs(
      PaymentAmountMethod,
      rest
    )({
      achFormActions: actions.forms.achForm,
      creditCardFormActions: actions.forms.creditCardForm,
      partialAmountFormActions: actions.forms.partialAmountForm,
      goToNextPage: goToPage("customer-information"),
      isGuestCheckout: rest.payment.isGuestCheckout
    }),
  [CHECKOUT_INFO]: ({ actions, goToPage, ...rest }) =>
    withCommonSubpageArgs(
      PaymentCustomerInfo,
      rest
    )({
      emailFormActions: actions.forms.emailForm,
      addressFormActions: actions.forms.addressForm,
      phoneFormActions: actions.forms.phoneForm,
      goToNextPage: goToPage("confirmation")
    }),
  [CHECKOUT_CONFIRM]: ({ actions, goToPage, ...rest }) =>
    withCommonSubpageArgs(
      PaymentConfirmation,
      rest
    )({
      captchaFormActions: actions.forms.captchaForm,
      goToPaymentPage: goToPage("payment"),
      goToBillingInfoPage: goToPage("customer-information"),
      gotToWalletPage: goToPage("wallet"),
      clearAlerts: actions.alerts.clearAlerts
    }),
  [CHECKOUT_EXPIRED_SESSION]: ({ expiredURL, ...rest }) =>
    withCommonSubpageArgs(
      PaymentExpiredSession,
      rest
    )({
      expiredURL: expiredURL
    })
};
