import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { get, compose, prop } from "partial.lenses";
import { push } from "connected-react-router";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  addCreditCardAlertBarAction,
  upsertCreditCard,
  CREDIT_CARD_RESOURCE,
  SUBMIT_OPERATIONS,
  SUBMIT_CHANGE,
  requestFailure,
  requestSuccess,
  setPaymentFormType,
  handlePaymentExpiration
} from "../../Profile.state";
import {
  getAddCreditCardForm,
  getCustomerManagement,
  getCheckoutCreditCardForm,
  getAddressForm,
  getSavedAddresses
} from "../../Profile.selectors";
import { createTokenizedCreditCard } from "../../graphql/Queries";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const CHECKOUT_KEY = "checkout";
const PROFILE_KEY = "profile";

const _formFieldRaw = fieldName =>
  compose(getAddCreditCardForm, prop(fieldName), prop("rawValue"));

const _formFieldRawWallet = fieldName =>
  compose(getCheckoutCreditCardForm, prop(fieldName), prop("rawValue"));

const _formFieldRawWalletAddress = fieldName =>
  compose(getAddressForm, prop(fieldName), prop("rawValue"));

//Temporary fix for expiryMonth and expiryYear until we get redux freeform to have input formatting to format expirationDate...
const getCreateCreditCardArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  userID: isInCustomerManagement
    ? state.profile.customerManagement.profileId
    : selectors.getUserID(state),
  cvv: get(_formFieldRaw("cvv"), state[key]),
  expiryMonth: get(_formFieldRaw("expirationDate"), state[key]).slice(0, 2),
  expiryYear: get(_formFieldRaw("expirationDate"), state[key]).slice(2),
  nameOnCard: get(_formFieldRaw("nameOnCard"), state[key]),
  cardNumber: get(_formFieldRaw("creditCardNumber"), state[key]),
  zipCode: get(_formFieldRaw("zipCode"), state[key]),
  country: get(_formFieldRaw("country"), state[key])
});

const getWalletCreditCardArgs = (key, selectors, state) => {
  const addressId = state[key].payment.selectedSavedAddress.addressId;
  const savedZipcode =
    getSavedAddresses(PROFILE_KEY)(state)[addressId]?.zip ?? "";
  const newZipcode = get(_formFieldRawWalletAddress("zip"), state[key]);
  return {
    endpoint: selectors.getGraphqlServiceEndpoint(state),
    clientSlug: selectors.getClientSlug(state),
    authJwt: isInCustomerManagement
      ? selectors.getAdminAccessToken(state)
      : selectors.getAccessToken(state),
    userID: isInCustomerManagement
      ? state.profile.customerManagement.profileId
      : selectors.getUserID(state),
    cvv: get(_formFieldRawWallet("cvv"), state[key]),
    expiryMonth: get(_formFieldRawWallet("expirationDate"), state[key]).slice(
      0,
      2
    ),
    expiryYear: get(_formFieldRawWallet("expirationDate"), state[key]).slice(2),
    nameOnCard: get(_formFieldRawWallet("nameOnCard"), state[key]),
    cardNumber: get(_formFieldRawWallet("creditCardNumber"), state[key]),
    country: get(_formFieldRawWalletAddress("country"), state[key]),
    zipCode: newZipcode || savedZipcode
  };
};

export const createCreditCardEpic = (profileKey, selectors) => (
  action$,
  state$
) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === CREDIT_CARD_RESOURCE &&
        operation === SUBMIT_OPERATIONS.ADD
    ),
    RxOp.flatMap(action =>
      Rx.from(
        createTokenizedCreditCard(
          action.payload.inWallet
            ? getWalletCreditCardArgs(CHECKOUT_KEY, selectors, state$.value)
            : getCreateCreditCardArgs(profileKey, selectors, state$.value)
        )
      ).pipe(
        RxOp.flatMap(response =>
          Rx.of(
            addCreditCardAlertBarAction(clearAlerts()),
            requestSuccess(CREDIT_CARD_RESOURCE, SUBMIT_OPERATIONS.ADD),
            upsertCreditCard(response.createCreditCard),
            setPaymentFormType(""),
            handlePaymentExpiration(),
            !action.payload.inWallet &&
              push(
                configureEndpoint(
                  getCustomerManagement(state$.value[profileKey]),
                  "/profile/wallet"
                )
              )
          )
        ),
        RxOp.catchError(err => {
          return Rx.of(
            addCreditCardAlertBarAction(clearAlerts()),
            addCreditCardAlertBarAction(
              addAlert({
                heading: "Unable to Save Credit Card",
                text:
                  "Unable to process credit card registration. Please check your payment information and try again.",
                variant: "error"
              })
            ),
            requestFailure(CREDIT_CARD_RESOURCE, err)
          );
        })
      )
    )
  );

export default createCreditCardEpic;
