import React, { useState, useEffect } from "react";
import ScreenReaderLiveAnnouncer from "../../../../../components/screenreader-live-announcer";

const CartScreenReaderMessages = ({
  instructionalMessage,
  screenReaderMessages
}) => {
  const [screenReaderMessage, setScreenReaderMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const formatScreenReaderMessage = screenReaderMessages => {
    if (screenReaderMessages.length === 0) return "";
    const message = screenReaderMessages
      ?.map(screenReaderMessage => screenReaderMessage.message)
      ?.join(" ");
    return message;
  };

  useEffect(() => {
    setScreenReaderMessage(
      `${formatScreenReaderMessage(screenReaderMessages)}`
    );
  }, [screenReaderMessages]);

  useEffect(() => {
    setInfoMessage(instructionalMessage);
  }, [instructionalMessage]);

  return (
    <>
      <ScreenReaderLiveAnnouncer delay={2000} message={infoMessage} />
      <ScreenReaderLiveAnnouncer
        message={screenReaderMessage}
        onAnnouncementClose={() => setScreenReaderMessage("")}
        aria-atomic={true}
      />
    </>
  );
};

export default CartScreenReaderMessages;
