import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import { get, compose, prop } from "partial.lenses";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  changePasswordAlertBarAction,
  PASSWORD_SETTING,
  SUBMIT_CHANGE,
  requestFailure,
  requestSuccess
} from "../../Profile.state";
import { changePassword } from "../../graphql/Queries";
import { getChangePasswordForm } from "../../Profile.selectors";

const _formFieldRaw = fieldName =>
  compose(getChangePasswordForm, prop(fieldName), prop("rawValue"));

const getChangePasswordArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: selectors.getAccessToken(state),
  userID: selectors.getUserID(state),
  newPassword: get(_formFieldRaw("newPassword"), state[key]),
  currentPassword: get(_formFieldRaw("currentPassword"), state[key])
});

export const changePasswordEpic = (profileKey, selectors) => (
  action$,
  state$
) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName } }) => settingName === PASSWORD_SETTING
    ),
    RxOp.flatMap(() =>
      Rx.from(
        changePassword(
          getChangePasswordArgs(profileKey, selectors, state$.value)
        )
      ).pipe(
        RxOp.flatMap(() =>
          Rx.of(
            changePasswordAlertBarAction(clearAlerts()),
            changePasswordAlertBarAction(
              addAlert({
                heading: "Change Password Succeeded",
                text: "Your password has been changed successfully",
                variant: "success"
              })
            ),
            requestSuccess(PASSWORD_SETTING),
            push("/profile/settings")
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            changePasswordAlertBarAction(clearAlerts()),
            changePasswordAlertBarAction(
              addAlert({
                heading: "Change Password Failed",
                text:
                  "An error ocurred while trying to change the password for your account.",
                variant: "error"
              })
            ),
            requestFailure(PASSWORD_SETTING, err)
          )
        )
      )
    )
  );

export default changePasswordEpic;
