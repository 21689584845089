import React from "react";
export const AlertInfoIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 Z M13,10 L13,16 L14,16 L14,18 L10,18 L10,16 L11,16 L11,12 L10,12 L10,10 L13,10 Z M13,6 L13,8 L11,8 L11,6 L13,6 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Alert/Informational" transform="translate(-24.000000, -34.000000)">
        <g
          id="Icon/Information-Container"
          transform="translate(24.000000, 34.000000)"
        >
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            id="background-2"
            fill="#15749d"
            fillRule="nonzero"
            xlinkHref="#path-1"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);
