import React from "react";
import { Stack } from "../layouts";
import Detail from "../detail";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./LineItem.theme";
import { STORM_GREY } from "../../../constants/colors";

const LineItem = ({
  description,
  subDescription,
  amount,
  themeValues,
  customAttributes,
  visibleFields = [],
  displayQuantity = null
}) => {
  const formatAttrKeys = key => {
    const keyWithoutUnderscores = key.replace(/_/g, " ");
    const capitalizedKey = keyWithoutUnderscores
      .split(" ")
      .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(" ");
    return capitalizedKey;
  };

  const visibleCustomAttrs = customAttributes
    ?.filter(
      attr => visibleFields?.includes(attr.key) && attr.key !== "description"
    )
    ?.map(attr => (
      <Detail variant="small" weight="400" key={attr.key} color={STORM_GREY}>
        {`${formatAttrKeys(attr.key)}: ${attr.value}`}
      </Detail>
    ));
  return (
    <Stack childGap="0px">
      <Detail
        as="h3"
        variant={themeValues.detailVariant}
        weight={themeValues.weightTitle}
        extraStyles={`display: flex; justify-content: space-between;`}
      >
        <span>{description}</span>
        <span>{!!displayQuantity && `x${displayQuantity}`}</span>
        <span>{amount}</span>
      </Detail>
      <Detail as="p" variant={themeValues.detailVariant} weight="400">
        {subDescription}
      </Detail>
      {visibleCustomAttrs && (
        <Stack childGap="0.25rem">{visibleCustomAttrs}</Stack>
      )}
    </Stack>
  );
};

export default themeComponent(LineItem, "LineItem", fallbackValues, "default");
