import {
  CORNFLOWER_BLUE,
  LINK_WATER,
  MOON_RAKER,
  ROYAL_BLUE_VIVID,
  MANATEE_GREY,
  GHOST_GREY,
  TRANSPARENT
} from "../../../constants/colors";

const disabledBackgroundColor = TRANSPARENT;
const disabledBorderColor = GHOST_GREY;
const disabledColor = MANATEE_GREY;
const activeBackgroundColor = CORNFLOWER_BLUE;
const backgroundColor = LINK_WATER;
const borderColor = MOON_RAKER;
const color = ROYAL_BLUE_VIVID;

export const fallbackValues = {
  disabledBackgroundColor,
  disabledBorderColor,
  disabledColor,
  activeBackgroundColor,
  backgroundColor,
  borderColor,
  color
};
