const fontWeight = {
  // v1 variants
  default: "600",
  pS: "600",
  p: "600",
  pL: "600",
  h6: "700",
  // v2 variants
  regular: "600", // fontsize Detail large
  extraSmall: "600", //fontsize Detail small
  small: "600", // fontsize Detail regular
  large: "700" // fontsize Title small
};

export const fallbackValues = {
  fontWeight
};
