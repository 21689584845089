import {
  createFormState,
  required,
  matchesField,
  hasLength,
  hasNumber,
  hasLowercaseLetter,
  hasUppercaseLetter,
  hasSpecialCharacter,
  isProbablyEmail,
  validName
} from "redux-freeform";

const formConfig = {
  firstName: {
    validators: [required(), validName()]
  },
  lastName: {
    validators: [required(), validName()]
  },
  email: {
    validators: [required(), isProbablyEmail()]
  },
  password: {
    validators: [
      required(),
      hasLength(8, 100),
      hasNumber(),
      hasLowercaseLetter(),
      hasUppercaseLetter(),
      hasSpecialCharacter()
    ]
  },
  confirmPassword: {
    validators: [required(), matchesField("password")]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
