import React, { forwardRef } from "react";
import { ButtonWithAction } from "../../../atoms";
import { FONT_WEIGHT_REGULAR } from "../../../../constants/style_constants";

const ActionLinkButton = forwardRef(
  ({ action, text, dataQa, ariaLabel }, ref) => {
    return (
      <ButtonWithAction
        ref={ref}
        action={action}
        variant="tertiary"
        extraStyles={`
        padding: 0.2rem; 
        margin: 0.5rem; 
        min-height: auto; 
        min-width: auto;
      `}
        textExtraStyles={`font-weight: ${FONT_WEIGHT_REGULAR};`}
        text={text}
        dataQa={dataQa}
        aria-label={ariaLabel}
      ></ButtonWithAction>
    );
  }
);

export default ActionLinkButton;
