const useGoogleAnalytics = clientTrackingId => {
  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    function() {
      window.dataLayer.push(arguments);
    };
  window.gtag("js", new Date());
  window.gtag("config", clientTrackingId);

  // Create initial gtag/js script in <head>
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = `//www.googletagmanager.com/gtag/js?id=${clientTrackingId}`;
  document.getElementsByTagName("head")[0].appendChild(script);
};

export default useGoogleAnalytics;
