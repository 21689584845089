import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Cluster,
  Switcher,
  Heading,
  ImageBox,
  Paragraph,
  Text,
  ButtonWithLink,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import { getCallToActionInfo } from "../../../../util/dataAdapters";

const FeaturedContentBlock = ({
  title,
  description,
  contentCards,
  blockPadding = "40px 0",
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Box padding={blockPadding}>
      <Stack>
        <Box padding="0">
          <Stack childGap="0.5rem">
            <Box
              padding="0"
              minHeight="5px"
              width={isMobile ? "24px" : "50px"}
              borderSize={isMobile ? "3px" : "6px"}
              borderColor={themeValues.lightAccentText}
              borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
              key="featured-content-accent"
            />
            <Heading
              variant="h3"
              color={themeValues.darkText}
              key="heading"
              extraStyles={`margin-top: 16px;`}
              weight={FONT_WEIGHT_SEMIBOLD}
            >
              {title}
            </Heading>
          </Stack>
        </Box>
        {description && (
          <Box padding="0">
            <Paragraph color={themeValues.darkText}>{description}</Paragraph>
          </Box>
        )}
        <Switcher childGap="1.5rem" padding="0 1px">
          {contentCards.map(
            (
              {
                title,
                Content_description: description,
                image,
                callToActionText,
                callToActionURL,
                callToActionLink,
                featuredContentLinks
              },
              index
            ) => {
              const { ctaUrl, ctaText } = getCallToActionInfo(
                callToActionLink,
                callToActionText,
                callToActionURL
              );
              return (
                <Box
                  padding="3px 0 0 0"
                  boxShadow={`inset 0px 3px 0px 0px ${themeValues.accents},
0px 1px 3px 0px rgba(0, 0, 0, 0.5)`}
                  background="white"
                  key={`feat-content-${index}`}
                >
                  <Stack fullHeight>
                    {image?.url && (
                      <ImageBox
                        image={image}
                        minWidth="100%"
                        minHeight="185px"
                        objectFit="cover"
                      />
                    )}
                    <Box
                      padding={isMobile ? "16px" : "1.5rem 1.75rem"}
                      minWidth="100%"
                      extraStyles={`height: 100%;`}
                    >
                      <Stack fullHeight bottomItem="4">
                        <Box padding="0" minWidth="100%">
                          <Heading variant="h6" color={themeValues.darkText}>
                            {title}
                          </Heading>
                        </Box>
                        <Box padding="0" minWidth="100%">
                          <Paragraph color={themeValues.darkText}>
                            {description}
                          </Paragraph>
                        </Box>
                        <Box padding="0" minWidth="100%">
                          <Stack childGap="0.5rem">
                            {featuredContentLinks?.links?.map(
                              ({ text, externalUrl }, index) => (
                                <Box padding="0" key={`links-${index}`}>
                                  <ButtonWithLink
                                    url={externalUrl}
                                    contentOverride
                                    variant="ghost"
                                  >
                                    <Text
                                      color={themeValues.linkColor}
                                      extraStyles={`text-decoration: underline; display: list-item; list-style: inside; text-align: left; ::marker { color: ${themeValues.linkColor}; }`}
                                      weight={FONT_WEIGHT_SEMIBOLD}
                                    >
                                      {text}
                                    </Text>
                                  </ButtonWithLink>
                                </Box>
                              )
                            )}
                          </Stack>
                        </Box>
                        <Box padding="0" minWidth="100%">
                          <Cluster justify="flex-end" align="center">
                            {ctaUrl && (
                              <ButtonWithLink
                                variant="ghost"
                                contentOverride
                                url={ctaUrl}
                              >
                                <Cluster justify="flex-start" align="center">
                                  <Text
                                    variant="pL"
                                    weight={FONT_WEIGHT_SEMIBOLD}
                                    color={themeValues.linkColor}
                                  >
                                    {ctaText}
                                  </Text>
                                  <RightArrowIcon
                                    color={themeValues.linkColor}
                                  />
                                </Cluster>
                              </ButtonWithLink>
                            )}
                          </Cluster>
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              );
            }
          )}
        </Switcher>
      </Stack>
    </Box>
  );
};

export default withWindowSize(FeaturedContentBlock);
