import React, { useContext, useEffect, useState } from "react";
import { Box, FormInput, withWindowSize } from "@thecb/components";
import { ThemeContext } from "styled-components";
import SearchIcon from "../../../icons/SearchIcon";

const KEY_ENTER = 13;

const SearchBox = ({ searchTerm, onSearch, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);
  const [val, setVal] = useState(searchTerm);

  useEffect(() => {
    setVal(searchTerm);
  }, [searchTerm]);

  const handleKeyDown = e => {
    if (e.keyCode === KEY_ENTER) {
      onSearch(val);
    }
  };

  return (
    <Box
      padding="0"
      extraStyles={`display:flex; align-items: center; width: 100%;`}
    >
      <FormInput
        placeholder="Search"
        field={{ rawValue: val }}
        fieldActions={{ set: value => setVal(value) }}
        onKeyDown={handleKeyDown}
        extraStyles={`border-radius: 4px; width: ${isMobile ? "75vw" : "230px"};
        font-size: ${isMobile ? "14px" : "16px"};
         -webkit-appearance: none; padding-right: 4rem; ::placeholder { color: #6D717E; }`}
        customHeight="56px"
      />
      <Box
        padding="0"
        extraStyles={`position: absolute; right: 0; top: 25%; margin-right: 1rem;`}
      >
        <SearchIcon size={28} color={themeValues.linkColor} />
      </Box>
    </Box>
  );
};

export default withWindowSize(SearchBox);
