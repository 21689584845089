import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import { deleteConstituentObligationAssoc } from "../../graphql/Queries";
import {
  DELETE_OBLIGATION_ASSOCIATION,
  accountsAlertBarAction,
  accountDetailsAlertBarAction,
  deleteObligationAssocSuccess,
  deleteObligationAssocFailure,
  deactivatePaymentScheduleSuccess,
  removeSchedule,
  filterFindableAccounts
} from "../../Profile.state";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import { getCustomerManagement, getSchedules } from "../../Profile.selectors";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const getDeleteObligationAssocArgs = (selectors, action, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  id: action.payload.id,
  client:
    selectors
      .getSubclients(state)
      .find(subClient => subClient.path === action.payload.obligationSlug)
      ?.slug ?? selectors.getSubclientSlug(state)
});

const getMatchingPaymentSchedule = (key, state, id) =>
  getSchedules(key)(state)?.find(
    sched => sched.obligationAssociationId === id
  ) ?? {};

const deleteObligationAssocEpic = (key, selectors) => (action$, state$) =>
  action$.ofType(DELETE_OBLIGATION_ASSOCIATION).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        deleteConstituentObligationAssoc(
          getDeleteObligationAssocArgs(selectors, action, state$.value)
        )
      ).pipe(
        RxOp.flatMap(() =>
          Rx.of(
            accountsAlertBarAction(clearAlerts()),
            deactivatePaymentScheduleSuccess(
              {
                obligationAssociationId: action.payload.id
              },
              false
            ),
            removeSchedule(
              getMatchingPaymentSchedule(key, state$.value, action.payload.id)
            ),
            deleteObligationAssocSuccess({
              id: action.payload.id,
              configs: selectors.getObligationConfigs(state$.value)
            }),
            filterFindableAccounts(
              state$.value?.global?.settings?.data?.canAddObligation
            ),
            accountsAlertBarAction(
              addAlert({
                heading: "Account Removed",
                text: "This account was successfully removed",
                variant: "success"
              })
            ),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[key]),
                "/profile"
              )
            )
          )
        )
      )
    ),
    RxOp.catchError(() =>
      Rx.of(
        accountDetailsAlertBarAction(clearAlerts()),
        deleteObligationAssocFailure(),
        accountDetailsAlertBarAction(
          addAlert({
            heading: "Account Removal Errors",
            text:
              "An error occurred when removing your account. Please try again.",
            variant: "error"
          })
        )
      )
    )
  );

export default deleteObligationAssocEpic;
