import { createFormState, required } from "redux-freeform";

const formState = {
  captchaToken: {
    validators: [required()]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formState
);
