import React from "react";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import { DetailText } from "./Detail.styled";
import { safeChildren } from "../../../util/general";
import {
  fallbackValues,
  mobileFallbackValues,
  MOBILE_BREAKPOINT
} from "./Detail.theme";
import { themeComponent } from "../../../util/themeUtils";

const isBelowBreakpoint = window.innerWidth < MOBILE_BREAKPOINT;
const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 1;
const mobileDeviceDetected = isBelowBreakpoint && isTouchDevice;
const fallbacks = mobileDeviceDetected ? mobileFallbackValues : fallbackValues;

const Detail = ({
  themeValues,
  weight = FONT_WEIGHT_REGULAR,
  color = FIREFLY_GREY,
  margin = 0,
  textAlign,
  extraStyles = ``,
  className,
  variant = "regular",
  as = "p",
  dataQa,
  children,
  ...rest
}) => {
  return (
    <DetailText
      variant={variant}
      as={as}
      weight={weight}
      color={color}
      margin={margin}
      textAlign={textAlign}
      extraStyles={extraStyles}
      className={className}
      fontFamily={themeValues.fontFamily}
      fontSize={themeValues.fontSize}
      data-qa={dataQa}
      {...rest}
    >
      {safeChildren(children, <span />)}
    </DetailText>
  );
};

export default themeComponent(Detail, "Detail", fallbacks, "regular");
