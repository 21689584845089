import React from "react";

const DuplicateIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -256.000000)"
        fill="#6D717E"
      >
        <path
          d="M80,288 C88.836556,288 96,280.836556 96,272 C96,263.163444 88.836556,256 80,256 C71.163444,256 64,263.163444 64,272 C64,280.836556 71.163444,288 80,288 Z M83,264 C83.5522847,264 84,264.447715 84,265 L84,268 L87,268 C87.5522847,268 88,268.447715 88,269 L88,279 C88,279.552285 87.5522847,280 87,280 L77,280 C76.4477153,280 76,279.552285 76,279 L76,276 L73,276 C72.4477153,276 72,275.552285 72,275 L72,265 C72,264.447715 72.4477153,264 73,264 L83,264 Z M82.5,265.5 L73.5,265.5 L73.5,274.5 L76,274.5 L76,269 C76,268.447715 76.4477153,268 77,268 L82.5,268 L82.5,265.5 Z M77.5,269.5 L86.5,269.5 L86.5,278.5 L77.5,278.5 L77.5,269.5 Z"
          id="status-icon---duplicate"
        ></path>
      </g>
    </g>
  </svg>
);

export default DuplicateIcon;
