import React from "react";

const DropdownIconV2 = ({
  width = "18",
  height = "19",
  color = "#292A33",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <g id="Carrot - Down / Carrot - Down - XS">
      <path
        id="Combined Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1075 7.45743C13.9122 7.26217 13.5956 7.26216 13.4003 7.45743L8.99233 11.8654L4.59199 7.46509C4.39673 7.26983 4.08014 7.26983 3.88488 7.46509L3.45355 7.89643C3.25828 8.09169 3.25828 8.40827 3.45355 8.60354L8.65078 13.8008C8.84604 13.996 9.16263 13.996 9.35789 13.8008L9.78922 13.3694C9.81738 13.3413 9.84147 13.3106 9.86151 13.2781L14.5413 8.59836C14.7365 8.4031 14.7365 8.08652 14.5413 7.89126L14.1075 7.45743Z"
        fill={color}
      />
      <mask
        id="mask0_3043_4463"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="7"
        width="12"
        height="7"
      >
        <path
          id="Combined Shape_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1075 7.45743C13.9122 7.26217 13.5956 7.26216 13.4003 7.45743L8.99233 11.8654L4.59199 7.46509C4.39673 7.26983 4.08014 7.26983 3.88488 7.46509L3.45355 7.89643C3.25828 8.09169 3.25828 8.40827 3.45355 8.60354L8.65078 13.8008C8.84604 13.996 9.16263 13.996 9.35789 13.8008L9.78922 13.3694C9.81738 13.3413 9.84147 13.3106 9.86151 13.2781L14.5413 8.59836C14.7365 8.4031 14.7365 8.08652 14.5413 7.89126L14.1075 7.45743Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3043_4463)">
        <path id="Path" d="M0 0.5H18V18.5H0V0.5Z" fill={color} />
      </g>
    </g>
  </svg>
);

export default DropdownIconV2;
