import React from "react";

const RefundIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `RefundIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5812 14.0293V15H11.1768V14.0347C9.64976 13.8666 8.66331 13.1291 8.51283 11.757L10.2071 11.7245C10.3798 12.4403 11.004 12.82 11.8957 12.82C12.6983 12.82 13.2723 12.4024 13.2723 11.8438C13.2723 11.2636 12.8989 11.0249 12.1131 10.8514L10.7923 10.5748C9.56059 10.3308 8.7692 9.63124 8.75248 8.42733C8.74133 7.14751 9.76679 6.28525 11.2047 6.07375V5H12.6147V6.07918C14.0191 6.31779 14.8718 7.25054 14.8551 8.3731L13.211 8.40022C13.1107 7.70607 12.7875 7.28308 11.9292 7.28308C11.043 7.28308 10.4969 7.67354 10.4969 8.24295C10.4969 8.69848 10.7532 8.92625 11.6282 9.14859L12.9268 9.4577C14.3925 9.79935 15 10.6562 15 11.833C15 13.0152 14.0693 13.8395 12.5812 14.0293ZM7.5 11.3438C7.5 11.7558 7.02962 11.991 6.7 11.7438L4.7 10.2438C4.43333 10.0438 4.43333 9.64375 4.7 9.44375L6.7 7.94375C7.02962 7.69654 7.5 7.93173 7.5 8.34375V11.3438Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="11"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5812 14.0293V15H11.1768V14.0347C9.64976 13.8666 8.66331 13.1291 8.51283 11.757L10.2071 11.7245C10.3798 12.4403 11.004 12.82 11.8957 12.82C12.6983 12.82 13.2723 12.4024 13.2723 11.8438C13.2723 11.2636 12.8989 11.0249 12.1131 10.8514L10.7923 10.5748C9.56059 10.3308 8.7692 9.63124 8.75248 8.42733C8.74133 7.14751 9.76679 6.28525 11.2047 6.07375V5H12.6147V6.07918C14.0191 6.31779 14.8718 7.25054 14.8551 8.3731L13.211 8.40022C13.1107 7.70607 12.7875 7.28308 11.9292 7.28308C11.043 7.28308 10.4969 7.67354 10.4969 8.24295C10.4969 8.69848 10.7532 8.92625 11.6282 9.14859L12.9268 9.4577C14.3925 9.79935 15 10.6562 15 11.833C15 13.0152 14.0693 13.8395 12.5812 14.0293ZM7.5 11.3438C7.5 11.7558 7.02962 11.991 6.7 11.7438L4.7 10.2438C4.43333 10.0438 4.43333 9.64375 4.7 9.44375L6.7 7.94375C7.02962 7.69654 7.5 7.93173 7.5 8.34375V11.3438Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="2.5" y="2.5" width="15" height="15" fill="white" />
      </g>
    </svg>
  );
};

export default RefundIconSmall;
