import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CART_LAYOUT_PADDING, SPACING, FONT_SIZE } from "../MultiCart.theme";
import {
  Box,
  ButtonWithAction,
  Cluster,
  Text,
  Title,
  ToastNotification,
  PlusCircleIcon,
  constants,
  util
} from "@thecb/components";
import {
  StyledCartList,
  StyledCartsList,
  StyledCart
} from "../MultiCart.styled";
import { formatName, pluralize } from "../../../../../util/general";
import CartItem from "./CartItem";
import RemoveButton from "./RemoveButton";
import { TOAST_AUTO_REMOVE_DELAY } from "../MultiCart";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../constants/style_constants";
import { isServiceRoute } from "../../../../../util/router-utils";

const CartItemsList = ({
  cartId,
  cartDisplayName,
  checkoutInProgress,
  inSlider,
  isMobile,
  items,
  removeFromMultiCart,
  linkColor
}) => {
  const [itemSectionList, setItemSectionList] = useState([]);
  const handleCollapsibleSection = index => {
    const newSectionList = itemSectionList.includes(index)
      ? itemSectionList.filter(item => item !== index)
      : [...itemSectionList, index];

    setItemSectionList(newSectionList);
  };

  return (
    <StyledCartList
      role="list"
      aria-label={`Cart Items List: ${cartDisplayName}`}
    >
      {items.map((item, index) => (
        <CartItem
          key={index}
          item={item}
          cartId={cartId}
          cartDisplayName={cartDisplayName}
          checkoutInProgress={checkoutInProgress}
          index={index}
          inSlider={inSlider}
          isMobile={isMobile}
          itemSectionList={itemSectionList}
          handleCollapsibleSection={handleCollapsibleSection}
          itemType={item.itemType}
          removeFromMultiCart={removeFromMultiCart}
          linkColor={linkColor}
        />
      ))}
    </StyledCartList>
  );
};

const CartsList = ({
  carts,
  checkoutCartId,
  checkoutFromMultiCart,
  clearMultiCart,
  closeCartSlider,
  hasMultipleCarts,
  inSlider,
  checkoutInProgress,
  isMobile,
  openMultiCartModal,
  removeFromMultiCart,
  removeToastFromMultiCart,
  screenReaderMessages,
  setMultiCartModalContent,
  focusArea,
  linkColor
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    colors: { STORM_GREY },
    fontWeights: { FONT_WEIGHT_REGULAR }
  } = constants;
  const { displayCurrency } = util.general;
  const cartRef = useRef();

  const cartInCheckout = carts.find(cart => cart.id === checkoutCartId);
  const startNewCheckout = ({ cart }) => {
    const cartInCheckoutName =
      cartInCheckout?.displayName ?? formatName(cartInCheckout?.id);
    setMultiCartModalContent({
      modalHeading: `Start new checkout - ${cart?.displayName ??
        formatName(cart?.id)}`,
      modalBody: `Your current checkout session in progress${
        cartInCheckoutName ? ` for ${cartInCheckoutName}` : ""
      } will be removed.`,
      modalContinueAction: () => checkoutFromMultiCart({ cartId: cart.id })
    });
    closeCartSlider();
    openMultiCartModal();
  };
  /*
    Handles cases when a user clicks the checkout button on both non-checkout and checkout pages. 
    - If the current checkout cart is clicked, the checkout button remains disabled.
    - If a checkout cart exists but it's not the one currently clicked, a modal appears to confirm or cancel the action (StartNewCheckoutModal).
    - Otherwise, the default checkout process applies for a multicart.
  */
  const handleCheckoutButtonClick = ({ cart }) => {
    if (cartInCheckout?.id === cart.id) return util.general.noop;
    if (checkoutInProgress) return startNewCheckout({ cart });
    return checkoutFromMultiCart({ cartId: cart.id });
  };

  useEffect(() => {
    setTimeout(() => {
      carts.forEach(cart => {
        cart?.toasts.forEach(toast => {
          removeToastFromMultiCart({ cartId: cart.id, id: toast.id });
        });
      });
    }, TOAST_AUTO_REMOVE_DELAY);
  }, [carts]);

  /* 
     Focuses on the cart container when the focusArea state is updated
     Note: The focusArea state is typically updated to manage focus after events, such as item deletions 
  */
  useEffect(() => {
    if (focusArea && cartRef.current) {
      cartRef.current.focus();
    }
  }, [focusArea]);

  return (
    <StyledCartsList
      role="list"
      ref={cartRef}
      tabIndex={-1}
      aria-label={`${pluralize("Cart", carts.length)}`}
      style={{ padding: isMobile ? "0" : "0 0.5rem" }}
    >
      {carts.map((cart, index) => {
        const cartId = cart.id;
        const cartDisplayName = cart.displayName ?? formatName(cartId);
        const cartCheckoutInProgress = checkoutCartId === cartId;
        const cartItemsLength = cart.items.length;
        const itemsText = pluralize("item", cartItemsLength);
        const cartTotal = displayCurrency(cart.total);
        const showDivider =
          (hasMultipleCarts && index < carts.length - 1) ||
          (inSlider && !hasMultipleCarts && cart?.config?.addMoreItemsURL);
        return (
          <React.Fragment key={cartId}>
            <StyledCart
              isMobile={isMobile}
              showDivider={showDivider}
              role="listitem"
              aria-label={`Cart: ${cartDisplayName} - Total: ${cartTotal}`}
              data-qa={`${cartId}-cart`}
            >
              <Box extraStyles="padding: 0">
                {cart?.toasts.map((toast, index) => (
                  <ToastNotification
                    key={index}
                    width="100%"
                    extraStyles="position: static; margin-bottom: 1.5rem"
                    message={toast.message}
                    toastOpen={true}
                    closeToastNotification={() =>
                      removeToastFromMultiCart({
                        cartId: cartId,
                        id: toast.id
                      })
                    }
                    screenReaderMessage={`${cartDisplayName}: ${toast.message}`}
                  />
                ))}
              </Box>
              <Cluster
                justify="space-between"
                align="center"
                extraStyles={`margin-bottom: ${SPACING.XS}`}
                nowrap
              >
                <Title
                  as={inSlider ? "h3" : "h2"}
                  extraStyles={`font-size: ${FONT_SIZE.MD};`}
                  weight={FONT_WEIGHT_REGULAR}
                >
                  {cartDisplayName}
                </Title>
                <Cluster
                  justify="space-between"
                  align="center"
                  childGap={isMobile ? SPACING.XS : SPACING.SM}
                  nowrap
                >
                  <Text
                    color={STORM_GREY}
                    weight={FONT_WEIGHT_REGULAR}
                    extraStyles={`font-size: ${FONT_SIZE.SM}; white-space: nowrap;`}
                    data-qa={`${cartId}-items-count`}
                  >
                    {`${cartItemsLength} ${itemsText}`}
                  </Text>
                  {!cartCheckoutInProgress && (
                    <RemoveButton
                      ariaLabel={`Delete Cart ${cartDisplayName}`}
                      isMobile={isMobile}
                      removeAction={() => clearMultiCart({ cartId: cartId })}
                      data-qa={`delete-${cartId}`}
                    />
                  )}
                </Cluster>
              </Cluster>
              <CartItemsList
                cartId={cartId}
                checkoutInProgress={cartCheckoutInProgress}
                cartDisplayName={cartDisplayName}
                inSlider={inSlider}
                isMobile={isMobile}
                items={cart.items}
                removeFromMultiCart={removeFromMultiCart}
                screenReaderMessages={screenReaderMessages}
                linkColor={linkColor}
              />
              <Box padding={`${SPACING.SM} 0 ${SPACING.XS}`}>
                <ButtonWithAction
                  action={() => handleCheckoutButtonClick({ cart })}
                  aria-label={
                    cartCheckoutInProgress
                      ? `${cartDisplayName} In Progress`
                      : `Proceed to Checkout: ${cartDisplayName}, with ${cartItemsLength} ${itemsText}, for ${cartTotal}`
                  }
                  text={
                    cartCheckoutInProgress
                      ? "In Progress"
                      : `Checkout (${cartItemsLength}) ${cartTotal}`
                  }
                  disabled={cartItemsLength === 0 || cartCheckoutInProgress}
                  borderRadius="10px"
                  extraStyles={
                    `margin: 0; width: 100%;` +
                    (isMobile
                      ? `min-width: 100%;`
                      : `padding:${SPACING.SM} ${SPACING.XL};`)
                  }
                  textExtraStyles={!isMobile && `font-size: ${FONT_SIZE.MD};`}
                  data-qa={`checkout-${cartId}`}
                />
              </Box>
            </StyledCart>
            {inSlider && !hasMultipleCarts && cart.config?.addMoreItemsURL && (
              <li
                role="presentation"
                style={{
                  padding: isMobile
                    ? CART_LAYOUT_PADDING.mobile.cart
                    : CART_LAYOUT_PADDING.desktop.cart
                }}
              >
                <ButtonWithAction
                  variant="secondary"
                  contentOverride={true}
                  action={() => {
                    closeCartSlider();
                    /* 
                      Preexisting logic in workflow.js prevents route changes between workflows.
                      This condition triggers a page refresh to switch workflows. 
                    */
                    if (isServiceRoute(location.pathname)) {
                      window.location.href = cart.config.addMoreItemsURL;
                    } else {
                      navigate(cart.config.addMoreItemsURL);
                    }
                  }}
                  borderRadius="8px"
                  extraStyles={`
                    margin: 0;
                    min-width: auto;
                    width: 100%;
                    border-style: dotted;
                  `}
                >
                  <Box
                    as="span"
                    padding="0"
                    extraStyles={`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      gap: 0.5rem;
                    `}
                  >
                    <PlusCircleIcon color={linkColor} />
                    <Text
                      variant={isMobile ? "pS" : "p"}
                      color={linkColor}
                      weight={FONT_WEIGHT_SEMIBOLD}
                    >
                      {"Add more to cart"}
                    </Text>
                  </Box>
                </ButtonWithAction>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </StyledCartsList>
  );
};

export default React.memo(CartsList);
