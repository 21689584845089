import React from "react";

const ErroredIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -304.000000)"
        fill="#D11053"
      >
        <path
          d="M80,336 C88.836556,336 96,328.836556 96,320 C96,311.163444 88.836556,304 80,304 C71.163444,304 64,311.163444 64,320 C64,328.836556 71.163444,336 80,336 Z M72.5492717,327 L87.4507283,327 C88.2791554,327 88.9507283,326.328427 88.9507283,325.5 C88.9507283,325.245084 88.8857626,324.994373 88.7619642,324.771536 L81.3112359,311.360225 C80.9089161,310.636049 79.9957112,310.375133 79.2715356,310.777453 C79.0266755,310.913487 78.8247975,311.115365 78.6887641,311.360225 L71.2380358,324.771536 C70.835716,325.495711 71.0966317,326.408916 71.8208073,326.811236 C72.0436443,326.935034 72.2943553,327 72.5492717,327 Z M72.5,325.5 L87.5,325.5 L80,312.087006 L72.5,325.5 Z M79.5,322.5 L80.5,322.5 C80.6380712,322.5 80.75,322.611929 80.75,322.75 L80.75,323.75 C80.75,323.888071 80.6380712,324 80.5,324 L79.5,324 C79.3619288,324 79.25,323.888071 79.25,323.75 L79.25,322.75 C79.25,322.611929 79.3619288,322.5 79.5,322.5 Z M79.5,315.5 L80.5,315.5 C80.6380712,315.5 80.75,315.611929 80.75,315.75 L80.75,321.25 C80.75,321.388071 80.6380712,321.5 80.5,321.5 L79.5,321.5 C79.3619288,321.5 79.25,321.388071 79.25,321.25 L79.25,315.75 C79.25,315.611929 79.3619288,315.5 79.5,315.5 Z"
          id="status-icon---errored"
        ></path>
      </g>
    </g>
  </svg>
);

export default ErroredIcon;
