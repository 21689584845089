import React from "react";
import { Box, Cluster, Center, Cover } from "../layouts";

const NavHeader = ({
  leftContent,
  rightContent,
  headerHeight = "105px",
  isMobile,
  backgroundColor,
  headerWidth,
  ...rest
}) => (
  <Box
    padding="0 16px 4px"
    background={backgroundColor}
    extraStyles={
      isMobile
        ? `height: 72px; > * { height: 100%; }; z-index: 2;`
        : `height: ${headerHeight}; > * { height: 100%; }; z-index: 2;`
    }
    minWidth="100%"
    {...rest}
  >
    <Center maxWidth={headerWidth || "76.5rem"}>
      <Box minHeight="100%" padding="0">
        <Cover singleChild minHeight={isMobile ? "72px" : headerHeight}>
          <Cluster justify="space-between" align="center" overflow>
            <Box padding="0">{leftContent}</Box>
            <Box padding="0">{rightContent}</Box>
          </Cluster>
        </Cover>
      </Box>
    </Center>
  </Box>
);

export default NavHeader;
