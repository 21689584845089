import styled from "styled-components";

export default styled.td`
  padding: ${({ padding = "24px" }) => padding};
  font-size: ${({ fontSize = "0.875rem" }) => fontSize}
  white-space: nowrap;
  max-width: ${({ maxWidth = "250px" }) => maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  &:last-child {
    text-align: right;
  }
  ${({ extraStyles }) => extraStyles};
`;
