import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { get, compose, prop } from "partial.lenses";
import { push } from "connected-react-router";
import { combineEpics } from "redux-observable";
import { createPhone, updateContact } from "../../graphql/Queries";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  PHONE_SETTING,
  SUBMIT_OPERATIONS,
  SUBMIT_CHANGE,
  upsertPhone,
  requestSuccess,
  requestFailure,
  addPhoneAlertBarAction
} from "../../Profile.state";
import {
  getPhoneForm,
  getConstituentID,
  getCustomerManagement
} from "../../Profile.selectors";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const _formFieldRaw = fieldName =>
  compose(getPhoneForm, prop(fieldName), prop("rawValue"));

const CHECKOUT_KEY = "checkout";

const getCreatePhoneArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  constituentID: getConstituentID(state),
  phone: get(_formFieldRaw("phone"), state[key])
});

const getUpdatePhoneArgs = (key, selectors, state, id) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  contactId: id,
  value: get(_formFieldRaw("phone"), state[key])
});

const addPhoneEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === PHONE_SETTING && operation === SUBMIT_OPERATIONS.ADD
    ),
    RxOp.flatMap(action =>
      Rx.from(
        createPhone(
          action.payload.inWallet
            ? getCreatePhoneArgs(CHECKOUT_KEY, selectors, state$.value)
            : getCreatePhoneArgs(profileKey, selectors, state$.value)
        )
      ).pipe(
        RxOp.mergeMap(result =>
          Rx.of(
            addPhoneAlertBarAction(clearAlerts()),
            requestSuccess(PHONE_SETTING, SUBMIT_OPERATIONS.ADD),
            upsertPhone(result.createContact),
            !action.payload.inWallet &&
              push(
                configureEndpoint(
                  getCustomerManagement(state$.value[profileKey]),
                  "/profile/wallet"
                )
              )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addPhoneAlertBarAction(clearAlerts()),
            addPhoneAlertBarAction(
              addAlert({
                heading: "Unable to add Phone",
                text:
                  "We are sorry, we were unable to add your phone number at this time. Please try again later.",
                variant: "error"
              })
            ),
            requestFailure(PHONE_SETTING, err)
          )
        )
      )
    )
  );

const updatePhoneEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === PHONE_SETTING && operation === SUBMIT_OPERATIONS.UPDATE
    ),
    RxOp.flatMap(({ payload: { id } }) =>
      Rx.from(
        updateContact(
          getUpdatePhoneArgs(profileKey, selectors, state$.value, id)
        )
      ).pipe(
        RxOp.mergeMap(result =>
          Rx.of(
            addPhoneAlertBarAction(clearAlerts()),
            requestSuccess(PHONE_SETTING, SUBMIT_OPERATIONS.UPDATE),
            upsertPhone(result.updateContact),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[profileKey]),
                "/profile/wallet"
              )
            )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addPhoneAlertBarAction(clearAlerts()),
            addPhoneAlertBarAction(
              addAlert({
                heading: "Unable to update Phone",
                text:
                  "We are sorry, we were unable to update your phone number at this time. Please try again later.",
                variant: "error"
              })
            ),
            requestFailure(PHONE_SETTING, err)
          )
        )
      )
    )
  );

export default (key, selectors) =>
  combineEpics(addPhoneEpic(key, selectors), updatePhoneEpic(key, selectors));
