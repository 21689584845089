import React, { useState, useEffect } from "react";
import { Box } from "../../../atoms";
import FilterableListItem from "./FilterableListItem";
import useKeyboardNavigation from "./useKeyboardNavigation";
import {
  sortItemsList,
  filterItemsList,
  selectOption,
  isChecked,
  isMaxSelectionReached
} from "./util";

const FilterableList = ({
  id,
  options,
  appliedOptions,
  selectedOptions,
  maxSelections,
  name,
  setSelectedOptions,
  searchTerm,
  themeValues
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredAppliedOptions, setFilteredAppliedOptions] = useState([]);

  useEffect(() => {
    setFilteredOptions(options);
    setFilteredAppliedOptions(appliedOptions);
  }, [options, appliedOptions]);

  useEffect(() => {
    const filteredOptionItems = filterItemsList(options, searchTerm?.rawValue);
    const filteredAppliedItems = filterItemsList(
      appliedOptions,
      searchTerm?.rawValue
    );

    setFilteredOptions(
      filteredOptionItems.length ? filteredOptionItems : filteredOptions
    );
    setFilteredAppliedOptions(filteredAppliedItems);
  }, [searchTerm.rawValue]);

  const handleSelectOption = option =>
    selectOption(option, selectedOptions, setSelectedOptions);

  const isAppliedOption = option =>
    filteredAppliedOptions?.some(
      appliedItem => appliedItem?.name === option?.name
    );

  const currentFilteredOptions = filteredOptions.filter(
    option => !isAppliedOption(option)
  );

  const sortedOptions = sortItemsList(currentFilteredOptions);
  const sortedAppliedOptions = sortItemsList(filteredAppliedOptions);

  const { itemRefs, focusedIndex, handleKeyDown } = useKeyboardNavigation({
    options: sortedOptions,
    appliedOptions: sortedAppliedOptions,
    selectedOptions,
    maxSelections
  });

  return (
    <Box
      id={id}
      padding="0"
      role="listbox"
      aria-label="Filter options container"
      onKeyDown={handleKeyDown}
      extraStyles={`
        overflow-y: auto;
        max-height: 250px;
        display: flex;
        flex-flow: column;
        padding-bottom: 0.5rem;
    `}
    >
      {sortedAppliedOptions?.length > 0 && (
        <>
          {sortedAppliedOptions.map((option, index) => {
            const checked = isChecked(option, selectedOptions);
            const tabIndex =
              index === focusedIndex || (index === 0 && focusedIndex === -1)
                ? "0"
                : "-1";
            return (
              <FilterableListItem
                key={index}
                ref={el => (itemRefs.current[index] = el)}
                index={index}
                option={option}
                checked={checked}
                selectOption={handleSelectOption}
                tabIndex={tabIndex}
                name={name}
                themeValues={themeValues}
                showDivider={
                  sortedOptions.length > 0 &&
                  index === sortedAppliedOptions.length - 1
                }
              ></FilterableListItem>
            );
          })}
        </>
      )}
      <>
        {sortedOptions.map((option, index) => {
          const checked = isChecked(option, selectedOptions);
          const isDisabled =
            isMaxSelectionReached(maxSelections, selectedOptions) && !checked;
          const indexOffset = sortedAppliedOptions?.length
            ? sortedAppliedOptions?.length
            : 0;
          const currentIndex = index === 0 ? indexOffset : index + indexOffset;
          const tabIndex =
            currentIndex === focusedIndex ||
            (indexOffset === 0 &&
              currentIndex === indexOffset &&
              focusedIndex === -1)
              ? "0"
              : "-1";
          return (
            <FilterableListItem
              key={currentIndex}
              ref={el => (itemRefs.current[currentIndex] = el)}
              index={currentIndex}
              option={option}
              checked={checked}
              selectOption={isDisabled ? noop : handleSelectOption}
              disabled={isDisabled}
              tabIndex={tabIndex}
              name={name}
              themeValues={themeValues}
            ></FilterableListItem>
          );
        })}
      </>
    </Box>
  );
};

export default React.memo(FilterableList);
