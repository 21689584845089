import React, { useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import { blockRegistry } from "./blocks";
import { themeComponent } from "../../util/themeUtils";
import { fallbackValues } from "./Content.theme";
import { history } from "../../state/store";
import { Box, constants, withWindowSize } from "@thecb/components";

const ScrollToTop = () => {
  useEffect(() => {
    const scroll = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => scroll();
  }, []);

  return null;
};

const Content = ({
  blockName,
  defaultBlockName = "TextBlock",
  pageType,
  blockIndex,
  inSidebar,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const Block = blockRegistry?.[blockName] ?? blockRegistry[defaultBlockName];
  const blockPadding = isMobile ? "24px 0" : "40px 0";

  return (
    <Box
      padding="0"
      extraStyles={!inSidebar && `&:last-child { padding-bottom : 120px;}`}
    >
      <Box
        padding="0"
        background={constants.colors.WHITE}
        id={`${blockName}-${blockIndex}`}
      >
        <ScrollToTop />
        <Block
          {...rest}
          useDarkText={pageType === "ActionPage" || pageType === "SectionPage"}
          pageType={pageType}
          blockIndex={blockIndex}
          inSidebar={inSidebar}
          blockPadding={inSidebar ? "0" : blockPadding}
        />
      </Box>
    </Box>
  );
};

export default withWindowSize(
  themeComponent(Content, "Content", fallbackValues, "default")
);
