import React, { useState } from "react";

import {
  Box,
  Stack,
  Heading,
  Paragraph,
  ButtonWithAction,
  constants,
  Cover,
  Cluster,
  ForgotPasswordForm,
  ForgotPasswordIcon,
  FormContainer,
  FormInputColumn,
  util
} from "@thecb/components";
import AlertBar from "../../../../../../components/alert-bar";
import FocusTrap from "react-focus-trap";

const ForgotPasswordPanel = ({
  closeSlider,
  panelIsOpen,
  changePanel,
  submitting,
  submitForgotPasswordForm,
  walletForgotPasswordForm,
  walletForgotPasswordFormActions,
  walletForgotPasswordAlertBar,
  walletForgotPasswordAlertBarActions,
  isMobile,
  handleFocusErrors,
  themeValues
}) => {
  const [errors, handleErrors] = useState(false);

  const handleSubmit = util.general.generateClickHandler(
    walletForgotPasswordForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitForgotPasswordForm({ inWallet: true })
  );

  return (
    <Box
      padding="0 2rem"
      minHeight="100%"
      width="100%"
      background={constants.colors.ATHENS_GREY}
      extraStyles={`.focus-trap-wrapper:focus, .focus-trap:focus { outline: none; }`}
    >
      <FocusTrap active={panelIsOpen} onExit={closeSlider}>
        <Cover singleChild minHeight="100vh">
          <Stack childGap="1.5rem">
            {walletForgotPasswordAlertBar.alerts.length > 0 && (
              <Box padding="0 0 1.5rem">
                <AlertBar
                  {...walletForgotPasswordAlertBar}
                  {...walletForgotPasswordAlertBarActions}
                />
              </Box>
            )}
            <Box padding={isMobile ? "0 0 48px" : "32px 0 64px"}>
              <Box
                padding="0"
                width={isMobile ? `auto` : `576px`}
                borderRadius="4px"
                background={constants.colors.WHITE}
                boxShadow="0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
              >
                <Box
                  background={themeValues.bannerBackgroundColor}
                  minWidth="100%"
                  padding="8px"
                >
                  <Cluster justify="center" align="center">
                    <ForgotPasswordIcon />
                  </Cluster>
                </Box>
                <FormContainer>
                  <FormInputColumn childGap="8px">
                    <Box padding="0">
                      <Heading
                        variant="h5"
                        color={constants.colors.CHARADE_GREY}
                        weight="600"
                        as="h1"
                      >
                        Forgot your password?
                      </Heading>
                    </Box>
                    <Box padding="0">
                      <Paragraph variant="p">
                        Enter your email address to receive a link to create a
                        new password.
                      </Paragraph>
                    </Box>
                    <Box padding={isMobile ? "8px 0 0" : "16px 0 0"}>
                      <Stack
                        childGap={isMobile ? "16px" : "24px"}
                        role="form"
                        aria-label="Forgot password"
                      >
                        <ForgotPasswordForm
                          {...walletForgotPasswordForm}
                          {...walletForgotPasswordFormActions}
                          clearOnDismount
                          handleSubmit={handleSubmit}
                          showErrors={errors}
                          isMobile
                        />
                        <ButtonWithAction
                          text="Reset Password"
                          isLoading={submitting}
                          variant="primary"
                          action={handleSubmit}
                          dataQa="Reset Password"
                          extraStyles={`width: 100%; margin-left: 0;`}
                        />
                      </Stack>
                    </Box>
                  </FormInputColumn>
                </FormContainer>
                <Box
                  background={themeValues.bannerBackgroundColor}
                  minWidth="100%"
                  padding={isMobile ? "24px" : "32px"}
                >
                  <Cluster justify="center" align="center">
                    <ButtonWithAction
                      action={() => {
                        changePanel("forgotPassword", "next");
                        changePanel("login", "onScreen");
                      }}
                      variant="smallGhost"
                      text="Cancel and return to login"
                    />
                  </Cluster>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Cover>
      </FocusTrap>
    </Box>
  );
};

export default ForgotPasswordPanel;
