/*
03/30/2022

For new pages/applications, please us <Title /> and <Detail /> atoms (specify "as" prop with value of "p")

*/
import React from "react";
import { fallbackValues } from "./Paragraph.theme";
import { themeComponent } from "../../../util/themeUtils";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import { ParagraphText } from "./Paragraph.styled";
import { safeChildren } from "../../../util/general";

const Paragraph = ({
  themeValues,
  weight = FONT_WEIGHT_REGULAR,
  color = FIREFLY_GREY,
  margin = 0,
  extraStyles = ``,
  dataQa,
  children,
  as,
  ...rest
}) => (
  <ParagraphText
    weight={weight}
    color={color}
    margin={margin}
    fontFamily={themeValues.fontFamily}
    fontSize={themeValues.fontSize}
    as={as}
    extraStyles={extraStyles}
    data-qa={dataQa}
    {...rest}
  >
    {safeChildren(children, <span />)}
  </ParagraphText>
);

export default themeComponent(Paragraph, "Paragraph", fallbackValues, "p");
