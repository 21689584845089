import React from "react";
import { fallbackValues } from "./Text.theme";
import { themeComponent } from "../../../util/themeUtils";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import { TextSpan } from "./Text.styled";
import { safeChildren } from "../../../util/general";

const Text = ({
  themeValues,
  weight = FONT_WEIGHT_REGULAR,
  color = FIREFLY_GREY,
  textWrap = true,
  extraStyles = ``,
  hoverStyles,
  onClick,
  onKeyPress,
  as,
  dataQa,
  children,
  variant = "p",
  ...rest
}) => (
  <TextSpan
    fontSize={themeValues.fontSize}
    weight={weight}
    color={color}
    fontFamily={themeValues.fontFamily}
    as={as}
    extraStyles={extraStyles}
    hoverStyles={hoverStyles}
    onClick={onClick}
    onKeyPress={onKeyPress}
    data-qa={dataQa}
    $textWrap={textWrap}
    $variant={variant}
    {...rest}
  >
    {safeChildren(children, <span />)}
  </TextSpan>
);

export default themeComponent(Text, "Text", fallbackValues, "p");
