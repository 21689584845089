import styled from "styled-components";
import { Switcher, constants, ButtonWithAction } from "@thecb/components";
import { SOFT_LAYERED_SHADOW } from "../../../../constants/style_constants";
import {
  CART_LAYOUT_PADDING,
  DIVIDER_STYLES,
  CART_MAX_WIDTH,
  SPACING
} from "./MultiCart.theme";

const getContext = ({ isMobile = false }) => (isMobile ? "mobile" : "desktop");

export const StyledCartPageContainer = styled(Switcher)`
  display: flex;
  justify-content: center;
  max-width: ${CART_MAX_WIDTH};
  margin: 0 auto;
  overflow: visible;
  border-radius: 8px;
  ${({ isCartEmpty }) => {
    return (
      !isCartEmpty &&
      `
        box-shadow: ${SOFT_LAYERED_SHADOW};
        background: ${constants.colors.WHITE};
      `
    );
  }}
  ${({ isMobile }) => {
    const context = getContext({ isMobile });
    return `padding: ${CART_LAYOUT_PADDING[context]?.cartsContainer};`;
  }}
`;

export const StyledCartList = styled.ul`
  list-style: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const StyledCartsList = styled(StyledCartList)`
  > :first-child {
    padding-top: 0;
  }
  > :last-child {
    padding-bottom: 0;
  }
`;

export const StyledCart = styled.li`
  box-sizing: border-box;
  width: 100%;
  ${({ isMobile, showDivider }) => {
    const context = getContext({ isMobile });
    return (
      `padding: ${CART_LAYOUT_PADDING[context].cart};` +
      (showDivider &&
        DIVIDER_STYLES &&
        `position: relative;
         ::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: ${DIVIDER_STYLES[context]?.width};
            height: ${DIVIDER_STYLES[context]?.height};
            background-color: ${constants.colors.ATHENS_GREY};
            ${isMobile && `margin-left: -1.5rem;`}
        }`)
    );
  }}
`;

export const StyledCartItem = styled.li`
  box-sizing: border-box;
  width: 100%;
  ${({ isMobile }) => {
    const context = getContext({ isMobile });
    return `padding: ${CART_LAYOUT_PADDING[context]?.cartItem};`;
  }}
`;

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ height, width }) =>
    `svg {
        height: ${height};
        width: ${width};
  }`}
`;

export const StyledFindPaymentsButton = styled(ButtonWithAction)`
  margin: ${SPACING.XL} auto;
  border-width: 1px;
  padding: ${({ isMobile }) => (isMobile ? "0.75rem 1rem" : "1rem 1.5rem")};
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
  > span {
    justify-content: center;
    flex-direction: row;
    gap: ${SPACING.XS};
  }
`;
