import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import S from "sanctuary";
import * as $ from "sanctuary-def";
import { TOKEN_STORAGE_NAME } from "@okta/okta-auth-js";

import {
  SUBMIT_LOGIN_FORM,
  loginAlertBarAction,
  loginSuccess,
  loginError
} from "./AdminLogin.state";
import { replaceAlerts } from "../../../../components/alert-bar/AlertBar.state";
import { loginUser } from "./graphql/Queries";
import { push } from "connected-react-router";
import {
  ADMIN_LOGOUT,
  ADMIN_REFRESH_TOKEN_EXPIRED,
  clearOktaAuth
} from "../../../../state/reducer";
import {
  getGraphqlServiceEndpoint,
  getClientSlug
} from "../../../../util/state";
import { resetExpiredSession } from "../../../checkout/pages/payment/Payment.state";

const genericErrorMessage =
  "There was a problem logging in to your account. Please try again.";
const wrongInfoErrorMessage =
  "The information you entered doesn't match our records.";

const loginUserParams = state => ({
  endpoint: getGraphqlServiceEndpoint(state),
  clientSlug: getClientSlug(state),
  email: state.admin.forms.adminLoginForm.email.rawValue,
  password: state.admin.forms.adminLoginForm.password.rawValue
});

const configureLandingPage = state => {
  const profileId = state.profile?.customerManagement?.profileId ?? null;
  return `/admin/${profileId}/profile/settings`;
};

export const onAdminRefreshTokenExpireEpic = action$ =>
  action$.ofType(ADMIN_REFRESH_TOKEN_EXPIRED).pipe(
    RxOp.flatMap(() =>
      Rx.of(
        loginAlertBarAction(
          replaceAlerts({
            heading: "Session Expired",
            text: "Your session has expired. Please login again.",
            type: "error"
          }),
          push("/login")
        )
      )
    )
  );

const onErrorEpic = ({ errorMessage }) =>
  Rx.of(
    loginError(errorMessage),
    loginAlertBarAction(
      replaceAlerts({
        heading: "Login Failed",
        text: errorMessage,
        variant: "error"
      })
    )
  );

export const adminLogoutEpic = action$ =>
  action$
    .ofType(ADMIN_LOGOUT)
    .pipe(
      RxOp.flatMap(() =>
        Rx.of(
          clearOktaAuth(),
          localStorage.removeItem(TOKEN_STORAGE_NAME),
          push("/admin/login")
        )
      )
    );

const parseError = S.pipe([
  S.get(S.is($.Array($.Object)))("errors"),
  S.chain(S.head),
  S.chain(S.get(S.is($.String))("message")),
  S.map(
    S.ifElse(S.equals("unauthenticated"))(S.K(wrongInfoErrorMessage))(
      S.K(genericErrorMessage)
    )
  ),
  S.fromMaybe(genericErrorMessage)
]);

const parseResponse = response =>
  S.pipe([
    S.gets(S.is($.String))(["performLoginForAdmin", "token", "key"]),
    S.maybeToNullable
  ])(response);

export const submitAdminLoginFormEpic = (action$, state$) =>
  action$.ofType(SUBMIT_LOGIN_FORM).pipe(
    RxOp.flatMap(() =>
      Rx.from(loginUser(loginUserParams(state$.value))).pipe(
        RxOp.flatMap(refreshToken =>
          Rx.of(
            resetExpiredSession(),
            loginSuccess(parseResponse(refreshToken)),
            push(configureLandingPage(state$.value))
          )
        ),
        RxOp.catchError(err =>
          onErrorEpic({
            errorMessage: err.response ? parseError(err.response) : err.message
          })
        )
      )
    )
  );
