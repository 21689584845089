import React from "react";

const PointOfSaleImage = () => (
  <svg
    width={180}
    height={96}
    viewBox="0 0 180 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.706 17.5583C140.253 17.3968 138.314 15.3563 138.314 12.8627C138.314 10.2637 140.421 8.15681 143.02 8.15681C143.913 8.15681 144.749 8.40592 145.46 8.8385C146.262 5.91385 148.939 3.76465 152.118 3.76465C155.728 3.76465 158.691 6.53709 158.994 10.0693C159.633 9.65335 160.396 9.41171 161.216 9.41171C163.468 9.41171 165.294 11.2377 165.294 13.4901C165.294 15.7426 163.468 17.5686 161.216 17.5686C161.11 17.5686 161.005 17.5646 160.902 17.5567V17.5686H152.118H143.02H142.706V17.5583Z"
      fill="#15749D"
      fillOpacity={0.3}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3531 64.627C18.9669 64.627 17.8433 63.5034 17.8433 62.1172C17.8433 60.7311 18.9669 59.6074 20.3531 59.6074C20.7424 59.6074 21.1111 59.6961 21.44 59.8544C21.9913 58.9536 22.9843 58.3525 24.1178 58.3525C25.5139 58.3525 26.6971 59.2646 27.104 60.5253C27.4257 60.3406 27.7986 60.2349 28.1962 60.2349C29.4091 60.2349 30.3923 61.2181 30.3923 62.431C30.3923 63.6438 29.4091 64.627 28.1962 64.627H24.1178H20.3531Z"
      fill="#15749D"
      fillOpacity={0.3}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.8237 8.78431H53.608H49.147V8.77384C49.0663 8.78078 48.9846 8.78431 48.9021 8.78431C47.3428 8.78431 46.0786 7.52018 46.0786 5.96078C46.0786 4.40139 47.3428 3.13726 48.9021 3.13726C49.067 3.13726 49.2286 3.15139 49.3857 3.1785C49.9123 1.34307 51.6033 0 53.608 0C55.4761 0 57.0719 1.1663 57.7068 2.81047C57.8935 2.7321 58.0986 2.68878 58.3139 2.68878C58.8648 2.68878 59.3494 2.97248 59.6292 3.40162C59.992 3.232 60.3968 3.13726 60.8237 3.13726C62.3831 3.13726 63.6472 4.40139 63.6472 5.96078C63.6472 7.43768 62.5133 8.64973 61.0686 8.77384V8.78431H60.8237Z"
      fill="#15749D"
      fillOpacity={0.3}
    />
    <path
      d="M109.451 91.9212C109.451 94.1737 100.883 95.9996 90.3138 95.9996C79.7446 95.9996 71.1765 94.1737 71.1765 91.9212C71.1765 89.6687 79.7446 87.8428 90.3138 87.8428C100.883 87.8428 109.451 89.6687 109.451 91.9212Z"
      fill="#15749D"
      fillOpacity={0.3}
    />
    <path
      d="M49.8433 32H130.784V80.3137C130.784 81.0068 130.223 81.5686 129.53 81.5686H51.0982C50.4051 81.5686 49.8433 81.0068 49.8433 80.3137V32Z"
      fill="white"
    />
    <path
      d="M47.9609 22.9333C47.9609 21.0102 49.5199 19.4512 51.4431 19.4512H129.185C131.108 19.4512 132.667 21.0102 132.667 22.9333V32.0002H47.9609V22.9333Z"
      fill="#E5E7EC"
    />
    <path
      d="M63.0196 25.7243C63.0196 26.4166 62.4577 26.9778 61.7647 26.9778C61.0716 26.9778 60.5098 26.4166 60.5098 25.7243C60.5098 25.0319 61.0716 24.4707 61.7647 24.4707C62.4577 24.4707 63.0196 25.0319 63.0196 25.7243Z"
      fill="#292A33"
    />
    <path
      d="M59.2549 25.7243C59.2549 26.4166 58.6931 26.9778 58 26.9778C57.307 26.9778 56.7451 26.4166 56.7451 25.7243C56.7451 25.0319 57.307 24.4707 58 24.4707C58.6931 24.4707 59.2549 25.0319 59.2549 25.7243Z"
      fill="#292A33"
    />
    <path
      d="M55.4903 25.7243C55.4903 26.4166 54.9284 26.9778 54.2354 26.9778C53.5423 26.9778 52.9805 26.4166 52.9805 25.7243C52.9805 25.0319 53.5423 24.4707 54.2354 24.4707C54.9284 24.4707 55.4903 25.0319 55.4903 25.7243Z"
      fill="#292A33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.53 21.3335H51.0982C50.4051 21.3335 49.8433 21.8954 49.8433 22.5884V80.3139C49.8433 81.007 50.4051 81.5688 51.0982 81.5688H129.53C130.223 81.5688 130.784 81.007 130.784 80.3139V22.5884C130.784 21.8954 130.223 21.3335 129.53 21.3335ZM51.0982 19.4512C49.3655 19.4512 47.9609 20.8558 47.9609 22.5884V80.3139C47.9609 82.0466 49.3655 83.4512 51.0982 83.4512H129.53C131.262 83.4512 132.667 82.0466 132.667 80.3139V22.5884C132.667 20.8558 131.262 19.4512 129.53 19.4512H51.0982Z"
      fill="#292A33"
    />
    <path
      d="M157.765 75.9219C157.765 75.2288 158.326 74.667 159.02 74.667H169.686C170.379 74.667 170.941 75.2288 170.941 75.9219V75.9219C170.941 76.615 170.379 77.1768 169.686 77.1768H159.02C158.326 77.1768 157.765 76.615 157.765 75.9219V75.9219Z"
      fill="#292A33"
    />
    <path
      d="M132.667 74.667H153.252C153.95 74.667 154.517 75.2335 154.517 75.9323V75.9323C154.517 76.6311 153.95 77.1976 153.252 77.1976H132.667V74.667Z"
      fill="#292A33"
    />
    <path
      d="M30.3923 75.9219C30.3923 75.2288 30.9542 74.667 31.6472 74.667H47.961V77.1768H31.6472C30.9542 77.1768 30.3923 76.615 30.3923 75.9219V75.9219Z"
      fill="#292A33"
    />
    <path
      d="M19.7256 75.9219C19.7256 75.2288 20.2874 74.667 20.9805 74.667H26.0001C26.6932 74.667 27.255 75.2288 27.255 75.9219V75.9219C27.255 76.615 26.6932 77.1768 26.0001 77.1768H20.9805C20.2874 77.1768 19.7256 76.615 19.7256 75.9219V75.9219Z"
      fill="#292A33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.7255 48.4773C98.639 48.4773 97.8554 49.1092 97.6631 49.7849C97.5435 50.2053 97.1057 50.4491 96.6854 50.3295C96.265 50.2098 96.0211 49.7721 96.1408 49.3517C96.5631 47.8673 98.0747 46.8945 99.7255 46.8945C101.376 46.8945 102.888 47.8673 103.31 49.3517C103.43 49.7721 103.186 50.2098 102.766 50.3295C102.345 50.4491 101.907 50.2053 101.788 49.7849C101.596 49.1092 100.812 48.4773 99.7255 48.4773Z"
      fill="#292A33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.1569 48.4773C81.0704 48.4773 80.2868 49.1092 80.0945 49.7849C79.9749 50.2053 79.5371 50.4491 79.1167 50.3295C78.6964 50.2098 78.4525 49.7721 78.5722 49.3517C78.9945 47.8673 80.5061 46.8945 82.1569 46.8945C83.8077 46.8945 85.3192 47.8673 85.7416 49.3517C85.8612 49.7721 85.6174 50.2098 85.197 50.3295C84.7766 50.4491 84.3389 50.2053 84.2192 49.7849C84.027 49.1092 83.2434 48.4773 82.1569 48.4773Z"
      fill="#292A33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.098 59.4443C83.5351 59.4443 83.8894 59.7987 83.8894 60.2357C83.8894 61.1848 84.6588 61.9541 85.6078 61.9541H95.0196C95.9687 61.9541 96.738 61.1848 96.738 60.2357C96.738 59.7987 97.0923 59.4443 97.5294 59.4443C97.9665 59.4443 98.3208 59.7987 98.3208 60.2357C98.3208 62.0589 96.8428 63.5369 95.0196 63.5369H85.6078C83.7846 63.5369 82.3066 62.0589 82.3066 60.2357C82.3066 59.7987 82.661 59.4443 83.098 59.4443Z"
      fill="#292A33"
    />
    <path
      d="M47.9609 30.1172H132.667V31.9995H47.9609V30.1172Z"
      fill="#292A33"
    />
    <g filter="url(#filter0_d_4979_37133)">
      <path
        d="M22.2354 35.1377C22.2354 34.4447 22.7972 33.8828 23.4903 33.8828H47.9608V42.0397H23.4903C22.7972 42.0397 22.2354 41.4778 22.2354 40.7848V35.1377Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9323 37.6658C25.5914 37.5877 25.5079 37.5381 25.5079 37.4245V37.4175C25.5079 37.3252 25.5878 37.2577 25.7514 37.2577C25.9455 37.2577 26.1621 37.3239 26.3658 37.4561C26.4039 37.4808 26.4541 37.4725 26.4801 37.4349L26.724 37.0836C26.7499 37.0462 26.7429 36.9935 26.7064 36.9669C26.5557 36.8569 26.3858 36.779 26.1926 36.7335V36.4748C26.1926 36.4294 26.1565 36.3926 26.112 36.3926H25.6219C25.5774 36.3926 25.5413 36.4294 25.5413 36.4748V36.7055C25.0931 36.7813 24.8261 37.0836 24.8261 37.4813V37.4884C24.8261 37.9957 25.2261 38.1448 25.7444 38.2654C26.0783 38.347 26.1584 38.4003 26.1584 38.5066V38.5138C26.1584 38.6202 26.061 38.6839 25.8766 38.6839C25.6183 38.6839 25.3747 38.5976 25.1483 38.4329C25.1127 38.407 25.0633 38.4148 25.0353 38.449L24.7648 38.7786C24.7353 38.8145 24.7391 38.869 24.7752 38.8981C24.9925 39.0729 25.2567 39.1835 25.5413 39.2299V39.4543C25.5413 39.496 25.5745 39.5298 25.6154 39.5298H26.1186C26.1595 39.5298 26.1926 39.496 26.1926 39.4543V39.2163C26.5893 39.1204 26.8366 38.8483 26.8366 38.4463V38.4393C26.8366 37.9744 26.4888 37.7899 25.9323 37.6658Z"
        fill="#959CA8"
      />
      <path
        d="M40.4314 37.9617C40.4314 37.4419 40.8528 37.0205 41.3726 37.0205H47.9608V38.9029H41.3726C40.8528 38.9029 40.4314 38.4815 40.4314 37.9617V37.9617Z"
        fill="#959CA8"
      />
      <path
        d="M29.7646 37.9617C29.7646 37.4419 30.186 37.0205 30.7058 37.0205H36.9803C37.5001 37.0205 37.9215 37.4419 37.9215 37.9617V37.9617C37.9215 38.4815 37.5001 38.9029 36.9803 38.9029H30.7058C30.186 38.9029 29.7646 38.4815 29.7646 37.9617V37.9617Z"
        fill="#959CA8"
      />
    </g>
    <g filter="url(#filter1_d_4979_37133)">
      <path
        d="M5.92163 42.0391C5.92163 41.346 6.48347 40.7842 7.17653 40.7842H55.4903C56.1833 40.7842 56.7452 41.346 56.7452 42.0391V47.6861C56.7452 48.3792 56.1833 48.941 55.4903 48.941H7.17653C6.48347 48.941 5.92163 48.3792 5.92163 47.6861V42.0391Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61856 44.5671C9.27768 44.4891 9.19416 44.4395 9.19416 44.3259V44.3189C9.19416 44.2266 9.27411 44.1591 9.43766 44.1591C9.63178 44.1591 9.84835 44.2252 10.0521 44.3575C10.0902 44.3822 10.1403 44.3738 10.1664 44.3362L10.4103 43.9849C10.4362 43.9476 10.4292 43.8949 10.3926 43.8683C10.242 43.7583 10.0721 43.6804 9.87892 43.6349V43.3761C9.87892 43.3308 9.84282 43.2939 9.79826 43.2939H9.30818C9.26369 43.2939 9.22759 43.3308 9.22759 43.3761V43.6069C8.77941 43.6827 8.5124 43.9849 8.5124 44.3826V44.3898C8.5124 44.8971 8.91239 45.0461 9.43066 45.1667C9.76462 45.2483 9.84464 45.3016 9.84464 45.4079V45.4152C9.84464 45.5215 9.74727 45.5853 9.56287 45.5853C9.30461 45.5853 9.06097 45.499 8.8346 45.3342C8.799 45.3083 8.74961 45.3162 8.72163 45.3504L8.45105 45.6799C8.4216 45.7159 8.42538 45.7704 8.46148 45.7994C8.67882 45.9743 8.94296 46.0849 9.22759 46.1313V46.3556C9.22759 46.3974 9.26075 46.4312 9.30167 46.4312H9.80484C9.84576 46.4312 9.87892 46.3974 9.87892 46.3556V46.1176C10.2755 46.0218 10.5229 45.7496 10.5229 45.3477V45.3407C10.5229 44.8758 10.1751 44.6913 9.61856 44.5671Z"
        fill="#959CA8"
      />
      <path
        d="M41.6863 44.8631C41.6863 44.3433 42.1077 43.9219 42.6275 43.9219H52.6667C53.1865 43.9219 53.6078 44.3433 53.6078 44.8631V44.8631C53.6078 45.3828 53.1865 45.8042 52.6667 45.8042H42.6275C42.1077 45.8042 41.6863 45.3828 41.6863 44.8631V44.8631Z"
        fill="#959CA8"
      />
      <path
        d="M26 44.8631C26 44.3433 26.4214 43.9219 26.9412 43.9219H38.2353C38.7551 43.9219 39.1765 44.3433 39.1765 44.8631V44.8631C39.1765 45.3828 38.7551 45.8042 38.2353 45.8042H26.9412C26.4214 45.8042 26 45.3828 26 44.8631V44.8631Z"
        fill="#959CA8"
      />
      <path
        d="M13.4509 44.8631C13.4509 44.3433 13.8723 43.9219 14.3921 43.9219H22.549C23.0688 43.9219 23.4901 44.3433 23.4901 44.8631V44.8631C23.4901 45.3828 23.0688 45.8042 22.549 45.8042H14.3921C13.8723 45.8042 13.4509 45.3828 13.4509 44.8631V44.8631Z"
        fill="#959CA8"
      />
    </g>
    <g filter="url(#filter2_d_4979_37133)">
      <path
        d="M132.667 27.6074H167.804C168.497 27.6074 169.059 28.1693 169.059 28.8623V34.5094C169.059 35.2024 168.497 35.7643 167.804 35.7643H132.667V27.6074Z"
        fill="white"
      />
      <path
        d="M157.765 31.6853C157.765 31.1655 158.186 30.7441 158.706 30.7441H164.98C165.5 30.7441 165.922 31.1655 165.922 31.6853V31.6853C165.922 32.2051 165.5 32.6265 164.98 32.6265H158.706C158.186 32.6265 157.765 32.2051 157.765 31.6853V31.6853Z"
        fill="#959CA8"
      />
      <path
        d="M146.471 31.6853C146.471 31.1655 146.892 30.7441 147.412 30.7441H154.314C154.834 30.7441 155.255 31.1655 155.255 31.6853V31.6853C155.255 32.2051 154.834 32.6265 154.314 32.6265H147.412C146.892 32.6265 146.471 32.2051 146.471 31.6853V31.6853Z"
        fill="#959CA8"
      />
      <path
        d="M132.667 30.7441H143.02C143.539 30.7441 143.961 31.1655 143.961 31.6853V31.6853C143.961 32.2051 143.539 32.6265 143.02 32.6265H132.667V30.7441Z"
        fill="#959CA8"
      />
    </g>
    <g filter="url(#filter3_d_4979_37133)">
      <path
        d="M123.255 35.7647C123.255 35.0716 123.817 34.5098 124.51 34.5098H172.824C173.517 34.5098 174.078 35.0716 174.078 35.7647V41.4117C174.078 42.1048 173.517 42.6666 172.824 42.6666H124.51C123.817 42.6666 123.255 42.1048 123.255 41.4117V35.7647Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.952 38.2927C126.611 38.2147 126.527 38.1651 126.527 38.0515V38.0445C126.527 37.9522 126.607 37.8847 126.771 37.8847C126.965 37.8847 127.182 37.9508 127.385 38.0831C127.423 38.1078 127.474 38.0994 127.5 38.0618L127.744 37.7105C127.769 37.6731 127.762 37.6205 127.726 37.5939C127.575 37.4839 127.405 37.4059 127.212 37.3605V37.1017C127.212 37.0563 127.176 37.0195 127.132 37.0195H126.641C126.597 37.0195 126.561 37.0563 126.561 37.1017V37.3325C126.113 37.4083 125.846 37.7105 125.846 38.1082V38.1153C125.846 38.6227 126.246 38.7717 126.764 38.8923C127.098 38.9739 127.178 39.0272 127.178 39.1335V39.1407C127.178 39.2471 127.081 39.3109 126.896 39.3109C126.638 39.3109 126.394 39.2246 126.168 39.0598C126.132 39.0339 126.083 39.0418 126.055 39.076L125.784 39.4055C125.755 39.4415 125.759 39.496 125.795 39.525C126.012 39.6999 126.276 39.8105 126.561 39.8568V40.0812C126.561 40.123 126.594 40.1568 126.635 40.1568H127.138C127.179 40.1568 127.212 40.123 127.212 40.0812V39.8432C127.609 39.7474 127.856 39.4752 127.856 39.0732V39.0663C127.856 38.6014 127.508 38.4169 126.952 38.2927Z"
        fill="#959CA8"
      />
      <path
        d="M159.647 38.5886C159.647 38.0688 160.069 37.6475 160.588 37.6475H170C170.52 37.6475 170.941 38.0688 170.941 38.5886V38.5886C170.941 39.1084 170.52 39.5298 170 39.5298H160.588C160.069 39.5298 159.647 39.1084 159.647 38.5886V38.5886Z"
        fill="#959CA8"
      />
      <path
        d="M143.961 38.5896C143.961 38.0698 144.382 37.6484 144.902 37.6484H156.196C156.716 37.6484 157.137 38.0698 157.137 38.5896V38.5896C157.137 39.1094 156.716 39.5308 156.196 39.5308H144.902C144.382 39.5308 143.961 39.1094 143.961 38.5896V38.5896Z"
        fill="#959CA8"
      />
      <path
        d="M130.784 38.5886C130.784 38.0688 131.206 37.6475 131.726 37.6475H140.51C141.03 37.6475 141.451 38.0688 141.451 38.5886V38.5886C141.451 39.1084 141.03 39.5298 140.51 39.5298H131.726C131.206 39.5298 130.784 39.1084 130.784 38.5886V38.5886Z"
        fill="#959CA8"
      />
    </g>
    <path
      d="M86.2354 89.7247V83.4502H88.7452V91.2848C88.7452 91.8093 88.32 92.2345 87.7956 92.2345H82.4707C81.7777 92.2345 81.2158 91.6727 81.2158 90.9796C81.2158 90.2865 81.7777 89.7247 82.4707 89.7247H86.2354Z"
      fill="#15749D"
    />
    <path
      d="M91.8823 91.2848V83.4502H94.3921V89.7247H98.1568C98.8499 89.7247 99.4117 90.2865 99.4117 90.9796C99.4117 91.6727 98.8499 92.2345 98.1568 92.2345H92.832C92.3075 92.2345 91.8823 91.8093 91.8823 91.2848Z"
      fill="#15749D"
    />
    <path
      d="M132.667 62.7447V65.8819L140.611 70.4217C141.107 70.705 141.731 70.6214 142.135 70.2177L152.745 59.6074H148.98L141.311 67.5835C141.209 67.6894 141.048 67.7113 140.922 67.6364L132.667 62.7447Z"
      fill="#15749D"
    />
    <path
      d="M145.843 58.3526C145.843 57.6595 146.405 57.0977 147.098 57.0977H157.137C157.83 57.0977 158.392 57.6595 158.392 58.3526V58.3526C158.392 59.0456 157.83 59.6075 157.137 59.6075H147.098C146.405 59.6075 145.843 59.0456 145.843 58.3526V58.3526Z"
      fill="#15749D"
    />
    <path
      d="M47.9608 65.8814V62.7441L37.054 71.0541C36.1854 71.7159 36.518 73.0945 37.5928 73.2874L59.2549 77.1755V74.6657L40.4314 71.5285L47.9608 65.8814Z"
      fill="#15749D"
    />
    <path
      d="M62.3921 71.5283H63.647C64.3401 71.5283 64.902 72.0902 64.902 72.7832C64.902 73.4763 64.3401 74.0381 63.647 74.0381H63.0196C61.98 74.0381 61.1372 74.8809 61.1372 75.9205C61.1372 76.9601 61.98 77.8028 63.0196 77.8028H65.5294C66.2225 77.8028 66.7843 78.3647 66.7843 79.0577C66.7843 79.7508 66.2225 80.3126 65.5294 80.3126H62.3921C60.313 80.3126 58.6274 78.6271 58.6274 76.5479V75.293C58.6274 73.2138 60.313 71.5283 62.3921 71.5283Z"
      fill="#15749D"
    />
    <g filter="url(#filter4_d_4979_37133)">
      <path
        d="M140.823 47.0591C140.823 44.6334 142.79 42.667 145.216 42.667H159.02C161.445 42.667 163.412 44.6334 163.412 47.0591V53.3337C163.412 54.0267 162.85 54.5886 162.157 54.5886H142.078C141.385 54.5886 140.823 54.0267 140.823 53.3337V47.0591Z"
        fill="#E5E7EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.02 43.9219H145.216C143.483 43.9219 142.078 45.3265 142.078 47.0591V53.3337H162.157V47.0591C162.157 45.3265 160.752 43.9219 159.02 43.9219ZM145.216 42.667C142.79 42.667 140.823 44.6334 140.823 47.0591V53.3337C140.823 54.0267 141.385 54.5886 142.078 54.5886H162.157C162.85 54.5886 163.412 54.0267 163.412 53.3337V47.0591C163.412 44.6334 161.445 42.667 159.02 42.667H145.216Z"
        fill="#292A33"
      />
      <path
        d="M144.588 47.0591C144.588 46.7126 144.869 46.4316 145.216 46.4316H159.02C159.366 46.4316 159.647 46.7126 159.647 47.0591V47.0591C159.647 47.4056 159.366 47.6865 159.02 47.6865H145.216C144.869 47.6865 144.588 47.4056 144.588 47.0591V47.0591Z"
        fill="#292A33"
      />
      <path
        d="M145.843 36.3926V47.6867H158.392V39.5299H161.53C161.876 39.5299 162.157 39.249 162.157 38.9024V36.3926C162.157 35.0065 161.033 33.8828 159.647 33.8828H148.353C146.967 33.8828 145.843 35.0065 145.843 36.3926Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.392 39.5299H161.53C161.876 39.5299 162.157 39.249 162.157 38.9024V36.3926C162.157 35.0065 161.033 33.8828 159.647 33.8828H148.353C146.967 33.8828 145.843 35.0065 145.843 36.3926V47.6867H158.392V39.5299ZM157.137 46.4318V38.275H160.902V36.3926C160.902 35.6996 160.34 35.1377 159.647 35.1377H148.353C147.66 35.1377 147.098 35.6996 147.098 36.3926V46.4318H157.137Z"
        fill="#292A33"
      />
      <path
        d="M157.137 36.3926C157.137 35.0065 158.261 33.8828 159.647 33.8828L-nan -nanL-nan -nanL159.647 33.8828C161.033 33.8828 162.157 35.0065 162.157 36.3926V38.9024C162.157 39.249 161.876 39.5299 161.529 39.5299H157.137V36.3926Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.902 38.275V36.3926C160.902 35.6996 160.34 35.1377 159.647 35.1377C158.954 35.1377 158.392 35.6996 158.392 36.3926V38.275H160.902ZM159.647 33.8828C158.261 33.8828 157.137 35.0065 157.137 36.3926V39.5299H161.529C161.876 39.5299 162.157 39.249 162.157 38.9024V36.3926C162.157 35.0065 161.033 33.8828 159.647 33.8828Z"
        fill="#292A33"
      />
      <path
        d="M148.98 37.9612C148.98 37.7879 149.121 37.6475 149.294 37.6475H154.941C155.115 37.6475 155.255 37.7879 155.255 37.9612V38.5886C155.255 38.7619 155.115 38.9024 154.941 38.9024H149.294C149.121 38.9024 148.98 38.7619 148.98 38.5886V37.9612Z"
        fill="#292A33"
      />
      <path
        d="M148.98 40.471C148.98 40.2977 149.121 40.1572 149.294 40.1572H153.686C153.86 40.1572 154 40.2977 154 40.471V41.0984C154 41.2717 153.86 41.4121 153.686 41.4121H149.294C149.121 41.4121 148.98 41.2717 148.98 41.0984V40.471Z"
        fill="#292A33"
      />
      <path
        d="M148.98 42.9807C148.98 42.8075 149.121 42.667 149.294 42.667H154.941C155.115 42.667 155.255 42.8075 155.255 42.9807V43.6082C155.255 43.7814 155.115 43.9219 154.941 43.9219H149.294C149.121 43.9219 148.98 43.7814 148.98 43.6082V42.9807Z"
        fill="#292A33"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4979_37133"
        x={17.2354}
        y={28.8828}
        width={35.7256}
        height={18.1572}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4979_37133"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4979_37133"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_4979_37133"
        x={0.921631}
        y={35.7842}
        width={60.8235}
        height={18.1572}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4979_37133"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4979_37133"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_4979_37133"
        x={127.667}
        y={22.6074}
        width={46.3921}
        height={18.1572}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4979_37133"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4979_37133"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_4979_37133"
        x={118.255}
        y={29.5098}
        width={60.8235}
        height={18.1572}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4979_37133"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4979_37133"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_4979_37133"
        x={132.823}
        y={25.8828}
        width={38.5881}
        height={36.7061}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4979_37133"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4979_37133"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default PointOfSaleImage;
