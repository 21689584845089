import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../templates.theme";
import { themeComponent } from "../../../util/themeUtils";
import withWindowResize from "../../withWindowSize";
import { Box, Center, Cover } from "../../atoms/layouts";
import { COOL_GREY_05 } from "../../../constants/colors";

const CenterSingle = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  maxContentWidth = "36.75rem",
  fullWidthMobile = true,
  centeredMobileContent = false,
  content,
  backgroundColor = COOL_GREY_05,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Box
      padding="0"
      minWidth="100%"
      background={backgroundColor}
      extraStyles="flex-grow: 1;"
    >
      <Cover centerOverride={isMobile && !centeredMobileContent}>
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" extraStyles={`z-index: 1;`}>
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Center
            maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            intrinsic={!isMobile}
            role="main"
          >
            {centeredMobileContent ? (
              <Cover minHeight="100%" singleChild>
                {content}
              </Cover>
            ) : (
              <Box padding="0">{content}</Box>
            )}
          </Center>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
};

export default withWindowResize(
  themeComponent(CenterSingle, "Global", fallbackValues)
);
