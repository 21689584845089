const smallWords = [
  "a",
  "all",
  "an",
  "and",
  "are",
  "as",
  "at",
  "be",
  "but",
  "by",
  "do",
  "for",
  "from",
  "i",
  "in",
  "is",
  "not",
  "now",
  "of",
  "or",
  "on",
  "out",
  "so",
  "that",
  "the",
  "this",
  "to",
  "up",
  "us",
  "we",
  "with",
  "you"
];

export const isSmallWord = word => smallWords.includes(word);
