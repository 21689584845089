import React from "react";

import AccountBillIcon from "./AccountBillIcon";
import AccountGenericIcon from "./AccountGenericIcon";
import AccountConstructionIcon from "./AccountConstructionIcon";
import AccountDentalIcon from "./AccountDentalIcon";
import AccountElectricIcon from "./AccountElectricIcon";
import AccountGarbageIcon from "./AccountGarbageIcon";
import AccountGasIcon from "./AccountGasIcon";
import AccountMedicalIcon from "./AccountMedicalIcon";
import AccountWaterIcon from "./AccountWaterIcon";
import PropertyApartmentIcon from "./PropertyApartmentIcon";
import PropertyBusinessIcon from "./PropertyBusinessIcon";
import PropertyCarIcon from "./PropertyCarIcon";
import PropertyCommercialVehicleIcon from "./PropertyCommercialVehicleIcon";
import PropertyGarageIcon from "./PropertyGarageIcon";
import PropertyLandIcon from "./PropertyLandIcon";
import PropertyMotorcycleIcon from "./PropertyMotorcycleIcon";
import PropertyStorefrontIcon from "./PropertyStorefrontIcon";
import PropertyPersonalIcon from "./PropertyPersonalIcon";

const ACCOUNTS_GENERIC_ICON = "ACCOUNTS_GENERIC";
const ACCOUNTS_CONSTRUCTION_ICON = "ACCOUNTS_CONSTRUCTION";
const ACCOUNTS_HEALTH_ICON = "ACCOUNTS_HEALTH";
const ACCOUNTS_DENTAL_ICON = "ACCOUNTS_DENTAL";
const ACCOUNTS_UTILITY_ELECTRIC_ICON = "ACCOUNTS_UTILITY_ELECTRIC";
const ACCOUNTS_UTILITY_GAS_ICON = "ACCOUNTS_UTILITY_GAS";
const ACCOUNTS_UTILITY_GARBAGE_ICON = "ACCOUNTS_UTILITY_GARBAGE";
const ACCOUNTS_UTILITY_WATER_ICON = "ACCOUNTS_UTILITY_WATER";

const PROPERTIES_PERSONAL_ICON = "PROPERTIES_PERSONAL";
const PROPERTIES_GARAGE_ICON = "PROPERTIES_GARAGE";
const PROPERTIES_BUSINESS_ICON = "PROPERTIES_BUSINESS";
const PROPERTIES_STOREFRONT_ICON = "PROPERTIES_STOREFRONT";
const PROPERTIES_APARTMENT_ICON = "PROPERTIES_APARTMENT";
const PROPERTIES_LAND_ICON = "PROPERTIES_LAND";
const PROPERTIES_CAR_ICON = "PROPERTIES_CAR";
const PROPERTIES_MOTORCYCLE_ICON = "PROPERTIES_MOTORCYCLE";
const PROPERTIES_COMMERCIAL_AUTO_ICON = "PROPERTIES_COMMERCIAL_AUTO";

const MISC_BILL_ICON = "MISC_SINGLE_BILL";

export const iconsMap = {
  [ACCOUNTS_GENERIC_ICON]: ({ color }) => <AccountGenericIcon color={color} />,
  [ACCOUNTS_CONSTRUCTION_ICON]: ({ color }) => (
    <AccountConstructionIcon color={color} />
  ),
  [ACCOUNTS_HEALTH_ICON]: ({ color }) => <AccountMedicalIcon color={color} />,
  [ACCOUNTS_DENTAL_ICON]: ({ color }) => <AccountDentalIcon color={color} />,
  [ACCOUNTS_UTILITY_ELECTRIC_ICON]: ({ color }) => (
    <AccountElectricIcon color={color} />
  ),
  [ACCOUNTS_UTILITY_GARBAGE_ICON]: ({ color }) => (
    <AccountGarbageIcon color={color} />
  ),
  [ACCOUNTS_UTILITY_GAS_ICON]: ({ color }) => <AccountGasIcon color={color} />,
  [ACCOUNTS_UTILITY_WATER_ICON]: ({ color }) => (
    <AccountWaterIcon color={color} />
  ),
  [PROPERTIES_PERSONAL_ICON]: ({ color }) => (
    <PropertyPersonalIcon color={color} />
  ),
  [PROPERTIES_GARAGE_ICON]: ({ color }) => <PropertyGarageIcon color={color} />,
  [PROPERTIES_BUSINESS_ICON]: ({ color }) => (
    <PropertyBusinessIcon color={color} />
  ),
  [PROPERTIES_STOREFRONT_ICON]: ({ color }) => (
    <PropertyStorefrontIcon color={color} />
  ),
  [PROPERTIES_APARTMENT_ICON]: ({ color }) => (
    <PropertyApartmentIcon color={color} />
  ),
  [PROPERTIES_LAND_ICON]: ({ color }) => <PropertyLandIcon color={color} />,
  [PROPERTIES_CAR_ICON]: ({ color }) => <PropertyCarIcon color={color} />,
  [PROPERTIES_MOTORCYCLE_ICON]: ({ color }) => (
    <PropertyMotorcycleIcon color={color} />
  ),
  [PROPERTIES_COMMERCIAL_AUTO_ICON]: ({ color }) => (
    <PropertyCommercialVehicleIcon color={color} />
  ),
  [MISC_BILL_ICON]: ({ color }) => <AccountBillIcon color={color} />
};
