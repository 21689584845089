import React from "react";

const PaymentSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="366"
      height="209"
      viewBox="0 0 366 209"
    >
      <defs>
        <rect
          id="payment-search-path-1"
          width="64"
          height="64"
          x="39"
          y="40"
          rx="6"
        ></rect>
        <filter
          id="payment-search-filter-2"
          width="178.1%"
          height="178.1%"
          x="-39.1%"
          y="-35.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.192156863 0 0 0 0 0.490196078 0 0 0 0 0.309803922 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-537 -466)">
          <g transform="translate(132 332)">
            <g transform="translate(405 134)">
              <path
                fill="#FFF"
                d="M73 25h224v144.75a2.25 2.25 0 01-2.25 2.25H75.25a2.25 2.25 0 01-2.25-2.25V25z"
              ></path>
              <path
                fill="#292A33"
                fillRule="nonzero"
                d="M294.625 0A6.376 6.376 0 01301 6.377V157h19a3 3 0 01.176 5.995L320 163h-19v6.623a6.376 6.376 0 01-6.375 6.377H75.374A6.375 6.375 0 0169 169.623V163H33a3 3 0 01-.176-5.995L33 157h36V6.377A6.375 6.375 0 0175.374 0h219.251zM297 25H73v144.623c0 1.259.976 2.288 2.211 2.372l.163.005h219.251a2.376 2.376 0 002.37-2.214l.005-.163V25zm39.953 132l26.112.082a2.952 2.952 0 012.935 2.969 2.953 2.953 0 01-2.78 2.945l-.173.004-26.112-.082a2.952 2.952 0 01-2.935-2.969 2.953 2.953 0 012.78-2.945l.173-.004zM17 157a3 3 0 01.176 5.995L17 163H3a3 3 0 01-.176-5.995L3 157h14zm186-52.514a2.515 2.515 0 012.514 2.514c0 4.633-3.761 8.376-8.422 8.51l-.26.004h-23.664c-4.78 0-8.683-3.795-8.683-8.514a2.515 2.515 0 015.024-.165l.006.165c0 1.848 1.517 3.383 3.457 3.48l.196.005h23.664c1.968 0 3.549-1.475 3.648-3.301l.006-.184a2.515 2.515 0 012.514-2.514zM216 39c13.255 0 24 10.745 24 24 0 5.67-1.967 10.881-5.255 14.988l.012.01.13.114 8.51 8.049a5.002 5.002 0 015.193.818l.18.162 17.668 16.728a5 5 0 01-6.696 7.424l-.18-.162-17.667-16.728a5.001 5.001 0 01-1.26-5.348l-8.496-8.037a2.025 2.025 0 01-.132-.137A23.909 23.909 0 01216 87c-13.255 0-24-10.745-24-24C192 49.746 202.745 39 216 39zm0 5c-10.493 0-19 8.507-19 19s8.507 19 19 19 19-8.507 19-19-8.507-19-19-19zm-66 13.986c4.212 0 7.914 2.692 9.362 6.65a2.514 2.514 0 01-4.662 1.88l-.062-.153c-.734-2.009-2.576-3.349-4.639-3.349-1.988 0-3.772 1.246-4.554 3.136l-.083.213a2.515 2.515 0 01-4.724-1.726c1.446-3.958 5.148-6.651 9.361-6.651zM294.624 4H75.374a2.375 2.375 0 00-2.369 2.214L73 6.377V21h224V6.377a2.376 2.376 0 00-2.212-2.372L294.625 4zM81.5 10a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
              ></path>
              <path
                fill="#E4E6EB"
                d="M294.75 4A2.25 2.25 0 01297 6.25V21H73V6.25A2.25 2.25 0 0175.25 4zM81.5 10a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
              ></path>
              <path
                fill="#E4F4FD"
                d="M216 44c10.493 0 19 8.507 19 19s-8.507 19-19 19-19-8.507-19-19 8.507-19 19-19zm0 14c-4.206 0-7.903 2.689-9.348 6.641a2.5 2.5 0 004.696 1.718l.083-.214c.786-1.896 2.575-3.145 4.57-3.145 2.068 0 3.914 1.343 4.651 3.359l.061.152a2.5 2.5 0 004.635-1.87C223.903 60.69 220.207 58 216.001 58z"
              ></path>
              <path
                fill="#959CA8"
                fillRule="nonzero"
                d="M216 58c4.207 0 7.903 2.689 9.348 6.641a2.5 2.5 0 01-4.635 1.87l-.061-.152c-.737-2.016-2.583-3.359-4.651-3.359-1.995 0-3.784 1.25-4.57 3.145l-.083.214a2.5 2.5 0 01-4.696-1.718c1.445-3.952 5.142-6.641 9.349-6.641z"
              ></path>
              <use
                fill="#000"
                fillOpacity="1"
                filter="url(#payment-search-filter-2)"
                xlinkHref="#payment-search-path-1"
              ></use>
              <path
                fill="#E8FFEF"
                d="M185 185c28.719 0 52 5.373 52 12s-23.281 12-52 12c-28.718 0-52-5.373-52-12s23.282-12 52-12zM97 44a2 2 0 012 2v52a2 2 0 01-2 2H45a2 2 0 01-2-2V46a2 2 0 012-2h52zM74.134 56h-5.268c-.441 0-.806.304-.86.696l-.006.1v2.456c-4.391.845-7.029 3.74-7.144 7.532l-.004.26v.072c0 5.135 4.208 6.644 9.66 7.865 3.408.801 4.302 1.333 4.351 2.348l.002.093v.073c0 1.077-1.025 1.723-2.963 1.723-2.717 0-5.28-.875-7.66-2.542a.896.896 0 00-1.112.082l-.078.081-2.845 3.336a.84.84 0 00.11 1.21c2.061 1.596 4.522 2.668 7.182 3.207l.5.095.001 2.581c0 .37.3.677.688.725l.108.007h5.407c.403 0 .737-.275.79-.632l.007-.1v-2.602c4.212-.902 6.88-3.587 6.996-7.58l.004-.273v-.072c0-4.705-3.659-6.572-9.513-7.83-3.483-.767-4.411-1.263-4.462-2.344l-.002-.097v-.072c0-.934.842-1.616 2.562-1.616 2.041 0 4.32.669 6.462 2.007a.9.9 0 001.13-.126l.073-.089 2.566-3.556a.841.841 0 00-.185-1.18 14.999 14.999 0 00-5.178-2.318l-.451-.103-.002-2.62c0-.44-.388-.797-.866-.797z"
              ></path>
              <path
                fill="#317D4F"
                fillRule="nonzero"
                d="M182 176v20a4 4 0 01-3.8 3.995l-.2.005h-14a4 4 0 01-.2-7.995l.2-.005h10v-16h8zm14 0v16h10a4 4 0 013.995 3.8l.005.2a4 4 0 01-3.8 3.995l-.2.005h-14a4 4 0 01-3.995-3.8L188 196v-20h8zM69 128.5v9l-7.875 4.429 32.876 6.953c1.81-4.055 5.877-6.882 10.604-6.882a4 4 0 01.2 7.995l-.2.005a3.605 3.605 0 00-3.605 3.593v.035-.023a3.606 3.606 0 003.414 3.601l.191.005h6.644a4 4 0 01.2 7.995l-.2.005h-6.644c-5.246 0-9.679-3.48-11.115-8.259l-44.708-9.457c-3.58-.757-4.322-5.493-1.242-7.336l.175-.1L69 128.5zM263.644 96c6.184 0 11.24 4.838 11.586 10.936l38.713 7.13c3.304.609 4.407 4.749 1.927 6.93l-.15.125L301 133v-10.5l2.891-2.151-31.014-5.712a11.586 11.586 0 01-8.881 4.569l-.352.005H257l-.2-.005a4 4 0 010-7.99l.2-.005h6.644l.191-.005a3.606 3.606 0 003.414-3.601c0-1.99-.358-3.259-2.403-5.238a361.04 361.04 0 00-4.65-4.395A3.998 3.998 0 01263.644 96zM97 40a6 6 0 016 6v52a6 6 0 01-6 6H45a6 6 0 01-6-6V46a6 6 0 016-6h52zm0 4H45a2 2 0 00-1.995 1.85L43 46v52a2 2 0 001.85 1.995L45 100h52a2 2 0 001.995-1.85L99 98V46a2 2 0 00-1.85-1.995L97 44zM74.134 56c.478 0 .866.356.866.796l.002 2.621c2.128.452 3.983 1.265 5.629 2.42.383.27.457.804.185 1.181l-2.566 3.556c-.275.382-.801.466-1.203.215-2.142-1.338-4.42-2.007-6.462-2.007-1.72 0-2.562.682-2.562 1.616v.072c0 1.15.879 1.652 4.464 2.442C78.341 70.169 82 72.036 82 76.742v.07c0 4.144-2.692 6.932-7 7.854v2.602c0 .404-.357.732-.797.732h-5.407c-.44 0-.796-.328-.796-.732v-2.581c-2.852-.502-5.493-1.606-7.684-3.302a.84.84 0 01-.11-1.21l2.846-3.336a.898.898 0 011.19-.163c2.38 1.667 4.943 2.542 7.66 2.542 1.938 0 2.963-.646 2.963-1.723v-.073c0-1.076-.841-1.615-4.353-2.44-5.452-1.222-9.66-2.73-9.66-7.866v-.072c0-3.92 2.657-6.928 7.148-7.792v-2.456c0-.44.388-.796.866-.796h5.268z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PaymentSearchIcon;
