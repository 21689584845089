import React from "react";

const OverageIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16"
      cy="16"
      r="16"
      transform="rotate(-180 16 16)"
      fill="#D11053"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6465 7.15426L9.7539 13.0468C9.55864 13.2421 9.55864 13.5587 9.7539 13.7539L10.461 14.461C10.6563 14.6563 10.9728 14.6563 11.1681 14.461L15 10.6291L15 20.6667H11.8333C11.5572 20.6667 11.3333 20.8905 11.3333 21.1667L11.3333 22.1667C11.3333 22.4428 11.5572 22.6667 11.8333 22.6667L20.1667 22.6667C20.4428 22.6667 20.6667 22.4428 20.6667 22.1667V21.1667C20.6667 20.8905 20.4428 20.6667 20.1667 20.6667H17L17 10.6291L20.8319 14.461C21.0272 14.6563 21.3437 14.6563 21.539 14.461L22.2461 13.7539C22.4414 13.5587 22.4414 13.2421 22.2461 13.0468L16.3536 7.15426C16.1583 6.959 15.8417 6.959 15.6465 7.15426Z"
      fill="white"
    />
  </svg>
);

export default OverageIcon;
