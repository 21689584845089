import React from "react";

const CalendarIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -352.000000)"
        fill="#6D717E"
      >
        <path
          d="M80,384 C88.836556,384 96,376.836556 96,368 C96,359.163444 88.836556,352 80,352 C71.163444,352 64,359.163444 64,368 C64,376.836556 71.163444,384 80,384 Z M73,362 L87,362 C87.5522847,362 88,362.447715 88,363 L88,375 C88,375.552285 87.5522847,376 87,376 L73,376 C72.4477153,376 72,375.552285 72,375 L72,363 C72,362.447715 72.4477153,362 73,362 Z M86.5,366 L86.5,374.5 L73.5,374.5 L73.5,366 L86.5,366 Z M76.25,360.5 C76.3880712,360.5 76.5,360.611929 76.5,360.75 L76.5,362 L75,362 L75,360.75 C75,360.611929 75.1119288,360.5 75.25,360.5 L76.25,360.5 Z M84.75,360.5 C84.8880712,360.5 85,360.611929 85,360.75 L85,362 L83.5,362 L83.5,360.75 C83.5,360.611929 83.6119288,360.5 83.75,360.5 L84.75,360.5 Z"
          id="status-icon---calendar"
        ></path>
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
