import React from "react";
import styled from "styled-components";

export const SidebarWrapper = styled.div`
  overflow: visible;
  box-sizing: border-box;

  ${({ fullHeight }) => (fullHeight ? `height: 100%;` : ``)}
`;

export const SidebarInnerWrapper = styled(
  // eslint-disable-next-line no-unused-vars
  ({ onRight, childGap, contentMinWidth, minHeight, fullHeight, ...props }) => (
    <div {...props} />
  )
)`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: calc(${({ childGap }) => childGap} / 2 * -1);

  > * {
    margin: calc(${({ childGap }) => childGap} / 2);
    flex-grow: 1;
    ${({ width }) => (width ? `flex-basis: ${width}` : ``)};
    ${({ minHeight }) => (minHeight ? `margin-top: 0; margin-bottom: 0;` : ``)}
  }

  ${({ $sidebarOnRight, contentMinWidth, childGap }) =>
    $sidebarOnRight
      ? `> :first-child {
      flex-basis: 0;
      flex-grow: 999;
      min-width: calc(${contentMinWidth} - ${childGap});
    }
    `
      : `> :last-child {
      flex-basis: 0;
      flex-grow: 999;
      min-width: calc(${contentMinWidth} - ${childGap});
    }`}

  ${({ fullHeight }) => (fullHeight ? `min-height: 100%;` : ``)}
`;
