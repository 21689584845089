import React from "react";
import { WHITE, RAZZMATAZZ_RED } from "../../constants/colors";

export const IconInvalid = ({
  bgFill = RAZZMATAZZ_RED,
  iconFill = WHITE,
  width = "18",
  height = "18",
  margin = "0"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ margin }}
    aria-label="Invalid"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-538.000000, -1064.000000)" fillRule="nonzero">
        <g transform="translate(457.000000, 938.000000)">
          <g transform="translate(81.000000, 20.000000)">
            <g transform="translate(0.000000, 106.000000)">
              <circle
                stroke={bgFill}
                fill={bgFill}
                cx="9"
                cy="9"
                r="8.5"
              ></circle>
              <g transform="translate(2.000000, 2.000000)" fill={iconFill}>
                <path
                  d="M7.58333333,7.58333333 L7.58333333,11.6666667 L6.41666667,11.6666667 L6.41666667,7.58333333 L2.33333333,7.58333333 L2.33333333,6.41666667 L6.41666667,6.41666667 L6.41666667,2.33333333 L7.58333333,2.33333333 L7.58333333,6.41666667 L11.6666667,6.41666667 L11.6666667,7.58333333 L7.58333333,7.58333333 Z"
                  transform="translate(7.000000, 7.000000) rotate(45.000000) translate(-7.000000, -7.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
