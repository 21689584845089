import React from "react";
import styled, { css } from "styled-components";
import { fallbackValues } from "./Spinner.theme";
import { themeComponent } from "../../../util/themeUtils";

const SpinnerSvgAnimation = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  & .path {
    stroke: ${({ color }) => color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  ${({ centerSpinner }) =>
    centerSpinner
      ? css`
          margin: 0;
        `
      : ""}
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1;
  ${({ centerSpinner, size }) =>
    centerSpinner
      ? css`
          width: ${size}px;
          height: ${size}px;
        `
      : ""}
`;

/*
  `centerSpinner` prop alters existing styling of spinner to allow it to properly center itself within
  containers. Default is false to preserve legacy behavior for past uses.
*/

const Spinner = ({
  size = "24",
  centerSpinner = false,
  themeValues,
  cx = "50",
  cy = "50",
  radius = "25",
  strokeWidth = "6"
}) => (
  <SpinnerContainer centerSpinner={centerSpinner} size={size}>
    <SpinnerSvgAnimation
      size={size}
      color={themeValues.color}
      centerSpinner={centerSpinner}
    >
      <circle
        className="path"
        cx={cx}
        cy={cy}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
      />
    </SpinnerSvgAnimation>
  </SpinnerContainer>
);

export default themeComponent(Spinner, "Spinner", fallbackValues);
