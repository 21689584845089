import { BRIGHT_GREY, CHARADE_GREY } from "../../../constants/colors";

const color = {
  default: `${CHARADE_GREY}`,
  large: `${BRIGHT_GREY}`
};
const height = {
  default: "3rem",
  large: "192px"
};

export const fallbackValues = {
  color,
  height
};
