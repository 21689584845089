import { WHITE, GHOST_GREY, ATHENS_GREY } from "../../../constants/colors";

const headingBackgroundColor = `${WHITE}`;
const headingDisabledColor = `${ATHENS_GREY}`;
const bodyBackgroundColor = "#eeeeee";
const borderColor = `${GHOST_GREY}`;
const focusStyles = `outline: none;`;

export const fallbackValues = {
  headingBackgroundColor,
  headingDisabledColor,
  bodyBackgroundColor,
  borderColor,
  focusStyles
};
