import React from "react";
import {
  Title,
  Paragraph,
  EmptyCartIconV2,
  Text,
  constants,
  Stack,
  Center
} from "@thecb/components";
import { SPACING } from "../MultiCart.theme";
import SearchIcon from "../../../../../components/icons/SearchIcon";
import { StyledFindPaymentsButton } from "../MultiCart.styled";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../constants/style_constants";
import { GHOST_GREY } from "../../../../../constants/colors";
import ScreenreaderOnlyText from "../../../../../components/screenreder-only-text";

const CartEmpty = ({
  closeCartSlider,
  config,
  isLoggedIn,
  inSlider,
  isMobile,
  navigate
}) => (
  <Stack
    childGap={isMobile ? SPACING.MD : SPACING.XL}
    justify="center"
    style={{
      minHeight: inSlider ? "auto" : "339px",
      margin: inSlider && `0 ${SPACING.LG}`
    }}
  >
    <Center>
      <EmptyCartIconV2
        color={GHOST_GREY}
        height={isMobile ? "5.5rem" : "7rem"}
      />
    </Center>
    <Center>
      <ScreenreaderOnlyText role="alert" aria-live="polite">
        No Items in Cart
      </ScreenreaderOnlyText>
      <Title
        as={inSlider ? "h3" : "h2"}
        variant="large"
        weight={constants.styleConstants.FONT_WEIGHT_SEMIBOLD}
        textAlign="center"
        extraStyles={isMobile && "font-size: 1.313rem;"}
      >
        No Items in Cart
      </Title>
      <Paragraph variant={isMobile ? "pXS" : "pS"} margin="0.25rem 0 0">
        You haven't added any items yet
      </Paragraph>
    </Center>
    {isLoggedIn && (
      <StyledFindPaymentsButton
        contentOverride
        borderRadius="8px"
        action={() => {
          closeCartSlider();
          navigate("/profile/find");
        }}
        isMobile={isMobile}
      >
        <Center as="span" intrinsic>
          <SearchIcon color={constants.colors.WHITE} />
          <Text
            variant={isMobile ? "pS" : "p"}
            color={constants.colors.WHITE}
            weight={FONT_WEIGHT_SEMIBOLD}
          >
            Find Payments
          </Text>
        </Center>
      </StyledFindPaymentsButton>
    )}
  </Stack>
);

export default CartEmpty;
