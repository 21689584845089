import React, { useEffect } from "react";
import { required } from "redux-freeform";
import {
  FormInput,
  FormContainer,
  FormInputColumn
} from "../../atoms/form-layouts";
import { noop } from "../../../util/general";

const EditNameForm = ({
  fields,
  actions,
  clearOnDismount,
  showErrors,
  handleSubmit = noop
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }

  const firstNameErrorMessages = {
    [required.error]: "First name is required"
  };
  const lastNameErrorMessages = {
    [required.error]: "Last name is required"
  };

  return (
    <FormContainer role="form" aria-label="Edit name">
      <FormInputColumn>
        <FormInput
          labelTextWhenNoError="First Name"
          dataQa="Edit First Name"
          errorMessages={firstNameErrorMessages}
          field={fields.firstName}
          fieldActions={actions.fields.firstName}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="given-name"
        />
        <FormInput
          labelTextWhenNoError="Last Name"
          dataQa="Edit Last Name"
          errorMessages={lastNameErrorMessages}
          field={fields.lastName}
          fieldActions={actions.fields.lastName}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="family-name"
        />
      </FormInputColumn>
    </FormContainer>
  );
};

export default EditNameForm;
