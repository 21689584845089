import React, { Fragment, useLayoutEffect } from "react";
import { useRoutes } from "react-router-dom";
import { matchRoutes } from "react-router";
import {
  Box,
  Stack,
  Switcher,
  SolidDivider,
  DefaultPageTemplate,
  constants
} from "@thecb/components";
import AlertBar from "../../../../../../components/alert-bar";
import { expiredSessionPaths } from "../../constants";

const SUBPAGE_WIDTH = 55;
const DETAILS_WIDTH = 42;

const PaymentProgress = ({
  subRoutes,
  isMobile,
  mobileWidth,
  paymentDetailsContent,
  subHeader,
  gutterPx,
  alerts
}) => {
  const numOfAlerts = alerts?.alerts?.length ?? 0;
  useLayoutEffect(() => {
    if (numOfAlerts > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [numOfAlerts]);
  const currentLocation = window.location;
  const expiredSessionTestPaths = expiredSessionPaths.map(path => ({ path }));
  const onExpiredSessionPage =
    matchRoutes(expiredSessionTestPaths, currentLocation) !== null;
  const childElems = useRoutes(subRoutes);
  const content = (
    <Switcher
      breakpoint={`${mobileWidth - gutterPx * 2}px`}
      childGap={isMobile ? "16px" : "0"}
      extraStyles="overflow: visible;"
    >
      {isMobile && (
        <Box
          key="payment details content"
          padding="2px 0 0"
          background={isMobile && constants.colors.WHITE}
          minWidth={isMobile && "100vw"}
        >
          <Box
            padding={`0 ${gutterPx}px`}
            as="section"
            aria-labelledby="payment-details-title"
          >
            {paymentDetailsContent}
          </Box>
          <Box padding="8px 0" />
          <SolidDivider />
        </Box>
      )}
      <Box
        key="payment subpage"
        padding={`${isMobile ? `0 ${gutterPx}px` : "1.5rem 0"} 0`}
        maxWidth={!isMobile && `${SUBPAGE_WIDTH}%`}
        as="section"
        aria-labelledby="checkout-section-title"
      >
        <Stack childGap="0">
          {alerts.alerts.length > 0 ? (
            <AlertBar key="alert bar" {...alerts} />
          ) : (
            <Fragment key="alert bar" />
          )}
          <Box key="child elements" padding="0">
            {childElems}
          </Box>
        </Stack>
      </Box>
      {!isMobile && (
        <Fragment>
          <Box padding="0 1rem" maxWidth="0" />
          <Box
            key="payment details content"
            padding="0"
            maxWidth={`${DETAILS_WIDTH}%`}
            as="section"
            aria-labelledby="payment-details-title"
          >
            {paymentDetailsContent}
          </Box>
        </Fragment>
      )}
    </Switcher>
  );

  return (
    <DefaultPageTemplate
      subHeader={onExpiredSessionPage ? <Fragment /> : subHeader}
      content={content}
      subHeaderHeight="5rem"
      gutters={isMobile ? "0" : `${gutterPx}px`}
      maxWidth="1176px"
    />
  );
};

export default PaymentProgress;
