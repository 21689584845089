const backgroundColor = {
  profile: "#3b414d",
  cms: "#3b414d"
};

const shadowColor = {
  profile: "#292A33",
  cms: "#292A33"
};

export const fallbackValues = {
  backgroundColor,
  shadowColor
};
