import React from "react";
export const AlertErrorIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M15.5714286,7 L12,10.572 L8.42857143,7 L7,8.42857143 L10.571,12 L7,15.5714286 L8.42857143,17 L11.999,13.428 L15.5714286,17 L17,15.5714286 L13.428,12 L17,8.42857143 L15.5714286,7 Z"
        id="path-1-error-icon"
      ></path>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Alert/Error" transform="translate(-24.000000, -32.000000)">
        <g
          id="Icon/Error-Container"
          transform="translate(24.000000, 32.000000)"
        >
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1-error-icon"></use>
          </mask>
          <use
            id="icon---error"
            fill="#ED125F"
            fillRule="nonzero"
            xlinkHref="#path-1-error-icon"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);
