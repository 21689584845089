import React from "react";

const XCircleIconSmall = ({ color = "#D11053", iconIndex = 0 }) => {
  const id = `XCircleIconSmall-${iconIndex}`;
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8298 6.18691C13.6345 5.99165 13.3179 5.99165 13.1227 6.18691L10.5 8.80955L7.87739 6.18691C7.68213 5.99165 7.36554 5.99165 7.17028 6.18691L6.68691 6.67028C6.49165 6.86554 6.49165 7.18213 6.68691 7.37739L9.30955 10L6.68691 12.6227C6.49165 12.8179 6.49165 13.1345 6.68691 13.3298L7.17028 13.8131C7.36554 14.0084 7.68213 14.0084 7.87739 13.8131L10.5 11.1905L13.1227 13.8131C13.3179 14.0084 13.6345 14.0084 13.8298 13.8131L14.3131 13.3298C14.5084 13.1345 14.5084 12.8179 14.3131 12.6227L11.6905 10L14.3131 7.37739C14.5084 7.18213 14.5084 6.86554 14.3131 6.67028L13.8298 6.18691Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="9"
        height="8"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8298 6.18691C13.6345 5.99165 13.3179 5.99165 13.1227 6.18691L10.5 8.80955L7.87739 6.18691C7.68213 5.99165 7.36554 5.99165 7.17028 6.18691L6.68691 6.67028C6.49165 6.86554 6.49165 7.18213 6.68691 7.37739L9.30955 10L6.68691 12.6227C6.49165 12.8179 6.49165 13.1345 6.68691 13.3298L7.17028 13.8131C7.36554 14.0084 7.68213 14.0084 7.87739 13.8131L10.5 11.1905L13.1227 13.8131C13.3179 14.0084 13.6345 14.0084 13.8298 13.8131L14.3131 13.3298C14.5084 13.1345 14.5084 12.8179 14.3131 12.6227L11.6905 10L14.3131 7.37739C14.5084 7.18213 14.5084 6.86554 14.3131 6.67028L13.8298 6.18691Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M0.5 0H20.5V20H0.5V0Z" fill="white" />
      </g>
    </svg>
  );
};

export default XCircleIconSmall;
