import React, { Fragment, useContext } from "react";
import { ThemeContext } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Cover } from "../layouts";
import Text from "../text";
import Paragraph from "../paragraph";
import ButtonWithAction from "../button-with-action";
import { fallbackValues } from "./CardRegistry.theme";
import { themeComponent } from "../../../util/themeUtils";
import {
  AccountsAddIcon,
  PropertiesAddIcon,
  PaymentMethodAddIcon
} from "../icons";
import withWindowSize from "../../withWindowSize";

const CardVariantSwitcher = ({ variant, children }) => {
  return variant === "vertical" ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Box padding="0" extraStyles={`width: 100%`}>
      <Stack childGap="0" maxWidth="100%" fullHeight>
        {children}
      </Stack>
    </Box>
  );
};

const CardRegistryCard = ({
  themeValues,
  icon,
  heading,
  text,
  cardAction = "/profile",
  buttonText,
  variant = "vertical"
}) => {
  const { isMobile } = useContext(ThemeContext);
  let navigate = useNavigate();

  const renderIcon = icon => {
    switch (icon) {
      case "accounts":
        return <AccountsAddIcon />;
      case "properties":
        return <PropertiesAddIcon />;
      case "payment":
        return <PaymentMethodAddIcon />;
      default:
        return <PaymentMethodAddIcon />;
    }
  };

  return (
    <Box
      background={themeValues.backgroundColor}
      borderRadius="4px"
      boxShadow=" 0px 1px 10px 0px rgb(246, 246, 249),
    0px 2px 5px 0px rgb(202, 206, 216)"
      padding="0"
      maxWidth={variant !== "vertical" ? "100%" : "306px"}
      minHeight="100%"
      minWidth={variant !== "vertical" && "300px"}
    >
      <Cover singleChild fillCenter>
        <Stack
          direction={variant === "vertical" ? "column" : "row"}
          justify={variant === "vertical" && "space-evenly"}
          fullHeight
          childGap="0"
        >
          {icon && (
            <Box padding="0" background={themeValues.iconBackgroundColor}>
              <Stack
                direction={variant === "vertical" ? "row" : "column"}
                justify="center"
                fullHeight
              >
                <Box
                  padding={
                    variant === "vertical"
                      ? "0.5rem 0"
                      : isMobile
                      ? "1rem 1.5rem"
                      : "2rem 2.5rem"
                  }
                >
                  {renderIcon(icon)}
                </Box>
              </Stack>
            </Box>
          )}
          <CardVariantSwitcher variant={variant}>
            <Box
              padding="1rem"
              width="100%"
              extraStyles={`flex-grow: 1; width: 100%;`}
            >
              <Cover singleChild fillCenter>
                <Box padding="0">
                  <Paragraph variant="p" weight="600">
                    {heading}
                  </Paragraph>
                  <Text variant="pS">{text}</Text>
                </Box>
              </Cover>
            </Box>
            <Box
              padding="0.5rem 1rem 1rem"
              extraStyles={`display: flex; justify-content: center;`}
            >
              <ButtonWithAction
                variant="smallPrimary"
                text={buttonText}
                action={() => navigate(cardAction)}
                extraStyles={`width: 100%;`}
              />
            </Box>
          </CardVariantSwitcher>
        </Stack>
      </Cover>
    </Box>
  );
};

export default themeComponent(
  withWindowSize(CardRegistryCard),
  "CardRegistryCard",
  fallbackValues
);
