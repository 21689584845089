import React from "react";

const StandardCheckoutImage = () => {
  return (
    <svg
      width="191"
      height="96"
      viewBox="0 0 191 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9545 28.1101C12.9276 28.1105 12.9007 28.1107 12.8736 28.1107C10.1502 28.1107 7.94238 25.909 7.94238 23.1931C7.94238 20.4771 10.1502 18.2754 12.8736 18.2754C13.9861 18.2754 15.0124 18.6427 15.8375 19.2624C16.6943 16.4514 19.3141 14.4058 22.4129 14.4058C25.9975 14.4058 28.9411 17.1431 29.2564 20.636C29.9741 20.0667 30.8828 19.7265 31.8711 19.7265C34.1928 19.7265 36.0748 21.6034 36.0748 23.9186C36.0748 26.2338 34.1928 28.1107 31.8711 28.1107C31.817 28.1107 31.7631 28.1097 31.7095 28.1077V28.111H12.9545V28.1101Z"
        fill="#B6C3F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5877 59.7212C17.5746 59.7214 17.5614 59.7215 17.5482 59.7215C16.2186 59.7215 15.1406 58.6466 15.1406 57.3206C15.1406 55.9945 16.2186 54.9196 17.5482 54.9196C18.0914 54.9196 18.5925 55.0989 18.9953 55.4014C19.4136 54.029 20.6927 53.0303 22.2056 53.0303C23.9559 53.0303 25.3931 54.3669 25.5469 56.0724C25.8973 55.7944 26.341 55.6283 26.8235 55.6283C27.957 55.6283 28.8759 56.5447 28.8759 57.6751C28.8759 58.8054 27.957 59.7218 26.8235 59.7218C26.7971 59.7218 26.7708 59.7213 26.7446 59.7203V59.7217H17.5877V59.7212Z"
        fill="#B6C3F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.253 16.466C151.171 16.4729 151.088 16.4765 151.004 16.4765C149.405 16.4765 148.108 15.1835 148.108 13.5885C148.108 11.9935 149.405 10.7005 151.004 10.7005C151.199 10.7005 151.39 10.7197 151.574 10.7563C152.095 8.82294 153.865 7.3999 155.969 7.3999C157.928 7.3999 159.598 8.63467 160.239 10.3665C160.449 10.2635 160.684 10.2057 160.933 10.2057C161.519 10.2057 162.029 10.5246 162.3 10.9978C162.686 10.8074 163.121 10.7005 163.581 10.7005C165.181 10.7005 166.477 11.9935 166.477 13.5885C166.477 15.1835 165.181 16.4765 163.581 16.4765C163.554 16.4765 163.526 16.4761 163.499 16.4753V16.4765H151.253V16.466Z"
        fill="#B6C3F1"
      />
      <ellipse
        cx="91.9267"
        cy="87.1911"
        rx="19.5273"
        ry="4.12571"
        fill="#B6C3F1"
      />
      <path
        d="M49.2305 21.9741H134.621V70.2083C134.621 73.9369 131.598 76.9595 127.87 76.9595H55.9817C52.2531 76.9595 49.2305 73.9369 49.2305 70.2083V21.9741Z"
        fill="white"
      />
      <path
        d="M47.5762 21.9738C47.5762 16.4312 52.0694 11.938 57.612 11.938H126.24C131.783 11.938 136.276 16.4312 136.276 21.9738V21.9738H47.5762V21.9738Z"
        fill="#B6C3F1"
      />
      <ellipse
        cx="62.0555"
        cy="16.956"
        rx="1.24102"
        ry="1.23771"
        fill="#292A33"
      />
      <ellipse
        cx="57.9188"
        cy="16.956"
        rx="1.24102"
        ry="1.23771"
        fill="#292A33"
      />
      <ellipse
        cx="53.782"
        cy="16.956"
        rx="1.24102"
        ry="1.23771"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.359 14.1685H52.4938C51.0124 14.1685 49.8115 15.3668 49.8115 16.8451V73.7024C49.8115 75.1807 51.0124 76.379 52.4938 76.379H131.359C132.84 76.379 134.041 75.1807 134.041 73.7024V16.8451C134.041 15.3668 132.84 14.1685 131.359 14.1685ZM52.4938 11.938C49.7779 11.938 47.5762 14.135 47.5762 16.8451V73.7024C47.5762 76.4125 49.7779 78.6095 52.4938 78.6095H131.359C134.075 78.6095 136.276 76.4125 136.276 73.7024V16.8451C136.276 14.135 134.075 11.938 131.359 11.938H52.4938Z"
        fill="#292A33"
      />
      <rect
        x="163.085"
        y="70.0283"
        width="12.5769"
        height="2.64072"
        rx="1.32036"
        fill="#292A33"
      />
      <path
        d="M136.276 70.0283H157.793C158.522 70.0283 159.113 70.6195 159.113 71.3487V71.3487C159.113 72.0779 158.522 72.669 157.793 72.669H136.276V70.0283Z"
        fill="#292A33"
      />
      <path
        d="M29.373 71.3487C29.373 70.6195 29.9642 70.0283 30.6934 70.0283H47.5764V72.669H30.6934C29.9642 72.669 29.373 72.0779 29.373 71.3487V71.3487Z"
        fill="#292A33"
      />
      <rect
        x="17.9541"
        y="70.0283"
        width="8.10877"
        height="2.64072"
        rx="1.32036"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.197 37.9328C103.47 37.9328 102.811 38.4623 102.629 39.2477C102.503 39.7935 101.957 40.134 101.41 40.0082C100.863 39.8824 100.522 39.3379 100.648 38.7921C101.024 37.1661 102.444 35.9043 104.197 35.9043C105.951 35.9043 107.37 37.1661 107.746 38.7921C107.872 39.3379 107.531 39.8824 106.984 40.0082C106.437 40.134 105.891 39.7935 105.765 39.2477C105.584 38.4623 104.924 37.9328 104.197 37.9328Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5253 37.9328C81.7984 37.9328 81.1387 38.4623 80.9573 39.2477C80.8312 39.7935 80.2856 40.134 79.7386 40.0082C79.1916 39.8824 78.8503 39.3379 78.9764 38.7921C79.3519 37.1661 80.7718 35.9043 82.5253 35.9043C84.2788 35.9043 85.6986 37.1661 86.0741 38.7921C86.2002 39.3379 85.859 39.8824 85.312 40.0082C84.765 40.134 84.2193 39.7935 84.0933 39.2477C83.9119 38.4623 83.2521 37.9328 82.5253 37.9328Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.8895 50.9126C87.4508 50.9126 87.9059 51.3667 87.9059 51.9268C87.9059 52.7759 88.5957 53.4642 89.4466 53.4642H97.275C98.1259 53.4642 98.8157 52.7759 98.8157 51.9268C98.8157 51.3667 99.2708 50.9126 99.8321 50.9126C100.393 50.9126 100.849 51.3667 100.849 51.9268C100.849 53.8962 99.2486 55.4927 97.275 55.4927H89.4466C87.473 55.4927 85.873 53.8962 85.873 51.9268C85.873 51.3667 86.3281 50.9126 86.8895 50.9126Z"
        fill="#292A33"
      />
      <rect
        x="47.5762"
        y="20.3237"
        width="88.7001"
        height="1.65045"
        fill="#292A33"
      />
      <g filter="url(#filter0_d_2902_16320)">
        <path
          d="M136.276 20.1899H169.341C171.826 20.1899 173.842 22.205 173.842 24.6907V24.9307C173.842 27.4165 171.826 29.4315 169.341 29.4315H136.276V20.1899Z"
          fill="white"
        />
        <rect
          x="162.754"
          y="23.8208"
          width="7.44684"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
        <rect
          x="155.638"
          y="23.8208"
          width="4.46811"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
        <rect
          x="142.399"
          y="23.8208"
          width="10.591"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
        <path
          d="M136.276 23.8208H138.761C139.308 23.8208 139.752 24.2642 139.752 24.8111V24.8111C139.752 25.358 139.308 25.8013 138.761 25.8013H136.276V23.8208Z"
          fill="#959CA8"
        />
      </g>
      <g filter="url(#filter1_d_2902_16320)">
        <rect
          x="126.347"
          y="28.1108"
          width="53.2862"
          height="9.2416"
          rx="4.5008"
          fill="white"
        />
        <rect
          x="164.739"
          y="31.7417"
          width="11.253"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
        <rect
          x="148.19"
          y="31.7417"
          width="13.9008"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
        <rect
          x="140.744"
          y="31.7417"
          width="4.79907"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
        <rect
          x="129.987"
          y="31.7417"
          width="8.10878"
          height="1.98054"
          rx="0.99027"
          fill="#959CA8"
        />
      </g>
      <path
        d="M88.2848 85.5411V78.6099H90.9325V84.8059C90.9325 86.6702 89.4212 88.1815 87.5569 88.1815H84.3095C83.5803 88.1815 82.9893 87.5904 82.9893 86.8613C82.9893 86.1321 83.5803 85.5411 84.3095 85.5411H88.2848Z"
        fill="#3B5BDB"
      />
      <path
        d="M93.5801 84.8059V78.6099H96.2278V85.5411H100.203C100.932 85.5411 101.523 86.1321 101.523 86.8613C101.523 87.5904 100.932 88.1815 100.203 88.1815H96.9557C95.0914 88.1815 93.5801 86.6702 93.5801 84.8059Z"
        fill="#3B5BDB"
      />
      <path
        d="M136.275 57.4861V60.7867L144.526 65.2615C145.226 65.6411 146.093 65.5128 146.653 64.9468L157.292 54.1855H153.651L145.62 62.5146C145.478 62.6628 145.252 62.6943 145.074 62.5909L136.275 57.4861Z"
        fill="#3B5BDB"
      />
      <path
        d="M150.011 52.8653C150.011 52.1361 150.603 51.5449 151.334 51.5449H162.257C162.988 51.5449 163.581 52.1361 163.581 52.8653V52.8653C163.581 53.5945 162.988 54.1856 162.257 54.1856H151.334C150.603 54.1856 150.011 53.5945 150.011 52.8653V52.8653Z"
        fill="#3B5BDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.0598 63.1006C66.8563 63.1974 67.4234 63.9203 67.3264 64.7151C67.2642 65.225 67.232 65.7448 67.232 66.2726C67.232 68.2087 67.6641 70.0401 68.4359 71.6798C68.777 72.4045 68.4648 73.268 67.7385 73.6084C67.0122 73.9489 66.1468 73.6373 65.8057 72.9125C64.8561 70.8952 64.3262 68.6436 64.3262 66.2726C64.3262 65.6273 64.3654 64.9905 64.4418 64.3645C64.5389 63.5697 65.2632 63.0038 66.0598 63.1006Z"
        fill="#3B5BDB"
      />
      <path
        d="M47.5758 54.6778V57.8819L45.9209 58.594C52.1818 61.3531 66.1101 67.4944 66.1101 67.4944V70.5205L42.8235 60.2201C41.5667 59.6641 41.5203 57.7668 42.7481 57.1404L47.5758 54.6778Z"
        fill="#3B5BDB"
      />
      <g filter="url(#filter2_d_2902_16320)">
        <rect
          x="147.529"
          y="30.5864"
          width="18.5343"
          height="18.4832"
          rx="8.439"
          fill="#3B5BDB"
        />
      </g>
      <path
        d="M157.607 45.072V44.0848C159.069 43.8917 159.983 43.0534 159.983 41.8511C159.983 40.6543 159.386 39.7829 157.946 39.4354L156.67 39.121C155.811 38.8949 155.559 38.6633 155.559 38.2C155.559 37.6209 156.095 37.2238 156.966 37.2238C157.809 37.2238 158.127 37.654 158.226 38.3599L159.841 38.3324C159.857 37.1907 159.02 36.2421 157.64 35.9994V34.9019H156.254V35.9939C154.841 36.209 153.834 37.0859 153.845 38.3875C153.861 39.6119 154.639 40.3234 155.849 40.5716L157.147 40.8528C157.919 41.0293 158.286 41.272 158.286 41.8621C158.286 42.4302 157.722 42.8549 156.933 42.8549C156.057 42.8549 155.444 42.4688 155.274 41.7408L153.609 41.7739C153.757 43.1692 154.726 43.9193 156.227 44.0903V45.072H157.607Z"
        fill="white"
      />
      <mask
        id="mask0_2902_16320"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="153"
        y="34"
        width="7"
        height="12"
      >
        <path
          d="M157.607 45.072V44.0848C159.069 43.8917 159.983 43.0534 159.983 41.8511C159.983 40.6543 159.386 39.7829 157.946 39.4354L156.67 39.121C155.811 38.8949 155.559 38.6633 155.559 38.2C155.559 37.6209 156.095 37.2238 156.966 37.2238C157.809 37.2238 158.127 37.654 158.226 38.3599L159.841 38.3324C159.857 37.1907 159.02 36.2421 157.64 35.9994V34.9019H156.254V35.9939C154.841 36.209 153.834 37.0859 153.845 38.3875C153.861 39.6119 154.639 40.3234 155.849 40.5716L157.147 40.8528C157.919 41.0293 158.286 41.272 158.286 41.8621C158.286 42.4302 157.722 42.8549 156.933 42.8549C156.057 42.8549 155.444 42.4688 155.274 41.7408L153.609 41.7739C153.757 43.1692 154.726 43.9193 156.227 44.0903V45.072H157.607Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2902_16320)">
        <rect
          x="149.147"
          y="32.2002"
          width="15.2974"
          height="15.2552"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2902_16320"
          x="125.125"
          y="9.03863"
          width="59.8681"
          height="31.5443"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.57566" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2902_16320"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2902_16320"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2902_16320"
          x="115.195"
          y="16.9595"
          width="75.5888"
          height="31.5443"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.57566" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2902_16320"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2902_16320"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2902_16320"
          x="132.529"
          y="15.5864"
          width="48.5342"
          height="48.4834"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.454902 0 0 0 0 0.615686 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2902_16320"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2902_16320"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default StandardCheckoutImage;
