import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const FindIconSmall = ({ themeValues, iconIndex = 0 }) => {
  const maskId = `find-icon-mask-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4142 4.34313C13.1295 6.05839 13.338 8.70983 12.0397 10.6527L15.9968 14.6098C16.1921 14.805 16.1921 15.1216 15.9968 15.3169L15.4665 15.8472C15.2712 16.0425 14.9546 16.0425 14.7594 15.8472L10.8287 11.9165C8.87269 13.3493 6.1108 13.1818 4.34315 11.4142C2.39053 9.46157 2.39053 6.29575 4.34315 4.34313C6.29577 2.39051 9.4616 2.39051 11.4142 4.34313ZM10.4246 10.4246C11.7915 9.05777 11.7915 6.84169 10.4246 5.47486C9.05779 4.10802 6.84171 4.10802 5.47488 5.47486C4.10804 6.84169 4.10804 9.05777 5.47488 10.4246C6.84171 11.7914 9.05779 11.7914 10.4246 10.4246Z"
        fill={themeValues.singleIconColor}
      />
      <mask
        id={maskId}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="15"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4142 4.34313C13.1295 6.05839 13.338 8.70983 12.0397 10.6527L15.9968 14.6098C16.1921 14.805 16.1921 15.1216 15.9968 15.3169L15.4665 15.8472C15.2712 16.0425 14.9546 16.0425 14.7594 15.8472L10.8287 11.9165C8.87269 13.3493 6.1108 13.1818 4.34315 11.4142C2.39053 9.46157 2.39053 6.29575 4.34315 4.34313C6.29577 2.39051 9.4616 2.39051 11.4142 4.34313ZM10.4246 10.4246C11.7915 9.05777 11.7915 6.84169 10.4246 5.47486C9.05779 4.10802 6.84171 4.10802 5.47488 5.47486C4.10804 6.84169 4.10804 9.05777 5.47488 10.4246C6.84171 11.7914 9.05779 11.7914 10.4246 10.4246Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect width="20" height="20" fill={themeValues.singleIconColor} />
      </g>
    </svg>
  );
};

export default themeComponent(
  FindIconSmall,
  "Icons",
  fallbackValues,
  "primary"
);
