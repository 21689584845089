import React from "react";

const ResetPasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="80"
      viewBox="0 0 86 80"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-677 -192)">
          <g transform="translate(432 168)">
            <g transform="translate(245 24)">
              <path
                fill="#FFF"
                d="M36 2.44311523L50.2941895 1.66943359 67 7.32739258 78 18.2668457 85 35.1682129 83 53.0935059 75.1977539 66.3569336 62.1745605 75.9748535 46 80 36 78z"
              ></path>
              <path
                fill="#E5E7EC"
                d="M46 6h17.5l11.749 10H46a1 1 0 01-1-1V7a1 1 0 011-1zm27 62L58.5 78H46a1 1 0 01-1-1v-8a1 1 0 011-1h27zm10-16l-4.506 10H46a1 1 0 01-1-1v-8a1 1 0 011-1h37zm2-16l-.382 10H46a1 1 0 01-1-1v-8a1 1 0 011-1h39zm-7-16l4 10H46a1 1 0 01-1-1v-8a1 1 0 011-1h32z"
              ></path>
              <path
                fill="#15749D"
                d="M45.09 0c22.091 0 40 17.909 40 40s-17.909 40-40 40C28.569 80 14.387 69.984 8.286 55.693H5a5 5 0 01-5-5V33.345a5 5 0 015-5h1.814C11.804 11.939 27.052 0 45.09 0zm33.573 55.693l-42.202-.001v20.326c2.78.667 5.682 1.02 8.667 1.02 14.84 0 27.639-8.735 33.535-21.345zm-45.24-.001l-21.83.001c4.271 9.134 12.164 16.235 21.83 19.456V55.692zm-23.41-27.345l23.41-.001V4.928C22.4 8.601 13.683 17.321 10.013 28.347zM45.128 3.038c-2.985 0-5.887.354-8.667 1.021v24.287h43.782C75.349 13.643 61.477 3.039 45.128 3.039z"
              ></path>
              <g transform="translate(8 38)">
                <rect
                  width="11"
                  height="7"
                  x="0"
                  y="2"
                  fill="#FFF"
                  rx="1"
                ></rect>
                <path
                  stroke="#FFF"
                  strokeWidth="2"
                  d="M5.5-1l.205.008c.609.05 1.156.317 1.563.724C7.72.185 8 .81 8 1.5h0V3H3V1.5c0-.69.28-1.315.732-1.768A2.492 2.492 0 015.5-1h0z"
                ></path>
              </g>
              <path
                fill="#FFF"
                d="M41.5 38a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm11 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm11 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm11 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm-44 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ResetPasswordIcon;
