import React from "react";
import { CaretArrowUp } from "../icons";
import { CaretArrowDown } from "../icons";
import { fallbackValues } from "./SortableTableHeading.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Cluster, Stack } from "../layouts";
import { TableHeading } from "../table";
import { noop } from "../../../util/general";

const SortableTableHeading = ({
  ariaControlsId,
  disabled = false,
  displayName,
  onSortChange,
  sortOrder = null,
  themeValues,
  extraStyles
}) => {
  const getCaretColor = order => {
    if (sortOrder === order) {
      return themeValues.activeColor;
    }
    return themeValues.inactiveColor;
  };
  return (
    <TableHeading
      disabled={disabled}
      extraStyles={`cursor: pointer; ${extraStyles}`}
      onClick={event => (disabled ? noop : onSortChange(event))}
      aria-controls={ariaControlsId}
    >
      <Cluster justify="space-between">
        {displayName}
        <Stack justify="center" childGap="2px">
          <CaretArrowUp color={getCaretColor("asc")} />
          <CaretArrowDown color={getCaretColor("desc")} />
        </Stack>
      </Cluster>
    </TableHeading>
  );
};

export default themeComponent(
  SortableTableHeading,
  "SortableTableHeading",
  fallbackValues
);
