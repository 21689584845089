import { connect } from "react-redux";
import AccountVerification from "./AccountVerification.query";
import {
  mapStateToProps,
  mapDispatchToProps
} from "./AccountVerification.state";

// TODO: import epics for verify account, account verification success when complete

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountVerification);
