import React from "react";
import { Box, Switcher } from "../layouts";

const FormInputRow = ({
  breakpoint = "30rem",
  childGap = "1rem",
  largeChild,
  largeChildSize,
  children,
  ...rest
}) => {
  return (
    <Box padding="0" {...rest}>
      <Switcher
        childGap={childGap}
        breakpoint={breakpoint}
        largeChild={largeChild}
        largeChildSize={largeChildSize}
      >
        {children}
      </Switcher>
    </Box>
  );
};

export default FormInputRow;
