import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { test } from "ramda";
import {
  Box,
  Cluster,
  constants,
  Stack,
  Paragraph,
  ExternalLink,
  InternalLink,
  Switcher,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import { URL_TEST } from "../../../constants/regex_constants";
import HeaderItem from "./HeaderItem";
import RightArrowIcon from "../../icons/RightArrowIcon";
import NavLabel from "./NavLabel";
import NavLink, { NavLinkLevels } from "./NavLink";
import { getCallToActionInfo } from "../../../util/dataAdapters";

const KEY_ARROW_DOWN = 40;
const KEY_ARROW_UP = 38;
const KEY_TAB = 9;

const Link = ({ url, id, onSetRef, children }) => {
  return test(URL_TEST, url) ? (
    <ExternalLink href={url} ref={element => onSetRef(id, element)}>
      {children}
    </ExternalLink>
  ) : (
    <InternalLink to={url} ref={element => onSetRef(id, element)}>
      {children}
    </InternalLink>
  );
};

const LinkMenu = ({
  link,
  onChangeMenu,
  hamburgerMenuStyle,
  kbNavUsed,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const linkElements = useRef({});
  const [focusLink, setFocusLink] = useState(-1);

  const setElementFocus = key => {
    if (kbNavUsed) {
      linkElements.current[key].focus();
    }
  };

  const handleSetRef = useCallback((id, element) => {
    return (linkElements.current[id] = element);
  }, []);

  useEffect(() => {
    const ref1Key = Object.keys(linkElements.current)[0];
    setElementFocus(ref1Key);
    setFocusLink(0);
  }, []);

  const SmallLink = ({ text, showArrow = false }) => (
    <Cluster justify="flex-start" align="center">
      <Paragraph variant={isMobile ? "p" : "pS"} color={themeValues.linkColor}>
        {text}
      </Paragraph>
      {showArrow && <RightArrowIcon size={18} color={themeValues.linkColor} />}
    </Cluster>
  );

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case KEY_TAB:
        event.preventDefault();
        onChangeMenu(event.shiftKey ? "previous" : "next");
        break;
      case KEY_ARROW_DOWN:
      case KEY_ARROW_UP:
        event.preventDefault();
        const increment = event.keyCode === KEY_ARROW_DOWN ? 1 : -1;
        const length = Object.keys(linkElements.current).length;
        const newFocus = (focusLink + (increment % length) + length) % length;
        const newFocusKey = Object.keys(linkElements.current)[newFocus];
        setFocusLink(newFocus);
        setElementFocus(newFocusKey);
        break;
      default:
        break;
    }
  };

  const renderSubLinkSectionName = subLink => {
    const { ctaUrl: subLinkUrl } = getCallToActionInfo(
      subLink.callToActionLink,
      "",
      subLink.nameUrl
    );
    if (hamburgerMenuStyle) {
      return subLinkUrl ? (
        <NavLink
          url={subLinkUrl}
          label={subLink.name}
          themeValues={themeValues}
          navLevel={NavLinkLevels.SUB_MENU}
        />
      ) : (
        <NavLabel text={subLink.name} themeValues={themeValues} navLevel={1} />
      );
    } else {
      return subLinkUrl ? (
        <Link url={subLinkUrl} id={subLink.id} onSetRef={handleSetRef}>
          <HeaderItem
            name={subLink?.name}
            showArrow={!hamburgerMenuStyle}
            themeValues={themeValues}
          />
        </Link>
      ) : (
        <HeaderItem
          name={subLink?.name}
          showArrow={false}
          themeValues={themeValues}
        />
      );
    }
  };

  const renderSubLinkLink = (smallLink, index) => {
    if (hamburgerMenuStyle) {
      return (
        <NavLink
          key={`navlink-${index}`}
          url={smallLink.externalUrl}
          label={smallLink.text}
          themeValues={themeValues}
          navLevel={NavLinkLevels.DETAIL}
        />
      );
    } else {
      return (
        <Box padding={"0"} key={`link-${index}`}>
          <Link
            url={smallLink.externalUrl}
            id={smallLink.id}
            onSetRef={handleSetRef}
          >
            <SmallLink text={smallLink.text} />
          </Link>
        </Box>
      );
    }
  };

  const { ctaUrl } = getCallToActionInfo(
    link.callToActionLink,
    "",
    link.nameUrl
  );

  return (
    <Box
      padding={hamburgerMenuStyle ? "16px 0" : "0"}
      background={
        hamburgerMenuStyle ? constants.colors.ATHENS_GREY : "transparent"
      }
      onKeyDown={handleKeyDown}
      extraStyles={
        hamburgerMenuStyle ? "margin-left: 16px; margin-right: 16px;" : ""
      }
    >
      <Switcher breakpoint="45rem" largeChild="1" largeChildSize="2">
        {hamburgerMenuStyle ? (
          <Box padding={hamburgerMenuStyle ? "0 16px 16px" : 0}>
            <Paragraph variant="pS" color={constants.colors.CHARADE_GREY}>
              {link?.description}
            </Paragraph>
          </Box>
        ) : (
          <Box key="title-desc" padding={"0 16px 0 4px"}>
            <Stack childGap="8px">
              <Box padding="0">
                {ctaUrl ? (
                  <Box padding="0">
                    <Link url={ctaUrl} id={link.id} onSetRef={handleSetRef}>
                      <HeaderItem
                        name={link?.name}
                        showArrow={!hamburgerMenuStyle}
                        themeValues={themeValues}
                      />
                    </Link>
                  </Box>
                ) : (
                  <HeaderItem
                    name={link?.name}
                    showArrow={ctaUrl !== ""}
                    themeValues={themeValues}
                  />
                )}
              </Box>
              <Box padding={"16px 24px 16px 0"}>
                <Paragraph variant="pS">{link?.description}</Paragraph>
              </Box>
            </Stack>
          </Box>
        )}
        {link?.linkLists?.map((subLink, index) => (
          <Box
            padding={hamburgerMenuStyle ? "0" : "0 24px"}
            key={`subLink-${index}`}
          >
            <Stack childGap={"1rem"}>
              {renderSubLinkSectionName(subLink)}
              {subLink?.links?.map((smallLink, index) =>
                renderSubLinkLink(smallLink, index)
              )}
            </Stack>
          </Box>
        ))}
        <Box padding={hamburgerMenuStyle ? "16px 0" : "0 24px"}>
          <Stack childGap={"1rem"}>
            <Box padding={"0"}>
              {hamburgerMenuStyle ? (
                <NavLabel
                  text={"Featured Services"}
                  themeValues={themeValues}
                  navLevel={1}
                />
              ) : (
                <HeaderItem
                  name="Featured Services"
                  showArrow={false}
                  themeValues={themeValues}
                  color={themeValues.linkColor}
                  weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                  variant="p"
                />
              )}
            </Box>
            {link?.featuredLinks?.links?.map((featuredLink, index) =>
              renderSubLinkLink(featuredLink, index)
            )}
          </Stack>
        </Box>
      </Switcher>
    </Box>
  );
};

export default withWindowSize(LinkMenu);
