import React from "react";
import Text from "../text";
import { WHITE, GRECIAN_GREY } from "../../../constants/colors";
import { Box } from "../../atoms/layouts";

const Tab = ({ label, activeTab, setActiveTab }) => {
  const onClick = () => {
    setActiveTab(label);
  };

  let className = "tab-list-item";
  if (activeTab === label) {
    className += " tab-list-active";
  }

  return (
    <Box
      className={className}
      onClick={onClick}
      margin="0 0 -1px 0"
      padding="0.5rem 0.75rem"
      background={activeTab === label ? WHITE : GRECIAN_GREY}
      extraStyles={`cursor: pointer; flex-grow: 1; text-align: center; ${
        activeTab === label ? `list-style: none;` : ``
      }`}
    >
      <Text>{label}</Text>
    </Box>
  );
};

export default Tab;
