import React from "react";
import Title from "../title";
import { Box } from "../layouts";
import { fallbackValues } from "./AmountCallout.theme";
import { themeComponent } from "../../../util/themeUtils";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";

const AmountCallout = ({ amount, textAlign, themeValues }) => (
  <Box padding="0" textAlign={textAlign}>
    <Title
      variant="small"
      weight={FONT_WEIGHT_SEMIBOLD}
      color={themeValues.color}
      as="p"
    >
      {amount}
    </Title>
  </Box>
);

export default themeComponent(AmountCallout, "AmountCallout", fallbackValues);
