import React, { useContext } from "react";
import { tint } from "polished";
import { Box, withWindowSize, constants } from "@thecb/components";
import { ThemeContext } from "styled-components";
import CollapsibleSection from "../../collapsible-section/CollapsibleSection";
import HamburgerSectionTitle from "./HamburgerSectionTitle";
import NavLink, { NavLinkLevels } from "./NavLink";
import NavLabel from "./NavLabel";
import { getCallToActionInfo } from "../../../util/dataAdapters";

const HamburgerNavSection = ({
  isOpen,
  toggleSection,
  themeValues,
  link,
  children,
  name = ""
}) => {
  const { isMobile } = useContext(ThemeContext);
  const hasSublinks =
    link?.linkLists?.length > 0 || link?.featuredLinks?.links.length > 0;
  const label = name || "collapsible section";
  const titleHoverStyles = `background: ${tint(0.9, themeValues.linkColor)};`;
  const { ctaUrl } = getCallToActionInfo(
    link.callToActionLink,
    link.name,
    link.nameUrl
  );

  if (!hasSublinks) {
    return (
      <Box
        padding={"0"}
        role="heading"
        aria-label={label}
        aria-level={3}
        activeStyles={titleHoverStyles}
        minHeight="56px"
        extraStyles={`display:flex; align-items: center;`}
      >
        {ctaUrl ? (
          <NavLink
            url={ctaUrl}
            label={link.name}
            themeValues={themeValues}
            navLevel={NavLinkLevels.TOP_LEVEL}
          />
        ) : (
          <NavLabel text={link.name} navLevel={0} themeValues={themeValues} />
        )}
      </Box>
    );
  }

  return (
    <CollapsibleSection
      customTitle
      toggleSection={toggleSection}
      isOpen={isOpen}
      name={link?.name}
      sectionGap="0"
      customSectionTitleComponent={
        <HamburgerSectionTitle
          label={label}
          link={link}
          themeValues={themeValues}
          toggleSection={toggleSection}
          isOpen={isOpen}
        />
      }
    >
      {children}
    </CollapsibleSection>
  );
};
export default withWindowSize(HamburgerNavSection);
