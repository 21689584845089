import * as R from "ramda";
import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";

// key is generated, email sent, has not been checked, link has not been clicked
export const ACCOUNT_VERIFICATION_PENDING = "unverified";
// key is currently being checked for validity
// set on when activateUser action is dispatched
export const ACCOUNT_VERIFICATION_CHECKING = "checking";
// the validation key in the link emailed to constituent has expired
// set by response from checkActivateUserAttempt
export const ACCOUNT_VERIFICATION_EXPIRED = "expired";
// the validation key have been verified
export const ACCOUNT_VERIFICATION_SUCCESS = "verified";
// the validation email is being resent
export const ACCOUNT_VERIFICATION_RESENDING = "resending";

export const ALERT_BAR_ACTION = "accountVerification/ALERT_BAR_ACTION";
export const alertBarAction = action => ({
  type: ALERT_BAR_ACTION,
  payload: { action }
});

const POPULATE_EMAIL = "accountVerification/POPULATE_EMAIL";
export const populateEmail = email => ({
  type: POPULATE_EMAIL,
  payload: { email }
});

const POPULATE_VERIFICATION_STATUS =
  "accountVerification/POPULATE_VERIFICATION_STATUS";
export const populateVerificationStatus = verificationStatus => ({
  type: POPULATE_VERIFICATION_STATUS,
  payload: { verificationStatus }
});

export const ACTIVATE_USER = "accountVerification/ACTIVATE_USER";
export const activateUser = verificationKey => ({
  type: ACTIVATE_USER,
  payload: { verificationKey }
});

export const ACTIVATE_USER_SUCCESS =
  "accountVerification/ACTIVATE_USER_SUCCESS";
export const activateUserSuccess = () => ({
  type: ACTIVATE_USER_SUCCESS
});

export const ACTIVATE_USER_FAILURE =
  "accountVerification/ACTIVATE_USER_FAILURE";
export const activateUserFailure = email => ({
  type: ACTIVATE_USER_FAILURE,
  payload: { email }
});

export const RESEND_VERIFICATION = "accountVerification/RESEND_VERIFICATION";
export const resendVerification = (email, resendType) => ({
  type: RESEND_VERIFICATION,
  payload: { email, resendType }
});

const RESEND_VERIFICATION_SUCCESS =
  "accountVerification/RESEND_VERIFICATION_SUCCESS";
export const resendVerificationSuccess = () => ({
  type: RESEND_VERIFICATION_SUCCESS
});

const RESEND_VERIFICATION_FAILURE =
  "accountVerification/RESEND_VERIFICATION_FAILURE";
export const resendVerificationFailure = () => ({
  type: RESEND_VERIFICATION_FAILURE
});

export const initialState = {
  alertBar: AlertBarState.reducer(undefined, "@@INIT"),
  email: undefined,
  verificationStatus: ACCOUNT_VERIFICATION_PENDING
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ALERT_BAR_ACTION:
      return {
        ...state,
        alertBar: AlertBarState.reducer(state.alertBar, payload.action)
      };
    case POPULATE_EMAIL:
      return {
        ...state,
        email: payload.email
      };
    case POPULATE_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: payload.verificationStatus
      };
    case ACTIVATE_USER:
      return {
        ...state,
        verificationStatus: ACCOUNT_VERIFICATION_CHECKING
      };
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        verificationStatus: ACCOUNT_VERIFICATION_SUCCESS
      };
    case ACTIVATE_USER_FAILURE:
      return {
        ...state,
        email: payload.email,
        verificationStatus: ACCOUNT_VERIFICATION_EXPIRED
      };
    case RESEND_VERIFICATION:
      return {
        ...state,
        verificationStatus: ACCOUNT_VERIFICATION_RESENDING
      };
    case RESEND_VERIFICATION_FAILURE:
      return {
        ...state,
        verificationStatus: ACCOUNT_VERIFICATION_PENDING
      };
    case RESEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationStatus: ACCOUNT_VERIFICATION_PENDING
      };
    default:
      return state;
  }
};

const getState = state => state.accountVerification;
export const getEmail = state => getState(state).email;

export const mapStateToProps = state => ({
  ...state.accountVerification,
  ...state.global.localStorage,
  email: getEmail(state)
});

export const mapDispatchToProps = dispatch => ({
  alertBarActions: R.map(
    actionFn => (...args) => dispatch(alertBarAction(actionFn(...args))),
    AlertBarState.mapDispatchToProps(R.identity)
  ),
  activateUser: verificationKey => dispatch(activateUser(verificationKey)),
  resendVerification: email => dispatch(resendVerification(email))
});
