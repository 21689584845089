import React, { useRef, useEffect } from "react";
import { util } from "@thecb/components";
import ScreenreaderOnlyText from "../screenreder-only-text";

const LIVEREGION_TIMEOUT_DELAY = 7000;
const LIVEREGION_TRANSITION_DELAY = 1000;

const ScreenReaderLiveAnnouncer = ({
  message,
  role = "status",
  ariaLive = "polite",
  timeout = LIVEREGION_TIMEOUT_DELAY,
  delay = LIVEREGION_TRANSITION_DELAY,
  onAnnouncementClose = util.general.noop,
  ...rest
}) => {
  const messageRef = useRef(null);

  useEffect(() => {
    if (!messageRef?.current) return;
    setTimeout(() => {
      announceMessage();
    }, delay);

    if (message !== "") {
      setTimeout(() => {
        removeMessage();
        onAnnouncementClose();
      }, timeout);
    }
  }, [message]);

  const announceMessage = () => {
    if (!messageRef?.current) return;
    messageRef.current.textContent = message ?? "";
  };

  const removeMessage = () => {
    if (!messageRef?.current) return;
    messageRef.current.textContent = "";
  };

  return (
    <ScreenreaderOnlyText
      ref={messageRef}
      role={role}
      aria-live={ariaLive}
      {...rest}
    ></ScreenreaderOnlyText>
  );
};

export default ScreenReaderLiveAnnouncer;
