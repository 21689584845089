import { CHARADE_GREY, MINESHAFT_GREY } from "../../../constants/colors";

const fontSize = { default: "1rem", radio: "1.0625rem" };
const padding = { default: "0", radio: "0.625rem 0" };
const color = { default: `${CHARADE_GREY}`, radio: `${MINESHAFT_GREY}` };

export const fallbackValues = {
  fontSize,
  padding,
  color
};
