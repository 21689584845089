// Comments assume desktop base font size of 16px, mobile base font size of 14px

const fontSize = {
  large: "1.125rem", // 18px (at base font size of 16)
  regular: "1rem", // 16px
  small: "0.875rem", // 14px
  extraSmall: "0.75rem" // 12px
};

const fontFamily = {
  large: "Public Sans",
  regular: "Public Sans",
  small: "Public Sans",
  extraSmall: "Public Sans"
};

const mobileFontSize = {
  large: "1.14285rem", // 16px (at base font size of 14)
  regular: "1", // 14px
  small: "0.85714rem", // 12px
  extraSmall: "0.71428rem" // 10px
};

export const fallbackValues = {
  fontFamily,
  fontSize
};

export const mobileFallbackValues = {
  fontFamily,
  fontSize: mobileFontSize
};

export const MOBILE_BREAKPOINT = 768;
