import { flatten } from "ramda";
import { buildObligationValues } from "./general";

import {
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_CREDIT_CARD,
  TRANSACTION_STATUS_ACH_RETURN,
  TRANSACTION_STATUS_CHARGEBACK_REVERSAL,
  TRANSACTION_STATUS_CHARGEBACK,
  TRANSACTION_STATUS_REFUNDED,
  TRANSACTION_STATUS_SUCCESSFUL,
  TRANSACTION_STATUS_VOIDED
} from "/constants/transaction_history_constants";

export const paymentLastFour = ({ paymentType, creditCard, bankAccount }) => {
  if (paymentType === PAYMENT_METHOD_CREDIT_CARD) {
    return creditCard?.lastFour;
  } else if (paymentType === PAYMENT_METHOD_CHECK) {
    return bankAccount?.accountNumberLastFour;
  } else {
    return null;
  }
};

export const flattenObligations = obligations =>
  flatten(Object.values(obligations?.accounts?.active ?? {}));

const subClientSlugForTransaction = transaction =>
  transaction?.obligationSlug ?? transaction.agencySlug;

export const obligationForTransaction = ({ transaction, obligations }) =>
  obligations.find(
    obligation =>
      obligation.account === transaction?.account?.number &&
      subClientSlugForTransaction(transaction) === obligation.subClientSlug
  );

export const transactionStatusText = status => {
  return {
    [TRANSACTION_STATUS_SUCCESSFUL]: "Payment Successful",
    [TRANSACTION_STATUS_VOIDED]: "Payment Voided",
    [TRANSACTION_STATUS_REFUNDED]: "Payment Refunded",
    [TRANSACTION_STATUS_ACH_RETURN]: "ACH Return",
    [TRANSACTION_STATUS_CHARGEBACK]: "Chargeback",
    [TRANSACTION_STATUS_CHARGEBACK_REVERSAL]: "Chargeback Reversal"
  }[status];
};

export const transactionDescription = ({
  transaction,
  obligation,
  obligationsConfig
}) => {
  const descriptionFromAssociatedObligation =
    obligation?.obligations?.[0]?.description;

  if (!!descriptionFromAssociatedObligation)
    return descriptionFromAssociatedObligation;

  const obligationConfigForTransaction = obligationsConfig.find(
    config => config.obligationSlug === transaction.obligationSlug
  );

  if (obligationConfigForTransaction) {
    const descriptionFromObligationConfig = buildObligationValues(
      obligationConfigForTransaction?.description,
      transaction?.customAttributes
    );
    if (!!descriptionFromObligationConfig)
      return descriptionFromObligationConfig;
  }

  return transaction?.agency;
};

const TRANSACTION_STATUSES_NEGATIVE = [
  TRANSACTION_STATUS_ACH_RETURN,
  TRANSACTION_STATUS_CHARGEBACK,
  TRANSACTION_STATUS_REFUNDED
];

export const signedAmount = (status, amount) => {
  if (TRANSACTION_STATUSES_NEGATIVE.includes(status)) {
    return -amount;
  } else {
    return amount;
  }
};

export const lineItemAmount = (
  status,
  { amountInCents, amountRefundedInCents }
) => {
  if (status === TRANSACTION_STATUS_REFUNDED) {
    return amountRefundedInCents ?? amountInCents;
  } else {
    return amountInCents;
  }
};
