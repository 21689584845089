import {
  WHITE,
  MATISSE_BLUE,
  ALABASTER_WHITE
} from "../../../constants/colors";

const arrowColor = WHITE;
const numberColor = MATISSE_BLUE;
const hoverBackgroundColor = ALABASTER_WHITE;
const activeBackgroundColor = WHITE;
const activeColor = MATISSE_BLUE;

export const fallbackValues = {
  activeColor,
  activeBackgroundColor,
  arrowColor,
  hoverBackgroundColor,
  numberColor
};
