import React from "react";

const RevenueManagementImage = () => {
  return (
    <svg
      width="179"
      height="96"
      viewBox="0 0 179 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.284 17.5583C139.832 17.3968 137.892 15.3563 137.892 12.8627C137.892 10.2637 139.999 8.15681 142.598 8.15681C143.492 8.15681 144.327 8.40592 145.039 8.8385C145.84 5.91385 148.517 3.76465 151.696 3.76465C155.307 3.76465 158.27 6.53709 158.573 10.0693C159.212 9.65335 159.975 9.41171 160.794 9.41171C163.047 9.41171 164.872 11.2377 164.872 13.4901C164.872 15.7426 163.047 17.5686 160.794 17.5686C160.688 17.5686 160.584 17.5646 160.48 17.5567V17.5686H151.696H142.598H142.284V17.5583Z"
        fill="#15749D"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9314 64.6275C18.5453 64.6275 17.4216 63.5039 17.4216 62.1177C17.4216 60.7316 18.5453 59.6079 19.9314 59.6079C20.3208 59.6079 20.6895 59.6966 21.0184 59.8549C21.5697 58.9541 22.5627 58.353 23.6961 58.353C25.0923 58.353 26.2755 59.265 26.6824 60.5258C27.0041 60.341 27.377 60.2354 27.7746 60.2354C28.9874 60.2354 29.9707 61.2186 29.9707 62.4315C29.9707 63.6443 28.9874 64.6275 27.7746 64.6275H23.6961H19.9314Z"
        fill="#15749D"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.4021 8.78431H53.1864H48.7254V8.77384C48.6447 8.78078 48.563 8.78431 48.4805 8.78431C46.9211 8.78431 45.657 7.52018 45.657 5.96078C45.657 4.40139 46.9211 3.13725 48.4805 3.13725C48.6454 3.13725 48.8069 3.15139 48.9641 3.1785C49.4907 1.34307 51.1817 0 53.1864 0C55.0545 0 56.6502 1.1663 57.2851 2.81047C57.4719 2.7321 57.677 2.68878 57.8923 2.68878C58.4432 2.68878 58.9278 2.97248 59.2076 3.40162C59.5704 3.232 59.9752 3.13725 60.4021 3.13725C61.9615 3.13725 63.2256 4.40139 63.2256 5.96078C63.2256 7.43768 62.0917 8.64973 60.6469 8.77384V8.78431H60.4021Z"
        fill="#15749D"
        fillOpacity="0.3"
      />
      <path
        d="M109.029 91.9217C109.029 94.1741 100.461 96.0001 89.8921 96.0001C79.3229 96.0001 70.7549 94.1741 70.7549 91.9217C70.7549 89.6692 79.3229 87.8433 89.8921 87.8433C100.461 87.8433 109.029 89.6692 109.029 91.9217Z"
        fill="#15749D"
        fillOpacity="0.3"
      />
      <path
        d="M49.4216 32H130.363V80.3137C130.363 81.0068 129.801 81.5686 129.108 81.5686H50.6765C49.9835 81.5686 49.4216 81.0068 49.4216 80.3137V32Z"
        fill="white"
      />
      <path
        d="M47.5393 22.9333C47.5393 21.0102 49.0983 19.4512 51.0214 19.4512H128.763C130.686 19.4512 132.245 21.0102 132.245 22.9333V32.0002H47.5393V22.9333Z"
        fill="#E5E7EC"
      />
      <path
        d="M62.5982 25.7243C62.5982 26.4166 62.0363 26.9778 61.3433 26.9778C60.6502 26.9778 60.0884 26.4166 60.0884 25.7243C60.0884 25.0319 60.6502 24.4707 61.3433 24.4707C62.0363 24.4707 62.5982 25.0319 62.5982 25.7243Z"
        fill="#292A33"
      />
      <path
        d="M58.8335 25.7243C58.8335 26.4166 58.2717 26.9778 57.5786 26.9778C56.8856 26.9778 56.3237 26.4166 56.3237 25.7243C56.3237 25.0319 56.8856 24.4707 57.5786 24.4707C58.2717 24.4707 58.8335 25.0319 58.8335 25.7243Z"
        fill="#292A33"
      />
      <path
        d="M55.0686 25.7243C55.0686 26.4166 54.5068 26.9778 53.8137 26.9778C53.1207 26.9778 52.5588 26.4166 52.5588 25.7243C52.5588 25.0319 53.1207 24.4707 53.8137 24.4707C54.5068 24.4707 55.0686 25.0319 55.0686 25.7243Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.108 21.3335H50.6766C49.9835 21.3335 49.4217 21.8954 49.4217 22.5884V80.3139C49.4217 81.007 49.9835 81.5688 50.6766 81.5688H129.108C129.801 81.5688 130.363 81.007 130.363 80.3139V22.5884C130.363 21.8954 129.801 21.3335 129.108 21.3335ZM50.6766 19.4512C48.9439 19.4512 47.5393 20.8558 47.5393 22.5884V80.3139C47.5393 82.0466 48.9439 83.4512 50.6766 83.4512H129.108C130.841 83.4512 132.245 82.0466 132.245 80.3139V22.5884C132.245 20.8558 130.841 19.4512 129.108 19.4512H50.6766Z"
        fill="#292A33"
      />
      <path
        d="M157.343 75.9214C157.343 75.2283 157.905 74.6665 158.598 74.6665H169.265C169.958 74.6665 170.52 75.2283 170.52 75.9214C170.52 76.6145 169.958 77.1763 169.265 77.1763H158.598C157.905 77.1763 157.343 76.6145 157.343 75.9214Z"
        fill="#292A33"
      />
      <path
        d="M132.245 74.6665H152.83C153.529 74.6665 154.095 75.233 154.095 75.9318C154.095 76.6306 153.529 77.1971 152.83 77.1971H132.245V74.6665Z"
        fill="#292A33"
      />
      <path
        d="M29.9707 75.9214C29.9707 75.2283 30.5325 74.6665 31.2256 74.6665H47.5393V77.1763H31.2256C30.5325 77.1763 29.9707 76.6145 29.9707 75.9214Z"
        fill="#292A33"
      />
      <path
        d="M19.304 75.9214C19.304 75.2283 19.8658 74.6665 20.5589 74.6665H25.5785C26.2715 74.6665 26.8334 75.2283 26.8334 75.9214C26.8334 76.6145 26.2715 77.1763 25.5785 77.1763H20.5589C19.8658 77.1763 19.304 76.6145 19.304 75.9214Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.696 45.8038V51.4508H93.3431V45.8038C91.5455 45.0049 89.4936 45.0049 87.696 45.8038ZM90.5196 48.941C91.0394 48.941 91.4608 48.5197 91.4608 47.9999C91.4608 47.4801 91.0394 47.0587 90.5196 47.0587C89.9998 47.0587 89.5784 47.4801 89.5784 47.9999C89.5784 48.5197 89.9998 48.941 90.5196 48.941Z"
        fill="#292A33"
      />
      <path
        d="M112.794 50.5097C112.794 56.2275 108.159 60.8626 102.441 60.8626C96.7233 60.8626 92.0881 56.2275 92.0881 50.5097C92.0881 44.7919 96.7233 40.1567 102.441 40.1567C108.159 40.1567 112.794 44.7919 112.794 50.5097Z"
        fill="#E4F4FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.441 59.2798C107.285 59.2798 111.211 55.3533 111.211 50.5097C111.211 45.6661 107.285 41.7395 102.441 41.7395C97.5975 41.7395 93.6709 45.6661 93.6709 50.5097C93.6709 55.3533 97.5975 59.2798 102.441 59.2798ZM102.441 60.8626C108.159 60.8626 112.794 56.2275 112.794 50.5097C112.794 44.7919 108.159 40.1567 102.441 40.1567C96.7233 40.1567 92.0881 44.7919 92.0881 50.5097C92.0881 56.2275 96.7233 60.8626 102.441 60.8626Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.441 50.3601C101.355 50.3601 100.571 50.992 100.379 51.6677C100.259 52.0881 99.8213 52.3319 99.4009 52.2123C98.9805 52.0926 98.7367 51.6549 98.8563 51.2345C99.2787 49.7502 100.79 48.7773 102.441 48.7773C104.092 48.7773 105.603 49.7502 106.026 51.2345C106.145 51.6549 105.902 52.0926 105.481 52.2123C105.061 52.3319 104.623 52.0881 104.503 51.6677C104.311 50.992 103.528 50.3601 102.441 50.3601Z"
        fill="#292A33"
      />
      <path
        d="M88.951 50.5097C88.951 56.2275 84.3158 60.8626 78.5981 60.8626C72.8803 60.8626 68.2451 56.2275 68.2451 50.5097C68.2451 44.7919 72.8803 40.1567 78.5981 40.1567C84.3158 40.1567 88.951 44.7919 88.951 50.5097Z"
        fill="#E4F4FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.5981 59.2798C83.4417 59.2798 87.3682 55.3533 87.3682 50.5097C87.3682 45.6661 83.4417 41.7395 78.5981 41.7395C73.7544 41.7395 69.8279 45.6661 69.8279 50.5097C69.8279 55.3533 73.7544 59.2798 78.5981 59.2798ZM78.5981 60.8626C84.3158 60.8626 88.951 56.2275 88.951 50.5097C88.951 44.7919 84.3158 40.1567 78.5981 40.1567C72.8803 40.1567 68.2451 44.7919 68.2451 50.5097C68.2451 56.2275 72.8803 60.8626 78.5981 60.8626Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.598 50.3601C77.5115 50.3601 76.728 50.992 76.5357 51.6677C76.4161 52.0881 75.9783 52.3319 75.5579 52.2123C75.1375 52.0926 74.8937 51.6549 75.0133 51.2345C75.4357 49.7502 76.9472 48.7773 78.598 48.7773C80.2488 48.7773 81.7604 49.7502 82.1827 51.2345C82.3024 51.6549 82.0586 52.0926 81.6382 52.2123C81.2178 52.3319 80.78 52.0881 80.6604 51.6677C80.4681 50.992 79.6846 50.3601 78.598 50.3601Z"
        fill="#292A33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6764 64.4634C83.1135 64.4634 83.4678 64.8177 83.4678 65.2548C83.4678 66.2038 84.2372 66.9732 85.1862 66.9732H94.598C95.547 66.9732 96.3164 66.2038 96.3164 65.2548C96.3164 64.8177 96.6707 64.4634 97.1078 64.4634C97.5449 64.4634 97.8992 64.8177 97.8992 65.2548C97.8992 67.078 96.4212 68.556 94.598 68.556H85.1862C83.363 68.556 81.885 67.078 81.885 65.2548C81.885 64.8177 82.2393 64.4634 82.6764 64.4634Z"
        fill="#292A33"
      />
      <path d="M47.5393 30.1177H132.245V32H47.5393V30.1177Z" fill="#292A33" />
      <g filter="url(#filter0_d_2573_15404)">
        <path
          d="M21.8137 35.1372C21.8137 34.4442 22.3756 33.8823 23.0686 33.8823H47.5392V42.0392H23.0686C22.3756 42.0392 21.8137 41.4773 21.8137 40.7843V35.1372Z"
          fill="white"
        />
        <path
          d="M40.0098 37.9607C40.0098 37.4409 40.4311 37.0195 40.9509 37.0195H47.5392V38.9019H40.9509C40.4311 38.9019 40.0098 38.4805 40.0098 37.9607Z"
          fill="#959CA8"
        />
        <path
          d="M24.9509 37.9607C24.9509 37.4409 25.3723 37.0195 25.8921 37.0195H36.5588C37.0786 37.0195 37.4999 37.4409 37.4999 37.9607C37.4999 38.4805 37.0786 38.9019 36.5588 38.9019H25.8921C25.3723 38.9019 24.9509 38.4805 24.9509 37.9607Z"
          fill="#959CA8"
        />
      </g>
      <g filter="url(#filter1_d_2573_15404)">
        <path
          d="M5.5 42.0391C5.5 41.346 6.06184 40.7842 6.7549 40.7842H55.0686C55.7617 40.7842 56.3235 41.346 56.3235 42.0391V47.6861C56.3235 48.3792 55.7617 48.941 55.0686 48.941H6.7549C6.06184 48.941 5.5 48.3792 5.5 47.6861V42.0391Z"
          fill="white"
        />
        <path
          d="M41.2646 44.8626C41.2646 44.3428 41.686 43.9214 42.2058 43.9214H52.245C52.7648 43.9214 53.1862 44.3428 53.1862 44.8626C53.1862 45.3824 52.7648 45.8037 52.245 45.8037H42.2058C41.686 45.8037 41.2646 45.3824 41.2646 44.8626Z"
          fill="#959CA8"
        />
        <path
          d="M25.5784 44.8626C25.5784 44.3428 25.9997 43.9214 26.5195 43.9214H37.8137C38.3335 43.9214 38.7548 44.3428 38.7548 44.8626C38.7548 45.3824 38.3335 45.8037 37.8137 45.8037H26.5195C25.9997 45.8037 25.5784 45.3824 25.5784 44.8626Z"
          fill="#959CA8"
        />
        <path
          d="M18.6765 44.8626C18.6765 44.3428 19.0979 43.9214 19.6177 43.9214H22.1275C22.6473 43.9214 23.0687 44.3428 23.0687 44.8626C23.0687 45.3824 22.6473 45.8037 22.1275 45.8037H19.6177C19.0979 45.8037 18.6765 45.3824 18.6765 44.8626Z"
          fill="#959CA8"
        />
        <path
          d="M8.63721 44.8626C8.63721 44.3428 9.05859 43.9214 9.57838 43.9214H15.2254C15.7452 43.9214 16.1666 44.3428 16.1666 44.8626C16.1666 45.3824 15.7452 45.8037 15.2254 45.8037H9.57838C9.05859 45.8037 8.63721 45.3824 8.63721 44.8626Z"
          fill="#959CA8"
        />
      </g>
      <g filter="url(#filter2_d_2573_15404)">
        <path
          d="M132.245 27.6079H167.382C168.075 27.6079 168.637 28.1697 168.637 28.8628V34.5099C168.637 35.2029 168.075 35.7648 167.382 35.7648H132.245V27.6079Z"
          fill="white"
        />
        <path
          d="M157.343 31.6863C157.343 31.1665 157.765 30.7451 158.284 30.7451H164.559C165.079 30.7451 165.5 31.1665 165.5 31.6863C165.5 32.2061 165.079 32.6275 164.559 32.6275H158.284C157.765 32.6275 157.343 32.2061 157.343 31.6863Z"
          fill="#959CA8"
        />
        <path
          d="M150.441 31.6863C150.441 31.1665 150.863 30.7451 151.382 30.7451H153.892C154.412 30.7451 154.833 31.1665 154.833 31.6863C154.833 32.2061 154.412 32.6275 153.892 32.6275H151.382C150.863 32.6275 150.441 32.2061 150.441 31.6863Z"
          fill="#959CA8"
        />
        <path
          d="M137.892 31.6863C137.892 31.1665 138.313 30.7451 138.833 30.7451H146.99C147.51 30.7451 147.931 31.1665 147.931 31.6863C147.931 32.2061 147.51 32.6275 146.99 32.6275H138.833C138.313 32.6275 137.892 32.2061 137.892 31.6863Z"
          fill="#959CA8"
        />
        <path
          d="M132.245 30.7451H134.441C134.961 30.7451 135.382 31.1665 135.382 31.6863C135.382 32.2061 134.961 32.6275 134.441 32.6275H132.245V30.7451Z"
          fill="#959CA8"
        />
      </g>
      <g filter="url(#filter3_d_2573_15404)">
        <path
          d="M122.833 35.7647C122.833 35.0716 123.395 34.5098 124.088 34.5098H172.402C173.095 34.5098 173.657 35.0716 173.657 35.7647V41.4117C173.657 42.1048 173.095 42.6666 172.402 42.6666H124.088C123.395 42.6666 122.833 42.1048 122.833 41.4117V35.7647Z"
          fill="white"
        />
        <path
          d="M159.225 38.5881C159.225 38.0684 159.647 37.647 160.167 37.647H169.578C170.098 37.647 170.519 38.0684 170.519 38.5881C170.519 39.1079 170.098 39.5293 169.578 39.5293H160.167C159.647 39.5293 159.225 39.1079 159.225 38.5881Z"
          fill="#959CA8"
        />
        <path
          d="M143.539 38.5891C143.539 38.0693 143.96 37.6479 144.48 37.6479H155.774C156.294 37.6479 156.716 38.0693 156.716 38.5891C156.716 39.1089 156.294 39.5303 155.774 39.5303H144.48C143.96 39.5303 143.539 39.1089 143.539 38.5891Z"
          fill="#959CA8"
        />
        <path
          d="M136.01 38.5891C136.01 38.0693 136.431 37.6479 136.951 37.6479H140.088C140.608 37.6479 141.029 38.0693 141.029 38.5891C141.029 39.1089 140.608 39.5303 140.088 39.5303H136.951C136.431 39.5303 136.01 39.1089 136.01 38.5891Z"
          fill="#959CA8"
        />
        <path
          d="M125.97 38.5891C125.97 38.0693 126.392 37.6479 126.912 37.6479H132.559C133.078 37.6479 133.5 38.0693 133.5 38.5891C133.5 39.1089 133.078 39.5303 132.559 39.5303H126.912C126.392 39.5303 125.97 39.1089 125.97 38.5891Z"
          fill="#959CA8"
        />
      </g>
      <path
        d="M85.8138 89.7252V83.4507H88.3236V91.2853C88.3236 91.8098 87.8984 92.235 87.3739 92.235H82.0491C81.356 92.235 80.7942 91.6732 80.7942 90.9801C80.7942 90.287 81.356 89.7252 82.0491 89.7252H85.8138Z"
        fill="#15749D"
      />
      <path
        d="M91.4609 91.2853V83.4507H93.9707V89.7252H97.7354C98.4285 89.7252 98.9903 90.287 98.9903 90.9801C98.9903 91.6732 98.4285 92.235 97.7354 92.235H92.4106C91.8861 92.235 91.4609 91.8098 91.4609 91.2853Z"
        fill="#15749D"
      />
      <path
        d="M132.245 62.7452V65.8824L140.19 70.4222C140.685 70.7055 141.31 70.6219 141.713 70.2182L152.324 59.6079H148.559L141.2 67.5796C141.1 67.688 140.939 67.7129 140.811 67.6398L132.245 62.7452Z"
        fill="#15749D"
      />
      <path
        d="M145.422 58.353C145.422 57.66 145.983 57.0981 146.677 57.0981H156.716C157.409 57.0981 157.971 57.66 157.971 58.353C157.971 59.0461 157.409 59.6079 156.716 59.6079H146.677C145.983 59.6079 145.422 59.0461 145.422 58.353Z"
        fill="#15749D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.5753 47.93C66.1393 47.9988 66.5408 48.5117 66.4721 49.0757C66.4108 49.5788 66.3792 50.0915 66.3792 50.612C66.3792 52.5215 66.8047 54.329 67.5652 55.9474C67.8068 56.4617 67.5858 57.0744 67.0716 57.3161C66.5573 57.5577 65.9445 57.3368 65.7029 56.8225C64.8164 54.9361 64.3215 52.8304 64.3215 50.612C64.3215 50.0081 64.3582 49.4123 64.4296 48.8268C64.4983 48.2628 65.0112 47.8613 65.5753 47.93Z"
        fill="#15749D"
      />
      <path
        d="M47.5393 66.5095V63.3722L46.2844 62.7448C52.2747 60.2933 65.7354 55.2154 65.7354 55.2154V52.0781L42.2512 61.8155C41.3742 62.1791 41.3309 63.4053 42.1801 63.8299L47.5393 66.5095Z"
        fill="#15749D"
      />
      <g filter="url(#filter4_d_2573_15404)">
        <path
          d="M142.912 39.5293C142.912 38.1432 144.036 37.0195 145.422 37.0195H157.971C159.357 37.0195 160.48 38.1432 160.48 39.5293V52.0784C160.48 53.4645 159.357 54.5882 157.971 54.5882H145.422C144.036 54.5882 142.912 53.4645 142.912 52.0784V39.5293Z"
          fill="#15749D"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.597 43.0261C153.484 43.9134 153.568 45.2997 152.849 46.2816L154.965 48.3975C155.014 48.4468 155.014 48.5267 154.965 48.5759L154.597 48.9436C154.548 48.9929 154.468 48.9929 154.419 48.9436L152.303 46.8276C151.321 47.5468 149.934 47.4628 149.047 46.5755C148.067 45.5953 148.067 44.0062 149.047 43.0261C150.027 42.0459 151.616 42.0459 152.597 43.0261ZM152.051 46.0295C152.729 45.3509 152.729 44.2508 152.051 43.5722C151.372 42.8936 150.272 42.8936 149.593 43.5722C148.915 44.2508 148.915 45.3509 149.593 46.0295C150.272 46.708 151.372 46.708 152.051 46.0295Z"
        fill="white"
      />
      <mask
        id="mask0_2573_15404"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="148"
        y="42"
        width="8"
        height="7"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.597 43.0261C153.484 43.9134 153.568 45.2997 152.849 46.2816L154.965 48.3975C155.014 48.4468 155.014 48.5267 154.965 48.5759L154.597 48.9436C154.548 48.9929 154.468 48.9929 154.419 48.9436L152.303 46.8276C151.321 47.5468 149.934 47.4628 149.047 46.5755C148.067 45.5953 148.067 44.0062 149.047 43.0261C150.027 42.0459 151.616 42.0459 152.597 43.0261ZM152.051 46.0295C152.729 45.3509 152.729 44.2508 152.051 43.5722C151.372 42.8936 150.272 42.8936 149.593 43.5722C148.915 44.2508 148.915 45.3509 149.593 46.0295C150.272 46.708 151.372 46.708 152.051 46.0295Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2573_15404)">
        <rect
          x="146.677"
          y="40.7842"
          width="10.0392"
          height="10.0392"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2573_15404"
          x="16.8137"
          y="28.8823"
          width="35.7256"
          height="18.1567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2573_15404"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2573_15404"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2573_15404"
          x="0.5"
          y="35.7842"
          width="60.8235"
          height="18.1567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2573_15404"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2573_15404"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2573_15404"
          x="127.245"
          y="22.6079"
          width="46.3921"
          height="18.1567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2573_15404"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2573_15404"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_2573_15404"
          x="117.833"
          y="29.5098"
          width="60.8235"
          height="18.1567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2573_15404"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2573_15404"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_2573_15404"
          x="132.822"
          y="26.9292"
          width="37.7492"
          height="37.7494"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.04515" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.454902 0 0 0 0 0.615686 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2573_15404"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2573_15404"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RevenueManagementImage;
