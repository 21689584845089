import PhoneForm from "./PhoneForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./PhoneForm.state";

PhoneForm.reducer = reducer;
PhoneForm.mapStateToProps = mapStateToProps;
PhoneForm.mapDispatchToProps = mapDispatchToProps;
export default PhoneForm;
