import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { ROYAL_BLUE } from "../../../constants/colors";
/*
  The child span selector on hover styles targets the text inside of buttons when a button is hovered,
  as long as the box wrapper has an "as" prop equal to "button"

  The extracting of props and the disabling of the eslint rule is to stop React from complaining about
  unrecognized DOM attributes.
*/

/* eslint-disable no-unused-vars */
export const BoxWrapper = styled(
  forwardRef(
    (
      {
        activeStyles,
        focusStyles,
        hoverStyles,
        disabledStyles,
        extraStyles,
        borderSize,
        borderColor,
        borderWidthOverride,
        borderRadius,
        textAlign,
        boxShadow,
        minHeight,
        minWidth,
        maxWidth,
        padding,
        hiddenStyles,
        ariaControls,
        ariaLabel,
        ...props
      },
      ref
    ) => (
      <div
        ref={ref}
        aria-controls={ariaControls}
        aria-label={ariaLabel}
        {...props}
      />
    )
  )
)`
  position: relative;
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  border: ${({ border, borderSize, borderColor }) =>
    border ? border : `${borderSize} solid ${borderColor}`};
  box-shadow: ${({ boxShadow }) => boxShadow};
  background-color: ${({ background }) => background};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  ${({ width }) => width && `width: ${width};`}
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "auto")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-width: ${({ borderWidthOverride }) => borderWidthOverride};
  text-align: ${({ textAlign }) => textAlign};

  &:hover {
    ${({ hoverStyles, as, disabled }) =>
      css`
        ${hoverStyles}
        ${as === "button" && !disabled
          ? `> * > span {
          ${hoverStyles}
          border: none;
          outline: none;
          box-shadow: none;
        }`
          : ``}
      `}
  }

   &:focus {
    ${({ focusStyles, as }) =>
      css`
        ${focusStyles}
        outline: 3px solid ${ROYAL_BLUE};
        outline-offset: 2px;
        ${as === "button" &&
          `
          > * > span {
          border: none;
          outline: none;
          box-shadow: none;
        }`}
      `}
  }

  &:active {
    ${({ activeStyles, as }) =>
      css`
        ${activeStyles}
        ${as === "button"
          ? `        > * > span {
          ${activeStyles}
          border: none;
          outline: none;
          box-shadow: none;
        }`
          : ``}
      `}
  }

  &:disabled {
    ${({ disabledStyles, as }) =>
      css`
        ${disabledStyles}
        ${as === "button"
          ? `        > * > span {
          ${disabledStyles}
          border: none;
          outline: none;
          box-shadow: none;
        }`
          : ``}
      `}
  }
  
  &[aria-disabled="true"] {
    ${({ disabledStyles, as }) =>
      css`
        ${disabledStyles}
        ${as === "button"
          ? `        > * > span {
          ${disabledStyles}
          border: none;
          outline: none;
          box-shadow: none;
        }`
          : ``}
      `}
  }

  & * {
    color: ${({ color }) => color};
  }

  ${({ hiddenStyles }) =>
    hiddenStyles &&
    css`
      display: none;
    `}
  ${({ extraStyles }) =>
    css`
      ${extraStyles}
    `}
`;
/* eslint-enable no-unused-vars */
