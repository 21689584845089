import {
  createFormState,
  required,
  onlyIntegers,
  hasLength
} from "redux-freeform";

const formConfig = {
  phone: {
    validators: [hasLength(10, 10)], // 'required' validator is conditionally added based on `isRequired` prop
    constraints: [onlyIntegers(), hasLength(0, 10)]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
