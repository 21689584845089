import { useEffect } from "react";

const useFocusInvalidInput = (hasErrors, resetHasErrors = () => {}) => {
  // Only move focus when "hasErrors" is true
  // "hasErrors" is managed by container page of form
  // typically set to "true" on attempted form submission, if errors exist
  // Reset errors, if provided, resets the error state tracking in order to properly re-run
  useEffect(() => {
    if (hasErrors) {
      const inputsWithErrors = document.querySelectorAll(
        "input[aria-invalid=true]"
      );
      inputsWithErrors?.[0]?.focus();
    }
    return () => resetHasErrors(false);
  });
};

export default useFocusInvalidInput;
