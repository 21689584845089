import React, { useState, useLayoutEffect, useRef } from "react";
import { ThemeProvider } from "styled-components";
import { MOBILE_WIDTH, throttle } from "../util/general";

const withWindowSize = Child => ({ ...props }) => {
  const [state, setState] = useState({
    isMobile: window.innerWidth < MOBILE_WIDTH,
    mobileWidth: MOBILE_WIDTH,
    supportsTouch: "ontouchstart" in window || navigator.maxTouchPoints > 1
  });
  const isMobileRef = useRef(state.isMobile);
  const onResize = throttle(25, () => {
    const width = window.innerWidth;
    const newMobileState = width <= MOBILE_WIDTH;
    if (newMobileState !== isMobileRef.current) {
      isMobileRef.current = newMobileState;
      setState({
        isMobile: width <= MOBILE_WIDTH,
        mobileWidth: MOBILE_WIDTH,
        supportsTouch: "ontouchstart" in window || navigator.maxTouchPoints > 1
      });
    }
  });
  useLayoutEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <ThemeProvider theme={state}>
      <Child {...props} />
    </ThemeProvider>
  );
};

export default withWindowSize;
