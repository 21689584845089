import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const AccountsIconSmall = ({ themeValues }) => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="1 0 22 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ display: "inline-block", verticalAlign: "text-bottom" }}
    >
      <defs>
        <rect
          id="path-1-accountssmall"
          x="0"
          y="0"
          width="29"
          height="27"
        ></rect>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <mask id="mask-2-accountssmall" fill="white">
                    <use xlinkHref="#path-1-accountssmall"></use>
                  </mask>
                  <g></g>
                  <path
                    d="M1.875,17.9609424 L4.30077547,13.8125148 C4.60546242,13.2968908 5.01561733,12.8867359 5.53124128,12.5820489 C6.04686523,12.277362 6.60936389,12.1250188 7.21873726,12.1250188 L7.21873726,12.1250188 L18.7499598,12.1250188 L18.7499598,10.4375228 C18.7499598,9.9687741 18.5858975,9.57033719 18.2577734,9.24221315 C17.9296494,8.91408911 17.5312125,8.75002682 17.0624638,8.75002682 L17.0624638,8.75002682 L11.4374772,8.75002682 L9.18748257,6.50003219 L3.56249598,6.50003219 C3.09374727,6.50003219 2.69531037,6.66409447 2.36718633,6.99221851 C2.03906229,7.32034255 1.875,7.71877946 1.875,8.18752816 L1.875,8.18752816 L1.875,17.9609424 Z M17.519494,20 C17.9179303,20 18.2929296,19.900391 18.6444913,19.7011726 C18.9960529,19.5019541 19.2655837,19.2265643 19.4530831,18.8750027 L19.4530831,18.8750027 L22.0194832,14.5156381 C22.1835455,14.2343887 22.1835455,13.9531394 22.0194832,13.6718901 C21.8554209,13.3906408 21.6093278,13.2500161 21.2812037,13.2500161 L21.2812037,13.2500161 L7.21873726,13.2500161 C6.82030089,13.2500161 6.4453016,13.3496251 6.09373994,13.5488435 C5.74217828,13.748062 5.47264749,14.0234517 5.28514812,14.3750134 L5.28514812,14.3750134 L2.71874799,18.734378 C2.5546857,19.0156273 2.5546857,19.2968767 2.71874799,19.578126 C2.88281028,19.8593753 3.12890344,20 3.45702748,20 L3.45702748,20 L17.519494,20 Z"
                    fill={themeValues.singleIconColor}
                    fillRule="nonzero"
                    mask="url(#mask-2-accountssmall)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default themeComponent(
  AccountsIconSmall,
  "Icons",
  fallbackValues,
  "primary"
);
