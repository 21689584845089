import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../templates.theme";
import { themeComponent } from "../../../util/themeUtils";
import withWindowResize from "../../withWindowSize";
import { Box, Center, Cover, Stack } from "../../atoms/layouts";
import { COOL_GREY_05 } from "../../../constants/colors";

const CenterStack = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  contentSpacing = "1rem",
  maxContentWidth = "36.75rem",
  fullWidthMobile = true,
  content,
  themeValues,
  role = "main"
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Box
      padding="0"
      minWidth="100%"
      background={COOL_GREY_05}
      extraStyles="flex-grow: 1;"
    >
      <Cover>
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Center
            maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            intrinsic={!isMobile}
            role={role}
          >
            <Cover minHeight="100%" singleChild>
              <Stack childGap={contentSpacing}>{content}</Stack>
            </Cover>
          </Center>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
};

export default withWindowResize(
  themeComponent(CenterStack, "Global", fallbackValues)
);
