import React from "react";
import Text from "../text";
import Box from "../layouts/Box";
import { fallbackValues } from "./FormattedAddress.theme";
import { themeComponent } from "../../../util/themeUtils";

const FormattedAddress = ({
  street1,
  city,
  stateProvince,
  country,
  zip,
  street2 = null,
  name = null,
  themeValues,
  qaPrefix = "address"
}) => {
  return (
    <Box padding={themeValues.padding}>
      {name && (
        <Text variant="p" color={themeValues.color} dataQa={`${qaPrefix}-name`}>
          {name}
        </Text>
      )}
      <Box padding="0">
        <Text variant="p" color={themeValues.color} dataQa={`${qaPrefix}-1`}>
          {street1}
        </Text>
        {street2 && (
          <Text variant="p" dataQa={`${qaPrefix}-2`}>
            , {street2}
          </Text>
        )}
      </Box>
      <Box padding="0">
        <Text variant="p" color={themeValues.color} dataQa={`${qaPrefix}-3`}>
          {city}, {stateProvince}
          {` ${zip}`}
          {country ? ` ${country}` : ""}
        </Text>
      </Box>
    </Box>
  );
};

export default themeComponent(
  FormattedAddress,
  "FormattedAddress",
  fallbackValues,
  "default"
);
