import React from "react";
import { Box, Cluster } from "../layouts";
import ButtonWithAction from "../button-with-action";
import { FormInput } from "../form-layouts";
import { SearchIcon } from "../icons";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./Search.theme";
import { noop } from "../../../util/general";
import { colors } from "../../../constants";

/**
 * Search requires a redux-freeform field and actions to use
 * for its `FormInput` state, as well as a dataset and the keys you wish
 * to include in your search.
 *
 * The `dataset` expected is a one-dimensional array of objects.
 * Properties within the objects are included in the traversal
 * when they are present in the `valuesToSearchFor` prop.
 *
 * This component expects implementations of `onSearchCallback` and `onClearCallback`
 * in the consuming application. For example, if you want to filter a table based on
 * this, your callbacks should trigger the adjustment of your table data's state in your
 * application implementing Search.
 */

const Search = ({
  field,
  fieldActions,
  dataset,
  disableFilter = false, // Some implementations may prefer to filter the list at the application level (e.g., Revenue Management centralizing search, sort, and filtering Users state).
  valuesToSearchFor,
  onSearchCallback,
  onClearCallback,
  disabled,
  placeholder = "Search",
  searchOnKeypress = false,
  width = "100%",
  ariaControlsId,
  themeValues,
  extraStyles
}) => {
  const searchTerm = field.rawValue;

  const getFilteredDataset = () => {
    if (!searchTerm) return dataset;
    return dataset.filter(item =>
      valuesToSearchFor.some(key =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  };

  const handleSubmit = () =>
    disableFilter ? onSearchCallback() : onSearchCallback(getFilteredDataset());

  return (
    <Cluster extraStyles={`overflow: visible; width: ${width}; ${extraStyles}`}>
      <Box padding="0" extraStyles="flex-grow: 1;">
        <FormInput
          id="searchInput"
          role="search"
          aria-controls={ariaControlsId}
          extraStyles={`border-radius: 4px 0 0 4px; border: none; box-shadow: 0px 1px 2px 0px ${colors.CHARADE_GREY}1A; font-size: 0.875rem;`}
          onKeyDown={e =>
            searchOnKeypress || e.key === "Enter" ? handleSubmit() : noop
          }
          field={field}
          fieldActions={fieldActions}
          placeholder={placeholder}
          errorMessages={{}}
          disabled={disabled}
        />
        {searchTerm.length > 0 && (
          <Box
            padding="0"
            extraStyles={`
            position: absolute;
            right: 8px;
            top: 10px;
          `}
          >
            <ButtonWithAction
              extraStyles="* > span { text-decoration: none; }"
              type="reset"
              variant="smallGhost"
              text="Clear Search"
              aria-label="Clear Search"
              action={() => {
                fieldActions.set("");
                onClearCallback();
              }}
            />
          </Box>
        )}
      </Box>
      <ButtonWithAction
        type="submit"
        aria-label="Submit search"
        minWidth="0"
        width="3rem"
        padding=".5rem"
        extraStyles={`
          height: 48px; 
          margin: 4px 0 0;
          border-radius: 0 4px 4px 0;
          background: ${themeValues.searchIconBackgroundColor};
          border: none;
          box-shadow: 0px 1px 2px 0px ${colors.CHARADE_GREY}1A;
        `}
        variant="primary"
        contentOverride={true}
        action={handleSubmit}
      >
        <SearchIcon color={themeValues.searchIconColor} size={24} />
      </ButtonWithAction>
    </Cluster>
  );
};

export default themeComponent(Search, "Search", fallbackValues);
