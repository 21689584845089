import React from "react";
import styled from "styled-components";
import { MARINER_BLUE, PEWTER_GREY } from "../../colors";

const defaultTheme = {
  theme: {
    accentColor: MARINER_BLUE,
    inactiveColor: PEWTER_GREY
  }
};

const RadioButtonBorder = styled.div`
  height: 24px;
  width: 24px;
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.accentColor : theme.inactiveColor};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  min-width: 24px;
  min-height: 24px;
`;

RadioButtonBorder.defaultProps = defaultTheme;

const RadioButtonCenter = styled.div`
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.accentColor};
  border-radius: 8px;
`;

RadioButtonCenter.defaultProps = defaultTheme;

const RadioButton = ({ isSelected, name }) => (
  <RadioButtonBorder
    isSelected={isSelected}
    name={name}
    aria-checked={isSelected}
  >
    {isSelected && <RadioButtonCenter />}
  </RadioButtonBorder>
);

export default RadioButton;
