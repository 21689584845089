import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const CaretArrowUp = ({ color }) => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.883904 5.88388C1.07917 6.07914 1.39575 6.07914 1.59101 5.88388L4.94977 2.52512L8.30853 5.88388C8.50379 6.07914 8.82037 6.07914 9.01563 5.88388L9.54596 5.35355C9.74122 5.15829 9.74122 4.84171 9.54596 4.64644L5.83365 0.934134L5.30332 0.403804C5.10806 0.208542 4.79148 0.208542 4.59621 0.403804L4.06588 0.934134L0.353574 4.64644C0.158312 4.84171 0.158312 5.15829 0.353574 5.35355L0.883904 5.88388Z"
      fill={color}
    />
  </svg>
);

export default themeComponent(CaretArrowUp, "Icons", fallbackValues);
