import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const GoToEmailIcon = ({ themeValues }) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100">
      <title>{"Large Icons/Email - Go to"}</title>
      <defs>
        <path id="prefix__a" d="M0 0h100v100H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill={themeValues.primaryColor}>
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M75.21 27c1.474 0 4.14 3.11 7.994 9.332 0 0 1.162 4.471 1.618 10.353H60.557a1.94 1.94 0 00-1.073.322l-.114.08a1.99 1.99 0 00-.207.184l-.093.101a2.005 2.005 0 00-.484 1.313v.376c0 .251.045.492.129.713l.054.13c.06.129.13.25.214.361l.086.108a2 2 0 00.094.102l.101.096a1.977 1.977 0 00.38.263l.082.041.128.055c.13.05.268.088.411.11l.145.016.147.005H85a45.807 45.807 0 01-.256 5.057H60.557a1.95 1.95 0 00-1.394.586l-.093.101c-.03.036-.06.072-.087.109l-.08.115c-.2.313-.317.687-.317 1.089v.376c0 .251.045.491.129.712l.054.13a1.97 1.97 0 001.788 1.158h23.44c-.433 1.77-1.03 3.475-1.825 5.055H60.557c-.095 0-.187.007-.278.02l-.036.006-.118.023-.138.037a1.945 1.945 0 00-.43.19l-.073.047-.114.08a1.99 1.99 0 00-.207.184l-.093.101a2.006 2.006 0 00-.484 1.312v.378c0 .25.045.49.129.712l.054.13c.06.129.13.25.214.361l.086.108c.304.355.73.6 1.21.669l.13.014.148.006h18.938a37.834 37.834 0 01-4.285 5.056H60.556a1.95 1.95 0 00-1.393.586l-.093.101c-.03.036-.06.072-.087.109l-.08.115c-.2.313-.317.687-.317 1.088v.377a2.008 2.008 0 00.785 1.597l.112.08c.039.026.078.05.119.072l.124.065c.252.12.534.186.83.186h9.322c-9.313 6.286-18.815 6.622-19.679 6.64l-.064.001.108-16.602H14.497c-1.379 0-2.497-1.133-2.497-2.531V44.049c0-.105.006-.208.018-.31l.001-.417a.42.42 0 01.23-.38 2.506 2.506 0 011.8-1.385V38.22a.41.41 0 00-.401-.42h-.988a.411.411 0 01-.402-.418v-.895a.42.42 0 01.32-.407c.4-.096.752-.202 1.053-.317a5.652 5.652 0 001.01-.515.383.383 0 01.204-.06h1.37a.41.41 0 01.401.418v5.91h33.809L50.52 27z"
          fill={themeValues.primaryColor}
          mask="url(#prefix__b)"
        />
        <path
          d="M50.295 13a36.365 36.365 0 0123.033 8.19C81.912 28.173 87 38.667 87 50c0 20.43-16.429 37-36.705 37-12.763 0-24.002-6.566-30.579-16.527h-5.043a3.704 3.704 0 01-3.695-3.513l-.005-.196V49.787A10.34 10.34 0 015 40.392C5 34.685 9.586 30.06 15.245 30.06c1.303 0 2.55.246 3.697.693C25.386 20.108 37.015 13 50.295 13zm31.459 23.331H51.423v4.044h1.776a3.704 3.704 0 013.694 3.514l.005.196v22.679a3.704 3.704 0 01-3.502 3.704l-.197.005-1.776-.001v14.069c18.196-.595 32.812-15.522 33.109-33.97l.004-.571c0-4.795-.973-9.429-2.782-13.669zM49.166 70.472l-26.46.001c6.027 8.259 15.607 13.713 26.46 14.068V70.472zm5.323-23.776L35.526 58.583a2.942 2.942 0 01-3.004.103l-.177-.103-13.552-8.494a10.22 10.22 0 01-5.413.466l.001 16.209c0 .69.513 1.25 1.16 1.317l.132.006h38.526c.664 0 1.218-.516 1.284-1.187l.007-.136V46.696zm-1.29-3.935H25.22a10.346 10.346 0 01-4 6.026l12.413 7.78c.177.11.345.126.52.047l.087-.047 20.236-12.682A1.303 1.303 0 0053.2 42.76zm-36.81-7.483h-1.368a.384.384 0 00-.204.06c-.328.209-.665.38-1.01.512-.3.115-.652.22-1.052.315a.417.417 0 00-.32.404v.89c0 .23.18.417.402.417h.987c.222 0 .402.185.402.416v4.238c0 .23-.18.416-.402.416h-1.226a.41.41 0 00-.402.416v1.302c0 .23.18.417.402.417h5.493a.41.41 0 00.402-.417v-1.302a.41.41 0 00-.402-.416h-.898a.41.41 0 01-.402-.416v-6.836c0-.229-.18-.416-.401-.416zm33.906-19.837c-12.34 0-23.16 6.59-29.186 16.477a10.346 10.346 0 014.38 8.457h23.677V26.169c0-.579.445-1.055 1.014-1.112l.115-.006h23.694a34.712 34.712 0 00-2.225-1.974 33.882 33.882 0 00-20.391-7.62l-.538-.012-.54-.004zm6.39 13.599a2.024 2.024 0 00-1.97 2.073 2.021 2.021 0 002.056 1.988 2.023 2.023 0 001.97-2.073 2.02 2.02 0 00-2.055-1.988zm7.023 0a2.023 2.023 0 00-1.971 2.073 2.021 2.021 0 002.056 1.988 2.023 2.023 0 001.97-2.073 2.02 2.02 0 00-2.055-1.988zm7.022 0a2.024 2.024 0 00-1.972 2.073 2.023 2.023 0 002.057 1.988 2.023 2.023 0 001.97-2.073 2.02 2.02 0 00-2.055-1.988z"
          fill={themeValues.accentColor}
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  );
};

export default themeComponent(GoToEmailIcon, "Icons", fallbackValues, "info");
