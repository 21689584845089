import React from "react";
import { equals } from "ramda";
import { FormInput } from "../../atoms/form-layouts";
import { displayCurrency } from "../../../util/general";
import Text from "../../atoms/text";

const PartialAmountField = ({
  lineItem,
  field,
  showErrors,
  errorMessages,
  moneyFormat,
  fieldActions
}) => (
  <FormInput
    labelTextWhenNoError={lineItem.description}
    key={lineItem.id}
    field={field}
    fieldActions={fieldActions}
    showErrors={showErrors}
    errorMessages={errorMessages}
    style={{ textAlign: "right" }}
    placeholder="$0.00"
    formatter={moneyFormat}
    isNum
    decorator={
      <Text variant="p">
        Amount owed:{"  "}
        {
          <Text variant="p" weight="600">
            {displayCurrency(lineItem.amount)}
          </Text>
        }
      </Text>
    }
  />
);

function arePropsEqual(prevProps, nextProps) {
  return (
    equals(prevProps.errorMessages, nextProps.errorMessages) &&
    equals(prevProps.field, nextProps.field) &&
    equals(prevProps.showErrors, nextProps.showErrors) &&
    equals(prevProps.moneyFormat, nextProps.moneyFormat) &&
    equals(prevProps.lineItem, nextProps.lineItem)
  );
}

export default React.memo(PartialAmountField, arePropsEqual);
