export const placeHolderOptionUS = { text: "Please select state", value: "" };
export const placeHolderOption = {
  text: "Please select state or province",
  value: ""
};
export const getOptions = countryCode => allOptions[countryCode];
const allOptions = {
  AF: [
    { text: "Badakhshan", value: "BDS" },
    { text: "Baghlan", value: "BGL" },
    { text: "Balkh", value: "BAL" },
    { text: "Bamyan", value: "BAM" },
    { text: "Farah", value: "FRA" },
    { text: "Faryab", value: "FYB" },
    { text: "Ghazni", value: "GHA" },
    { text: "Helmand", value: "HEL" },
    { text: "Herat", value: "HER" },
    { text: "Kabul", value: "KAB" },
    { text: "Kandahar", value: "KAN" },
    { text: "Kapisa", value: "KAP" },
    { text: "Khost", value: "KHO" },
    { text: "Kunduz", value: "KDZ" },
    { text: "Logar", value: "LOG" },
    { text: "Nangarhar", value: "NAN" },
    { text: "Nimroz", value: "NIM" },
    { text: "Paktiya", value: "PIA" },
    { text: "Parwan", value: "PAR" },
    { text: "Samangan", value: "SAM" },
    { text: "Takhar", value: "TAK" },
    { text: "Uruzgan", value: "URU" }
  ],
  AX: [
    { text: "Eckeroe", value: "01" },
    { text: "Finstroem", value: "02" },
    { text: "Hammarland", value: "03" },
    { text: "Jomala", value: "04" },
    { text: "Mariehamn", value: "05" },
    { text: "Saltvik", value: "06" },
    { text: "Sund", value: "07" }
  ],
  AL: [
    { text: "Berat", value: "01" },
    { text: "Diber", value: "09" },
    { text: "Durres", value: "02" },
    { text: "Elbasan", value: "03" },
    { text: "Fier", value: "04" },
    { text: "Gjirokaster", value: "05" },
    { text: "Korce", value: "06" },
    { text: "Kukes", value: "07" },
    { text: "Lezhe", value: "08" },
    { text: "Shkoder", value: "10" },
    { text: "Tirane", value: "11" },
    { text: "Vlore", value: "12" }
  ],
  DZ: [
    { text: "Adrar", value: "01" },
    { text: "Ain Defla", value: "44" },
    { text: "Ain Temouchent", value: "46" },
    { text: "Alger", value: "16" },
    { text: "Annaba", value: "23" },
    { text: "Batna", value: "05" },
    { text: "Bechar", value: "08" },
    { text: "Bejaia", value: "06" },
    { text: "Biskra", value: "07" },
    { text: "Blida", value: "09" },
    { text: "Bordj Bou Arreridj", value: "34" },
    { text: "Bouira", value: "10" },
    { text: "Boumerdes", value: "35" },
    { text: "Chlef", value: "02" },
    { text: "Constantine", value: "25" },
    { text: "Djelfa", value: "17" },
    { text: "El Bayadh", value: "32" },
    { text: "El Oued", value: "39" },
    { text: "El Tarf", value: "36" },
    { text: "Ghardaia", value: "47" },
    { text: "Guelma", value: "24" },
    { text: "Illizi", value: "33" },
    { text: "Jijel", value: "18" },
    { text: "Khenchela", value: "40" },
    { text: "Laghouat", value: "03" },
    { text: "M'sila", value: "28" },
    { text: "Mascara", value: "29" },
    { text: "Medea", value: "26" },
    { text: "Mila", value: "43" },
    { text: "Mostaganem", value: "27" },
    { text: "Naama", value: "45" },
    { text: "Oran", value: "31" },
    { text: "Ouargla", value: "30" },
    { text: "Oum el Bouaghi", value: "04" },
    { text: "Relizane", value: "48" },
    { text: "Saida", value: "20" },
    { text: "Setif", value: "19" },
    { text: "Sidi Bel Abbes", value: "22" },
    { text: "Skikda", value: "21" },
    { text: "Souk Ahras", value: "41" },
    { text: "Tamanrasset", value: "11" },
    { text: "Tebessa", value: "12" },
    { text: "Tiaret", value: "14" },
    { text: "Tindouf", value: "37" },
    { text: "Tipaza", value: "42" },
    { text: "Tissemsilt", value: "38" },
    { text: "Tizi Ouzou", value: "15" },
    { text: "Tlemcen", value: "13" }
  ],
  AS: [{ text: "Eastern District", value: "01" }],
  AD: [
    { text: "Andorra la Vella", value: "07" },
    { text: "Canillo", value: "02" },
    { text: "Encamp", value: "03" },
    { text: "Escaldes-Engordany", value: "08" },
    { text: "La Massana", value: "04" },
    { text: "Ordino", value: "05" },
    { text: "Sant Julia de Loria", value: "06" }
  ],
  AO: [
    { text: "Bengo", value: "BGO" },
    { text: "Benguela", value: "BGU" },
    { text: "Bie", value: "BIE" },
    { text: "Cabinda", value: "CAB" },
    { text: "Cunene", value: "CNN" },
    { text: "Huambo", value: "HUA" },
    { text: "Huila", value: "HUI" },
    { text: "Kuando Kubango", value: "CCU" },
    { text: "Kwanza Norte", value: "CNO" },
    { text: "Kwanza Sul", value: "CUS" },
    { text: "Luanda", value: "LUA" },
    { text: "Lunda Norte", value: "LNO" },
    { text: "Lunda Sul", value: "LSU" },
    { text: "Malange", value: "MAL" },
    { text: "Moxico", value: "MOX" },
    { text: "Namibe", value: "NAM" },
    { text: "Uige", value: "UIG" },
    { text: "Zaire", value: "ZAI" }
  ],
  AI: [{ text: "Anguilla", value: "01" }],
  AQ: [{ text: "-", value: "01" }],
  AG: [
    { text: "Redonda", value: "11" },
    { text: "Saint George", value: "03" },
    { text: "Saint John", value: "04" },
    { text: "Saint Mary", value: "05" },
    { text: "Saint Paul", value: "06" },
    { text: "Saint Peter", value: "07" },
    { text: "Saint Philip", value: "08" }
  ],
  AR: [
    { text: "Buenos Aires", value: "B" },
    { text: "Catamarca", value: "K" },
    { text: "Chaco", value: "H" },
    { text: "Chubut", value: "U" },
    { text: "Ciudad Autonoma de Buenos Aires", value: "C" },
    { text: "Cordoba", value: "X" },
    { text: "Corrientes", value: "W" },
    { text: "Entre Rios", value: "E" },
    { text: "Formosa", value: "P" },
    { text: "Jujuy", value: "Y" },
    { text: "La Pampa", value: "L" },
    { text: "La Rioja", value: "F" },
    { text: "Mendoza", value: "M" },
    { text: "Misiones", value: "N" },
    { text: "Neuquen", value: "Q" },
    { text: "Rio Negro", value: "R" },
    { text: "Salta", value: "A" },
    { text: "San Juan", value: "J" },
    { text: "San Luis", value: "D" },
    { text: "Santa Cruz", value: "Z" },
    { text: "Santa Fe", value: "S" },
    { text: "Santiago del Estero", value: "G" },
    { text: "Tierra del Fuego", value: "V" },
    { text: "Tucuman", value: "T" }
  ],
  AM: [
    { text: "Aragacotn", value: "AG" },
    { text: "Ararat", value: "AR" },
    { text: "Armavir", value: "AV" },
    { text: "Erevan", value: "ER" },
    { text: "Gegark'unik'", value: "GR" },
    { text: "Kotayk'", value: "KT" },
    { text: "Lori", value: "LO" },
    { text: "Sirak", value: "SH" },
    { text: "Syunik'", value: "SU" },
    { text: "Tavus", value: "TV" },
    { text: "Vayoc Jor", value: "VD" }
  ],
  AW: [{ text: "Aruba", value: "01" }],
  AU: [
    { text: "Australian Capital Territory", value: "ACT" },
    { text: "New South Wales", value: "NSW" },
    { text: "Northern Territory", value: "NT" },
    { text: "Queensland", value: "QLD" },
    { text: "South Australia", value: "SA" },
    { text: "Tasmania", value: "TAS" },
    { text: "Victoria", value: "VIC" },
    { text: "Western Australia", value: "WA" }
  ],
  AT: [
    { text: "Burgenland", value: "1" },
    { text: "Karnten", value: "2" },
    { text: "Niederosterreich", value: "3" },
    { text: "Oberosterreich", value: "4" },
    { text: "Salzburg", value: "5" },
    { text: "Steiermark", value: "6" },
    { text: "Tirol", value: "7" },
    { text: "Vorarlberg", value: "8" },
    { text: "Wien", value: "9" }
  ],
  AZ: [
    { text: "Abseron", value: "ABS" },
    { text: "Agcabadi", value: "AGC" },
    { text: "Agdas", value: "AGS" },
    { text: "Agstafa", value: "AGA" },
    { text: "Agsu", value: "AGU" },
    { text: "Astara", value: "AST" },
    { text: "Baki", value: "BA" },
    { text: "Balakan", value: "BAL" },
    { text: "Barda", value: "BAR" },
    { text: "Beylaqan", value: "BEY" },
    { text: "Bilasuvar", value: "BIL" },
    { text: "Calilabad", value: "CAL" },
    { text: "Daskasan", value: "DAS" },
    { text: "Gadabay", value: "GAD" },
    { text: "Ganca", value: "GA" },
    { text: "Goranboy", value: "GOR" },
    { text: "Goycay", value: "GOY" },
    { text: "Goygol", value: "GYG" },
    { text: "Haciqabul", value: "HAC" },
    { text: "Imisli", value: "IMI" },
    { text: "Ismayilli", value: "ISM" },
    { text: "Kurdamir", value: "KUR" },
    { text: "Lankaran", value: "LA" },
    { text: "Masalli", value: "MAS" },
    { text: "Mingacevir", value: "MI" },
    { text: "Naftalan", value: "NA" },
    { text: "Naxcivan", value: "NX" },
    { text: "Neftcala", value: "NEF" },
    { text: "Oguz", value: "OGU" },
    { text: "Qabala", value: "QAB" },
    { text: "Qax", value: "QAX" },
    { text: "Qazax", value: "QAZ" },
    { text: "Quba", value: "QBA" },
    { text: "Qusar", value: "QUS" },
    { text: "Sabirabad", value: "SAB" },
    { text: "Saki", value: "SAK" },
    { text: "Salyan", value: "SAL" },
    { text: "Samaxi", value: "SMI" },
    { text: "Samkir", value: "SKR" },
    { text: "Samux", value: "SMX" },
    { text: "Sirvan", value: "SR" },
    { text: "Siyazan", value: "SIY" },
    { text: "Sumqayit", value: "SM" },
    { text: "Tovuz", value: "TOV" },
    { text: "Xacmaz", value: "XAC" },
    { text: "Xizi", value: "XIZ" },
    { text: "Yevlax", value: "YEV" },
    { text: "Zaqatala", value: "ZAQ" }
  ],
  BS: [
    { text: "Central Andros", value: "CS" },
    { text: "City of Freeport", value: "FP" },
    { text: "Harbour Island", value: "HI" },
    { text: "Hope Town", value: "HT" },
    { text: "Long Island", value: "LI" },
    { text: "New Providence", value: "NP" },
    { text: "South Eleuthera", value: "SE" }
  ],
  BH: [
    { text: "Al 'Asimah", value: "13" },
    { text: "Al Janubiyah", value: "14" },
    { text: "Al Muharraq", value: "15" },
    { text: "Ash Shamaliyah", value: "17" }
  ],
  BD: [
    { text: "Barisal", value: "A" },
    { text: "Chittagong", value: "B" },
    { text: "Dhaka", value: "C" },
    { text: "Khulna", value: "D" },
    { text: "Rajshahi", value: "E" },
    { text: "Rangpur", value: "F" },
    { text: "Sylhet", value: "G" }
  ],
  BB: [
    { text: "Christ Church", value: "01" },
    { text: "Saint Andrew", value: "02" },
    { text: "Saint George", value: "03" },
    { text: "Saint James", value: "04" },
    { text: "Saint John", value: "05" },
    { text: "Saint Joseph", value: "06" },
    { text: "Saint Lucy", value: "07" },
    { text: "Saint Michael", value: "08" },
    { text: "Saint Peter", value: "09" },
    { text: "Saint Philip", value: "10" },
    { text: "Saint Thomas", value: "11" }
  ],
  BY: [
    { text: "Brestskaya voblasts'", value: "BR" },
    { text: "Homyel'skaya voblasts'", value: "HO" },
    { text: "Horad Minsk", value: "HM" },
    { text: "Hrodzenskaya voblasts'", value: "HR" },
    { text: "Mahilyowskaya voblasts'", value: "MA" },
    { text: "Minskaya voblasts'", value: "MI" },
    { text: "Vitsyebskaya voblasts'", value: "VI" }
  ],
  BE: [
    { text: "Antwerpen", value: "VAN" },
    { text: "Brabant wallon", value: "WBR" },
    { text: "Brussels Hoofdstedelijk Gewest", value: "BRU" },
    { text: "Hainaut", value: "WHT" },
    { text: "Liege", value: "WLG" },
    { text: "Limburg", value: "VLI" },
    { text: "Luxembourg", value: "WLX" },
    { text: "Namur", value: "WNA" },
    { text: "Oost-Vlaanderen", value: "VOV" },
    { text: "Vlaams-Brabant", value: "VBR" },
    { text: "West-Vlaanderen", value: "VWV" }
  ],
  BZ: [
    { text: "Belize", value: "BZ" },
    { text: "Cayo", value: "CY" },
    { text: "Corozal", value: "CZL" },
    { text: "Orange Walk", value: "OW" },
    { text: "Stann Creek", value: "SC" },
    { text: "Toledo", value: "TOL" }
  ],
  BJ: [
    { text: "Alibori", value: "AL" },
    { text: "Atacora", value: "AK" },
    { text: "Atlantique", value: "AQ" },
    { text: "Borgou", value: "BO" },
    { text: "Donga", value: "DO" },
    { text: "Littoral", value: "LI" },
    { text: "Mono", value: "MO" },
    { text: "Oueme", value: "OU" },
    { text: "Plateau", value: "PL" },
    { text: "Zou", value: "ZO" }
  ],
  BM: [
    { text: "Hamilton", value: "01" },
    { text: "Saint George", value: "02" }
  ],
  BT: [
    { text: "Bumthang", value: "33" },
    { text: "Chhukha", value: "12" },
    { text: "Gasa", value: "GA" },
    { text: "Haa", value: "13" },
    { text: "Lhuentse", value: "44" },
    { text: "Monggar", value: "42" },
    { text: "Paro", value: "11" },
    { text: "Pemagatshel", value: "43" },
    { text: "Punakha", value: "23" },
    { text: "Samdrup Jongkhar", value: "45" },
    { text: "Samtse", value: "14" },
    { text: "Thimphu", value: "15" },
    { text: "Trashigang", value: "41" },
    { text: "Trongsa", value: "32" },
    { text: "Tsirang", value: "21" },
    { text: "Wangdue Phodrang", value: "24" }
  ],
  BO: [
    { text: "Chuquisaca", value: "H" },
    { text: "Cochabamba", value: "C" },
    { text: "El Beni", value: "B" },
    { text: "La Paz", value: "L" },
    { text: "Oruro", value: "O" },
    { text: "Pando", value: "N" },
    { text: "Potosi", value: "P" },
    { text: "Santa Cruz", value: "S" },
    { text: "Tarija", value: "T" }
  ],
  BA: [
    { text: "Brcko distrikt", value: "BRC" },
    { text: "Federacija Bosne i Hercegovine", value: "BIH" },
    { text: "Republika Srpska", value: "SRP" }
  ],
  BW: [
    { text: "Central", value: "CE" },
    { text: "Kgatleng", value: "KL" },
    { text: "Kweneng", value: "KW" },
    { text: "North East", value: "NE" },
    { text: "North West", value: "NW" },
    { text: "South East", value: "SE" },
    { text: "Southern", value: "SO" }
  ],
  BV: [{ text: "-", value: "01" }],
  BR: [
    { text: "Acre", value: "AC" },
    { text: "Alagoas", value: "AL" },
    { text: "Amapa", value: "AP" },
    { text: "Amazonas", value: "AM" },
    { text: "Bahia", value: "BA" },
    { text: "Ceara", value: "CE" },
    { text: "Distrito Federal", value: "DF" },
    { text: "Espirito Santo", value: "ES" },
    { text: "Goias", value: "GO" },
    { text: "Maranhao", value: "MA" },
    { text: "Mato Grosso", value: "MT" },
    { text: "Mato Grosso do Sul", value: "MS" },
    { text: "Minas Gerais", value: "MG" },
    { text: "Para", value: "PA" },
    { text: "Paraiba", value: "PB" },
    { text: "Parana", value: "PR" },
    { text: "Pernambuco", value: "PE" },
    { text: "Piaui", value: "PI" },
    { text: "Rio Grande do Norte", value: "RN" },
    { text: "Rio Grande do Sul", value: "RS" },
    { text: "Rio de Janeiro", value: "RJ" },
    { text: "Rondonia", value: "RO" },
    { text: "Roraima", value: "RR" },
    { text: "Santa Catarina", value: "SC" },
    { text: "Sao Paulo", value: "SP" },
    { text: "Sergipe", value: "SE" },
    { text: "Tocantins", value: "TO" }
  ],
  VG: [{ text: "Virgin Islands, British", value: "01" }],
  IO: [{ text: "British Indian Ocean Territory", value: "01" }],
  BN: [
    { text: "Belait", value: "BE" },
    { text: "Brunei-Muara", value: "BM" },
    { text: "Temburong", value: "TE" },
    { text: "Tutong", value: "TU" }
  ],
  BG: [
    { text: "Blagoevgrad", value: "01" },
    { text: "Burgas", value: "02" },
    { text: "Dobrich", value: "08" },
    { text: "Gabrovo", value: "07" },
    { text: "Haskovo", value: "26" },
    { text: "Kardzhali", value: "09" },
    { text: "Kyustendil", value: "10" },
    { text: "Lovech", value: "11" },
    { text: "Montana", value: "12" },
    { text: "Pazardzhik", value: "13" },
    { text: "Pernik", value: "14" },
    { text: "Pleven", value: "15" },
    { text: "Plovdiv", value: "16" },
    { text: "Razgrad", value: "17" },
    { text: "Ruse", value: "18" },
    { text: "Shumen", value: "27" },
    { text: "Silistra", value: "19" },
    { text: "Sliven", value: "20" },
    { text: "Smolyan", value: "21" },
    { text: "Sofia", value: "23" },
    { text: "Sofia (stolitsa)", value: "22" },
    { text: "Stara Zagora", value: "24" },
    { text: "Targovishte", value: "25" },
    { text: "Varna", value: "03" },
    { text: "Veliko Tarnovo", value: "04" },
    { text: "Vidin", value: "05" },
    { text: "Vratsa", value: "06" },
    { text: "Yambol", value: "28" }
  ],
  BF: [
    { text: "Bale", value: "BAL" },
    { text: "Boulgou", value: "BLG" },
    { text: "Boulkiemde", value: "BLK" },
    { text: "Comoe", value: "COM" },
    { text: "Gnagna", value: "GNA" },
    { text: "Houet", value: "HOU" },
    { text: "Kadiogo", value: "KAD" },
    { text: "Kourweogo", value: "KOW" },
    { text: "Leraba", value: "LER" },
    { text: "Mouhoun", value: "MOU" },
    { text: "Oubritenga", value: "OUB" },
    { text: "Poni", value: "PON" },
    { text: "Sanmatenga", value: "SMT" },
    { text: "Tapoa", value: "TAP" },
    { text: "Tuy", value: "TUI" },
    { text: "Yatenga", value: "YAT" },
    { text: "Zoundweogo", value: "ZOU" }
  ],
  BI: [
    { text: "Bubanza", value: "BB" },
    { text: "Bujumbura Mairie", value: "BM" },
    { text: "Bururi", value: "BR" },
    { text: "Cibitoke", value: "CI" },
    { text: "Gitega", value: "GI" },
    { text: "Kirundo", value: "KI" },
    { text: "Muyinga", value: "MY" },
    { text: "Mwaro", value: "MW" },
    { text: "Ngozi", value: "NG" },
    { text: "Rutana", value: "RT" },
    { text: "Ruyigi", value: "RY" }
  ],
  KH: [
    { text: "Baat Dambang", value: "2" },
    { text: "Banteay Mean Chey", value: "1" },
    { text: "Kampong Chaam", value: "3" },
    { text: "Kampong Chhnang", value: "4" },
    { text: "Kampong Spueu", value: "5" },
    { text: "Kampong Thum", value: "6" },
    { text: "Kampot", value: "7" },
    { text: "Kandaal", value: "8" },
    { text: "Kaoh Kong", value: "9" },
    { text: "Kracheh", value: "10" },
    { text: "Krong Kaeb", value: "23" },
    { text: "Krong Pailin", value: "24" },
    { text: "Krong Preah Sihanouk", value: "18" },
    { text: "Mondol Kiri", value: "11" },
    { text: "Phnom Penh", value: "12" },
    { text: "Prey Veaeng", value: "14" },
    { text: "Rotanak Kiri", value: "16" },
    { text: "Siem Reab", value: "17" },
    { text: "Svaay Rieng", value: "20" },
    { text: "Taakaev", value: "21" }
  ],
  CM: [
    { text: "Adamaoua", value: "AD" },
    { text: "Centre", value: "CE" },
    { text: "Est", value: "ES" },
    { text: "Extreme-Nord", value: "EN" },
    { text: "Littoral", value: "LT" },
    { text: "Nord", value: "NO" },
    { text: "Nord-Ouest", value: "NW" },
    { text: "Ouest", value: "OU" },
    { text: "Sud", value: "SU" },
    { text: "Sud-Ouest", value: "SW" }
  ],
  CA: [
    { text: "Alberta", value: "AB" },
    { text: "British Columbia", value: "BC" },
    { text: "Manitoba", value: "MB" },
    { text: "New Brunswick", value: "NB" },
    { text: "Newfoundland and Labrador", value: "NL" },
    { text: "Northwest Territories", value: "NT" },
    { text: "Nova Scotia", value: "NS" },
    { text: "Nunavut", value: "NU" },
    { text: "Ontario", value: "ON" },
    { text: "Prince Edward Island", value: "PE" },
    { text: "Quebec", value: "QC" },
    { text: "Saskatchewan", value: "SK" },
    { text: "Yukon", value: "YT" }
  ],
  CV: [
    { text: "Boa Vista", value: "BV" },
    { text: "Paul", value: "PA" },
    { text: "Porto Novo", value: "PN" },
    { text: "Praia", value: "PR" },
    { text: "Ribeira Grande", value: "RG" },
    { text: "Sal", value: "SL" },
    { text: "Sao Vicente", value: "SV" },
    { text: "Tarrafal", value: "TA" }
  ],
  KY: [{ text: "Cayman Islands", value: "01" }],
  CF: [
    { text: "Bangui", value: "BGF" },
    { text: "Haute-Kotto", value: "HK" },
    { text: "Nana-Mambere", value: "NM" },
    { text: "Ouham", value: "AC" }
  ],
  TD: [
    { text: "Guera", value: "GR" },
    { text: "Hadjer Lamis", value: "HL" },
    { text: "Logone-Occidental", value: "LO" },
    { text: "Mayo-Kebbi-Est", value: "ME" },
    { text: "Ouaddai", value: "OD" },
    { text: "Tibesti", value: "TI" },
    { text: "Ville de Ndjamena", value: "ND" }
  ],
  CL: [
    { text: "Aisen del General Carlos Ibanez del Campo", value: "AI" },
    { text: "Antofagasta", value: "AN" },
    { text: "Arica y Parinacota", value: "AP" },
    { text: "Atacama", value: "AT" },
    { text: "Biobio", value: "BI" },
    { text: "Coquimbo", value: "CO" },
    { text: "La Araucania", value: "AR" },
    { text: "Libertador General Bernardo O'Higgins", value: "LI" },
    { text: "Los Lagos", value: "LL" },
    { text: "Los Rios", value: "LR" },
    { text: "Magallanes", value: "MA" },
    { text: "Maule", value: "ML" },
    { text: "Region Metropolitana de Santiago", value: "RM" },
    { text: "Tarapaca", value: "TA" },
    { text: "Valparaiso", value: "VS" }
  ],
  CN: [
    { text: "Anhui", value: "AH" },
    { text: "Beijing", value: "BJ" },
    { text: "Chongqing", value: "CQ" },
    { text: "Fujian", value: "FJ" },
    { text: "Gansu", value: "GS" },
    { text: "Guangdong", value: "GD" },
    { text: "Guangxi", value: "GX" },
    { text: "Guizhou", value: "GZ" },
    { text: "Hainan", value: "HI" },
    { text: "Hebei", value: "HE" },
    { text: "Heilongjiang", value: "HL" },
    { text: "Henan", value: "HA" },
    { text: "Hubei", value: "HB" },
    { text: "Hunan", value: "HN" },
    { text: "Jiangsu", value: "JS" },
    { text: "Jiangxi", value: "JX" },
    { text: "Jilin", value: "JL" },
    { text: "Liaoning", value: "LN" },
    { text: "Nei Mongol", value: "NM" },
    { text: "Ningxia", value: "NX" },
    { text: "Qinghai", value: "QH" },
    { text: "Shaanxi", value: "SN" },
    { text: "Shandong", value: "SD" },
    { text: "Shanghai", value: "SH" },
    { text: "Shanxi", value: "SX" },
    { text: "Sichuan", value: "SC" },
    { text: "Tianjin", value: "TJ" },
    { text: "Xinjiang", value: "XJ" },
    { text: "Xizang", value: "XZ" },
    { text: "Yunnan", value: "YN" },
    { text: "Zhejiang", value: "ZJ" }
  ],
  HK: [{ text: "Hong Kong", value: "01" }],
  MO: [{ text: "Macao", value: "01" }],
  CX: [{ text: "-", value: "01" }],
  CC: [{ text: "-", value: "01" }],
  CO: [
    { text: "Amazonas", value: "AMA" },
    { text: "Antioquia", value: "ANT" },
    { text: "Arauca", value: "ARA" },
    { text: "Atlantico", value: "ATL" },
    { text: "Bolivar", value: "BOL" },
    { text: "Boyaca", value: "BOY" },
    { text: "Caldas", value: "CAL" },
    { text: "Caqueta", value: "CAQ" },
    { text: "Casanare", value: "CAS" },
    { text: "Cauca", value: "CAU" },
    { text: "Cesar", value: "CES" },
    { text: "Choco", value: "CHO" },
    { text: "Cordoba", value: "COR" },
    { text: "Cundinamarca", value: "CUN" },
    { text: "Distrito Capital de Bogota", value: "DC" },
    { text: "Guainia", value: "GUA" },
    { text: "Guaviare", value: "GUV" },
    { text: "Huila", value: "HUI" },
    { text: "La Guajira", value: "LAG" },
    { text: "Magdalena", value: "MAG" },
    { text: "Meta", value: "MET" },
    { text: "Narino", value: "NAR" },
    { text: "Norte de Santander", value: "NSA" },
    { text: "Putumayo", value: "PUT" },
    { text: "Quindio", value: "QUI" },
    { text: "Risaralda", value: "RIS" },
    { text: "San Andres, Providencia y Santa Catalina", value: "SAP" },
    { text: "Santander", value: "SAN" },
    { text: "Sucre", value: "SUC" },
    { text: "Tolima", value: "TOL" },
    { text: "Valle del Cauca", value: "VAC" },
    { text: "Vichada", value: "VID" }
  ],
  KM: [{ text: "Grande Comore", value: "G" }],
  CG: [
    { text: "Brazzaville", value: "BZV" },
    { text: "Cuvette", value: "8" },
    { text: "Cuvette-Ouest", value: "15" },
    { text: "Pointe-Noire", value: "16" },
    { text: "Sangha", value: "13" }
  ],
  CD: [
    { text: "Equateur", value: "EQ" },
    { text: "Haut-Katanga", value: "HK" },
    { text: "Ituri", value: "IT" },
    { text: "Kasai Central", value: "KC" },
    { text: "Kasai Oriental", value: "KE" },
    { text: "Kinshasa", value: "KN" },
    { text: "Kongo Central", value: "BC" },
    { text: "Kwilu", value: "KL" },
    { text: "Lualaba", value: "LU" },
    { text: "Maniema", value: "MA" },
    { text: "Nord-Kivu", value: "NK" },
    { text: "Nord-Ubangi", value: "NU" },
    { text: "Sankuru", value: "SA" },
    { text: "Sud-Kivu", value: "SK" },
    { text: "Sud-Ubangi", value: "SU" },
    { text: "Tanganyika", value: "TA" },
    { text: "Tshopo", value: "TO" },
    { text: "Tshuapa", value: "TU" }
  ],
  CK: [{ text: "Cook Islands", value: "01" }],
  CR: [
    { text: "Alajuela", value: "A" },
    { text: "Cartago", value: "C" },
    { text: "Guanacaste", value: "G" },
    { text: "Heredia", value: "H" },
    { text: "Limon", value: "L" },
    { text: "Puntarenas", value: "P" },
    { text: "San Jose", value: "SJ" }
  ],
  CI: [
    { text: "Abidjan", value: "AB" },
    { text: "Bas-Sassandra", value: "BS" },
    { text: "Comoe", value: "CM" },
    { text: "Denguele", value: "DN" },
    { text: "Goh-Djiboua", value: "GD" },
    { text: "Lacs", value: "LC" },
    { text: "Lagunes", value: "LG" },
    { text: "Montagnes", value: "MG" },
    { text: "Sassandra-Marahoue", value: "SM" },
    { text: "Savanes", value: "SV" },
    { text: "Vallee du Bandama", value: "VB" },
    { text: "Woroba", value: "WR" },
    { text: "Yamoussoukro", value: "YM" },
    { text: "Zanzan", value: "ZZ" }
  ],
  HR: [
    { text: "Bjelovarsko-bilogorska zupanija", value: "07" },
    { text: "Brodsko-posavska zupanija", value: "12" },
    { text: "Dubrovacko-neretvanska zupanija", value: "19" },
    { text: "Grad Zagreb", value: "21" },
    { text: "Istarska zupanija", value: "18" },
    { text: "Karlovacka zupanija", value: "04" },
    { text: "Koprivnicko-krizevacka zupanija", value: "06" },
    { text: "Krapinsko-zagorska zupanija", value: "02" },
    { text: "Licko-senjska zupanija", value: "09" },
    { text: "Medimurska zupanija", value: "20" },
    { text: "Osjecko-baranjska zupanija", value: "14" },
    { text: "Pozesko-slavonska zupanija", value: "11" },
    { text: "Primorsko-goranska zupanija", value: "08" },
    { text: "Sibensko-kninska zupanija", value: "15" },
    { text: "Sisacko-moslavacka zupanija", value: "03" },
    { text: "Splitsko-dalmatinska zupanija", value: "17" },
    { text: "Varazdinska zupanija", value: "05" },
    { text: "Viroviticko-podravska zupanija", value: "10" },
    { text: "Vukovarsko-srijemska zupanija", value: "16" },
    { text: "Zadarska zupanija", value: "13" },
    { text: "Zagrebacka zupanija", value: "01" }
  ],
  CU: [
    { text: "Artemisa", value: "15" },
    { text: "Camaguey", value: "09" },
    { text: "Ciego de Avila", value: "08" },
    { text: "Cienfuegos", value: "06" },
    { text: "Granma", value: "12" },
    { text: "Guantanamo", value: "14" },
    { text: "Holguin", value: "11" },
    { text: "La Habana", value: "03" },
    { text: "Las Tunas", value: "10" },
    { text: "Matanzas", value: "04" },
    { text: "Mayabeque", value: "16" },
    { text: "Pinar del Rio", value: "01" },
    { text: "Sancti Spiritus", value: "07" },
    { text: "Santiago de Cuba", value: "13" },
    { text: "Villa Clara", value: "05" }
  ],
  CY: [
    { text: "Ammochostos", value: "04" },
    { text: "Keryneia", value: "06" },
    { text: "Larnaka", value: "03" },
    { text: "Lefkosia", value: "01" },
    { text: "Lemesos", value: "02" },
    { text: "Pafos", value: "05" }
  ],
  CZ: [
    { text: "Jihocesky kraj", value: "31" },
    { text: "Jihomoravsky kraj", value: "64" },
    { text: "Karlovarsky kraj", value: "41" },
    { text: "Kraj Vysocina", value: "63" },
    { text: "Kralovehradecky kraj", value: "52" },
    { text: "Liberecky kraj", value: "51" },
    { text: "Moravskoslezsky kraj", value: "80" },
    { text: "Olomoucky kraj", value: "71" },
    { text: "Pardubicky kraj", value: "53" },
    { text: "Plzensky kraj", value: "32" },
    { text: "Praha, Hlavni mesto", value: "10" },
    { text: "Stredocesky kraj", value: "20" },
    { text: "Ustecky kraj", value: "42" },
    { text: "Zlinsky kraj", value: "72" }
  ],
  DK: [
    { text: "Hovedstaden", value: "84" },
    { text: "Midtjylland", value: "82" },
    { text: "Nordjylland", value: "81" },
    { text: "Sjaelland", value: "85" },
    { text: "Syddanmark", value: "83" }
  ],
  DJ: [{ text: "Djibouti", value: "DJ" }],
  DM: [
    { text: "Saint Andrew", value: "02" },
    { text: "Saint George", value: "04" },
    { text: "Saint John", value: "05" },
    { text: "Saint Mark", value: "08" },
    { text: "Saint Paul", value: "10" }
  ],
  DO: [
    { text: "Azua", value: "02" },
    { text: "Baoruco", value: "03" },
    { text: "Barahona", value: "04" },
    { text: "Dajabon", value: "05" },
    { text: "Distrito Nacional (Santo Domingo)", value: "01" },
    { text: "Duarte", value: "06" },
    { text: "El Seibo", value: "08" },
    { text: "Espaillat", value: "09" },
    { text: "Hato Mayor", value: "30" },
    { text: "Hermanas Mirabal", value: "19" },
    { text: "Independencia", value: "10" },
    { text: "La Altagracia", value: "11" },
    { text: "La Romana", value: "12" },
    { text: "La Vega", value: "13" },
    { text: "Maria Trinidad Sanchez", value: "14" },
    { text: "Monsenor Nouel", value: "28" },
    { text: "Monte Cristi", value: "15" },
    { text: "Monte Plata", value: "29" },
    { text: "Peravia", value: "17" },
    { text: "Puerto Plata", value: "18" },
    { text: "Samana", value: "20" },
    { text: "San Cristobal", value: "21" },
    { text: "San Jose de Ocoa", value: "31" },
    { text: "San Juan", value: "22" },
    { text: "San Pedro de Macoris", value: "23" },
    { text: "Sanchez Ramirez", value: "24" },
    { text: "Santiago", value: "25" },
    { text: "Santiago Rodriguez", value: "26" },
    { text: "Valverde", value: "27" }
  ],
  EC: [
    { text: "Azuay", value: "A" },
    { text: "Bolivar", value: "B" },
    { text: "Canar", value: "F" },
    { text: "Carchi", value: "C" },
    { text: "Chimborazo", value: "H" },
    { text: "Cotopaxi", value: "X" },
    { text: "El Oro", value: "O" },
    { text: "Esmeraldas", value: "E" },
    { text: "Galapagos", value: "W" },
    { text: "Guayas", value: "G" },
    { text: "Imbabura", value: "I" },
    { text: "Loja", value: "L" },
    { text: "Los Rios", value: "R" },
    { text: "Manabi", value: "M" },
    { text: "Morona Santiago", value: "S" },
    { text: "Napo", value: "N" },
    { text: "Orellana", value: "D" },
    { text: "Pastaza", value: "Y" },
    { text: "Pichincha", value: "P" },
    { text: "Santa Elena", value: "SE" },
    { text: "Santo Domingo de los Tsachilas", value: "SD" },
    { text: "Sucumbios", value: "U" },
    { text: "Tungurahua", value: "T" },
    { text: "Zamora Chinchipe", value: "Z" }
  ],
  EG: [
    { text: "Ad Daqahliyah", value: "DK" },
    { text: "Al Bahr al Ahmar", value: "BA" },
    { text: "Al Buhayrah", value: "BH" },
    { text: "Al Fayyum", value: "FYM" },
    { text: "Al Gharbiyah", value: "GH" },
    { text: "Al Iskandariyah", value: "ALX" },
    { text: "Al Isma'iliyah", value: "IS" },
    { text: "Al Jizah", value: "GZ" },
    { text: "Al Minufiyah", value: "MNF" },
    { text: "Al Minya", value: "MN" },
    { text: "Al Qahirah", value: "C" },
    { text: "Al Qalyubiyah", value: "KB" },
    { text: "Al Uqsur", value: "LX" },
    { text: "Al Wadi al Jadid", value: "WAD" },
    { text: "As Suways", value: "SUZ" },
    { text: "Ash Sharqiyah", value: "SHR" },
    { text: "Aswan", value: "ASN" },
    { text: "Asyut", value: "AST" },
    { text: "Bani Suwayf", value: "BNS" },
    { text: "Bur Sa'id", value: "PTS" },
    { text: "Dumyat", value: "DT" },
    { text: "Janub Sina'", value: "JS" },
    { text: "Kafr ash Shaykh", value: "KFS" },
    { text: "Matruh", value: "MT" },
    { text: "Qina", value: "KN" },
    { text: "Shamal Sina'", value: "SIN" },
    { text: "Suhaj", value: "SHG" }
  ],
  SV: [
    { text: "Ahuachapan", value: "AH" },
    { text: "Cabanas", value: "CA" },
    { text: "Chalatenango", value: "CH" },
    { text: "Cuscatlan", value: "CU" },
    { text: "La Libertad", value: "LI" },
    { text: "La Paz", value: "PA" },
    { text: "La Union", value: "UN" },
    { text: "Morazan", value: "MO" },
    { text: "San Miguel", value: "SM" },
    { text: "San Salvador", value: "SS" },
    { text: "San Vicente", value: "SV" },
    { text: "Santa Ana", value: "SA" },
    { text: "Sonsonate", value: "SO" },
    { text: "Usulutan", value: "US" }
  ],
  GQ: [
    { text: "Bioko Norte", value: "BN" },
    { text: "Bioko Sur", value: "BS" },
    { text: "Litoral", value: "LI" },
    { text: "Wele-Nzas", value: "WN" }
  ],
  ER: [{ text: "Al Awsat", value: "MA" }],
  EE: [
    { text: "Harjumaa", value: "37" },
    { text: "Hiiumaa", value: "39" },
    { text: "Ida-Virumaa", value: "44" },
    { text: "Jarvamaa", value: "51" },
    { text: "Jogevamaa", value: "49" },
    { text: "Laane-Virumaa", value: "59" },
    { text: "Laanemaa", value: "57" },
    { text: "Parnumaa", value: "67" },
    { text: "Polvamaa", value: "65" },
    { text: "Raplamaa", value: "70" },
    { text: "Saaremaa", value: "74" },
    { text: "Tartumaa", value: "78" },
    { text: "Valgamaa", value: "82" },
    { text: "Viljandimaa", value: "84" },
    { text: "Vorumaa", value: "86" }
  ],
  ET: [
    { text: "Adis Abeba", value: "AA" },
    { text: "Afar", value: "AF" },
    { text: "Amara", value: "AM" },
    { text: "Binshangul Gumuz", value: "BE" },
    { text: "Hareri Hizb", value: "HA" },
    { text: "Oromiya", value: "OR" },
    { text: "Sumale", value: "SO" },
    { text: "Tigray", value: "TI" },
    { text: "YeDebub Biheroch Bihereseboch na Hizboch", value: "SN" }
  ],
  FK: [{ text: "Falkland Islands (Malvinas)", value: "01" }],
  FO: [
    { text: "Eysturoy", value: "01" },
    { text: "Nordoyar", value: "02" },
    { text: "Streymoy", value: "03" },
    { text: "Suduroy", value: "04" },
    { text: "Vagar", value: "05" }
  ],
  FJ: [
    { text: "Central", value: "C" },
    { text: "Eastern", value: "E" },
    { text: "Rotuma", value: "R" },
    { text: "Western", value: "W" }
  ],
  FI: [
    { text: "Etela-Karjala", value: "02" },
    { text: "Etela-Pohjanmaa", value: "03" },
    { text: "Etela-Savo", value: "04" },
    { text: "Kainuu", value: "05" },
    { text: "Kanta-Hame", value: "06" },
    { text: "Keski-Pohjanmaa", value: "07" },
    { text: "Keski-Suomi", value: "08" },
    { text: "Kymenlaakso", value: "09" },
    { text: "Lappi", value: "10" },
    { text: "Paijat-Hame", value: "16" },
    { text: "Pirkanmaa", value: "11" },
    { text: "Pohjanmaa", value: "12" },
    { text: "Pohjois-Karjala", value: "13" },
    { text: "Pohjois-Pohjanmaa", value: "14" },
    { text: "Pohjois-Savo", value: "15" },
    { text: "Satakunta", value: "17" },
    { text: "Uusimaa", value: "18" },
    { text: "Varsinais-Suomi", value: "19" }
  ],
  FR: [
    { text: "Auvergne-Rhone-Alpes", value: "ARA" },
    { text: "Bourgogne-Franche-Comte", value: "BFC" },
    { text: "Bretagne", value: "BRE" },
    { text: "Centre-Val de Loire", value: "CVL" },
    { text: "Corse", value: "COR" },
    { text: "Grand-Est", value: "GES" },
    { text: "Hauts-de-France", value: "HDF" },
    { text: "Ile-de-France", value: "IDF" },
    { text: "Normandie", value: "NOR" },
    { text: "Nouvelle-Aquitaine", value: "NAQ" },
    { text: "Occitanie", value: "OCC" },
    { text: "Pays-de-la-Loire", value: "PDL" },
    { text: "Provence-Alpes-Cote-d'Azur", value: "PAC" }
  ],
  GF: [{ text: "Guyane", value: "01" }],
  PF: [
    { text: "Iles Marquises", value: "01" },
    { text: "Iles Sous-le-Vent", value: "02" },
    { text: "Iles Tuamotu-Gambier", value: "03" },
    { text: "Iles du Vent", value: "04" }
  ],
  TF: [{ text: "-", value: "01" }],
  GA: [
    { text: "Estuaire", value: "1" },
    { text: "Haut-Ogooue", value: "2" },
    { text: "Moyen-Ogooue", value: "3" },
    { text: "Ngounie", value: "4" },
    { text: "Ogooue-Ivindo", value: "6" },
    { text: "Ogooue-Maritime", value: "8" },
    { text: "Woleu-Ntem", value: "9" }
  ],
  GM: [
    { text: "Banjul", value: "B" },
    { text: "Central River", value: "M" },
    { text: "Lower River", value: "L" },
    { text: "North Bank", value: "N" },
    { text: "Upper River", value: "U" },
    { text: "Western", value: "W" }
  ],
  GE: [
    { text: "Abkhazia", value: "AB" },
    { text: "Ajaria", value: "AJ" },
    { text: "Guria", value: "GU" },
    { text: "Imereti", value: "IM" },
    { text: "K'akheti", value: "KA" },
    { text: "Kvemo Kartli", value: "KK" },
    { text: "Mtskheta-Mtianeti", value: "MM" },
    { text: "Rach'a-Lechkhumi-Kvemo Svaneti", value: "RL" },
    { text: "Samegrelo-Zemo Svaneti", value: "SZ" },
    { text: "Samtskhe-Javakheti", value: "SJ" },
    { text: "Shida Kartli", value: "SK" },
    { text: "Tbilisi", value: "TB" }
  ],
  DE: [
    { text: "Baden-Wurttemberg", value: "BW" },
    { text: "Bayern", value: "BY" },
    { text: "Berlin", value: "BE" },
    { text: "Brandenburg", value: "BB" },
    { text: "Bremen", value: "HB" },
    { text: "Hamburg", value: "HH" },
    { text: "Hessen", value: "HE" },
    { text: "Mecklenburg-Vorpommern", value: "MV" },
    { text: "Niedersachsen", value: "NI" },
    { text: "Nordrhein-Westfalen", value: "NW" },
    { text: "Rheinland-Pfalz", value: "RP" },
    { text: "Saarland", value: "SL" },
    { text: "Sachsen", value: "SN" },
    { text: "Sachsen-Anhalt", value: "ST" },
    { text: "Schleswig-Holstein", value: "SH" },
    { text: "Thuringen", value: "TH" }
  ],
  GH: [
    { text: "Ashanti", value: "AH" },
    { text: "Brong-Ahafo", value: "BA" },
    { text: "Central", value: "CP" },
    { text: "Eastern", value: "EP" },
    { text: "Greater Accra", value: "AA" },
    { text: "Northern", value: "NP" },
    { text: "Upper East", value: "UE" },
    { text: "Volta", value: "TV" },
    { text: "Western", value: "WP" }
  ],
  GI: [{ text: "Gibraltar", value: "01" }],
  GR: [
    { text: "Anatoliki Makedonia kai Thraki", value: "A" },
    { text: "Attiki", value: "I" },
    { text: "Dytiki Ellada", value: "G" },
    { text: "Dytiki Makedonia", value: "C" },
    { text: "Ionia Nisia", value: "F" },
    { text: "Ipeiros", value: "D" },
    { text: "Kentriki Makedonia", value: "B" },
    { text: "Kriti", value: "M" },
    { text: "Notio Aigaio", value: "L" },
    { text: "Peloponnisos", value: "J" },
    { text: "Sterea Ellada", value: "H" },
    { text: "Thessalia", value: "E" },
    { text: "Voreio Aigaio", value: "K" }
  ],
  GL: [
    { text: "Avannaata Kommunia", value: "AV" },
    { text: "Kommune Kujalleq", value: "KU" },
    { text: "Kommune Qeqertalik", value: "QT" },
    { text: "Kommuneqarfik Sermersooq", value: "SM" },
    { text: "Qeqqata Kommunia", value: "QE" }
  ],
  GD: [
    { text: "Saint Andrew", value: "01" },
    { text: "Saint David", value: "02" },
    { text: "Saint George", value: "03" },
    { text: "Saint John", value: "04" },
    { text: "Saint Mark", value: "05" },
    { text: "Saint Patrick", value: "06" },
    { text: "Southern Grenadine Islands", value: "10" }
  ],
  GP: [{ text: "Guadeloupe", value: "01" }],
  GU: [
    { text: "Agat", value: "01" },
    { text: "Barrigada", value: "02" },
    { text: "Chalan Pago-Ordot", value: "03" },
    { text: "Dededo", value: "04" },
    { text: "Hagatna", value: "05" },
    { text: "Mangilao", value: "06" },
    { text: "Mongmong-Toto-Maite", value: "07" },
    { text: "Piti", value: "08" },
    { text: "Santa Rita", value: "09" },
    { text: "Sinajana", value: "10" },
    { text: "Tamuning-Tumon-Harmon", value: "11" },
    { text: "Yigo", value: "12" },
    { text: "Yona", value: "13" }
  ],
  GT: [
    { text: "Alta Verapaz", value: "AV" },
    { text: "Baja Verapaz", value: "BV" },
    { text: "Chimaltenango", value: "CM" },
    { text: "Chiquimula", value: "CQ" },
    { text: "El Progreso", value: "PR" },
    { text: "Escuintla", value: "ES" },
    { text: "Guatemala", value: "GU" },
    { text: "Huehuetenango", value: "HU" },
    { text: "Izabal", value: "IZ" },
    { text: "Jalapa", value: "JA" },
    { text: "Jutiapa", value: "JU" },
    { text: "Peten", value: "PE" },
    { text: "Quetzaltenango", value: "QZ" },
    { text: "Quiche", value: "QC" },
    { text: "Retalhuleu", value: "RE" },
    { text: "Sacatepequez", value: "SA" },
    { text: "San Marcos", value: "SM" },
    { text: "Santa Rosa", value: "SR" },
    { text: "Solola", value: "SO" },
    { text: "Suchitepequez", value: "SU" },
    { text: "Totonicapan", value: "TO" },
    { text: "Zacapa", value: "ZA" }
  ],
  GG: [{ text: "Guernsey", value: "01" }],
  GN: [
    { text: "Boffa", value: "BF" },
    { text: "Boke", value: "B" },
    { text: "Conakry", value: "C" },
    { text: "Coyah", value: "CO" },
    { text: "Dabola", value: "DB" },
    { text: "Dalaba", value: "DL" },
    { text: "Dubreka", value: "DU" },
    { text: "Kankan", value: "K" },
    { text: "Kissidougou", value: "KS" },
    { text: "Kouroussa", value: "KO" },
    { text: "Labe", value: "L" },
    { text: "Macenta", value: "MC" },
    { text: "Nzerekore", value: "N" },
    { text: "Pita", value: "PI" },
    { text: "Siguiri", value: "SI" }
  ],
  GW: [
    { text: "Bissau", value: "BS" },
    { text: "Bolama", value: "BL" },
    { text: "Gabu", value: "GA" }
  ],
  GY: [
    { text: "Demerara-Mahaica", value: "DE" },
    { text: "East Berbice-Corentyne", value: "EB" },
    { text: "Essequibo Islands-West Demerara", value: "ES" },
    { text: "Upper Demerara-Berbice", value: "UD" }
  ],
  HT: [
    { text: "Artibonite", value: "AR" },
    { text: "Centre", value: "CE" },
    { text: "Grande'Anse", value: "GA" },
    { text: "Nord", value: "ND" },
    { text: "Nord-Est", value: "NE" },
    { text: "Nord-Ouest", value: "NO" },
    { text: "Ouest", value: "OU" },
    { text: "Sud", value: "SD" },
    { text: "Sud-Est", value: "SE" }
  ],
  HM: [{ text: "-", value: "01" }],
  VA: [{ text: "Vatican City", value: "01" }],
  HN: [
    { text: "Atlantida", value: "AT" },
    { text: "Choluteca", value: "CH" },
    { text: "Colon", value: "CL" },
    { text: "Comayagua", value: "CM" },
    { text: "Copan", value: "CP" },
    { text: "Cortes", value: "CR" },
    { text: "El Paraiso", value: "EP" },
    { text: "Francisco Morazan", value: "FM" },
    { text: "Intibuca", value: "IN" },
    { text: "Islas de la Bahia", value: "IB" },
    { text: "La Paz", value: "LP" },
    { text: "Lempira", value: "LE" },
    { text: "Ocotepeque", value: "OC" },
    { text: "Olancho", value: "OL" },
    { text: "Santa Barbara", value: "SB" },
    { text: "Valle", value: "VA" },
    { text: "Yoro", value: "YO" }
  ],
  HU: [
    { text: "Bacs-Kiskun", value: "BK" },
    { text: "Baranya", value: "BA" },
    { text: "Bekes", value: "BE" },
    { text: "Borsod-Abauj-Zemplen", value: "BZ" },
    { text: "Budapest", value: "BU" },
    { text: "Csongrad", value: "CS" },
    { text: "Fejer", value: "FE" },
    { text: "Gyor-Moson-Sopron", value: "GS" },
    { text: "Hajdu-Bihar", value: "HB" },
    { text: "Heves", value: "HE" },
    { text: "Jasz-Nagykun-Szolnok", value: "JN" },
    { text: "Komarom-Esztergom", value: "KE" },
    { text: "Nograd", value: "NO" },
    { text: "Pest", value: "PE" },
    { text: "Somogy", value: "SO" },
    { text: "Szabolcs-Szatmar-Bereg", value: "SZ" },
    { text: "Tolna", value: "TO" },
    { text: "Vas", value: "VA" },
    { text: "Veszprem", value: "VE" },
    { text: "Zala", value: "ZA" }
  ],
  IS: [
    { text: "Austurland", value: "7" },
    { text: "Hofudborgarsvaedi", value: "1" },
    { text: "Nordurland eystra", value: "6" },
    { text: "Nordurland vestra", value: "5" },
    { text: "Sudurland", value: "8" },
    { text: "Sudurnes", value: "2" },
    { text: "Vestfirdir", value: "4" },
    { text: "Vesturland", value: "3" }
  ],
  IN: [
    { text: "Andaman and Nicobar Islands", value: "AN" },
    { text: "Andhra Pradesh", value: "AP" },
    { text: "Arunachal Pradesh", value: "AR" },
    { text: "Assam", value: "AS" },
    { text: "Bihar", value: "BR" },
    { text: "Chandigarh", value: "CH" },
    { text: "Chhattisgarh", value: "CT" },
    { text: "Dadra and Nagar Haveli", value: "DN" },
    { text: "Daman and Diu", value: "DD" },
    { text: "Delhi", value: "DL" },
    { text: "Goa", value: "GA" },
    { text: "Gujarat", value: "GJ" },
    { text: "Haryana", value: "HR" },
    { text: "Himachal Pradesh", value: "HP" },
    { text: "Jammu and Kashmir", value: "JK" },
    { text: "Jharkhand", value: "JH" },
    { text: "Karnataka", value: "KA" },
    { text: "Kerala", value: "KL" },
    { text: "Madhya Pradesh", value: "MP" },
    { text: "Maharashtra", value: "MH" },
    { text: "Manipur", value: "MN" },
    { text: "Meghalaya", value: "ML" },
    { text: "Mizoram", value: "MZ" },
    { text: "Nagaland", value: "NL" },
    { text: "Odisha", value: "OR" },
    { text: "Puducherry", value: "PY" },
    { text: "Punjab", value: "PB" },
    { text: "Rajasthan", value: "RJ" },
    { text: "Sikkim", value: "SK" },
    { text: "Tamil Nadu", value: "TN" },
    { text: "Telangana", value: "TG" },
    { text: "Tripura", value: "TR" },
    { text: "Uttar Pradesh", value: "UP" },
    { text: "Uttarakhand", value: "UT" },
    { text: "West Bengal", value: "WB" }
  ],
  ID: [
    { text: "Aceh", value: "AC" },
    { text: "Bali", value: "BA" },
    { text: "Banten", value: "BT" },
    { text: "Bengkulu", value: "BE" },
    { text: "Gorontalo", value: "GO" },
    { text: "Jakarta Raya", value: "JK" },
    { text: "Jambi", value: "JA" },
    { text: "Jawa Barat", value: "JB" },
    { text: "Jawa Tengah", value: "JT" },
    { text: "Jawa Timur", value: "JI" },
    { text: "Kalimantan Barat", value: "KB" },
    { text: "Kalimantan Selatan", value: "KS" },
    { text: "Kalimantan Tengah", value: "KT" },
    { text: "Kalimantan Timur", value: "KI" },
    { text: "Kalimantan Utara", value: "KU" },
    { text: "Kepulauan Bangka Belitung", value: "BB" },
    { text: "Kepulauan Riau", value: "KR" },
    { text: "Lampung", value: "LA" },
    { text: "Maluku", value: "ML" },
    { text: "Maluku Utara", value: "MU" },
    { text: "Nusa Tenggara Barat", value: "NB" },
    { text: "Nusa Tenggara Timur", value: "NT" },
    { text: "Papua", value: "PP" },
    { text: "Papua Barat", value: "PB" },
    { text: "Riau", value: "RI" },
    { text: "Sulawesi Barat", value: "SR" },
    { text: "Sulawesi Selatan", value: "SN" },
    { text: "Sulawesi Tengah", value: "ST" },
    { text: "Sulawesi Tenggara", value: "SG" },
    { text: "Sulawesi Utara", value: "SA" },
    { text: "Sumatera Barat", value: "SB" },
    { text: "Sumatera Selatan", value: "SS" },
    { text: "Sumatera Utara", value: "SU" },
    { text: "Yogyakarta", value: "YO" }
  ],
  IR: [
    { text: "Alborz", value: "32" },
    { text: "Ardabil", value: "03" },
    { text: "Azarbayjan-e Gharbi", value: "02" },
    { text: "Azarbayjan-e Sharqi", value: "01" },
    { text: "Bushehr", value: "06" },
    { text: "Chahar Mahal va Bakhtiari", value: "08" },
    { text: "Esfahan", value: "04" },
    { text: "Fars", value: "14" },
    { text: "Gilan", value: "19" },
    { text: "Golestan", value: "27" },
    { text: "Hamadan", value: "24" },
    { text: "Hormozgan", value: "23" },
    { text: "Ilam", value: "05" },
    { text: "Kerman", value: "15" },
    { text: "Kermanshah", value: "17" },
    { text: "Khorasan-e Jonubi", value: "29" },
    { text: "Khorasan-e Razavi", value: "30" },
    { text: "Khorasan-e Shomali", value: "31" },
    { text: "Khuzestan", value: "10" },
    { text: "Kohgiluyeh va Bowyer Ahmad", value: "18" },
    { text: "Kordestan", value: "16" },
    { text: "Lorestan", value: "20" },
    { text: "Markazi", value: "22" },
    { text: "Mazandaran", value: "21" },
    { text: "Qazvin", value: "28" },
    { text: "Qom", value: "26" },
    { text: "Semnan", value: "12" },
    { text: "Sistan va Baluchestan", value: "13" },
    { text: "Tehran", value: "07" },
    { text: "Yazd", value: "25" },
    { text: "Zanjan", value: "11" }
  ],
  IQ: [
    { text: "Al Anbar", value: "AN" },
    { text: "Al Basrah", value: "BA" },
    { text: "Al Muthanna", value: "MU" },
    { text: "Al Qadisiyah", value: "QA" },
    { text: "An Najaf", value: "NA" },
    { text: "Arbil", value: "AR" },
    { text: "As Sulaymaniyah", value: "SU" },
    { text: "Babil", value: "BB" },
    { text: "Baghdad", value: "BG" },
    { text: "Dahuk", value: "DA" },
    { text: "Dhi Qar", value: "DQ" },
    { text: "Diyala", value: "DI" },
    { text: "Karbala'", value: "KA" },
    { text: "Kirkuk", value: "KI" },
    { text: "Maysan", value: "MA" },
    { text: "Ninawa", value: "NI" },
    { text: "Salah ad Din", value: "SD" },
    { text: "Wasit", value: "WA" }
  ],
  IE: [
    { text: "Carlow", value: "CW" },
    { text: "Cavan", value: "CN" },
    { text: "Clare", value: "CE" },
    { text: "Cork", value: "CO" },
    { text: "Donegal", value: "DL" },
    { text: "Dublin", value: "D" },
    { text: "Galway", value: "G" },
    { text: "Kerry", value: "KY" },
    { text: "Kildare", value: "KE" },
    { text: "Kilkenny", value: "KK" },
    { text: "Laois", value: "LS" },
    { text: "Leitrim", value: "LM" },
    { text: "Limerick", value: "LK" },
    { text: "Longford", value: "LD" },
    { text: "Louth", value: "LH" },
    { text: "Mayo", value: "MO" },
    { text: "Meath", value: "MH" },
    { text: "Monaghan", value: "MN" },
    { text: "Offaly", value: "OY" },
    { text: "Roscommon", value: "RN" },
    { text: "Sligo", value: "SO" },
    { text: "Tipperary", value: "TA" },
    { text: "Waterford", value: "WD" },
    { text: "Westmeath", value: "WH" },
    { text: "Wexford", value: "WX" },
    { text: "Wicklow", value: "WW" }
  ],
  IM: [{ text: "Isle of Man", value: "01" }],
  IL: [
    { text: "HaDarom", value: "D" },
    { text: "HaMerkaz", value: "M" },
    { text: "HaTsafon", value: "Z" },
    { text: "Hefa", value: "HA" },
    { text: "Tel Aviv", value: "TA" },
    { text: "Yerushalayim", value: "JM" }
  ],
  IT: [
    { text: "Abruzzo", value: "65" },
    { text: "Basilicata", value: "77" },
    { text: "Calabria", value: "78" },
    { text: "Campania", value: "72" },
    { text: "Emilia-Romagna", value: "45" },
    { text: "Friuli-Venezia Giulia", value: "36" },
    { text: "Lazio", value: "62" },
    { text: "Liguria", value: "42" },
    { text: "Lombardia", value: "25" },
    { text: "Marche", value: "57" },
    { text: "Molise", value: "67" },
    { text: "Piemonte", value: "21" },
    { text: "Puglia", value: "75" },
    { text: "Sardegna", value: "88" },
    { text: "Sicilia", value: "82" },
    { text: "Toscana", value: "52" },
    { text: "Trentino-Alto Adige", value: "32" },
    { text: "Umbria", value: "55" },
    { text: "Valle d'Aosta", value: "23" },
    { text: "Veneto", value: "34" }
  ],
  JM: [
    { text: "Clarendon", value: "13" },
    { text: "Hanover", value: "09" },
    { text: "Kingston", value: "01" },
    { text: "Manchester", value: "12" },
    { text: "Portland", value: "04" },
    { text: "Saint Andrew", value: "02" },
    { text: "Saint Ann", value: "06" },
    { text: "Saint Catherine", value: "14" },
    { text: "Saint Elizabeth", value: "11" },
    { text: "Saint James", value: "08" },
    { text: "Saint Mary", value: "05" },
    { text: "Saint Thomas", value: "03" },
    { text: "Trelawny", value: "07" },
    { text: "Westmoreland", value: "10" }
  ],
  JP: [
    { text: "Aichi", value: "23" },
    { text: "Akita", value: "05" },
    { text: "Aomori", value: "02" },
    { text: "Chiba", value: "12" },
    { text: "Ehime", value: "38" },
    { text: "Fukui", value: "18" },
    { text: "Fukuoka", value: "40" },
    { text: "Fukushima", value: "07" },
    { text: "Gifu", value: "21" },
    { text: "Gunma", value: "10" },
    { text: "Hiroshima", value: "34" },
    { text: "Hokkaido", value: "01" },
    { text: "Hyogo", value: "28" },
    { text: "Ibaraki", value: "08" },
    { text: "Ishikawa", value: "17" },
    { text: "Iwate", value: "03" },
    { text: "Kagawa", value: "37" },
    { text: "Kagoshima", value: "46" },
    { text: "Kanagawa", value: "14" },
    { text: "Kochi", value: "39" },
    { text: "Kumamoto", value: "43" },
    { text: "Kyoto", value: "26" },
    { text: "Mie", value: "24" },
    { text: "Miyagi", value: "04" },
    { text: "Miyazaki", value: "45" },
    { text: "Nagano", value: "20" },
    { text: "Nagasaki", value: "42" },
    { text: "Nara", value: "29" },
    { text: "Niigata", value: "15" },
    { text: "Oita", value: "44" },
    { text: "Okayama", value: "33" },
    { text: "Okinawa", value: "47" },
    { text: "Osaka", value: "27" },
    { text: "Saga", value: "41" },
    { text: "Saitama", value: "11" },
    { text: "Shiga", value: "25" },
    { text: "Shimane", value: "32" },
    { text: "Shizuoka", value: "22" },
    { text: "Tochigi", value: "09" },
    { text: "Tokushima", value: "36" },
    { text: "Tokyo", value: "13" },
    { text: "Tottori", value: "31" },
    { text: "Toyama", value: "16" },
    { text: "Wakayama", value: "30" },
    { text: "Yamagata", value: "06" },
    { text: "Yamaguchi", value: "35" },
    { text: "Yamanashi", value: "19" }
  ],
  JE: [{ text: "Jersey", value: "01" }],
  JO: [
    { text: "'Ajlun", value: "AJ" },
    { text: "Al 'Aqabah", value: "AQ" },
    { text: "Al 'Asimah", value: "AM" },
    { text: "Al Balqa'", value: "BA" },
    { text: "Al Karak", value: "KA" },
    { text: "Al Mafraq", value: "MA" },
    { text: "Az Zarqa'", value: "AZ" },
    { text: "Irbid", value: "IR" },
    { text: "Jarash", value: "JA" },
    { text: "Ma'an", value: "MN" },
    { text: "Madaba", value: "MD" }
  ],
  KZ: [
    { text: "Almaty", value: "ALA" },
    { text: "Almaty oblysy", value: "ALM" },
    { text: "Aqmola oblysy", value: "AKM" },
    { text: "Aqtobe oblysy", value: "AKT" },
    { text: "Astana", value: "AST" },
    { text: "Atyrau oblysy", value: "ATY" },
    { text: "Batys Qazaqstan oblysy", value: "ZAP" },
    { text: "Bayqongyr", value: "BAY" },
    { text: "Mangghystau oblysy", value: "MAN" },
    { text: "Ongtustik Qazaqstan oblysy", value: "YUZ" },
    { text: "Pavlodar oblysy", value: "PAV" },
    { text: "Qaraghandy oblysy", value: "KAR" },
    { text: "Qostanay oblysy", value: "KUS" },
    { text: "Qyzylorda oblysy", value: "KZY" },
    { text: "Shyghys Qazaqstan oblysy", value: "VOS" },
    { text: "Soltustik Qazaqstan oblysy", value: "SEV" },
    { text: "Zhambyl oblysy", value: "ZHA" }
  ],
  KE: [
    { text: "Baringo", value: "01" },
    { text: "Bomet", value: "02" },
    { text: "Bungoma", value: "03" },
    { text: "Busia", value: "04" },
    { text: "Elgeyo/Marakwet", value: "05" },
    { text: "Embu", value: "06" },
    { text: "Garissa", value: "07" },
    { text: "Homa Bay", value: "08" },
    { text: "Isiolo", value: "09" },
    { text: "Kajiado", value: "10" },
    { text: "Kakamega", value: "11" },
    { text: "Kericho", value: "12" },
    { text: "Kiambu", value: "13" },
    { text: "Kilifi", value: "14" },
    { text: "Kirinyaga", value: "15" },
    { text: "Kisii", value: "16" },
    { text: "Kisumu", value: "17" },
    { text: "Kitui", value: "18" },
    { text: "Kwale", value: "19" },
    { text: "Laikipia", value: "20" },
    { text: "Lamu", value: "21" },
    { text: "Machakos", value: "22" },
    { text: "Makueni", value: "23" },
    { text: "Mandera", value: "24" },
    { text: "Marsabit", value: "25" },
    { text: "Meru", value: "26" },
    { text: "Migori", value: "27" },
    { text: "Mombasa", value: "28" },
    { text: "Murang'a", value: "29" },
    { text: "Nairobi City", value: "30" },
    { text: "Nakuru", value: "31" },
    { text: "Nandi", value: "32" },
    { text: "Narok", value: "33" },
    { text: "Nyamira", value: "34" },
    { text: "Nyandarua", value: "35" },
    { text: "Nyeri", value: "36" },
    { text: "Siaya", value: "38" },
    { text: "Taita/Taveta", value: "39" },
    { text: "Tharaka-Nithi", value: "41" },
    { text: "Trans Nzoia", value: "42" },
    { text: "Turkana", value: "43" },
    { text: "Uasin Gishu", value: "44" },
    { text: "Wajir", value: "46" }
  ],
  KI: [
    { text: "Gilbert Islands", value: "G" },
    { text: "Line Islands", value: "L" }
  ],
  KP: [{ text: "P'yongyang", value: "01" }],
  KR: [
    { text: "Busan-gwangyeoksi", value: "26" },
    { text: "Chungcheongbuk-do", value: "43" },
    { text: "Chungcheongnam-do", value: "44" },
    { text: "Daegu-gwangyeoksi", value: "27" },
    { text: "Daejeon-gwangyeoksi", value: "30" },
    { text: "Gangwon-do", value: "42" },
    { text: "Gwangju-gwangyeoksi", value: "29" },
    { text: "Gyeonggi-do", value: "41" },
    { text: "Gyeongsangbuk-do", value: "47" },
    { text: "Gyeongsangnam-do", value: "48" },
    { text: "Incheon-gwangyeoksi", value: "28" },
    { text: "Jeju-teukbyeoljachido", value: "49" },
    { text: "Jeollabuk-do", value: "45" },
    { text: "Jeollanam-do", value: "46" },
    { text: "Seoul-teukbyeolsi", value: "11" },
    { text: "Ulsan-gwangyeoksi", value: "31" }
  ],
  KW: [
    { text: "Al 'Asimah", value: "KU" },
    { text: "Al Ahmadi", value: "AH" },
    { text: "Al Farwaniyah", value: "FA" },
    { text: "Al Jahra'", value: "JA" },
    { text: "Hawalli", value: "HA" },
    { text: "Mubarak al Kabir", value: "MU" }
  ],
  KG: [
    { text: "Batken", value: "B" },
    { text: "Bishkek", value: "GB" },
    { text: "Chuy", value: "C" },
    { text: "Jalal-Abad", value: "J" },
    { text: "Naryn", value: "N" },
    { text: "Osh", value: "GO" },
    { text: "Talas", value: "T" },
    { text: "Ysyk-Kol", value: "Y" }
  ],
  LA: [
    { text: "Bolikhamxai", value: "BL" },
    { text: "Champasak", value: "CH" },
    { text: "Houaphan", value: "HO" },
    { text: "Khammouan", value: "KH" },
    { text: "Louang Namtha", value: "LM" },
    { text: "Louangphabang", value: "LP" },
    { text: "Oudomxai", value: "OU" },
    { text: "Savannakhet", value: "SV" },
    { text: "Viangchan", value: "VI" },
    { text: "Xaignabouli", value: "XA" },
    { text: "Xekong", value: "XE" }
  ],
  LV: [
    { text: "Adazu novads", value: "011" },
    { text: "Aglonas novads", value: "001" },
    { text: "Aizkraukles novads", value: "002" },
    { text: "Aizputes novads", value: "003" },
    { text: "Alojas novads", value: "005" },
    { text: "Aluksnes novads", value: "007" },
    { text: "Babites novads", value: "012" },
    { text: "Baldones novads", value: "013" },
    { text: "Balvu novads", value: "015" },
    { text: "Bauskas novads", value: "016" },
    { text: "Beverinas novads", value: "017" },
    { text: "Brocenu novads", value: "018" },
    { text: "Carnikavas novads", value: "020" },
    { text: "Cesu novads", value: "022" },
    { text: "Cesvaines novads", value: "021" },
    { text: "Daugavpils novads", value: "025" },
    { text: "Dobeles novads", value: "026" },
    { text: "Dundagas novads", value: "027" },
    { text: "Erglu novads", value: "030" },
    { text: "Gulbenes novads", value: "033" },
    { text: "Iecavas novads", value: "034" },
    { text: "Ikskiles novads", value: "035" },
    { text: "Incukalna novads", value: "037" },
    { text: "Jaunjelgavas novads", value: "038" },
    { text: "Jaunpiebalgas novads", value: "039" },
    { text: "Jaunpils novads", value: "040" },
    { text: "Jekabpils novads", value: "042" },
    { text: "Jelgava", value: "JEL" },
    { text: "Jelgavas novads", value: "041" },
    { text: "Jurmala", value: "JUR" },
    { text: "Kekavas novads", value: "052" },
    { text: "Kokneses novads", value: "046" },
    { text: "Kraslavas novads", value: "047" },
    { text: "Kuldigas novads", value: "050" },
    { text: "Lielvardes novads", value: "053" },
    { text: "Liepaja", value: "LPX" },
    { text: "Limbazu novads", value: "054" },
    { text: "Livanu novads", value: "056" },
    { text: "Lubanas novads", value: "057" },
    { text: "Ludzas novads", value: "058" },
    { text: "Madonas novads", value: "059" },
    { text: "Malpils novads", value: "061" },
    { text: "Nauksenu novads", value: "064" },
    { text: "Ogres novads", value: "067" },
    { text: "Olaines novads", value: "068" },
    { text: "Ozolnieku novads", value: "069" },
    { text: "Preilu novads", value: "073" },
    { text: "Rezeknes novads", value: "077" },
    { text: "Riebinu novads", value: "078" },
    { text: "Riga", value: "RIX" },
    { text: "Rojas novads", value: "079" },
    { text: "Ropazu novads", value: "080" },
    { text: "Rugaju novads", value: "082" },
    { text: "Rundales novads", value: "083" },
    { text: "Salacgrivas novads", value: "086" },
    { text: "Salaspils novads", value: "087" },
    { text: "Saldus novads", value: "088" },
    { text: "Saulkrastu novads", value: "089" },
    { text: "Sejas novads", value: "090" },
    { text: "Siguldas novads", value: "091" },
    { text: "Skrundas novads", value: "093" },
    { text: "Stopinu novads", value: "095" },
    { text: "Talsu novads", value: "097" },
    { text: "Tukuma novads", value: "099" },
    { text: "Valkas novads", value: "101" },
    { text: "Valmiera", value: "VMR" },
    { text: "Vecumnieku novads", value: "105" },
    { text: "Ventspils novads", value: "106" }
  ],
  LB: [
    { text: "Aakkar", value: "AK" },
    { text: "Baalbek-Hermel", value: "BH" },
    { text: "Beqaa", value: "BI" },
    { text: "Beyrouth", value: "BA" },
    { text: "Liban-Nord", value: "AS" },
    { text: "Liban-Sud", value: "JA" },
    { text: "Mont-Liban", value: "JL" },
    { text: "Nabatiye", value: "NA" }
  ],
  LS: [
    { text: "Berea", value: "D" },
    { text: "Leribe", value: "C" },
    { text: "Maseru", value: "A" },
    { text: "Quthing", value: "G" }
  ],
  LR: [
    { text: "Grand Bassa", value: "GB" },
    { text: "Grand Gedeh", value: "GG" },
    { text: "Margibi", value: "MG" },
    { text: "Maryland", value: "MY" },
    { text: "Montserrado", value: "MO" },
    { text: "Nimba", value: "NI" },
    { text: "Sinoe", value: "SI" }
  ],
  LY: [
    { text: "Al Butnan", value: "BU" },
    { text: "Al Jabal al Akhdar", value: "JA" },
    { text: "Al Jabal al Gharbi", value: "JG" },
    { text: "Al Jufrah", value: "JU" },
    { text: "Al Kufrah", value: "KF" },
    { text: "Al Marj", value: "MJ" },
    { text: "Al Marqab", value: "MB" },
    { text: "Al Wahat", value: "WA" },
    { text: "An Nuqat al Khams", value: "NQ" },
    { text: "Az Zawiyah", value: "ZA" },
    { text: "Banghazi", value: "BA" },
    { text: "Darnah", value: "DR" },
    { text: "Misratah", value: "MI" },
    { text: "Murzuq", value: "MQ" },
    { text: "Nalut", value: "NL" },
    { text: "Sabha", value: "SB" },
    { text: "Tarabulus", value: "TB" }
  ],
  LI: [
    { text: "Balzers", value: "01" },
    { text: "Eschen", value: "02" },
    { text: "Gamprin", value: "03" },
    { text: "Mauren", value: "04" },
    { text: "Planken", value: "05" },
    { text: "Ruggell", value: "06" },
    { text: "Schaan", value: "07" },
    { text: "Schellenberg", value: "08" },
    { text: "Triesen", value: "09" },
    { text: "Vaduz", value: "11" }
  ],
  LT: [
    { text: "Alytaus apskritis", value: "AL" },
    { text: "Kauno apskritis", value: "KU" },
    { text: "Klaipedos apskritis", value: "KL" },
    { text: "Marijampoles apskritis", value: "MR" },
    { text: "Panevezio apskritis", value: "PN" },
    { text: "Siauliu apskritis", value: "SA" },
    { text: "Taurages apskritis", value: "TA" },
    { text: "Telsiu apskritis", value: "TE" },
    { text: "Utenos apskritis", value: "UT" },
    { text: "Vilniaus apskritis", value: "VL" }
  ],
  LU: [
    { text: "Diekirch", value: "DI" },
    { text: "Grevenmacher", value: "GR" },
    { text: "Luxembourg", value: "LU" }
  ],
  MK: [
    { text: "Aracinovo", value: "802" },
    { text: "Berovo", value: "201" },
    { text: "Bitola", value: "501" },
    { text: "Bogdanci", value: "401" },
    { text: "Bogovinje", value: "601" },
    { text: "Bosilovo", value: "402" },
    { text: "Brvenica", value: "602" },
    { text: "Centar Zupa", value: "313" },
    { text: "Cesinovo-Oblesevo", value: "210" },
    { text: "Debar", value: "303" },
    { text: "Delcevo", value: "203" },
    { text: "Demir Kapija", value: "103" },
    { text: "Dolneni", value: "503" },
    { text: "Gevgelija", value: "405" },
    { text: "Gostivar", value: "604" },
    { text: "Ilinden", value: "807" },
    { text: "Jegunovce", value: "606" },
    { text: "Karbinci", value: "205" },
    { text: "Kavadarci", value: "104" },
    { text: "Kicevo", value: "307" },
    { text: "Kocani", value: "206" },
    { text: "Kratovo", value: "701" },
    { text: "Kriva Palanka", value: "702" },
    { text: "Krivogastani", value: "504" },
    { text: "Krusevo", value: "505" },
    { text: "Kumanovo", value: "703" },
    { text: "Lipkovo", value: "704" },
    { text: "Lozovo", value: "105" },
    { text: "Makedonska Kamenica", value: "207" },
    { text: "Negotino", value: "106" },
    { text: "Novo Selo", value: "408" },
    { text: "Ohrid", value: "310" },
    { text: "Pehcevo", value: "208" },
    { text: "Petrovec", value: "810" },
    { text: "Plasnica", value: "311" },
    { text: "Prilep", value: "508" },
    { text: "Probistip", value: "209" },
    { text: "Radovis", value: "409" },
    { text: "Rankovce", value: "705" },
    { text: "Resen", value: "509" },
    { text: "Rosoman", value: "107" },
    { text: "Skopje", value: "85" },
    { text: "Sopiste", value: "812" },
    { text: "Staro Nagoricane", value: "706" },
    { text: "Stip", value: "211" },
    { text: "Struga", value: "312" },
    { text: "Strumica", value: "410" },
    { text: "Studenicani", value: "813" },
    { text: "Sveti Nikole", value: "108" },
    { text: "Tearce", value: "608" },
    { text: "Tetovo", value: "609" },
    { text: "Valandovo", value: "403" },
    { text: "Vasilevo", value: "404" },
    { text: "Veles", value: "101" },
    { text: "Vevcani", value: "301" },
    { text: "Vinica", value: "202" },
    { text: "Vrapciste", value: "603" },
    { text: "Zelenikovo", value: "806" },
    { text: "Zelino", value: "605" }
  ],
  MG: [
    { text: "Antananarivo", value: "T" },
    { text: "Antsiranana", value: "D" },
    { text: "Fianarantsoa", value: "F" },
    { text: "Mahajanga", value: "M" },
    { text: "Toamasina", value: "A" },
    { text: "Toliara", value: "U" }
  ],
  MW: [
    { text: "Balaka", value: "BA" },
    { text: "Blantyre", value: "BL" },
    { text: "Dowa", value: "DO" },
    { text: "Lilongwe", value: "LI" },
    { text: "Machinga", value: "MH" },
    { text: "Mangochi", value: "MG" },
    { text: "Mzimba", value: "MZ" },
    { text: "Ntchisi", value: "NI" },
    { text: "Salima", value: "SA" },
    { text: "Zomba", value: "ZO" }
  ],
  MY: [
    { text: "Johor", value: "01" },
    { text: "Kedah", value: "02" },
    { text: "Kelantan", value: "03" },
    { text: "Melaka", value: "04" },
    { text: "Negeri Sembilan", value: "05" },
    { text: "Pahang", value: "06" },
    { text: "Perak", value: "08" },
    { text: "Perlis", value: "09" },
    { text: "Pulau Pinang", value: "07" },
    { text: "Sabah", value: "12" },
    { text: "Sarawak", value: "13" },
    { text: "Selangor", value: "10" },
    { text: "Terengganu", value: "11" },
    { text: "Wilayah Persekutuan Kuala Lumpur", value: "14" },
    { text: "Wilayah Persekutuan Labuan", value: "15" },
    { text: "Wilayah Persekutuan Putrajaya", value: "16" }
  ],
  MV: [
    { text: "Alifu Alifu", value: "02" },
    { text: "Baa", value: "20" },
    { text: "Dhaalu", value: "17" },
    { text: "Gaafu Dhaalu", value: "28" },
    { text: "Haa Dhaalu", value: "23" },
    { text: "Kaafu", value: "26" },
    { text: "Laamu", value: "05" },
    { text: "Maale", value: "MLE" },
    { text: "Meemu", value: "12" },
    { text: "Noonu", value: "25" },
    { text: "Raa", value: "13" },
    { text: "Seenu", value: "01" },
    { text: "Vaavu", value: "04" }
  ],
  ML: [
    { text: "Bamako", value: "BKO" },
    { text: "Gao", value: "7" },
    { text: "Kayes", value: "1" },
    { text: "Kidal", value: "8" },
    { text: "Koulikoro", value: "2" },
    { text: "Mopti", value: "5" },
    { text: "Segou", value: "4" },
    { text: "Sikasso", value: "3" },
    { text: "Tombouctou", value: "6" }
  ],
  MT: [
    { text: "Attard", value: "01" },
    { text: "Balzan", value: "02" },
    { text: "Birgu", value: "03" },
    { text: "Birkirkara", value: "04" },
    { text: "Birzebbuga", value: "05" },
    { text: "Bormla", value: "06" },
    { text: "Dingli", value: "07" },
    { text: "Fgura", value: "08" },
    { text: "Floriana", value: "09" },
    { text: "Fontana", value: "10" },
    { text: "Gharb", value: "14" },
    { text: "Gharghur", value: "15" },
    { text: "Ghasri", value: "16" },
    { text: "Ghaxaq", value: "17" },
    { text: "Gudja", value: "11" },
    { text: "Gzira", value: "12" },
    { text: "Hamrun", value: "18" },
    { text: "Iklin", value: "19" },
    { text: "Isla", value: "20" },
    { text: "Kalkara", value: "21" },
    { text: "Kercem", value: "22" },
    { text: "Kirkop", value: "23" },
    { text: "Lija", value: "24" },
    { text: "Luqa", value: "25" },
    { text: "Marsa", value: "26" },
    { text: "Marsaskala", value: "27" },
    { text: "Marsaxlokk", value: "28" },
    { text: "Mdina", value: "29" },
    { text: "Mellieha", value: "30" },
    { text: "Mgarr", value: "31" },
    { text: "Mosta", value: "32" },
    { text: "Mqabba", value: "33" },
    { text: "Msida", value: "34" },
    { text: "Mtarfa", value: "35" },
    { text: "Munxar", value: "36" },
    { text: "Nadur", value: "37" },
    { text: "Naxxar", value: "38" },
    { text: "Paola", value: "39" },
    { text: "Pembroke", value: "40" },
    { text: "Pieta", value: "41" },
    { text: "Qala", value: "42" },
    { text: "Qormi", value: "43" },
    { text: "Qrendi", value: "44" },
    { text: "Rabat Gozo", value: "45" },
    { text: "Rabat Malta", value: "46" },
    { text: "Safi", value: "47" },
    { text: "Saint John", value: "49" },
    { text: "Saint Julian's", value: "48" },
    { text: "Saint Lucia's", value: "53" },
    { text: "Saint Paul's Bay", value: "51" },
    { text: "Sannat", value: "52" },
    { text: "Santa Venera", value: "54" },
    { text: "Siggiewi", value: "55" },
    { text: "Sliema", value: "56" },
    { text: "Swieqi", value: "57" },
    { text: "Ta' Xbiex", value: "58" },
    { text: "Tarxien", value: "59" },
    { text: "Valletta", value: "60" },
    { text: "Xaghra", value: "61" },
    { text: "Xewkija", value: "62" },
    { text: "Xghajra", value: "63" },
    { text: "Zabbar", value: "64" },
    { text: "Zebbug Gozo", value: "65" },
    { text: "Zejtun", value: "67" },
    { text: "Zurrieq", value: "68" }
  ],
  MH: [
    { text: "Kwajalein", value: "KWA" },
    { text: "Majuro", value: "MAJ" }
  ],
  MQ: [{ text: "Martinique", value: "01" }],
  MR: [
    { text: "Dakhlet Nouadhibou", value: "08" },
    { text: "Nouakchott Nord", value: "14" },
    { text: "Tiris Zemmour", value: "11" }
  ],
  MU: [
    { text: "Black River", value: "BL" },
    { text: "Flacq", value: "FL" },
    { text: "Grand Port", value: "GP" },
    { text: "Moka", value: "MO" },
    { text: "Pamplemousses", value: "PA" },
    { text: "Plaines Wilhems", value: "PW" },
    { text: "Port Louis", value: "PL" },
    { text: "Riviere du Rempart", value: "RR" },
    { text: "Rodrigues Islands", value: "RO" },
    { text: "Savanne", value: "SA" }
  ],
  YT: [
    { text: "Mamoudzou", value: "01" },
    { text: "Mtsamboro", value: "02" },
    { text: "Ouangani", value: "03" },
    { text: "Pamandzi", value: "04" }
  ],
  MX: [
    { text: "Aguascalientes", value: "AGU" },
    { text: "Baja California", value: "BCN" },
    { text: "Baja California Sur", value: "BCS" },
    { text: "Campeche", value: "CAM" },
    { text: "Chiapas", value: "CHP" },
    { text: "Chihuahua", value: "CHH" },
    { text: "Ciudad de Mexico", value: "CMX" },
    { text: "Coahuila de Zaragoza", value: "COA" },
    { text: "Colima", value: "COL" },
    { text: "Durango", value: "DUR" },
    { text: "Guanajuato", value: "GUA" },
    { text: "Guerrero", value: "GRO" },
    { text: "Hidalgo", value: "HID" },
    { text: "Jalisco", value: "JAL" },
    { text: "Mexico", value: "MEX" },
    { text: "Michoacan de Ocampo", value: "MIC" },
    { text: "Morelos", value: "MOR" },
    { text: "Nayarit", value: "NAY" },
    { text: "Nuevo Leon", value: "NLE" },
    { text: "Oaxaca", value: "OAX" },
    { text: "Puebla", value: "PUE" },
    { text: "Queretaro", value: "QUE" },
    { text: "Quintana Roo", value: "ROO" },
    { text: "San Luis Potosi", value: "SLP" },
    { text: "Sinaloa", value: "SIN" },
    { text: "Sonora", value: "SON" },
    { text: "Tabasco", value: "TAB" },
    { text: "Tamaulipas", value: "TAM" },
    { text: "Tlaxcala", value: "TLA" },
    { text: "Veracruz de Ignacio de la Llave", value: "VER" },
    { text: "Yucatan", value: "YUC" },
    { text: "Zacatecas", value: "ZAC" }
  ],
  FM: [
    { text: "Chuuk", value: "TRK" },
    { text: "Kosrae", value: "KSA" },
    { text: "Pohnpei", value: "PNI" },
    { text: "Yap", value: "YAP" }
  ],
  MD: [
    { text: "Anenii Noi", value: "AN" },
    { text: "Balti", value: "BA" },
    { text: "Basarabeasca", value: "BS" },
    { text: "Bender", value: "BD" },
    { text: "Briceni", value: "BR" },
    { text: "Cahul", value: "CA" },
    { text: "Calarasi", value: "CL" },
    { text: "Cantemir", value: "CT" },
    { text: "Causeni", value: "CS" },
    { text: "Chisinau", value: "CU" },
    { text: "Cimislia", value: "CM" },
    { text: "Criuleni", value: "CR" },
    { text: "Donduseni", value: "DO" },
    { text: "Drochia", value: "DR" },
    { text: "Dubasari", value: "DU" },
    { text: "Edinet", value: "ED" },
    { text: "Falesti", value: "FA" },
    { text: "Floresti", value: "FL" },
    { text: "Gagauzia, Unitatea teritoriala autonoma", value: "GA" },
    { text: "Glodeni", value: "GL" },
    { text: "Hincesti", value: "HI" },
    { text: "Ialoveni", value: "IA" },
    { text: "Leova", value: "LE" },
    { text: "Nisporeni", value: "NI" },
    { text: "Ocnita", value: "OC" },
    { text: "Orhei", value: "OR" },
    { text: "Rezina", value: "RE" },
    { text: "Riscani", value: "RI" },
    { text: "Singerei", value: "SI" },
    { text: "Soldanesti", value: "SD" },
    { text: "Soroca", value: "SO" },
    { text: "Stefan Voda", value: "SV" },
    { text: "Stinga Nistrului, unitatea teritoriala din", value: "SN" },
    { text: "Straseni", value: "ST" },
    { text: "Taraclia", value: "TA" },
    { text: "Telenesti", value: "TE" },
    { text: "Ungheni", value: "UN" }
  ],
  MC: [
    { text: "Fontvieille", value: "FO" },
    { text: "La Condamine", value: "CO" },
    { text: "Monaco-Ville", value: "MO" },
    { text: "Monte-Carlo", value: "MC" },
    { text: "Saint-Roman", value: "SR" }
  ],
  MN: [
    { text: "Bayan-Olgiy", value: "071" },
    { text: "Dornogovi", value: "063" },
    { text: "Govi-Altay", value: "065" },
    { text: "Omnogovi", value: "053" },
    { text: "Orhon", value: "035" },
    { text: "Ovorhangay", value: "055" },
    { text: "Selenge", value: "049" },
    { text: "Tov", value: "047" },
    { text: "Ulaanbaatar", value: "1" }
  ],
  ME: [
    { text: "Bar", value: "02" },
    { text: "Berane", value: "03" },
    { text: "Bijelo Polje", value: "04" },
    { text: "Budva", value: "05" },
    { text: "Cetinje", value: "06" },
    { text: "Danilovgrad", value: "07" },
    { text: "Herceg-Novi", value: "08" },
    { text: "Kolasin", value: "09" },
    { text: "Kotor", value: "10" },
    { text: "Niksic", value: "12" },
    { text: "Plav", value: "13" },
    { text: "Pljevlja", value: "14" },
    { text: "Pluzine", value: "15" },
    { text: "Podgorica", value: "16" },
    { text: "Rozaje", value: "17" },
    { text: "Tivat", value: "19" },
    { text: "Ulcinj", value: "20" },
    { text: "Zabljak", value: "21" }
  ],
  MS: [
    { text: "Saint Anthony", value: "01" },
    { text: "Saint Peter", value: "02" }
  ],
  MA: [
    { text: "Beni-Mellal-Khenifra", value: "05" },
    { text: "Casablanca-Settat", value: "06" },
    { text: "Draa-Tafilalet", value: "08" },
    { text: "Fes- Meknes", value: "03" },
    { text: "Guelmim-Oued Noun (EH-partial)", value: "10" },
    { text: "L'Oriental", value: "02" },
    { text: "Laayoune-Sakia El Hamra (EH-partial)", value: "11" },
    { text: "Marrakech-Safi", value: "07" },
    { text: "Rabat-Sale-Kenitra", value: "04" },
    { text: "Souss-Massa", value: "09" },
    { text: "Tanger-Tetouan-Al Hoceima", value: "01" }
  ],
  MZ: [
    { text: "Cabo Delgado", value: "P" },
    { text: "Gaza", value: "G" },
    { text: "Inhambane", value: "I" },
    { text: "Manica", value: "B" },
    { text: "Maputo", value: "L" },
    { text: "Nampula", value: "N" },
    { text: "Niassa", value: "A" },
    { text: "Sofala", value: "S" },
    { text: "Tete", value: "T" },
    { text: "Zambezia", value: "Q" }
  ],
  MM: [
    { text: "Ayeyarwady", value: "07" },
    { text: "Bago", value: "02" },
    { text: "Chin", value: "14" },
    { text: "Kachin", value: "11" },
    { text: "Magway", value: "03" },
    { text: "Mandalay", value: "04" },
    { text: "Mon", value: "15" },
    { text: "Nay Pyi Taw", value: "18" },
    { text: "Sagaing", value: "01" },
    { text: "Shan", value: "17" },
    { text: "Tanintharyi", value: "05" },
    { text: "Yangon", value: "06" }
  ],
  NA: [
    { text: "Erongo", value: "ER" },
    { text: "Hardap", value: "HA" },
    { text: "Karas", value: "KA" },
    { text: "Kavango East", value: "KE" },
    { text: "Khomas", value: "KH" },
    { text: "Kunene", value: "KU" },
    { text: "Ohangwena", value: "OW" },
    { text: "Omaheke", value: "OH" },
    { text: "Omusati", value: "OS" },
    { text: "Oshana", value: "ON" },
    { text: "Oshikoto", value: "OT" },
    { text: "Otjozondjupa", value: "OD" },
    { text: "Zambezi", value: "CA" }
  ],
  NR: [{ text: "Yaren", value: "14" }],
  NP: [
    { text: "Bagmati", value: "BA" },
    { text: "Bheri", value: "BH" },
    { text: "Dhawalagiri", value: "DH" },
    { text: "Gandaki", value: "GA" },
    { text: "Janakpur", value: "JA" },
    { text: "Kosi", value: "KO" },
    { text: "Lumbini", value: "LU" },
    { text: "Mahakali", value: "MA" },
    { text: "Mechi", value: "ME" },
    { text: "Narayani", value: "NA" },
    { text: "Rapti", value: "RA" },
    { text: "Sagarmatha", value: "SA" },
    { text: "Seti", value: "SE" }
  ],
  NL: [
    { text: "Drenthe", value: "DR" },
    { text: "Flevoland", value: "FL" },
    { text: "Fryslan", value: "FR" },
    { text: "Gelderland", value: "GE" },
    { text: "Groningen", value: "GR" },
    { text: "Limburg", value: "LI" },
    { text: "Noord-Brabant", value: "NB" },
    { text: "Noord-Holland", value: "NH" },
    { text: "Overijssel", value: "OV" },
    { text: "Utrecht", value: "UT" },
    { text: "Zeeland", value: "ZE" },
    { text: "Zuid-Holland", value: "ZH" }
  ],
  AN: [{ text: "-", value: "01" }],
  NC: [
    { text: "Province Nord", value: "01" },
    { text: "Province Sud", value: "02" }
  ],
  NZ: [
    { text: "Auckland", value: "AUK" },
    { text: "Bay of Plenty", value: "BOP" },
    { text: "Canterbury", value: "CAN" },
    { text: "Chatham Islands Territory", value: "CIT" },
    { text: "Gisborne", value: "GIS" },
    { text: "Hawke's Bay", value: "HKB" },
    { text: "Manawatu-Wanganui", value: "MWT" },
    { text: "Marlborough", value: "MBH" },
    { text: "Nelson", value: "NSN" },
    { text: "Northland", value: "NTL" },
    { text: "Otago", value: "OTA" },
    { text: "Southland", value: "STL" },
    { text: "Taranaki", value: "TKI" },
    { text: "Tasman", value: "TAS" },
    { text: "Waikato", value: "WKO" },
    { text: "Wellington", value: "WGN" },
    { text: "West Coast", value: "WTC" }
  ],
  NI: [
    { text: "Boaco", value: "BO" },
    { text: "Carazo", value: "CA" },
    { text: "Chinandega", value: "CI" },
    { text: "Chontales", value: "CO" },
    { text: "Costa Caribe Norte", value: "AN" },
    { text: "Costa Caribe Sur", value: "AS" },
    { text: "Esteli", value: "ES" },
    { text: "Granada", value: "GR" },
    { text: "Jinotega", value: "JI" },
    { text: "Leon", value: "LE" },
    { text: "Madriz", value: "MD" },
    { text: "Managua", value: "MN" },
    { text: "Masaya", value: "MS" },
    { text: "Matagalpa", value: "MT" },
    { text: "Nueva Segovia", value: "NS" },
    { text: "Rio San Juan", value: "SJ" },
    { text: "Rivas", value: "RI" }
  ],
  NE: [
    { text: "Agadez", value: "1" },
    { text: "Diffa", value: "2" },
    { text: "Dosso", value: "3" },
    { text: "Maradi", value: "4" },
    { text: "Niamey", value: "8" },
    { text: "Tahoua", value: "5" },
    { text: "Tillaberi", value: "6" },
    { text: "Zinder", value: "7" }
  ],
  NG: [
    { text: "Abia", value: "AB" },
    { text: "Abuja Federal Capital Territory", value: "FC" },
    { text: "Adamawa", value: "AD" },
    { text: "Akwa Ibom", value: "AK" },
    { text: "Anambra", value: "AN" },
    { text: "Bauchi", value: "BA" },
    { text: "Bayelsa", value: "BY" },
    { text: "Benue", value: "BE" },
    { text: "Borno", value: "BO" },
    { text: "Cross River", value: "CR" },
    { text: "Delta", value: "DE" },
    { text: "Ebonyi", value: "EB" },
    { text: "Edo", value: "ED" },
    { text: "Ekiti", value: "EK" },
    { text: "Enugu", value: "EN" },
    { text: "Gombe", value: "GO" },
    { text: "Imo", value: "IM" },
    { text: "Jigawa", value: "JI" },
    { text: "Kaduna", value: "KD" },
    { text: "Kano", value: "KN" },
    { text: "Katsina", value: "KT" },
    { text: "Kebbi", value: "KE" },
    { text: "Kogi", value: "KO" },
    { text: "Kwara", value: "KW" },
    { text: "Lagos", value: "LA" },
    { text: "Nasarawa", value: "NA" },
    { text: "Niger", value: "NI" },
    { text: "Ogun", value: "OG" },
    { text: "Ondo", value: "ON" },
    { text: "Osun", value: "OS" },
    { text: "Oyo", value: "OY" },
    { text: "Plateau", value: "PL" },
    { text: "Rivers", value: "RI" },
    { text: "Sokoto", value: "SO" },
    { text: "Taraba", value: "TA" },
    { text: "Yobe", value: "YO" },
    { text: "Zamfara", value: "ZA" }
  ],
  NU: [{ text: "Niue", value: "01" }],
  NF: [{ text: "Norfolk Island", value: "01" }],
  MP: [{ text: "Northern Mariana Islands", value: "01" }],
  NO: [
    { text: "Akershus", value: "02" },
    { text: "Aust-Agder", value: "09" },
    { text: "Buskerud", value: "06" },
    { text: "Finnmark", value: "20" },
    { text: "Hedmark", value: "04" },
    { text: "Hordaland", value: "12" },
    { text: "More og Romsdal", value: "15" },
    { text: "Nord-Trondelag", value: "17" },
    { text: "Nordland", value: "18" },
    { text: "Oppland", value: "05" },
    { text: "Oslo", value: "03" },
    { text: "Ostfold", value: "01" },
    { text: "Rogaland", value: "11" },
    { text: "Sogn og Fjordane", value: "14" },
    { text: "Sor-Trondelag", value: "16" },
    { text: "Telemark", value: "08" },
    { text: "Troms", value: "19" },
    { text: "Vest-Agder", value: "10" },
    { text: "Vestfold", value: "07" }
  ],
  OM: [
    { text: "Ad Dakhiliyah", value: "DA" },
    { text: "Al Buraymi", value: "BU" },
    { text: "Al Wusta", value: "WU" },
    { text: "Az Zahirah", value: "ZA" },
    { text: "Janub al Batinah", value: "BJ" },
    { text: "Janub ash Sharqiyah", value: "SJ" },
    { text: "Masqat", value: "MA" },
    { text: "Musandam", value: "MU" },
    { text: "Shamal al Batinah", value: "BS" },
    { text: "Shamal ash Sharqiyah", value: "SS" },
    { text: "Zufar", value: "ZU" }
  ],
  PK: [
    { text: "Azad Jammu and Kashmir", value: "JK" },
    { text: "Balochistan", value: "BA" },
    { text: "Federally Administered Tribal Areas", value: "TA" },
    { text: "Gilgit-Baltistan", value: "GB" },
    { text: "Islamabad", value: "IS" },
    { text: "Khyber Pakhtunkhwa", value: "KP" },
    { text: "Punjab", value: "PB" },
    { text: "Sindh", value: "SD" }
  ],
  PW: [
    { text: "Airai", value: "004" },
    { text: "Kayangel", value: "100" },
    { text: "Koror", value: "150" },
    { text: "Melekeok", value: "212" },
    { text: "Ngaraard", value: "214" },
    { text: "Ngardmau", value: "222" }
  ],
  PS: [
    { text: "Bethlehem", value: "BTH" },
    { text: "Gaza", value: "GZA" },
    { text: "Hebron", value: "HBN" },
    { text: "Jenin", value: "JEN" },
    { text: "Jericho and Al Aghwar", value: "JRH" },
    { text: "Jerusalem", value: "JEM" },
    { text: "Nablus", value: "NBS" },
    { text: "Qalqilya", value: "QQA" },
    { text: "Ramallah", value: "RBH" },
    { text: "Salfit", value: "SLT" },
    { text: "Tubas", value: "TBS" },
    { text: "Tulkarm", value: "TKM" }
  ],
  PA: [
    { text: "Bocas del Toro", value: "1" },
    { text: "Chiriqui", value: "4" },
    { text: "Cocle", value: "2" },
    { text: "Colon", value: "3" },
    { text: "Herrera", value: "6" },
    { text: "Los Santos", value: "7" },
    { text: "Ngobe-Bugle", value: "NB" },
    { text: "Panama", value: "8" },
    { text: "Veraguas", value: "9" }
  ],
  PG: [
    { text: "Bougainville", value: "NSB" },
    { text: "Central", value: "CPM" },
    { text: "Chimbu", value: "CPK" },
    { text: "East New Britain", value: "EBR" },
    { text: "Eastern Highlands", value: "EHG" },
    { text: "Madang", value: "MPM" },
    { text: "Manus", value: "MRL" },
    { text: "Milne Bay", value: "MBA" },
    { text: "Morobe", value: "MPL" },
    { text: "National Capital District (Port Moresby)", value: "NCD" },
    { text: "New Ireland", value: "NIK" },
    { text: "Southern Highlands", value: "SHM" },
    { text: "West New Britain", value: "WBK" },
    { text: "Western", value: "WPD" },
    { text: "Western Highlands", value: "WHM" }
  ],
  PY: [
    { text: "Alto Paraguay", value: "16" },
    { text: "Alto Parana", value: "10" },
    { text: "Amambay", value: "13" },
    { text: "Asuncion", value: "ASU" },
    { text: "Boqueron", value: "19" },
    { text: "Caaguazu", value: "5" },
    { text: "Caazapa", value: "6" },
    { text: "Canindeyu", value: "14" },
    { text: "Central", value: "11" },
    { text: "Concepcion", value: "1" },
    { text: "Cordillera", value: "3" },
    { text: "Guaira", value: "4" },
    { text: "Itapua", value: "7" },
    { text: "Misiones", value: "8" },
    { text: "Neembucu", value: "12" },
    { text: "Paraguari", value: "9" },
    { text: "Presidente Hayes", value: "15" },
    { text: "San Pedro", value: "2" }
  ],
  PE: [
    { text: "Amazonas", value: "AMA" },
    { text: "Ancash", value: "ANC" },
    { text: "Apurimac", value: "APU" },
    { text: "Arequipa", value: "ARE" },
    { text: "Ayacucho", value: "AYA" },
    { text: "Cajamarca", value: "CAJ" },
    { text: "Cusco", value: "CUS" },
    { text: "El Callao", value: "CAL" },
    { text: "Huancavelica", value: "HUV" },
    { text: "Huanuco", value: "HUC" },
    { text: "Ica", value: "ICA" },
    { text: "Junin", value: "JUN" },
    { text: "La Libertad", value: "LAL" },
    { text: "Lambayeque", value: "LAM" },
    { text: "Lima", value: "LIM" },
    { text: "Loreto", value: "LOR" },
    { text: "Madre de Dios", value: "MDD" },
    { text: "Moquegua", value: "MOQ" },
    { text: "Pasco", value: "PAS" },
    { text: "Piura", value: "PIU" },
    { text: "Puno", value: "PUN" },
    { text: "San Martin", value: "SAM" },
    { text: "Tacna", value: "TAC" },
    { text: "Tumbes", value: "TUM" },
    { text: "Ucayali", value: "UCA" }
  ],
  PH: [
    { text: "Abra", value: "ABR" },
    { text: "Agusan del Norte", value: "AGN" },
    { text: "Agusan del Sur", value: "AGS" },
    { text: "Aklan", value: "AKL" },
    { text: "Albay", value: "ALB" },
    { text: "Antique", value: "ANT" },
    { text: "Apayao", value: "APA" },
    { text: "Aurora", value: "AUR" },
    { text: "Basilan", value: "BAS" },
    { text: "Bataan", value: "BAN" },
    { text: "Batanes", value: "BTN" },
    { text: "Batangas", value: "BTG" },
    { text: "Benguet", value: "BEN" },
    { text: "Biliran", value: "BIL" },
    { text: "Bohol", value: "BOH" },
    { text: "Bukidnon", value: "BUK" },
    { text: "Bulacan", value: "BUL" },
    { text: "Cagayan", value: "CAG" },
    { text: "Camarines Norte", value: "CAN" },
    { text: "Camarines Sur", value: "CAS" },
    { text: "Camiguin", value: "CAM" },
    { text: "Capiz", value: "CAP" },
    { text: "Catanduanes", value: "CAT" },
    { text: "Cavite", value: "CAV" },
    { text: "Cebu", value: "CEB" },
    { text: "Compostela Valley", value: "COM" },
    { text: "Cotabato", value: "NCO" },
    { text: "Davao Oriental", value: "DAO" },
    { text: "Davao del Norte", value: "DAV" },
    { text: "Davao del Sur", value: "DAS" },
    { text: "Dinagat Islands", value: "DIN" },
    { text: "Eastern Samar", value: "EAS" },
    { text: "Guimaras", value: "GUI" },
    { text: "Ifugao", value: "IFU" },
    { text: "Ilocos Norte", value: "ILN" },
    { text: "Ilocos Sur", value: "ILS" },
    { text: "Iloilo", value: "ILI" },
    { text: "Isabela", value: "ISA" },
    { text: "Kalinga", value: "KAL" },
    { text: "La Union", value: "LUN" },
    { text: "Laguna", value: "LAG" },
    { text: "Lanao del Norte", value: "LAN" },
    { text: "Lanao del Sur", value: "LAS" },
    { text: "Leyte", value: "LEY" },
    { text: "Maguindanao", value: "MAG" },
    { text: "Marinduque", value: "MAD" },
    { text: "Masbate", value: "MAS" },
    { text: "Mindoro Occidental", value: "MDC" },
    { text: "Mindoro Oriental", value: "MDR" },
    { text: "Misamis Occidental", value: "MSC" },
    { text: "Misamis Oriental", value: "MSR" },
    { text: "Mountain Province", value: "MOU" },
    { text: "National Capital Region", value: "00" },
    { text: "Negros Occidental", value: "NEC" },
    { text: "Negros Oriental", value: "NER" },
    { text: "Northern Samar", value: "NSA" },
    { text: "Nueva Ecija", value: "NUE" },
    { text: "Nueva Vizcaya", value: "NUV" },
    { text: "Palawan", value: "PLW" },
    { text: "Pampanga", value: "PAM" },
    { text: "Pangasinan", value: "PAN" },
    { text: "Quezon", value: "QUE" },
    { text: "Quirino", value: "QUI" },
    { text: "Rizal", value: "RIZ" },
    { text: "Romblon", value: "ROM" },
    { text: "Samar", value: "WSA" },
    { text: "Sarangani", value: "SAR" },
    { text: "Siquijor", value: "SIG" },
    { text: "Sorsogon", value: "SOR" },
    { text: "South Cotabato", value: "SCO" },
    { text: "Southern Leyte", value: "SLE" },
    { text: "Sultan Kudarat", value: "SUK" },
    { text: "Sulu", value: "SLU" },
    { text: "Surigao del Norte", value: "SUN" },
    { text: "Surigao del Sur", value: "SUR" },
    { text: "Tarlac", value: "TAR" },
    { text: "Tawi-Tawi", value: "TAW" },
    { text: "Zambales", value: "ZMB" },
    { text: "Zamboanga Sibugay", value: "ZSI" },
    { text: "Zamboanga del Norte", value: "ZAN" },
    { text: "Zamboanga del Sur", value: "ZAS" }
  ],
  PN: [{ text: "Pitcairn", value: "01" }],
  PL: [
    { text: "Dolnoslaskie", value: "02" },
    { text: "Kujawsko-pomorskie", value: "04" },
    { text: "Lodzkie", value: "10" },
    { text: "Lubelskie", value: "06" },
    { text: "Lubuskie", value: "08" },
    { text: "Malopolskie", value: "12" },
    { text: "Mazowieckie", value: "14" },
    { text: "Opolskie", value: "16" },
    { text: "Podkarpackie", value: "18" },
    { text: "Podlaskie", value: "20" },
    { text: "Pomorskie", value: "22" },
    { text: "Slaskie", value: "24" },
    { text: "Swietokrzyskie", value: "26" },
    { text: "Warminsko-mazurskie", value: "28" },
    { text: "Wielkopolskie", value: "30" },
    { text: "Zachodniopomorskie", value: "32" }
  ],
  PT: [
    { text: "Aveiro", value: "01" },
    { text: "Beja", value: "02" },
    { text: "Braga", value: "03" },
    { text: "Braganca", value: "04" },
    { text: "Castelo Branco", value: "05" },
    { text: "Coimbra", value: "06" },
    { text: "Evora", value: "07" },
    { text: "Faro", value: "08" },
    { text: "Guarda", value: "09" },
    { text: "Leiria", value: "10" },
    { text: "Lisboa", value: "11" },
    { text: "Portalegre", value: "12" },
    { text: "Porto", value: "13" },
    { text: "Regiao Autonoma da Madeira", value: "30" },
    { text: "Regiao Autonoma dos Acores", value: "20" },
    { text: "Santarem", value: "14" },
    { text: "Setubal", value: "15" },
    { text: "Viana do Castelo", value: "16" },
    { text: "Vila Real", value: "17" },
    { text: "Viseu", value: "18" }
  ],
  PR: [
    { text: "Adjuntas", value: "01" },
    { text: "Aguada", value: "02" },
    { text: "Aguadilla", value: "03" },
    { text: "Aguas Buenas", value: "04" },
    { text: "Aibonito", value: "05" },
    { text: "Anasco", value: "06" },
    { text: "Arecibo", value: "07" },
    { text: "Arroyo", value: "08" },
    { text: "Barceloneta", value: "09" },
    { text: "Barranquitas", value: "10" },
    { text: "Bayamon", value: "11" },
    { text: "Cabo Rojo", value: "12" },
    { text: "Caguas", value: "13" },
    { text: "Camuy", value: "14" },
    { text: "Canovanas", value: "15" },
    { text: "Carolina", value: "16" },
    { text: "Catano", value: "17" },
    { text: "Cayey", value: "18" },
    { text: "Ceiba", value: "19" },
    { text: "Ciales", value: "20" },
    { text: "Cidra", value: "21" },
    { text: "Coamo", value: "22" },
    { text: "Comerio", value: "23" },
    { text: "Corozal", value: "24" },
    { text: "Culebra", value: "25" },
    { text: "Dorado", value: "26" },
    { text: "Fajardo", value: "27" },
    { text: "Florida", value: "28" },
    { text: "Guanica", value: "29" },
    { text: "Guayama", value: "30" },
    { text: "Guayanilla", value: "31" },
    { text: "Guaynabo", value: "32" },
    { text: "Gurabo", value: "33" },
    { text: "Hatillo", value: "34" },
    { text: "Hormigueros", value: "35" },
    { text: "Humacao", value: "36" },
    { text: "Isabela", value: "37" },
    { text: "Juana Diaz", value: "38" },
    { text: "Lajas", value: "39" },
    { text: "Lares", value: "40" },
    { text: "Las Marias", value: "41" },
    { text: "Las Piedras", value: "42" },
    { text: "Loiza", value: "43" },
    { text: "Luquillo", value: "44" },
    { text: "Manati", value: "45" },
    { text: "Maricao", value: "46" },
    { text: "Mayaguez", value: "47" },
    { text: "Moca", value: "48" },
    { text: "Morovis", value: "49" },
    { text: "Municipio de Jayuya", value: "50" },
    { text: "Municipio de Juncos", value: "51" },
    { text: "Naguabo", value: "52" },
    { text: "Naranjito", value: "53" },
    { text: "Patillas", value: "54" },
    { text: "Penuelas", value: "55" },
    { text: "Ponce", value: "56" },
    { text: "Quebradillas", value: "57" },
    { text: "Rincon", value: "58" },
    { text: "Rio Grande", value: "59" },
    { text: "Sabana Grande", value: "60" },
    { text: "Salinas", value: "61" },
    { text: "San German", value: "62" },
    { text: "San Juan", value: "63" },
    { text: "San Lorenzo", value: "64" },
    { text: "San Sebastian", value: "65" },
    { text: "Santa Isabel Municipio", value: "66" },
    { text: "Toa Alta", value: "67" },
    { text: "Toa Baja", value: "68" },
    { text: "Trujillo Alto", value: "69" },
    { text: "Utuado", value: "70" },
    { text: "Vega Alta", value: "71" },
    { text: "Vega Baja", value: "72" },
    { text: "Vieques", value: "73" },
    { text: "Villalba", value: "74" },
    { text: "Yabucoa", value: "75" },
    { text: "Yauco", value: "76" }
  ],
  QA: [
    { text: "Ad Dawhah", value: "DA" },
    { text: "Al Khawr wa adh Dhakhirah", value: "KH" },
    { text: "Al Wakrah", value: "WA" },
    { text: "Ar Rayyan", value: "RA" },
    { text: "Ash Shamal", value: "MS" },
    { text: "Az Za'ayin", value: "ZA" },
    { text: "Umm Salal", value: "US" }
  ],
  RE: [{ text: "Reunion", value: "01" }],
  RO: [
    { text: "Alba", value: "AB" },
    { text: "Arad", value: "AR" },
    { text: "Arges", value: "AG" },
    { text: "Bacau", value: "BC" },
    { text: "Bihor", value: "BH" },
    { text: "Bistrita-Nasaud", value: "BN" },
    { text: "Botosani", value: "BT" },
    { text: "Braila", value: "BR" },
    { text: "Brasov", value: "BV" },
    { text: "Bucuresti", value: "B" },
    { text: "Buzau", value: "BZ" },
    { text: "Calarasi", value: "CL" },
    { text: "Caras-Severin", value: "CS" },
    { text: "Cluj", value: "CJ" },
    { text: "Constanta", value: "CT" },
    { text: "Covasna", value: "CV" },
    { text: "Dambovita", value: "DB" },
    { text: "Dolj", value: "DJ" },
    { text: "Galati", value: "GL" },
    { text: "Giurgiu", value: "GR" },
    { text: "Gorj", value: "GJ" },
    { text: "Harghita", value: "HR" },
    { text: "Hunedoara", value: "HD" },
    { text: "Ialomita", value: "IL" },
    { text: "Iasi", value: "IS" },
    { text: "Ilfov", value: "IF" },
    { text: "Maramures", value: "MM" },
    { text: "Mehedinti", value: "MH" },
    { text: "Mures", value: "MS" },
    { text: "Neamt", value: "NT" },
    { text: "Olt", value: "OT" },
    { text: "Prahova", value: "PH" },
    { text: "Salaj", value: "SJ" },
    { text: "Satu Mare", value: "SM" },
    { text: "Sibiu", value: "SB" },
    { text: "Suceava", value: "SV" },
    { text: "Teleorman", value: "TR" },
    { text: "Timis", value: "TM" },
    { text: "Tulcea", value: "TL" },
    { text: "Valcea", value: "VL" },
    { text: "Vaslui", value: "VS" },
    { text: "Vrancea", value: "VN" }
  ],
  RU: [
    { text: "Adygeya, Respublika", value: "AD" },
    { text: "Altay, Respublika", value: "AL" },
    { text: "Altayskiy kray", value: "ALT" },
    { text: "Amurskaya oblast'", value: "AMU" },
    { text: "Arkhangel'skaya oblast'", value: "ARK" },
    { text: "Astrakhanskaya oblast'", value: "AST" },
    { text: "Bashkortostan, Respublika", value: "BA" },
    { text: "Belgorodskaya oblast'", value: "BEL" },
    { text: "Bryanskaya oblast'", value: "BRY" },
    { text: "Buryatiya, Respublika", value: "BU" },
    { text: "Chechenskaya Respublika", value: "CE" },
    { text: "Chelyabinskaya oblast'", value: "CHE" },
    { text: "Chukotskiy avtonomnyy okrug", value: "CHU" },
    { text: "Chuvashskaya Respublika", value: "CU" },
    { text: "Dagestan, Respublika", value: "DA" },
    { text: "Ingushetiya, Respublika", value: "IN" },
    { text: "Irkutskaya oblast'", value: "IRK" },
    { text: "Ivanovskaya oblast'", value: "IVA" },
    { text: "Kabardino-Balkarskaya Respublika", value: "KB" },
    { text: "Kaliningradskaya oblast'", value: "KGD" },
    { text: "Kalmykiya, Respublika", value: "KL" },
    { text: "Kaluzhskaya oblast'", value: "KLU" },
    { text: "Kamchatskiy kray", value: "KAM" },
    { text: "Karachayevo-Cherkesskaya Respublika", value: "KC" },
    { text: "Kareliya, Respublika", value: "KR" },
    { text: "Kemerovskaya oblast'", value: "KEM" },
    { text: "Khabarovskiy kray", value: "KHA" },
    { text: "Khakasiya, Respublika", value: "KK" },
    { text: "Khanty-Mansiyskiy avtonomnyy okrug", value: "KHM" },
    { text: "Kirovskaya oblast'", value: "KIR" },
    { text: "Komi, Respublika", value: "KO" },
    { text: "Kostromskaya oblast'", value: "KOS" },
    { text: "Krasnodarskiy kray", value: "KDA" },
    { text: "Krasnoyarskiy kray", value: "KYA" },
    { text: "Kurganskaya oblast'", value: "KGN" },
    { text: "Kurskaya oblast'", value: "KRS" },
    { text: "Leningradskaya oblast'", value: "LEN" },
    { text: "Lipetskaya oblast'", value: "LIP" },
    { text: "Magadanskaya oblast'", value: "MAG" },
    { text: "Mariy El, Respublika", value: "ME" },
    { text: "Mordoviya, Respublika", value: "MO" },
    { text: "Moskovskaya oblast'", value: "MOS" },
    { text: "Moskva", value: "MOW" },
    { text: "Murmanskaya oblast'", value: "MUR" },
    { text: "Nenetskiy avtonomnyy okrug", value: "NEN" },
    { text: "Nizhegorodskaya oblast'", value: "NIZ" },
    { text: "Novgorodskaya oblast'", value: "NGR" },
    { text: "Novosibirskaya oblast'", value: "NVS" },
    { text: "Omskaya oblast'", value: "OMS" },
    { text: "Orenburgskaya oblast'", value: "ORE" },
    { text: "Orlovskaya oblast'", value: "ORL" },
    { text: "Penzenskaya oblast'", value: "PNZ" },
    { text: "Permskiy kray", value: "PER" },
    { text: "Primorskiy kray", value: "PRI" },
    { text: "Pskovskaya oblast'", value: "PSK" },
    { text: "Rostovskaya oblast'", value: "ROS" },
    { text: "Ryazanskaya oblast'", value: "RYA" },
    { text: "Saha, Respublika", value: "SA" },
    { text: "Sakhalinskaya oblast'", value: "SAK" },
    { text: "Samarskaya oblast'", value: "SAM" },
    { text: "Sankt-Peterburg", value: "SPE" },
    { text: "Saratovskaya oblast'", value: "SAR" },
    { text: "Severnaya Osetiya, Respublika", value: "SE" },
    { text: "Smolenskaya oblast'", value: "SMO" },
    { text: "Stavropol'skiy kray", value: "STA" },
    { text: "Sverdlovskaya oblast'", value: "SVE" },
    { text: "Tambovskaya oblast'", value: "TAM" },
    { text: "Tatarstan, Respublika", value: "TA" },
    { text: "Tomskaya oblast'", value: "TOM" },
    { text: "Tul'skaya oblast'", value: "TUL" },
    { text: "Tverskaya oblast'", value: "TVE" },
    { text: "Tyumenskaya oblast'", value: "TYU" },
    { text: "Tyva, Respublika", value: "TY" },
    { text: "Udmurtskaya Respublika", value: "UD" },
    { text: "Ul'yanovskaya oblast'", value: "ULY" },
    { text: "Vladimirskaya oblast'", value: "VLA" },
    { text: "Volgogradskaya oblast'", value: "VGG" },
    { text: "Vologodskaya oblast'", value: "VLG" },
    { text: "Voronezhskaya oblast'", value: "VOR" },
    { text: "Yamalo-Nenetskiy avtonomnyy okrug", value: "YAN" },
    { text: "Yaroslavskaya oblast'", value: "YAR" },
    { text: "Yevreyskaya avtonomnaya oblast'", value: "YEV" },
    { text: "Zabaykal'skiy kray", value: "ZAB" }
  ],
  RW: [
    { text: "Est", value: "02" },
    { text: "Nord", value: "03" },
    { text: "Ouest", value: "04" },
    { text: "Sud", value: "05" },
    { text: "Ville de Kigali", value: "01" }
  ],
  BL: [{ text: "Saint Barthelemy", value: "01" }],
  SH: [{ text: "Saint Helena", value: "HL" }],
  KN: [
    { text: "Saint Anne Sandy Point", value: "02" },
    { text: "Saint George Basseterre", value: "03" },
    { text: "Saint James Windward", value: "05" },
    { text: "Saint John Capisterre", value: "06" },
    { text: "Saint John Figtree", value: "07" },
    { text: "Saint Mary Cayon", value: "08" },
    { text: "Saint Paul Capisterre", value: "09" },
    { text: "Saint Paul Charlestown", value: "10" },
    { text: "Saint Peter Basseterre", value: "11" },
    { text: "Saint Thomas Lowland", value: "12" },
    { text: "Saint Thomas Middle Island", value: "13" }
  ],
  LC: [
    { text: "Anse la Raye", value: "01" },
    { text: "Castries", value: "02" },
    { text: "Dennery", value: "05" },
    { text: "Gros Islet", value: "06" },
    { text: "Laborie", value: "07" },
    { text: "Micoud", value: "08" },
    { text: "Soufriere", value: "10" },
    { text: "Vieux Fort", value: "11" }
  ],
  MF: [{ text: "Saint Martin (French Part)", value: "01" }],
  PM: [{ text: "Saint Pierre and Miquelon", value: "01" }],
  VC: [
    { text: "Charlotte", value: "01" },
    { text: "Grenadines", value: "06" },
    { text: "Saint David", value: "03" },
    { text: "Saint George", value: "04" },
    { text: "Saint Patrick", value: "05" }
  ],
  WS: [
    { text: "Fa'asaleleaga", value: "FA" },
    { text: "Tuamasaga", value: "TU" }
  ],
  SM: [
    { text: "Domagnano", value: "03" },
    { text: "San Marino", value: "07" },
    { text: "Serravalle", value: "09" }
  ],
  ST: [
    { text: "Principe", value: "P" },
    { text: "Sao Tome", value: "S" }
  ],
  SA: [
    { text: "'Asir", value: "14" },
    { text: "Al Bahah", value: "11" },
    { text: "Al Hudud ash Shamaliyah", value: "08" },
    { text: "Al Jawf", value: "12" },
    { text: "Al Madinah al Munawwarah", value: "03" },
    { text: "Al Qasim", value: "05" },
    { text: "Ar Riyad", value: "01" },
    { text: "Ash Sharqiyah", value: "04" },
    { text: "Ha'il", value: "06" },
    { text: "Jazan", value: "09" },
    { text: "Makkah al Mukarramah", value: "02" },
    { text: "Najran", value: "10" },
    { text: "Tabuk", value: "07" }
  ],
  SN: [
    { text: "Dakar", value: "DK" },
    { text: "Diourbel", value: "DB" },
    { text: "Fatick", value: "FK" },
    { text: "Kaffrine", value: "KA" },
    { text: "Kaolack", value: "KL" },
    { text: "Kolda", value: "KD" },
    { text: "Louga", value: "LG" },
    { text: "Matam", value: "MT" },
    { text: "Saint-Louis", value: "SL" },
    { text: "Tambacounda", value: "TC" },
    { text: "Thies", value: "TH" },
    { text: "Ziguinchor", value: "ZG" }
  ],
  RS: [
    { text: "Beograd", value: "00" },
    { text: "Borski okrug", value: "14" },
    { text: "Branicevski okrug", value: "11" },
    { text: "Jablanicki okrug", value: "23" },
    { text: "Juznobacki okrug", value: "06" },
    { text: "Juznobanatski okrug", value: "04" },
    { text: "Kolubarski okrug", value: "09" },
    { text: "Kosovsko-Mitrovacki okrug", value: "28" },
    { text: "Macvanski okrug", value: "08" },
    { text: "Moravicki okrug", value: "17" },
    { text: "Nisavski okrug", value: "20" },
    { text: "Pcinjski okrug", value: "24" },
    { text: "Pecki okrug", value: "26" },
    { text: "Pirotski okrug", value: "22" },
    { text: "Podunavski okrug", value: "10" },
    { text: "Pomoravski okrug", value: "13" },
    { text: "Prizrenski okrug", value: "27" },
    { text: "Rasinski okrug", value: "19" },
    { text: "Raski okrug", value: "18" },
    { text: "Severnobacki okrug", value: "01" },
    { text: "Severnobanatski okrug", value: "03" },
    { text: "Srednjebanatski okrug", value: "02" },
    { text: "Sremski okrug", value: "07" },
    { text: "Sumadijski okrug", value: "12" },
    { text: "Toplicki okrug", value: "21" },
    { text: "Zajecarski okrug", value: "15" },
    { text: "Zapadnobacki okrug", value: "05" },
    { text: "Zlatiborski okrug", value: "16" }
  ],
  SC: [
    { text: "Anse Boileau", value: "02" },
    { text: "Anse aux Pins", value: "01" },
    { text: "Baie Lazare", value: "06" },
    { text: "Beau Vallon", value: "08" },
    { text: "Bel Ombre", value: "10" },
    { text: "Cascade", value: "11" },
    { text: "English River", value: "16" },
    { text: "Grand Anse Mahe", value: "13" },
    { text: "La Digue", value: "15" },
    { text: "Pointe Larue", value: "20" },
    { text: "Port Glaud", value: "21" },
    { text: "Takamaka", value: "23" }
  ],
  SL: [
    { text: "Eastern", value: "E" },
    { text: "Northern", value: "N" },
    { text: "Western Area", value: "W" }
  ],
  SG: [{ text: "Singapore", value: "01" }],
  SK: [
    { text: "Banskobystricky kraj", value: "BC" },
    { text: "Bratislavsky kraj", value: "BL" },
    { text: "Kosicky kraj", value: "KI" },
    { text: "Nitriansky kraj", value: "NI" },
    { text: "Presovsky kraj", value: "PV" },
    { text: "Trenciansky kraj", value: "TC" },
    { text: "Trnavsky kraj", value: "TA" },
    { text: "Zilinsky kraj", value: "ZI" }
  ],
  SI: [
    { text: "Ajdovscina", value: "001" },
    { text: "Apace", value: "195" },
    { text: "Beltinci", value: "002" },
    { text: "Benedikt", value: "148" },
    { text: "Bistrica ob Sotli", value: "149" },
    { text: "Bled", value: "003" },
    { text: "Bloke", value: "150" },
    { text: "Bohinj", value: "004" },
    { text: "Borovnica", value: "005" },
    { text: "Bovec", value: "006" },
    { text: "Braslovce", value: "151" },
    { text: "Brda", value: "007" },
    { text: "Brezice", value: "009" },
    { text: "Brezovica", value: "008" },
    { text: "Cankova", value: "152" },
    { text: "Celje", value: "011" },
    { text: "Cerklje na Gorenjskem", value: "012" },
    { text: "Cerknica", value: "013" },
    { text: "Cerkno", value: "014" },
    { text: "Cirkulane", value: "196" },
    { text: "Crensovci", value: "015" },
    { text: "Crnomelj", value: "017" },
    { text: "Destrnik", value: "018" },
    { text: "Divaca", value: "019" },
    { text: "Dobje", value: "154" },
    { text: "Dobrepolje", value: "020" },
    { text: "Dobrna", value: "155" },
    { text: "Dobrova-Polhov Gradec", value: "021" },
    { text: "Dobrovnik", value: "156" },
    { text: "Domzale", value: "023" },
    { text: "Dornava", value: "024" },
    { text: "Dravograd", value: "025" },
    { text: "Duplek", value: "026" },
    { text: "Gorje", value: "207" },
    { text: "Gornja Radgona", value: "029" },
    { text: "Gornji Petrovci", value: "031" },
    { text: "Grad", value: "158" },
    { text: "Grosuplje", value: "032" },
    { text: "Hajdina", value: "159" },
    { text: "Hoce-Slivnica", value: "160" },
    { text: "Hodos", value: "161" },
    { text: "Horjul", value: "162" },
    { text: "Hrastnik", value: "034" },
    { text: "Hrpelje-Kozina", value: "035" },
    { text: "Idrija", value: "036" },
    { text: "Ig", value: "037" },
    { text: "Ilirska Bistrica", value: "038" },
    { text: "Ivancna Gorica", value: "039" },
    { text: "Izola", value: "040" },
    { text: "Jesenice", value: "041" },
    { text: "Jursinci", value: "042" },
    { text: "Kamnik", value: "043" },
    { text: "Kanal", value: "044" },
    { text: "Kidricevo", value: "045" },
    { text: "Kobarid", value: "046" },
    { text: "Kobilje", value: "047" },
    { text: "Kocevje", value: "048" },
    { text: "Komen", value: "049" },
    { text: "Komenda", value: "164" },
    { text: "Koper", value: "050" },
    { text: "Kosanjevica na Krki", value: "197" },
    { text: "Kostel", value: "165" },
    { text: "Kranj", value: "052" },
    { text: "Kranjska Gora", value: "053" },
    { text: "Krizevci", value: "166" },
    { text: "Krsko", value: "054" },
    { text: "Kungota", value: "055" },
    { text: "Kuzma", value: "056" },
    { text: "Lasko", value: "057" },
    { text: "Lenart", value: "058" },
    { text: "Lendava", value: "059" },
    { text: "Litija", value: "060" },
    { text: "Ljubljana", value: "061" },
    { text: "Ljutomer", value: "063" },
    { text: "Log-Dragomer", value: "208" },
    { text: "Logatec", value: "064" },
    { text: "Loska Dolina", value: "065" },
    { text: "Lovrenc na Pohorju", value: "167" },
    { text: "Luce", value: "067" },
    { text: "Lukovica", value: "068" },
    { text: "Majsperk", value: "069" },
    { text: "Makole", value: "198" },
    { text: "Maribor", value: "070" },
    { text: "Markovci", value: "168" },
    { text: "Medvode", value: "071" },
    { text: "Menges", value: "072" },
    { text: "Metlika", value: "073" },
    { text: "Mezica", value: "074" },
    { text: "Miklavz na Dravskem Polju", value: "169" },
    { text: "Miren-Kostanjevica", value: "075" },
    { text: "Mirna Pec", value: "170" },
    { text: "Mislinja", value: "076" },
    { text: "Mokronog-Trebelno", value: "199" },
    { text: "Moravce", value: "077" },
    { text: "Mozirje", value: "079" },
    { text: "Murska Sobota", value: "080" },
    { text: "Muta", value: "081" },
    { text: "Naklo", value: "082" },
    { text: "Nazarje", value: "083" },
    { text: "Nova Gorica", value: "084" },
    { text: "Novo Mesto", value: "085" },
    { text: "Odranci", value: "086" },
    { text: "Oplotnica", value: "171" },
    { text: "Ormoz", value: "087" },
    { text: "Piran", value: "090" },
    { text: "Pivka", value: "091" },
    { text: "Podcetrtek", value: "092" },
    { text: "Podlehnik", value: "172" },
    { text: "Poljcane", value: "200" },
    { text: "Polzela", value: "173" },
    { text: "Postojna", value: "094" },
    { text: "Prebold", value: "174" },
    { text: "Preddvor", value: "095" },
    { text: "Prevalje", value: "175" },
    { text: "Ptuj", value: "096" },
    { text: "Puconci", value: "097" },
    { text: "Race-Fram", value: "098" },
    { text: "Radece", value: "099" },
    { text: "Radenci", value: "100" },
    { text: "Radlje ob Dravi", value: "101" },
    { text: "Radovljica", value: "102" },
    { text: "Ravne na Koroskem", value: "103" },
    { text: "Razkrizje", value: "176" },
    { text: "Recica ob Savinji", value: "209" },
    { text: "Rence-Vogrsko", value: "201" },
    { text: "Ribnica", value: "104" },
    { text: "Rogaska Slatina", value: "106" },
    { text: "Rogasovci", value: "105" },
    { text: "Ruse", value: "108" },
    { text: "Salovci", value: "033" },
    { text: "Semic", value: "109" },
    { text: "Sempeter-Vrtojba", value: "183" },
    { text: "Sencur", value: "117" },
    { text: "Sentilj", value: "118" },
    { text: "Sentjernej", value: "119" },
    { text: "Sentjur", value: "120" },
    { text: "Sentrupert", value: "211" },
    { text: "Sevnica", value: "110" },
    { text: "Sezana", value: "111" },
    { text: "Skocjan", value: "121" },
    { text: "Skofja Loka", value: "122" },
    { text: "Skofljica", value: "123" },
    { text: "Slovenj Gradec", value: "112" },
    { text: "Slovenska Bistrica", value: "113" },
    { text: "Slovenske Konjice", value: "114" },
    { text: "Smarje pri Jelsah", value: "124" },
    { text: "Smarjeske Toplice", value: "206" },
    { text: "Smartno ob Paki", value: "125" },
    { text: "Smartno pri Litiji", value: "194" },
    { text: "Sodrazica", value: "179" },
    { text: "Solcava", value: "180" },
    { text: "Sostanj", value: "126" },
    { text: "Starse", value: "115" },
    { text: "Store", value: "127" },
    { text: "Straza", value: "203" },
    { text: "Sveta Trojica v Slovenskih Goricah", value: "204" },
    { text: "Sveti Andraz v Slovenskih Goricah", value: "182" },
    { text: "Sveti Jurij", value: "116" },
    { text: "Sveti Jurij v Slovenskih Goricah", value: "210" },
    { text: "Sveti Tomaz", value: "205" },
    { text: "Tabor", value: "184" },
    { text: "Tisina", value: "010" },
    { text: "Tolmin", value: "128" },
    { text: "Trbovlje", value: "129" },
    { text: "Trebnje", value: "130" },
    { text: "Trnovska Vas", value: "185" },
    { text: "Trzic", value: "131" },
    { text: "Trzin", value: "186" },
    { text: "Turnisce", value: "132" },
    { text: "Velenje", value: "133" },
    { text: "Velika Polana", value: "187" },
    { text: "Velike Lasce", value: "134" },
    { text: "Verzej", value: "188" },
    { text: "Videm", value: "135" },
    { text: "Vipava", value: "136" },
    { text: "Vitanje", value: "137" },
    { text: "Vodice", value: "138" },
    { text: "Vojnik", value: "139" },
    { text: "Vransko", value: "189" },
    { text: "Vrhnika", value: "140" },
    { text: "Vuzenica", value: "141" },
    { text: "Zagorje ob Savi", value: "142" },
    { text: "Zalec", value: "190" },
    { text: "Zavrc", value: "143" },
    { text: "Zelezniki", value: "146" },
    { text: "Zetale", value: "191" },
    { text: "Ziri", value: "147" },
    { text: "Zrece", value: "144" },
    { text: "Zuzemberk", value: "193" }
  ],
  SB: [
    { text: "Choiseul", value: "CH" },
    { text: "Guadalcanal", value: "GU" }
  ],
  SO: [
    { text: "Awdal", value: "AW" },
    { text: "Banaadir", value: "BN" },
    { text: "Bay", value: "BY" },
    { text: "Nugaal", value: "NU" },
    { text: "Shabeellaha Hoose", value: "SH" },
    { text: "Togdheer", value: "TO" },
    { text: "Woqooyi Galbeed", value: "WO" }
  ],
  ZA: [
    { text: "Eastern Cape", value: "EC" },
    { text: "Free State", value: "FS" },
    { text: "Gauteng", value: "GP" },
    { text: "Kwazulu-Natal", value: "KZN" },
    { text: "Limpopo", value: "LP" },
    { text: "Mpumalanga", value: "MP" },
    { text: "North-West", value: "NW" },
    { text: "Northern Cape", value: "NC" },
    { text: "Western Cape", value: "WC" }
  ],
  GS: [{ text: "South Georgia and the South Sandwich Islands", value: "01" }],
  SS: [
    { text: "Central Equatoria", value: "EC" },
    { text: "Eastern Equatoria", value: "EE" },
    { text: "Northern Bahr el Ghazal", value: "BN" },
    { text: "Unity", value: "UY" },
    { text: "Upper Nile", value: "NU" },
    { text: "Western Equatoria", value: "EW" }
  ],
  ES: [
    { text: "Andalucia", value: "AN" },
    { text: "Aragon", value: "AR" },
    { text: "Asturias, Principado de", value: "AS" },
    { text: "Canarias", value: "CN" },
    { text: "Cantabria", value: "CB" },
    { text: "Castilla y Leon", value: "CL" },
    { text: "Castilla-La Mancha", value: "CM" },
    { text: "Catalunya", value: "CT" },
    { text: "Ceuta", value: "CE" },
    { text: "Extremadura", value: "EX" },
    { text: "Galicia", value: "GA" },
    { text: "Illes Balears", value: "IB" },
    { text: "La Rioja", value: "RI" },
    { text: "Madrid, Comunidad de", value: "MD" },
    { text: "Melilla", value: "ML" },
    { text: "Murcia, Region de", value: "MC" },
    { text: "Navarra, Comunidad Foral de", value: "NC" },
    { text: "Pais Vasco", value: "PV" },
    { text: "Valenciana, Comunidad", value: "VC" }
  ],
  LK: [
    { text: "Central Province", value: "2" },
    { text: "Eastern Province", value: "5" },
    { text: "North Central Province", value: "7" },
    { text: "North Western Province", value: "6" },
    { text: "Northern Province", value: "4" },
    { text: "Sabaragamuwa Province", value: "9" },
    { text: "Southern Province", value: "3" },
    { text: "Uva Province", value: "8" },
    { text: "Western Province", value: "1" }
  ],
  SD: [
    { text: "Blue Nile", value: "NB" },
    { text: "Gedaref", value: "GD" },
    { text: "Gezira", value: "GZ" },
    { text: "Kassala", value: "KA" },
    { text: "Khartoum", value: "KH" },
    { text: "North Darfur", value: "DN" },
    { text: "Northern", value: "NO" },
    { text: "Red Sea", value: "RS" },
    { text: "River Nile", value: "NR" },
    { text: "Sennar", value: "SI" },
    { text: "South Darfur", value: "DS" },
    { text: "South Kordofan", value: "KS" },
    { text: "West Darfur", value: "DW" },
    { text: "White Nile", value: "NW" }
  ],
  SR: [
    { text: "Brokopondo", value: "BR" },
    { text: "Commewijne", value: "CM" },
    { text: "Nickerie", value: "NI" },
    { text: "Para", value: "PR" },
    { text: "Paramaribo", value: "PM" },
    { text: "Saramacca", value: "SA" },
    { text: "Sipaliwini", value: "SI" },
    { text: "Wanica", value: "WA" }
  ],
  SJ: [{ text: "Svalbard and Jan Mayen", value: "01" }],
  SZ: [
    { text: "Hhohho", value: "HH" },
    { text: "Lubombo", value: "LU" },
    { text: "Manzini", value: "MA" }
  ],
  SE: [
    { text: "Blekinge lan", value: "K" },
    { text: "Dalarnas lan", value: "W" },
    { text: "Gavleborgs lan", value: "X" },
    { text: "Gotlands lan", value: "I" },
    { text: "Hallands lan", value: "N" },
    { text: "Jamtlands lan", value: "Z" },
    { text: "Jonkopings lan", value: "F" },
    { text: "Kalmar lan", value: "H" },
    { text: "Kronobergs lan", value: "G" },
    { text: "Norrbottens lan", value: "BD" },
    { text: "Orebro lan", value: "T" },
    { text: "Ostergotlands lan", value: "E" },
    { text: "Skane lan", value: "M" },
    { text: "Sodermanlands lan", value: "D" },
    { text: "Stockholms lan", value: "AB" },
    { text: "Uppsala lan", value: "C" },
    { text: "Varmlands lan", value: "S" },
    { text: "Vasterbottens lan", value: "AC" },
    { text: "Vasternorrlands lan", value: "Y" },
    { text: "Vastmanlands lan", value: "U" },
    { text: "Vastra Gotalands lan", value: "O" }
  ],
  CH: [
    { text: "Aargau", value: "AG" },
    { text: "Appenzell Ausserrhoden", value: "AR" },
    { text: "Appenzell Innerrhoden", value: "AI" },
    { text: "Basel-Landschaft", value: "BL" },
    { text: "Basel-Stadt", value: "BS" },
    { text: "Bern", value: "BE" },
    { text: "Fribourg", value: "FR" },
    { text: "Geneve", value: "GE" },
    { text: "Glarus", value: "GL" },
    { text: "Graubunden", value: "GR" },
    { text: "Jura", value: "JU" },
    { text: "Luzern", value: "LU" },
    { text: "Neuchatel", value: "NE" },
    { text: "Nidwalden", value: "NW" },
    { text: "Obwalden", value: "OW" },
    { text: "Sankt Gallen", value: "SG" },
    { text: "Schaffhausen", value: "SH" },
    { text: "Schwyz", value: "SZ" },
    { text: "Solothurn", value: "SO" },
    { text: "Thurgau", value: "TG" },
    { text: "Ticino", value: "TI" },
    { text: "Uri", value: "UR" },
    { text: "Valais", value: "VS" },
    { text: "Vaud", value: "VD" },
    { text: "Zug", value: "ZG" },
    { text: "Zurich", value: "ZH" }
  ],
  SY: [
    { text: "Al Hasakah", value: "HA" },
    { text: "Al Ladhiqiyah", value: "LA" },
    { text: "Al Qunaytirah", value: "QU" },
    { text: "Ar Raqqah", value: "RA" },
    { text: "As Suwayda'", value: "SU" },
    { text: "Dar'a", value: "DR" },
    { text: "Dayr az Zawr", value: "DY" },
    { text: "Dimashq", value: "DI" },
    { text: "Halab", value: "HL" },
    { text: "Hamah", value: "HM" },
    { text: "Hims", value: "HI" },
    { text: "Idlib", value: "ID" },
    { text: "Rif Dimashq", value: "RD" },
    { text: "Tartus", value: "TA" }
  ],
  TW: [
    { text: "Changhua", value: "CHA" },
    { text: "Chiayi", value: "CYQ" },
    { text: "Hsinchu", value: "HSQ" },
    { text: "Hualien", value: "HUA" },
    { text: "Kaohsiung", value: "KHH" },
    { text: "Keelung", value: "KEE" },
    { text: "Kinmen", value: "KIN" },
    { text: "Lienchiang", value: "LIE" },
    { text: "Miaoli", value: "MIA" },
    { text: "Nantou", value: "NAN" },
    { text: "New Taipei", value: "NWT" },
    { text: "Penghu", value: "PEN" },
    { text: "Pingtung", value: "PIF" },
    { text: "Taichung", value: "TXG" },
    { text: "Tainan", value: "TNN" },
    { text: "Taipei", value: "TPE" },
    { text: "Taitung", value: "TTT" },
    { text: "Taoyuan", value: "TAO" },
    { text: "Yilan", value: "ILA" },
    { text: "Yunlin", value: "YUN" }
  ],
  TJ: [
    { text: "Dushanbe", value: "DU" },
    { text: "Khatlon", value: "KT" },
    { text: "Kuhistoni Badakhshon", value: "GB" },
    { text: "Nohiyahoi Tobei Jumhuri", value: "RA" },
    { text: "Sughd", value: "SU" }
  ],
  TZ: [
    { text: "Arusha", value: "01" },
    { text: "Dar es Salaam", value: "02" },
    { text: "Dodoma", value: "03" },
    { text: "Geita", value: "27" },
    { text: "Iringa", value: "04" },
    { text: "Kagera", value: "05" },
    { text: "Kaskazini Unguja", value: "07" },
    { text: "Katavi", value: "28" },
    { text: "Kigoma", value: "08" },
    { text: "Kilimanjaro", value: "09" },
    { text: "Kusini Pemba", value: "10" },
    { text: "Kusini Unguja", value: "11" },
    { text: "Lindi", value: "12" },
    { text: "Manyara", value: "26" },
    { text: "Mara", value: "13" },
    { text: "Mbeya", value: "14" },
    { text: "Mjini Magharibi", value: "15" },
    { text: "Morogoro", value: "16" },
    { text: "Mtwara", value: "17" },
    { text: "Mwanza", value: "18" },
    { text: "Njombe", value: "29" },
    { text: "Pwani", value: "19" },
    { text: "Rukwa", value: "20" },
    { text: "Ruvuma", value: "21" },
    { text: "Shinyanga", value: "22" },
    { text: "Simiyu", value: "30" },
    { text: "Singida", value: "23" },
    { text: "Tabora", value: "24" },
    { text: "Tanga", value: "25" }
  ],
  TH: [
    { text: "Amnat Charoen", value: "37" },
    { text: "Ang Thong", value: "15" },
    { text: "Bueng Kan", value: "38" },
    { text: "Buri Ram", value: "31" },
    { text: "Chachoengsao", value: "24" },
    { text: "Chai Nat", value: "18" },
    { text: "Chaiyaphum", value: "36" },
    { text: "Chanthaburi", value: "22" },
    { text: "Chiang Mai", value: "50" },
    { text: "Chiang Rai", value: "57" },
    { text: "Chon Buri", value: "20" },
    { text: "Chumphon", value: "86" },
    { text: "Kalasin", value: "46" },
    { text: "Kamphaeng Phet", value: "62" },
    { text: "Kanchanaburi", value: "71" },
    { text: "Khon Kaen", value: "40" },
    { text: "Krabi", value: "81" },
    { text: "Krung Thep Maha Nakhon", value: "10" },
    { text: "Lampang", value: "52" },
    { text: "Lamphun", value: "51" },
    { text: "Loei", value: "42" },
    { text: "Lop Buri", value: "16" },
    { text: "Mae Hong Son", value: "58" },
    { text: "Maha Sarakham", value: "44" },
    { text: "Mukdahan", value: "49" },
    { text: "Nakhon Nayok", value: "26" },
    { text: "Nakhon Pathom", value: "73" },
    { text: "Nakhon Phanom", value: "48" },
    { text: "Nakhon Ratchasima", value: "30" },
    { text: "Nakhon Sawan", value: "60" },
    { text: "Nakhon Si Thammarat", value: "80" },
    { text: "Nan", value: "55" },
    { text: "Narathiwat", value: "96" },
    { text: "Nong Bua Lam Phu", value: "39" },
    { text: "Nong Khai", value: "43" },
    { text: "Nonthaburi", value: "12" },
    { text: "Pathum Thani", value: "13" },
    { text: "Pattani", value: "94" },
    { text: "Phangnga", value: "82" },
    { text: "Phatthalung", value: "93" },
    { text: "Phayao", value: "56" },
    { text: "Phetchabun", value: "67" },
    { text: "Phetchaburi", value: "76" },
    { text: "Phichit", value: "66" },
    { text: "Phitsanulok", value: "65" },
    { text: "Phra Nakhon Si Ayutthaya", value: "14" },
    { text: "Phrae", value: "54" },
    { text: "Phuket", value: "83" },
    { text: "Prachin Buri", value: "25" },
    { text: "Prachuap Khiri Khan", value: "77" },
    { text: "Ranong", value: "85" },
    { text: "Ratchaburi", value: "70" },
    { text: "Rayong", value: "21" },
    { text: "Roi Et", value: "45" },
    { text: "Sa Kaeo", value: "27" },
    { text: "Sakon Nakhon", value: "47" },
    { text: "Samut Prakan", value: "11" },
    { text: "Samut Sakhon", value: "74" },
    { text: "Samut Songkhram", value: "75" },
    { text: "Saraburi", value: "19" },
    { text: "Satun", value: "91" },
    { text: "Si Sa Ket", value: "33" },
    { text: "Sing Buri", value: "17" },
    { text: "Songkhla", value: "90" },
    { text: "Sukhothai", value: "64" },
    { text: "Suphan Buri", value: "72" },
    { text: "Surat Thani", value: "84" },
    { text: "Surin", value: "32" },
    { text: "Tak", value: "63" },
    { text: "Trang", value: "92" },
    { text: "Trat", value: "23" },
    { text: "Ubon Ratchathani", value: "34" },
    { text: "Udon Thani", value: "41" },
    { text: "Uthai Thani", value: "61" },
    { text: "Uttaradit", value: "53" },
    { text: "Yala", value: "95" },
    { text: "Yasothon", value: "35" }
  ],
  TL: [
    { text: "Ainaro", value: "AN" },
    { text: "Cova Lima", value: "CO" },
    { text: "Dili", value: "DI" }
  ],
  TG: [
    { text: "Centrale", value: "C" },
    { text: "Kara", value: "K" },
    { text: "Maritime", value: "M" },
    { text: "Plateaux", value: "P" }
  ],
  TK: [{ text: "Tokelau", value: "01" }],
  TO: [
    { text: "Niuas", value: "03" },
    { text: "Tongatapu", value: "04" }
  ],
  TT: [
    { text: "Arima", value: "ARI" },
    { text: "Chaguanas", value: "CHA" },
    { text: "Couva-Tabaquite-Talparo", value: "CTT" },
    { text: "Diego Martin", value: "DMN" },
    { text: "Mayaro-Rio Claro", value: "MRC" },
    { text: "Penal-Debe", value: "PED" },
    { text: "Point Fortin", value: "PTF" },
    { text: "Port of Spain", value: "POS" },
    { text: "Princes Town", value: "PRT" },
    { text: "San Fernando", value: "SFO" },
    { text: "San Juan-Laventille", value: "SJL" },
    { text: "Sangre Grande", value: "SGE" },
    { text: "Siparia", value: "SIP" },
    { text: "Tobago", value: "TOB" },
    { text: "Tunapuna-Piarco", value: "TUP" }
  ],
  TN: [
    { text: "Beja", value: "31" },
    { text: "Ben Arous", value: "13" },
    { text: "Bizerte", value: "23" },
    { text: "Gabes", value: "81" },
    { text: "Gafsa", value: "71" },
    { text: "Jendouba", value: "32" },
    { text: "Kairouan", value: "41" },
    { text: "Kasserine", value: "42" },
    { text: "Kebili", value: "73" },
    { text: "L'Ariana", value: "12" },
    { text: "La Manouba", value: "14" },
    { text: "Le Kef", value: "33" },
    { text: "Mahdia", value: "53" },
    { text: "Medenine", value: "82" },
    { text: "Monastir", value: "52" },
    { text: "Nabeul", value: "21" },
    { text: "Sfax", value: "61" },
    { text: "Sidi Bouzid", value: "43" },
    { text: "Siliana", value: "34" },
    { text: "Sousse", value: "51" },
    { text: "Tataouine", value: "83" },
    { text: "Tozeur", value: "72" },
    { text: "Tunis", value: "11" },
    { text: "Zaghouan", value: "22" }
  ],
  TR: [
    { text: "Adana", value: "01" },
    { text: "Adiyaman", value: "02" },
    { text: "Afyonkarahisar", value: "03" },
    { text: "Agri", value: "04" },
    { text: "Aksaray", value: "68" },
    { text: "Amasya", value: "05" },
    { text: "Ankara", value: "06" },
    { text: "Antalya", value: "07" },
    { text: "Ardahan", value: "75" },
    { text: "Artvin", value: "08" },
    { text: "Aydin", value: "09" },
    { text: "Balikesir", value: "10" },
    { text: "Bartin", value: "74" },
    { text: "Batman", value: "72" },
    { text: "Bayburt", value: "69" },
    { text: "Bilecik", value: "11" },
    { text: "Bingol", value: "12" },
    { text: "Bitlis", value: "13" },
    { text: "Bolu", value: "14" },
    { text: "Burdur", value: "15" },
    { text: "Bursa", value: "16" },
    { text: "Canakkale", value: "17" },
    { text: "Cankiri", value: "18" },
    { text: "Corum", value: "19" },
    { text: "Denizli", value: "20" },
    { text: "Diyarbakir", value: "21" },
    { text: "Duzce", value: "81" },
    { text: "Edirne", value: "22" },
    { text: "Elazig", value: "23" },
    { text: "Erzincan", value: "24" },
    { text: "Erzurum", value: "25" },
    { text: "Eskisehir", value: "26" },
    { text: "Gaziantep", value: "27" },
    { text: "Giresun", value: "28" },
    { text: "Gumushane", value: "29" },
    { text: "Hakkari", value: "30" },
    { text: "Hatay", value: "31" },
    { text: "Igdir", value: "76" },
    { text: "Isparta", value: "32" },
    { text: "Istanbul", value: "34" },
    { text: "Izmir", value: "35" },
    { text: "Kahramanmaras", value: "46" },
    { text: "Karabuk", value: "78" },
    { text: "Karaman", value: "70" },
    { text: "Kars", value: "36" },
    { text: "Kastamonu", value: "37" },
    { text: "Kayseri", value: "38" },
    { text: "Kilis", value: "79" },
    { text: "Kirikkale", value: "71" },
    { text: "Kirklareli", value: "39" },
    { text: "Kirsehir", value: "40" },
    { text: "Kocaeli", value: "41" },
    { text: "Konya", value: "42" },
    { text: "Kutahya", value: "43" },
    { text: "Malatya", value: "44" },
    { text: "Manisa", value: "45" },
    { text: "Mardin", value: "47" },
    { text: "Mersin", value: "33" },
    { text: "Mugla", value: "48" },
    { text: "Mus", value: "49" },
    { text: "Nevsehir", value: "50" },
    { text: "Nigde", value: "51" },
    { text: "Ordu", value: "52" },
    { text: "Osmaniye", value: "80" },
    { text: "Rize", value: "53" },
    { text: "Sakarya", value: "54" },
    { text: "Samsun", value: "55" },
    { text: "Sanliurfa", value: "63" },
    { text: "Siirt", value: "56" },
    { text: "Sinop", value: "57" },
    { text: "Sirnak", value: "73" },
    { text: "Sivas", value: "58" },
    { text: "Tekirdag", value: "59" },
    { text: "Tokat", value: "60" },
    { text: "Trabzon", value: "61" },
    { text: "Tunceli", value: "62" },
    { text: "Usak", value: "64" },
    { text: "Van", value: "65" },
    { text: "Yalova", value: "77" },
    { text: "Yozgat", value: "66" },
    { text: "Zonguldak", value: "67" }
  ],
  TM: [
    { text: "Ahal", value: "A" },
    { text: "Balkan", value: "B" },
    { text: "Dasoguz", value: "D" },
    { text: "Lebap", value: "L" },
    { text: "Mary", value: "M" }
  ],
  TC: [{ text: "Turks and Caicos Islands", value: "01" }],
  TV: [{ text: "Funafuti", value: "FUN" }],
  UG: [
    { text: "Amuru", value: "316" },
    { text: "Arua", value: "303" },
    { text: "Bugiri", value: "201" },
    { text: "Buikwe", value: "117" },
    { text: "Bukedea", value: "219" },
    { text: "Buliisa", value: "416" },
    { text: "Buvuma", value: "120" },
    { text: "Gulu", value: "304" },
    { text: "Hoima", value: "403" },
    { text: "Iganga", value: "203" },
    { text: "Jinja", value: "204" },
    { text: "Kabale", value: "404" },
    { text: "Kabarole", value: "405" },
    { text: "Kalangala", value: "101" },
    { text: "Kaliro", value: "222" },
    { text: "Kalungu", value: "122" },
    { text: "Kampala", value: "102" },
    { text: "Kamuli", value: "205" },
    { text: "Kasese", value: "406" },
    { text: "Kayunga", value: "112" },
    { text: "Kibaale", value: "407" },
    { text: "Kiboga", value: "103" },
    { text: "Kiruhura", value: "419" },
    { text: "Kitgum", value: "305" },
    { text: "Kumi", value: "208" },
    { text: "Kyenjojo", value: "415" },
    { text: "Lira", value: "307" },
    { text: "Luwero", value: "104" },
    { text: "Masaka", value: "105" },
    { text: "Mayuge", value: "214" },
    { text: "Mbale", value: "209" },
    { text: "Mbarara", value: "410" },
    { text: "Mityana", value: "115" },
    { text: "Mpigi", value: "106" },
    { text: "Mukono", value: "108" },
    { text: "Nakaseke", value: "116" },
    { text: "Nwoya", value: "328" },
    { text: "Oyam", value: "321" },
    { text: "Pallisa", value: "210" },
    { text: "Rukungiri", value: "412" },
    { text: "Sironko", value: "215" },
    { text: "Soroti", value: "211" },
    { text: "Wakiso", value: "113" }
  ],
  UA: [
    { text: "Avtonomna Respublika Krym", value: "43" },
    { text: "Cherkaska oblast", value: "71" },
    { text: "Chernihivska oblast", value: "74" },
    { text: "Chernivetska oblast", value: "77" },
    { text: "Dnipropetrovska oblast", value: "12" },
    { text: "Donetska oblast", value: "14" },
    { text: "Ivano-Frankivska oblast", value: "26" },
    { text: "Kharkivska oblast", value: "63" },
    { text: "Khersonska oblast", value: "65" },
    { text: "Khmelnytska oblast", value: "68" },
    { text: "Kirovohradska oblast", value: "35" },
    { text: "Kyiv", value: "30" },
    { text: "Kyivska oblast", value: "32" },
    { text: "Luhanska oblast", value: "09" },
    { text: "Lvivska oblast", value: "46" },
    { text: "Mykolaivska oblast", value: "48" },
    { text: "Odeska oblast", value: "51" },
    { text: "Poltavska oblast", value: "53" },
    { text: "Rivnenska oblast", value: "56" },
    { text: "Sevastopol", value: "40" },
    { text: "Sumska oblast", value: "59" },
    { text: "Ternopilska oblast", value: "61" },
    { text: "Vinnytska oblast", value: "05" },
    { text: "Volynska oblast", value: "07" },
    { text: "Zakarpatska oblast", value: "21" },
    { text: "Zaporizka oblast", value: "23" },
    { text: "Zhytomyrska oblast", value: "18" }
  ],
  AE: [
    { text: "'Ajman", value: "AJ" },
    { text: "Abu Zaby", value: "AZ" },
    { text: "Al Fujayrah", value: "FU" },
    { text: "Ash Shariqah", value: "SH" },
    { text: "Dubayy", value: "DU" },
    { text: "Ra's al Khaymah", value: "RK" },
    { text: "Umm al Qaywayn", value: "UQ" }
  ],
  GB: [
    { text: "England", value: "ENG" },
    { text: "Northern Ireland", value: "NIR" },
    { text: "Scotland", value: "SCT" },
    { text: "Wales", value: "WLS" }
  ],
  US: [
    { text: "Alabama", value: "AL" },
    { text: "Alaska", value: "AK" },
    { text: "Arizona", value: "AZ" },
    { text: "Arkansas", value: "AR" },
    { text: "California", value: "CA" },
    { text: "Colorado", value: "CO" },
    { text: "Connecticut", value: "CT" },
    { text: "Delaware", value: "DE" },
    { text: "District of Columbia", value: "DC" },
    { text: "Florida", value: "FL" },
    { text: "Georgia", value: "GA" },
    { text: "Hawaii", value: "HI" },
    { text: "Idaho", value: "ID" },
    { text: "Illinois", value: "IL" },
    { text: "Indiana", value: "IN" },
    { text: "Iowa", value: "IA" },
    { text: "Kansas", value: "KS" },
    { text: "Kentucky", value: "KY" },
    { text: "Louisiana", value: "LA" },
    { text: "Maine", value: "ME" },
    { text: "Maryland", value: "MD" },
    { text: "Massachusetts", value: "MA" },
    { text: "Michigan", value: "MI" },
    { text: "Minnesota", value: "MN" },
    { text: "Mississippi", value: "MS" },
    { text: "Missouri", value: "MO" },
    { text: "Montana", value: "MT" },
    { text: "Nebraska", value: "NE" },
    { text: "Nevada", value: "NV" },
    { text: "New Hampshire", value: "NH" },
    { text: "New Jersey", value: "NJ" },
    { text: "New Mexico", value: "NM" },
    { text: "New York", value: "NY" },
    { text: "North Carolina", value: "NC" },
    { text: "North Dakota", value: "ND" },
    { text: "Ohio", value: "OH" },
    { text: "Oklahoma", value: "OK" },
    { text: "Oregon", value: "OR" },
    { text: "Pennsylvania", value: "PA" },
    { text: "Rhode Island", value: "RI" },
    { text: "South Carolina", value: "SC" },
    { text: "South Dakota", value: "SD" },
    { text: "Tennessee", value: "TN" },
    { text: "Texas", value: "TX" },
    { text: "Utah", value: "UT" },
    { text: "Vermont", value: "VT" },
    { text: "Virginia", value: "VA" },
    { text: "Washington", value: "WA" },
    { text: "West Virginia", value: "WV" },
    { text: "Wisconsin", value: "WI" },
    { text: "Wyoming", value: "WY" },
    { text: "Armed Forces Americas", value: "AA" },
    { text: "Armed Forces Europe", value: "AE" },
    { text: "Armed Forces Pacific", value: "AP" }
  ],
  UM: [{ text: "Palmyra Atoll", value: "95" }],
  UY: [
    { text: "Artigas", value: "AR" },
    { text: "Canelones", value: "CA" },
    { text: "Cerro Largo", value: "CL" },
    { text: "Colonia", value: "CO" },
    { text: "Durazno", value: "DU" },
    { text: "Flores", value: "FS" },
    { text: "Florida", value: "FD" },
    { text: "Lavalleja", value: "LA" },
    { text: "Maldonado", value: "MA" },
    { text: "Montevideo", value: "MO" },
    { text: "Paysandu", value: "PA" },
    { text: "Rio Negro", value: "RN" },
    { text: "Rivera", value: "RV" },
    { text: "Rocha", value: "RO" },
    { text: "Salto", value: "SA" },
    { text: "San Jose", value: "SJ" },
    { text: "Soriano", value: "SO" },
    { text: "Tacuarembo", value: "TA" },
    { text: "Treinta y Tres", value: "TT" }
  ],
  UZ: [
    { text: "Andijon", value: "AN" },
    { text: "Buxoro", value: "BU" },
    { text: "Farg'ona", value: "FA" },
    { text: "Jizzax", value: "JI" },
    { text: "Namangan", value: "NG" },
    { text: "Navoiy", value: "NW" },
    { text: "Qashqadaryo", value: "QA" },
    { text: "Qoraqalpog'iston Respublikasi", value: "QR" },
    { text: "Samarqand", value: "SA" },
    { text: "Sirdaryo", value: "SI" },
    { text: "Surxondaryo", value: "SU" },
    { text: "Toshkent", value: "TK" },
    { text: "Xorazm", value: "XO" }
  ],
  VU: [
    { text: "Shefa", value: "SEE" },
    { text: "Tafea", value: "TAE" },
    { text: "Torba", value: "TOB" }
  ],
  VE: [
    { text: "Amazonas", value: "Z" },
    { text: "Anzoategui", value: "B" },
    { text: "Apure", value: "C" },
    { text: "Aragua", value: "D" },
    { text: "Barinas", value: "E" },
    { text: "Bolivar", value: "F" },
    { text: "Carabobo", value: "G" },
    { text: "Cojedes", value: "H" },
    { text: "Delta Amacuro", value: "Y" },
    { text: "Distrito Capital", value: "A" },
    { text: "Falcon", value: "I" },
    { text: "Guarico", value: "J" },
    { text: "Lara", value: "K" },
    { text: "Merida", value: "L" },
    { text: "Miranda", value: "M" },
    { text: "Monagas", value: "N" },
    { text: "Nueva Esparta", value: "O" },
    { text: "Portuguesa", value: "P" },
    { text: "Sucre", value: "R" },
    { text: "Tachira", value: "S" },
    { text: "Trujillo", value: "T" },
    { text: "Vargas", value: "X" },
    { text: "Yaracuy", value: "U" },
    { text: "Zulia", value: "V" }
  ],
  VN: [
    { text: "An Giang", value: "44" },
    { text: "Ba Ria - Vung Tau", value: "43" },
    { text: "Bac Giang", value: "54" },
    { text: "Bac Kan", value: "53" },
    { text: "Bac Lieu", value: "55" },
    { text: "Bac Ninh", value: "56" },
    { text: "Ben Tre", value: "50" },
    { text: "Binh Dinh", value: "31" },
    { text: "Binh Duong", value: "57" },
    { text: "Binh Phuoc", value: "58" },
    { text: "Binh Thuan", value: "40" },
    { text: "Ca Mau", value: "59" },
    { text: "Can Tho", value: "CT" },
    { text: "Cao Bang", value: "04" },
    { text: "Da Nang", value: "DN" },
    { text: "Dak Lak", value: "33" },
    { text: "Dak Nong", value: "72" },
    { text: "Dien Bien", value: "71" },
    { text: "Dong Nai", value: "39" },
    { text: "Dong Thap", value: "45" },
    { text: "Gia Lai", value: "30" },
    { text: "Ha Giang", value: "03" },
    { text: "Ha Nam", value: "63" },
    { text: "Ha Noi", value: "HN" },
    { text: "Ha Tinh", value: "23" },
    { text: "Hai Duong", value: "61" },
    { text: "Hai Phong", value: "HP" },
    { text: "Ho Chi Minh", value: "SG" },
    { text: "Hoa Binh", value: "14" },
    { text: "Hung Yen", value: "66" },
    { text: "Khanh Hoa", value: "34" },
    { text: "Kien Giang", value: "47" },
    { text: "Kon Tum", value: "28" },
    { text: "Lai Chau", value: "01" },
    { text: "Lam Dong", value: "35" },
    { text: "Lang Son", value: "09" },
    { text: "Lao Cai", value: "02" },
    { text: "Long An", value: "41" },
    { text: "Nam Dinh", value: "67" },
    { text: "Nghe An", value: "22" },
    { text: "Ninh Binh", value: "18" },
    { text: "Ninh Thuan", value: "36" },
    { text: "Phu Tho", value: "68" },
    { text: "Phu Yen", value: "32" },
    { text: "Quang Binh", value: "24" },
    { text: "Quang Nam", value: "27" },
    { text: "Quang Ngai", value: "29" },
    { text: "Quang Ninh", value: "13" },
    { text: "Quang Tri", value: "25" },
    { text: "Soc Trang", value: "52" },
    { text: "Son La", value: "05" },
    { text: "Tay Ninh", value: "37" },
    { text: "Thai Binh", value: "20" },
    { text: "Thai Nguyen", value: "69" },
    { text: "Thanh Hoa", value: "21" },
    { text: "Thua Thien-Hue", value: "26" },
    { text: "Tien Giang", value: "46" },
    { text: "Tra Vinh", value: "51" },
    { text: "Tuyen Quang", value: "07" },
    { text: "Vinh Long", value: "49" },
    { text: "Vinh Phuc", value: "70" },
    { text: "Yen Bai", value: "06" }
  ],
  VI: [{ text: "Virgin Islands, U.S.", value: "01" }],
  WF: [
    { text: "Sigave", value: "SG" },
    { text: "Uvea", value: "UV" }
  ],
  EH: [{ text: "-", value: "01" }],
  YE: [
    { text: "'Adan", value: "AD" },
    { text: "'Amran", value: "AM" },
    { text: "Abyan", value: "AB" },
    { text: "Ad Dali'", value: "DA" },
    { text: "Al Bayda'", value: "BA" },
    { text: "Al Hudaydah", value: "HU" },
    { text: "Al Jawf", value: "JA" },
    { text: "Al Mahrah", value: "MR" },
    { text: "Al Mahwit", value: "MW" },
    { text: "Amanat al 'Asimah", value: "SA" },
    { text: "Dhamar", value: "DH" },
    { text: "Hadramawt", value: "HD" },
    { text: "Ibb", value: "IB" },
    { text: "Lahij", value: "LA" },
    { text: "Ma'rib", value: "MA" },
    { text: "Sa'dah", value: "SD" },
    { text: "San'a'", value: "SN" },
    { text: "Shabwah", value: "SH" },
    { text: "Ta'izz", value: "TA" }
  ],
  ZM: [
    { text: "Central", value: "02" },
    { text: "Copperbelt", value: "08" },
    { text: "Eastern", value: "03" },
    { text: "Luapula", value: "04" },
    { text: "Lusaka", value: "09" },
    { text: "North-Western", value: "06" },
    { text: "Northern", value: "05" },
    { text: "Southern", value: "07" },
    { text: "Western", value: "01" }
  ],
  ZW: [
    { text: "Bulawayo", value: "BU" },
    { text: "Harare", value: "HA" },
    { text: "Manicaland", value: "MA" },
    { text: "Mashonaland Central", value: "MC" },
    { text: "Mashonaland East", value: "ME" },
    { text: "Mashonaland West", value: "MW" },
    { text: "Masvingo", value: "MV" },
    { text: "Matabeleland North", value: "MN" },
    { text: "Matabeleland South", value: "MS" },
    { text: "Midlands", value: "MI" }
  ]
};
