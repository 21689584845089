import React from "react";
import { fallbackValues } from "./TableRow.theme";
import { themeComponent } from "../../../util/themeUtils";
import TableRowWrapper from "./TableRow.styled";

const TableRow = ({
  children,
  extraStyles,
  hoverCursor = false,
  hoverEffect = true,
  onClick,
  themeValues,
  ...props
}) => (
  <TableRowWrapper
    onClick={onClick}
    hoverEffect={hoverEffect}
    hoverCursor={hoverCursor}
    extraStyles={extraStyles}
    borderColor={themeValues.borderColor}
    hoverBackgroundColor={themeValues.hoverBackgroundColor}
    {...props}
  >
    {children}
  </TableRowWrapper>
);

export default themeComponent(TableRow, "TableRow", fallbackValues);
