import React from "react";
import { AnimatePresence } from "framer-motion";
import { Motion } from "@thecb/components";

/* 
  Component meant to be used with the Slider. This is a panel that can be animated onto the screen while the Slider is open. Takes a "status" prop and children. Status corresponds to one of three values: "onScreen", "next", and "offScreen".

  "onScreen" displays within the open Slider element.

  "next" positions the panel off the screen to the right. Changing a status from "next" to "onScreen" will animate the panel into view.

  "offScreen" puts the panel below the slider. Changing a status from "onScreen" to "offScreen" will result in the panel moving downward off the screen.

  Changing a status from "onScreen" to "next" will result in the panel moving off screen to the right.

*/

const SliderPanel = ({ status, sliderKey, children }) => {
  const variants = {
    onScreen: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        delay: 0.2,
        type: "tween",
        ease: "easeIn"
      }
    },
    next: {
      opacity: 0,
      x: "100%",
      y: 0,
      transition: {
        type: "tween",
        ease: "easeOut"
      }
    },
    offScreen: {
      opacity: 0,
      x: 0,
      y: "100%",
      transition: {
        type: "tween",
        ease: "easeOut"
      }
    }
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <Motion
        variants={variants}
        initial="next"
        animate={status}
        exit="offScreen"
        extraStyles={`position: absolute; height: 100%; overflow: auto;`}
        minWidth="100%"
        key={sliderKey}
      >
        {children}
      </Motion>
    </AnimatePresence>
  );
};

export default SliderPanel;
