import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import * as R from "ramda";
import { push } from "connected-react-router";
import {
  SUBMIT_REGISTRATION_FORM,
  registrationSuccess,
  registrationError,
  registrationAlertBarAction
} from "./Registration.state";
import { addAlert } from "../../../../components/alert-bar/AlertBar.state";
import {
  ACCOUNT_VERIFICATION_PENDING,
  populateEmail,
  populateVerificationStatus
} from "../../pages/account-verification/AccountVerification.state";
import { createUser } from "./graphql/Queries";
import {
  getClientSlug,
  getGraphqlServiceEndpoint
} from "../../../../util/state";
import { clearState } from "../../../../state/reducer";

const createUserParams = state => ({
  endpoint: getGraphqlServiceEndpoint(state),
  clientSlug: getClientSlug(state),
  email: state.registration.forms.registrationForm.email.rawValue,
  password: state.registration.forms.registrationForm.password.rawValue,
  firstName: state.registration.forms.registrationForm.firstName.rawValue,
  lastName: state.registration.forms.registrationForm.lastName.rawValue
});

const getEmail = state$ =>
  state$.value.registration.forms.registrationForm.email.rawValue;

const registrationFailedAlert = {
  heading: "Registration Failed",
  text: "An error occurred while trying to register your account.",
  variant: "error"
};

const formatError = error =>
  R.pathOr(error.message, ["response", "statusText"], error);

export const submitRegistrationEpic = (action$, state$) =>
  action$.ofType(SUBMIT_REGISTRATION_FORM).pipe(
    RxOp.mergeMap(() =>
      Rx.from(createUser(createUserParams(state$.value))).pipe(
        RxOp.flatMap(() => {
          const email = getEmail(state$);
          return Rx.of(
            registrationSuccess(),
            clearState(),
            populateEmail(email),
            populateVerificationStatus(ACCOUNT_VERIFICATION_PENDING),
            push("/verify-account")
          );
        }),
        RxOp.catchError(error => {
          return Rx.of(
            registrationError(formatError(error)),
            registrationAlertBarAction(addAlert(registrationFailedAlert))
          );
        })
      )
    )
  );
