import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import { RegistrationForm } from "@thecb/components";
import * as Nested from "../../../../util/Nested";

export const SUBMIT_REGISTRATION_FORM = "registration/SUBMIT_REGISTRATION_FORM";
const submitRegistrationForm = () => ({ type: SUBMIT_REGISTRATION_FORM });

export const REGISTRATION_SUCCESS = "registration/REGISTRATION_SUCCESS";
export const registrationSuccess = () => ({ type: REGISTRATION_SUCCESS });

export const REGISTRATION_ERROR = "registration/REGISTRATION_ERROR";
export const registrationError = error => ({
  type: REGISTRATION_ERROR,
  payload: { error }
});

const initialState = {
  submitting: false,
  error: false
};

export const _reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBMIT_REGISTRATION_FORM:
      return {
        ...state,
        submitting: true
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        submitting: false,
        error: payload
      };
    default:
      return state;
  }
};

export const _mapStateToProps = state => state.registration;
export const _mapDispatchToProps = dispatch => ({
  submitRegistrationForm: () => dispatch(submitRegistrationForm())
});

export const ALERT_BAR_ACTION = "registration/ALERT_BAR_ACTION";
export const registrationAlertBarAction = Nested.nestedAction(ALERT_BAR_ACTION);

export const REGISTRATION_FORM_ACTION = "registration/REGISTRATION_FORM_ACTION";
export const registrationFormAction = Nested.nestedAction(
  REGISTRATION_FORM_ACTION
);

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    forms: {
      registrationForm: {
        ...RegistrationForm,
        actionType: REGISTRATION_FORM_ACTION
      }
    },
    alertBars: {
      registrationAlertBar: {
        ...AlertBarState,
        actionType: ALERT_BAR_ACTION
      }
    }
  },
  "registration"
);

const reducer = (state, action) => nestedReducer(state, action);

export { reducer, mapStateToProps, mapDispatchToProps, actions };
