import styled from "styled-components";

export default styled.tr`
  &:not(:last-child) {
    border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
  }

  ${({ hoverCursor, hoverEffect, hoverBackgroundColor }) =>
    hoverEffect &&
    `&:hover {
    ${hoverCursor && "cursor: pointer"};
    background-color: ${hoverBackgroundColor};
  }`}

  ${({ extraStyles }) => extraStyles}
`;
