const fontFamily = {
  primary: "Public Sans",
  secondary: "Open Sans"
};

const fontSize = {
  h1: "2.25rem",
  h2: "2rem",
  h3: "1.75rem",
  h4: "1.5rem",
  h5: "1.375rem",
  h6: "1.25rem"
};

export const fallbackValues = {
  fontFamily,
  fontSize
};
