import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as R from "ramda";
import { ThemeContext } from "styled-components";
import {
  Box,
  Loading,
  Pagination,
  Reel,
  Stack,
  Title
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "/constants/style_constants";
import { GHOST_GREY, WHITE } from "/constants/colors";
import { TransactionTable } from "./TransactionTable";
import AlertBar from "/components/alert-bar";
import { FETCHED_TRANSACTION_STATUSES } from "/constants/transaction_history_constants";
import { getPageCount, getQueryParam } from "/util/general";

const TransactionHistory = ({
  alertBars,
  actions,
  customerManagement,
  obligationsConfig,
  resources,
  resourcesActions
}) => {
  const PAGE_PARAM_NAME = "page";
  const PER_PAGE = 10;
  const currentPage = parseInt(getQueryParam(PAGE_PARAM_NAME) ?? "1");
  const [pageCount, setPageCount] = useState(0);

  const {
    obligations = {},
    transactionHistory: { pages: { [currentPage]: transactionHistoryPage } } = {
      pages: { [currentPage]: {} }
    }
  } = resources;

  const transactionHistoryFetched = transactionHistoryPage?.isSuccess ?? false;

  const { transactionHistoryAlertBar: alertBar } = alertBars;
  const {
    alertBars: { transactionHistoryAlertBar: alertBarActions }
  } = actions;

  const {
    fetchTransactionHistory,
    navigateToDetailedObligation,
    setDetailedObligation
  } = resourcesActions;

  useEffect(() => {
    setPageCount(getPageCount(transactionHistoryPage?.total, PER_PAGE));
  }, [transactionHistoryPage?.total]);

  const navigate = useNavigate();
  const setCurrentPage = ({ pageNumber }) => {
    navigate({
      page: `/profile/history`,
      search: `?${PAGE_PARAM_NAME}=${pageNumber}`
    });
  };

  useEffect(() => {
    if (!transactionHistoryFetched) {
      fetchTransactionHistory({
        statuses: FETCHED_TRANSACTION_STATUSES,
        page: currentPage,
        perPage: PER_PAGE
      });
    }
  }, [transactionHistoryFetched]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const status = transactionHistoryPage?.isLoading
    ? "loading"
    : transactionHistoryPage?.isSuccess
    ? "success"
    : transactionHistoryPage?.isFailure
    ? "error"
    : "not found";

  const renderPagination = () =>
    pageCount > 1 && (
      <Box
        background={WHITE}
        borderRadius="4px"
        boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
        padding={isMobile ? "16px" : "24px"}
      >
        <Pagination
          activeBorderWidth={isMobile ? "2px" : "3px"}
          childGap="24px"
          borderRadius="4px"
          fontSize={isMobile ? "12px" : "14px"}
          buttonHeight={isMobile ? "32px" : "44px"}
          buttonWidth={isMobile ? "32px" : "44px"}
          fontWeight={FONT_WEIGHT_SEMIBOLD}
          currentPage={currentPage}
          pageCount={pageCount}
          setCurrentPage={setCurrentPage}
          pageNext={() => setCurrentPage({ pageNumber: currentPage + 1 })}
          pagePrevious={() => setCurrentPage({ pageNumber: currentPage - 1 })}
          ariaLabel="Pagination"
        />
      </Box>
    );

  const renderTransactionHistory = transactionHistoryPage => {
    switch (status) {
      case "loading":
        return <Loading />;
      case "success":
        return (
          <>
            <Stack childGap="32px">
              <TransactionTable
                isMobile={isMobile}
                transactions={transactionHistoryPage?.transactions}
                obligations={obligations}
                navigateToDetailedObligation={navigateToDetailedObligation}
                setDetailedObligation={setDetailedObligation}
                obligationsConfig={obligationsConfig}
                customerManagement={customerManagement}
              />
            </Stack>
          </>
        );
      default:
    }
  };

  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Box padding="0 0 16px">
      <Stack fullHeight childGap="16px">
        {!R.isEmpty(alertBar.alerts) && (
          <AlertBar {...alertBar} {...alertBarActions} key="alert-bar" />
        )}
        <Title variant="small" as="h1" weight={FONT_WEIGHT_SEMIBOLD}>
          Transaction History
        </Title>
        <Stack childGap="32px">
          {isMobile ? (
            <Reel padding={"2px"}>
              {renderTransactionHistory(transactionHistoryPage)}
            </Reel>
          ) : (
            renderTransactionHistory(transactionHistoryPage)
          )}
          {renderPagination()}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TransactionHistory;
