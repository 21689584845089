import React, { useEffect, useState, forwardRef } from "react";
import { required } from "redux-freeform";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Cluster, Stack, Text } from "@thecb/components";
import { RED } from "../../constants/colors";
import { FONT_WEIGHT_REGULAR } from "../../constants/style_constants";

const CaptchaForm = forwardRef(
  ({ siteKey, fields, actions, clearOnDismount, showErrors }, ref) => {
    useEffect(() => {
      if (clearOnDismount) {
        return () => actions.form.clear();
      }
    }, []);

    const [captchaError, setError] = useState(false);
    const [expired, setExpired] = useState(false);

    const captchaErrorMessages = {
      [required.error]: "Please complete the challenge"
    };

    return (
      <Cluster justify="flex-end">
        <Stack childGap="4px">
          <HCaptcha
            sitekey={siteKey}
            ref={ref}
            tabIndex="0"
            onError={() => {
              setError(true);
              actions.fields.captchaToken.set("");
            }}
            onExpire={() => {
              setExpired(true);
              actions.fields.captchaToken.set("");
            }}
            onVerify={token => {
              setError(false);
              setExpired(false);
              actions.fields.captchaToken.set(token);
            }}
            aria-label={`HCaptcha widget (all widget links open in a new window)`}
          />
          {(fields.captchaToken.hasErrors && fields.captchaToken.dirty) ||
          (fields.captchaToken.hasErrors && showErrors) ||
          captchaError ||
          expired ? (
            <Text
              color={RED}
              variant="pXS"
              weight={FONT_WEIGHT_REGULAR}
              extraStyles={`word-break: break-word;
              text-align: end;
              font-family: Public Sans;
              &::first-letter {
                text-transform: uppercase;
              }`}
            >
              {expired
                ? "Your validation has expired. Please validate again."
                : captchaError
                ? "Something went wrong. Please refresh and try again."
                : captchaErrorMessages[fields.captchaToken.errors[0]]}
            </Text>
          ) : (
            <Text extraStyles="height: 1rem" />
          )}
        </Stack>
      </Cluster>
    );
  }
);

export default CaptchaForm;
