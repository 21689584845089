import { useEffect } from "react";

const useLogoutTimers = ({
  logoutTimerOn = false,
  action,
  timeLeftToLogout,
  handleSetTimeLeft,
  initialTimeLeftToLogout = 30
}) => {
  let timers = { timer: null, interval: null };
  let timeLeft = timeLeftToLogout;

  useEffect(() => {
    if (logoutTimerOn) {
      timers.timer = window.setTimeout(() => {
        action();
        handleSetTimeLeft(initialTimeLeftToLogout);
      }, 30 * 1000);
    }
    return () => clearTimeout(timers.timer);
  }, [logoutTimerOn]);

  useEffect(() => {
    if (logoutTimerOn) {
      timers.interval = window.setInterval(() => {
        timeLeft--;
        handleSetTimeLeft(timeLeft);
      }, 1000);
    }
    return () => clearInterval(timers.interval);
  }, [logoutTimerOn]);

  return timers;
};

export default useLogoutTimers;
