import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  ButtonWithAction,
  Heading,
  Paragraph,
  Box,
  Stack,
  Center,
  CenterStack,
  NotFoundIcon,
  withWindowSize
} from "@thecb/components";

const NotFound = ({ history }) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  return (
    <CenterStack
      content={
        <Box padding="2rem">
          <Box
            padding={isMobile ? "2rem 0" : "2rem"}
            extraStyles={isMobile && `svg { max-width: 100%; }`}
          >
            <Center intrinsic>
              <NotFoundIcon />
            </Center>
          </Box>
          <Heading variant="h4" textAlign="center" weight="bold">
            Page Not Found
          </Heading>
          <Stack>
            <Center singleChild>
              <Box padding="0.5rem 0 0" maxWidth="37.5rem">
                <Paragraph extraStyles="text-align: center;">
                  {`We're not sure how you got here, but we're pretty positive
                  this page doesn't exist. Check to make sure you entered the
                  right address.`}
                </Paragraph>
              </Box>
            </Center>
            <Center singleChild>
              <Box padding="1rem 0">
                <ButtonWithAction
                  text="Take me back"
                  action={() => history.back()}
                />
              </Box>
            </Center>
          </Stack>
        </Box>
      }
      contentSpacing="0"
      maxContentWidth="100%"
    />
  );
};

export default withWindowSize(NotFound);
