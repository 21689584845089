import React, { Fragment } from "react";
import Modal from "../modal";
import Text from "../../atoms/text";
import Paragraph from "../../atoms/paragraph";
import { Box, Stack, Center } from "../../atoms/layouts";
import ButtonWithAction from "../../atoms/button-with-action/ButtonWithAction";
import { fallbackValues } from "./AccountAndRoutingModal.theme";
import { themeComponent } from "../../../util/themeUtils";
import { AccountNumberImage, RoutingNumberImage } from "../../atoms/icons";

const AccountAndRoutingModal = ({
  link,
  title,
  isOpen,
  toggleOpen,
  toggleAccepted,
  acceptText,
  content,
  imageType,
  themeValues,
  dataQa = null
}) => {
  return (
    <Modal
      modalOpen={isOpen}
      hideModal={() => toggleOpen(false)}
      showModal={() => toggleOpen(true)}
      modalHeaderText={title}
      dataQa={dataQa}
      modalBodyText={
        <Box extraStyles="overflow: scroll; max-height: 20rem;">
          <Stack>
            <Paragraph variant="p">{content}</Paragraph>
            {imageType === "Account" ? (
              <Center intrinsic>
                <AccountNumberImage />
              </Center>
            ) : imageType === "Routing" ? (
              <Center intrinsic>
                <RoutingNumberImage />
              </Center>
            ) : (
              <Fragment />
            )}
          </Stack>
        </Box>
      }
      defaultWrapper={false}
      onlyCloseButton={!acceptText}
      continueButtonText={acceptText}
      continueAction={() => {
        toggleAccepted(true);
        toggleOpen(false);
      }}
      initialFocusSelector={"[name='Close']"}
    >
      <ButtonWithAction
        variant="ghost"
        tabIndex="0"
        onClick={() => toggleOpen(true)}
        onKeyPress={e => e.key === "Enter" && toggleOpen(true)}
        extraStyles="min-width: 0; min-height: 0;margin: 0; padding: 0; text-transform: none;"
        text={
          <Text variant="pS" color="inherit">
            {link}
          </Text>
        }
      />
    </Modal>
  );
};

export default themeComponent(
  AccountAndRoutingModal,
  "AccountAndRoutingModal",
  fallbackValues,
  "default"
);
