const hoverColor = "#116285";
const activeColor = "#0E506D";
const menuTriggerColor = "#15749D";
const backgroundColor = "white";

export const fallbackValues = {
  hoverColor,
  activeColor,
  menuTriggerColor,
  backgroundColor
};
