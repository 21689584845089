import React, { useEffect } from "react";
import {
  required,
  hasLength,
  matchesField,
  hasNumber,
  hasLowercaseLetter,
  hasUppercaseLetter,
  hasSpecialCharacter
} from "redux-freeform";
import {
  FormInput,
  FormContainer,
  FormInputColumn
} from "../../atoms/form-layouts";
import { Box, Cluster, Stack } from "../../atoms/layouts";
import ButtonWithAction from "../../atoms/button-with-action";
import Heading from "../../atoms/heading";
import Text from "../../atoms/text";
import PasswordRequirements from "../../atoms/password-requirements";
import { noop } from "../../../util/general";
import { GHOST_GREY } from "../../../constants/colors";

const ChangePasswordForm = ({
  clearOnDismount,
  fields,
  actions,
  handleSubmit = noop,
  showErrors,
  isMobile,
  revenueManagement,
  closeForm,
  firstName,
  lastName
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }

  const currentPasswordErrorMessages = {
    [required.error]: "Current Password is required"
  };
  const newPasswordErrorMessages = {
    [required.error]: "New Password field is required.",
    [hasLength.error]: "Your new password must have at least 8 characters",
    [hasNumber.error]: "Your new password must contain at least one number",
    [hasLowercaseLetter.error]:
      "Your new password must contain at least one lowercase letter",
    [hasUppercaseLetter.error]:
      "Your new password must contain at least one uppercase letter",
    [hasSpecialCharacter.error]:
      "Your new password must contain at least one special character (!@#$%^&*.?)"
  };
  const confirmNewPasswordErrorMessages = {
    [matchesField.error]: "Confirm password must match your new password"
  };

  return (
    <FormContainer
      role="form"
      aria-label="Change password"
      extraStyles={`padding: 0;`}
    >
      {revenueManagement && (
        <Box
          padding="1.5rem"
          borderColor={GHOST_GREY}
          borderSize="1px"
          borderWidthOverride={"0 0 1px 0"}
        >
          <Cluster justify="flex-start" align="center">
            <Box padding="1.25rem" borderRadius="50%" background="#CACED8">
              <Heading variant="h4" weight="700">
                {firstName.charAt(0).toUpperCase()}
                {lastName.charAt(0).toUpperCase()}
              </Heading>
            </Box>
            <Text variant="p" weight="700" extraStyles={`padding-left: 1rem;`}>
              {firstName} {lastName}
            </Text>
          </Cluster>
        </Box>
      )}
      <Box padding="1.5rem">
        <FormInputColumn>
          <FormInput
            labelTextWhenNoError="Current password"
            errorMessages={currentPasswordErrorMessages}
            field={fields.currentPassword}
            fieldActions={actions.fields.currentPassword}
            showErrors={showErrors}
            onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
            type="password"
            autocompleteValue="current-password"
          />
          <FormInput
            labelTextWhenNoError="New password"
            errorMessages={newPasswordErrorMessages}
            field={fields.newPassword}
            fieldActions={actions.fields.newPassword}
            showErrors={showErrors}
            onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
            type="password"
            autocompleteValue="new-password"
          />
          <FormInput
            labelTextWhenNoError="Confirm password"
            errorMessages={confirmNewPasswordErrorMessages}
            field={fields.confirmNewPassword}
            fieldActions={actions.fields.confirmNewPassword}
            showErrors={showErrors}
            onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
            type="password"
            autocompleteValue="new-password"
          />
        </FormInputColumn>
        <Box padding={isMobile ? "1rem 0 0" : "1.5rem 0 0"}>
          <Cluster justify="center" align="center">
            <PasswordRequirements
              password={fields.newPassword}
              isMobile={isMobile}
            />
          </Cluster>
        </Box>
        {revenueManagement && (
          <Box padding="1.5rem 0 0">
            <Stack childGap="1rem" direction="row" justify="flex-end">
              <ButtonWithAction
                text="Cancel"
                action={closeForm}
                variant={"secondary"}
                dataQa="Cancel"
                extraStyles={`margin: 0rem; padding: 0.75rem 1.5rem; border-radius: 4px;`}
              />
              <ButtonWithAction
                text="Save"
                action={handleSubmit}
                variant="primary"
                dataQa="Save"
                extraStyles={`margin: 0rem; padding: 0.75rem 1.5rem; border-radius: 4px;`}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </FormContainer>
  );
};

export default ChangePasswordForm;
