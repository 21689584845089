import React from "react";
import VerifyAccount from "./sub-pages/verify-account";
import AccountVerificationSuccess from "./sub-pages/account-verification-success";

export const VERIFY_ACCOUNT_INDEX = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "/success";

export const routes = {
  [VERIFY_ACCOUNT_INDEX]: props => <VerifyAccount {...props} />,
  [VERIFY_ACCOUNT_SUCCESS]: props => <AccountVerificationSuccess {...props} />
};
