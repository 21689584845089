import React, { useState, useMemo, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Sidebar,
  Paragraph,
  Heading,
  Checkbox,
  ButtonWithAction,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import SearchResults from "../../search-results";

const RelatedContentBlock = ({
  retrieveRelatedContent,
  relatedContent,
  title,
  tags,
  blockPadding = "40px 0",
  themeValues,
  meta,
  pageSlug
}) => {
  const { isMobile } = useContext(ThemeContext);
  const { retrievingRelatedContent, content } = useMemo(() => {
    return (
      relatedContent[title] || { retrievingRelatedContent: true, content: null }
    );
  }, [title, relatedContent]);
  const initialSearchQuery = tags.map(tag => ({ _search: `${tag.tagName}` }));
  const [selectedFilters, setSelectedFilters] = useState([]);
  const sectionNames = tags.map(tag => tag.sectionName).sort();
  const sectionTagMap = tags.reduce((acc, curr) => {
    const { sectionName, tagName } = curr;
    const currentTags = acc?.[sectionName] ?? [];
    return {
      ...acc,
      [sectionName]: [...currentTags, tagName].sort()
    };
  }, {});
  const sortedSectionTags = sectionNames.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: sectionTagMap[curr]
    }),
    {}
  );
  const contentPages =
    content?.tagsConnection?.edges
      ?.map(edge => [
        ...edge.node.actionPage,
        ...edge.node.landingPage,
        ...edge.node.sectionPage,
        ...edge.node.subjectPage
      ])
      .reduce((acc, curr) => [...acc, ...curr], [])
      .reduce((acc, curr) => {
        return curr.slug[0]?.slug
          ? {
              ...acc,
              [curr.slug[0].slug]: curr
            }
          : {
              ...acc
            };
      }, {}) ?? {};

  useEffect(() => {
    retrieveRelatedContent(initialSearchQuery, meta, title, pageSlug);
  }, [window.location.href]);

  const isFilterSelected = filter => selectedFilters.includes(filter);

  return (
    <Box width="100%" padding={blockPadding} borderRadius="4px">
      <Stack childGap="2rem">
        <Box padding="0">
          <Stack childGap="0.5rem">
            <Box
              padding="0"
              minHeight="5px"
              width={isMobile ? "24px" : "50px"}
              borderSize={isMobile ? "3px" : "6px"}
              borderColor={themeValues.lightAccentText}
              borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
              key="accent"
            />
            <Heading
              variant="h3"
              color={themeValues.darkText}
              key="heading"
              extraStyles={`margin-top: 16px;`}
              weight={FONT_WEIGHT_SEMIBOLD}
            >
              {title}
            </Heading>
          </Stack>
        </Box>
        <Box padding="0">
          <Sidebar childGap="2rem" fullHeight width="255px">
            <Box
              padding="0 2rem 0 0"
              borderColor={"#cfd4de"}
              borderSize="1px"
              borderWidthOverride="0 1px 0 0"
            >
              <Stack childGap="2rem">
                {Object.entries(sortedSectionTags).map(
                  ([section, values], index) => (
                    <Box padding="0" key={`section-${section}-${index}`}>
                      <Stack>
                        <Box padding="0">
                          <Paragraph
                            variant="pL"
                            weight={FONT_WEIGHT_SEMIBOLD}
                            color={themeValues.darkText}
                          >
                            {section}
                          </Paragraph>
                        </Box>
                        <Stack>
                          {values.map((value, index) => (
                            <Box padding="0" key={`value-${value}-${index}`}>
                              <Checkbox
                                title={value}
                                name={value}
                                checked={isFilterSelected(value)}
                                onChange={() => {
                                  const combinedFilters = [
                                    ...selectedFilters,
                                    value
                                  ];
                                  setSelectedFilters(combinedFilters);
                                  const searchQueries = combinedFilters.map(
                                    filter => ({
                                      _search: filter
                                    })
                                  );
                                  retrieveRelatedContent(
                                    searchQueries,
                                    meta,
                                    title,
                                    pageSlug
                                  );
                                }}
                                themeValues={themeValues}
                              />
                            </Box>
                          ))}
                        </Stack>
                      </Stack>
                    </Box>
                  )
                )}
                <Box padding="0" minWidth="100%">
                  <ButtonWithAction
                    variant="secondary"
                    text="Clear Filters"
                    extraStyles={`width: 100%;`}
                    action={() => {
                      setSelectedFilters([]);
                      retrieveRelatedContent(
                        initialSearchQuery,
                        meta,
                        title,
                        pageSlug
                      );
                    }}
                  />
                </Box>
              </Stack>
            </Box>
            <SearchResults
              pages={contentPages}
              isLoading={retrievingRelatedContent}
              themeValues={themeValues}
            />
          </Sidebar>
        </Box>
      </Stack>
    </Box>
  );
};

export default withWindowSize(RelatedContentBlock);
