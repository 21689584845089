import React from "react";

const ChargebackIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -160.000000)"
        fill="#B34A00"
      >
        <path
          d="M80,192 C88.836556,192 96,184.836556 96,176 C96,167.163444 88.836556,160 80,160 C71.163444,160 64,167.163444 64,176 C64,184.836556 71.163444,192 80,192 Z M89,170 C89.5522847,170 90,170.447715 90,171 L90,181 C90,181.552285 89.5522847,182 89,182 L75,182 C74.4477153,182 74,181.552285 74,181 L74,171 C74,170.447715 74.4477153,170 75,170 L89,170 Z M89,176 L75,176 L75,181 L89,181 L89,176 Z M71.8535534,172.853553 C71.9473216,172.947322 72,173.074499 72,173.207107 L72,178.792893 C72,179.069036 71.7761424,179.292893 71.5,179.292893 C71.3673918,179.292893 71.2402148,179.240215 71.1464466,179.146447 L68.3535534,176.353553 C68.1582912,176.158291 68.1582912,175.841709 68.3535534,175.646447 L71.1464466,172.853553 C71.3417088,172.658291 71.6582912,172.658291 71.8535534,172.853553 Z M86.75,177.75 C87.0261424,177.75 87.25,177.973858 87.25,178.25 L87.25,178.75 C87.25,179.026142 87.0261424,179.25 86.75,179.25 L84.75,179.25 C84.4738576,179.25 84.25,179.026142 84.25,178.75 L84.25,178.25 C84.25,177.973858 84.4738576,177.75 84.75,177.75 L86.75,177.75 Z M89,171 L75,171 L75,173.5 L89,173.5 L89,171 Z"
          id="status-icon---chargeback"
        ></path>
      </g>
    </g>
  </svg>
);

export default ChargebackIcon;
