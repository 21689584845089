import { MATISSE_BLUE, CHARADE_GREY } from "../../../constants/colors";

const fontFamily = "Public Sans, sans-serif";
const activeColor = MATISSE_BLUE;
const linkColor = CHARADE_GREY;

export const fallbackValues = {
  fontFamily,
  activeColor,
  linkColor
};
