import React from "react";
import { CHARADE_GREY } from "../../../constants/colors";

const PersonIcon = ({
  width = "20",
  height = "21",
  color = CHARADE_GREY,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 4.8125H4.75C4.50838 4.8125 4.3125 5.00838 4.3125 5.25V15.75C4.3125 15.9916 4.50838 16.1875 4.75 16.1875H5.625V14.7778C5.625 12.8985 7.02411 11.375 8.75 11.375H11.25C12.9759 11.375 14.375 12.8985 14.375 14.7778V16.1875H15.25C15.4916 16.1875 15.6875 15.9916 15.6875 15.75V5.25C15.6875 5.00838 15.4916 4.8125 15.25 4.8125ZM4.75 17.5H5.625H14.375H15.25C16.2165 17.5 17 16.7165 17 15.75V5.25C17 4.2835 16.2165 3.5 15.25 3.5H4.75C3.7835 3.5 3 4.2835 3 5.25V15.75C3 16.7165 3.7835 17.5 4.75 17.5ZM10 10.5C11.2081 10.5 12.1875 9.52062 12.1875 8.3125C12.1875 7.10438 11.2081 6.125 10 6.125C8.79188 6.125 7.8125 7.10438 7.8125 8.3125C7.8125 9.52062 8.79188 10.5 10 10.5Z"
        fill={color}
      />
    </svg>
  );
};
export default PersonIcon;
