// import theme from "styled-theming";
import { colors, styleConstants } from "../../../constants";

const { MATISSE_BLUE, STORM_GREY } = colors;
const { LINK_TEXT_DECORATION } = styleConstants;

/*
  For now I'm using string values, eventually shared components library will have its own constants file
  for colors/values that should be used here instead
*/

const color = `${MATISSE_BLUE}`;
const activeColor = `${STORM_GREY}`;
const activeBreadcrumbColor = `${STORM_GREY}`;
const fontSize = "0.875rem";
const lineHeight = "1.25rem";
const fontWeight = "400";
const margin = "0.5rem";
const hover = `text-decoration: ${LINK_TEXT_DECORATION};`;

export const fallbackValues = {
  color,
  activeColor,
  activeBreadcrumbColor,
  fontSize,
  lineHeight,
  fontWeight,
  margin,
  hover
};
