import React from "react";

const KebabMenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="32"
      viewBox="0 0 21 32"
      fill="none"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0L17 0C19.2091 0 21 1.79086 21 4V28C21 30.2091 19.2091 32 17 32H4C1.79086 32 0 30.2091 0 28L0 4Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6C9.39333 6 8.5 6.89333 8.5 8C8.5 9.10667 9.39333 10 10.5 10C11.6067 10 12.5 9.10667 12.5 8C12.5 6.89333 11.6067 6 10.5 6Z"
        fill="#3B5BDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 14C9.39333 14 8.5 14.8933 8.5 16C8.5 17.1067 9.39333 18 10.5 18C11.6067 18 12.5 17.1067 12.5 16C12.5 14.8933 11.6067 14 10.5 14Z"
        fill="#3B5BDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 22C9.39333 22 8.5 22.9067 8.5 24C8.5 25.0933 9.40667 26 10.5 26C11.5933 26 12.5 25.0933 12.5 24C12.5 22.9067 11.6067 22 10.5 22Z"
        fill="#3B5BDB"
      />
    </svg>
  );
};

export default KebabMenuIcon;
