import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box, Stack, Center, Cover } from "../../atoms/layouts";
import { TimeoutImage } from "../../atoms/icons";
import ButtonWithAction from "../../atoms/button-with-action";
import Heading from "../../atoms/heading";
import { FONT_WEIGHT_BOLD } from "../../../constants/style_constants";
import Paragraph from "../../atoms/paragraph";
import withWindowSize from "../../withWindowSize";

const Timeout = ({ onLogout }) => {
  const { isMobile } = useContext(ThemeContext);
  return (
    <Cover>
      <Center intrinsic>
        <Box padding="0" maxWidth={isMobile ? "100%" : "500px"}>
          <Stack childGap="32px" justify="center">
            <Box padding={isMobile ? "1rem" : "0"}>
              <TimeoutImage />
            </Box>
            <Box padding="0">
              <Heading textAlign="center" weight={FONT_WEIGHT_BOLD}>
                Request Timed Out
              </Heading>
              <Paragraph variant="pL" extraStyles={`text-align: center;`}>
                That was taking longer than we expected and your request timed
                out. Please log out, log back in, and then try again.
              </Paragraph>
            </Box>
            <Center intrinsic>
              <Box padding="0">
                <ButtonWithAction
                  variant="primary"
                  text="Log out"
                  action={onLogout}
                />
              </Box>
            </Center>
          </Stack>
        </Box>
      </Center>
    </Cover>
  );
};

export default withWindowSize(Timeout);
