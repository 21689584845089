import React from "react";

import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./FooterWithSubfooter.theme";
import NavFooter from "../../atoms/nav-footer";

const FooterWithSubfooter = ({
  footerLargeSide = "right",
  footerLargeSideSize = "2",
  leftFooterContent,
  rightFooterContent,
  leftSubfooterContent,
  rightSubfooterContent,
  themeValues
}) => {
  return (
    <>
      <NavFooter
        largeSide={footerLargeSide}
        largeSideSize={footerLargeSideSize}
        rightContent={rightFooterContent}
        leftContent={leftFooterContent}
        aria-label="footer"
        backgroundColor={themeValues.footerBackgroundColor}
      />
      <NavFooter
        backgroundColor={themeValues.subfooterBackgroundColor}
        footerMinHeight="45px"
        footerPadding="0 16px"
        aria-label="subfooter"
        leftContent={leftSubfooterContent}
        rightContent={rightSubfooterContent}
      ></NavFooter>
    </>
  );
};

export default themeComponent(
  FooterWithSubfooter,
  "FooterWithSubfooter",
  fallbackValues
);
