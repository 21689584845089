import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const EmptyCartIconV2 = ({ themeValues, color, height = "auto", ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="113"
    viewBox="0 0 126 113"
    style={{ height, width: "auto" }}
    {...rest}
  >
    <title>Cart-Empty-Illustration</title>
    <g id="Empty Shopping Cart Icon">
      <path
        id="Empty-shopping-cart"
        d="M115.523 66.3438C115.086 67.6562 114.43 68.75 113.555 69.4062C112.68 70.2812 111.586 70.5 110.492 70.5H46.3984L47.7109 77.5H106.555C108.086 77.5 109.398 78.1562 110.492 79.4688C111.367 80.7812 111.805 82.3125 111.586 83.8438L110.273 89.3125C112.461 90.4062 113.992 91.9375 115.305 93.9062C116.617 95.875 117.273 98.0625 117.273 100.25C117.273 103.75 115.961 106.594 113.555 109C111.148 111.406 108.305 112.5 105.023 112.5C101.523 112.5 98.6797 111.406 96.2734 109C93.8672 106.594 92.7734 103.75 92.7734 100.25C92.7734 96.75 93.8672 93.9062 96.4922 91.5H50.5547C52.9609 93.9062 54.2734 96.75 54.2734 100.25C54.2734 103.75 52.9609 106.594 50.5547 109C48.1484 111.406 45.3047 112.5 42.0234 112.5C38.5234 112.5 35.6797 111.406 33.2734 109C30.8672 106.594 29.7734 103.75 29.7734 100.25C29.7734 98.0625 30.2109 96.0938 31.3047 94.125C32.3984 92.375 33.9297 90.8438 35.8984 89.5312L20.5859 14.5H5.27344C3.74219 14.5 2.42969 14.0625 1.55469 12.9688C0.460938 12.0938 0.0234375 10.7812 0.0234375 9.25V5.75C0.0234375 4.4375 0.460938 3.125 1.55469 2.03125C2.42969 1.15625 3.74219 0.5 5.27344 0.5H27.8047C28.8984 0.5 29.9922 0.9375 30.8672 1.8125C31.7422 2.6875 32.3984 3.5625 32.8359 4.65625L34.8047 14.5H120.773C122.523 14.5 123.836 15.1562 124.93 16.4688C125.805 17.7812 126.242 19.3125 125.805 20.8438L115.523 66.3438Z"
        fill={color ?? themeValues.subIconColor}
      />
    </g>
  </svg>
);

export default themeComponent(EmptyCartIconV2, "Icons", fallbackValues, "info");
