import React from "react";
import { v4 as uuidv4 } from "uuid";

const SearchIcon = ({ color = "#3B5BDB", size = 21 }) => {
  const height = size;
  const width = size - Math.ceil(20 / 21);
  const maskWidth = Math.ceil(width * 0.75);
  const maskHeight = Math.ceil(height * 0.66);
  const maskId = `mask_${uuidv4()}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 20 21`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4142 4.83336C13.1295 6.54863 13.338 9.20007 12.0397 11.1429L15.9968 15.1C16.1921 15.2953 16.1921 15.6119 15.9968 15.8071L15.4665 16.3374C15.2712 16.5327 14.9546 16.5327 14.7594 16.3374L10.8287 12.4068C8.87269 13.8395 6.1108 13.6721 4.34315 11.9044C2.39053 9.95181 2.39053 6.78598 4.34315 4.83336C6.29577 2.88074 9.4616 2.88074 11.4142 4.83336ZM10.4246 10.9148C11.7915 9.548 11.7915 7.33193 10.4246 5.96509C9.05779 4.59826 6.84171 4.59826 5.47488 5.96509C4.10804 7.33193 4.10804 9.548 5.47488 10.9148C6.84171 12.2817 9.05779 12.2817 10.4246 10.9148Z"
        fill={color}
      />
      <mask
        id={maskId}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width={maskWidth}
        height={maskHeight}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4142 4.83336C13.1295 6.54863 13.338 9.20007 12.0397 11.1429L15.9968 15.1C16.1921 15.2953 16.1921 15.6119 15.9968 15.8071L15.4665 16.3374C15.2712 16.5327 14.9546 16.5327 14.7594 16.3374L10.8287 12.4068C8.87269 13.8395 6.1108 13.6721 4.34315 11.9044C2.39053 9.95181 2.39053 6.78598 4.34315 4.83336C6.29577 2.88074 9.4616 2.88074 11.4142 4.83336ZM10.4246 10.9148C11.7915 9.548 11.7915 7.33193 10.4246 5.96509C9.05779 4.59826 6.84171 4.59826 5.47488 5.96509C4.10804 7.33193 4.10804 9.548 5.47488 10.9148C6.84171 12.2817 9.05779 12.2817 10.4246 10.9148Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect y="0.490234" width={size} height={size} fill={color} />
      </g>
    </svg>
  );
};

export default SearchIcon;
