import { IconWarn } from "./IconWarn";
import { IconCheck } from "./IconCheck";
import { IconCheckEmail } from "./IconCheckEmail";
import { IconEmailVerified } from "./IconEmailVerified";
import { IconEye } from "./IconEye";
import { IconEyeSlash } from "./IconEyeSlash";
import { IconChevron } from "./IconChevron";
import { IconNeutral } from "./IconNeutral";
import { IconValid } from "./IconValid";
import { IconInvalid } from "./IconInvalid";

import { AlertInfoIcon } from "./AlertInfoIcon";
import { AlertErrorIcon } from "./AlertErrorIcon";
import { AlertSuccessIcon } from "./AlertSuccessIcon";
import { AlertWarningIcon } from "./AlertWarningIcon";
import { IconQuit } from "./IconQuit";

const AlertIcons = {
  error: AlertErrorIcon,
  info: AlertInfoIcon,
  success: AlertSuccessIcon,
  warn: AlertWarningIcon
};

export {
  IconWarn,
  IconCheck,
  IconCheckEmail,
  IconEmailVerified,
  IconEye,
  IconEyeSlash,
  IconChevron,
  IconNeutral,
  IconValid,
  IconInvalid,
  AlertIcons,
  IconQuit
};
