import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { tint } from "polished";
import {
  Box,
  Detail,
  Stack,
  Cluster,
  Switcher,
  Paragraph,
  Text,
  withWindowSize,
  ExternalLink,
  constants
} from "@thecb/components";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import { getCallToActionInfo } from "../../../../util/dataAdapters";

const FeaturedActivitiesBlock = ({
  title,
  description,
  linkLists,
  blockPadding = "40px 0",
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const textColor = themeValues.linkColor;
  const { FONT_WEIGHT_SEMIBOLD } = constants.fontWeights;
  const hoverStyles = `
    outline: none;
    box-shadow: 0px 4px 4px rgba(41, 42, 51, 0.15), 0px 1px 7px rgba(41, 42, 51, 0.2), 0px 7px 12px rgba(41, 42, 51, 0.15);
  `;

  const renderFeaturedListName = name => (
    <Paragraph
      as="h3"
      variant="pXL"
      weight={FONT_WEIGHT_SEMIBOLD}
      color={textColor}
    >
      {name}
    </Paragraph>
  );

  const rendeFeauredListNameLink = (name, nameUrl) => (
    <ExternalLink href={nameUrl} extraStyles="text-decoration: none;">
      <Cluster justify="space-between" nowrap align="center" childGap={"1px"}>
        <Text
          as="h4"
          variant="pXL"
          weight={FONT_WEIGHT_SEMIBOLD}
          color={textColor}
        >
          {name}
        </Text>
        <RightArrowIcon color={textColor} size={20} />
      </Cluster>
    </ExternalLink>
  );

  const maxCardsInRow = Math.min(linkLists.length, 4);
  const breakPoint = Math.max(
    maxCardsInRow * 270 + (maxCardsInRow - 1) * 16,
    1
  );

  return (
    <Box padding={blockPadding} minWidth="100%">
      <Stack childGap="2.5rem">
        <Box padding="0">
          <Stack childGap="1rem">
            <Box
              padding="0"
              minHeight="5px"
              width={isMobile ? "24px" : "50px"}
              borderSize={isMobile ? "3px" : "6px"}
              borderColor={themeValues.lightAccentText}
              borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
              key="featured-activities-accent"
            />
            <Detail
              as="h3"
              color={constants.colors.CHARADE_GREY}
              variant="pL"
              extraStyles={`margin-top: 0.5rem; font-size: 1.75rem;`}
              weight={FONT_WEIGHT_SEMIBOLD}
            >
              {title}
            </Detail>
            <Box padding="0">
              <Paragraph color={themeValues.darkText}>{description}</Paragraph>
            </Box>
          </Stack>
        </Box>

        <Box padding="0" minHeight="100%">
          <Switcher
            breakpoint={`${breakPoint}px`}
            childGap={isMobile ? "1rem" : "3rem"}
          >
            {linkLists.map(
              ({ name, nameUrl, callToActionLink, links }, index) => {
                const { ctaUrl } = getCallToActionInfo(
                  callToActionLink,
                  "",
                  nameUrl
                );
                return (
                  <Box
                    padding={0}
                    key={`list-${index}`}
                    hoverStyles={hoverStyles}
                  >
                    <Box
                      padding={
                        isMobile
                          ? "20px 24px 24px 24px"
                          : "1.75rem 2rem 2rem 2rem"
                      }
                      background={tint(0.9, themeValues.linkColor)}
                      boxShadow={`0px 2px 6px rgba(41, 42, 51, 0.2), 0px 1px 2px rgba(41, 42, 51, 0.1), inset 0px 1px 0px rgba(41, 42, 51, 0.1);`}
                      borderRadius="4px"
                      minHeight="100%"
                      extraStyles={`border-top: 4px solid ${themeValues.linkColor};`}
                    >
                      <Stack
                        fullHeight
                        bottomItem="3"
                        childGap={isMobile ? "1.15rem" : "1.5rem"}
                      >
                        <Box padding="0" minWidth="100%">
                          {ctaUrl
                            ? rendeFeauredListNameLink(name, ctaUrl)
                            : renderFeaturedListName(name)}
                        </Box>
                        <Stack childGap={isMobile ? "1.1rem" : "1rem"}>
                          {links.map(({ text, externalUrl }, index) => (
                            <Box padding="0" key={`link-${index}`}>
                              <ExternalLink href={externalUrl}>
                                <Text variant="pL" color={textColor}>
                                  {text}
                                </Text>
                              </ExternalLink>
                            </Box>
                          ))}
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                );
              }
            )}
          </Switcher>
        </Box>
      </Stack>
    </Box>
  );
};

export default withWindowSize(FeaturedActivitiesBlock);
