import React from "react";

const KioskImage = () => (
  <svg
    width={191}
    height={96}
    viewBox="0 0 191 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4433 28.1096C12.4164 28.11 12.3894 28.1102 12.3624 28.1102C9.63896 28.1102 7.43115 25.9085 7.43115 23.1926C7.43115 20.4766 9.63896 18.2749 12.3624 18.2749C13.4748 18.2749 14.5012 18.6422 15.3263 19.2619C16.1831 16.4509 18.8028 14.4053 21.9017 14.4053C25.4863 14.4053 28.4299 17.1426 28.7452 20.6355C29.4629 20.0662 30.3716 19.726 31.3599 19.726C33.6816 19.726 35.5636 21.6029 35.5636 23.9181C35.5636 26.2334 33.6816 28.1102 31.3599 28.1102C31.3058 28.1102 31.2519 28.1092 31.1983 28.1072V28.1105H12.4433V28.1096Z"
      fill="#3B5BDB"
      fillOpacity={0.3}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.077 59.7212C17.0639 59.7214 17.0507 59.7215 17.0375 59.7215C15.7078 59.7215 14.6299 58.6466 14.6299 57.3205C14.6299 55.9945 15.7078 54.9196 17.0375 54.9196C17.5806 54.9196 18.0817 55.0989 18.4845 55.4014C18.9029 54.029 20.1819 53.0303 21.6949 53.0303C23.4451 53.0303 24.8824 54.3669 25.0362 56.0724C25.3866 55.7944 25.8302 55.6283 26.3128 55.6283C27.4463 55.6283 28.3652 56.5447 28.3652 57.6751C28.3652 58.8054 27.4463 59.7218 26.3128 59.7218C26.2864 59.7218 26.26 59.7213 26.2339 59.7203V59.7217H17.077V59.7212Z"
      fill="#3B5BDB"
      fillOpacity={0.3}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8554 45.6714C51.8755 45.6718 51.8958 45.6719 51.916 45.6719C53.9561 45.6719 55.6099 43.9769 55.6099 41.886C55.6099 39.7952 53.9561 38.1002 51.916 38.1002C51.0827 38.1002 50.3139 38.383 49.6959 38.86C49.0541 36.6959 47.0917 35.1211 44.7705 35.1211C42.0852 35.1211 39.8802 37.2286 39.6442 39.9179C39.1066 39.4796 38.4259 39.2177 37.6855 39.2177C35.9465 39.2177 34.5367 40.6626 34.5367 42.445C34.5367 44.2274 35.9465 45.6723 37.6855 45.6723C37.7261 45.6723 37.7665 45.6715 37.8066 45.67V45.6721H51.8554V45.6714Z"
      fill="#3B5BDB"
      fillOpacity={0.3}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.742 16.4655C150.66 16.4725 150.577 16.476 150.493 16.476C148.894 16.476 147.597 15.183 147.597 13.588C147.597 11.993 148.894 10.7 150.493 10.7C150.688 10.7 150.878 10.7192 151.062 10.7558C151.584 8.82245 153.354 7.39941 155.457 7.39941C157.417 7.39941 159.087 8.63418 159.728 10.366C159.937 10.263 160.173 10.2052 160.422 10.2052C161.008 10.2052 161.518 10.5242 161.789 10.9973C162.175 10.807 162.61 10.7 163.07 10.7C164.669 10.7 165.966 11.993 165.966 13.588C165.966 15.183 164.669 16.476 163.07 16.476C163.042 16.476 163.015 16.4756 162.987 16.4748V16.476H150.742V16.4655Z"
      fill="#3B5BDB"
      fillOpacity={0.3}
    />
    <path
      d="M115.317 86.6337C115.317 88.8967 104.441 90.7313 91.0246 90.7313C77.6081 90.7313 66.7319 88.8967 66.7319 86.6337C66.7319 84.3707 77.6081 82.5361 91.0246 82.5361C104.441 82.5361 115.317 84.3707 115.317 86.6337Z"
      fill="#3B5BDB"
      fillOpacity={0.3}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.656 5.85669C66.0341 5.85669 64.3904 5.64884 64.3904 8.59922V75.4381C64.3904 78.3885 65.9995 78.4389 67.6214 78.4389H115.307C116.929 78.4389 118.244 78.3885 118.244 75.4381L118.209 8.85746C118.209 5.90708 116.271 5.85669 114.649 5.85669H67.656Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.209 8.85746C118.209 5.90708 116.271 5.85669 114.649 5.85669H67.656C67.5556 5.85669 67.455 5.85589 67.3548 5.85509C65.8368 5.84305 64.3904 5.83158 64.3904 8.59922V75.4381C64.3904 78.3885 65.9995 78.4389 67.6214 78.4389H115.307C116.929 78.4389 118.244 78.3885 118.244 75.4381L118.209 8.85746ZM115.903 75.4381L115.868 8.85746C115.868 8.55226 115.836 8.36846 115.811 8.27076C115.764 8.25927 115.696 8.24597 115.602 8.23432C115.354 8.20357 115.063 8.19815 114.649 8.19815H67.656C67.5462 8.19815 67.4378 8.19729 67.3401 8.19651L67.3335 8.19646C67.2309 8.19565 67.1397 8.19495 67.051 8.19498C66.9336 8.19503 66.8349 8.19644 66.7493 8.19961C66.7392 8.30204 66.7318 8.43357 66.7318 8.59922V75.4381C66.7318 75.7386 66.7553 75.9409 66.7793 76.0687C66.9759 76.092 67.2256 76.0974 67.6214 76.0974H115.307C115.552 76.0974 115.729 76.0952 115.866 76.0889C115.886 75.9395 115.903 75.7279 115.903 75.4381Z"
      fill="#292A33"
    />
    <path
      d="M70.8725 12.8778C70.8727 12.5547 71.1347 12.293 71.4578 12.293H105.366C105.689 12.293 105.951 12.555 105.951 12.8783V63.8052C105.951 64.1285 105.689 64.3905 105.366 64.3905H71.4152C71.0917 64.3905 70.8296 64.1282 70.8298 63.8047L70.8725 12.8778Z"
      fill="#EBEFFB"
    />
    <rect
      x={162.573}
      y={70.0283}
      width={12.5769}
      height={2.64072}
      rx={1.32036}
      fill="#292A33"
    />
    <path
      d="M117.073 70.2441H157.463C158.11 70.2441 158.634 70.7683 158.634 71.4149V71.4149C158.634 72.0614 158.11 72.5856 157.463 72.5856H117.073V70.2441Z"
      fill="#292A33"
    />
    <path
      d="M28.6829 71.4149C28.6829 70.7683 29.207 70.2441 29.8536 70.2441H66.1463V72.5856H29.8536C29.207 72.5856 28.6829 72.0614 28.6829 71.4149V71.4149Z"
      fill="#292A33"
    />
    <rect
      x={17.4429}
      y={70.0283}
      width={8.10877}
      height={2.64072}
      rx={1.32036}
      fill="#292A33"
    />
    <rect
      x={93.0732}
      y={70.2441}
      width={8.10877}
      height={2.64072}
      rx={1.32036}
      fill="#292A33"
    />
    <rect
      x={112.689}
      y={56.1953}
      width={8.10877}
      height={2.64072}
      rx={1.32036}
      transform="rotate(90 112.689 56.1953)"
      fill="#292A33"
    />
    <rect
      x={104.781}
      y={70.2441}
      width={8.10877}
      height={2.64072}
      rx={1.32036}
      fill="#292A33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.4624 31.5838C95.8425 31.5838 95.2796 32.0356 95.1249 32.7055C95.0174 33.1711 94.5519 33.4615 94.0853 33.3542C93.6187 33.2469 93.3276 32.7825 93.4352 32.3169C93.7555 30.9299 94.9667 29.8535 96.4624 29.8535C97.9582 29.8535 99.1694 30.9299 99.4897 32.3169C99.5972 32.7825 99.3062 33.2469 98.8396 33.3542C98.3729 33.4615 97.9075 33.1711 97.8 32.7055C97.6452 32.0356 97.0824 31.5838 96.4624 31.5838Z"
      fill="#3B5BDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.3179 31.5838C79.6979 31.5838 79.1351 32.0356 78.9804 32.7055C78.8728 33.1711 78.4074 33.4615 77.9408 33.3542C77.4742 33.2469 77.1831 32.7825 77.2907 32.3169C77.611 30.9299 78.8221 29.8535 80.3179 29.8535C81.8137 29.8535 83.0248 30.9299 83.3452 32.3169C83.4527 32.7825 83.1616 33.2469 82.695 33.3542C82.2284 33.4615 81.763 33.1711 81.6554 32.7055C81.5007 32.0356 80.9379 31.5838 80.3179 31.5838Z"
      fill="#3B5BDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.8702 42.6553C83.349 42.6553 83.7372 43.0426 83.7372 43.5204C83.7372 44.2447 84.3256 44.8319 85.0514 44.8319H91.7293C92.4551 44.8319 93.0435 44.2447 93.0435 43.5204C93.0435 43.0426 93.4317 42.6553 93.9105 42.6553C94.3894 42.6553 94.7775 43.0426 94.7775 43.5204C94.7775 45.2003 93.4128 46.5622 91.7293 46.5622H85.0514C83.3679 46.5622 82.0032 45.2003 82.0032 43.5204C82.0032 43.0426 82.3914 42.6553 82.8702 42.6553Z"
      fill="#3B5BDB"
    />
    <g filter="url(#filter0_d_4997_39198)">
      <path
        d="M118.244 20.1895H151.308C153.794 20.1895 155.809 22.2045 155.809 24.6903V24.9302C155.809 27.416 153.794 29.4311 151.308 29.4311H118.244V20.1895Z"
        fill="white"
      />
      <rect
        x={144.722}
        y={23.8203}
        width={7.44683}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
      <rect
        x={137.606}
        y={23.8203}
        width={4.4681}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
      <rect
        x={124.367}
        y={23.8203}
        width={10.5911}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
      <path
        d="M118.244 23.8203H120.729C121.276 23.8203 121.719 24.2637 121.719 24.8106V24.8106C121.719 25.3575 121.276 25.8009 120.729 25.8009H118.244V23.8203Z"
        fill="#959CA8"
      />
    </g>
    <g filter="url(#filter1_d_4997_39198)">
      <rect
        x={125.836}
        y={28.1113}
        width={53.2862}
        height={9.2416}
        rx={4.5008}
        fill="white"
      />
      <rect
        x={164.229}
        y={31.7422}
        width={11.253}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
      <rect
        x={147.68}
        y={31.7422}
        width={13.9008}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
      <rect
        x={140.233}
        y={31.7422}
        width={4.79907}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
      <rect
        x={129.477}
        y={31.7422}
        width={8.10877}
        height={1.98054}
        rx={0.990269}
        fill="#959CA8"
      />
    </g>
    <path
      d="M118.244 57.6594V60.7877L126.495 65.2624C127.195 65.6421 128.062 65.5138 128.622 64.9478L139.261 54.1865H135.62L127.589 62.5156C127.446 62.6637 127.22 62.6953 127.042 62.5919L118.244 57.6594Z"
      fill="#3B5BDB"
    />
    <path
      d="M131.708 52.8663C131.708 52.137 132.3 51.5459 133.031 51.5459H143.954C144.685 51.5459 145.277 52.137 145.277 52.8663V52.8663C145.277 53.5955 144.685 54.1866 143.954 54.1866H133.031C132.3 54.1866 131.708 53.5955 131.708 52.8663V52.8663Z"
      fill="#3B5BDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.3283 63.4473C84.1249 63.5441 84.692 64.267 84.595 65.0618C84.5327 65.5717 84.5006 66.0914 84.5006 66.6193C84.5006 68.5554 84.9326 70.3868 85.7044 72.0265C86.0456 72.7512 85.7333 73.6147 85.007 73.9551C84.2807 74.2955 83.4154 73.984 83.0742 73.2592C82.1247 71.2419 81.5947 68.9903 81.5947 66.6193C81.5947 65.974 81.634 65.3372 81.7104 64.7112C81.8074 63.9164 82.5318 63.3505 83.3283 63.4473Z"
      fill="#3B5BDB"
    />
    <path
      d="M64.683 55.0245V58.244L63.1895 58.9406C69.4503 61.6998 83.3787 67.841 83.3787 67.841V70.8672L60.092 60.5667C58.8353 60.0108 58.7888 58.1134 60.0167 57.4871L64.683 55.0245Z"
      fill="#3B5BDB"
    />
    <g filter="url(#filter2_d_4997_39198)">
      <rect
        x={128.195}
        y={30.5869}
        width={18.5343}
        height={18.4832}
        rx={8.439}
        fill="#3B5BDB"
      />
    </g>
    <path
      d="M138.41 45.0735V44.0862C139.872 43.8932 140.787 43.0549 140.787 41.8526C140.787 40.6557 140.19 39.7843 138.75 39.4369L137.474 39.1225C136.614 38.8964 136.362 38.6647 136.362 38.2015C136.362 37.6223 136.899 37.2252 137.77 37.2252C138.613 37.2252 138.93 37.6554 139.029 38.3614L140.644 38.3338C140.661 37.1922 139.823 36.2435 138.443 36.0009V34.9033H137.058V35.9953C135.645 36.2104 134.637 37.0874 134.648 38.389C134.665 39.6134 135.442 40.3248 136.652 40.573L137.95 40.8543C138.722 41.0308 139.089 41.2735 139.089 41.8636C139.089 42.4317 138.525 42.8563 137.737 42.8563C136.861 42.8563 136.247 42.4703 136.078 41.7423L134.413 41.7753C134.561 43.1707 135.53 43.9208 137.03 44.0918V45.0735H138.41Z"
      fill="white"
    />
    <mask
      id="mask0_4997_39198"
      style={{
        maskType: "luminance"
      }}
      maskUnits="userSpaceOnUse"
      x={134}
      y={34}
      width={7}
      height={12}
    >
      <path
        d="M138.41 45.0735V44.0862C139.872 43.8932 140.787 43.0549 140.787 41.8526C140.787 40.6557 140.19 39.7843 138.75 39.4369L137.474 39.1225C136.614 38.8964 136.362 38.6647 136.362 38.2015C136.362 37.6223 136.899 37.2252 137.77 37.2252C138.613 37.2252 138.93 37.6554 139.029 38.3614L140.644 38.3338C140.661 37.1922 139.823 36.2435 138.443 36.0009V34.9033H137.058V35.9953C135.645 36.2104 134.637 37.0874 134.648 38.389C134.665 39.6134 135.442 40.3248 136.652 40.573L137.95 40.8543C138.722 41.0308 139.089 41.2735 139.089 41.8636C139.089 42.4317 138.525 42.8563 137.737 42.8563C136.861 42.8563 136.247 42.4703 136.078 41.7423L134.413 41.7753C134.561 43.1707 135.53 43.9208 137.03 44.0918V45.0735H138.41Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4997_39198)">
      <rect
        x={129.951}
        y={32.2012}
        width={15.2974}
        height={15.2552}
        fill="white"
      />
    </g>
    <path
      d="M73.1709 76.0977H108.878V86.0489C108.878 86.6955 108.354 87.2196 107.707 87.2196H74.3416C73.6951 87.2196 73.1709 86.6955 73.1709 86.0489V76.0977Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.537 78.4391H75.5124V84.8781H106.537V78.4391ZM73.1709 76.0977V86.0489C73.1709 86.6955 73.6951 87.2196 74.3416 87.2196H107.707C108.354 87.2196 108.878 86.6955 108.878 86.0489V76.0977H73.1709Z"
      fill="#292A33"
    />
    <defs>
      <filter
        id="filter0_d_4997_39198"
        x={107.093}
        y={9.03814}
        width={59.8678}
        height={31.5438}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={5.57566} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4997_39198"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4997_39198"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_4997_39198"
        x={114.685}
        y={16.96}
        width={75.5888}
        height={31.5438}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={5.57566} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4997_39198"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4997_39198"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_4997_39198"
        x={113.195}
        y={15.5869}
        width={48.5344}
        height={48.4834}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={7.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0823529 0 0 0 0 0.454902 0 0 0 0 0.615686 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4997_39198"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4997_39198"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default KioskImage;
