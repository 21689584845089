/* eslint-disable */
import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";
import actions, {
  bindData,
  changeScreen,
  clearNetworkError
} from "../../../renderer/actions";
import { isUnexpectedError, isNotFoundError } from "./lookup";

const doRestCall = async ({
  method,
  url,
  data,
  params,
  delayChangeScreen: { performDelay, screenIndex }
}) => {
  try {
    const urlWithParams = new URL(url);

    if (method.toUpperCase() === "GET" && params) {
      urlWithParams.search = new URLSearchParams(params).toString();
    }
    const options = {
      method,
      headers: { "Content-Type": "application/json" },
      body: method.toUpperCase() !== "GET" && data ? JSON.stringify(data) : null
    };

    const response = await fetch(urlWithParams, options);
    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    const responseData = await response.json();
    const responseDataObj =
      method.toUpperCase() === "GET" ? { data: responseData } : responseData;

    return { ...responseDataObj, performDelay, screenIndex };
  } catch (error) {
    return Rx.throwError(error);
  }
};

export const restApiCallEpic = (action$, state$) =>
  action$.ofType(actions.DO_REST_CALL).pipe(
    RxOp.flatMap(({ payload }) =>
      Rx.from(doRestCall(payload)).pipe(
        RxOp.flatMap(response =>
          R.ifElse(
            R.pathEq(["performDelay"], true),
            R.always(
              Rx.of(
                clearNetworkError(),
                bindData({
                  location: payload.resultsLocation,
                  data: response.data
                }),
                changeScreen(response.screenIndex)
              )
            ),
            R.always(
              Rx.of(
                clearNetworkError(),
                bindData({
                  location: payload.resultsLocation,
                  data: response.data
                })
              )
            )
          )(response)
        ),
        RxOp.catchError(
          R.pipe(
            R.cond([
              [
                isNotFoundError,
                R.always(
                  Rx.of(
                    bindData({ location: ["notFoundErrorFlag"], data: true })
                  )
                )
              ],
              [
                isUnexpectedError,
                R.always(
                  Rx.of(
                    bindData({ location: ["unexpectedErrorFlag"], data: true })
                  )
                )
              ],
              [
                R.T,
                R.always(
                  Rx.of(
                    bindData({
                      location: ["catchAllLookupErrorFlag"],
                      data:
                        "catch all error happened, this will be of no use most likely if this happens."
                    }),
                    bindData({
                      location: ["unexpectedErrorFlag"],
                      data: true
                    })
                  )
                )
              ]
            ])
          )
        )
      )
    )
  );
