export const filterItemsList = (list, searchTerm) =>
  list.filter(item =>
    item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

export const sortItemsList = list =>
  list
    .slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

export const isMaxSelectionReached = (maxSelection, selectedOptions) =>
  maxSelection && maxSelection === selectedOptions?.length;

export const isChecked = (option, selectedOptions) =>
  selectedOptions?.some(
    selectedOption => selectedOption?.name === option?.name
  );

export const selectValues = items => items.map(item => item.value);

export const selectOption = (option, selectedOptions, setSelectedOptions) => {
  if (selectValues(selectedOptions).includes(option.value)) {
    const fewerOptions = selectedOptions.filter(
      selectedOption => selectedOption.value !== option.value
    );
    setSelectedOptions(fewerOptions);
  } else {
    const moreOptions = selectedOptions.concat(option);
    setSelectedOptions(moreOptions);
  }
};
