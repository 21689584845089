import React from "react";
import { MINESHAFT_GREY } from "../../constants/colors";

export const IconQuit = ({
  fill = MINESHAFT_GREY,
  width = "24px",
  height = "24px"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="quit"
        d="M18.9999989 6.40999946L17.589999 4.9999997 11.9999993 10.5899995 6.40999946 4.9999997 4.9999997 6.40999946 10.5899995 11.9999993 4.9999997 17.589999 6.40999946 18.9999989 11.9999993 13.409999 17.589999 18.9999989 18.9999989 17.589999 13.409999 11.9999993z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <mask id="quitMask" fill="#fff">
        <use xlinkHref="#quit"></use>
      </mask>
      <use fill={fill} xlinkHref="#quit"></use>
      <g fill={fill} mask="url(#quitMask)">
        <path d="M0 0H24V24H0z"></path>
      </g>
    </g>
  </svg>
);
