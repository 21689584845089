import React from "react";
import Heading from "../../atoms/heading";
import Paragraph from "../../atoms/paragraph";
import {
  ATHENS_GREY,
  MATISSE_BLUE,
  CHARADE_GREY,
  STORM_GREY,
  WHITE,
  GHOST_GREY,
  GRECIAN_GREY
} from "../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import ButtonWithLink from "../../atoms/button-with-link";
import { Box, Stack } from "../../atoms/layouts";
const WorkflowTile = ({
  workflowName = "Test Workflow",
  workflowDescription = "Link your benefit plan",
  workflowActionName = "Search",
  slug,
  buttonVariant = "primary"
}) => (
  <Box
    background={WHITE}
    boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
    padding={0}
    borderRadius="4px"
  >
    <Stack childGap={0} bottomItem={3} fullHeight>
      <Box padding={"1rem 1rem 0 1rem"}>
        <Heading
          variant="h6"
          weight={FONT_WEIGHT_SEMIBOLD}
          color={CHARADE_GREY}
          margin={"0 0 0.5rem 0"}
        >
          {workflowName}
        </Heading>
      </Box>
      <Box padding={"0 1rem 1rem 1rem"} minHeight={"4.25rem"}>
        <Paragraph variant="pS" color={STORM_GREY}>
          {workflowDescription}
        </Paragraph>
      </Box>
      <Box
        padding={"1.5rem 1rem"}
        background={ATHENS_GREY}
        borderColor={GRECIAN_GREY}
        borderWidthOverride="2px 0 0 0"
      >
        <ButtonWithLink
          variant={buttonVariant}
          primary={buttonVariant == "primary"}
          primaryBG={MATISSE_BLUE}
          fontWeight={FONT_WEIGHT_SEMIBOLD}
          fontSize={"1.125rem"}
          text={workflowActionName}
          minWidth={"100%"}
          url={`/service/${slug}`}
          extraStyles={`width: 100%; margin: 0;`}
          linkExtraStyles={`justify-content: center;`}
          dataQa={slug}
        />
      </Box>
    </Stack>
  </Box>
);

export default WorkflowTile;
