import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { Cluster, Cover, Box } from "../../atoms/layouts";
import Text from "../../atoms/text";
import ButtonWithAction from "../../atoms/button-with-action";
import { ChevronIcon } from "../../atoms/icons";
import { noop } from "../../../util/general";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./Pagination.theme";

const PAGING_SPACE = 2; // how many pages we want to have before/after delimiter
const PAGING_INIT_SPACE = 3; // first delimiter should appear after 3 pages

const PrevNextPlaceholder = ({ buttonHeight, buttonWidth }) => (
  <Box
    padding="0"
    minHeight={buttonHeight}
    minWidth={buttonWidth}
    extraStyles={`max-height: ${buttonHeight};`}
  ></Box>
);

const PrevNextButton = ({
  action,
  ariaLabel,
  arrowColor,
  borderRadius,
  buttonHeight,
  buttonWidth,
  numberColor,
  type
}) => (
  <Box
    padding="0"
    minHeight={buttonHeight}
    extraStyles={`max-height: ${buttonHeight};`}
    as="li"
  >
    <ButtonWithAction
      action={action}
      contentOverride
      dataQa={type}
      aria-label={ariaLabel}
      extraStyles={`
        background-color: ${numberColor};
        border-color: ${numberColor};
        border-radius: ${borderRadius};
        margin: 0;
        max-height: ${buttonHeight};
        min-height: 100%;
        min-width: ${buttonWidth};
        padding: 0;
      `}
    >
      <Box padding="0" extraStyles={type === "prev" && "transform: scaleX(-1)"}>
        <ChevronIcon variant="darkMode" iconFill={arrowColor} />
      </Box>
    </ButtonWithAction>
  </Box>
);

export const getPagesPanel = (page, pagesCount) => {
  if (!pagesCount || pagesCount <= 1) {
    return [];
  }
  const lastPageNumber = pagesCount;
  const space = page === 1 ? PAGING_INIT_SPACE : PAGING_SPACE;
  const pages = [{ index: 1, isButton: true }];
  if (page > space + 1) {
    pages.push({ isDelimiter: true, id: "first-delimiter" });
  }
  for (
    let j = Math.max(1, page - space) + 1;
    j < Math.min(lastPageNumber, page + space);
    j++
  ) {
    pages.push({ index: j, isButton: true, id: `page-${j}` });
  }
  if (page < lastPageNumber - space) {
    pages.push({ isDelimiter: true, id: "last-delimiter" });
  }
  pages.push({
    index: lastPageNumber,
    isButton: true,
    id: `page-${lastPageNumber}`
  });
  const activePage = pages.find(p => p.index === page);
  if (activePage) {
    activePage.active = true;
  }
  return pages;
};

const Pagination = ({
  activeBorderWidth = "3px",
  ariaLabel,
  arrowColor,
  borderRadius = "3px",
  buttonHeight = "44px",
  buttonWidth = "44px",
  childGap = "24px",
  currentPage,
  fontSize = "17px",
  fontWeight = "900",
  numberColor,
  pageCount,
  pageNext,
  pagePrevious,
  setCurrentPage,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);

  const extraStyles = `
    min-width: ${buttonWidth}; min-height: 100%; padding: 0;
    border-radius: ${borderRadius};
    > * > span {
      color: ${numberColor ?? themeValues.numberColor}
    }
    margin: 0;
    &:hover {
        background-color: ${themeValues.hoverBackgroundColor}
    }
  `;

  const currentPageStyles = `
    border: ${activeBorderWidth} solid ${numberColor ??
    themeValues.numberColor};
    color: ${numberColor ?? themeValues.activeColor};
    background-color: ${themeValues.activeBackgroundColor};
    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: initial;
      border: ${activeBorderWidth} solid ${numberColor ??
    themeValues.numberColor};
      background-color: ${themeValues.activeBackgroundColor};
    }
  `;

  return (
    <Cluster
      justify="center"
      childGap={childGap}
      overflow={true}
      as="nav"
      role="navigation"
      innerWrapperAs="ul"
      aria-label={ariaLabel}
      extraStyles="> ul { padding: 0px; > li { list-style-type: none; } };"
    >
      {currentPage > 1 ? (
        <PrevNextButton
          action={pagePrevious}
          ariaLabel={`Previous Page`}
          arrowColor={arrowColor ?? themeValues.arrowColor}
          borderRadius={borderRadius}
          buttonHeight={buttonHeight}
          buttonWidth={buttonWidth}
          numberColor={numberColor ?? themeValues.numberColor}
          type="prev"
        />
      ) : (
        isMobile && (
          <PrevNextPlaceholder
            buttonHeight={buttonHeight}
            buttonWidth={buttonWidth}
          />
        )
      )}
      {isMobile
        ? pageCount > 0 && (
            <Box padding="0">
              <Cover singleChild>
                <Text
                  variant="pXL"
                  weight={fontWeight}
                  color={numberColor ?? themeValues.numberColor}
                  extraStyles={`font-size: ${fontSize}`}
                >
                  {`${currentPage} of ${pageCount}`}
                </Text>
              </Cover>
            </Box>
          )
        : getPagesPanel(currentPage, pageCount).map((item, index) =>
            item.isButton ? (
              <Box
                padding="0"
                extraStyles={`max-height: ${buttonHeight};`}
                as="li"
                key={item.id}
              >
                <ButtonWithAction
                  variant="ghost"
                  text={item.index}
                  aria-current={item.active ? "page" : undefined}
                  aria-label={`${
                    item.index == pageCount ? "Last Page, " : ""
                  }page ${item.index}`}
                  action={
                    !item.active
                      ? () => setCurrentPage({ pageNumber: item.index })
                      : noop
                  }
                  textExtraStyles={`font-size: ${fontSize}; font-weight: ${fontWeight};`}
                  extraStyles={`${extraStyles}${
                    item.active ? currentPageStyles : ""
                  }`}
                  dataQa={index}
                >
                  {item.index}
                </ButtonWithAction>
              </Box>
            ) : (
              <Box padding="0 10px" as="li" key={item.id}>
                <Cluster justify="flex-end">
                  <Text
                    variant="pXL"
                    weight={fontWeight}
                    color={numberColor ?? themeValues.numberColor}
                    role="presentation"
                  >
                    {"…"}
                  </Text>
                </Cluster>
              </Box>
            )
          )}
      {currentPage < pageCount ? (
        <PrevNextButton
          action={pageNext}
          ariaLabel={`Next Page`}
          arrowColor={arrowColor ?? themeValues.arrowColor}
          borderRadius={borderRadius}
          buttonHeight={buttonHeight}
          buttonWidth={buttonWidth}
          numberColor={numberColor ?? themeValues.numberColor}
          type="next"
        />
      ) : (
        isMobile && (
          <PrevNextPlaceholder
            buttonHeight={buttonHeight}
            buttonWidth={buttonWidth}
          />
        )
      )}
    </Cluster>
  );
};

export default themeComponent(Pagination, "Pagination", fallbackValues);
