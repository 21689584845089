import { useRef, useEffect } from "react";

/* 
  Hook that assigns a click event listener to the main document element
  Returns a ref to attach to another element (like an icon/button that triggers a popover)
  If a click event gets captured by the document and the assigned element isn't the target
  hook will run whatever handler is passed (eg a function that closes a popover)

  See popover component for implementation

*/

const useOutsideClickHook = handler => {
  const ref = useRef();

  useEffect(() => {
    const handleOutsideClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler();
      }
    };

    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClickHook;
