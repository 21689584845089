import { WHITE, CHARADE_GREY } from "../../../constants/colors";

const fontSize = {
  default: "1.375rem",
  largeTitle: "1.75rem",
  small: "1.25rem"
};
const fontWeight = { default: "600", largeTitle: "700", small: "600" };
const fontColor = {
  default: CHARADE_GREY,
  largeTitle: CHARADE_GREY,
  small: CHARADE_GREY
};
const lineHeight = { default: "2rem", largeTitle: "2rem", small: "2rem" };
const textAlign = { default: "left", largeTitle: "left", small: "left" };
const titleType = { default: "h5", largeTitle: "h1", small: "h6" };
const titleSpacing = {
  default: "0.5rem",
  largeTitle: "1.125rem",
  small: "0.5rem"
};
const boxShadow = {
  default:
    "0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)",
  largeTitle:
    "0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)",
  small:
    "0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
};
const borderRadius = {
  default: "0.25rem",
  largeTitle: "0.25rem",
  small: "0.25rem"
};
const backgroundColor = { default: WHITE, largeTitle: WHITE, small: WHITE };

export const fallbackValues = {
  fontSize,
  fontWeight,
  fontColor,
  lineHeight,
  textAlign,
  titleType,
  titleSpacing,
  boxShadow,
  borderRadius,
  backgroundColor
};
