import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const TrashIcon = ({ themeValues, iconFill }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M15,7 L14,15.5714286 C14,16.3571429 13.25,17 12.3333333,17 L12.3333333,17 L7.66666667,17 C6.75,17 6,16.3571429 6,15.5714286 L6,15.5714286 L5,7 L15,7 Z M12.1428571,3 L13,4 L16,4 L16,6 L4,6 L4,4 L7,4 L7.85714286,3 L12.1428571,3 Z"
          id="trash-path-1"
        ></path>
      </defs>
      <g
        id="trash-Icons-/-Small-/-20px-S-/-Trash---Mobile---20px"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <mask id="trash-mask-2" fill="white">
          <use xlinkHref="#trash-path-1"></use>
        </mask>
        <use
          id="trash-Mask"
          fill={iconFill ?? themeValues.singleIconColor}
          fillRule="nonzero"
          xlinkHref="#trash-path-1"
        ></use>
        <polygon
          id="trash-Path"
          fill={iconFill ?? themeValues.singleIconColor}
          fillRule="nonzero"
          mask="url(#trash-mask-2)"
          points="0 0 20 0 20 20 0 20"
        ></polygon>
      </g>
    </svg>
  );
};

export default themeComponent(TrashIcon, "Icons", fallbackValues, "primary");
