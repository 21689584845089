export const NOT_ASKED = "NOT_ASKED";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";

export const isSuccess = x => x.phase === SUCCESS;

export const success = data => ({ phase: SUCCESS, data });

export const error = error => ({ phase: ERROR, error });

export const notAsked = () => ({ phase: NOT_ASKED });

export const loading = () => ({ phase: LOADING });

export const map = f => remoteData =>
  isSuccess(remoteData)
    ? { ...remoteData, data: f(remoteData.data) }
    : remoteData;

export const withDefault = x => remoteData =>
  isSuccess(remoteData) ? remoteData.data : x;
