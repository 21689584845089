import React, { useReducer } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { isEmpty } from "ramda";
import {
  CollapsibleSection,
  Stack,
  Motion,
  DisplayCard
} from "@thecb/components";
import AlertBar from "/components/alert-bar";
import { configureEndpoint } from "/util/router-utils";

const ProfileSettings = ({
  actions,
  resources,
  alertBars,
  customerManagement
}) => {
  const { profileSettingsAlertBar: alertBar } = alertBars;
  const {
    alertBars: { profileSettingsAlertBar: alertBarActions }
  } = actions;

  const SET_SECURITY = "SET_SECURITY";

  const reducer = (settingsPage, action) => {
    switch (action) {
      case SET_SECURITY:
        return {
          ...settingsPage,
          securitySection: !settingsPage.securitySection
        };
      default:
        return settingsPage;
    }
  };

  const [settingsPage, dispatch] = useReducer(reducer, {
    securitySection: true
  });

  const content = {
    open: {
      x: "0",
      opacity: 1
    },
    closed: {
      x: "-7.5%",
      opacity: 0
    }
  };

  return (
    <Stack childGap="24px">
      {!isEmpty(alertBar.alerts) && (
        <AlertBar {...alertBar} {...alertBarActions} />
      )}
      <CollapsibleSection
        title="Login & Security"
        toggleSection={() => dispatch(SET_SECURITY)}
        isOpen={settingsPage.securitySection}
        key="security-section"
      >
        <Stack childGap="16px">
          <Motion
            padding="0"
            variants={content}
            transition={{ duration: 0.3 }}
            positionTransition
            extraStyles={`transform-origin: 100% 0;`}
          >
            <DisplayCard
              title="Name"
              item={`${resources.firstName} ${resources.lastName}`}
              buttonText="Edit Name"
              link={!customerManagement}
              url={configureEndpoint(
                customerManagement,
                "/profile/settings/edit-name"
              )}
            />
          </Motion>
          <Motion
            padding="0"
            variants={content}
            transition={{ duration: 0.3 }}
            positionTransition
            extraStyles={`transform-origin: 100% 0;`}
          >
            <DisplayCard
              title="Username"
              item={resources.email}
              helpText="Not Editable"
              hasPopover
              popoverTriggerText="What's this?"
              popoverContent="This email address was used to register. It cannot be changed."
              popoverTextExtraStyles="text-decoration: underline;"
            />
          </Motion>
          <Motion
            padding="0"
            variants={content}
            transition={{ duration: 0.3 }}
            positionTransition
            extraStyles={`transform-origin: 100% 0;`}
          >
            <DisplayCard
              title="Password"
              item="•••••••••••••"
              buttonText="Change Password"
              link={!customerManagement}
              url="/profile/settings/change-password"
            />
          </Motion>
        </Stack>
      </CollapsibleSection>
    </Stack>
  );
};

export default connect(null, { push })(React.memo(ProfileSettings));
