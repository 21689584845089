import React from "react";

const ArrowLeftCircleIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ArrowLeftCircleIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.528 14.472C10.7232 14.2768 10.7233 13.9605 10.5283 13.7652L7.39375 10.625H14.5C14.7761 10.625 15 10.4011 15 10.125V9.875C15 9.59886 14.7761 9.375 14.5 9.375H7.39375L10.5283 6.2348C10.7233 6.03949 10.7232 5.72315 10.528 5.52801L10.3536 5.35355C10.1583 5.15829 9.84171 5.15829 9.64645 5.35355L5.35355 9.64645C5.15829 9.84171 5.15829 10.1583 5.35355 10.3536L9.64645 14.6464C9.84171 14.8417 10.1583 14.8417 10.3536 14.6464L10.528 14.472Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.528 14.472C10.7232 14.2768 10.7233 13.9605 10.5283 13.7652L7.39375 10.625H14.5C14.7761 10.625 15 10.4011 15 10.125V9.875C15 9.59886 14.7761 9.375 14.5 9.375H7.39375L10.5283 6.2348C10.7233 6.03949 10.7232 5.72315 10.528 5.52801L10.3536 5.35355C10.1583 5.15829 9.84171 5.15829 9.64645 5.35355L5.35355 9.64645C5.15829 9.84171 5.15829 10.1583 5.35355 10.3536L9.64645 14.6464C9.84171 14.8417 10.1583 14.8417 10.3536 14.6464L10.528 14.472Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect
          x="17.5"
          y="17.5"
          width="15"
          height="15"
          transform="rotate(180 17.5 17.5)"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default ArrowLeftCircleIconSmall;
