import React from "react";
import { STORM_GREY, WHITE, PICKLED_BLUE } from "../../constants/colors";

export const IconChevron = ({ fill = STORM_GREY }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id={`icon-chevron`}
    >
      <defs>
        <polygon
          id={`icon-chevron-path-1`}
          points="10.4099998 6 9 7.40999976 13.5799996 11.9999996 9 16.5899995 10.4099998 17.9999993 16.4099994 11.9999996"
        />
      </defs>
      <g id={`icon-chevron-Icon/Chevron-Down`} fill="none" fillRule="evenodd">
        <mask id={`icon-chevron-mask-2`} fill={WHITE}>
          <use xlinkHref={`#icon-chevron-path-1`} />
        </mask>
        <use
          id={`icon-chevron-Fill-2`}
          fill={PICKLED_BLUE}
          xlinkHref={`#icon-chevron-path-1`}
        />
        <g id="Colors/Blue-Dark" mask={`url(#icon-chevron-mask-2)`} fill={fill}>
          <rect id={`icon-chevron-rect`} width="24" height="24" />
        </g>
      </g>
    </svg>
  );
};
