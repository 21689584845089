import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import { Box } from "@thecb/components";
import dompurify from "dompurify";
import ResponsiveIframe from "../responsive-iframe";
import {
  StyledTable,
  TableHeaderCell,
  TableDataCell,
  TableCellContentContainer
} from "./StyledTable";

const RichTextField = ({
  html,
  horizontalPad = 0,
  themeValues,
  extraStyles = ""
}) => {
  const { isMobile } = useContext(ThemeContext);

  const options = {
    replace: domNode => {
      const props = attributesToProps(domNode?.attribs);
      switch (domNode.name) {
        case "iframe":
          return <ResponsiveIframe {...props} horizontalPad={horizontalPad} />;

        case "img":
          delete props["width"];
          delete props["height"];
          return (
            <img {...props} style={{ maxWidth: "100%", height: "auto" }} />
          );

        case "table":
          return (
            <StyledTable {...props}>
              {domToReact(domNode.children, options)}
            </StyledTable>
          );

        case "th":
          return (
            <TableHeaderCell {...props} background={themeValues.linkColorAlpha}>
              <div style={{ margin: "24px 0" }}>
                {domToReact(domNode.children, options)}
              </div>
            </TableHeaderCell>
          );

        case "td":
          return (
            <TableDataCell {...props}>
              <TableCellContentContainer>
                {domToReact(domNode.children, options)}
              </TableCellContentContainer>
            </TableDataCell>
          );
        default:
          break;
      }
    }
  };
  return (
    <Box
      padding={0}
      extraStyles={`${extraStyles} ${isMobile && "overflowX: auto;"}`}
    >
      {parse(dompurify.sanitize(html, { ADD_TAGS: ["iframe"] }), options)}
    </Box>
  );
};

export default RichTextField;
