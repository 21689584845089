import styled from "styled-components";
import {
  BRIGHT_GREY,
  STORM_GREY,
  GHOST_GREY,
  MATISSE_BLUE
} from "../../../constants/colors";

export const EditableTableContainer = styled.div`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  flex-direction: column;
  flex: 1;
`;

export const EditableTableListItem = styled.div`
  width: 100%;
  display: flex;
  ${({ isMobile }) => isMobile && "justify-content: center"};
  align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  flex: 1;
  ${({ isMobile }) =>
    isMobile ? "padding: 1rem 0.5rem" : "padding: 0 0.5rem"};
`;

export const EditableListItemControls = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const EditableListAction = styled.div`
  color: ${MATISSE_BLUE};
  align-items: center;
  font-size: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  display: ${({ hide }) => (hide ? "none" : "flex")};
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid ${GHOST_GREY};
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;
  ${({ isMobile }) => isMobile && `display: none`};
  flex: 1;
`;

export const TableItemKey = styled.div`
  display: flex;
  ${({ isMobile }) => !isMobile && "flex: 1"};
  ${({ isMobile }) => isMobile && "align-items: center"};
  ${({ isMobile }) => !isMobile && "padding: 1.25rem 0"};
  font-size: ${({ isMobile }) => (isMobile ? "1rem" : "1.125rem")};
  color: ${STORM_GREY};
`;

export const TableItemValue = styled.div`
  display: flex;
  ${({ isMobile }) => !isMobile && "flex: 1"};
  ${({ isMobile }) => !isMobile && "padding: 1.25rem 0"};
  ${({ isMobile }) => isMobile && "align-items: center"};
  font-size: ${({ isMobile }) => (isMobile ? "1.125rem" : "1.0625rem")};
  color: ${BRIGHT_GREY};
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;
