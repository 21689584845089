import React, { Fragment } from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import RadioButton from "./radio-button/RadioButton";
import { Box, Cluster, Stack, Motion } from "../../atoms/layouts";
import { createIdFromString, noop } from "../../../util/general";
import Text from "../../atoms/text";
import { CHARADE_GREY } from "../../../constants/colors";
import { MANATEE_GREY } from "../../../constants/colors";

const idString = section =>
  typeof section.title === "string"
    ? createIdFromString(section.title)
    : section.id;

const InnerRadioSection = ({
  themeValues,
  isMobile,
  supportsTouch,
  section,
  sectionIndex,
  openSection = "",
  toggleOpenSection,
  staggeredAnimation = false,
  initiallyOpen = true,
  openHeight = "auto",
  ariaDescribedBy,
  focused,
  setFocused,
  sectionRefs,
  ariaLabelledBy,
  isLastGroupedItemInSection,
  onKeyDown = noop
}) => {
  const wrapper = {
    open: {
      height: openHeight,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: staggeredAnimation ? 0.15 : 0
      }
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
        staggerChildren: staggeredAnimation ? 0.15 : 0,
        staggerDirection: -1
      }
    }
  };

  const borderStyles = `
    border-width: 0 0 1px 0;
    border-color: ${
      isLastGroupedItemInSection ? MANATEE_GREY : themeValues.borderColor
    };
    border-style: solid;
    border-radius: 0px;
    transform-origin: 100% 0;

    &:last-child {
      border-width: 0;
    }
  `;

  const RightIcon = styled.img`
    height: ${({ isMobile }) => (isMobile ? "14px" : "18px")};
    width: ${({ isMobile }) => (isMobile ? "22px" : "28px")};
    ${({ fade }) => fade && "opacity: 0.4;"}
    transition: opacity 0.3s ease;
  `;

  return (
    <Motion
      tabIndex={section.hideRadioButton || section.disabled ? "-1" : "0"}
      ref={sectionRefs.current[sectionIndex]}
      onBlur={() => !section.disabled && setFocused(null)}
      onFocus={() => !section.disabled && setFocused(section.id)}
      onKeyDown={onKeyDown}
      hoverStyles={themeValues.focusStyles}
      animate={openSection === section.id ? "open" : "closed"}
      initial={initiallyOpen ? "open" : "closed"}
      key={`item-${section.id}`}
      extraStyles={borderStyles}
      role="radio"
      aria-checked={openSection === section.id}
      aria-disabled={section.disabled}
      aria-required={section.required}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      onClick={
        (isMobile && supportsTouch) ||
        section.disabled ||
        section.id === openSection
          ? noop
          : () => toggleOpenSection(section.id)
      }
      onTouchEnd={
        isMobile &&
        supportsTouch &&
        !section.disabled &&
        section.id !== openSection
          ? () => toggleOpenSection(section.id)
          : noop
      }
      id={`inner-radio-section-${sectionIndex}`}
      data-qa={
        section.dataQa
          ? section.dataQa
          : section.id || `inner-radio-section-${sectionIndex}`
      }
    >
      <Stack childGap="0">
        <Box
          padding={section.hideRadioButton ? "1.5rem" : "1.25rem 1.5rem"}
          background={
            section.disabled
              ? themeValues.headingDisabledColor
              : themeValues.headingBackgroundColor
          }
          key={`header-${section.id}`}
          borderSize="0px"
          borderColor={themeValues.borderColor}
          borderWidthOverride={
            openSection === section.id && !!section.content
              ? `0px 0px 1px 0px`
              : ``
          }
          extraStyles={!section.disabled ? "cursor: pointer;" : ""}
        >
          <Cluster justify="space-between" align="center" childGap="1px" nowrap>
            <Cluster justify="flex-start" align="center" nowrap>
              {!section.hideRadioButton && (
                <Box padding="0">
                  <RadioButton
                    id={`radio-input-${idString(section)}`}
                    name={idString(section)}
                    ariaDescribedBy={ariaDescribedBy}
                    radioOn={openSection === section.id}
                    radioFocused={focused === section.id}
                    toggleRadio={
                      section.disabled
                        ? noop
                        : () => toggleOpenSection(section.id)
                    }
                    tabIndex="-1"
                    isRequired={section.required}
                  />
                </Box>
              )}
              {section.titleIcon && (
                <Cluster align="center">{section.titleIcon}</Cluster>
              )}
              <Box padding={section.titleIcon ? "0 0 0 8px" : "0"}>
                <Text
                  as="label"
                  htmlFor={`radio-input-${idString(section)}`}
                  color={CHARADE_GREY}
                >
                  {section.title}
                </Text>
              </Box>
            </Cluster>
            {section.rightIcons && (
              <Cluster
                id={`right-icons-${idString(section)}`}
                childGap="0.5rem"
                aria-label={section.rightIconsLabel || null}
                role={section.rightIconsRole || null}
              >
                {section.rightIcons.map(icon => (
                  <RightIcon
                    src={icon.img}
                    key={icon.img}
                    fade={!icon.enabled}
                    isMobile={isMobile}
                    alt={icon.altText}
                    aria-disabled={!icon.enabled}
                  />
                ))}
              </Cluster>
            )}
            {section.rightTitleContent && (
              <Fragment>{section.rightTitleContent}</Fragment>
            )}
          </Cluster>
        </Box>
        <AnimatePresence initial={false}>
          {openSection === section.id && (
            <Motion
              key={`content-${section.id}`}
              padding="0"
              background={themeValues.bodyBackgroundColor}
              layoutTransition
              initial="closed"
              animate="open"
              exit="closed"
              variants={wrapper}
              extraStyles={`transform-origin: 100% 0; border-top: 0;`}
            >
              {section.content}
            </Motion>
          )}
        </AnimatePresence>
      </Stack>
    </Motion>
  );
};

export default InnerRadioSection;
