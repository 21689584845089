import React from "react";
import styled from "styled-components";
import {
  Alert,
  Box,
  Cover,
  Center,
  EmptyCartIcon,
  Heading,
  Paragraph,
  Module,
  ButtonWithLink,
  constants
} from "@thecb/components";

const PaymentExpiredSessionContainer = styled.div`
  /* width: 100%;
  height: 100%; */
`;

const PaymentExpiredSession = ({ isMobile, expiredURL }) => {
  return (
    <PaymentExpiredSessionContainer>
      <Box padding="0" extraStyles={`margin: 1.5rem 0;`}>
        <Alert
          heading="Session ended due to inactivity"
          text={`For your security, your checkout session has been ended due to inactivity.`}
          variant="error"
          showQuitLink={false}
        />
      </Box>
      <Module>
        <Box
          minHeight={isMobile ? "auto" : "471px"}
          padding={isMobile ? "40px 16px 16px" : "48px"}
          minWidth={isMobile ? "100%" : "646px"}
          background={constants.colors.WHITE}
          boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
0px 3px 8px 0px rgb(202, 206, 216)"
          borderRadius="4px"
        >
          <Cover singleChild fillCenter>
            <Center maxWidth="100%" intrinsic>
              <Box
                extraStyles={`display: flex; flex-direction: column; justify-content: center; align-items: center; flex-grow: 1;`}
                padding="0"
              >
                <Box
                  padding="0"
                  extraStyles={isMobile && `svg { width: 100%; height: auto;}`}
                >
                  <EmptyCartIcon />
                </Box>
                <Box
                  padding="0"
                  extraStyles={
                    isMobile ? `margin: 32px 0;` : `margin: 32px 0 12px 0`
                  }
                >
                  <Heading
                    variant="h3"
                    weight={constants.fontWeights.FONT_WEIGHT_BOLD}
                    textAlign="center"
                  >
                    No Items in Checkout
                  </Heading>
                  <Paragraph variant="pL" extraStyles={`text-align: center;`}>
                    Please search for a new payment.
                  </Paragraph>
                </Box>
                <Box padding="0" minWidth={isMobile ? "100%" : "auto"}>
                  <ButtonWithLink
                    text={"Start Over"}
                    url={expiredURL.url}
                    extraStyles={isMobile && `width: 100%; margin: 0;`}
                  />
                </Box>
              </Box>
            </Center>
          </Cover>
        </Box>
      </Module>
    </PaymentExpiredSessionContainer>
  );
};

export default PaymentExpiredSession;
