import React, { useEffect } from "react";
import { required, isProbablyEmail } from "redux-freeform";
import { FormInput, FormInputColumn } from "../../atoms/form-layouts";
import { noop } from "../../../util/general";

const LoginForm = ({
  clearOnDismount,
  fields,
  actions,
  showErrors,
  handleSubmit = noop
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const emailErrorMessages = {
    [required.error]: "Email address is required",
    [isProbablyEmail.error]: "Invalid email address"
  };
  const passwordErrorMessages = {
    [required.error]: "Password is required"
  };
  return (
    <FormInputColumn role="form" aria-label="Login">
      <FormInput
        labelTextWhenNoError="Email address"
        errorMessages={emailErrorMessages}
        type="email"
        field={fields.email}
        fieldActions={actions.fields.email}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        isEmail
        autocompleteValue="email"
      />
      <FormInput
        labelTextWhenNoError="Password"
        errorMessages={passwordErrorMessages}
        type="password"
        field={fields.password}
        fieldActions={actions.fields.password}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        autocompleteValue="current-password"
      />
    </FormInputColumn>
  );
};

export default LoginForm;
