import React, { useState } from "react";
import { GHOST_GREY } from "../../../../constants/colors";
import { Box, Cluster } from "../../../atoms/layouts";
import AutopayModalModule from "./AutopayModalModule";
import RemoveAccountModalModule from "./RemoveAccountModalModule";
import { noop } from "../../../../util/general";

const InactiveControlsModule = ({
  autoPayEnabled,
  autoPaySchedule,
  navigateToSettings,
  deactivatePaymentSchedule,
  isMobile,
  paymentPlanSchedule,
  isPaymentPlan,
  nextAutopayDate,
  obligationAssocID,
  dueDate,
  agencyName,
  configType,
  actions,
  description,
  subDescription,
  allowedPaymentInstruments,
  disableActions = false
}) => {
  const [modalOpen, toggleModal] = useState(false);
  const { deleteObligationAssoc } = actions;
  const handleRemoveAccount = () => deleteObligationAssoc(obligationAssocID);
  return (
    <Box
      padding={isMobile ? "18px 0 0 0" : "0"}
      border={isMobile ? `1px solid ${GHOST_GREY}` : `0px`}
      borderWidthOverride={isMobile ? `1px 0 0 0` : `0px`}
    >
      <Cluster
        childGap={autoPayEnabled ? `8px` : "0"}
        nowrap
        justify={isMobile && autoPayEnabled && "center"}
        align={isMobile && autoPayEnabled && "center"}
      >
        {autoPayEnabled && (
          <Box padding="0" extraStyles={`flex-grow: 1;`}>
            <AutopayModalModule
              autoPayActive={autoPayEnabled}
              autoPaySchedule={autoPaySchedule}
              toggleModal={toggleModal}
              modalOpen={modalOpen}
              navigateToSettings={navigateToSettings}
              deactivatePaymentSchedule={deactivatePaymentSchedule}
              isMobile={isMobile}
              paymentPlanSchedule={paymentPlanSchedule}
              isPaymentPlan={isPaymentPlan}
              nextAutopayDate={nextAutopayDate}
              obligationAssocID={obligationAssocID}
              dueDate={dueDate}
              controlType="secondary"
              inactive
              description={description}
              subDescription={subDescription}
              allowedPaymentInstruments={allowedPaymentInstruments}
              disableActions={disableActions}
            />
          </Box>
        )}
        <Box padding="0" extraStyles={`flex-grow: 1;`}>
          <RemoveAccountModalModule
            agencyName={agencyName}
            removeAccount={disableActions ? noop : handleRemoveAccount}
            accountType={configType === "ACCOUNT" ? "Account" : "Property"}
            isMobile={isMobile}
            disableActions={disableActions}
          />
        </Box>
      </Cluster>
    </Box>
  );
};

export default InactiveControlsModule;
