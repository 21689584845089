import EditNameForm from "./EditNameForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./EditNameForm.state";

EditNameForm.reducer = reducer;
EditNameForm.mapStateToProps = mapStateToProps;
EditNameForm.mapDispatchToProps = mapDispatchToProps;
export default EditNameForm;
