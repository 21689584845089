import React, { useEffect, useState } from "react";
import ButtonWithAction from "../../atoms/button-with-action";
import ClipboardIcon from "../../atoms/icons/ClipboardIcon";
import Popover from "../popover";
import Stack from "../../atoms/layouts/Stack";
import Text from "../../atoms/text";

/*
This component will render `content` and a clipboard icon.
When hovered, a popover with content `initialPopoverContent` will be displayed.
to the left of the content.

When clicked, `content` will be copied to the clipboard, the popover content
will change to `copySuccessPopoverContent` for `copiedPopoverContentDuration`
milliseconds, and the `onCopy` callback will be executed.

If the content was unable to be copied to the clipboard, the popover will instead
display `copyErrorPopoverContent`

If you only want the copy to clipboard behaviour without a popover, pass the prop
`hasPopover={false}`
*/

const CopyableContent = ({ content, onClick }) => {
  return (
    <ButtonWithAction
      data-testid="copyable-trigger"
      contentOverride
      action={onClick}
      variant="ghost"
      extraStyles="padding: 0; margin: 0; min-height: initial; min-width: initial"
    >
      <Stack direction="row" childGap="0.25rem">
        <Text>{content}</Text>
        <ClipboardIcon />
      </Stack>
    </ButtonWithAction>
  );
};

const Copyable = ({
  content,
  onCopy,
  initialPopoverContent = "Click to copy to clipboard",
  copySuccessPopoverContent = "Copied!",
  copyErrorPopoverContent = "Unable to copy value",
  copiedPopoverContentDuration = 1000,
  hasPopover = true,
  popoverMinWidth = "max-content",
  popoverID = 0,
  popoverExtraStyles
}) => {
  const [popoverContent, setPopoverContent] = useState(initialPopoverContent);
  const [startTimer, setStartTimer] = useState(false);
  const [attemptCopy, setAttemptCopy] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const cleanup = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    if (startTimer) {
      if (timeoutId) {
        // If there's an active timeout, clean it up.
        cleanup();
      }
      // Start a timeout to restore popover content to the initial value.
      // Record the ID of the timeout so it can be cleaned up later.
      setTimeoutId(
        setTimeout(() => {
          setPopoverContent(initialPopoverContent);
        }, copiedPopoverContentDuration)
      );

      // Set startTimer to false immediately after starting the timer,
      // so subsequent clicks will start a new timer, refreshing the duration.
      setStartTimer(false);
    }

    // Return cleanup function so timeout will be cleared when component unmounts.
    return cleanup;
  }, [startTimer]);

  useEffect(() => {
    if (attemptCopy) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          setPopoverContent(copySuccessPopoverContent);
          onCopy?.();
          setAttemptCopy(false);
        })
        .catch(error => {
          console.error(error);
          setPopoverContent(copyErrorPopoverContent);
          setAttemptCopy(false);
        });
    }
  }, [attemptCopy]);

  const onClick = () => {
    setStartTimer(true);
    setAttemptCopy(true);
  };

  const popoverArrowWidth = "8px";

  return hasPopover ? (
    <Popover
      minWidth={popoverMinWidth}
      position={{
        top: "auto",
        right: "auto",
        bottom: "auto",
        left: `calc(-${popoverArrowWidth} - 8px)`
      }}
      arrowPosition={{
        arrowTop: "auto",
        arrowRight: "-8px",
        arrowBottom: `calc(50% - ${popoverArrowWidth})`,
        arrowLeft: "auto"
      }}
      transform="translate(-100%, -75%)"
      arrowDirection="right"
      popoverID={popoverID}
      buttonExtraStyles="margin: 0;min-width:auto"
      extraStyles={popoverExtraStyles}
      triggerText={CopyableContent({ content, onClick })}
      content={popoverContent}
    ></Popover>
  ) : (
    CopyableContent({ content, onClick })
  );
};

export default Copyable;
