import React, { useState, useEffect } from "react";
import { fallbackValues } from "./SearchableSelect.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Box, Stack } from "../layouts";
import { FormInput } from "../form-layouts";
import Checkbox from "../checkbox";

export const SELECT_ALL = "Select All";

const SearchableSelect = ({
  autocompleteValue,
  items,
  selectedItems,
  allSelected,
  toggleSelectAllItems,
  selectItem,
  fields,
  actions,
  disabled,
  themeValues,
  placeholder = "Search agencies"
}) => {
  const [itemList, setItemList] = useState([]);
  const completeOptionsList = itemList.sort((a, b) =>
    a.name == SELECT_ALL
      ? -1
      : b.name === SELECT_ALL
      ? 1
      : a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  useEffect(() => setItemList(items), [items, selectedItems]);
  useEffect(() => {
    const filteredItems = items.filter(item =>
      item?.name
        ?.toLowerCase()
        .includes(fields?.searchTerm?.rawValue?.toLowerCase())
    );
    setItemList(filteredItems);
  }, [fields.searchTerm.rawValue]);

  const handleSelect = value => {
    if (value.name === SELECT_ALL) {
      allSelected ? toggleSelectAllItems(false) : toggleSelectAllItems(true);
    } else {
      selectItem(value);
    }
  };

  return (
    <Box
      padding="1rem"
      border={themeValues.border}
      extraStyles={
        disabled &&
        `color: #6e727e; background-color: #f7f7f7; pointer-events: none;`
      }
    >
      <FormInput
        autocompleteValue={autocompleteValue}
        errorMessages={{}}
        field={fields.searchTerm}
        fieldActions={actions.fields.searchTerm}
        placeholder={placeholder}
        disabled={disabled}
      />
      <Box
        padding="0 0 0.5rem"
        extraStyles={`overflow-y: scroll; max-height: 250px;`}
      >
        <Stack>
          {completeOptionsList.map(option => (
            <Checkbox
              key={option.name}
              title={option.name}
              name={option.name}
              checked={
                !!selectedItems?.find(item => item?.name === option?.name)
              }
              onChange={() => handleSelect(option)}
              textExtraStyles={`margin: 0;`}
              disabled={disabled}
              extraStyles={`margin: 0.5rem;`}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default themeComponent(
  SearchableSelect,
  "SearchableSelect",
  fallbackValues,
  "default"
);
