import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import BoxWithShadow from "../box-with-shadow";
import Center from "../layouts/Center";
import Cluster from "../layouts/Cluster";
import NavTab from "./NavTab";

const NavTabs = ({ tabsConfig, tabGap }) => {
  const { isMobile } = useContext(ThemeContext);

  const tabs = tabsConfig.map(tabConfig => {
    return <NavTab key={tabConfig.path} {...tabConfig} />;
  });
  return (
    <BoxWithShadow variant="insetStandard" extraStyles="padding: 0">
      <Center intrinsic>
        {isMobile ? tabs : <Cluster childGap={tabGap}>{tabs}</Cluster>}
      </Center>
    </BoxWithShadow>
  );
};

export default NavTabs;
