import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { deactivateScheduleAttempt } from "../../graphql/Queries";
import {
  DEACTIVATE_PAYMENT_SCHEDULE,
  deactivatePaymentScheduleSuccess,
  deactivatePaymentScheduleFailure,
  accountDetailsAlertBarAction,
  accountsAlertBarAction,
  propertiesAlertBarAction,
  removeSchedule
} from "../../Profile.state";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import { getConstituentID } from "../../Profile.selectors";
import { isInCustomerManagement } from "/util/router-utils";

const successAlert = isPaymentPlan =>
  addAlert({
    heading: "Payment Schedule Removed",
    text: `${
      isPaymentPlan ? "A payment plan" : "Autopay"
    } for your account has been turned off.`,
    variant: "success"
  });

const errorAlert = isPaymentPlan =>
  addAlert({
    heading: "Payment Removal Errors",
    text: `An error occurred when disabling ${
      isPaymentPlan ? "a payment plan" : "autopay"
    } for your account. Please try again.`,
    variant: "error"
  });

const handleSuccessPageAlert = isPaymentPlan =>
  window.location.pathname.split("/").includes("details")
    ? accountDetailsAlertBarAction(successAlert(isPaymentPlan))
    : isPaymentPlan
    ? propertiesAlertBarAction(successAlert(isPaymentPlan))
    : accountsAlertBarAction(successAlert(isPaymentPlan));

const handleErrorPageAlert = isPaymentPlan =>
  window.location.pathname.split("/").includes("details")
    ? accountDetailsAlertBarAction(errorAlert(isPaymentPlan))
    : isPaymentPlan
    ? propertiesAlertBarAction(successAlert(isPaymentPlan))
    : accountsAlertBarAction(errorAlert(isPaymentPlan));

const getDeactivateScheduleArgs = (key, selectors, action, state) => {
  return {
    endpoint: selectors.getGraphqlServiceEndpoint(state),
    clientSlug: selectors.getClientSlug(state),
    authJwt: isInCustomerManagement
      ? selectors.getAdminAccessToken(state)
      : selectors.getAccessToken(state),
    schedule: {
      constituentID: getConstituentID(state),
      id: action.payload.schedule
    }
  };
};

/*
  Only one schedule per user for now, so deactivating wipes all schedules
*/
const deactivateScheduleEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(DEACTIVATE_PAYMENT_SCHEDULE).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        deactivateScheduleAttempt(
          getDeactivateScheduleArgs(profileKey, selectors, action, state$.value)
        )
      ).pipe(
        RxOp.mergeMap(response =>
          Rx.of(
            accountDetailsAlertBarAction(clearAlerts()),
            deactivatePaymentScheduleSuccess(
              response.deactivateSchedule,
              action?.payload?.isPaymentPlan
            ),
            removeSchedule(response.deactivateSchedule),
            handleSuccessPageAlert(action?.payload?.isPaymentPlan)
          )
        )
      )
    ),
    RxOp.catchError(error => {
      console.warn("disable autopay error", error);
      return Rx.of(
        accountDetailsAlertBarAction(clearAlerts()),
        deactivatePaymentScheduleFailure(error),
        handleErrorPageAlert(action?.payload?.isPaymentPlan)
      );
    })
  );

export default deactivateScheduleEpic;
