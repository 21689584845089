import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { combineEpics } from "redux-observable";

import {
  getCartPaymentConfig,
  getSubClientPaymentConfig,
  getPaymentConfigCustomAttrs,
  getPaymentConfigOptions,
  getPaymentConfigServiceName,
  getCartLineItems,
  getCartAccountNumber,
  getVisitIdFromCart,
  getAccountLookupConfigKey
} from "./MultiCart.selectors";
import { createPayment } from "../../payment/Payment.state";
import {
  ADD_TO_MULTI_CART,
  CHECKOUT_FROM_MULTI_CART,
  CLEAR_MULTI_CART,
  REMOVE_FROM_MULTI_CART,
  clearScreenReaderMessage,
  closeCartSlider,
  handleMultiCartFocus,
  openCartSlider,
  populateMultiCartPaymentConfig,
  addScreenReaderMessage
} from "./ShoppingCart.state";
import { formatName } from "../../../../../util/general";

const populatePaymentConfigEpic = (action$, state$) =>
  action$.ofType(ADD_TO_MULTI_CART).pipe(
    RxOp.flatMap(({ payload: { cartId } }) => {
      return getCartPaymentConfig(state$.value, cartId) === null
        ? Rx.of(
            populateMultiCartPaymentConfig({
              paymentConfig: getSubClientPaymentConfig(state$.value, cartId),
              cartId
            })
          )
        : Rx.EMPTY;
    })
  );

const openCartAfterItemsAddedEpic = action$ =>
  action$.ofType(ADD_TO_MULTI_CART).pipe(
    RxOp.flatMap(() => {
      return Rx.of(openCartSlider());
    })
  );

const handleRemoveItemFromCart = action$ => {
  return action$.ofType(REMOVE_FROM_MULTI_CART).pipe(
    RxOp.concatMap(action => {
      return Rx.from([
        handleMultiCartFocus({ focusArea: "cart-container" }),
        clearScreenReaderMessage(),
        addScreenReaderMessage({
          message: `Item deleted: ${
            action?.payload?.item?.description
          }, from ${formatName(action?.payload?.cartId)} Cart`
        })
      ]);
    })
  );
};

const handleClearCart = action$ => {
  return action$.ofType(CLEAR_MULTI_CART).pipe(
    RxOp.concatMap(action => {
      return Rx.from([
        handleMultiCartFocus({ focusArea: "cart-container" }),
        clearScreenReaderMessage(),
        addScreenReaderMessage({
          message: `Cart deleted: ${formatName(action?.payload?.cartId)}`
        })
      ]);
    })
  );
};

const checkoutFromCartEpic = (action$, state$) =>
  action$.ofType(CHECKOUT_FROM_MULTI_CART).pipe(
    RxOp.flatMap(({ payload: { cartId } }) =>
      Rx.of(
        closeCartSlider(),
        createPayment({
          lineItems: getCartLineItems(state$.value, cartId),
          serviceName: getPaymentConfigServiceName(state$.value, cartId),
          customAttributes: getPaymentConfigCustomAttrs(state$.value, cartId),
          accountId: getCartAccountNumber(state$.value, cartId),
          visitId: getVisitIdFromCart(state$.value, cartId),
          accountLookupConfigKey: getAccountLookupConfigKey(
            state$.value,
            cartId
          ),
          ...getPaymentConfigOptions(state$.value, cartId),
          cartId
        })
      )
    )
  );

const MultiCartEpic = combineEpics(
  populatePaymentConfigEpic,
  openCartAfterItemsAddedEpic,
  checkoutFromCartEpic,
  handleRemoveItemFromCart,
  handleClearCart
);
export default MultiCartEpic;
