import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import { TOKEN_STORAGE_NAME } from "@okta/okta-auth-js";
import dayjs from "dayjs";
import {
  ifElse,
  always,
  lte,
  split,
  indexOf,
  inc,
  nth,
  compose,
  converge,
  identity,
  isNil,
  equals,
  prop
} from "ramda";
import {
  CLEAR_LOCAL_STORAGE,
  CLEAR_LOCAL_STORAGE_AND_REDIRECT,
  clearLocalStorage,
  clearLocalStorageSuccess,
  setLaunchDarklyFlags,
  setLocalStorage,
  setOktaAuthFromLocalStorage
} from "../reducer";
import {
  createPaymentFromLocalStorage,
  setSessionFromLocalStorage
} from "../../apps/checkout/pages/payment/Payment.state";
import { checkoutLocalStorageKey } from "../../apps/checkout/pages/payment";
import { setCartFromLocalStorage } from "../../apps/checkout/pages/multi-cart/state/ShoppingCart.state";

export const loadState = localStorageKey => {
  try {
    const serializedState = localStorage.getItem(localStorageKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Returns :id from url pattern .../{idType}/:id if idType is present, otherwise returns undefined
const getIndexOfId = idType =>
  compose(ifElse(lte(0), inc, always(undefined)), indexOf(idType));

const parseInvoiceId = compose(
  converge(nth, [getIndexOfId("invoice"), identity]),
  split("/")
);

/**
 *
 * @param expireDateTime the ISO date string representing when cache should be expired
 * @returns if current date/time is after expiration, returns false, else returns true
 */
export const shouldLoadLocalStorage = expireDateTime => {
  const isDateTimeBeforeExpire = dayjs().isBefore(dayjs(expireDateTime));

  return isDateTimeBeforeExpire;
};

export const loadLocalStorageEpic = () => {
  const invoiceId = parseInvoiceId(window.location.pathname);
  const loadedCheckoutState = loadState(checkoutLocalStorageKey);
  const newInvoice =
    !isNil(invoiceId) &&
    !equals(invoiceId, prop("invoiceId", loadedCheckoutState));
  const loadedGlobalState = loadState("global");
  const loadedSessionState = loadState("session");
  const loadedCartState = loadState("cart");
  const expireDateTime = loadedSessionState?.expireDateTime ?? "";
  const loadedOktaTokenStorage = loadState(TOKEN_STORAGE_NAME);
  const loadedLaunchDarklyFlagsState = loadState("launchDarklyFlags");

  return shouldLoadLocalStorage(expireDateTime)
    ? Rx.of(
        ...[
          setLocalStorage(loadedGlobalState),
          setSessionFromLocalStorage(loadedSessionState),
          setCartFromLocalStorage(loadedCartState),
          setOktaAuthFromLocalStorage(loadedOktaTokenStorage),
          setLaunchDarklyFlags(loadedLaunchDarklyFlagsState),
          ...(loadedCheckoutState && !newInvoice
            ? [createPaymentFromLocalStorage(loadedCheckoutState)]
            : [])
        ]
      )
    : Rx.of(clearLocalStorage());
};

export const clearLocalStorageEpic = action$ =>
  action$.ofType(CLEAR_LOCAL_STORAGE).pipe(
    RxOp.flatMap(() => {
      // Remove specific localStorage items to avoid clearing auth state too
      // Auth state clear handled by redux-auth-refresh
      localStorage.removeItem("global");
      localStorage.removeItem("session");
      localStorage.removeItem("cart");

      return Rx.of(clearLocalStorageSuccess());
    })
  );

export const clearLocalStorageAndRedirectEpic = action$ =>
  action$.ofType(CLEAR_LOCAL_STORAGE_AND_REDIRECT).pipe(
    RxOp.tap(({ payload: { url } }) => {
      localStorage.removeItem("global");
      localStorage.removeItem("session");
      localStorage.removeItem("cart");
      window.location = url;
    })
  );
