import React from "react";
import { Notifier } from "@airbrake/browser";
import {
  GenericErrorIcon,
  Stack,
  Cover,
  Center,
  constants
} from "@thecb/components";
import styled from "styled-components";

/*
  Because this component potentially renders when most of the app has crashed,
  we have to use custom styled components, as we will not have access to most
  of the components in CB Components, except the error icon and layout atoms
*/

const ErrorBoundaryTitle = styled.h1`
  font-size: 2rem;
  line-height: 1.5;
  color: ${constants.colors.FIREFLY_GREY};
  font-weight: ${constants.fontWeights.FONT_WEIGHT_SEMIBOLD};
  text-align: center;
  font-family: Public Sans, Merriweather, Tahoma, Sans-Serif;
  padding-top: 1rem;
`;

const ErrorBoundaryDetail = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  color: ${constants.colors.FIREFLY_GREY};
  font-weight: ${constants.fontWeights.FONT_WEIGHT_REGULAR};
  text-align: center;
  font-family: Public Sans, Merriweather, Tahoma, Sans-Serif;
  padding-bottom: 1rem;
`;

const ErrorBounderyLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  min-width: 130px;
  width: 130px;
  height: 2rem;
  padding: 0.75rem 1.5rem;
  color: WHITE;
  background-color: #3b5bdb;
  border: 2px solid #3b5bdb;
  font-family: Public Sans, Merriweather, Tahoma, Sans-Serif;
  border-radius: 4px;
  padding: 0.5rem 1.5rem;

  &:hover {
    background-color: #324dba;
    border: 2px solid #324dba;
  }

  &:active {
    background-color: #293f99;
    border: 2px solid #293f99;
  }
`;

/*
  As of 06/23, ErrorBoundary components must still be written in Class Component style
*/

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    const { appEnv } = this.props;

    if (appEnv !== "local") {
      this.airbrake = new Notifier({
        projectId: this.props.airbrakeProjectId,
        projectKey: this.props.airbrakeProjectKey,
        environment: appEnv
      });
      this.airbrake.addFilter(notice => {
        if (appEnv === undefined) {
          return null;
        }

        notice.context.environment = appEnv;

        return notice;
      });
    } else {
      this.airbrake = {
        notify: _ => null
      };
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });
    this.airbrake.notify({
      error,
      context: {
        component: "<ErrorBoundary />"
      },
      params: {
        info
      }
    });
    console.group(
      "%cError caught by Error Boundary, expand for error and stack trace",
      "background-color: rgba(175, 12, 0, 0.25); color: #db0000; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #db0000;"
    );
    console.log(`Error caught by Error Boundary:`, error);
    console.log(`Stack trace of error:`, info.componentStack);
    console.groupEnd(
      "%cError caught by Error Boundary, expand for error and stack trace",
      "background-color: rgba(175, 12, 0, 0.25); color: #db0000; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #db0000;"
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <Cover minHeight="100vh" singleChild>
          <Center intrinsic>
            <Stack padding="0" childGap="0.5rem">
              <GenericErrorIcon />
              <ErrorBoundaryTitle>Something Went Wrong</ErrorBoundaryTitle>
              <ErrorBoundaryDetail>
                Please return home and try again.
              </ErrorBoundaryDetail>
              <ErrorBounderyLink href="/">Return Home</ErrorBounderyLink>
            </Stack>
          </Center>
        </Cover>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
