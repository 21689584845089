import React, { useEffect } from "react";
import { required, hasLength } from "redux-freeform";
import StateProvinceDropdown from "../../atoms/state-province-dropdown";
import Checkbox from "../../atoms/checkbox";
import CountryDropdown from "../../atoms/country-dropdown";
import { noop } from "../../../util/general";
import { zipFormat } from "../../../util/formats";
import {
  FormInput,
  FormContainer,
  FormInputColumn
} from "../../atoms/form-layouts";

const AddressForm = ({
  variant = "default",
  fields,
  actions,
  clearOnDismount,
  showErrors,
  handleSubmit = noop,
  showWalletCheckbox,
  saveToWallet,
  walletCheckboxMarked,
  dataQa = null
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }

  const street1ErrorMessages = {
    [required.error]: "Street is required"
  };
  const cityErrorMessages = {
    [required.error]: "City is required"
  };
  const zipErrorMessages = {
    [required.error]: "Zip code is required",
    [hasLength.error]: "Zip code must be 5 or 9 digits"
  };
  const stateProvinceErrorMessages = {
    [required.error]: "State or Province is required"
  };
  const countryErrorMessages = {
    [required.error]: "Country is required"
  };

  const isUS = fields.country.rawValue === "US";

  return (
    <FormContainer
      variant={variant}
      role="form"
      aria-label="Address"
      dataQa={dataQa}
    >
      <FormInputColumn>
        <CountryDropdown
          labelTextWhenNoError="Country"
          errorMessages={countryErrorMessages}
          field={fields.country}
          onChange={value => {
            actions.fields.country.set(value);
            // temporary measure to not dirty fields until
            // we can write a reset function for fields
            if (fields.stateProvince.rawValue) {
              actions.fields.stateProvince.set("");
            }
            if (fields.zip.rawValue) {
              actions.fields.zip.set("");
            }
          }}
          showErrors={showErrors}
          dataQa="Country"
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Address"
          errorMessages={street1ErrorMessages}
          field={fields.street1}
          fieldActions={actions.fields.street1}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="address-line1"
          dataQa="Address"
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Apt, Suite, Unit, Floor, etc. (Optional)"
          field={fields.street2}
          fieldActions={actions.fields.street2}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="address-line2"
          dataQa="Address Line 2"
        />
        <FormInput
          labelTextWhenNoError="City"
          errorMessages={cityErrorMessages}
          field={fields.city}
          fieldActions={actions.fields.city}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="address-level2"
          dataQa="City"
          isRequired={true}
        />
        <StateProvinceDropdown
          labelTextWhenNoError={isUS ? "State" : "State or Province"}
          errorMessages={stateProvinceErrorMessages}
          countryCode={fields.country.rawValue || "US"}
          field={fields.stateProvince}
          fieldActions={actions.fields.stateProvince}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          dataQa="State or Province"
          isRequired={true}
        />
        <FormInput
          isNum={isUS}
          formatter={isUS ? zipFormat : null}
          labelTextWhenNoError="Zip code"
          errorMessages={zipErrorMessages}
          field={fields.zip}
          fieldActions={actions.fields.zip}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="postal-code"
          dataQa="Zip code"
          isRequired={true}
        />
        {showWalletCheckbox && (
          <Checkbox
            name="address checkbox"
            title="Save address to wallet"
            checked={walletCheckboxMarked}
            onChange={saveToWallet}
          />
        )}
      </FormInputColumn>
    </FormContainer>
  );
};

export default AddressForm;
