import React from "react";

const GenericCardLarge = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Card Payment"
    >
      <rect width="36" height="24" rx="2" fill="#15749D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17.3333C4.44772 17.3333 4 17.781 4 18.3333C4 18.8856 4.44772 19.3333 5 19.3333H10C10.5523 19.3333 11 18.8856 11 18.3333C11 17.781 10.5523 17.3333 10 17.3333H5ZM14 17.3333C13.4477 17.3333 13 17.781 13 18.3333C13 18.8856 13.4477 19.3333 14 19.3333H23C23.5523 19.3333 24 18.8856 24 18.3333C24 17.781 23.5523 17.3333 23 17.3333H14Z"
        fill="#E4F4FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0357 7V9.04167H24V8C24 7.44771 24.4477 7 25 7H26.0357ZM26.2857 7V9.04167H28.7142V7H28.9642V9.04167H30.9997V9.29167H28.9642V11.0417L30.9997 11.0417L30.9997 11.2917L28.9642 11.2917V13L29.9999 13C30.5522 13 30.9999 12.5523 30.9999 12V8C30.9999 7.44772 30.5522 7 29.9999 7H26.2857ZM28.7142 13V11.2917L26.2857 11.2917V13L28.7142 13ZM26.0357 13V11.2917L24 11.2917V12C24 12.5523 24.4477 13 25 13L26.0357 13ZM24 11.0417L26.0357 11.0417V9.29167H24V11.0417ZM26.2857 11.0417V9.29167H28.7142V11.0417L26.2857 11.0417Z"
        fill="#F4C932"
      />
    </svg>
  );
};

export default GenericCardLarge;
