/*
These constants are intended to aid the formatting of strings,
such as the correct capitalization of abbreviations or
uncapitalized common parts of speech.
*/
const CONJUNCTIONS = ["for", "and", "nor", "but", "or", "yet", "so"];
const ARTICLES = ["a", "an", "the"];
const PREPOSITIONS = [
  "of",
  "to",
  "in",
  "for",
  "on",
  "with",
  "by",
  "but",
  "at",
  "from",
  "about",
  "like",
  "into",
  "up",
  "out",
  "through",
  "over",
  "off"
];
export const PARTS_OF_SPEECH = CONJUNCTIONS.concat(ARTICLES).concat(
  PREPOSITIONS
);
export const STATE_ABBREVIATIONS = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];
