import React from "react";
import { Box, Switcher, Cluster } from "../../atoms/layouts";
import {
  BRIGHT_GREY,
  GHOST_GREY,
  ATHENS_GREY,
  TRANSPARENT,
  COOL_GREY_05
} from "../../../constants/colors";
import Text from "../../atoms/text";
import { InternalLink } from "../../atoms/link";
import {
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR
} from "../../../constants/style_constants";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./TabSidebar.theme";

const TabSidebar = ({ links, isMobile, themeValues }) => (
  <Box
    padding="0"
    background={COOL_GREY_05}
    minHeight="100%"
    role="region"
    aria-label="Profile tabs"
    boxShadow={isMobile && `inset 0px -1px 0px 0px rgb(202, 206, 216)`}
  >
    <Switcher
      breakpoint="20rem"
      childGap="0.1px"
      padding="0"
      extraStyles={!isMobile && `overflow: visible;`}
    >
      {links.map(({ route, text, active, icon }, index) => (
        <InternalLink
          to={route}
          key={`${route}-${index}`}
          extraStyles={`text-decoration: none;
            &:hover {
            ${
              active
                ? `> * {
                background-color: ${themeValues.activeTabHover};
              }`
                : `> * { 
                background-color: rgba(8, 27, 43, 0.05);
              }`
            }}`}
        >
          <Box
            padding={isMobile ? "6px 4px" : "18px 16px"}
            background={
              active ? themeValues.activeTabBackground : "transparent"
            }
            borderSize={active ? "3px" : "1px"}
            borderColor={active ? themeValues.activeTabAccent : TRANSPARENT}
            borderWidthOverride="0 0 3px 0"
            boxShadow={
              active
                ? `0px 2px 4px 0px ${GHOST_GREY}, 0px 2px 9px 1px ${GHOST_GREY}`
                : `0px 2px 4px 0px ${TRANSPARENT}, 0px 2px 9px 1px ${TRANSPARENT}`
            }
            minWidth="100%"
            borderRadius="4px 4px 0 0"
          >
            <Box
              padding="0"
              minWidth="100%"
              extraStyles={
                isMobile ? `text-align: center;` : `text-align: left;`
              }
            >
              <Cluster
                justify={isMobile ? "center" : "flex-start"}
                align="center"
              >
                {icon}
                <Text
                  variant={isMobile ? "pXXS" : "pS"}
                  weight={active ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR}
                  color={active ? themeValues.activeTabAccent : BRIGHT_GREY}
                  extraStyles={`text-transform: uppercase; ${
                    isMobile
                      ? `width: 100%; padding-top: 4px;`
                      : `padding-left: 8px;`
                  }`}
                >
                  {text}
                </Text>
              </Cluster>
            </Box>
          </Box>
        </InternalLink>
      ))}
    </Switcher>
  </Box>
);

export default themeComponent(TabSidebar, "ProfileTab", fallbackValues);
