import React from "react";
import TermsAndConditionsControlV1 from "./TermsAndConditionsControlV1";
import TermsAndConditionsControlV2 from "./TermsAndConditionsControlV2";

const TermsAndConditions = ({ version = "v1", ...rest }) => {
  const TermsAndConditionsControl =
    version === "v1"
      ? TermsAndConditionsControlV1
      : TermsAndConditionsControlV2;

  return <TermsAndConditionsControl {...rest} />;
};

export default TermsAndConditions;
