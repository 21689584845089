import {
  createFormState,
  required,
  onlyNaturals,
  validateSum,
  numberGreaterThanOrEqualTo,
  numberLessThanOrEqualTo
} from "redux-freeform";

export const createPartialAmountFormState = (
  lineItems,
  maximum,
  minimum = 1,
  blockPartialPaymentOverpay = false
) => {
  const formConfig = lineItems.reduce((acc, item) => {
    const validators = createPartialAmountFormValidators(
      item,
      lineItems,
      maximum,
      minimum,
      blockPartialPaymentOverpay
    );
    return {
      ...acc,
      [item.id]: {
        validators: validators,
        constraints: [onlyNaturals()],
        defaultValue: String(item.amount)
      }
    };
  }, {});
  const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
    formConfig
  );
  return {
    partialAmountFormReducer: reducer,
    partialAmountFormMapStateToProps: mapStateToProps,
    partialAmountFormMapDispatchToProps: mapDispatchToProps
  };
};

export const createPartialAmountFormValidators = (
  item,
  lineItems,
  maximum,
  minimum = 1,
  blockPartialPaymentOverpay = false
) => {
  const validators = [
    required(),
    validateSum(
      numberGreaterThanOrEqualTo(minimum),
      lineItems
        .filter(lineItem => lineItem != item)
        .reduce((acc, curr) => [...acc, curr.id], [])
    )
  ];
  if (!!maximum) {
    validators.push(
      validateSum(
        numberLessThanOrEqualTo(maximum),
        lineItems
          .filter(lineItem => lineItem != item)
          .reduce((acc, curr) => [...acc, curr.id], [])
      )
    );
  }
  if (blockPartialPaymentOverpay) {
    validators.push(numberLessThanOrEqualTo(item.amount));
  }

  return validators;
};
