import React, { useContext } from "react";
import { Box, Checkbox, Stack, withWindowSize } from "@thecb/components";
import { ThemeContext } from "styled-components";
import { v4 as uuidv4 } from "uuid";

const Filter = ({ choices, selected, onSelected, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);
  return (
    <Stack childGap="15px">
      {choices.map(choice => {
        const checked = !!selected.find(selection => selection === choice);
        return (
          <Checkbox
            key={uuidv4()}
            title={
              <Box padding={0} maxWidth={isMobile ? "80vw" : "180px"}>
                {choice}
              </Box>
            }
            name={choice}
            checked={checked}
            themeValues={themeValues}
            onChange={onSelected}
          />
        );
      })}
    </Stack>
  );
};

export default withWindowSize(Filter);
