import EmailForm from "./EmailForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./EmailForm.state";

EmailForm.reducer = reducer;
EmailForm.mapStateToProps = mapStateToProps;
EmailForm.mapDispatchToProps = mapDispatchToProps;
export default EmailForm;
