import React from "react";
import { tint } from "polished";
import { Box, ButtonWithAction, Cluster, Motion } from "@thecb/components";
import PlusIcon from "../../icons/PlusIcon";
import DashIcon from "../../icons/DashIcon";
import NavLabel from "./NavLabel";
import NavLink, { NavLinkLevels } from "./NavLink";
import { getCallToActionInfo } from "../../../util/dataAdapters";

const HamburgerSectionTitle = ({
  label,
  link,
  themeValues,
  toggleSection,
  isOpen
}) => {
  const collapsibleControlFocusStyles = `border: none; outline: none;background: ${tint(
    0.8,
    themeValues.linkColor
  )};`;
  const borderStyle = `border-left: 1px solid #ebeffb;`;
  const iconPlus = {
    open: {
      rotate: "-90deg",
      opacity: 0
    },
    closed: {
      rotate: "0deg",
      opacity: 1
    }
  };
  const iconDash = {
    closed: {
      rotate: "90deg",
      opacity: 0
    },
    open: {
      rotate: "0deg",
      opacity: 1
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      toggleSection();
    }
  };

  const { ctaUrl } = getCallToActionInfo(
    link.callToActionLink,
    link.name,
    link.nameUrl
  );

  return (
    <Box
      padding="0"
      role="heading"
      aria-label={label}
      aria-level={3}
      onKeyDown={handleKeyDown}
    >
      <Cluster justify="space-between" align="center">
        {ctaUrl ? (
          <NavLink
            url={ctaUrl}
            label={link.name}
            themeValues={themeValues}
            navLevel={NavLinkLevels.TOP_LEVEL}
          />
        ) : (
          <NavLabel text={link.name} navLevel={0} themeValues={themeValues} />
        )}
        <ButtonWithAction
          variant="ghost"
          contentOverride
          action={toggleSection}
          activeStyles={collapsibleControlFocusStyles}
          hoverStyles={borderStyle}
          borderRadius={0}
          border={"1px"}
          extraStyles={`padding: 0; min-height: 56px; min-width: 56px; margin: 0;outline: none !important; ${borderStyle}`}
        >
          <Motion
            variants={iconPlus}
            transition={{ duration: 0.3 }}
            extraStyles={isOpen && "display:none;"}
          >
            <PlusIcon size={24} />
          </Motion>
          <Motion
            variants={iconDash}
            transition={{ duration: 0.3 }}
            extraStyles={!isOpen && "display:none;"}
          >
            <DashIcon size={24} />
          </Motion>
        </ButtonWithAction>
      </Cluster>
    </Box>
  );
};

export default HamburgerSectionTitle;
