import React, { forwardRef } from "react";
import { Text, Center } from "../../../atoms";
import DropdownIconV2 from "../../../atoms/dropdown/DropdownIconV2";
import { StyledFilterButton } from "../MultipleSelectFilter.styled";
import { noop } from "../../../../util/general";

const FilterButton = forwardRef(
  (
    {
      btnContentOverride,
      action = noop,
      opened,
      backgroundColor,
      backgroundHoverColor,
      textColor,
      textHoverColor,
      name,
      filterDropdownID,
      hasIcon = false,
      icon: Icon,
      truncateBtnTextWidth,
      filterLabel,
      selectedOptions,
      extraStyles
    },
    ref
  ) => {
    const btnTextFilterDescription = selectedOptions?.length
      ? `${filterLabel ? `${filterLabel}: ` : ""}${selectedOptions[0].name}`
      : `${filterLabel ? filterLabel : ""}`;
    const btnTextItemsDescription =
      selectedOptions?.length && selectedOptions?.length > 1
        ? `, +${selectedOptions?.length - 1} More`
        : "";
    return (
      <StyledFilterButton
        ref={ref}
        variant="tertiary"
        action={action}
        aria-haspopup="listbox"
        aria-expanded={opened}
        aria-controls={filterDropdownID}
        backgroundColor={backgroundColor}
        backgroundHoverColor={backgroundHoverColor}
        textColor={textColor}
        textHoverColor={textHoverColor}
        opened={opened}
        dataQa={`${name}-filter-button`}
        extraStyles={extraStyles}
        aria-label={`${filterLabel} Filter: ${btnTextFilterDescription} ${btnTextItemsDescription}`}
        contentOverride
      >
        {btnContentOverride ? (
          btnContentOverride
        ) : (
          <Center
            as="span"
            style={{ display: "flex", flexDirection: "row" }}
            intrinsic
          >
            {hasIcon && <Icon color={opened ? textHoverColor : textColor} />}
            <Center
              as="span"
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 0.5rem 0 0.25rem"
              }}
              intrinsic
            >
              <Text
                variant="pS"
                color={opened ? textHoverColor : textColor}
                extraStyles={`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  ${truncateBtnTextWidth && `max-width:` + truncateBtnTextWidth}
                `}
              >
                {btnTextFilterDescription}
              </Text>
              <Text color={opened ? textHoverColor : textColor} variant="pS">
                {btnTextItemsDescription}
              </Text>
            </Center>
            <DropdownIconV2 color={opened ? textHoverColor : textColor} />
          </Center>
        )}
      </StyledFilterButton>
    );
  }
);

export default FilterButton;
