import React from "react";

const AchReturnIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -64.000000)"
        fill="#B34A00"
      >
        <path
          d="M80,64 C88.836556,64 96,71.163444 96,80 C96,88.836556 88.836556,96 80,96 C71.163444,96 64,88.836556 64,80 C64,71.163444 71.163444,64 80,64 Z M77.4246212,73.2824856 C77.2293591,73.0872234 76.9127766,73.0872234 76.7175144,73.2824856 L76.7175144,73.2824856 L70.3535534,79.6464466 C70.1582912,79.8417088 70.1582912,80.1582912 70.3535534,80.3535534 L70.3535534,80.3535534 L76.7175144,86.7175144 C76.9127766,86.9127766 77.2293591,86.9127766 77.4246212,86.7175144 L77.4246212,86.7175144 L78.131728,86.0104076 C78.3269901,85.8151455 78.3269901,85.498563 78.131728,85.3033009 L78.131728,85.3033009 L73.8271068,80.999039 L89.5,81 C89.7761424,81 90,80.7761424 90,80.5 L90,80.5 L90,79.5 C90,79.2238576 89.7761424,79 89.5,79 L89.5,79 L73.8281068,78.999039 L78.131728,74.6966991 C78.3269901,74.501437 78.3269901,74.1848545 78.131728,73.9895924 L78.131728,73.9895924 Z"
          id="status-icon---ach-return"
        ></path>
      </g>
    </g>
  </svg>
);

export default AchReturnIcon;
