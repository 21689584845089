import {
  any as ramdaAny,
  compose as ramdaCompose,
  concat as ramdaConcat,
  equals as ramdaEquals,
  filter as ramdaFilter,
  findIndex as ramdaFindIndex,
  flatten as ramdaFlatten,
  is as ramdaIs,
  isEmpty as ramdaIsEmpty,
  lensIndex as ramdaLensIndex,
  lensPath as ramdaLensPath,
  map as ramdaMap,
  mapObjIndexed as ramdaMapObjIndexed,
  mergeAll as ramdaMergeAll,
  path as ramdaPath,
  pipe as ramdaPipe,
  prop as ramdaProp,
  propEq as ramdaPropEq,
  propOr as ramdaPropOr,
  set as ramdaSet,
  // flow can't find ramda
  values as ramdaValues,
  view as ramdaView
} from "ramda";
import Maybe from "folktale/maybe";
import Result from "folktale/result";
import { card } from "creditcards";
import { setSessionItem } from "./storage";

const GRAPH_COMPOSITE_TYPE = "graph_composite";
const NUMBER_OF_CHARS_VISIABLE = 4;

// flattens nested refs
// for nested component validation in tab components

export function flattenByKey(targetKey, targetObj) {
  const getKeyRecur = (num, key, obj) => {
    const refs = ramdaPath([targetKey], obj[key]);
    if (refs && ramdaIsEmpty(refs)) {
      return { [key]: obj[key] };
    } else {
      return ramdaValues(ramdaMapObjIndexed(getKeyRecur, refs));
    }
  };
  // after recursively mapping over the keys at 'key' in the target object
  // apply ramda values twice to extract the actual component from the
  // returned object. Flatten that array, adding the target object to that
  // array so that all the objects in the array can be merged into one object
  // for consumption in the workflow render
  // -startamella 2018-02-22
  return ramdaCompose(
    ramdaMergeAll,
    ramdaConcat([targetObj]),
    ramdaFlatten,
    ramdaValues,
    ramdaMapObjIndexed(getKeyRecur)
  )(targetObj);
}

// "part/of/path" -> "path"
export function getLastPath(fullPath) {
  if (typeof fullPath !== "string") {
    return null;
  } else {
    return fullPath.substr(fullPath.lastIndexOf("/") + 1);
  }
}

// "4111111111111111" -> "************1111"
// -startamella 2018-01-11
export function redactAllButLastFourCharacters(cardNumber, redactString) {
  const redactChar = redactString || "*";
  if (
    cardNumber &&
    ramdaIs(String, cardNumber) &&
    cardNumber.length > NUMBER_OF_CHARS_VISIABLE
  ) {
    const trimmedCardNumber = cardNumber.replace(/\s/g, "");
    return trimmedCardNumber
      .slice(trimmedCardNumber.length - NUMBER_OF_CHARS_VISIABLE)
      .padStart(trimmedCardNumber.length, redactChar);
  } else {
    return "";
  }
}

// "4111111111111111" -> "4111 1111 1111 1111"
// -startamella 2018-01-11
export function formatCreditCardNumber(cardNumber) {
  return ramdaCompose(card.format, card.parse)(cardNumber) || "";
}

// "4111 1111 1111 1111" -> "4111111111111111"
// -startamella 2018-01-22
export function parseCreditCardNumber(cardNumber) {
  return card.parse(cardNumber) || "";
}

// "4111111111111111" -> "Visa" || "MasterCard" || "AmericanExpress" || "Discover"
// -startamella 2018-01-11
export function getCreditCardType(cardNumber) {
  return ramdaCompose(card.type, card.parse)(cardNumber) || "";
}

// "4111111111111111" -> true
// validates against luhn algo
// -startamella 2018-01-11
export function isCreditCardValid(cardNumber) {
  return ramdaCompose(card.luhn, card.parse)(cardNumber) || false;
}

export function coerceIndex(id, screens) {
  switch (typeof id) {
    case "string":
      const newIndex = ramdaFindIndex(ramdaPropEq("name", id))(screens);
      if (newIndex === -1) {
        console.error(`Could not find a screen with the name of ${id}`);
        return 0;
      }
      return newIndex;
    case "number":
      return id;
    default:
      console.error("screen_index is not of expected type");
      return 0;
  }
}

export function slugify(text) {
  if (!text || typeof text !== "string") {
    return "";
  }
  let result = text.toLowerCase().replace(/\s+/g, "-");
  return result.replace(/['"]+/g, "").replace(/(^-+|-+$)/g, "");
}

// extracts a list of graph_composite ids needed to complete the workflow
// Array -> Array
// -startamella 2017-12-4
function extractNodes(nodes) {
  return ramdaCompose(
    ramdaFilter(node => ramdaPath(["type"], node) === GRAPH_COMPOSITE_TYPE),
    ramdaMap(nodeN => {
      const nodeName = ramdaPath(["name"], nodeN);
      return {
        id: ramdaPath(["data", "id"], nodeN),
        type: ramdaPath(["data", "type"], nodeN),
        config: ramdaPath(["data", "config"], nodeN) || {},
        lens: ramdaLensIndex(
          ramdaFindIndex(ramdaPropEq("name", nodeName))(nodes)
        )
      };
    }),
    ramdaFlatten
  )(nodes);
}

// wraps extractNodes in a Result
// GraphDocumentType -> Result String GraphCompositeArrayType
// -startamella 2017-11-29
export function extractGraphComposite(graphDocument) {
  const nodes = ramdaPath(["nodes"], graphDocument);
  return Result.fromNullable(nodes).matchWith({
    Ok: ({ value }) => {
      return Result.Ok(extractNodes(value));
    },
    Error: () => {
      const error = "workflow is malformed";
      return Result.Error(error);
    }
  });
}

// Config -> GraphDocumentType -> Result String GraphDocumentType
// -startamella 2017-11-29
export function applyConfiguration(config, graphComposite) {
  const maybeGraphComposite = Maybe.fromNullable(graphComposite);
  const maybeConfig = Maybe.fromNullable(config);
  const graphCompositeError = "graphComposite is malformed";
  const configError = "config is malformed";
  return Result.fromMaybe(maybeGraphComposite, graphCompositeError).matchWith({
    Ok: ({ value }) => {
      return Result.fromMaybe(maybeConfig, configError).matchWith({
        /* eslint flowtype/no-weak-types: 0 */
        Ok: ({ value }) => {
          // this is a no-op for now, simply returning the graphComposite
          return Result.Ok(graphComposite);
        },
        Error: ({ value }) => {
          return Result.Error(value);
        }
      });
    },
    Error: ({ value }) => {
      return Result.Error(value);
    }
  });
}

// imports a single graph_composite into the workflow
// GraphDocumentType -> GraphDocumentType -> GraphCompositeObjectType -> Result String GraphDocumentType
// -startamella 2017-11-29
export function importGraphComposite(
  composite,
  graphDocument,
  graphCompositeObject
) {
  const graphCompositeLens = ramdaPath(["lens"], graphCompositeObject);
  if (graphCompositeLens && composite && graphDocument) {
    const error = "nodes do not exist on composite:";
    const nodes = ramdaPath(["nodes"], composite);
    return Result.fromNullable(nodes, error).matchWith({
      Ok: ({ value }) => {
        const newGraphDocumentNodes = ramdaFlatten(
          ramdaSet(graphCompositeLens, value, graphDocument.nodes)
        );
        const newGraphDocument = ramdaSet(
          ramdaLensPath(["nodes"]),
          newGraphDocumentNodes,
          graphDocument
        );
        return Result.Ok(newGraphDocument);
      },
      Error: ({ value }) => {
        return Result.Error(value);
      }
    });
  } else {
    const error = "params missing";
    return Result.Error(error);
  }
}

export const DEBOUNCE_WAIT_MS = 500;

export function debounce(func, wait = DEBOUNCE_WAIT_MS, immediate = false) {
  let timeout;

  return function _debounce() {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function collectAllComponents(componentGroups) {
  const getComponentAndChildren = group => {
    if (group.components && !ramdaIsEmpty(group.components)) {
      return group.components.reduce((accumulator, currentComponent) => {
        return accumulator
          .concat([currentComponent])
          .concat(getComponentAndChildren(currentComponent));
      }, []);
    } else {
      return [];
    }
  };
  return ramdaFlatten(
    componentGroups.map(group => getComponentAndChildren(group))
  );
}

export function isMobile() {
  return window.matchMedia("(max-width: 767px)").matches;
}

export function bindApiResponse(bindings, response, bindingFunction) {
  bindings.forEach(binding => {
    const {
      key_location: keyPath,
      binding_location: bindingPath,
      session_key: sessionKey
    } = binding;
    if (keyPath) {
      const data = ramdaPath(keyPath, response);
      if (bindingPath) {
        bindingFunction(bindingPath, data);
      } else if (sessionKey) {
        setSessionItem(sessionKey, data);
      }
    }
  });
}

// If an item in the locationPath argument is an array, it is used to retrieve
// a value at that location in the data argument, and that child array
// is replaced by the value that was retrieved from the data
// For example if locationPath is:
// ['payment', ['payment', 'payment_type'], 'email_address']
// and data is { payment: { payment_type: 'credit_card' } }
// then the return value will be ['payment', 'credit_card', 'email_address']
export function createDynamicLocationPath(locationPath, data) {
  return ramdaMap(location => {
    return ramdaIs(Array, location) ? ramdaPath(location, data) : location;
  }, locationPath);
}

export function computeUrl({ description, slug }, path) {
  const re = /^\[(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?\]$/gm;

  if (!description || !description.match(re)) {
    return `/${path}/${slug}`;
  }

  return description.substring(1, description.length - 1);
}

export function timeIsOutsideRange(
  startTime,
  endTime,
  currentTime = new Date()
) {
  const now = currentTime.getTime();
  // if startTime or endTime are invalid, that's OK because comparison to NaN always returns false
  return now < startTime.getTime() || now > endTime.getTime();
}

// The following is a table with regex values that attempt to guess
// The appropriate autocomplete value for a generic text field

const autocompleteTable = {
  "given-name": /\bfirst name\b/gi,
  "family-name": /\blast name\b/gi,
  "additional-name": /\bmiddle name\b/gi,
  "honorific-prefix": /\bhonorific|prefix\b/gi,
  "honorific-suffix": /\bsuffix\b/gi,
  nickname: /\bnickname\b/gi,
  organization: /\bcompany name|organization name|business name|name of company|name of organization|name of business\b/gi,
  "street-address": /^Full Address|Address$/gi,
  "address-line1": /\bstreet address|street number|street name|address line 1\b/gi,
  "address-line2": /\bapt|suite|unit|apartment|address line 2\b/gi,
  "address-level1": /^State$/gi,
  "address-level2": /^City|Town|Village|City\/Town$/gi,
  country: /\bcountry code\b/gi,
  "country-name": /^Country$/gi,
  "bday-day": /\bbirth date day\b/gi,
  "bday-month": /\bbirth date month\b/gi,
  "bday-year": /\bbirth date year\b/gi,
  bday: /\bbirthday|birth date|birthdate|date of birth\b/gi,
  "organization-title": /^title|job|role$/gi,
  name: /^Name$/gi,
  sex: /\bgender\b/gi,
  url: /\burl\b/gi
};

export const getAutocompleteValue = inputTitle => {
  const result = Object.entries(
    autocompleteTable
  ).find(([_, autocompleteTest]) => autocompleteTest.test(inputTitle));
  const autocompleteValue = result?.[0];
  return autocompleteValue !== undefined ? autocompleteValue : "on";
};

// (Array<Components>, String) => Component || undefined
export const findComponentByType = (components, type) => {
  // Searches through nested array of workflow components
  // Returns first component of specified type
  // If no match, returns undefined
  if (!Array.isArray(components) || typeof type !== "string") {
    console.warn(
      `Warning: invalid arguments for findComponentByType. Components must be an array [components is ${
        Array.isArray(components) ? "" : "not"
      } an array], type must be a string [type is a ${typeof type}].`
    );
    return undefined;
  }

  const foundComponent = components.find(component => component.type === type);
  if (foundComponent !== undefined) {
    return foundComponent;
  }

  const componentsWithChildren = components.filter(component =>
    Array.isArray(component.components)
  );

  for (const component of componentsWithChildren) {
    const innerResult = findComponentByType(component.components, type);
    if (innerResult) {
      return innerResult;
    }
  }

  return undefined;
};

const requiredLens = ramdaLensPath(["options", "required"]);

const selectAllItemDataLens = ramdaLensPath([
  "options",
  "select_all_item_data"
]);

const selectableLens = ramdaLensPath(["options", "selectable"]);

const multiSelectLens = ramdaLensPath(["options", "multiple_select"]);

const singleSelectLens = ramdaLensPath(["options", "single_select"]);

// (state: Object) => Boolean
export const selectableListViewPresent = state => {
  // Walks through workflow component nodes to get any PaymentItemsListView component
  // Gets first component or undefined
  // Checks to see if listview has the "selectable" or "multi_select" option set to true
  // Returns that value
  const workflowComponents =
    state?.workflow?.workflow?.nodes?.map(node => node?.data) ?? [];
  // any listview will never be on the first page of a workflow
  // has to come *after* the lookup happens
  // saves time by eliminating a workflow page to walk
  const paymentItemsListView = findComponentByType(
    workflowComponents.slice(1),
    "PaymentItemsListView"
  );
  return (
    ramdaView(selectableLens, paymentItemsListView) ||
    ramdaView(multiSelectLens, paymentItemsListView) ||
    ramdaView(singleSelectLens, paymentItemsListView)
  );
};

export const isPaymentItemRestricted = restrictedValues => paymentItem =>
  restrictedValues && !ramdaIsEmpty(restrictedValues)
    ? ramdaPipe(ramdaProp(restrictedValues.column_accessor), value =>
        ramdaAny(ramdaEquals(value), restrictedValues.column_restricted_value)
      )(paymentItem)
    : false;

export const PAYMENT_ITEMS_LOCATION = "paymentItems";
export const SELECTED_PAYMENT_ITEMS_LOCATION = "selectedPaymentItems";

/*
  Determines if there is a PaymentItemsListView in the workflow that is required 
  and allows all items to be selected.
*/
export const hasRequiredSelectAllListView = store => {
  const workflowComponents =
    store?.workflow?.nodes?.map(node => node?.data) ?? [];
  const paymentItemsListView = findComponentByType(
    workflowComponents.slice(1), // any listview will never be on the first page of a workflow
    "PaymentItemsListView"
  );
  return paymentItemsListView
    ? ramdaView(requiredLens, paymentItemsListView) &&
        ramdaView(selectAllItemDataLens, paymentItemsListView) &&
        (paymentItemsListView.options.bound_location?.includes(
          PAYMENT_ITEMS_LOCATION
        ) ||
          paymentItemsListView.options.bind?.location?.includes(
            PAYMENT_ITEMS_LOCATION
          ))
    : false;
};
