import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Cluster,
  ButtonWithAction,
  Text,
  Heading,
  Paragraph,
  GoToEmailIcon,
  withWindowSize,
  constants
} from "@thecb/components";

const VerifyAccount = ({
  email,
  resendVerification,
  headerColor = constants.colors.GRECIAN_GREY
}) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Box
      padding="0"
      background={constants.colors.WHITE}
      borderRadius="4px"
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
      0px 3px 8px 0px rgb(202, 206, 216);"
    >
      <Box background={headerColor} minWidth="100%" padding="0.5rem">
        <Cluster justify="center" align="center">
          <GoToEmailIcon />
        </Cluster>
      </Box>
      <Box padding={isMobile ? "1rem" : "1rem 1.5rem"}>
        <Heading
          color={constants.colors.CHARADE_GREY}
          variant="h5"
          weight="600"
          as="h1"
        >
          Check your email to complete registration
        </Heading>
        <Paragraph
          color={constants.colors.CHARADE_GREY}
          variant="p"
          margin="1rem 0"
        >
          Check your email
          <Text
            variant="p"
            color={constants.colors.CHARADE_GREY}
            extraStyles={`font-style: italic;`}
          >
            {` (${email}) `}
          </Text>
          and click the verification link to continue. Please allow up to 10
          minutes for the email to arrive in your inbox.
        </Paragraph>
        <Paragraph
          color={constants.colors.CHARADE_GREY}
          margin="1rem 0"
          variant="p"
        >
          Can&apos;t find the email?{" "}
          <ButtonWithAction
            text="Resend the link."
            variant="smallGhost"
            dataQa={"Resend the link"}
            action={() => resendVerification(email, "userInitiated")}
            textExtraStyles={isMobile && "font-size: 0.984rem;"}
          />
        </Paragraph>
      </Box>
    </Box>
  );
};

export default withWindowSize(VerifyAccount);
