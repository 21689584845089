import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import objectSupport from "dayjs/plugin/objectSupport";

// https://day.js.org/docs/en/plugin/localized-format
dayjs.extend(localizedFormat);

// https://day.js.org/docs/en/plugin/object-support
dayjs.extend(objectSupport);

/**
 *
 * @param dateString The date string to format
 * @param dateFormat The desired format (default of MM/D/YYYY)
 * @returns if dateString is a valid date, then dateString is returned formatted in dateFormat. Otherwise null is returned.
 */
export const formatDate = (dateString, dateFormat = "MM/DD/YYYY") => {
  if (!dateString) return;

  const dateTime = new Date(dateString);
  const date = dayjs(dateTime);
  if (date.isValid()) {
    return date.format(dateFormat);
  } else {
    console.warn(`${dateString} is not a valid date`);
    return null;
  }
};

/**
 * Formats a date object into a date string. Months are zero-indexed in the Day.js
 * library, and our system handles month data that is one-indexed. This utility
 * accounts for that difference.
 *
 * The default format is "l": English Locale: M/D/YYYY, Sample Output: 8/16/2018
 * See: https://day.js.org/docs/en/display/format#list-of-localized-formats
 * @param {{day: number, month: number, year: number}} dateObject - date construction values
 * @param {string} [format=l] - the date format (default: "l")
 * @returns {string} a date string
 */
export const formatDateObject = (dateObject = {}, dateFormat = "l") => {
  const { day, month, year } = dateObject;

  if (
    typeof day !== "number" ||
    typeof month !== "number" ||
    typeof year !== "number"
  ) {
    console.warn(`${JSON.stringify(dateObject)} is not a valid date object`);
    return null;
  }

  let adjustedDateObject = { ...dateObject };
  adjustedDateObject.month -= 1;

  return dayjs(adjustedDateObject).format(dateFormat);
};

export const getCardExpirationStatus = card => {
  const ACTIVE = "ACTIVE";
  const EXPIRING_SOON = "EXPIRING_SOON";
  const EXPIRED = "EXPIRED";

  const { expiryMonth, expiryYear } = card;
  const today = dayjs();
  const expiration = dayjs(`20${expiryYear}-${expiryMonth}`);
  const cardExpirationStatus = expiration.diff(today, "months");

  return cardExpirationStatus >= 1
    ? ACTIVE
    : cardExpirationStatus === 0
    ? EXPIRING_SOON
    : EXPIRED;
};
