import React from "react";
import Spinner from "../spinner";
import { Box, Cover, Center } from "../layouts";

const Loading = () => (
  <Box
    key="spinner-container"
    extraStyles={`position: fixed; top: 0; bottom: 0; left: 0; right: 0;`}
  >
    <Cover minHeight="100%" singleChild>
      <Center intrinsic>
        <Box>
          <Spinner size="100" />
        </Box>
      </Center>
    </Cover>
  </Box>
);

export default Loading;
