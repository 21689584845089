export const TRANSACTION_STATUS_SUCCESSFUL = "successful";
export const TRANSACTION_STATUS_VOIDED = "voided";
export const TRANSACTION_STATUS_REFUNDED = "refunded";
export const TRANSACTION_STATUS_ACH_RETURN = "ach_return";
export const TRANSACTION_STATUS_CHARGEBACK = "chargeback";
export const TRANSACTION_STATUS_CHARGEBACK_REVERSAL = "chargeback_reversal";

export const FETCHED_TRANSACTION_STATUSES = [
  TRANSACTION_STATUS_SUCCESSFUL,
  TRANSACTION_STATUS_VOIDED,
  TRANSACTION_STATUS_REFUNDED,
  TRANSACTION_STATUS_ACH_RETURN,
  TRANSACTION_STATUS_CHARGEBACK,
  TRANSACTION_STATUS_CHARGEBACK_REVERSAL
];

export const PAYMENT_METHOD_CREDIT_CARD = "CREDIT_CARD";
export const PAYMENT_METHOD_CHECK = "CHECK";

export const HEADING_ACCOUNT = "Account";
export const HEADING_DATE = "Date";
export const HEADING_PAYMENT_METHOD = "Payment Method";
export const HEADING_TRANSACTION_ID = "Transaction ID";
export const HEADING_AMOUNT = "Amount";
