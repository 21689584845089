import {
  createFormState,
  required,
  matchesField,
  onlyIntegers,
  hasLength,
  isRoutingNumber
} from "redux-freeform";

const formConfig = {
  name: {
    validators: [required()]
  },
  routingNumber: {
    validators: [required(), hasLength(9, 9), isRoutingNumber()],
    constraints: [onlyIntegers(), hasLength(0, 9)]
  },
  confirmRoutingNumber: {
    validators: [required(), matchesField("routingNumber")],
    constraints: [onlyIntegers(), hasLength(0, 9)]
  },
  accountNumber: {
    validators: [required(), hasLength(5, 17)],
    constraints: [onlyIntegers(), hasLength(0, 17)]
  },
  confirmAccountNumber: {
    validators: [required(), matchesField("accountNumber")],
    constraints: [onlyIntegers(), hasLength(0, 17)]
  },
  accountType: {
    defaultValue: "CHECKING",
    validators: [required()]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
