import React from "react";

const CheckboxCheckmarkIcon = ({
  width = "18",
  height = "18",
  color = "#FEFEFE",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7503 5.35354C13.555 5.15828 13.2385 5.15828 13.0432 5.35354L7.52373 10.873L5.52808 8.87735C5.33282 8.68209 5.01624 8.68209 4.82097 8.87735L4.35348 9.34484C4.15822 9.54011 4.15822 9.85669 4.35348 10.052L6.70268 12.4012L7.17018 12.8687C7.36544 13.0639 7.68203 13.0639 7.87729 12.8687L8.34478 12.4012L14.2178 6.52814C14.4131 6.33288 14.4131 6.0163 14.2178 5.82104L13.7503 5.35354Z"
      fill="#FEFEFE"
    />
    <mask
      id="mask0_3361_1486"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="5"
      width="11"
      height="9"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7503 5.35354C13.555 5.15828 13.2385 5.15828 13.0432 5.35354L7.52373 10.873L5.52808 8.87735C5.33282 8.68209 5.01624 8.68209 4.82097 8.87735L4.35348 9.34484C4.15822 9.54011 4.15822 9.85669 4.35348 10.052L6.70268 12.4012L7.17018 12.8687C7.36544 13.0639 7.68203 13.0639 7.87729 12.8687L8.34478 12.4012L14.2178 6.52814C14.4131 6.33288 14.4131 6.0163 14.2178 5.82104L13.7503 5.35354Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3361_1486)">
      <rect width={width} height={height} fill={color} />
    </g>
  </svg>
);

export default CheckboxCheckmarkIcon;
