import React from "react";
import { Box, Title, Stack } from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";

const LocationSection = ({ title, Icon, iconName, themeValues, children }) => {
  return (
    <Box extraStyles={`padding: 0 0 8px 0;`}>
      <Title
        as="h5"
        color={themeValues.darkText}
        extraStyles={`font-size: 18px; display: flex; gap: 8px; align-items: center;`}
        weight={FONT_WEIGHT_SEMIBOLD}
      >
        {Icon && <Icon size="24px" aria-label={iconName} />}
        {title}
      </Title>
      <Box extraStyles={`padding: 0 0 0 32px;`}>
        <Stack childGap="0.25rem">{children}</Stack>
      </Box>
    </Box>
  );
};

export default LocationSection;
