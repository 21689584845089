const fontFamily = {
  p: "Public Sans",
  pL: "Public Sans",
  pS: "Public Sans",
  pXS: "Public Sans",
  pXXS: "Public Sans",
  pXL: "Public Sans"
};

// Comments assume base font size of 16px

const fontSize = {
  p: "1rem", // 16px
  pL: "1.125rem", // 18px
  pS: "0.875rem", // 14px
  pXS: "0.75rem", // 12px
  pXXS: "0.65rem", // 10px
  pXL: "1.5rem" // 24px
};

export const fallbackValues = {
  fontFamily,
  fontSize
};
