import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { pipe } from "ramda";
import { ThemeContext } from "styled-components";
import {
  Box,
  ButtonWithAction,
  Cluster,
  Detail,
  Loading,
  Paragraph,
  SolidDivider,
  Stack,
  Text,
  Title,
  util
} from "@thecb/components";
import {
  ATHENS_GREY,
  CHARADE_GREY,
  GHOST_GREY,
  STORM_GREY,
  WHITE
} from "/constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "/constants/style_constants";
import {
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_CREDIT_CARD
} from "/constants/transaction_history_constants";
import {
  signedAmount,
  flattenObligations,
  obligationForTransaction,
  paymentLastFour,
  transactionStatusText
} from "/util/transactionHistory";
import { PaymentMethodIcon } from "../profile-transaction-history/PaymentMethodIcon";
import TransactionAutopayIcon from "./TransactionAutopayIcon";
import TransactionStatusIcon from "./TransactionStatusIcon";
import TransactionAutopayPopover from "../profile-transaction-history/TransactionAutopayPopover";
import { formatDate } from "/util/dateUtil";
import { convertMoneyDecimalToCents } from "/util/general";
import { lineItemAmount } from "../../../../../../util/transactionHistory";

const { displayCurrency } = util.general;

const PAYMENT_LAST_FOUR_TEXT = {
  [PAYMENT_METHOD_CHECK]: "Account Number",
  [PAYMENT_METHOD_CREDIT_CARD]: "Card Number"
};

const TransactionDetails = ({ resources, resourcesActions }) => {
  const { id } = useParams();

  const {
    obligations = {},
    transactions: { ids: { [id]: transaction } } = {
      ids: { [id]: {} }
    }
  } = resources;

  const transactionFetched = transaction?.isSuccess ?? false;

  const {
    fetchTransaction,
    navigateToDetailedObligation,
    setDetailedObligation
  } = resourcesActions;

  useEffect(() => {
    if (!transactionFetched) {
      fetchTransaction({
        paymentId: parseInt(id)
      });
    }
  }, [transactionFetched, id]);

  const status = transaction?.isLoading
    ? "loading"
    : transaction?.isSuccess
    ? "success"
    : transaction?.isFailure
    ? "error"
    : "not found";

  const obligation = obligationForTransaction({
    transaction,
    obligations: flattenObligations(obligations)
  });

  const { isMobile, subClientMetadata } = useContext(ThemeContext);
  const padding = isMobile ? "16px" : "24px";

  const statusText = transactionStatusText(transaction?.status);
  const isAutopayEnabled =
    transaction?.paymentInitiatorDetails?.isAutoInitiated;
  const isSuccessfulAutopayment =
    isAutopayEnabled && statusText == "Payment Successful";
  const popoverID = `transaction-details-autopay-${id}`;

  const feeTypeLabel = subClientMetadata?.data?.feeTypeLabel ?? "Service Fee";

  const paymentLastFourForTransaction = paymentLastFour(transaction ?? {});

  const paymentMethodTextForTransaction = paymentLastFourForTransaction
    ? [
        PAYMENT_LAST_FOUR_TEXT[transaction?.paymentType],
        paymentLastFourForTransaction
      ].join(" ")
    : "";

  const transactionDate = formatDate(transaction?.createdAt);

  const onObligationClick = obligation => {
    setDetailedObligation(
      obligation.obligations,
      obligation.config,
      obligation.id
    );
    navigateToDetailedObligation(
      `/profile/accounts/details/${obligation.config.obligationSlug}`
    );
  };
  const getAmount = pipe(
    signedAmount,
    convertMoneyDecimalToCents,
    displayCurrency
  );

  const renderLineItem = (
    { id, customAttributes, amountInCents, amountRefundedInCents },
    index
  ) => {
    const description = customAttributes.find(
      attribute => attribute.key == "line_item_description"
    )?.value;
    const subDescription = customAttributes.find(
      attribute => attribute.key == "line_item_sub_description"
    )?.value;

    const getAmount = pipe(
      lineItemAmount,
      amount => signedAmount(transaction.status, amount),
      displayCurrency
    );

    const lineItemNumber = index + 1;

    return (
      <Cluster
        key={id}
        justify="space-between"
        align="center"
        as="section"
        aria-label={`Line Item ${lineItemNumber}`}
      >
        <Stack childGap={0}>
          <Text weight={FONT_WEIGHT_SEMIBOLD} color="inherit">
            {description}
          </Text>
          {subDescription && <Text color="inherit">{subDescription}</Text>}
          {obligation && (
            <Cluster justify="space-between">
              <ButtonWithAction
                variant="ghost"
                onClick={() => onObligationClick(obligation)}
                extraStyles="text-align: left; padding: 0; margin: 0; min-width: 0; min-height: 0;"
                text={
                  <Text fontSize={"12px"} color="inherit">
                    View Account Details
                  </Text>
                }
              ></ButtonWithAction>
            </Cluster>
          )}
        </Stack>
        <Box srOnly padding={0}>
          <Detail>Amount</Detail>
        </Box>
        <Text weight={FONT_WEIGHT_SEMIBOLD}>
          {getAmount(transaction.status, {
            amountInCents,
            amountRefundedInCents
          })}
        </Text>
      </Cluster>
    );
  };

  const renderTransactionDetails = () => (
    <Stack childGap={padding}>
      <Box padding={0}>
        <Stack fullHeight childGap={padding}>
          <Box
            background={WHITE}
            borderRadius="4px"
            boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
            padding="0"
          >
            <Box
              borderRadius="4px 4px 0 0"
              padding={padding}
              background={ATHENS_GREY}
              boxShadow={`0px 1px 0px 0px ${GHOST_GREY}`}
            >
              <Title variant="small" as="h1">
                Transaction ID:
                <Text
                  extraStyles="margin-left: 8px"
                  fontSize="inherit"
                  weight={FONT_WEIGHT_SEMIBOLD}
                >
                  {transaction?.id}
                </Text>
              </Title>
            </Box>
            <Box borderRadius="0 0 4px 4px" padding={"24px"}>
              {isSuccessfulAutopayment ? (
                <TransactionAutopayPopover
                  id={popoverID}
                  childGap={"8px"}
                  displayText={"Autopay Successful"}
                  fontSize={"16px"}
                  Icon={TransactionAutopayIcon}
                  position={{
                    top: "-43px",
                    left: "-55px"
                  }}
                  textColor={CHARADE_GREY}
                />
              ) : (
                statusText && (
                  <Cluster childGap="8px" align="center">
                    <Box aria-hidden="true" padding={0}>
                      <TransactionStatusIcon
                        status={transaction?.status}
                        isMobile={isMobile}
                      />
                    </Box>

                    <Text>{statusText}</Text>
                  </Cluster>
                )
              )}
            </Box>
          </Box>
        </Stack>
      </Box>

      <Box padding={0} as="section" aria-labelledby="payment-details-heading">
        <Stack>
          <Box padding="0">
            <Paragraph
              variant="pL"
              weight={FONT_WEIGHT_SEMIBOLD}
              as="h2"
              id="payment-details-heading"
            >
              Payment Details
            </Paragraph>
          </Box>
          <Box
            padding={padding}
            background={WHITE}
            borderRadius={"4px"}
            boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
          >
            <Stack childGap={padding}>
              {transaction.lineItems?.length > 0 && (
                <>
                  {transaction.lineItems.map(renderLineItem)}
                  <SolidDivider />
                </>
              )}

              <Cluster justify="space-between" align="center">
                <Text color={STORM_GREY} as="h3" extraStyles="margin: 0;">
                  Subtotal
                </Text>
                <Text color={STORM_GREY}>
                  {getAmount(transaction.status, transaction.amount)}
                </Text>
              </Cluster>
              {transaction.serviceFee > 0 && (
                <Cluster justify="space-between" align="center">
                  <Text color={STORM_GREY} as="h3" extraStyles="margin: 0;">
                    {feeTypeLabel}
                  </Text>
                  <Text color={STORM_GREY}>
                    {getAmount(transaction?.status, transaction?.serviceFee)}
                  </Text>
                </Cluster>
              )}
              <SolidDivider />
              <Cluster justify="space-between" align="center">
                <Text
                  weight={FONT_WEIGHT_SEMIBOLD}
                  fontSize={isMobile ? "initial" : "18px"}
                  as="h3"
                  extraStyles="margin: 0;"
                >
                  Total Amount
                </Text>
                <Text weight={FONT_WEIGHT_SEMIBOLD}>
                  {getAmount(transaction?.status, transaction?.totalAmount)}
                </Text>
              </Cluster>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {paymentMethodTextForTransaction && (
        <Box padding={0} as="section" aria-labelledby="payment-method-heading">
          <Stack>
            <Box padding="0">
              <Paragraph
                variant="pL"
                weight={FONT_WEIGHT_SEMIBOLD}
                as="h2"
                id="payment-method-heading"
              >
                Payment Method
              </Paragraph>
            </Box>
            <Box
              padding={padding}
              background={WHITE}
              borderRadius={"4px"}
              boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
            >
              <Cluster childGap="8px" align="center">
                <PaymentMethodIcon
                  paymentType={transaction?.paymentType}
                  large={!isMobile}
                />
                <Text>{paymentMethodTextForTransaction}</Text>
              </Cluster>
            </Box>
          </Stack>
        </Box>
      )}

      {transactionDate && (
        <Box padding={0} as="section" aria-labelledby="payment-date-heading">
          <Stack>
            <Box padding="0">
              <Paragraph
                variant="pL"
                weight={FONT_WEIGHT_SEMIBOLD}
                as="h2"
                id="payment-date-heading"
              >
                Payment Date
              </Paragraph>
            </Box>
            <Box
              padding={padding}
              background={WHITE}
              borderRadius={"4px"}
              boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
            >
              <Text>{transactionDate}</Text>
            </Box>
          </Stack>
        </Box>
      )}
    </Stack>
  );

  return (
    <Box padding={"0 4px 16px"}>
      {status === "loading" ? (
        <Loading />
      ) : transaction?.id ? (
        renderTransactionDetails()
      ) : (
        <Text>Transaction Not Found</Text>
      )}
    </Box>
  );
};

export default TransactionDetails;
