import React from "react";

const PropertyLandIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill="#3B5BDB"
    />
    <path
      d="M25.8629 35.15C26.1342 35.15 26.3376 35.037 26.4733 34.8109C26.6089 34.5848 26.6164 34.3512 26.4959 34.1101L25.1394 31.8945V30.8094H31.3339C31.5449 30.8094 31.7333 30.7566 31.899 30.6511C32.0648 30.5456 32.1929 30.4024 32.2834 30.2216C32.3738 30.0407 32.4039 29.8523 32.3738 29.6564C32.3437 29.4605 32.2532 29.2721 32.1025 29.0912L28.4853 25.0219H29.887C30.3391 25.0219 30.6556 24.8259 30.8365 24.4341C31.0174 24.0422 30.9721 23.6805 30.7009 23.3489L27.1289 19.2344H28.4401C28.6511 19.2344 28.847 19.1816 29.0279 19.0761C29.2088 18.9706 29.3369 18.8199 29.4122 18.624C29.4876 18.428 29.5102 18.2321 29.4801 18.0362C29.4499 17.8403 29.3595 17.6669 29.2088 17.5162L24.2351 12.2261C24.0844 12.0754 23.9036 12 23.6926 12C23.4816 12 23.3007 12.0754 23.15 12.2261L18.1763 17.5162C18.0256 17.6669 17.9352 17.8403 17.9051 18.0362C17.8749 18.2321 17.8975 18.428 17.9729 18.624C18.0482 18.8199 18.1763 18.9706 18.3572 19.0761C18.5381 19.1816 18.734 19.2344 18.945 19.2344H20.2562L16.6843 23.3489C16.413 23.6805 16.3677 24.0422 16.5486 24.4341C16.7295 24.8259 17.046 25.0219 17.4981 25.0219H18.8998L15.2826 29.0912C15.1319 29.2721 15.0414 29.4605 15.0113 29.6564C14.9812 29.8523 15.0113 30.0407 15.1017 30.2216C15.1922 30.4024 15.3203 30.5456 15.4861 30.6511C15.6518 30.7566 15.8402 30.8094 16.0512 30.8094H22.2457V31.8945L20.8892 34.1101C20.7687 34.3512 20.7762 34.5848 20.9118 34.8109C21.0475 35.037 21.251 35.15 21.5222 35.15H25.8629Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default PropertyLandIcon;
