import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import { ForgotPasswordForm } from "@thecb/components";
import * as Nested from "../../../../util/Nested";

export const SUBMIT_FORGOT_PASSWORD_FORM =
  "forgotPassword/SUBMIT_FORGOT_PASSWORD_FORM";
export const submitForgotPasswordForm = (options = {}) => {
  const { inWallet = false } = options;
  return {
    type: SUBMIT_FORGOT_PASSWORD_FORM,
    payload: {
      inWallet
    }
  };
};

export const FORGOT_PASSWORD_SUCESS = "forgotPassword/FORGOT_PASSWORD_SUCESS";
export const forgotPasswordSuccess = () => ({ type: FORGOT_PASSWORD_SUCESS });

const FORGOT_PASSWORD_ERROR = "forgotPassword/FORGOT_PASSWORD_ERROR";
export const forgotPasswordError = error => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { error }
});

const initialState = {
  submitting: false,
  error: false
};

export const _reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBMIT_FORGOT_PASSWORD_FORM:
      return { ...state, submitting: true };
    case FORGOT_PASSWORD_SUCESS:
      return {
        ...state,
        submitting: false,
        error: false
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        submitting: false,
        error: payload
      };
    default:
      return state;
  }
};

export const _mapStateToProps = state => state.forgotPassword;
export const _mapDispatchToProps = dispatch => ({
  submitForgotPasswordForm: () => dispatch(submitForgotPasswordForm())
});

export const ALERT_BAR_ACTION = "forgotPassword/ALERT_BAR_ACTION";
export const forgotPasswordAlertBarAction = Nested.nestedAction(
  ALERT_BAR_ACTION
);

export const FORGOT_PASSWORD_FORM_ACTION =
  "forgotPassword/FORGOT_PASSWORD_FORM_ACTION";
export const forgotPasswordFormAction = Nested.nestedAction(
  FORGOT_PASSWORD_FORM_ACTION
);

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    forms: {
      forgotPasswordForm: {
        ...ForgotPasswordForm,
        actionType: FORGOT_PASSWORD_FORM_ACTION
      }
    },
    alertBars: {
      forgotPasswordAlertBar: {
        ...AlertBarState,
        actionType: ALERT_BAR_ACTION
      }
    }
  },
  "forgotPassword"
);

const reducer = (state, action) => nestedReducer(state, action);

export { reducer, mapStateToProps, mapDispatchToProps, actions };
