//

export function removeComponent(id, screen, workflow) {
  // keep a collection of components to remove
  var targets = [id];
  function recurse(item) {
    if (item.components) {
      var list = item.components;
      for (let i = 0; i < list.length; i++) {
        const current = list[i];
        if (
          typeof current.child_of === "string" &&
          ~targets.indexOf(current.child_of)
        ) {
          targets.push(current.id);
          list.splice(i, 1);
          i--;
        } else if ("components" in current) {
          recurse(current);
        }
      }
    }
  }
  recurse(workflow.graph_document.nodes[screen].data);
  return workflow;
}

export function insertComponent(component, screen, after, workflow) {
  function recurse(item) {
    if (item.components) {
      var list = item.components;
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === after) {
          if (list.length <= i + 1) {
            // no more in the list, so just insert
            list.push(component);
            break;
          } else {
            // check to see if it's next
            if (list[i + 1].id === component.id) {
              // if yes, replace
              list[i + 1] = component;
              i++;
              break;
            } else {
              // if no, insert
              list.splice(i + 1, 0, component);
              break;
            }
          }
        } else if ("components" in list[i]) {
          recurse(list[i]);
        }
      }
    }
  }
  recurse(workflow.graph_document.nodes[screen].data);
  return workflow;
}
