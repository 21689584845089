import { BRIGHT_GREY } from "../../../constants/colors";

import { FONT_WEIGHT_BOLD } from "../../../constants/style_constants";
const titleColor = BRIGHT_GREY;
const titleWeight = FONT_WEIGHT_BOLD;
const textColor = BRIGHT_GREY;

export const fallbackValues = {
  titleColor,
  titleWeight,
  textColor
};
