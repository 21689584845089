import React, { Fragment } from "react";
import { SidebarWrapper, SidebarInnerWrapper } from "./Sidebar.styled";
import { safeChildren } from "../../../util/general";

/*
  Component to assist in creating layouts with some element and a sidebar next to it
  Sidebar takes a width value, this is the ideal target with, and what the sidebar
  will be sized to on larger/medium screens

  The component also takes a content minimum width, this should be a percentage
  it represents the narrowest the content piece can get before the sidebar wraps to
  the top of the layout. If not supplied, the sidebar will size to the width of its content.

  This can be used for many use cases beyond the typical content/sidebar layout
  Such as for pinning a button/icon next to a form input
*/

const Sidebar = ({
  childGap = "1rem",
  width,
  sidebarOnRight = false,
  contentMinWidth = "50%",
  fullHeight = false,
  minHeight,
  children,
  ...rest
}) => (
  <SidebarWrapper fullHeight={fullHeight} minHeight={minHeight} {...rest}>
    <SidebarInnerWrapper
      childGap={childGap}
      width={width}
      $sidebarOnRight={sidebarOnRight}
      contentMinWidth={contentMinWidth}
      fullHeight={fullHeight}
      minHeight={minHeight}
    >
      {safeChildren(children, <Fragment />)}
    </SidebarInnerWrapper>
  </SidebarWrapper>
);

export default Sidebar;
