import React from "react";

import VerifyAccount from "../../../../../profile/pages/account-verification/sub-pages/verify-account";
import {
  Box,
  Stack,
  Heading,
  Paragraph,
  ButtonWithAction,
  constants,
  Cover
} from "@thecb/components";
import AlertBar from "../../../../../../components/alert-bar";
import FocusTrap from "react-focus-trap";

const VerifyAccountPanel = ({
  closeSlider,
  panelIsOpen,
  changePanel,
  email,
  resendVerification,
  walletVerifyAlertBar,
  walletVerifyAlertBarActions,
  isMobile
}) => (
  <Box
    padding="0 2rem"
    minHeight="100%"
    width="100%"
    background={constants.colors.ATHENS_GREY}
    extraStyles={`.focus-trap-wrapper:focus, .focus-trap:focus { outline: none; }`}
  >
    <FocusTrap active={panelIsOpen} onExit={closeSlider}>
      <Cover singleChild minHeight="100vh">
        <Stack childGap="1.5rem">
          {walletVerifyAlertBar.alerts.length > 0 && (
            <Box padding="0 0 1.5rem">
              <AlertBar
                {...walletVerifyAlertBar}
                {...walletVerifyAlertBarActions}
              />
            </Box>
          )}
          <VerifyAccount
            email={email}
            resendVerification={resendVerification}
            headerColor={constants.colors.INFO_BLUE}
          />
          <Box
            padding="0"
            background={constants.colors.WHITE}
            borderRadius="4px"
            boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
      0px 3px 8px 0px rgb(202, 206, 216);"
          >
            <Box padding={isMobile ? "1rem" : "1rem 1.5rem"}>
              <Heading
                color={constants.colors.CHARADE_GREY}
                variant="h5"
                weight="600"
                as="h1"
              >
                Already verified?
              </Heading>
              <Paragraph
                color={constants.colors.CHARADE_GREY}
                variant="pL"
                margin="1rem 0"
              >
                After verifying your account, come back to this tab and log in.
              </Paragraph>
              <ButtonWithAction
                text="Login"
                variant="secondary"
                action={() => {
                  changePanel("verifyAccount", "next");
                  changePanel("login", "onScreen");
                }}
                extraStyles={`width: 100%;`}
                dataQa="Return to login"
              />
            </Box>
          </Box>
        </Stack>
      </Cover>
    </FocusTrap>
  </Box>
);

export default VerifyAccountPanel;
