import React from "react";
import { Paragraph } from "@thecb/components";
import { IoMdTime } from "@react-icons/all-files/io/IoMdTime";
import LocationSection from "./LocationSection";
import { v4 as uuidv4 } from "uuid";

const getDay = dayName => {
  const days = {
    Monday: { dow: 0, shortName: "Mon" },
    Tuesday: { dow: 1, shortName: "Tue" },
    Wednesday: { dow: 2, shortName: "Wed" },
    Thursday: { dow: 3, shortName: "Thu" },
    Friday: { dow: 4, shortName: "Fri" },
    Saturday: { dow: 5, shortName: "Sat" },
    Sunday: { dow: 6, shortName: "Sun" }
  };
  return days[dayName];
};

const HoursOfOperation = ({ hours, themeValues }) => {
  const { title, schedules } = hours;

  const getScheduleLine = s => {
    // build array of days consolidating any continuous series of days
    const scheduleDays = [];
    let pair = {
      start: getDay(s.daysOfWeek[0]),
      end: getDay(s.daysOfWeek[0])
    };
    let lastPair = null;
    for (let i = 1; i < s.daysOfWeek.length; ++i) {
      let day = getDay(s.daysOfWeek[i]);
      if (day && day.dow === pair.end.dow + 1) {
        pair.end = day;
      } else {
        lastPair = { ...pair };
        scheduleDays.push(lastPair);
        pair.start = day;
        pair.end = day;
      }
    }
    if (pair != lastPair) scheduleDays.push(pair);
    const formattedDays = scheduleDays.map(pair =>
      pair.start.dow == pair.end.dow
        ? pair.start.shortName
        : `${pair.start.shortName} - ${pair.end.shortName}`
    );
    const scheduleHours = [];
    s.times.forEach(t => {
      scheduleHours.push(`${t.openTime} - ${t.closeTime}`);
    });
    return `${formattedDays.join(", ")}: ${scheduleHours.join(", ")}`;
  };

  return (
    <LocationSection
      key="hours"
      Icon={IoMdTime}
      iconName="hours icon"
      title={title}
      themeValues={themeValues}
    >
      {schedules.map(s => {
        return (
          <Paragraph key={uuidv4()} variant="pL">
            {getScheduleLine(s)}
          </Paragraph>
        );
      })}
    </LocationSection>
  );
};

export default HoursOfOperation;
