import React, { useContext, useState } from "react";
import {
  Box,
  CollapsibleSection,
  Detail,
  Motion,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import Filter from "../filter";

const CollapsibleFilterSection = ({
  title,
  name,
  choices,
  selected,
  onSelected,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(!isMobile);
  return (
    <CollapsibleSection
      title={
        <Detail as="h3" variant="large" weight={FONT_WEIGHT_SEMIBOLD}>
          {title}
        </Detail>
      }
      customTitle
      toggleSection={() => setIsOpen(!isOpen)}
      isOpen={isOpen}
      name={name}
    >
      <Motion
        padding="0"
        transition={{ duration: 0.3 }}
        positionTransition
        extraStyles={`transform-origin: 100% 0;`}
      >
        <Box padding="0">
          <Filter
            choices={choices}
            selected={selected}
            themeValues={themeValues}
            onSelected={onSelected}
          />
        </Box>
      </Motion>
    </CollapsibleSection>
  );
};

export default withWindowSize(CollapsibleFilterSection);
