import React from "react";

const ChargebackIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ChargebackIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="5"
        width="15"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.6667 6.83325C6.6667 6.28097 7.11441 5.83325 7.6667 5.83325H15.6667C16.219 5.83325 16.6667 6.28097 16.6667 6.83325V8.33325V9.99992V13.1666C16.6667 13.7189 16.219 14.1666 15.6667 14.1666H7.6667C7.11441 14.1666 6.6667 13.7189 6.6667 13.1666V9.99992V8.33325V6.83325ZM16.0417 6.5277V8.33325H7.2917V8.26381V6.5277H16.0417ZM7.2917 13.4721V9.99992H16.0417V13.4721H7.2917ZM4.8275 8.70703C4.8275 8.26157 4.28893 8.03849 3.97395 8.35347L2.68105 9.64636C2.48579 9.84163 2.48579 10.1582 2.68105 10.3535L3.97395 11.6464C4.28893 11.9613 4.8275 11.7383 4.8275 11.2928V8.70703ZM13.4896 11.6666C13.2595 11.6666 13.0729 11.8531 13.0729 12.0833C13.0729 12.3134 13.2595 12.4999 13.4896 12.4999H14.5313C14.7614 12.4999 14.9479 12.3134 14.9479 12.0833C14.9479 11.8531 14.7614 11.6666 14.5313 11.6666H13.4896Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="2.5" y="2.5" width="15" height="15" fill="white" />
      </g>
    </svg>
  );
};

export default ChargebackIconSmall;
