import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { themeComponent } from "/util/themeUtils";
import {
  fallbackValues,
  SPACING,
  FONT_SIZE,
  CART_LAYOUT_PADDING,
  CART_MAX_WIDTH
} from "./MultiCart.theme";
import {
  Alert,
  Box,
  ButtonWithAction,
  Center,
  Cluster,
  Cover,
  DefaultPageTemplate,
  IconQuitLarge,
  Text,
  Title,
  constants,
  util,
  withWindowSize,
  PlusCircleIcon
} from "@thecb/components";
import { MAX_CONTENT_WIDTH } from "../../../../constants/style_constants";
import { StyledCartPageContainer } from "./MultiCart.styled";
import CartsList from "./components/CartsList";
import CartEmpty from "./components/CartEmpty";
import { pluralize } from "../../../../util/general";
import CartScreenReaderMessages from "./components/CartScreenReaderMessages";

export const TOAST_AUTO_REMOVE_DELAY = 5000;

const MultiCart = ({
  carts,
  checkoutCartId,
  checkoutFromMultiCart,
  clearMultiCart,
  closeCartSlider,
  config,
  inSlider = false,
  checkoutInProgress,
  isLoggedIn,
  multiCartItemsCount,
  multiCartTotal,
  openMultiCartModal,
  removeFromMultiCart,
  removeToastFromMultiCart,
  displayMultiCartInstructionalAlert,
  hideMultiCartInstructionalAlert,
  screenReaderMessages,
  setMultiCartModalContent,
  focusArea,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const { displayCurrency } = util.general;
  const navigate = useNavigate();
  const hasMultipleCarts = multiCartItemsCount > 1 && carts?.length > 1;

  const {
    colors: { WHITE, ATHENS_GREY },
    fontWeights: { FONT_WEIGHT_REGULAR, FONT_WEIGHT_SEMIBOLD }
  } = constants;

  const CartTitle = () => {
    const multiCartDescription = `(${carts?.length} ${pluralize(
      "Cart",
      carts?.length
    )}, ${multiCartItemsCount} ${pluralize("Item", multiCartItemsCount)})`;
    const titleAriaLabel = hasMultipleCarts
      ? `Carts, total of ${carts?.length} ${pluralize(
          "Cart",
          carts?.length
        )} containing ${multiCartItemsCount} ${pluralize(
          "Item",
          multiCartItemsCount
        )}`
      : `Cart with ${multiCartItemsCount} ${pluralize(
          "item",
          multiCartItemsCount
        )}`;
    return (
      <Title
        as={inSlider ? "h2" : "h1"}
        variant="small"
        color={themeValues.headerTextColor}
        weight={FONT_WEIGHT_SEMIBOLD}
        extraStyles={`font-size: ${isMobile ? FONT_SIZE.LG : FONT_SIZE.XL}`}
        aria-label={titleAriaLabel}
      >
        {hasMultipleCarts ? `${pluralize("Cart", carts?.length)}` : "Cart"}
        {hasMultipleCarts && (
          <Text
            color={themeValues.headerTextColor}
            weight={FONT_WEIGHT_REGULAR}
            extraStyles={`font-size: ${isMobile ? FONT_SIZE.LG : FONT_SIZE.XL}`}
          >
            {` ${multiCartDescription}`}
          </Text>
        )}
      </Title>
    );
  };

  const CartPageWrapper = () => {
    const content = (
      <>
        {multiCartItemsCount > 0 ? (
          <CartsList
            carts={carts}
            checkoutCartId={checkoutCartId}
            checkoutFromMultiCart={checkoutFromMultiCart}
            clearMultiCart={clearMultiCart}
            closeCartSlider={closeCartSlider}
            hasMultipleCarts={hasMultipleCarts}
            inSlider={inSlider}
            checkoutInProgress={checkoutInProgress}
            isMobile={isMobile}
            removeFromMultiCart={removeFromMultiCart}
            removeToastFromMultiCart={removeToastFromMultiCart}
            screenReaderMessages={screenReaderMessages}
            multiCartItemsCount={multiCartItemsCount}
            focusArea={focusArea}
            linkColor={themeValues.linkColor}
          />
        ) : (
          <CartEmpty
            closeCartSlider={closeCartSlider}
            config={config}
            isLoggedIn={isLoggedIn}
            inSlider={inSlider}
            isMobile={isMobile}
            navigate={navigate}
          />
        )}
      </>
    );
    return (
      <DefaultPageTemplate
        background={isMobile ? WHITE : ATHENS_GREY}
        header={
          <Box padding="16px" background={themeValues.headerBackgroundColor}>
            <Center maxWidth={MAX_CONTENT_WIDTH}>
              <Cluster justify="space-between" align="center">
                <CartTitle />
              </Cluster>
            </Center>
          </Box>
        }
        gutters={isMobile && SPACING.XL}
        maxWidth={MAX_CONTENT_WIDTH}
        content={
          <Box
            padding={isMobile ? `${SPACING.XL} 0` : `2.5rem 0 2.5rem`}
            minHeight="100%"
          >
            {isMobile ? (
              <>{content}</>
            ) : (
              <StyledCartPageContainer
                childGap="40px"
                breakpoint="1140px"
                constrainMobile={isMobile}
                isMobile={isMobile}
                padding={CART_LAYOUT_PADDING}
                style={{ paddingTop: SPACING.XXL }}
                isCartEmpty={multiCartItemsCount === 0}
              >
                {content}
              </StyledCartPageContainer>
            )}
            {!hasMultipleCarts && carts[0]?.config?.addMoreItemsURL && (
              <Center maxWidth={CART_MAX_WIDTH}>
                <ButtonWithAction
                  variant="secondary"
                  contentOverride={true}
                  action={() => {
                    navigate(carts[0]?.config?.addMoreItemsURL);
                  }}
                  borderRadius="8px"
                  extraStyles={`
                    margin: ${
                      isMobile ? `${SPACING.SM} 0 0` : `${SPACING.XXL} 0 0`
                    };
                    min-width: auto;
                    width: 100%;
                    border-style: dotted;
                  `}
                >
                  <Box
                    as="span"
                    padding="0"
                    extraStyles={`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      gap: 0.5rem;
                    `}
                  >
                    <PlusCircleIcon linkColor={themeValues.linkColor} />
                    <Text
                      variant="pS"
                      color={themeValues.linkColor}
                      weight={FONT_WEIGHT_SEMIBOLD}
                    >
                      {isMobile ? "Search for items" : "Search for more items"}
                    </Text>
                  </Box>
                </ButtonWithAction>
              </Center>
            )}
          </Box>
        }
      />
    );
  };

  const CartSliderWrapper = () => {
    const isEmptyCart = multiCartItemsCount === 0;
    return (
      <Box padding="0" background={WHITE} minWidth="100%" minHeight="100%">
        <Cover
          minHeight={isMobile ? "calc(100vh - 197px)" : "calc(100vh - 97px)"}
          fillCenter
        >
          <Box
            padding={
              isMobile
                ? `${SPACING.SM} ${SPACING.XL}`
                : `${SPACING.XL} ${SPACING.XXL}`
            }
            background={themeValues.headerBackgroundColor}
            extraStyles={`
              margin-bottom: ${isMobile ? SPACING.SM : SPACING.XS};
              position: sticky;
              top: 0;
              z-index: 10;
            `}
          >
            <Cluster
              justify="space-between"
              align="center"
              extraStyles="overflow: visible;"
            >
              <CartTitle />
              <Cluster
                justify="space-between"
                align="center"
                childGap={SPACING.XS}
                extraStyles="overflow: visible;"
              >
                {hasMultipleCarts && (
                  <Text
                    variant="p"
                    color={themeValues.headerTextColor}
                    weight={FONT_WEIGHT_SEMIBOLD}
                    extraStyles={`font-size: ${
                      isMobile ? FONT_SIZE.LG : FONT_SIZE.XL
                    }`}
                    aria-label={`Total is ${displayCurrency(
                      multiCartTotal
                    )} for ${carts.length} ${pluralize("cart", carts.length)}.`}
                    data-qa="multicart-total"
                  >
                    {displayCurrency(multiCartTotal)}
                  </Text>
                )}
                {isMobile && (
                  <ButtonWithAction
                    variant="smallGhost"
                    action={closeCartSlider}
                    contentOverride
                    extraStyles={`
                      padding: 0.15rem;
                      min-width: 1.5rem;
                      min-height: auto;
                      margin: 0;
                      svg { height: 12px; width: 12px; }
                    `}
                    aria-label="Close Cart Modal"
                  >
                    <IconQuitLarge fill={WHITE} />
                  </ButtonWithAction>
                )}
              </Cluster>
            </Cluster>
          </Box>
          {isEmptyCart ? (
            <CartEmpty
              closeCartSlider={closeCartSlider}
              config={config}
              isLoggedIn={isLoggedIn}
              inSlider={inSlider}
              isMobile={isMobile}
              navigate={navigate}
            />
          ) : (
            <Box
              padding={
                isMobile
                  ? CART_LAYOUT_PADDING.mobile.cartsContainer
                  : CART_LAYOUT_PADDING.desktop.cartsContainer
              }
            >
              {hasMultipleCarts && displayMultiCartInstructionalAlert && (
                <Alert
                  heading="Why Multiple Shopping Carts?"
                  text="Each agency has distinct rules about payment methods, service fees, and more. This means they must be paid in separate transactions. "
                  onLinkClick={hideMultiCartInstructionalAlert}
                  noBorder
                  extraStyles={`
                    margin-bottom: ${SPACING.XXL};
                    box-shadow: 0px 1px 2px 0px rgba(41, 42, 51, 0.1), 0px 2px 4px 0px rgba(41, 42, 51, 0.2), 0px 1px 0px 0px rgba(41, 42, 51, 0.1) inset;
                  `}
                />
              )}
              <CartsList
                carts={carts}
                checkoutCartId={checkoutCartId}
                checkoutFromMultiCart={checkoutFromMultiCart}
                clearMultiCart={clearMultiCart}
                closeCartSlider={closeCartSlider}
                hasMultipleCarts={hasMultipleCarts}
                inSlider={inSlider}
                checkoutInProgress={checkoutInProgress}
                isMobile={isMobile}
                removeFromMultiCart={removeFromMultiCart}
                removeToastFromMultiCart={removeToastFromMultiCart}
                screenReaderMessages={screenReaderMessages}
                openMultiCartModal={openMultiCartModal}
                setMultiCartModalContent={setMultiCartModalContent}
                focusArea={focusArea}
                linkColor={themeValues.linkColor}
              />
            </Box>
          )}
        </Cover>
      </Box>
    );
  };

  return (
    <>
      <CartScreenReaderMessages
        screenReaderMessages={screenReaderMessages}
        instructionalMessage={
          hasMultipleCarts && displayMultiCartInstructionalAlert
            ? `You have ${carts.length} shopping carts. Why multiple shopping carts? Each agency has distinct rules about payment methods, service fees, and more. This means they must be paid in separate transactions.`
            : ""
        }
      />
      {inSlider ? <CartSliderWrapper /> : <CartPageWrapper />}
    </>
  );
};

export default withWindowSize(
  React.memo(themeComponent(MultiCart, "MultiCart", fallbackValues))
);
