import {
  createFormState,
  required,
  matchesField,
  hasLength,
  hasNumber,
  hasLowercaseLetter,
  hasUppercaseLetter,
  hasSpecialCharacter
} from "redux-freeform";

const formConfig = {
  currentPassword: {
    validators: [required()]
  },
  newPassword: {
    validators: [
      required(),
      hasLength(8, 100),
      hasNumber(),
      hasLowercaseLetter(),
      hasUppercaseLetter(),
      hasSpecialCharacter()
    ]
  },
  confirmNewPassword: {
    validators: [required(), matchesField("newPassword")]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
