import styled from "styled-components";

export const OutdatedBrowserContainer = styled.div`
  font-family: arial, sans-serif;
  padding: 5% 20%;
`;

export const BrowserLinkGroup = styled.div`
  text-align: center;
  width: 100%;
`;

export const BrowserLink = styled.a`
  display: inline-block;
  margin: 2em;
`;

export const BrowserLogo = styled.img`
  display: block;
  height: 50px;
  margin: auto;
  padding-bottom: 10px;
  width: 50px;
`;

export const BrowserLinkText = styled.div``;

export const OutdatedBrowserText = styled.p`
  width: 100%;
`;
