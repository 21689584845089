import PaymentFormACH from "./PaymentFormACH";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./PaymentFormACH.state";

PaymentFormACH.reducer = reducer;
PaymentFormACH.mapStateToProps = mapStateToProps;
PaymentFormACH.mapDispatchToProps = mapDispatchToProps;
export default PaymentFormACH;
