import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import * as Nested from "../../../../util/Nested";

export const RETRIEVE_CMS_CONTENT = "cms/RETRIEVE_CMS_CONTENT";
export const retrieveCMSContent = (pageSlug, meta, id, preview = false) => ({
  type: RETRIEVE_CMS_CONTENT,
  payload: { pageSlug, meta, id, preview }
});

export const RETRIEVE_CMS_CONTENT_SUCCESS = "cms/RETRIEVE_CMS_CONTENT_SUCCESS";
export const retrieveCMSContentSuccess = payload => ({
  type: RETRIEVE_CMS_CONTENT_SUCCESS,
  payload
});

export const RETRIEVE_CMS_CONTENT_ERROR = "cms/RETRIEVE_CMS_CONTENT_ERROR";
export const retrieveCMSContentError = error => ({
  type: RETRIEVE_CMS_CONTENT_ERROR,
  payload: { error }
});

export const RETRIEVE_RELATED_CONTENT = "cms/RETRIEVE_RELATED_CONTENT";
export const retrieveRelatedContent = (search, meta, key, pageSlug) => ({
  type: RETRIEVE_RELATED_CONTENT,
  payload: { search, meta, key, pageSlug }
});

export const RETRIEVE_RELATED_CONTENT_SUCCESS =
  "cms/RETRIEVE_RELATED_CONTENT_SUCCESS";
export const retrieveRelatedContentSuccess = ({ content, key }) => ({
  type: RETRIEVE_RELATED_CONTENT_SUCCESS,
  payload: { key, content }
});

export const RETRIEVE_RELATED_CONTENT_ERROR =
  "cms/RETRIEVE_RELATED_CONTENT_ERROR";
export const retrieveRelatedContentError = ({ error, key }) => ({
  type: RETRIEVE_RELATED_CONTENT_ERROR,
  payload: { error, key }
});

export const SET_SEARCH_TERM = "cms/SET_SEARCH_TERM";
export const setSearchTerm = searchTerm => ({
  type: SET_SEARCH_TERM,
  payload: { searchTerm }
});

export const RETRIEVE_SEARCH_RESULTS = "cms/RETRIEVE_SEARCH_RESULTS";
export const retrieveSearchResults = (
  search,
  meta,
  userSearchString,
  redirect = false
) => ({
  type: RETRIEVE_SEARCH_RESULTS,
  payload: { search, meta, userSearchString, redirect }
});

export const RETRIEVE_SEARCH_RESULTS_SUCCESS =
  "cms/RETRIEVE_SEARCH_RESULTS_SUCCESS";
export const retrieveSearchResultsSuccess = payload => ({
  type: RETRIEVE_SEARCH_RESULTS_SUCCESS,
  payload
});

export const RETRIEVE_SEARCH_RESULTS_ERROR =
  "cms/RETRIEVE_SEARCH_RESULTS_ERROR";
export const retrieveSearchResultsError = error => ({
  type: RETRIEVE_SEARCH_RESULTS_ERROR,
  payload: { error }
});

export const RETRIEVE_ACTION_PAGES = "cms/RETRIEVE_ACTION_PAGES";
export const retrieveActionPages = (pageSlug, meta) => ({
  type: RETRIEVE_ACTION_PAGES,
  payload: { meta }
});

export const RETRIEVE_ACTION_PAGES_SUCCESS =
  "cms/RETRIEVE_ACTION_PAGES_SUCCESS";
export const retrieveActionPagesSuccess = payload => ({
  type: RETRIEVE_ACTION_PAGES_SUCCESS,
  payload
});

export const RETRIEVE_ACTION_PAGES_ERROR = "cms/RETRIEVE_ACTION_PAGES_ERROR";
export const retrieveActionPagesError = error => ({
  type: RETRIEVE_ACTION_PAGES_ERROR,
  payload: { error }
});

export const RETRIEVE_SECTION_PAGES = "cms/RETRIEVE_SECTION_PAGES";
export const retrieveSectionPages = (pageSlug, meta) => ({
  type: RETRIEVE_SECTION_PAGES,
  payload: { meta }
});

export const RETRIEVE_SECTION_PAGES_SUCCESS =
  "cms/RETRIEVE_SECTION_PAGES_SUCCESS";
export const retrieveSectionPagesSuccess = payload => ({
  type: RETRIEVE_SECTION_PAGES_SUCCESS,
  payload
});

export const RETRIEVE_SECTION_PAGES_ERROR = "cms/RETRIEVE_SECTION_PAGES_ERROR";
export const retrieveSectionPagesError = error => ({
  type: RETRIEVE_SECTION_PAGES_ERROR,
  payload: { error }
});

export const RETRIEVE_SUBJECT_PAGES = "cms/RETRIEVE_SUBJECT_PAGES";
export const retrieveSubjectPages = (pageSlug, meta) => ({
  type: RETRIEVE_SUBJECT_PAGES,
  payload: { meta }
});

export const RETRIEVE_SUBJECT_PAGES_SUCCESS =
  "cms/RETRIEVE_SUBJECT_PAGES_SUCCESS";
export const retrieveSubjectPagesSuccess = payload => ({
  type: RETRIEVE_SUBJECT_PAGES_SUCCESS,
  payload
});

export const RETRIEVE_SUBJECT_PAGES_ERROR = "cms/RETRIEVE_SUBJECT_PAGES_ERROR";
export const retrieveSubjectPagesError = error => ({
  type: RETRIEVE_SUBJECT_PAGES_ERROR,
  payload: { error }
});

const initialState = {
  retrieving: false,
  relatedContent: {},
  searchResults: {
    searchTerm: "",
    retrievingSearchResults: false,
    content: [],
    error: false
  },
  actionPages: {
    retrievingActionPages: false,
    pages: [],
    error: false
  },
  sectionPages: {
    retrievingSectionPages: false,
    pages: [],
    error: false
  },
  subjectPages: {
    retrievingSubjectPages: false,
    pages: [],
    error: false
  },
  error: false
};

export const _reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RETRIEVE_CMS_CONTENT:
      return {
        ...state,
        retrieving: true
      };
    case RETRIEVE_CMS_CONTENT_SUCCESS:
      return {
        ...state,
        retrieving: false,
        content: payload
      };
    case RETRIEVE_CMS_CONTENT_ERROR:
      return {
        ...state,
        retrieving: false,
        error: payload.error
      };
    case RETRIEVE_RELATED_CONTENT:
      return {
        ...state,
        relatedContent: {
          ...state.relatedContent,
          [payload.key]: {
            retrievingRelatedContent: true,
            content: [],
            error: false
          }
        }
      };
    case RETRIEVE_RELATED_CONTENT_SUCCESS:
      return {
        ...state,
        relatedContent: {
          ...state.relatedContent,
          [payload.key]: {
            retrievingRelatedContent: false,
            content: payload.content
          }
        }
      };
    case RETRIEVE_RELATED_CONTENT_ERROR:
      return {
        ...state,
        relatedContent: {
          ...state.relatedContent,
          [payload.key]: {
            retrievingRelatedContent: false,
            error: payload.error
          }
        }
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          searchTerm: payload.searchTerm
        }
      };
    case RETRIEVE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          retrievingSearchResults: true
        }
      };
    case RETRIEVE_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          retrievingSearchResults: false,
          content: payload
        }
      };
    case RETRIEVE_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          retrievingSearchResults: false,
          error: payload.error
        }
      };
    case RETRIEVE_ACTION_PAGES:
      return {
        ...state,
        actionPages: {
          ...state.actionPages,
          retrievingActionPages: true,
          error: false
        }
      };
    case RETRIEVE_ACTION_PAGES_SUCCESS:
      return {
        ...state,
        content: { navigation: payload.navigation },
        actionPages: {
          ...state.actionPages,
          retrievingActionPages: false,
          pages: payload.actionPages
        }
      };
    case RETRIEVE_ACTION_PAGES_ERROR:
      return {
        ...state,
        actionPages: {
          ...state.actionPages,
          retrievingActionPages: false,
          error: payload.error
        }
      };
    case RETRIEVE_SECTION_PAGES:
      return {
        ...state,
        sectionPages: {
          ...state.sectionPages,
          retrievingSectionPages: true,
          error: false
        }
      };
    case RETRIEVE_SECTION_PAGES_SUCCESS:
      return {
        ...state,
        content: { navigation: payload.navigation },
        sectionPages: {
          ...state.sectionPages,
          retrievingSectionPages: false,
          pages: payload.sectionPages
        }
      };
    case RETRIEVE_SECTION_PAGES_ERROR:
      return {
        ...state,
        sectionPages: {
          ...state.sectionPages,
          retrievingSectionPages: false,
          error: payload.error
        }
      };
    case RETRIEVE_SUBJECT_PAGES:
      return {
        ...state,
        subjectPages: {
          ...state.subjectPages,
          retrievingSubjectPages: true,
          error: false
        }
      };
    case RETRIEVE_SUBJECT_PAGES_SUCCESS:
      return {
        ...state,
        content: { navigation: payload.navigation },
        subjectPages: {
          ...state.subjectPages,
          retrievingSubjectPages: false,
          pages: payload.subjectPages
        }
      };
    case RETRIEVE_SUBJECT_PAGES_ERROR:
      return {
        ...state,
        subjectPages: {
          ...state.subjectPages,
          retrievingSubjectPages: false,
          error: payload.error
        }
      };
    default:
      return state;
  }
};

export const _mapStateToProps = state => state.cms;
export const _mapDispatchToProps = dispatch => ({
  retrieveCMSContent: (pageSlug, meta, id, preview) =>
    dispatch(retrieveCMSContent(pageSlug, meta, id, preview)),
  retrieveRelatedContent: (search, meta, key, pageSlug) =>
    dispatch(retrieveRelatedContent(search, meta, key, pageSlug)),
  retrieveSearchResults: (search, meta, userSearchString, redirect) =>
    dispatch(retrieveSearchResults(search, meta, userSearchString, redirect)),
  setSearchTerm: searchTerm => dispatch(setSearchTerm(searchTerm)),
  retrieveActionPages: (pageSlug, meta) =>
    dispatch(retrieveActionPages(pageSlug, meta)),
  retrieveSectionPages: (pageSlug, meta) =>
    dispatch(retrieveSectionPages(pageSlug, meta)),
  retrieveSubjectPages: (pageSlug, meta) =>
    dispatch(retrieveSubjectPages(pageSlug, meta))
});

export const ALERT_BAR_ACTION = "cms/ALERT_BAR_ACTION";
export const cmsAlertBarAction = Nested.nestedAction(ALERT_BAR_ACTION);

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    alertBars: {
      cmsAlertBar: {
        ...AlertBarState,
        actionType: ALERT_BAR_ACTION
      }
    }
  },
  "cms"
);

const reducer = (state, action) => nestedReducer(state, action);

export { reducer, mapStateToProps, mapDispatchToProps, actions };
