import React, { useContext } from "react";
import { Box, Cover, Heading, Motion, withWindowSize } from "@thecb/components";
import { ThemeContext } from "styled-components";
import LinkMenu from "./LinkMenu";
import HamburgerNavSection from "./HamburgerNavSection";
import { borderWrapper } from "./style";

const NavLinks = ({
  navigation,
  hamburgerMenuStyle,
  themeValues,
  navMenuOpen,
  hamburgerOpenSection,
  selectedNavMenu,
  setSearchMenuOpen,
  setSelectedMenu,
  setNavMenuOpen,
  setIsAnimating,
  setKeyboardNavUsed,
  setHamburgerOpenSection
}) => {
  const { isMobile } = useContext(ThemeContext);
  const selectMenu = (index, kbNavUsed) => {
    setKeyboardNavUsed(kbNavUsed);
    if (navMenuOpen !== true) {
      setSearchMenuOpen(false);
      setSelectedMenu(index);
      setNavMenuOpen(true);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 600);
    } else if (selectedNavMenu !== index) {
      setSelectedMenu(index);
      setSearchMenuOpen(false);
    }
  };

  return (
    <>
      {navigation?.sections?.map((link, index) => {
        return (
          <Box
            padding="0"
            key={`nav-link-${index}`}
            extraStyles={!hamburgerMenuStyle && `height: 102px;`}
          >
            <Cover
              singleChild
              minHeight={isMobile ? "0%" : "100%"}
              key={`section-${index}`}
            >
              {hamburgerMenuStyle ? (
                <HamburgerNavSection
                  link={link}
                  toggleSection={() => {
                    link?.id === hamburgerOpenSection
                      ? setHamburgerOpenSection("")
                      : setHamburgerOpenSection(link?.id);
                  }}
                  isOpen={hamburgerOpenSection === link?.id}
                  name={link?.name}
                  themeValues={themeValues}
                >
                  <Motion
                    padding="0"
                    transition={{ duration: 0.3 }}
                    positionTransition
                    extraStyles={`transform-origin: 100% 0;`}
                  >
                    <LinkMenu
                      key={link.id}
                      link={link}
                      hamburgerMenuStyle={hamburgerMenuStyle}
                      themeValues={themeValues}
                      kbNavUsed={false}
                      onChangeMenu={() => {
                        setNavMenuOpen(false);
                        setSelectedMenu(100);
                      }}
                    />
                  </Motion>
                </HamburgerNavSection>
              ) : (
                <Box
                  padding="0"
                  as="button"
                  background="transparent"
                  tabIndex="0"
                  onClick={() => {
                    setSearchMenuOpen(false);
                    setSelectedMenu(index);
                    setNavMenuOpen(!navMenuOpen);
                    setIsAnimating(true);
                    setTimeout(() => {
                      setIsAnimating(false);
                    }, 600);
                  }}
                  onMouseEnter={() => selectMenu(index, false)}
                  onFocus={() => selectMenu(index, true)}
                >
                  <Box padding="0 1.5rem">
                    <Heading
                      variant="h6"
                      color={isMobile ? "#FFFFFF" : themeValues.linkColor}
                      extraStyles={
                        isMobile ? "font-size: 14px;" : `font-size: 16px;`
                      }
                    >
                      {link?.name}
                    </Heading>
                  </Box>
                </Box>
              )}
              {!hamburgerMenuStyle && (
                <Motion
                  padding="0"
                  minWidth="100%"
                  variants={borderWrapper}
                  animate={navMenuOpen ? "open" : "closed"}
                  layoutTransition
                  extraStyles={`border-bottom: 3px solid ${
                    selectedNavMenu === index
                      ? `${themeValues.linkColor}`
                      : `transparent`
                  }`}
                />
              )}
            </Cover>
          </Box>
        );
      })}
    </>
  );
};

export default NavLinks;
