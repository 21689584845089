import { createFormState, required, isProbablyEmail } from "redux-freeform";

const formConfig = {
  email: {
    validators: [required(), isProbablyEmail()]
  },
  password: {
    validators: [required()]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
