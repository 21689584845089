import React from "react";
import { Box, Switcher, Center, Cluster, Cover } from "../layouts";

const NavFooter = ({
  leftContent,
  rightContent,
  footerMinHeight = "104px",
  backgroundColor,
  largeSide = "right",
  largeSideSize = "2",
  footerPadding = "1.5rem 1rem",
  isMobile,
  footerWidth,
  ...rest
}) => (
  <Box
    padding={footerPadding}
    background={backgroundColor}
    minHeight={footerMinHeight}
    minWidth="100%"
    extraStyles={
      isMobile
        ? `overflow: hidden;`
        : `height: ${footerMinHeight}; > * { height: 100%; };`
    }
    {...rest}
  >
    <Center maxWidth={footerWidth || "76.5rem"}>
      <Cover singleChild minHeight="100%">
        <Box minHeight="100%" padding="0">
          <Switcher
            breakpoint={isMobile ? "999px" : "700px"}
            largeChild={largeSide === "right" ? "2" : "1"}
            largeChildSize={largeSideSize}
            childGap="0.5rem"
          >
            {leftContent}
            <Cluster
              justify={isMobile ? "flex-start" : "flex-end"}
              align="center"
              childGap="0.5rem"
            >
              <Switcher
                maxChildrenOnly
                maxChildren={isMobile ? 1 : 99}
                childGap={isMobile ? "1rem" : "2rem"}
              >
                {rightContent}
              </Switcher>
            </Cluster>
          </Switcher>
        </Box>
      </Cover>
    </Center>
  </Box>
);

export default NavFooter;
