import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "./FormLayouts.theme.js";
import { themeComponent } from "../../../util/themeUtils";
import { Box, Cluster } from "../layouts";
import ButtonWithLink from "../button-with-link";
import withWindowSize from "../../withWindowSize";

const FormFooterPanel = ({ themeValues, link, linkText }) => {
  const { isMobile } = useContext(ThemeContext);
  return (
    <Box
      background={themeValues.formFooterPanel}
      minWidth="100%"
      padding={isMobile ? "1rem" : "1.5rem"}
    >
      <Cluster justify="center" align="center">
        <ButtonWithLink url={link} variant="smallGhost" text={linkText} />
      </Cluster>
    </Box>
  );
};

export default themeComponent(
  withWindowSize(FormFooterPanel),
  "FormFooterPanel",
  fallbackValues,
  "default"
);
