import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import {
  createActivateUserAttempt,
  createResendVerificationAttempt
} from "./graphql/Queries";
import {
  ACCOUNT_VERIFICATION_SUCCESS,
  ACTIVATE_USER,
  RESEND_VERIFICATION,
  alertBarAction,
  resendVerificationSuccess,
  activateUserSuccess,
  resendVerification,
  activateUserFailure,
  resendVerificationFailure
} from "./AccountVerification.state";
import {
  addAlert,
  clearAlerts
} from "../../../../components/alert-bar/AlertBar.state";
import { clearState } from "../../../../state/reducer";
import {
  getGraphqlServiceEndpoint,
  getClientSlug
} from "../../../../util/state";

const resendSuccessAlert = (email, resendType) =>
  resendType === "expired"
    ? {
        heading: "Verification link has expired",
        text: `We sent a new verification email to you at ${email}.`,
        variant: "error"
      }
    : {
        heading: "Verification email sent",
        text: `We sent a new verification email to you at ${email}.`,
        variant: "success"
      };

const verifcationEmailUnableToSendAlert = {
  heading: "Unable to send verification email",
  text:
    "We were unable to send a new verification email. Please wait a moment and then try again.",
  variant: "error"
};

const userAlreadyActivated = {
  heading: "Email address verified",
  text:
    "This email address has already been verified, please proceed to the login page below.",
  variant: "success"
};

const getRequestParams = (additionalParams, state) => ({
  endpoint: getGraphqlServiceEndpoint(state),
  clientSlug: getClientSlug(state),
  ...additionalParams
});

const push_and_remove_query_params = pathname =>
  push({ pathname: pathname, search: "" });

export const activateUserEpic = (action$, state$) =>
  action$.ofType(ACTIVATE_USER).pipe(
    RxOp.mergeMap(({ payload: { verificationKey } }) =>
      Rx.from(
        createActivateUserAttempt(
          getRequestParams({ verificationKey }, state$.value)
        )
      ).pipe(
        RxOp.flatMap(response => {
          const {
            authentication: { email: email },
            status: verificationStatus
          } = response.createUserActivationRequest;

          return verificationStatus === ACCOUNT_VERIFICATION_SUCCESS
            ? activateUserSuccessful()
            : activateUserFailed(email);
        }),
        RxOp.catchError(error => {
          console.error({ error });
          return Rx.of(push(`/verify-account/failure`), clearState());
        })
      )
    )
  );

const activateUserSuccessful = () =>
  Rx.of(
    activateUserSuccess(),
    alertBarAction(clearAlerts()),
    push_and_remove_query_params("/verify-account/success"),
    clearState()
  );

const activateUserFailed = email =>
  Rx.of(
    activateUserFailure(email),
    push_and_remove_query_params("/verify-account"),
    alertBarAction(clearAlerts()),
    resendVerification(email, "expired")
  );

export const resendVerificationEpic = (action$, state$) =>
  action$.ofType(RESEND_VERIFICATION).pipe(
    RxOp.mergeMap(({ payload: { email, resendType } }) =>
      Rx.from(
        createResendVerificationAttempt(
          getRequestParams({ email }, state$.value)
        )
      ).pipe(
        RxOp.flatMap(() =>
          Rx.of(
            alertBarAction(clearAlerts()),
            resendVerificationSuccess(),
            alertBarAction(addAlert(resendSuccessAlert(email, resendType)))
          )
        ),
        RxOp.catchError(error =>
          error.response.errors[0].code === "account_activated"
            ? Rx.of(
                activateUserSuccess(),
                alertBarAction(clearAlerts()),
                push_and_remove_query_params("/verify-account/success"),
                clearState(),
                alertBarAction(addAlert(userAlreadyActivated))
              )
            : Rx.of(
                resendVerificationFailure(),
                alertBarAction(clearAlerts()),
                alertBarAction(addAlert(verifcationEmailUnableToSendAlert))
              )
        )
      )
    )
  );
