import React from "react";

const ChargebackReversalIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -208.000000)"
        fill="#B34A00"
      >
        <path
          d="M80,240 C88.836556,240 96,232.836556 96,224 C96,215.163444 88.836556,208 80,208 C71.163444,208 64,215.163444 64,224 C64,232.836556 71.163444,240 80,240 Z M85,218 C85.5522847,218 86,218.447715 86,219 L86,229 C86,229.552285 85.5522847,230 85,230 L71,230 C70.4477153,230 70,229.552285 70,229 L70,219 C70,218.447715 70.4477153,218 71,218 L85,218 Z M85,224 L71,224 L71,229 L85,229 L85,224 Z M88.1464466,220.853553 C88.3417088,220.658291 88.6582912,220.658291 88.8535534,220.853553 L88.8535534,220.853553 L91.6464466,223.646447 C91.8417088,223.841709 91.8417088,224.158291 91.6464466,224.353553 L91.6464466,224.353553 L88.8535534,227.146447 C88.7597852,227.240215 88.6326082,227.292893 88.5,227.292893 C88.2238576,227.292893 88,227.069036 88,226.792893 L88,226.792893 L88,221.207107 C88,221.074499 88.0526784,220.947322 88.1464466,220.853553 Z M82.75,225.75 C83.0261424,225.75 83.25,225.973858 83.25,226.25 L83.25,226.75 C83.25,227.026142 83.0261424,227.25 82.75,227.25 L80.75,227.25 C80.4738576,227.25 80.25,227.026142 80.25,226.75 L80.25,226.25 C80.25,225.973858 80.4738576,225.75 80.75,225.75 L82.75,225.75 Z M85,219 L71,219 L71,221.5 L85,221.5 L85,219 Z"
          id="status-icon---chargeback-reversal"
        ></path>
      </g>
    </g>
  </svg>
);

export default ChargebackReversalIcon;
