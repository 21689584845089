import React from "react";
import { path } from "ramda";
import { ErrorContainer, ErrorContent } from "./Error.styled";
import { Helmet } from "react-helmet";

const Error = ({ title = "Error", error, faviconURL }) => {
  const message = error?.message ?? "Error";
  return (
    <ErrorContainer>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={faviconURL} />
      </Helmet>
      <ErrorContent>
        {path(["networkError", "result", "errors"], error) ? (
          <pre>
            {error.networkError.result.errors.map((error, i) => (
              <div key={i}>{message}</div>
            ))}
          </pre>
        ) : (
          <pre>{message}</pre>
        )}
      </ErrorContent>
    </ErrorContainer>
  );
};

export default Error;
