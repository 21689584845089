import React from "react";
import styled from "styled-components";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./FormattedBankAccount.theme";
import BankIcon from "../icons/BankIcon";
import { Stack, Box } from "../layouts";
import Text from "../text";

export const BankItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const BankAccountText = styled.h4`
  color: ${({ color }) => color};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  margin-right: 0.5rem;
  display: inline-block;
`;

const CHECKING = "CHECKING";
const SAVINGS = "SAVINGS";

const FormattedBankAccount = ({
  lastFour,
  accountType,
  autoPay,
  themeValues
}) => (
  <BankItemWrapper>
    <Box padding="0.25rem 0 0 0" extraStyles={`margin-right: 1rem;`}>
      <BankIcon />
    </Box>
    <Stack childGap="0">
      {accountType === CHECKING && (
        <BankAccountText color={themeValues.textColor}>
          Checking Account ending in {lastFour}
        </BankAccountText>
      )}
      {accountType === SAVINGS && (
        <BankAccountText color={themeValues.textColor}>
          Savings Account ending in {lastFour}
        </BankAccountText>
      )}
      {autoPay && (
        <Text
          variant="p"
          color={themeValues.autopayTextColor}
          extraStyles={`font-style: italic;`}
        >{`Autopay Enabled`}</Text>
      )}
    </Stack>
  </BankItemWrapper>
);

export default themeComponent(
  FormattedBankAccount,
  "FormattedBankAccount",
  fallbackValues
);
