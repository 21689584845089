import React, { useEffect, useState } from "react";
import {
  ButtonWithAction,
  Cluster,
  Stack,
  TableCell,
  TableRow,
  Text
} from "@thecb/components";
import {
  HEADING_ACCOUNT,
  HEADING_AMOUNT,
  HEADING_DATE,
  HEADING_TRANSACTION_ID,
  HEADING_PAYMENT_METHOD
} from "/constants/transaction_history_constants";
import { FONT_WEIGHT_SEMIBOLD } from "/constants/style_constants";
import { CHARADE_GREY } from "/constants/colors";
import {
  paymentLastFour,
  transactionDescription
} from "/util/transactionHistory";
import { PaymentMethodIcon } from "./PaymentMethodIcon";
import TransactionAmount from "./TransactionAmount";
import TransactionDate from "./TransactionDate";

const FONT_SIZE = "14px";

export const TransactionRow = ({
  headings,
  isMobile,
  obligation,
  obligationsConfig,
  onAccountClick,
  onTransactionClick,
  transaction
}) => {
  const DESKTOP_TABLE_CELL_PADDING = "16px 24px";
  const MOBILE_TABLE_CELL_PADDING = "12px 16px";
  const [tableCellPadding, setTableCellPadding] = useState(
    DESKTOP_TABLE_CELL_PADDING
  );
  useEffect(() => {
    if (isMobile) {
      setTableCellPadding(MOBILE_TABLE_CELL_PADDING);
    } else {
      setTableCellPadding(DESKTOP_TABLE_CELL_PADDING);
    }
  }, [isMobile]);

  const accountCell = () => {
    return (
      <TableCell padding={tableCellPadding} maxWidth="160px">
        {obligation ? (
          <ButtonWithAction
            variant="ghost"
            onClick={() => onAccountClick(obligation)}
            extraStyles="text-align: left; padding: 0; margin: 0; min-width: 0;"
            text={accountContent({
              color: "inherit"
            })}
          ></ButtonWithAction>
        ) : (
          accountContent()
        )}
      </TableCell>
    );
  };

  const accountContent = ({ color = CHARADE_GREY } = {}) => {
    const description = transactionDescription({
      transaction,
      obligation,
      obligationsConfig
    });
    const subDescription = obligation?.obligations?.[0]?.subDescription;
    return (
      <Stack childGap={0}>
        <Text weight={FONT_WEIGHT_SEMIBOLD} fontSize={FONT_SIZE} color={color}>
          {description}
        </Text>
        {subDescription && (
          <Text fontSize={"12px"} color={color}>
            {subDescription}
          </Text>
        )}
      </Stack>
    );
  };

  const dateCell = () => (
    <TableCell padding={tableCellPadding} extraStyles={"overflow: visible"}>
      <TransactionDate {...transaction} fontSize={FONT_SIZE} />
    </TableCell>
  );

  const paymentMethodCell = () => (
    <TableCell padding={tableCellPadding}>
      <Cluster childGap={"8px"} align="center">
        <PaymentMethodIcon paymentType={transaction.paymentType} />
        <Text color={CHARADE_GREY} fontSize={FONT_SIZE}>
          {paymentLastFour(transaction) ?? ""}
        </Text>
      </Cluster>
    </TableCell>
  );

  const transactionIDCell = () => (
    <TableCell padding={tableCellPadding}>
      <ButtonWithAction
        variant="ghost"
        onClick={() => onTransactionClick(transaction)}
        extraStyles="text-align: left; padding: 0; margin: 0; min-width: 0;"
        text={
          <Text color="inherit" fontSize={FONT_SIZE}>
            {transaction.id}
          </Text>
        }
      ></ButtonWithAction>
    </TableCell>
  );

  const transactionAmountCell = () => (
    <TableCell extraStyles="overflow: initial" padding={tableCellPadding}>
      <TransactionAmount {...transaction} fontSize={FONT_SIZE} />
    </TableCell>
  );

  const rowHeadingMapping = {
    [HEADING_ACCOUNT]: accountCell(),
    [HEADING_DATE]: dateCell(),
    [HEADING_PAYMENT_METHOD]: paymentMethodCell(),
    [HEADING_TRANSACTION_ID]: transactionIDCell(),
    [HEADING_AMOUNT]: transactionAmountCell()
  };

  return (
    <TableRow key={transaction.id} hoverCursor={false}>
      {headings.map(heading => rowHeadingMapping[heading])}
    </TableRow>
  );
};
