import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../../content/Content.theme";
import { themeComponent } from "../../../util/themeUtils";
import { withWindowSize } from "@thecb/components";
import {
  Box,
  Center,
  Cluster,
  Cover,
  Stack,
  Sidebar,
  Heading,
  constants
} from "@thecb/components";
import useRelatedContent from "../../content/hooks/useRelatedContent";
import RelatedContentSection from "../../content/related-content-section";
import { MAX_CONTENT_WIDTH } from "../../../constants/style_constants";

const CMSSubjectPage = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  maxContentWidth = MAX_CONTENT_WIDTH,
  fullWidthMobile = true,
  content,
  mainContent,
  mainContentMinWidth = "50rem",
  sidebarContent = null,
  sidebarTargetWidth = "24rem",
  secondaryContent,
  secondaryContentGap = "2rem",
  pageTitle,
  subtitle,
  showRelatedContent,
  parentPage,
  otherRelatedContent,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const relatedContentCards = useRelatedContent(
    parentPage,
    [],
    otherRelatedContent
  );
  const gradient = `background: ${themeValues.darkGradient[0]};
  background: linear-gradient(-225deg, ${themeValues.darkGradient[2]} 0%, ${themeValues.darkGradient[3]} 99%); display: flex; flex-direction: column; justify-content: center; align-items: flex-start; > div { width: 100%; }`;

  const headingContainer = (
    <Box
      padding="0"
      key="heading-container"
      maxWidth="80%"
      extraStyles={isMobile ? "padding-top: 16px; padding-bottom: 16px;" : ""}
    >
      <Stack childGap="1rem">
        <Box
          padding="0"
          minHeight="5px"
          width="50px"
          borderSize="5px"
          borderColor={constants.colors.WHITE}
          borderWidthOverride="0 0 5px 0"
          key="subject-page-accent"
        />
        <Heading
          variant="h1"
          color={constants.colors.WHITE}
          key="heading"
          weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
          extraStyles={`margin-top: 8px; font-size: ${
            isMobile ? "28px" : "40px"
          }; line-height: ${isMobile ? "40px" : "60px"};`}
        >
          {pageTitle ? pageTitle.substring(0, 70) : ""}
        </Heading>
        {subtitle && (
          <Heading
            variant="h2"
            color={constants.colors.WHITE}
            key="subtitle"
            weight={constants.fontWeights.FONT_WEIGHT_NORMAL}
            extraStyles={`font-size: ${
              isMobile ? "21px" : "24px"
            };line-height: ${isMobile ? "30px" : "36px"};`}
          >
            {subtitle.substring(0, 140)}
          </Heading>
        )}
      </Stack>
    </Box>
  );

  const PageStack = (
    <Box
      padding="0"
      minWidth="100%"
      background={constants.colors.WHITE}
      extraStyles={isMobile && `overflow-x: hidden;`}
    >
      <Cover minHeight="100vh" fillCenter>
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box
            padding={isMobile ? "0 16px" : "0 24px"}
            minWidth="100%"
            minHeight={isMobile ? "140px" : "225px"}
            maxHeight={isMobile ? "140px" : "225px"}
            extraStyles={gradient}
          >
            <Center
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            >
              {headingContainer}
            </Center>
          </Box>
          <Cluster justify="center" extraStyles="width:100%;">
            <Box
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
              width="100%"
              padding={0}
              extraStyles={{ margin: "0 16px" }}
            >
              <Stack childGap="0">
                <Cover
                  minHeight="100%"
                  singleChild
                  fillCenter
                  key="content-container"
                >
                  <Stack childGap="64px">
                    {content}
                    {showRelatedContent && (
                      <RelatedContentSection
                        relatedContentCards={relatedContentCards}
                        maxContentWidth={maxContentWidth}
                        themeValues={themeValues}
                        mobileContentPadding={0}
                      />
                    )}
                  </Stack>
                </Cover>
              </Stack>
            </Box>
          </Cluster>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );

  const PageStackSidebar = (
    <Box padding="0" minWidth="100%" background={constants.colors.WHITE}>
      <Cover minHeight="100vh">
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box
            padding={isMobile ? "0 16px" : "0"}
            minWidth="100%"
            minHeight={isMobile ? "140px" : "225px"}
            maxHeight={isMobile ? "140px" : "225px"}
            extraStyles={gradient}
          >
            <Center
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            >
              {headingContainer}
            </Center>
          </Box>
          <Cluster justify="center" flexGrow={0} extraStyles="width:100%;">
            <Box
              padding={0}
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
              width="100%"
            >
              <Stack childGap="2rem">
                <Cover
                  minHeight="100%"
                  singleChild
                  fillCenter
                  key="content-container"
                >
                  <Stack childGap={secondaryContentGap}>
                    <Fragment key="sidebar">
                      <Sidebar
                        width={isMobile ? "100%" : sidebarTargetWidth}
                        childGap={isMobile ? "1px" : "80px"}
                        contentMinWidth={
                          isMobile ? "100%" : mainContentMinWidth
                        }
                        key="content-sidebar"
                        sidebarOnRight
                        fullHeight
                      >
                        <Box
                          key="content-stack"
                          padding={isMobile ? "0 16px" : "0"}
                        >
                          {mainContent}
                        </Box>
                        <Box padding={isMobile ? "40px 16px 0" : "40px 0 0"}>
                          <Box
                            key="sidebar-content"
                            background="white"
                            padding="0"
                          >
                            <Stack childGap="40px">{sidebarContent}</Stack>
                          </Box>
                        </Box>
                      </Sidebar>
                      <Box padding={isMobile ? "0 16px" : "0"}>
                        <Stack childGap="64px">
                          {secondaryContent}
                          {showRelatedContent && (
                            <Box padding={0} width="100%">
                              <RelatedContentSection
                                relatedContentCards={relatedContentCards}
                                maxContentWidth={maxContentWidth}
                                mobileContentPadding={0}
                                themeValues={themeValues}
                              />
                            </Box>
                          )}
                        </Stack>
                      </Box>
                    </Fragment>
                  </Stack>
                </Cover>
              </Stack>
            </Box>
          </Cluster>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );

  return sidebarContent !== null ? PageStackSidebar : PageStack;
};

export default withWindowSize(
  themeComponent(CMSSubjectPage, "Content", fallbackValues, "default")
);
