import React from "react";

const GenericCard = () => (
  <svg
    width="28"
    height="18"
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Card Payment"
  >
    <rect width="28" height="18" rx="2" fill="#15749D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86108 13C3.44687 13 3.11108 13.3358 3.11108 13.75C3.11108 14.1642 3.44687 14.5 3.86108 14.5H7.80553C8.21974 14.5 8.55553 14.1642 8.55553 13.75C8.55553 13.3358 8.21974 13 7.80553 13H3.86108ZM10.8611 13C10.4469 13 10.1111 13.3358 10.1111 13.75C10.1111 14.1642 10.4469 14.5 10.8611 14.5H17.9166C18.3309 14.5 18.6666 14.1642 18.6666 13.75C18.6666 13.3358 18.3309 13 17.9166 13H10.8611Z"
      fill="#E4F4FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2223 5.25V6.75H18.6667V6.25C18.6667 5.69772 19.1145 5.25 19.6667 5.25H20.2223ZM20.4723 5.25V6.75H22.3056V5.25H22.5556V6.75H24.1109V7H22.5556V8.25L24.1109 8.25V8.5L22.5556 8.5V9.74999L23.1111 9.74999C23.6634 9.74999 24.1111 9.30228 24.1111 8.74999V6.25C24.1111 5.69772 23.6634 5.25 23.1111 5.25H20.4723ZM22.3056 9.74999V8.5L20.4723 8.49999V9.74999L22.3056 9.74999ZM20.2223 9.74999V8.49999L18.6667 8.49999V8.74999C18.6667 9.30228 19.1145 9.74999 19.6667 9.74999H20.2223ZM18.6667 8.24999L20.2223 8.24999V7H18.6667V8.24999ZM20.4723 8.24999V7H22.3056V8.25L20.4723 8.24999Z"
      fill="#F4C932"
    />
  </svg>
);

export default GenericCard;
