import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";

import {
  FETCH_WORKFLOW,
  fetchWorkflowSuccess,
  fetchWorkflowError
} from "../reducer";

const getWorkflowUrl = state =>
  state.config.configWorkflowsEndpoint === "http://localhost:8080/workflows"
    ? `${state.config.configWorkflowsEndpoint}/${state.config.defaultClientSlug}/workflows.json`
    : `${state.config.configWorkflowsEndpoint}/workflows.json`;

const fetchWorkflows = async url => await fetch(url, { cache: "no-store" });

/* This code fetches our newer flat file workflows.
 * The other workflow approach will be deprecated.
 */
export const fetchWorkflowEpic = (action$, state$) =>
  action$.ofType(FETCH_WORKFLOW).pipe(() =>
    Rx.from(fetchWorkflows(getWorkflowUrl(state$.value))).pipe(
      RxOp.mergeMap(response => Rx.from(response.json())),
      RxOp.map(body => fetchWorkflowSuccess(body)),
      RxOp.catchError(error => Rx.of(fetchWorkflowError(error)))
    )
  );
