import React from "react";
import ButtonWithAction from "../button-with-action";
import { Box, Center, Cluster, Cover } from "../layouts";
import { IconAdd } from "../icons";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { fallbackValues } from "./AddObligation.theme";
import { themeComponent } from "../../../util/themeUtils";
import { ROYAL_BLUE } from "../../../constants/colors";

const AddObligation = ({
  text,
  action,
  themeValues,
  extraStyles,
  textExtraStyles
}) => {
  const hoverStyles = `
  &:hover {
    .fill { fill: ${themeValues.hoverColor}; }
    .stroke { stroke: ${themeValues.hoverColor}; }
    button { text-decoration: underline; text-decoration-color: ${themeValues.hoverColor}; }
    span { color: ${themeValues.hoverColor}; }
  }`;

  const activeStyles = `
   &:active {
     .fill { fill: ${themeValues.activeColor}; }
     .stroke { stroke: ${themeValues.activeColor}; }
    button { text-decoration: underline; text-decoration-color: ${themeValues.activeColor}; }
    span { color: ${themeValues.activeColor}; }
    }`;

  const defaultStyles = `
      min-height: 0;
      .fill { fill: ${themeValues.color}; }
      .stroke { stroke: ${themeValues.color}; }
      &:focus {
        outline: 3px solid ${ROYAL_BLUE};
        outline-offset: 2px;
      }
     `;
  return (
    <Box
      className="button"
      padding="0"
      hoverStyles={hoverStyles}
      activeStyles={activeStyles}
      extraStyles={defaultStyles}
      onClick={action}
      tabIndex="0"
    >
      <Cover singleChild minHeight="100%">
        <Cluster justify="center" align="center" minHeight="100%">
          <IconAdd />
          <Center intrinsic>
            <ButtonWithAction
              action={action}
              text={text}
              variant="smallGhost"
              extraStyles={`min-height: 0; ${extraStyles}`}
              textExtraStyles={`padding: 0 0 0 0.75rem; font-size: 0.875rem; font-weight ${FONT_WEIGHT_SEMIBOLD}; ${textExtraStyles}`}
              tabIndex="-1"
            />
          </Center>
        </Cluster>
      </Cover>
    </Box>
  );
};

export default themeComponent(AddObligation, "AddObligation", fallbackValues);
