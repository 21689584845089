import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const CaretArrowDown = ({ color }) => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.01563 1.11612C8.82037 0.920857 8.50379 0.920857 8.30853 1.11612L4.94977 4.47488L1.59101 1.11612C1.39575 0.920857 1.07917 0.920856 0.883904 1.11612L0.353574 1.64645C0.158312 1.84171 0.158312 2.15829 0.353574 2.35356L4.06588 6.06587L4.59621 6.5962C4.79148 6.79146 5.10806 6.79146 5.30332 6.5962L5.83365 6.06587L9.54596 2.35356C9.74122 2.15829 9.74122 1.84171 9.54596 1.64645L9.01563 1.11612Z"
      fill={color}
    />
  </svg>
);

export default themeComponent(CaretArrowDown, "Icons", fallbackValues);
