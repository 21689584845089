import React from "react";
import { Stack } from "../layouts";

const FormInputColumn = ({
  childGap = "0.5rem",
  bottomItem,
  children,
  ...rest
}) => (
  <Stack childGap={childGap} bottomItem={bottomItem} {...rest}>
    {children}
  </Stack>
);

export default FormInputColumn;
