import S from "/util/Sanctuary";
import { prop, compose, set, assign } from "partial.lenses";
import { omit, isEmpty, isNil } from "ramda";
import { push } from "connected-react-router";
import {
  ChangePasswordForm,
  EditNameForm,
  PaymentFormCard,
  PaymentFormACH,
  AddressForm,
  EmailForm,
  PhoneForm
} from "@thecb/components";

import {
  _resources,
  _resourceSuccess,
  _resourcePageSuccess,
  _resourceIdSuccess,
  _changeRequest,
  _automaticPaymentData,
  _paymentPlanData,
  _resourcesSuccess,
  _automaticPayment,
  _paymentPlan,
  _detailedObligation,
  _selectedPaymentForm,
  _customerManagementProfile,
  cleanAndFormatObligations,
  getDeniedCardsForClient
} from "./Profile.selectors";
import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";

import { getCardExpirationStatus } from "../../../../util/dateUtil";
import {
  normalizeById,
  getPrototypeOf,
  attachPrototypeToPayload,
  formatName,
  cleanSubClientName
} from "../../../../util/general";
import { configureEndpoint } from "../../../../util/router-utils";
import { nestedAction, nestStates } from "../../../../util/Nested";

export const START_CUSTOMER_MANAGEMENT = "profile/START_CUSTOMER_MANAGEMENT";
export const startCustomerManagement = profileId => ({
  type: START_CUSTOMER_MANAGEMENT,
  payload: profileId
});

export const FETCH_RESOURCES = "profile/FETCH_RESOURCES";
export const fetchResources = () => ({ type: FETCH_RESOURCES });

export const FETCH_RESOURCES_SUCCESS = "profile/FETCH_RESOURCES_SUCCESS";
export const fetchResourcesSuccess = data => ({
  type: FETCH_RESOURCES_SUCCESS,
  payload: { data }
});

const FETCH_RESOURCES_FAILURE = "profile/FETCH_RESOURCES_FAILURE";
export const fetchResourcesFailure = error => ({
  type: FETCH_RESOURCES_FAILURE,
  payload: { error }
});

const SET_RESOURCES = `profile/SET_RESOURCES`;
const setResources = resourceType => resources => ({
  type: SET_RESOURCES,
  payload: {
    resources,
    resourceType
  }
});

const UPSERT_RESOURCE = `profile/UPSERT_RESOURCE`;
const upsertResource = resourceType => resource => ({
  type: UPSERT_RESOURCE,
  payload: {
    resource,
    resourceType
  }
});

const UPDATE_PERSON = `profile/UPDATE_PERSON`;
export const updatePerson = profile => ({
  type: UPDATE_PERSON,
  payload: profile
});

export const bankForm = "Bank_Form";
export const creditCardForm = "Credit_Card_Form";

const SET_PAYMENT_FORM_TYPE = "profile/SET_PAYMENT_FORM_TYPE";
export const setPaymentFormType = formType => ({
  type: SET_PAYMENT_FORM_TYPE,
  payload: formType
});

export const SUBMIT_OPERATIONS = {
  ADD: "ADD",
  UPDATE: "UPDATE"
};
export const SUBMIT_CHANGE = "profile/SUBMIT_CHANGE";
export const submitChange = (
  settingName,
  operation = "",
  id = "",
  { inWallet } = false
) => ({
  type: SUBMIT_CHANGE,
  payload: {
    settingName,
    operation,
    id,
    inWallet
  }
});

export const REQUEST_SUCCESS = "profile/REQUEST_SUCCESS";
export const requestSuccess = (settingName, value = {}) => ({
  type: REQUEST_SUCCESS,
  payload: {
    settingName,
    value
  }
});

export const REQUEST_FAILURE = "profile/REQUEST_FAILURE";
export const requestFailure = (settingName, error = {}) => ({
  type: REQUEST_FAILURE,
  payload: {
    settingName,
    error
  }
});

export const CREATE_PAYMENT_FROM_PROFILE =
  "profile/CREATE_PAYMENT_FROM_PROFILE";
export const createPaymentFromProfile = (obligations, config) => ({
  type: CREATE_PAYMENT_FROM_PROFILE,
  payload: {
    obligations,
    config
  }
});

// TODO: Is this necessary? Can we deal with the workflow error somehow?
export const SEND_PROFILE_PAYMENT = "profile/SEND_PROFILE_PAYMENT";
export const sendProfilePayment = ({
  serviceName,
  options: { partialPaymentAllowed }
}) => ({
  type: SEND_PROFILE_PAYMENT,
  payload: {
    serviceName,
    partialPaymentAllowed
  }
});

export const POPULATE_PAYMENT_FROM_PROFILE =
  "profile/POPULATE_PAYMENT_FROM_PROFILE";
export const populatePaymentFromProfile = ({
  id,
  serviceName,
  lineItems,
  customAttributes,
  accountId,
  allowedPaymentMethods,
  accountLookupConfigKey,
  options: {
    fees,
    routingKey,
    agencyDisplayName,
    terms,
    contact,
    paymentMinimumInCents,
    paymentMaximumInCents,
    partialPaymentAllowed,
    partialPaymentMinimumInCents,
    blockPartialPaymentOverpay,
    subClientSlug,
    configForPaymentTypes
  }
}) => ({
  type: POPULATE_PAYMENT_FROM_PROFILE,
  payload: {
    id,
    lineItems,
    fees,
    routingKey,
    agencyDisplayName,
    terms,
    contact,
    customAttributes,
    accountId,
    allowedPaymentMethods,
    paymentMinimumInCents,
    paymentMaximumInCents,
    partialPaymentAllowed,
    partialPaymentMinimumInCents,
    blockPartialPaymentOverpay,
    serviceName,
    subClientSlug,
    accountLookupConfigKey,
    configForPaymentTypes,
    isProfilePayment: true
  }
});

export const AUTO_PAYMENT_SELECTED_PAYMENT_METHOD =
  "profile/AUTO_PAYMENT_SELECTED_PAYMENT_METHOD";
export const setAutoPaymentSelectedPaymentMethod = paymentMethod => ({
  type: AUTO_PAYMENT_SELECTED_PAYMENT_METHOD,
  payload: {
    paymentMethod
  }
});

export const PAYMENT_PLAN_SELECTED_PAYMENT_METHOD =
  "profile/PAYMENT_PLAN_SELECTED_PAYMENT_METHOD";
export const setPaymentPlanSelectedPaymentMethod = paymentMethod => ({
  type: PAYMENT_PLAN_SELECTED_PAYMENT_METHOD,
  payload: {
    paymentMethod
  }
});

export const SELECTED_OBLIGATION = "profile/SELECTED_OBLIGATION";
export const setSelectedObligation = (obligation, isPaymentPlan) => ({
  type: SELECTED_OBLIGATION,
  payload: {
    obligation,
    isPaymentPlan
  }
});

// TODO: eventually will need a payload with the selected payment rule?
export const CREATE_PAYMENT_SCHEDULE = "profile/CREATE_PAYMENT_SCHEDULE";
export const createPaymentSchedule = ({ obligationSlug }) => ({
  type: CREATE_PAYMENT_SCHEDULE,
  payload: {
    obligationSlug
  }
});

export const CREATE_PAYMENT_SCHEDULE_SUCCESS =
  "profile/CREATE_PAYMENT_SCHEDULE_SUCCESS";
export const createPaymentScheduleSuccess = (schedules, isPaymentPlan) => ({
  type: CREATE_PAYMENT_SCHEDULE_SUCCESS,
  payload: {
    schedules,
    isPaymentPlan
  }
});

export const CREATE_PAYMENT_SCHEDULE_FAILURE =
  "profile/CREATE_PAYMENT_SCHEDULE_FAILURE";
export const createPaymentScheduleFailure = error => ({
  type: CREATE_PAYMENT_SCHEDULE_FAILURE,
  payload: {
    error
  }
});

export const ADD_SCHEDULE = "profile/ADD_SCHEDULE";
export const addSchedule = schedule => ({
  type: ADD_SCHEDULE,
  payload: {
    schedule
  }
});

export const REMOVE_SCHEDULE = "profile/REMOVE_SCHEDULE";
export const removeSchedule = schedule => ({
  type: REMOVE_SCHEDULE,
  payload: {
    schedule
  }
});

export const DEACTIVATE_PAYMENT_SCHEDULE =
  "profile/DEACTIVATE_PAYMENT_SCHEDULE";
export const deactivatePaymentSchedule = (schedule, isPaymentPlan) => ({
  type: DEACTIVATE_PAYMENT_SCHEDULE,
  payload: {
    schedule,
    isPaymentPlan
  }
});

export const DEACTIVATE_PAYMENT_SCHEDULE_SUCCESS =
  "profile/DEACTIVATE_PAYMENT_SCHEDULE_SUCCESS";
export const deactivatePaymentScheduleSuccess = (
  paymentDetails,
  isPaymentPlan
) => ({
  type: DEACTIVATE_PAYMENT_SCHEDULE_SUCCESS,
  payload: {
    paymentDetails,
    isPaymentPlan
  }
});

export const DEACTIVATE_PAYMENT_SCHEDULE_FAILURE =
  "profile/DEACTIVATE_PAYMENT_SCHEDULE_FAILURE";
export const deactivatePaymentScheduleFailure = error => ({
  type: DEACTIVATE_PAYMENT_SCHEDULE_FAILURE,
  payload: {
    error
  }
});

export const DEACTIVATE_PAYMENT_INSTRUMENT =
  "profile/DEACTIVATE_PAYMENT_INSTRUMENT";
export const deactivatePaymentInstrument = paymentInstrument => ({
  type: DEACTIVATE_PAYMENT_INSTRUMENT,
  payload: {
    paymentInstrument
  }
});

export const DEACTIVATE_PAYMENT_INSTRUMENT_SUCCESS =
  "profile/DEACTIVATE_PAYMENT_INSTRUMENT_SUCCESS";
export const deactivatePaymentInstrumentSuccess = paymentInstrumentDetails => ({
  type: DEACTIVATE_PAYMENT_INSTRUMENT_SUCCESS,
  payload: {
    paymentInstrumentDetails
  }
});

export const DEACTIVATE_PAYMENT_INSTRUMENT_FAILURE =
  "profile/DEACTIVATE_PAYMENT_INSTRUMENT_FAILURE";
export const deactivatePaymentInstrumentFailure = error => ({
  type: DEACTIVATE_PAYMENT_INSTRUMENT_FAILURE,
  payload: {
    error
  }
});

export const TOGGLE_TERMS_AND_CONDITIONS =
  "profile/TOGGLE_TERMS_AND_CONDITIONS";
export const toggleTermsAndConditions = isPaymentPlan => ({
  type: TOGGLE_TERMS_AND_CONDITIONS,
  payload: {
    isPaymentPlan
  }
});

export const CLEAR_AUTO_PAY_INFO = "profile/CLEAR_AUTO_PAY_INFO";
export const clearAutoPayInfo = () => ({
  type: CLEAR_AUTO_PAY_INFO
});

export const CLEAR_PAYMENT_PLAN_INFO = "profile/CLEAR_PAYMENT_PLAN_INFO";
export const clearPaymentPlanInfo = () => ({
  type: CLEAR_PAYMENT_PLAN_INFO
});

export const SET_DETAILED_OBLIGATION = "profile/SET_DETAILED_OBLIGATION";
export const setDetailedObligation = (obligations, config, assocID) => ({
  type: SET_DETAILED_OBLIGATION,
  payload: {
    obligations,
    config,
    assocID
  }
});

export const SET_DETAILED_OBLIGATION_NAME =
  "profile/SET_DETAILED_OBLIGATION_NAME";
export const setDetailedObligationName = name => ({
  type: SET_DETAILED_OBLIGATION_NAME,
  payload: {
    name
  }
});

export const FETCH_ACCOUNT_BILL_URL = "profile/FETCH_ACCOUNT_BILL_URL";
export const fetchAccountBillURL = payload => ({
  type: FETCH_ACCOUNT_BILL_URL,
  payload
});

export const FETCH_ACCOUNT_BILL_URL_SUCCESS =
  "profile/FETCH_ACCOUNT_BILL_URL_SUCCESS";
export const fetchAccountBillURLSuccess = payload => ({
  type: FETCH_ACCOUNT_BILL_URL_SUCCESS,
  payload
});

export const FETCH_ACCOUNT_BILL_URL_FAILURE =
  "profile/FETCH_ACCOUNT_BILL_URL_FAILURE";
export const fetchAccountBillURLFailure = error => ({
  type: FETCH_ACCOUNT_BILL_URL_FAILURE,
  payload: {
    error
  }
});

export const NAVIGATE_TO_DETAILED_OBLIGATION =
  "profile/NAVIGATE_TO_DETAILED_OBLIGATION";
export const navigateToDetailedObligation = slug => ({
  type: NAVIGATE_TO_DETAILED_OBLIGATION,
  payload: {
    slug
  }
});

export const DELETE_OBLIGATION_ASSOCIATION =
  "profile/DELETE_OBLIGATION_ASSOCIATION";
export const deleteObligationAssoc = ({ id, obligationSlug }) => ({
  type: DELETE_OBLIGATION_ASSOCIATION,
  payload: { id, obligationSlug }
});

export const DELETE_OBLIGATION_ASSOCIATION_SUCCESS =
  "profile/DELETE_OBLIGATION_ASSOCIATION_SUCCESS";
export const deleteObligationAssocSuccess = ({ id, configs }) => ({
  type: DELETE_OBLIGATION_ASSOCIATION_SUCCESS,
  payload: { id, configs }
});

export const DELETE_OBLIGATION_ASSOCIATION_FAILURE =
  "profile/DELETE_OBLIGATION_ASSOCIATION_FAILURE";
export const deleteObligationAssocFailure = () => ({
  type: DELETE_OBLIGATION_ASSOCIATION_FAILURE
});

export const REFRESH_OBLIGATIONS = "profile/REFRESH_OBLIGATIONS";
export const refreshObligations = () => ({
  type: REFRESH_OBLIGATIONS
});

const REFRESH_OBLIGATIONS_SUCCESS = "profile/REFRESH_OBLIGATIONS_SUCCESS";
export const refreshObligationsSuccess = obligations => ({
  type: REFRESH_OBLIGATIONS_SUCCESS,
  payload: {
    obligations
  }
});

const REFRESH_OBLIGATIONS_FAILURE = "profile/REFRESH_OBLIGATIONS_FAILURE";
export const refreshObligationsFailure = error => ({
  type: REFRESH_OBLIGATIONS_FAILURE,
  payload: {
    error
  }
});

export const FETCH_OBLIGATIONS = "profile/FETCH_OBLIGATIONS";
export const fetchObligations = () => ({
  type: FETCH_OBLIGATIONS
});

export const FETCH_OBLIGATIONS_SUCCESS = "profile/FETCH_OBLIGATIONS_SUCCESS";
export const fetchObligationsSuccess = obligations => ({
  type: FETCH_OBLIGATIONS_SUCCESS,
  payload: {
    obligations
  }
});

export const FETCH_OBLIGATIONS_FAILURE = "profile/FETCH_OBLIGATIONS_FAILURE";
export const fetchObligationsFailure = error => ({
  type: FETCH_OBLIGATIONS_FAILURE,
  payload: { error }
});

export const ASSOCIATE_OBLIGATION = "profile/ASSOCIATE_OBLIGATION";
export const associateObligation = () => ({
  type: ASSOCIATE_OBLIGATION
});

export const ASSOCIATE_OBLIGATION_SUCCESS =
  "profile/ASSOCIATE_OBLIGATION_SUCCESS";
export const associateObligationSuccess = obligations => ({
  type: ASSOCIATE_OBLIGATION_SUCCESS,
  payload: {
    obligations
  }
});

export const ASSOCIATE_OBLIGATION_FAILURE =
  "profile/ASSOCIATE_OBLIGATION_FAILURE";
export const associateObligationFailure = error => ({
  type: ASSOCIATE_OBLIGATION_FAILURE,
  payload: { error }
});

export const FETCH_TRANSACTION_HISTORY = "profile/FETCH_TRANSACTION_HISTORY";
export const fetchTransactionHistory = ({ statuses, page, perPage }) => ({
  type: FETCH_TRANSACTION_HISTORY,
  payload: {
    statuses,
    page,
    perPage
  }
});

export const REFRESH_TRANSACTION_HISTORY =
  "profile/REFRESH_TRANSACTION_HISTORY";
export const refreshTransactionHistory = () => ({
  type: REFRESH_TRANSACTION_HISTORY
});

export const FETCH_TRANSACTION_HISTORY_SUCCESS =
  "profile/FETCH_TRANSACTION_HISTORY_SUCCESS";
export const fetchTransactionHistorySuccess = transactionHistory => ({
  type: FETCH_TRANSACTION_HISTORY_SUCCESS,
  payload: {
    transactionHistory
  }
});

export const FETCH_TRANSACTION_HISTORY_FAILURE =
  "profile/FETCH_TRANSACTION_HISTORY_FAILURE";
export const fetchTransactionHistoryFailure = ({ error, page }) => ({
  type: FETCH_TRANSACTION_HISTORY_FAILURE,
  payload: {
    error,
    page
  }
});

export const FETCH_TRANSACTION = "profile/FETCH_TRANSACTION";
export const fetchTransaction = ({ paymentId }) => ({
  type: FETCH_TRANSACTION,
  payload: {
    paymentId
  }
});

export const FETCH_TRANSACTION_SUCCESS = "profile/FETCH_TRANSACTION_SUCCESS";
export const fetchTransactionSuccess = payment => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: {
    payment
  }
});

export const FETCH_TRANSACTION_FAILURE = "profile/FETCH_TRANSACTION_FAILURE";
export const fetchTransactionFailure = ({ error, paymentId }) => ({
  type: FETCH_TRANSACTION_FAILURE,
  payload: {
    error,
    paymentId
  }
});

const TIMEOUT = "profile/TIMEOUT";
export const timeout = error => ({ type: TIMEOUT, payload: { error } });

const HANDLE_PAYMENT_EXPIRATION = "profile/HANDLE_PAYMENT_EXPIRATION";
export const handlePaymentExpiration = () => ({
  type: HANDLE_PAYMENT_EXPIRATION
});

export const CHECK_PAYMENT_EXPIRATION = "profile/CHECK_PAYMENT_EXPIRATION";
export const checkPaymentExpiration = () => ({
  type: CHECK_PAYMENT_EXPIRATION
});

export const FILTER_FINDABLE_ACCOUNTS = "profile/FILTER_FINDABLE_ACCOUNTS";
export const filterFindableAccounts = canAddObligation => ({
  type: FILTER_FINDABLE_ACCOUNTS,
  payload: { canAddObligation }
});

export const ADDRESS_SETTING = "ADDRESS_SETTING";
export const PHONE_SETTING = "PHONE_SETTING";
export const EMAIL_SETTING = "EMAIL_SETTING";
export const PASSWORD_SETTING = "password";
export const NAME_SETTING = "name";
export const ADDRESS_RESOURCE = "addresses";
export const ACCOUNT_RESOURCE = "accounts";
export const FINDABLE_ACCOUNTS_RESOURCE = "findableAccounts";
export const FILTERED_FINDABLE_ACCOUNTS_RESOURCE = "filteredFindableAccounts";
export const setAddresses = setResources(ADDRESS_RESOURCE);
export const upsertAddress = upsertResource(ADDRESS_RESOURCE);
export const setAccounts = setResources(ACCOUNT_RESOURCE);

export const PHONE_RESOURCE = "phoneNumbers";
export const setPhones = setResources(PHONE_RESOURCE);
export const upsertPhone = upsertResource(PHONE_RESOURCE);

export const CREDIT_CARD_RESOURCE = "creditCards";
export const setCreditCards = setResources(CREDIT_CARD_RESOURCE);
export const upsertCreditCard = upsertResource(CREDIT_CARD_RESOURCE);

export const ACH_RESOURCE = "achAccounts";
export const setAch = setResources(ACH_RESOURCE);
export const upsertAch = upsertResource(ACH_RESOURCE);

export const EMAIL_RESOURCE = "emails";
export const setEmails = setResources(EMAIL_RESOURCE);
export const upsertEmail = upsertResource(EMAIL_RESOURCE);

export const CREDIT_CARD_KIND = "CREDIT_CARD";
export const BANK_ACCOUNT_KIND = "BANK_ACCOUNT";

export const OBLIGATION_RESOURCE = "obligations";
export const setObligations = setResources(OBLIGATION_RESOURCE);

export const PAYMENT_SCHEDULES_RESOURCE = "schedules";

export const SELECTED_AUTO_PAYMENT_METHOD_RESOURCE =
  "selectedAutopaymentMethodId";
export const SELECTED_PAYMENT_PLAN_METHOD_RESOURCE =
  "selectedPaymentPlanMethodId";
export const SELECTED_OBLIGATION_RESOURCE = "selectedObligationId";
export const TERMS_CONDITIONS_AGREED_RESOURCE = "termsAndConditionsAgreedTo";

export const TRANSACTION_HISTORY_RESOURCE = "transactionHistory";
export const TRANSACTION_RESOURCE = "transactions";

export const ACCOUNT_OBLIGATION_TYPE = "ACCOUNT";
export const PROPERTY_OBLIGATION_TYPE = "PROPERTY";

const initialState = {
  settings: {
    resources: S.RemoteData.NotAsked,
    requests: {},
    automaticPaymentData: {},
    paymentPlanData: {},
    selectedPaymentForm: "",
    detailedObligation: null
  }
};

const mapResponseToState = response => {
  return {
    constituentID: response.person.constituent.id,
    firstName: response.person.firstName,
    lastName: response.person.lastName,
    email: response.authentication.email,
    [ACH_RESOURCE]: normalizeById(
      response.paymentInstruments.filter(x => x.kind === BANK_ACCOUNT_KIND)
    ),
    [ADDRESS_RESOURCE]: normalizeById(response.person.constituent.addresses),
    [CREDIT_CARD_RESOURCE]: normalizeById(
      response.paymentInstruments.filter(x => x.kind === CREDIT_CARD_KIND)
    ),
    [EMAIL_RESOURCE]: normalizeById(
      response.person.constituent.contacts.filter(x => x.kind === "email")
    ),
    [PHONE_RESOURCE]: normalizeById(
      response.person.constituent.contacts.filter(x => x.kind === "phone")
    ),
    [ACCOUNT_RESOURCE]: normalizeById(response.accounts),
    [OBLIGATION_RESOURCE]: {},
    [PAYMENT_SCHEDULES_RESOURCE]: response.schedules,
    [FINDABLE_ACCOUNTS_RESOURCE]: response[FINDABLE_ACCOUNTS_RESOURCE] ?? [],
    [FILTERED_FINDABLE_ACCOUNTS_RESOURCE]:
      response[FILTERED_FINDABLE_ACCOUNTS_RESOURCE] ?? []
  };
};

const mapRemoteDataPrototype = response => {
  const remoteDataPrototype = getPrototypeOf(response);
  const payload = attachPrototypeToPayload({ ...response.value });

  return Object.create(remoteDataPrototype, { ...payload });
};

export const _reducer = (state = initialState, { type, payload } = {}) => {
  const _dataSelector = payload?.isPaymentPlan
    ? _paymentPlanData
    : _automaticPaymentData;
  const _planSelector = payload?.isPaymentPlan
    ? _paymentPlan
    : _automaticPayment;
  switch (type) {
    case UPDATE_PERSON:
      return assign(_resourcesSuccess, payload, state);
    case UPSERT_RESOURCE:
      return set(
        compose(
          _resourceSuccess(payload.resourceType),
          prop(payload.resource.id)
        ),
        payload.resource,
        state
      );
    case SET_RESOURCES:
      return set(
        _resourceSuccess(payload.resourceType),
        payload.resources,
        state
      );
    case FETCH_RESOURCES:
      return set(_resources, S.RemoteData.Loading, state);
    case FETCH_RESOURCES_FAILURE:
      return set(_resources, S.RemoteData.Failure(payload.error), state);
    case FETCH_RESOURCES_SUCCESS:
      return set(
        _resources,
        S.RemoteData.Success(mapResponseToState(payload.data)),
        state
      );
    case START_CUSTOMER_MANAGEMENT:
      return set(_customerManagementProfile, payload, state);
    case SUBMIT_CHANGE:
      return set(
        _changeRequest(payload.settingName),
        S.RemoteData.Loading,
        state
      );
    case REQUEST_SUCCESS:
      return set(
        _changeRequest(payload.settingName),
        S.RemoteData.Success(payload.value),
        state
      );
    case REQUEST_FAILURE:
      return set(
        _changeRequest(payload.settingName),
        S.RemoteData.Failure(payload.error),
        state
      );
    case SET_PAYMENT_FORM_TYPE:
      return set(_selectedPaymentForm, payload, state);
    case AUTO_PAYMENT_SELECTED_PAYMENT_METHOD:
      return set(
        _automaticPaymentData(SELECTED_AUTO_PAYMENT_METHOD_RESOURCE),
        payload.paymentMethod,
        state
      );
    case PAYMENT_PLAN_SELECTED_PAYMENT_METHOD:
      return set(
        _paymentPlanData(SELECTED_PAYMENT_PLAN_METHOD_RESOURCE),
        payload.paymentMethod,
        state
      );
    case SELECTED_OBLIGATION:
      return set(
        _dataSelector(SELECTED_OBLIGATION_RESOURCE),
        payload.obligation.id,
        state
      );
    case TOGGLE_TERMS_AND_CONDITIONS:
      return set(
        _dataSelector(TERMS_CONDITIONS_AGREED_RESOURCE),
        !state.settings.automaticPaymentData.termsAndConditionsAgreedTo,
        state
      );
    case CREATE_PAYMENT_SCHEDULE_SUCCESS:
      const { achAccounts, creditCards } = state.settings.resources.value;
      // If we re-enable separation between autopay and payment plans
      // Need to change the next line
      // So it looks at either autopay or payment plan, as needed
      const existingAutoPayData = state.settings.automaticPaymentData;
      const { schedules: autoPaySchedules } = payload;
      const autoPaymentDetails = autoPaySchedules.reduce(
        (data, schedule) => {
          const {
            id,
            nextOccurrenceOn,
            obligationAssociationId: obligationAssocId,
            data: { paymentInstrumentId }
          } = schedule;
          const paymentInstrument = Object.values({
            ...achAccounts,
            ...creditCards
          }).find(instrument => instrument.id === paymentInstrumentId);
          return {
            ...data,
            [obligationAssocId]: {
              paymentInstrument,
              id,
              nextOccurrenceOn,
              obligationAssociationId: obligationAssocId
            }
          };
        },
        { ...existingAutoPayData }
      );
      return set(_planSelector, autoPaymentDetails, state);

    case DEACTIVATE_PAYMENT_SCHEDULE_SUCCESS:
      const assocId = payload.paymentDetails.obligationAssociationId;
      const newAutoPayData = omit(
        [assocId],
        state.settings.automaticPaymentData
      );
      return set(_planSelector, newAutoPayData, state);

    case DEACTIVATE_PAYMENT_INSTRUMENT_SUCCESS:
      const { paymentInstrumentDetails } = payload;
      const {
        achAccounts: ach,
        creditCards: cards
      } = state.settings.resources.value;
      if (paymentInstrumentDetails.kind === CREDIT_CARD_KIND) {
        const newCreditCards = Object.values(cards).filter(
          creditCard => creditCard.id !== paymentInstrumentDetails.id
        );
        return set(
          ["settings", "resources", CREDIT_CARD_RESOURCE],
          newCreditCards,
          state
        );
      }
      const newAch = Object.values(ach).filter(
        ach => ach.id !== paymentInstrumentDetails.id
      );
      return set(["settings", "resources", ACH_RESOURCE], newAch, state);

    case CLEAR_AUTO_PAY_INFO:
      const { automaticPaymentData } = state.settings;
      const clearAutopaymentInfo = {
        ...automaticPaymentData,
        selectedAutopaymentMethodId: "",
        termsAndConditionsAgreedTo: false
      };
      return set(_automaticPayment, clearAutopaymentInfo, state);

    case CLEAR_PAYMENT_PLAN_INFO:
      const { paymentPlanData } = state.settings;
      const clearPaymentPlanInfo = {
        ...paymentPlanData,
        selectedPaymentPlanMethodId: "",
        termsAndConditionsAgreedTo: false
      };
      return set(_paymentPlan, clearPaymentPlanInfo, state);

    case SET_DETAILED_OBLIGATION:
      return set(_detailedObligation, payload, state);

    case SET_DETAILED_OBLIGATION_NAME:
      return assign(_detailedObligation, payload, state);

    case FETCH_ACCOUNT_BILL_URL:
      return assign(_detailedObligation, { bill: S.RemoteData.Loading }, state);

    case FETCH_ACCOUNT_BILL_URL_SUCCESS:
      return assign(_detailedObligation, payload, state);

    case FETCH_ACCOUNT_BILL_URL_FAILURE:
      return assign(
        _detailedObligation,
        { bill: S.RemoteData.Failure({ error: payload.error }) },
        state
      );

    case DELETE_OBLIGATION_ASSOCIATION_SUCCESS:
      const { obligations: obligationList } = state.settings.resources.value;
      const { id: obligationAssociationId, configs: currentConfigs } = payload;
      const rawAssociations = obligationList?.rawOblAssocs ?? [];
      const updatedAssociations = rawAssociations.filter(
        assoc => assoc.id !== obligationAssociationId
      );
      return set(
        _resourceSuccess(OBLIGATION_RESOURCE),
        mapRemoteDataPrototype(
          S.RemoteData.Success(
            cleanAndFormatObligations(updatedAssociations, currentConfigs)
          )
        ),
        state
      );

    case FETCH_OBLIGATIONS:
      return set(
        _resourceSuccess(OBLIGATION_RESOURCE),
        S.RemoteData.Loading,
        state
      );

    case ASSOCIATE_OBLIGATION_SUCCESS:
      const {
        response: assocResponse,
        configs: assocConfigs
      } = payload.obligations;
      const {
        createConstituentObligationAssociation: associations = []
      } = assocResponse;
      const existingAssociations =
        state?.settings?.resources?.value?.obligations?.rawOblAssocs?.filter(
          assoc => !associations.some(obl => obl.account === assoc.account)
        ) ?? [];
      const newFormattedAssoc = cleanAndFormatObligations(
        [...existingAssociations, ...associations],
        assocConfigs
      );
      return set(
        _resourceSuccess(OBLIGATION_RESOURCE),
        mapRemoteDataPrototype(S.RemoteData.Success(newFormattedAssoc)),
        state
      );

    case REFRESH_OBLIGATIONS_SUCCESS:
    case FETCH_OBLIGATIONS_SUCCESS:
      const { response, configs } = payload.obligations;
      const {
        getConstituentObligationAssociations: obligationAssocs = []
      } = response;
      const formattedObls = cleanAndFormatObligations(
        obligationAssocs,
        configs
      );
      return set(
        _resourceSuccess(OBLIGATION_RESOURCE),
        mapRemoteDataPrototype(S.RemoteData.Success(formattedObls)),
        state
      );

    case FETCH_OBLIGATIONS_FAILURE:
      return set(
        _resourceSuccess(OBLIGATION_RESOURCE),
        mapRemoteDataPrototype(S.RemoteData.Failure({ error: "FAILURE" })),
        state
      );

    case TIMEOUT:
      return set(
        _resourceSuccess(OBLIGATION_RESOURCE),
        mapRemoteDataPrototype(S.RemoteData.Failure({ error: "TIMEOUT" })),
        state
      );
    case ADD_SCHEDULE:
      const { schedules } = state.settings.resources.value;
      const newSchedules = [...schedules, payload.schedule];
      return set(
        _resourceSuccess(PAYMENT_SCHEDULES_RESOURCE),
        newSchedules,
        state
      );
    case REMOVE_SCHEDULE:
      if (isEmpty(payload.schedule) || isNil(payload.schedule)) {
        return state;
      }
      const { schedules: savedSchedules } = state.settings.resources.value;
      const { id: scheduleId } = payload.schedule;
      const updatedSchedules = savedSchedules.filter(
        schedule => schedule.id !== scheduleId
      );
      return set(
        _resourceSuccess(PAYMENT_SCHEDULES_RESOURCE),
        updatedSchedules,
        state
      );
    case HANDLE_PAYMENT_EXPIRATION:
      const { creditCards: savedCards } = state.settings.resources.value;
      const newCardData = Object.values(savedCards).map(method => ({
        ...method,
        expirationStatus: getCardExpirationStatus(method)
      }));
      return set(
        _resourceSuccess(CREDIT_CARD_RESOURCE),
        normalizeById(newCardData),
        state
      );
    case FILTER_FINDABLE_ACCOUNTS:
      /*
      For some clients, a user can only add a single obligation from a given account. These are the clients where canAddObligation = false.

      For these clients, the Findable Accounts are filtered according to the obligations active for the user.

      Thus, the Find tab will appear in the navigation only if there are some Findable Accounts that the user has no active obligations for.
      */
      const findableAccounts =
        state?.settings?.resources?.value?.findableAccounts ?? [];
      const obligations = state?.settings?.resources?.value?.obligations ?? {};
      const { canAddObligation } = payload;
      const filteredFindableAccounts = findableAccounts.filter(
        findableAccount => {
          if (canAddObligation) return true;

          const cleanedSubClient = formatName(
            cleanSubClientName(
              findableAccount.client,
              findableAccount.sub_client
            )
          );
          const activeAccountsForSubClient =
            obligations?.accounts?.active[cleanedSubClient] ?? [];

          return activeAccountsForSubClient.length === 0;
        }
      );
      return set(
        _resourceSuccess(FILTERED_FINDABLE_ACCOUNTS_RESOURCE),
        filteredFindableAccounts,
        state
      );

    case FETCH_TRANSACTION_HISTORY:
      return set(
        _resourcePageSuccess(TRANSACTION_HISTORY_RESOURCE, payload.page),
        S.RemoteData.Loading,
        state
      );

    case FETCH_TRANSACTION_HISTORY_SUCCESS:
      const {
        response: transactionHistoryResponse,
        page
      } = payload.transactionHistory;
      const {
        getConstituentPayments: transactionHistory = {
          transactions: [],
          total: 0
        }
      } = transactionHistoryResponse;
      return set(
        _resourcePageSuccess(TRANSACTION_HISTORY_RESOURCE, page),
        mapRemoteDataPrototype(S.RemoteData.Success(transactionHistory)),
        state
      );

    case REFRESH_TRANSACTION_HISTORY:
      return set(
        _resourceSuccess(TRANSACTION_HISTORY_RESOURCE),
        mapRemoteDataPrototype(S.RemoteData.Success({ pages: {} })),
        state
      );

    case FETCH_TRANSACTION_HISTORY_FAILURE:
      return set(
        _resourcePageSuccess(TRANSACTION_HISTORY_RESOURCE, payload.page),
        mapRemoteDataPrototype(S.RemoteData.Failure({ error: "FAILURE" })),
        state
      );

    case FETCH_TRANSACTION:
      return set(
        _resourceIdSuccess(TRANSACTION_RESOURCE, payload.paymentId),
        S.RemoteData.Loading,
        state
      );

    case FETCH_TRANSACTION_SUCCESS:
      const { response: transactionResponse, paymentId } = payload.payment;
      const { getConstituentPayment: transaction = {} } = transactionResponse;
      return set(
        _resourceIdSuccess(TRANSACTION_RESOURCE, paymentId),
        mapRemoteDataPrototype(S.RemoteData.Success(transaction)),
        state
      );

    case FETCH_TRANSACTION_FAILURE:
      return set(
        _resourceIdSuccess(TRANSACTION_RESOURCE, payload.paymentId),
        mapRemoteDataPrototype(S.RemoteData.Failure({ error: "FAILURE" })),
        state
      );

    default:
      return state;
  }
};

const _mapStateToProps = (key, selectors) => state => ({
  ...state[key],
  settings: {
    ...state[key].settings,
    detailedObligation:
      state?.[key]?.settings?.detailedObligation ??
      state?.global?.localStorage?.detailedObligation
  },
  profileRoutes: selectors.getRoutes(state),
  paymentConfigs: selectors.getPaymentConfigs(state),
  deniedCardsForClient: getDeniedCardsForClient(state)
});
const _mapDispatchToProps = dispatch => ({
  resourcesActions: {
    fetchResources: () => dispatch(fetchResources()),
    startCustomerManagement: profileId =>
      dispatch(startCustomerManagement(profileId)),
    // Address Actions
    onAddAddress: profileId => () =>
      dispatch(
        push(configureEndpoint(profileId, "/profile/wallet/add-address"))
      ),
    onEditAddress: profileId => id =>
      dispatch(
        push(configureEndpoint(profileId, `/profile/wallet/edit-address/${id}`))
      ),
    // Email Actions
    onAddEmail: profileId => () =>
      dispatch(push(configureEndpoint(profileId, "/profile/wallet/add-email"))),
    onEditEmail: profileId => id =>
      dispatch(
        push(configureEndpoint(profileId, `/profile/wallet/edit-email/${id}`))
      ),
    // Phone Actions
    onAddPhone: profileId => () =>
      dispatch(push(configureEndpoint(profileId, "/profile/wallet/add-phone"))),
    onEditPhone: profileId => id =>
      dispatch(
        push(configureEndpoint(profileId, `/profile/wallet/edit-phone/${id}`))
      ),
    // Payment Actions
    handlePaymentExpiration: paymentInstruments =>
      dispatch(handlePaymentExpiration(paymentInstruments)),
    onSubmitChange: (settingName, operation, id, { inWallet } = false) =>
      dispatch(submitChange(settingName, operation, id, { inWallet })),
    onAddPayment: profileId => () =>
      dispatch(
        push(configureEndpoint(profileId, "/profile/wallet/add-payment"))
      ),
    checkPaymentExpiration: () => dispatch(checkPaymentExpiration()),
    setPaymentFormType: formType => dispatch(setPaymentFormType(formType)),
    createPaymentFromProfile: (obligations, config) =>
      dispatch(createPaymentFromProfile(obligations, config)),
    // Auto Pay Payment Actions
    setAutoPaymentSelectedPaymentMethod: paymentMethod =>
      dispatch(setAutoPaymentSelectedPaymentMethod(paymentMethod)),
    setPaymentPlanSelectedPaymentMethod: paymentMethod =>
      dispatch(setPaymentPlanSelectedPaymentMethod(paymentMethod)),
    setSelectedObligation: (obligation, isPaymentPlan) =>
      dispatch(setSelectedObligation(obligation, isPaymentPlan)),
    createPaymentSchedule: ({ obligationSlug }) =>
      dispatch(createPaymentSchedule({ obligationSlug })),
    deactivatePaymentSchedule: schedule =>
      dispatch(deactivatePaymentSchedule(schedule)),
    toggleTermsAndConditions: isPaymentPlan =>
      dispatch(toggleTermsAndConditions(isPaymentPlan)),
    clearAutoPayInfo: () => dispatch(clearAutoPayInfo()),
    clearPaymentPlanInfo: () => dispatch(clearPaymentPlanInfo()),
    deactivatePaymentInstrument: paymentInstrument =>
      dispatch(deactivatePaymentInstrument(paymentInstrument)),
    addSchedule: schedule => dispatch(addSchedule(schedule)),
    removeSchedule: schedule => dispatch(removeSchedule(schedule)),
    // Account/Property Details Actions
    setDetailedObligation: (obligations, config, assocID) =>
      dispatch(setDetailedObligation(obligations, config, assocID)),
    navigateToDetailedObligation: slug =>
      dispatch(navigateToDetailedObligation(slug)),
    deleteObligationAssoc: ({ id, obligationSlug }) =>
      dispatch(deleteObligationAssoc({ id, obligationSlug })),
    deleteObligationAssocSuccess: id =>
      dispatch(deleteObligationAssocSuccess(id)),
    deleteObligationAssocFailure: () =>
      dispatch(deleteObligationAssocFailure()),
    refreshObligations: () => dispatch(refreshObligations()),
    fetchObligations: () => dispatch(fetchObligations()),
    fetchTransactionHistory: ({ statuses, page, perPage }) =>
      dispatch(fetchTransactionHistory({ statuses, page, perPage })),
    refreshTransactionHistory: () => dispatch(refreshTransactionHistory()),
    fetchTransaction: ({ paymentId }) =>
      dispatch(fetchTransaction({ paymentId })),
    fetchAccountBillURL: payload => dispatch(fetchAccountBillURL(payload))
  }
});

const CHANGE_PASSWORD_FORM_ACTION = "CHANGE_PASSWORD_FORM_ACTION";
const EDIT_NAME_FORM_ACTION = "EDIT_NAME_FORM_ACTION";
const CHANGE_PASSWORD_ALERT_BAR_ACTION = "CHANGE_PASSWORD_ALERT_BAR_ACTION";
const EDIT_NAME_ALERT_BAR_ACTION = "EDIT_NAME_ALERT_BAR_ACTION";
const ADD_ACH_FORM_ACTION = "ADD_ACH_FORM_ACTION";
const ADD_CARD_FORM_ACTION = "ADD_CARD_FORM_ACTION";
const ADD_ADDRESS_FORM_ACTION = "ADD_ADDRESS_FORM_ACTION";
const ADD_ADDRESS_ALERT_BAR_ACTION = "ADD_ADDRESS_ALERT_BAR_ACTION";
const ADD_CREDIT_CARD_ALERT_BAR_ACTION = "ADD_CARD_ALERT_BAR_ACTION";
const ADD_ACH_ALERT_BAR_ACTION = "ADD_ACH_ALERT_BAR_ACTION";
const ADD_EMAIL_FORM_ACTION = "ADD_EMAIL_FORM_ACTION";
const ADD_EMAIL_ALERT_BAR_ACTION = "ADD_EMAIL_ALERT_BAR_ACTION";
const PHONE_FORM_ACTION = "ADD_PHONE_FORM_ACTION";
const PHONE_ALERT_BAR_ACTION = "ADD_PHONE_ALERT_BAR_ACTION";
const ADD_ACCOUNT_ALERT_BAR_ACTION = "ADD_ACCOUNT_ALERT_BAR_ACTION";
const ASSOCIATE_OBLIGATION_ACTION = "ASSOCIATE_OBLIGATION_ACTION";
const ACCOUNTS_ALERT_BAR_ACTION = "ACCOUNTS_ALERT_BAR_ACTION";
const PROPERTIES_ALERT_BAR_ACTION = "PROPERTIES_ALERT_BAR_ACTION";
const TRANSACTION_HISTORY_ALERT_BAR_ACTION =
  "TRANSACTION_HISTORY_ALERT_BAR_ACTION";
const SET_UP_AUTOPAY_ALERT_BAR_ACTION = "SET_UP_AUTOPAY_ALERT_BAR_ACTION";
const PAYMENT_PLAN_ALERT_BAR_ACTION = "PAYMENT_PLAN_ALERT_BAR_ACTION";
const ACCOUNT_DETAILS_ALERT_BAR_ACTION = "ACCOUNT_DETAILS_ALERT_BAR_ACTION";
const PROFILE_SETTINGS_ALERT_BAR_ACTION = "PROFILE_SETTINGS_ALERT_BAR_ACTION";
const PROFILE_WALLET_SETTINGS_ALERT_BAR_ACTION =
  "PROFILE_WALLET_SETTINGS_ALERT_BAR_ACTION";

export const addAddressAlertBarAction = nestedAction(
  ADD_ADDRESS_ALERT_BAR_ACTION
);
export const addEmailAlertBarAction = nestedAction(ADD_EMAIL_ALERT_BAR_ACTION);
export const addPhoneAlertBarAction = nestedAction(PHONE_ALERT_BAR_ACTION);
export const changePasswordFormAction = nestedAction(
  CHANGE_PASSWORD_FORM_ACTION
);
export const changePasswordAlertBarAction = nestedAction(
  CHANGE_PASSWORD_ALERT_BAR_ACTION
);
export const addCardFormAction = nestedAction(ADD_CARD_FORM_ACTION);
export const addCreditCardAlertBarAction = nestedAction(
  ADD_CREDIT_CARD_ALERT_BAR_ACTION
);
export const addAchAlertBarAction = nestedAction(ADD_ACH_ALERT_BAR_ACTION);
export const addAccountAlertBarAction = nestedAction(
  ADD_ACCOUNT_ALERT_BAR_ACTION
);
export const associateObligationAction = nestedAction(
  ASSOCIATE_OBLIGATION_ACTION
);
export const accountsAlertBarAction = nestedAction(ACCOUNTS_ALERT_BAR_ACTION);
export const propertiesAlertBarAction = nestedAction(
  PROPERTIES_ALERT_BAR_ACTION
);
export const transactionHistoryAlertBarAction = nestedAction(
  TRANSACTION_HISTORY_ALERT_BAR_ACTION
);
export const setUpAutopayAlertBarAction = nestedAction(
  SET_UP_AUTOPAY_ALERT_BAR_ACTION
);
export const paymentPlanAlertBarAction = nestedAction(
  PAYMENT_PLAN_ALERT_BAR_ACTION
);
export const editNameFormAction = nestedAction(EDIT_NAME_FORM_ACTION);
export const editNameAlertBarAction = nestedAction(EDIT_NAME_ALERT_BAR_ACTION);
export const accountDetailsAlertBarAction = nestedAction(
  ACCOUNT_DETAILS_ALERT_BAR_ACTION
);
export const profileSettingsAlertBarAction = nestedAction(
  PROFILE_SETTINGS_ALERT_BAR_ACTION
);
export const profileWalletSettingsAlertBarAction = nestedAction(
  PROFILE_WALLET_SETTINGS_ALERT_BAR_ACTION
);

const createState = (key, config) => {
  const {
    reducer: nestedReducer,
    mapStateToProps,
    mapDispatchToProps,
    actions
  } = nestStates(
    {
      reducer: _reducer,
      mapStateToProps: _mapStateToProps(key, config),
      mapDispatchToProps: _mapDispatchToProps
    },
    {
      forms: {
        changePasswordForm: {
          ...ChangePasswordForm,
          actionType: CHANGE_PASSWORD_FORM_ACTION
        },
        editNameForm: {
          ...EditNameForm,
          actionType: EDIT_NAME_FORM_ACTION
        },
        addCreditCardForm: {
          ...PaymentFormCard,
          actionType: ADD_CARD_FORM_ACTION
        },
        addAchForm: {
          ...PaymentFormACH,
          actionType: ADD_ACH_FORM_ACTION
        },
        addressForm: {
          ...AddressForm,
          actionType: ADD_ADDRESS_FORM_ACTION
        },
        emailForm: {
          ...EmailForm,
          actionType: ADD_EMAIL_FORM_ACTION
        },
        phoneForm: {
          ...PhoneForm,
          actionType: PHONE_FORM_ACTION
        }
      },
      alertBars: {
        changePasswordAlertBar: {
          ...AlertBarState,
          actionType: CHANGE_PASSWORD_ALERT_BAR_ACTION
        },
        editNameAlertBar: {
          ...AlertBarState,
          actionType: EDIT_NAME_ALERT_BAR_ACTION
        },
        addAchAlertBar: {
          ...AlertBarState,
          actionType: ADD_ACH_ALERT_BAR_ACTION
        },
        addCreditCardAlertBar: {
          ...AlertBarState,
          actionType: ADD_CREDIT_CARD_ALERT_BAR_ACTION
        },
        addressAlertBar: {
          ...AlertBarState,
          actionType: ADD_ADDRESS_ALERT_BAR_ACTION
        },
        emailAlertBar: {
          ...AlertBarState,
          actionType: ADD_EMAIL_ALERT_BAR_ACTION
        },
        phoneAlertBar: {
          ...AlertBarState,
          actionType: PHONE_ALERT_BAR_ACTION
        },
        addAccountAlertBar: {
          ...AlertBarState,
          actionType: ADD_ACCOUNT_ALERT_BAR_ACTION
        },
        accountsAlertBar: {
          ...AlertBarState,
          actionType: ACCOUNTS_ALERT_BAR_ACTION
        },
        propertiesAlertBar: {
          ...AlertBarState,
          actionType: PROPERTIES_ALERT_BAR_ACTION
        },
        transactionHistoryAlertBar: {
          ...AlertBarState,
          actionType: TRANSACTION_HISTORY_ALERT_BAR_ACTION
        },
        setUpAutopayAlertBar: {
          ...AlertBarState,
          actionType: SET_UP_AUTOPAY_ALERT_BAR_ACTION
        },
        paymentPlanAlertBar: {
          ...AlertBarState,
          actionType: PAYMENT_PLAN_ALERT_BAR_ACTION
        },
        accountDetailsAlertBar: {
          ...AlertBarState,
          actionType: ACCOUNT_DETAILS_ALERT_BAR_ACTION
        },
        profileSettingsAlertBar: {
          ...AlertBarState,
          actionType: PROFILE_SETTINGS_ALERT_BAR_ACTION
        },
        profileWalletSettingsAlertBar: {
          ...AlertBarState,
          actionType: PROFILE_WALLET_SETTINGS_ALERT_BAR_ACTION
        }
      }
    },
    key
  );

  const reducer = actionsToClearStateOn => (state, action) =>
    actionsToClearStateOn.includes(action.type)
      ? nestedReducer(undefined, "@@init")
      : nestedReducer(state, action);

  return { reducer, mapStateToProps, mapDispatchToProps, actions };
};

export default createState;
