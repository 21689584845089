const activeTabBackground = "#E3F1F8";
const activeTabHover = "#CEE9F6";
const activeTabAccent = "#15749D";
const subheader = "#FFFFFF";
const subheaderNameColor = "#3B414D";
const subheaderEmailColor = "#6D717E";

export const fallbackValues = {
  activeTabAccent,
  activeTabBackground,
  activeTabHover,
  subheader,
  subheaderNameColor,
  subheaderEmailColor
};
