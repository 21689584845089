import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { withWindowSize } from "@thecb/components";
import { Box, Stack, Cluster, Heading, constants } from "@thecb/components";
import CategoryGrid from "../../content/category-grid";
import {
  FONT_WEIGHT_SEMIBOLD,
  MAX_CONTENT_WIDTH
} from "../../../constants/style_constants";
import { CategoryGridFilterType } from "../../content/category-grid/CategoryGrid";

const RelatedContentSection = ({
  relatedContentCards,
  maxContentWidth = MAX_CONTENT_WIDTH,
  mobileContentPadding,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  const sidebarProps = {
    showSearch: false,
    filters: {
      [CategoryGridFilterType.Action]: { enabled: true, title: "I Want To..." },
      [CategoryGridFilterType.Subject]: { enabled: true, title: "Subjects" },
      [CategoryGridFilterType.Section]: { enabled: true, title: "Agency" },
      [CategoryGridFilterType.Tag]: { enabled: true, title: "Tags" }
    }
  };

  return (
    <Box padding="0" extraStyles={`padding-bottom : 120px;`}>
      <Box padding="0" background={constants.colors.WHITE}>
        <Box padding={isMobile ? `0 ${mobileContentPadding}` : "0"}>
          <Cluster
            justify="center"
            extraStyles={`margin: auto; max-width: ${
              isMobile ? "100%" : MAX_CONTENT_WIDTH
            };`}
          >
            <Box
              minWidth="100%"
              maxWidth="100%"
              padding={isMobile ? "24px 0" : "40px 0"}
            >
              <Stack childGap="20px">
                <Box padding="0">
                  <Stack childGap="1rem">
                    <Box
                      padding="0"
                      minHeight="5px"
                      width={isMobile ? "24px" : "50px"}
                      borderSize={isMobile ? "3px" : "6px"}
                      borderColor={themeValues.lightAccentText}
                      borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
                    />
                    <Heading
                      variant="h3"
                      color={themeValues.darkText}
                      extraStyles={`margin-top: 16px;`}
                      weight={FONT_WEIGHT_SEMIBOLD}
                    >
                      Related Content
                    </Heading>
                  </Stack>
                </Box>
                <Box
                  padding={isMobile ? "0 4px" : "0"}
                  maxWidth={maxContentWidth}
                  extraStyles={"position: relative;"}
                >
                  <CategoryGrid
                    sidebarProps={sidebarProps}
                    paginationProps={{
                      scrollOnPageChange: false,
                      itemsPerPage: 15
                    }}
                    gridCards={relatedContentCards}
                    themeValues={themeValues}
                  />
                </Box>
              </Stack>
            </Box>
          </Cluster>
        </Box>
      </Box>
    </Box>
  );
};

export default withWindowSize(RelatedContentSection);
