import * as R from "ramda";

const ADD_ALERT = "ADD_ALERT";
export const addAlert = alert => ({ type: ADD_ALERT, payload: { alert } });

const DELETE_ALERT = "DELETE_ALERT";
export const deleteAlert = index => ({
  type: DELETE_ALERT,
  payload: { index }
});

const CLEAR_ALERTS = "CLEAR_ALERTS";
export const clearAlerts = () => ({ type: CLEAR_ALERTS });

const REPLACE_ALERTS = "REPLACE_ALERTS";
export const replaceAlerts = alert => ({
  type: REPLACE_ALERTS,
  payload: { alert }
});

const initialState = {
  alerts: []
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ALERT:
      return { ...state, alerts: [...state.alerts, payload.alert] };
    case DELETE_ALERT:
      return {
        ...state,
        alerts: R.remove(payload.index, payload.index + 1, state.alerts)
      };
    case CLEAR_ALERTS:
      return initialState;
    case REPLACE_ALERTS:
      return { ...state, alerts: [payload.alert] };
    default:
      return state;
  }
};

export const mapStateToProps = state => state;
export const mapDispatchToProps = dispatch => ({
  addAlert: alert => dispatch(addAlert(alert)),
  deleteAlert: index => dispatch(deleteAlert(index)),
  clearAlerts: () => dispatch(clearAlerts()),
  replaceAlerts: alert => dispatch(replaceAlerts(alert))
});
