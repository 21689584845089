import React from "react";
import * as R from "ramda";
import { URL_TEST } from "../../../constants/regex_constants";
import { InternalLink, ExternalLink } from "../link";
import { safeChildren } from "../../../util/general";
import ButtonWithAction from "../button-with-action";

const ButtonWithLink = ({
  url = "/",
  disabled = false,
  fileLink = false,
  extraStyles = "",
  linkExtraStyles = "",
  newTab = false,
  dataQa,
  ...otherProps
}) => {
  const ButtonWithLinkWrapper = ({
    children,
    url,
    disabled,
    newTab,
    dataQa,
    extraStyles
  }) => {
    if (disabled) {
      return safeChildren(children, <span />);
    }
    return R.test(URL_TEST, url) || fileLink ? (
      <ExternalLink
        href={url}
        tabIndex="-1"
        newTab={newTab}
        extraStyles={extraStyles}
        dataQa={dataQa}
      >
        {safeChildren(children, <span />)}
      </ExternalLink>
    ) : (
      <InternalLink
        to={url}
        tabIndex="-1"
        dataQa={dataQa}
        extraStyles={extraStyles}
      >
        {safeChildren(children, <span />)}
      </InternalLink>
    );
  };

  return (
    <ButtonWithLinkWrapper
      url={url}
      disabled={disabled}
      newTab={newTab}
      extraStyles={`${linkExtraStyles} text-decoration: none; &:hover {
        text-decoration: none; }`}
      dataQa={dataQa}
    >
      <ButtonWithAction
        {...otherProps}
        disabled={disabled}
        extraStyles={extraStyles}
      />
    </ButtonWithLinkWrapper>
  );
};

export default ButtonWithLink;
