import "babel-polyfill";

import React from "react";
import { render } from "react-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import App from "./App";
import OutdatedBrowser from "./apps/common/pages/outdated-browser";
import { getKubernetesCluster, isLocalDev } from "./util/domain";

if (window.configPromise) {
  (async () => {
    const configResponse = await window.configPromise;
    const configData = await configResponse.json();
    const launchDarklyClientSideID =
      configData?.launchDarkly?.clientSideID ?? "64c9242c67de691386343496";
    const flagOverrides = configData?.launchDarkly?.flagOverrides;

    const host = window.location.host;
    const isLocal = isLocalDev(host);
    const isProd = getKubernetesCluster(host) === "prod";
    const cluster = getKubernetesCluster(host);

    const LDProvider = await asyncWithLDProvider({
      clientSideID: launchDarklyClientSideID,
      context: {
        kind: "multi",
        environment: {
          key: "environment",
          local: isLocal,
          prod: isProd,
          hostname: host
        },
        "kubernetes-environment": {
          key: "kubernetes-environment",
          cluster: cluster
        },
        "flag-overrides": {
          key: "flag-overrides",
          ...flagOverrides
        }
      },
      options: {
        streaming: false,
        privateAttributes: ["email"]
      },
      reactOptions: {
        useCamelCaseFlagKeys: false
      }
    });

    render(
      <LDProvider>
        <App config={configData} />
      </LDProvider>,
      document.getElementById("root")
    );
  })();
} else if (window.outdatedBrowser) {
  ReactDOM.render(<OutdatedBrowser />, document.getElementById("root"));
} else {
  console.error("Config was not fetched, cannot render app.");
}
