import React from "react";

const XCircleIconMedium = ({ color = "#D11053", iconIndex = 0 }) => {
  const id = `XCircleIconMedium-${iconIndex}`;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35355 8.07502C7.15829 8.27028 7.15829 8.58686 7.35355 8.78212L10.5714 12L7.35355 15.2179C7.15829 15.4131 7.15829 15.7297 7.35355 15.925L8.07502 16.6464C8.27028 16.8417 8.58686 16.8417 8.78212 16.6464L12 13.4286L15.2179 16.6464C15.4131 16.8417 15.7297 16.8417 15.925 16.6464L16.6464 15.925C16.8417 15.7297 16.8417 15.4131 16.6464 15.2179L13.4286 12L16.6464 8.78212C16.8417 8.58686 16.8417 8.27028 16.6464 8.07502L15.925 7.35355C15.7297 7.15829 15.4131 7.15829 15.2179 7.35355L12 10.5714L8.78212 7.35355C8.58686 7.15829 8.27028 7.15829 8.07502 7.35355L7.35355 8.07502Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.35355 8.07502C7.15829 8.27028 7.15829 8.58686 7.35355 8.78212L10.5714 12L7.35355 15.2179C7.15829 15.4131 7.15829 15.7297 7.35355 15.925L8.07502 16.6464C8.27028 16.8417 8.58686 16.8417 8.78212 16.6464L12 13.4286L15.2179 16.6464C15.4131 16.8417 15.7297 16.8417 15.925 16.6464L16.6464 15.925C16.8417 15.7297 16.8417 15.4131 16.6464 15.2179L13.4286 12L16.6464 8.78212C16.8417 8.58686 16.8417 8.27028 16.6464 8.07502L15.925 7.35355C15.7297 7.15829 15.4131 7.15829 15.2179 7.35355L12 10.5714L8.78212 7.35355C8.58686 7.15829 8.27028 7.15829 8.07502 7.35355L7.35355 8.07502Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </g>
    </svg>
  );
};

export default XCircleIconMedium;
