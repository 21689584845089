import {
  CHARADE_GREY,
  MATISSE_BLUE,
  WHITE,
  SEASHELL_WHITE,
  MINESHAFT_GREY,
  DUSTY_GREY,
  GREY_CHATEAU,
  ATHENS_GREY
} from "../../../constants/colors";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";

const linkColor = { default: `${MATISSE_BLUE}`, disabled: `${MATISSE_BLUE}` };
const formBackgroundColor = {
  default: `${WHITE}`,
  disabled: `${WHITE}`,
  checkout: `${ATHENS_GREY}`,
  collapsible: `${ATHENS_GREY}`
};

const inputBackgroundColor = {
  default: `${WHITE}`,
  disabled: `${SEASHELL_WHITE}`
};
const color = { default: `${MINESHAFT_GREY}`, disabled: `${DUSTY_GREY}` };
const labelColor = { default: `${CHARADE_GREY}`, disabled: `${CHARADE_GREY}` };
const borderColor = { default: `${GREY_CHATEAU}`, disabled: `${GREY_CHATEAU}` };
const lineHeight = { default: "1rem", disabled: "1rem" };
const fontSize = { default: "0.875rem", disabled: "0.875rem" };
const errorFontSize = { default: "0.75rem", disabled: "0.75rem" };
const fontWeight = {
  default: `${FONT_WEIGHT_REGULAR}`,
  disabled: `${FONT_WEIGHT_REGULAR}`
};
const hoverFocusStyles = {
  default: `color: #0E506D; outline: none; text-decoration: underline;  `,
  disabled: `color: #6D717E;`
};

export const fallbackValues = {
  linkColor,
  formBackgroundColor,
  inputBackgroundColor,
  color,
  labelColor,
  borderColor,
  lineHeight,
  fontSize,
  errorFontSize,
  fontWeight,
  hoverFocusStyles
};
