import styled from "styled-components";
import ButtonWithAction from "../../../atoms/button-with-action/ButtonWithAction";

export const PopupMenuItemContainer = styled(ButtonWithAction)`
  width: 100%;
  margin: 0;
  padding: 17px 11px;
  margin-bottom: 5px;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  ${({ theme, isDeleteAction }) => `
    background-color: ${theme.menuItemBackgroundColor};
    color: ${isDeleteAction ? theme.menuItemColorDelete : theme.menuItemColor};
  `}
  &:hover,
  &:active {
    text-decoration: none;
    ${({ theme, isDeleteAction }) => `
    background-color: ${
      isDeleteAction
        ? theme.menuItemHoverBackgroundColorDelete
        : theme.menuItemHoverBackgroundColor
    };
  `}
  }
`;
