import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import { replaceAlerts } from "../../../../components/alert-bar/AlertBar.state";
import { createForgotPasswordRequest } from "./graphql/Queries";
import { push } from "connected-react-router";
import {
  SUBMIT_FORGOT_PASSWORD_FORM,
  forgotPasswordSuccess,
  forgotPasswordError,
  forgotPasswordAlertBarAction
} from "./ForgotPassword.state";
import { changePanel } from "../../../checkout/pages/payment/Payment.state";

const createForgotPasswordParams = state => ({
  endpoint: state.config.graphqlServiceEndpoint,
  clientSlug: state.config.defaultClientSlug,
  email: state.forgotPassword.forms.forgotPasswordForm.email.rawValue
});

export const forgotPasswordSubmitEpic = (action$, state$) =>
  action$.ofType(SUBMIT_FORGOT_PASSWORD_FORM).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        createForgotPasswordRequest(createForgotPasswordParams(state$.value))
      ).pipe(
        RxOp.flatMap(() =>
          action.payload.inWallet
            ? Rx.of(
                forgotPasswordSuccess(),
                changePanel("forgotPassword", "next"),
                changePanel("confirmForgotPassword", "onScreen")
              )
            : Rx.of(forgotPasswordSuccess(), push("/reset-confirmation"))
        ),
        RxOp.catchError(error =>
          Rx.of(
            forgotPasswordError(error),
            forgotPasswordAlertBarAction(
              replaceAlerts({
                heading: "Reset link failed to send",
                text:
                  "An error occurred while sending you a link to reset your password.",
                variant: "error"
              })
            )
          )
        )
      )
    )
  );

export default forgotPasswordSubmitEpic;
