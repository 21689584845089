import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IoIosCall } from "@react-icons/all-files/io/IoIosCall";
import { IoMdMail } from "@react-icons/all-files/io/IoMdMail";
import { IoMdPrint } from "@react-icons/all-files/io/IoMdPrint";
import { IoLogoFacebook } from "@react-icons/all-files/io/IoLogoFacebook";
import { IoLogoTwitter } from "@react-icons/all-files/io/IoLogoTwitter";
import { IoLogoInstagram } from "@react-icons/all-files/io/IoLogoInstagram";
import {
  Box,
  Detail,
  Stack,
  Switcher,
  Cluster,
  Paragraph,
  Text,
  Title,
  ButtonWithLink,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import HoursOfOperation from "./HoursOfOperation";
import Address from "./Address";
import { v4 as uuidv4 } from "uuid";
import RightArrowIcon from "../../../icons/RightArrowIcon";

const GetInTouchIconHeading = ({ Icon, ariaLabel }) => (
  <Text as="h5" aria-label={ariaLabel} extraStyles="margin: 0;">
    <Icon size="29px" />
  </Text>
);

const LocationBlock = ({
  locationName = "",
  addresses = [],
  city = "",
  state = "",
  zip = "",
  phone = "",
  fax = "",
  email = "",
  hoursOfOperation = [],
  facebookUrl = "",
  twitterUrl = "",
  instagramUrl = "",
  inSidebar,
  blockPadding = "40px 0",
  themeValues
}) => {
  const cityString = `${city}, ${state} ${zip}`;
  const { isMobile } = useContext(ThemeContext);
  const itemPadding = inSidebar ? "0 0 0 8px" : "0 0 0 16px";

  return (
    <Box
      maxWidth="450px"
      minHeight={"auto"}
      padding={blockPadding}
      minWidth="100%"
    >
      <Box padding="0" borderRadius="4px">
        <Stack>
          <Box key="location-heading" padding="0">
            <Stack>
              <Detail
                as="h3"
                extraStyles={`${isMobile ? "text-transform: uppercase;" : ""}`}
              >
                Get In Touch
              </Detail>
              <Cluster
                justify="flex-start"
                align="center"
                extraStyles="margin-top: 16px;"
              >
                <Title
                  as="h4"
                  variant="h2"
                  color={themeValues.lightAccentText}
                  key="heading"
                  extraStyles={`font-size: ${
                    isMobile ? "24px" : "28px"
                  }; line-height: 150%;`}
                  weight={FONT_WEIGHT_SEMIBOLD}
                >
                  {locationName}
                  <RightArrowIcon
                    size={isMobile ? 24 : 28}
                    color={themeValues.lightAccentText}
                    paddingBottom="0"
                  />
                </Title>
              </Cluster>
            </Stack>
          </Box>
          <Switcher key="location-info">
            <Box key="hours-address" padding={inSidebar ? "0" : "16px 0"}>
              <Stack>
                {hoursOfOperation.length > 0 &&
                  hoursOfOperation.map(hours => (
                    <HoursOfOperation
                      themeValues={themeValues}
                      hours={hours}
                      key={uuidv4()}
                    />
                  ))}
                {addresses.map(address => (
                  <Address
                    themeValues={themeValues}
                    address={address}
                    key={uuidv4()}
                  />
                ))}
              </Stack>
            </Box>
            {inSidebar && (
              <Box
                padding="0"
                minHeight="5px"
                borderSize={isMobile ? "3px" : "6px"}
                borderColor="#cfd4de"
                borderWidthOverride={"0 0 1px 0"}
                key="location-sidebar-accent"
              />
            )}
            <Box key="phone-email" padding="16px 0">
              <Stack>
                {phone && (
                  <Box padding="0" key="phone">
                    <Cluster justify="flex-start" align="flex-start">
                      <GetInTouchIconHeading
                        Icon={IoIosCall}
                        ariaLabel="telephone"
                      />
                      <Box padding={itemPadding}>
                        <Paragraph variant="pL">{phone}</Paragraph>
                      </Box>
                    </Cluster>
                  </Box>
                )}
                {fax && (
                  <Box padding="0" key="fax">
                    <Cluster justify="flex-start" align="flex-start">
                      <GetInTouchIconHeading Icon={IoMdPrint} ariaLabel="fax" />
                      <Box padding={itemPadding}>
                        <Paragraph variant="pL">{fax}</Paragraph>
                      </Box>
                    </Cluster>
                  </Box>
                )}
                {email && (
                  <Box padding="0" key="email">
                    <Cluster justify="flex-start" align="flex-start">
                      <GetInTouchIconHeading
                        Icon={IoMdMail}
                        ariaLabel="email"
                      />
                      <Box padding={itemPadding}>
                        <a mailto={email}>
                          <Paragraph
                            variant="pL"
                            color={themeValues.linkColor}
                            extraStyles={`cursor: pointer; text-decoration: underline;`}
                          >
                            {email}
                          </Paragraph>
                        </a>
                      </Box>
                    </Cluster>
                  </Box>
                )}
              </Stack>
            </Box>
            {inSidebar && (
              <Box
                padding="0"
                minHeight="5px"
                borderSize={isMobile ? "3px" : "6px"}
                borderColor="#cfd4de"
                borderWidthOverride={"0 0 1px 0"}
                key="location-accent"
              />
            )}
            <Box key="social" padding="16px 0">
              <Stack childGap="0">
                {facebookUrl && (
                  <Box padding="0" key="fb">
                    <Cluster justify="flex-start" align="center">
                      <GetInTouchIconHeading
                        Icon={IoLogoFacebook}
                        ariaLabel="facebook"
                      />
                      <Box padding="0">
                        <ButtonWithLink
                          contentOverride
                          variant="ghost"
                          url={facebookUrl}
                        >
                          <Paragraph
                            variant="pL"
                            color={themeValues.linkColor}
                            extraStyles="text-decoration: underline;"
                          >
                            Office&apos;s Facebook
                          </Paragraph>
                        </ButtonWithLink>
                      </Box>
                    </Cluster>
                  </Box>
                )}
                {twitterUrl && (
                  <Box padding="0" key="twitter">
                    <Cluster justify="flex-start" align="center">
                      <GetInTouchIconHeading
                        Icon={IoLogoTwitter}
                        ariaLabel="twitter"
                      />
                      <Box padding="0">
                        <ButtonWithLink
                          contentOverride
                          variant="ghost"
                          url={twitterUrl}
                        >
                          <Paragraph
                            variant="pL"
                            color={themeValues.linkColor}
                            extraStyles="text-decoration: underline;"
                          >
                            Office&apos;s Twitter
                          </Paragraph>
                        </ButtonWithLink>
                      </Box>
                    </Cluster>
                  </Box>
                )}
                {instagramUrl && (
                  <Box padding="0" key="instagram">
                    <Cluster justify="flex-start" align="center">
                      <GetInTouchIconHeading
                        Icon={IoLogoInstagram}
                        ariaLabel="instagram"
                      />
                      <Box padding="0">
                        <ButtonWithLink
                          contentOverride
                          variant="ghost"
                          url={instagramUrl}
                        >
                          <Paragraph
                            variant="pL"
                            color={themeValues.linkColor}
                            extraStyles="text-decoration: underline;"
                          >
                            Office&apos;s Instagram
                          </Paragraph>
                        </ButtonWithLink>
                      </Box>
                    </Cluster>
                  </Box>
                )}
              </Stack>
            </Box>
          </Switcher>
        </Stack>
      </Box>
    </Box>
  );
};

export default withWindowSize(LocationBlock);
