import styled from "styled-components";

export const ImposterWrapper = styled.div`
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  display: ${({ visible }) => (visible ? "block" : "none")};

  ${({ centered }) => (centered ? `transform: translate(-50%, -50%);` : ``)}

  ${({ breakout, verticalMargin, horizontalMargin }) =>
    breakout
      ? ``
      : `
    overflow: auto;
    max-width: calc(100% - (${horizontalMargin} * 2));
    max-height: calc(100% - (${verticalMargin} * 2));
  `}
  ${({ extraStyles }) => extraStyles};
`;
