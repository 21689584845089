import CreatePasswordResetRequest from "./CreatePasswordResetRequest.graphql";
import { createQuery } from "../../../../../util/graphql";
export const createPasswordResetRequest = ({
  endpoint,
  clientSlug,
  id,
  key,
  password
}) =>
  createQuery(endpoint, clientSlug)(CreatePasswordResetRequest, {
    id,
    key,
    password
  });
