import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import { get, compose, prop } from "partial.lenses";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  editNameAlertBarAction,
  NAME_SETTING,
  SUBMIT_CHANGE,
  requestFailure,
  requestSuccess,
  updatePerson
} from "../../Profile.state";
import { updateName } from "../../graphql/Queries";
import {
  getEditNameForm,
  getCustomerManagement
} from "../../Profile.selectors";
import { configureEndpoint, isInCustomerManagement } from "/util/router-utils";

const _formFieldRaw = fieldName =>
  compose(getEditNameForm, prop(fieldName), prop("rawValue"));

const getEditNameArgs = (key, selectors, state) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  userID: isInCustomerManagement
    ? state.profile.customerManagement.profileId
    : selectors.getUserID(state),
  firstName: get(_formFieldRaw("firstName"), state[key]),
  lastName: get(_formFieldRaw("lastName"), state[key])
});

export const editNameEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(({ payload: { settingName } }) => settingName === NAME_SETTING),
    RxOp.flatMap(() =>
      Rx.from(
        updateName(getEditNameArgs(profileKey, selectors, state$.value))
      ).pipe(
        /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
        RxOp.flatMap(({ updatePerson: { id: _, ...person } }) =>
          Rx.of(
            editNameAlertBarAction(clearAlerts()),
            updatePerson(person),
            requestSuccess(NAME_SETTING, person),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[profileKey]),
                "/profile/settings"
              )
            )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            editNameAlertBarAction(clearAlerts()),
            editNameAlertBarAction(
              addAlert({
                heading: "Edit Name",
                text:
                  "An error ocurred while trying to update the name on your account.",
                variant: "error"
              })
            ),
            requestFailure(NAME_SETTING, err)
          )
        )
      )
    )
  );

export default editNameEpic;
