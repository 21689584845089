import React from "react";
import { IconNeutral, IconInvalid, IconValid } from "../../../deprecated/icons";
import Paragraph from "../paragraph";
import { Cluster, Stack, Box, Sidebar, Cover } from "../layouts";
import {
  getInputState,
  MIN_LENGTH_ERROR,
  HAS_NUMBER_ERROR,
  HAS_UPPERCASE_LETTER_ERROR,
  HAS_LOWERCASE_LETTER_ERROR,
  HAS_SPECIAL_CHARACTER_ERROR,
  INPUT_STATE_INVALID,
  INPUT_STATE_NEUTRAL,
  INPUT_STATE_VALID
} from "../../../util/inputValidationUtils";
import {
  MINESHAFT_GREY,
  ATHENS_GREY,
  FOREST_GREEN,
  RAZZMATAZZ_RED
} from "../../../constants/colors";

const PasswordRequirements = ({ password, isMobile }) => {
  const iconMap = {
    [INPUT_STATE_NEUTRAL]: {
      icon: <IconNeutral margin="0 0.5rem 0 0" />,
      color: MINESHAFT_GREY
    },
    [INPUT_STATE_INVALID]: {
      icon: <IconInvalid margin="0 0.5rem 0 0" />,
      color: RAZZMATAZZ_RED
    },
    [INPUT_STATE_VALID]: {
      icon: <IconValid margin="0 0.5rem 0 0" />,
      color: FOREST_GREEN
    }
  };

  const validationMap = {
    charactersValidation: {
      validationMessage: "Password must be 8 characters",
      validationRequirement: [MIN_LENGTH_ERROR]
    },
    numbersValidation: {
      validationMessage: "Password must contain at least one number",
      validationRequirement: [HAS_NUMBER_ERROR]
    },
    letterCaseValidation: {
      validationMessage: "Password must contain an upper and lower case letter",
      validationRequirement: [
        HAS_UPPERCASE_LETTER_ERROR,
        HAS_LOWERCASE_LETTER_ERROR
      ]
    },
    specialCharacterValidation: {
      validationMessage:
        "Password must contain a special character (!@#$%^&*.?)",
      validationRequirement: [HAS_SPECIAL_CHARACTER_ERROR]
    }
  };

  return (
    <Box
      background={ATHENS_GREY}
      padding={isMobile ? "1rem" : "1.5rem"}
      width="100%"
    >
      <Cluster justify="center" align="center">
        <Stack>
          {Object.values(validationMap).map(item => (
            <Box padding="0" key={item.validationMessage}>
              <Sidebar width="24px" childGap="0rem">
                <Box padding="0" minHeight="100%">
                  <Cover minHeight="100%" singleChild>
                    <Box padding="0">
                      {
                        iconMap[
                          getInputState(password, item.validationRequirement)
                        ].icon
                      }
                    </Box>
                  </Cover>
                </Box>
                <Box padding="0">
                  <Sidebar onRight childGap="0rem">
                    <Box padding="0.15rem 0 0 0">
                      <Cluster justify="flex-start" align="center">
                        <Stack fullHeight childGap="0.25rem">
                          <Paragraph
                            variant="pS"
                            color={
                              iconMap[
                                getInputState(
                                  password,
                                  item.validationRequirement
                                )
                              ].color
                            }
                          >
                            {item.validationMessage}
                          </Paragraph>
                        </Stack>
                      </Cluster>
                    </Box>
                  </Sidebar>
                </Box>
              </Sidebar>
            </Box>
          ))}
        </Stack>
      </Cluster>
    </Box>
  );
};

export default PasswordRequirements;
