import LoginForm from "./LoginForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./LoginForm.state";

LoginForm.reducer = reducer;
LoginForm.mapStateToProps = mapStateToProps;
LoginForm.mapDispatchToProps = mapDispatchToProps;
export default LoginForm;
