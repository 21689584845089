import { createFormat, format } from "formatted-input";
export const formatDelimiter = "_";

export const expirationDateFormats = ["", "_", "__/", "__/_", "__/__"];

export const creditCardFormats = [
  "",
  "_",
  "__",
  "___",
  "____",
  "____ _",
  "____ __",
  "____ ___",
  "____ ____",
  "____ ____ _",
  "____ ____ __",
  "____ ____ ___",
  "____ ____ ____",
  "____ ____ ____ _",
  "____ ____ ____ __",
  "____ ____ ____ ___",
  "____ ____ ____ ____"
];

export const phoneFormats = [
  "",
  "_",
  "__",
  "(___) ",
  "(___) _",
  "(___) __",
  "(___) ___-",
  "(___) ___-_",
  "(___) ___-__",
  "(___) ___-___",
  "(___) ___-____"
];

export const moneyFormats = [
  "",
  "$0.0_",
  "$0.__",
  "$_.__",
  "$__.__",
  "$___.__",
  "$_,___.__",
  "$__,___.__",
  "$___,___.__",
  "$_,___,___.__",
  "$__,___,___.__",
  "$___,___,___.__",
  "$_,___,___,___.__",
  "$__,___,___,___.__",
  "$___,___,___,___.__",
  "$_,___,___,___,___.__"
];

export const dateFormats = [
  "",
  "_",
  "__/",
  "__/_",
  "__/__/",
  "__/__/_",
  "__/__/__",
  "__/__/___",
  "__/__/____"
];

const zipFormats = [
  "",
  "_",
  "__",
  "___",
  "____",
  "_____",
  "______",
  "_____-__",
  "_____-___",
  "_____-____"
];
export const zipFormat = createFormat(zipFormats, formatDelimiter);
export const _zipFormat = format(zipFormat);
