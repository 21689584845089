import React, { useCallback, useContext, useEffect, useState } from "react";

import { Box, Sidebar, withWindowSize } from "@thecb/components";
import { ThemeContext } from "styled-components";
import GridCardsDirectory from "./grid-cards-directory";
import CategoryGridSidebar from "./category-grid-sidebar";
import NoResultsFound from "../../no-results-found";
import { RelatedContentType } from "../../../util/dataAdapters";

export const CategoryGridType = {
  Action: RelatedContentType.Action,
  Subject: RelatedContentType.Subject,
  Section: RelatedContentType.Section,
  External: RelatedContentType.External
};

export const CategoryGridFilterType = {
  ...CategoryGridType,
  Tag: "tag"
};

const ContentContainer = ({ isMobile, children }) => {
  return isMobile ? (
    <Box padding={0}>{children}</Box>
  ) : (
    <Sidebar fullHeight childGap="1.75rem" extraStyles="position:relative;">
      {children}
    </Sidebar>
  );
};

const CategoryGrid = ({
  gridCards,
  sidebarProps,
  paginationProps,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [actionChoices, setActionChoices] = useState([]);
  const [subjectChoices, setSubjectChoices] = useState([]);
  const [sectionChoices, setSectionChoices] = useState([]);
  const [tagChoices, setTagChoices] = useState([]);
  const [filteredGridCards, setFilteredGridCards] = useState([]);

  useEffect(() => {
    const actions = new Set();
    const tags = new Set();
    const subjects = new Set();
    const sections = new Set();
    setFilteredGridCards(gridCards);
    gridCards.forEach(card => {
      card.tags.forEach(tag => tags.add(tag.tagName));
      switch (card.category) {
        case CategoryGridType.Action:
          actions.add(card.type);
          break;
        case CategoryGridType.Subject:
          subjects.add(card.type);
          break;
        case CategoryGridType.Section:
          sections.add(card.type);
          break;
        default:
          break;
      }
    });
    setActionChoices(Array.from(actions));
    setSubjectChoices(Array.from(subjects));
    setSectionChoices(Array.from(sections));
    setTagChoices(Array.from(tags));
  }, [gridCards]);

  const handleFilterSearchSelection = useCallback(
    (
      actionSelections,
      subjectSelections,
      sectionSelections,
      tagSelections,
      searchTerm
    ) => {
      let updatedCards = [...gridCards];
      if (searchTerm.length) {
        const searchRegex = new RegExp(
          searchTerm.replace(/[#-.]|[[-^]|[?|{}]/g, "\\$&"),
          "i"
        );
        updatedCards = updatedCards.filter(page => {
          const searchValues =
            page.title + page.description + page.metaDescription + page.url;
          return searchValues.search(searchRegex) >= 0;
        });
      }
      if (
        tagSelections.length > 0 ||
        actionSelections.length > 0 ||
        subjectSelections.length > 0 ||
        sectionSelections.length > 0
      ) {
        updatedCards = updatedCards.filter(page => {
          const tagFound = page.tags.some(tag =>
            tagSelections.includes(tag.tagName)
          );
          return (
            tagFound ||
            actionSelections.includes(page.type) ||
            subjectSelections.includes(page.type) ||
            sectionSelections.includes(page.type)
          );
        });
      }
      setFilteredGridCards(updatedCards);
    },
    [gridCards]
  );

  return (
    <ContentContainer isMobile={isMobile}>
      <CategoryGridSidebar
        cardFilters={sidebarProps.filters}
        showSearch={sidebarProps.showSearch}
        actionChoices={actionChoices}
        tagChoices={tagChoices}
        subjectChoices={subjectChoices}
        sectionChoices={sectionChoices}
        onUpdate={handleFilterSearchSelection}
        themeValues={themeValues}
      />

      <Box padding={isMobile ? "0" : "32px 0 0 0"}>
        {gridCards.length > 0 && filteredGridCards.length === 0 ? (
          <NoResultsFound showText={!!gridCards.length} />
        ) : (
          <GridCardsDirectory
            gridCards={filteredGridCards}
            isMobile={isMobile}
            paginationProps={paginationProps}
            themeValues={themeValues}
          />
        )}
      </Box>
    </ContentContainer>
  );
};

export default withWindowSize(CategoryGrid);
