import React from "react";

import {
  Box,
  Stack,
  Heading,
  Paragraph,
  ButtonWithAction,
  constants,
  Cover,
  Cluster,
  ForgotPasswordIcon,
  FormContainer,
  FormInputColumn
} from "@thecb/components";
import FocusTrap from "react-focus-trap";

const ConfirmForgotPasswordPanel = ({
  closeSlider,
  panelIsOpen,
  changePanel,
  isMobile,
  themeValues
}) => {
  return (
    <Box
      padding="0 2rem"
      minHeight="100%"
      width="100%"
      background={constants.colors.ATHENS_GREY}
      extraStyles={`.focus-trap-wrapper:focus, .focus-trap:focus { outline: none; }`}
    >
      <FocusTrap active={panelIsOpen} onExit={closeSlider}>
        <Cover singleChild minHeight="100vh">
          <Stack childGap="1.5rem">
            <Box
              padding="0"
              width={isMobile ? `auto` : `576px`}
              borderRadius="4px"
              background={constants.colors.WHITE}
              boxShadow="0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
            >
              <Box
                background={themeValues.bannerBackgroundColor}
                minWidth="100%"
                padding="0.5rem"
              >
                <Cluster justify="center" align="center">
                  <ForgotPasswordIcon />
                </Cluster>
              </Box>
              <FormContainer
                role="form"
                aria-label="Reset password confirmation"
              >
                <FormInputColumn childGap="0.5rem">
                  <Box padding="0">
                    <Heading
                      color={constants.colors.CHARADE_GREY}
                      variant="h5"
                      weight="600"
                    >
                      Password reset link sent
                    </Heading>
                  </Box>
                  <Box padding="0">
                    <Paragraph
                      variant="p"
                      color={constants.colors.CHARADE_GREY}
                    >
                      Your password link was sent successfully. Check your email
                      for a reset password link.
                    </Paragraph>
                  </Box>
                </FormInputColumn>
                <Box padding={isMobile ? "1rem 0 0" : "1.5rem 0 0"}>
                  <Cluster justify="center" align="center">
                    <ButtonWithAction
                      action={() => {
                        changePanel("confirmForgotPassword", "next");
                        changePanel("login", "onScreen");
                      }}
                      text="Back to Login"
                      variant="primary"
                      extraStyles={`width: 100%;`}
                      linkExtraStyles={`width: 100%;`}
                    />
                  </Cluster>
                </Box>
              </FormContainer>
            </Box>
          </Stack>
        </Cover>
      </FocusTrap>
    </Box>
  );
};

export default ConfirmForgotPasswordPanel;
