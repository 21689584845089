import React from "react";

const PropertyPersonalIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill="#3B5BDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1674 26.0625C36.0736 26.1875 35.9486 26.25 35.7924 26.25C35.6361 26.25 35.4955 26.2031 35.3705 26.1093L24.3549 17.0625C24.2299 16.9687 24.1049 16.9219 23.9799 16.9219C23.8549 16.9219 23.7299 16.9687 23.6049 17.0625L12.5893 26.1093C12.4643 26.2031 12.3237 26.25 12.1674 26.25C12.0112 26.25 11.8862 26.1875 11.7924 26.0625L10.6205 24.6093C10.5268 24.4843 10.4877 24.3437 10.5033 24.1875C10.519 24.0312 10.5737 23.9062 10.6674 23.8125L22.5736 14.0156C22.9799 13.6719 23.4486 13.5 23.9799 13.5C24.5111 13.5 24.9955 13.6719 25.433 14.0156L29.6049 17.4844V14.0625C29.6049 13.9062 29.6596 13.7734 29.7689 13.664C29.8783 13.5547 30.0111 13.5 30.1674 13.5H32.7924C32.9486 13.5 33.0814 13.5547 33.1908 13.664C33.3002 13.7734 33.3549 13.9062 33.3549 14.0625V20.5781L37.2924 23.8125C37.3861 23.9062 37.4408 24.0312 37.4564 24.1875C37.472 24.3437 37.433 24.4843 37.3392 24.6093L36.1674 26.0625ZM21.5658 34.3359C21.4565 34.4453 21.3236 34.4999 21.1674 34.4999H15.7299C15.4174 34.4999 15.1518 34.3906 14.933 34.1718C14.7143 33.9531 14.6049 33.6874 14.6049 33.375V26.6718C14.6049 26.4843 14.6674 26.3281 14.7924 26.2031L23.6049 18.9844C23.7299 18.8906 23.8549 18.8437 23.9799 18.8437C24.1049 18.8437 24.2299 18.8906 24.3549 18.9844L33.1674 26.2031C33.2924 26.3281 33.3549 26.4843 33.3549 26.6718V33.375C33.3549 33.6874 33.2455 33.9531 33.0267 34.1718C32.808 34.3906 32.5424 34.4999 32.2299 34.4999H26.7924C26.6361 34.4999 26.5033 34.4453 26.3939 34.3359C26.2846 34.2265 26.2299 34.0937 26.2299 33.9374V28.6875C26.2299 28.5312 26.1752 28.3984 26.0658 28.289C25.9565 28.1797 25.8236 28.125 25.6674 28.125H22.2924C22.1361 28.125 22.0033 28.1797 21.894 28.289C21.7846 28.3984 21.7299 28.5312 21.7299 28.6875V33.9374C21.7299 34.0937 21.6752 34.2265 21.5658 34.3359Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default PropertyPersonalIcon;
