import * as L from "partial.lenses";
import S from "../Sanctuary";

export const _Success = L.lens(
  remoteData => (remoteData.isSuccess ? remoteData.value : undefined),
  (value, remoteData) =>
    remoteData.isSuccess ? S.RemoteData.Success(value) : remoteData
);

export const _Failure = L.lens(
  remoteData => (remoteData.isFailure ? remoteData.value : undefined),
  (value, remoteData) =>
    remoteData.isFailure ? S.RemoteData.Failure(value) : remoteData
);

export const _NotAsked = L.lens(
  remoteData => (remoteData.isNotAsked ? remoteData : undefined),
  (value, remoteData) => remoteData
);

export const _Loading = L.lens(
  remoteData => (remoteData.isLoading ? remoteData : undefined),
  (value, remoteData) => remoteData
);
