import { ATHENS_GREY, WHITE, MATISSE_BLUE } from "../../../constants/colors";

const background = `${ATHENS_GREY}`;
const white = `${WHITE}`;
const black = "#000";
const focusBorder = `${MATISSE_BLUE}`;

export const themeValues = {
  background,
  white,
  black,
  focusBorder
};
