const DEV_DOMAIN_REGEX = /\.dev\.cityba\.se$/;
const LOCAL_DOMAIN_REGEX = /-nfe:3020$/;
const UAT_DOMAIN_REGEX = /\.uat\.cityba\.se$/;
const DEMOS_DOMAIN_REGEX = /-demos\.uat\.cityba\.se$/;

const NON_PROD_DOMAIN_REGEXS = [DEV_DOMAIN_REGEX, UAT_DOMAIN_REGEX];

export const isLocalDev = hostname => LOCAL_DOMAIN_REGEX.test(hostname);

const isNonProdEnv = hostname =>
  NON_PROD_DOMAIN_REGEXS.some(regex => regex.test(hostname));

export const getKubernetesCluster = hostname => {
  if (!hostname) {
    return null;
  }

  if (isLocalDev(hostname)) {
    return "local";
  }

  return isNonProdEnv(hostname) ? hostname.split(".")?.[1] : "prod";
};

export const isDemosDomain = hostname => DEMOS_DOMAIN_REGEX.test(hostname);

export const showTestSiteAlert = hostname => {
  if (!hostname) {
    return false;
  }

  return getKubernetesCluster(hostname) != "prod" && !isDemosDomain(hostname);
};
