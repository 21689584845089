import React from "react";

const PropertyBusinessIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill="#3B5BDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3134 14.209V16H14.0746V14.209H16.3134ZM16.3134 14.209H18.5522C18.8507 14.209 19.1045 14.3134 19.3134 14.5224C19.5224 14.7313 19.6269 14.9851 19.6269 15.2836V24.0597L25.8507 20.1194C26.2089 19.8806 26.5746 19.8657 26.9477 20.0746C27.3209 20.2836 27.5074 20.597 27.5074 21.0149V24.0597L33.7313 20.1194C34.0895 19.8806 34.4552 19.8657 34.8283 20.0746C35.2014 20.2836 35.388 20.597 35.388 21.0149V33.194C35.388 33.4925 35.2835 33.7462 35.0746 33.9552C34.8656 34.1641 34.6119 34.2686 34.3134 34.2686H13.5373C13.2388 34.2686 12.9851 34.1641 12.7761 33.9552C12.5672 33.7462 12.4627 33.4925 12.4627 33.194V15.2836C12.4627 14.9851 12.5672 14.7313 12.7761 14.5224C12.9851 14.3134 13.2388 14.209 13.5373 14.209H14.0746V13.8955H16.3134V14.209Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default PropertyBusinessIcon;
