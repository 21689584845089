import React, { useState } from "react";
import Checkbox from "../../atoms/checkbox";
import { Box, Stack } from "../../atoms/layouts";
import DisplayBox from "../../atoms/display-box";
import TermsAndConditionsModal from "../../molecules/terms-and-conditions-modal";

const TermsAndConditionsControlV1 = ({
  onCheck,
  isChecked,
  html,
  terms,
  error = false,
  linkVariant,
  initialFocusSelector = ""
}) => {
  const [showTerms, toggleShowTerms] = useState(false);
  return (
    <DisplayBox>
      <Stack>
        <Checkbox
          name="terms"
          title="Terms and Conditions"
          error={error}
          checked={isChecked}
          onChange={onCheck}
        />
        <Box padding="0 0 0 58px">
          <Stack>
            <Box padding="0">{html}</Box>
            {terms && (
              <TermsAndConditionsModal
                link="Learn More"
                terms={terms}
                isOpen={showTerms}
                toggleOpen={toggleShowTerms}
                linkVariant={linkVariant}
                initialFocusSelector={initialFocusSelector}
              />
            )}
          </Stack>
        </Box>
      </Stack>
    </DisplayBox>
  );
};

export default TermsAndConditionsControlV1;
