import React, { Fragment, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "styled-components";
import AriaModal from "react-aria-modal";
import { WHITE, ATHENS_GREY, SILVER_GREY } from "../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import Paragraph from "../../atoms/paragraph";
import Title from "../../atoms/title";
import ButtonWithAction from "../../atoms/button-with-action";
import { Box, Stack, Cluster } from "../../atoms/layouts";

/*
Default Modal molecule
Uses react-aria-modal behind the scenes for a11y purposes
Styling accomplished with our atoms / layout primitives

Cancel button will (for now) always use hideModal as its action
Continue button takes an action, if you want to navigate to
a different route (as with a link) connect() and use "push" from @thecb/connected-react-router
*/

const getApplicationNode = () => document.getElementById("root");

const Modal = ({
  hideModal,
  onExit = hideModal,
  continueAction,
  isContinueActionDisabled = false,
  cancelAction,
  modalOpen,
  modalHeaderText,
  modalBodyText,
  cancelButtonText = "Cancel",
  continueButtonText = "Continue",
  closeButtonText = "Close",
  modalHeaderBg = WHITE,
  modalBodyBg = ATHENS_GREY,
  useDangerButton = false,
  defaultWrapper = true,
  onlyCloseButton = false,
  noButtons = false, // for instances where modal is closed automatically
  maxHeight,
  underlayClickExits = true,
  noBorder,
  customWidth,
  isLoading,
  buttonExtraStyles,
  children,
  dataQa = null,
  initialFocusSelector = "",
  blurUnderlay = true
}) => {
  const { isMobile } = useContext(ThemeContext);

  // `AriaModal` uses `focus-trap` as a transient dependency. It automatically looks
  // for a tabbable node when the modal mounts. When it doesn't find one, it looks to
  // the `fallbackFocus` option. However, React does not guarantee the ref supplied to
  // this option will be populated on initial render when `focus-trap` is checking
  // these option. When there are no buttons in the modal, this causes an error.
  // Because `focus-trap` cannot be disabled, the ref itself requires a default value
  // to satisfy `focus-trap` until React populates it with the real ref value.
  //
  // See:
  //   - https://react.dev/reference/react/useRef#caveats
  //   - https://github.com/davidtheclark/react-aria-modal/pull/103
  //   - https://github.com/focus-trap/focus-trap?tab=readme-ov-file#createoptions
  const modalContainerRef = useRef("#react-aria-modal-dialog");

  return (
    <div ref={modalContainerRef} tabIndex="-1" data-qa={dataQa}>
      {modalOpen && (
        <AriaModal
          focusTrapOptions={{
            // fallback to resolve Jest unit test errors when tabbable doesn't exist in jsdom https://github.com/focus-trap/focus-trap-react/issues/91
            fallbackFocus: modalContainerRef?.current
          }}
          onExit={onExit}
          getApplicationNode={getApplicationNode}
          titleText={modalHeaderText}
          underlayStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(41, 42, 51, 0.45)",
            backdropFilter: blurUnderlay ? "blur(4px)" : "none",
            WebkitBackdropFilter: blurUnderlay ? "blur(4px)" : "none"
          }}
          dialogStyle={{
            width: customWidth || "615px",
            overflow: "auto"
          }}
          underlayClickExits={underlayClickExits}
          aria-modal={true}
          initialFocus={initialFocusSelector}
          focusDialog={!initialFocusSelector} // Focus the dialogue box itself if no selector for initial focus was provided
        >
          <Box
            padding="0"
            borderRadius="2px"
            boxShadow="inset 0px -2px 0px 0px rgb(0, 80, 149)"
          >
            <Box background={modalHeaderBg} padding="1.5rem">
              <Cluster justify="flex-start" align="center">
                <Title as="h2" weight={FONT_WEIGHT_SEMIBOLD} fontSize="1.25rem">
                  {modalHeaderText}
                </Title>
              </Cluster>
            </Box>
            <Box background={modalBodyBg} padding="1.5rem">
              <Stack childGap="1.5rem">
                <Box
                  borderWidthOverride={noBorder && "0 0 2px 0"}
                  borderColor={!noBorder && SILVER_GREY}
                  padding={!noBorder && "0 0 1.5rem 0"}
                  extraStyles={
                    maxHeight ? `max-height: ${maxHeight}; overflow: auto;` : ``
                  }
                >
                  {defaultWrapper ? (
                    <Paragraph variant="p">{modalBodyText}</Paragraph>
                  ) : (
                    <Box padding={maxHeight ? "0 0 1rem 0" : "0"}>
                      {modalBodyText}
                    </Box>
                  )}
                </Box>
                <Box padding="0">
                  <Stack
                    direction="row"
                    justify="flex-end"
                    align="center"
                    childGap="0rem"
                  >
                    {!noButtons && (
                      <>
                        {!onlyCloseButton ? (
                          <Fragment>
                            {isMobile ? (
                              <Stack childGap="1rem" direction="row">
                                <Box width="100%" padding="0">
                                  <ButtonWithAction
                                    variant="secondary"
                                    action={
                                      cancelAction ? cancelAction : hideModal
                                    }
                                    text={cancelButtonText}
                                    dataQa={cancelButtonText}
                                    extraStyles={buttonExtraStyles}
                                    className="modal-cancel-button"
                                    role="button"
                                    name={cancelButtonText}
                                  />
                                </Box>
                                <Box width="100%" padding="0">
                                  <ButtonWithAction
                                    variant={
                                      useDangerButton ? "danger" : "primary"
                                    }
                                    action={continueAction}
                                    text={continueButtonText}
                                    dataQa={continueButtonText}
                                    isLoading={isLoading}
                                    disabled={isContinueActionDisabled}
                                    extraStyles={buttonExtraStyles}
                                    className="modal-continue-button"
                                    role="button"
                                    name={continueButtonText}
                                  />
                                </Box>
                              </Stack>
                            ) : (
                              <Stack
                                childGap="1rem"
                                direction="row"
                                justify="flex-end"
                              >
                                <ButtonWithAction
                                  variant="secondary"
                                  action={
                                    cancelAction ? cancelAction : hideModal
                                  }
                                  text={cancelButtonText}
                                  dataQa={cancelButtonText}
                                  extraStyles={buttonExtraStyles}
                                  className="modal-cancel-button"
                                  role="button"
                                  name={cancelButtonText}
                                />
                                <ButtonWithAction
                                  variant={
                                    useDangerButton ? "danger" : "primary"
                                  }
                                  action={continueAction}
                                  text={continueButtonText}
                                  dataQa={continueButtonText}
                                  isLoading={isLoading}
                                  disabled={isContinueActionDisabled}
                                  extraStyles={buttonExtraStyles}
                                  className="modal-continue-button"
                                  role="button"
                                  name={continueButtonText}
                                />
                              </Stack>
                            )}
                          </Fragment>
                        ) : (
                          <Box padding="0.5rem">
                            <ButtonWithAction
                              tabIndex="0"
                              action={hideModal}
                              variant="primary"
                              text={closeButtonText}
                              dataQa={closeButtonText}
                              extraStyles={buttonExtraStyles}
                              className="modal-close-button"
                              role="button"
                              name={closeButtonText}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </AriaModal>
      )}
      {children}
    </div>
  );
};

export default Modal;
