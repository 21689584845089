import { GraphQLClient } from "graphql-request";
import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import * as R from "ramda";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { arrayify, objectToArrayOfKeyValues } from "../../../util/general";
import { util } from "@thecb/components";
import { makeAuth } from "../../../util/graphql";
import actions, {
  bindData,
  changeScreen,
  clearNetworkError,
  displayItemWarning
} from "../../../renderer/actions";
import { selectableListViewPresent } from "../../../renderer/utils";

const BANK_ACCOUNT = "BANK_ACCOUNT";
const CREDIT_CARD = "CREDIT_CARD";

const doQuery = async ({
  queryArgs,
  queryParts,
  queryTopLevel,
  headers,
  uri
}) => {
  const gqlClient = new GraphQLClient(uri, {
    headers
  });

  const queryObj = {
    query: {
      [queryTopLevel]: {
        __args: queryArgs,
        ...queryParts
      }
    }
  };

  const curriedGQLconvert = R.curry(jsonToGraphQLQuery);

  const makeGqlString = R.pipe(
    R.ifElse(
      queryObject =>
        R.is(Object, R.pathOr(undefined, ["query", "assets"], queryObject)),
      R.pipe(
        stillQueryObject => {
          let clonedQueryObject = R.clone(stillQueryObject);
          let clonedAssets = clonedQueryObject.query.assets;

          if (clonedAssets.hasOwnProperty("License")) {
            clonedAssets["... on License"] = clonedAssets["License"];
            delete clonedAssets["License"];
          }

          return clonedQueryObject;
        },
        // This ensures the "assets" query coming from FCS contains these values.
        R.assocPath(["query", "assets", "obligations"], {
          id: true,
          kind: true,
          amountDue: true,
          allowedPaymentInstruments: true,
          customAttributes: { kind: true, data: true }
        })
      ),
      R.identity
    ),
    curriedGQLconvert(R.__, { pretty: true })
  );

  const response = await gqlClient.request(makeGqlString(queryObj));

  if (queryTopLevel === "obligations" && R.isEmpty(response[queryTopLevel])) {
    let error = new Error("No obligations found");
    error.code = "not_found";
    throw error;
  }

  return response;
};

const extractObligations = queryTopLevel =>
  R.ifElse(
    R.has("assets"),
    R.pipe(
      R.prop("assets"),
      R.chain(R.path(["obligations"])),
      R.map(obl => ({
        amount: util.general.displayCurrency(obl.amountDue),
        amountDueCents: obl.amountDue,
        allowedPaymentMethods: obl?.allowedPaymentInstruments ?? [
          BANK_ACCOUNT,
          CREDIT_CARD
        ],
        description: R.pipe(
          R.find(R.propEq("kind", "term")),
          R.prop("data")
        )(obl.customAttributes),
        customAttributes: R.reduce(
          (acc, value) => R.assoc(value.kind, value.data, acc),
          {},
          obl.customAttributes
        )
      }))
    ),
    R.pipe(
      R.path([queryTopLevel]),
      R.map(obl => ({
        amount: util.general.displayCurrency(obl.amountDue),
        amountDueCents: obl.amountDue,
        allowedPaymentMethods: obl?.allowedPaymentInstruments ?? [
          BANK_ACCOUNT,
          CREDIT_CARD
        ],
        description: obl.id,
        ...R.reduce(
          (acc, value) => R.assoc(value.kind, value.data, acc),
          {},
          obl.customAttributes
        )
      }))
    )
  );

const evolveCents = cents => util.general.displayCurrency(cents);

const normalizeAmounts = queryTopLevel =>
  R.ifElse(
    R.has("assets"),
    R.over(
      R.lensPath(["assets", 0, "obligations"]),
      R.map(R.evolve({ amountDue: evolveCents }))
    ),
    R.over(
      R.lensPath([queryTopLevel]),
      R.map(R.evolve({ amountDue: evolveCents }))
    )
  );

// Old method of identifying not found errors (pre-March 2023)
export const isOldNotFoundError = R.pipe(
  errors => R.path(["response", "errors", 0, "message"], errors),
  R.includes("Resource not found")
);

// New format for not found errors from ghenghis (March 2023)
// Keeping old method in place for now in case any lookups return incorrect format for error message
export const isNotFoundError = R.pipe(
  errors => R.path(["response", "errors", 0, "code"], errors),
  R.includes("not_found")
);

export const isUnexpectedError = R.pipe(
  errors => R.path(["response", "errors", 0, "message"], errors),
  R.anyPass([
    R.includes("Bad request"),
    R.includes("Bad gateway"),
    R.includes("Unknown error"),
    R.includes("unauthenticated"),
    R.includes("Unhandled gateway error")
  ])
);

export const resetErrorStateEpic = action$ =>
  action$.ofType(actions.DO_SEARCH).pipe(
    RxOp.flatMap(() => {
      return Rx.concat(
        Rx.of(bindData({ location: ["unexpectedErrorFlag"], data: false })),
        Rx.of(bindData({ location: ["notFoundErrorFlag"], data: false }))
      );
    })
  );

/*
We are keeping the handling of DO_LOOKUP queries in src/state/epics/workflow/lookup.js
until our test coverage of these workflows is up to par.

https://citybase.atlassian.net/browse/WCHK-601
*/
// export const lookupEpic = (action$, state$) =>
//   action$.ofType(actions.DO_LOOKUP).pipe(
//     RxOp.flatMap(({ payload }) =>
//       Rx.from(
//         doQuery({
//           queryArgs: {
//             lookupBy: lookupBy({ ...payload, stateValue: state$.value })
//           },
//           ...commonQueryProps({ ...payload, stateValue: state$.value })
//         })
//       ).pipe(
//         handleResponse({
//           ...payload,
//           stateValue: state$.value
//         }),
//         handleErrors
//       )
//     )
//   );

export const searchEpic = (action$, state$) =>
  action$.ofType(actions.DO_SEARCH).pipe(
    RxOp.flatMap(({ payload }) =>
      Rx.from(
        doQuery({
          queryArgs: {
            searchParams: makeSearchParams({
              ...payload,
              stateValue: state$.value
            }),
            accountSearchConfigKey:
              state$.value.workflow.boundData.accountSearchConfigKey
          },
          ...commonQueryProps({ ...payload, stateValue: state$.value })
        })
      ).pipe(
        handleResponse({
          ...payload,
          stateValue: state$.value
        }),
        handleErrors
      )
    )
  );

export const commonQueryProps = ({ query, queryTopLevel, stateValue }) => ({
  queryParts: query[queryTopLevel],
  queryTopLevel,
  headers: makeHeaders(stateValue),
  uri: stateValue.config.graphqlServiceEndpoint
});

export const makeSearchParams = ({
  allowedSearchParamKeys = null,
  stateValue
}) => {
  let searchParams = R.path(["workflow", "boundData"], stateValue);
  if (!!allowedSearchParamKeys) {
    searchParams = R.pick(allowedSearchParamKeys, searchParams);
  }
  return objectToArrayOfKeyValues(searchParams);
};

export const lookupBy = ({ queryLocation, stateValue }) => ({
  client: stateValue.workflow.boundData.client,
  ids: arrayify(
    R.path(R.append(queryLocation, ["workflow", "boundData"]), stateValue)
  )
});

export const makeHeaders = stateValue =>
  makeAuth(
    R.path(
      ["workflow", "boundData", "lookup_data", "authentication"],
      stateValue
    )
  );

export const handleResponse = ({
  postQuery: { screen },
  queryTopLevel,
  resultsLocation,
  stateValue
}) =>
  RxOp.flatMap(response => {
    return Rx.concat(
      Rx.of(
        bindData({
          location: ["selectedPaymentItems"], // wipe selected items on new lookup
          data: []
        })
      ),
      Rx.of(displayItemWarning(false)), // remove any restricted items alert from prev lookup
      Rx.of(
        bindData({
          location: ["lookupResponse"],
          data: normalizeAmounts(queryTopLevel)(response)
        })
      ),
      Rx.of(
        bindData({
          location: resultsLocation,
          data: extractObligations(queryTopLevel)(response)
        })
      ),
      Rx.of(
        bindData({
          location: ["workflowHasSelectableListView"],
          data: selectableListViewPresent(stateValue)
        })
      ),
      // TODO: Implement other post-lookup behaviors as necessary
      Rx.of(clearNetworkError(), changeScreen(screen))
    );
  });

export const handleErrors = RxOp.catchError(
  R.pipe(
    R.cond([
      [
        isNotFoundError,
        R.always(
          Rx.of(bindData({ location: ["notFoundErrorFlag"], data: true }))
        )
      ],
      [
        isOldNotFoundError,
        R.always(
          Rx.of(bindData({ location: ["notFoundErrorFlag"], data: true }))
        )
      ],
      [
        isUnexpectedError,
        R.always(
          Rx.of(
            bindData({
              location: ["unexpectedErrorFlag"],
              data: true
            })
          )
        )
      ],
      [
        R.T,
        R.always(
          Rx.of(
            bindData({
              location: ["catchAllLookupErrorFlag"],
              data:
                "catch all error happened, this will be of no use most likely if this happens."
            })
          )
        )
      ]
    ])
  )
);
