import React from "react";
import { CHARADE_GREY } from "../../../constants/colors";

const PaymentStatusIcon = ({
  width = "20",
  height = "21",
  color = CHARADE_GREY,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 4.375C10.875 3.89175 11.2668 3.5 11.75 3.5H16.125C16.6082 3.5 17 3.89175 17 4.375V8.75C17 9.23325 16.6082 9.625 16.125 9.625H15.25V15.75C15.25 16.7165 14.4665 17.5 13.5 17.5H4.75C3.7835 17.5 3 16.7165 3 15.75V7C3 6.0335 3.7835 5.25 4.75 5.25H10.875V4.375ZM10.875 8.75V6.5625H4.75C4.50838 6.5625 4.3125 6.75838 4.3125 7V15.75C4.3125 15.9916 4.50838 16.1875 4.75 16.1875H13.5C13.7416 16.1875 13.9375 15.9916 13.9375 15.75V9.625H11.75C11.2668 9.625 10.875 9.23325 10.875 8.75ZM13.9375 7.875C14.6624 7.875 15.25 7.28737 15.25 6.5625C15.25 5.83763 14.6624 5.25 13.9375 5.25C13.2126 5.25 12.625 5.83763 12.625 6.5625C12.625 7.28737 13.2126 7.875 13.9375 7.875Z"
        fill={color}
      />
    </svg>
  );
};
export default PaymentStatusIcon;
