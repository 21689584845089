import CreateActivateUserAttempt from "./CreateActivateUserAttempt.graphql";
import CreateResendVerificationAttempt from "./CreateResendVerificationAttempt.graphql";
import { createQuery } from "../../../../../util/graphql";

export const createActivateUserAttempt = ({
  endpoint,
  clientSlug,
  verificationKey
}) =>
  createQuery(endpoint, clientSlug)(CreateActivateUserAttempt, {
    key: verificationKey
  });

export const createResendVerificationAttempt = ({
  endpoint,
  clientSlug,
  email
}) =>
  createQuery(endpoint, clientSlug)(CreateResendVerificationAttempt, {
    email
  });
