import S from "sanctuary";
import $ from "sanctuary-def";
import RemoteData, { RemoteDataType } from "./RemoteData";

const _S = S.create({
  checkTypes: process.env.NODE_ENV !== "production",
  env: S.env.concat([RemoteDataType($.Unknown)($.Unknown)])
});

const logShow = x => console.log(S.show(x));

export default { ..._S, RemoteData, logShow };
