import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../../content/Content.theme";
import { themeComponent } from "../../../util/themeUtils";
import { withWindowSize } from "@thecb/components";
import {
  Box,
  Center,
  Cover,
  Stack,
  Cluster,
  ButtonWithLink,
  Heading,
  Text,
  constants
} from "@thecb/components";
import Search from "../../content/search";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import RightArrowIcon from "../../icons/RightArrowIcon";
import { getCallToActionInfo } from "../../../util/dataAdapters";

const CMSLandingPage = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  maxContentWidth = "1348px",
  fullWidthMobile = true,
  meta,
  searchParams,
  pageTitle,
  description,
  callToActionText,
  callToActionURL,
  callToActionLink,
  heroImage,
  content,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const gradient = `background: ${themeValues.gradient[0]};
   background: linear-gradient(-225deg, rgba(55, 65, 77, 0.50) 0%, rgba(41, 42, 51, 0.50) 99%), url(${heroImage.url}); background-size: cover;background-position:center;`;

  const { heroSearch } = searchParams;

  const { ctaUrl, ctaText } = getCallToActionInfo(
    callToActionLink,
    callToActionText,
    callToActionURL
  );

  const heroTextShadowStyle = `text-shadow: 1px 1px 10px ${FIREFLY_GREY};`;

  const Divider = () => (
    <Box
      padding={0}
      style={{
        width: "298px",
        margin: isMobile ? "32px auto 8px" : "48px auto 24px",
        borderTop: "2px solid white"
      }}
    />
  );

  const searchMenu = () => {
    return heroSearch ? (
      <>
        <Divider />
        <Heading as="h3" aria-label="Search" />
        <Search
          searchResults={searchParams.searchResults}
          retrieveSearchResults={searchParams.retrieveSearchResults}
          setSearchTerm={searchParams.setSearchTerm}
          meta={meta}
          themeValues={themeValues}
          isMobile={isMobile}
          inHeaderMenu
          inHero
        />
      </>
    ) : null;
  };

  return (
    <Box padding="0" minWidth="100%" background={constants.colors.WHITE}>
      <Cover minHeight="100vh" fillCenter>
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box
            padding={isMobile ? "0 16px 8px" : "80px 32px 104px"}
            minWidth="100%"
            minHeight={isMobile ? "313px" : "376px"}
            extraStyles={gradient}
          >
            <Cover
              minHeight={isMobile ? "313px" : "376px"}
              singleChild
              fillCenter
            >
              <Stack
                childGap={isMobile ? "16px" : "24px"}
                fullHeight
                justify="center"
              >
                <Center
                  maxWidth={
                    isMobile && fullWidthMobile ? "100%" : maxContentWidth
                  }
                  intrinsic={!isMobile}
                >
                  <Box key="contents" padding="0">
                    <Cluster justify="center" align="center">
                      <Stack childGap="16px">
                        <Heading
                          variant={isMobile ? "h6" : "h3"}
                          as="h1"
                          color="white"
                          key="heading"
                          textAlign="center"
                          extraStyles={`font-size: ${
                            isMobile ? "17.5px" : "27px"
                          }; line-height: ${
                            isMobile ? "26px" : "40px"
                          }; ${heroTextShadowStyle}`}
                        >
                          {pageTitle}
                        </Heading>
                      </Stack>
                    </Cluster>
                  </Box>
                </Center>
                <Center
                  maxWidth={
                    isMobile && fullWidthMobile ? "100%" : maxContentWidth
                  }
                  intrinsic={!isMobile}
                >
                  <Box key="desc" padding="0">
                    <Cluster justify="center" align="center">
                      <Stack childGap={isMobile ? "16px" : "24px"}>
                        <Box key="desc-text" padding="0">
                          <Heading
                            variant="h1"
                            as="h2"
                            weight={FONT_WEIGHT_SEMIBOLD}
                            color="white"
                            textAlign="center"
                            extraStyles={`font-size: ${
                              isMobile ? `36px` : `70.5px;`
                            };
                              line-height: ${
                                isMobile ? `54px` : `61px`
                              }; ${heroTextShadowStyle}`}
                          >
                            {description}
                          </Heading>
                        </Box>
                        {!heroSearch && ctaUrl && (
                          <Box key="cta" padding="0" minWidth="100%">
                            <Cluster
                              justify={isMobile ? "center" : "flex-end"}
                              align="center"
                            >
                              <ButtonWithLink
                                url={ctaUrl}
                                contentOverride
                                variant="ghost"
                                extraStyles={`span { color: white; }`}
                                key="call-to-action"
                              >
                                <Cluster justify="flex-start" align="center">
                                  <Text
                                    weight={FONT_WEIGHT_SEMIBOLD}
                                    color="white"
                                    key="cta-text"
                                    extraStyles={`line-height: ${
                                      isMobile ? "21px" : "27px"
                                    }; font-size: ${
                                      isMobile ? "14px" : "27px"
                                    };`}
                                  >
                                    {ctaText}
                                  </Text>
                                  <RightArrowIcon
                                    color="white"
                                    key="cta-arrow"
                                  />
                                </Cluster>
                              </ButtonWithLink>
                            </Cluster>
                          </Box>
                        )}
                        {searchMenu()}
                      </Stack>
                    </Cluster>
                  </Box>
                </Center>
              </Stack>
            </Cover>
          </Box>
          <Box padding="0" extraStyles="margin: auto;">
            <Cover minHeight="100%" singleChild fillCenter>
              <Stack childGap="40px">{content}</Stack>
            </Cover>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
};

export default withWindowSize(
  themeComponent(CMSLandingPage, "Content", fallbackValues, "default")
);
