import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";

import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./NavTab.theme";

import InternalLink from "../link/InternalLink";
import Box from "../layouts/Box";

const NavTab = ({ path, label, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);

  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsActive(location.pathname.includes(path));
  }, [location.pathname]);

  const border = `border-bottom: 3px solid ${themeValues.activeColor}`;

  return (
    <Box padding={0}>
      <InternalLink
        color={isActive ? themeValues.activeColor : themeValues.linkColor}
        to={path}
        extraStyles={`
          border-bottom: 3px solid transparent;
          font-family: ${themeValues.fontFamily};
          text-decoration: none;
          ${isActive && !isMobile ? border : "none"};
          &:hover {
            text-decoration: none;
            color: ${themeValues.activeColor};
            ${isMobile ? "" : `${border}`}
          };
          padding: 1.25rem 0;
        `}
      >
        {label}
      </InternalLink>
    </Box>
  );
};

export default themeComponent(NavTab, "NavTab", fallbackValues);
