import React from "react";

const SuccessfulIconMedium = ({ iconIndex = 0 }) => {
  const mask0ID = `SuccessfulIconMedium-mask0-${iconIndex}`;
  const mask1ID = `SuccessfulIconMedium-mask1-${iconIndex}`;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#317D4F" />
      <mask
        id={mask0ID}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <circle cx="12" cy="12" r="12" fill="white" />
      </mask>
      <g mask={`url(#${mask0ID})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6672 7.35354C17.472 7.15828 17.1554 7.15828 16.9601 7.35354L10.2426 14.0711L7.76775 11.5962C7.57249 11.4009 7.25591 11.4009 7.06065 11.5962L6.35354 12.3033C6.15828 12.4985 6.15828 12.8151 6.35354 13.0104L9.18167 15.8385C9.18177 15.8386 9.18187 15.8387 9.18197 15.8388L9.88907 16.5459C10.0843 16.7412 10.4009 16.7412 10.5962 16.5459L11.3033 15.8388C11.3034 15.8387 11.3036 15.8386 11.3037 15.8384L18.3744 8.76775C18.5696 8.57249 18.5696 8.25591 18.3744 8.06065L17.6672 7.35354Z"
          fill="white"
        />
        <mask
          id={mask1ID}
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="7"
          width="13"
          height="10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6672 7.35354C17.472 7.15828 17.1554 7.15828 16.9601 7.35354L10.2426 14.0711L7.76775 11.5962C7.57249 11.4009 7.25591 11.4009 7.06065 11.5962L6.35354 12.3033C6.15828 12.4985 6.15828 12.8151 6.35354 13.0104L9.18167 15.8385C9.18177 15.8386 9.18187 15.8387 9.18197 15.8388L9.88907 16.5459C10.0843 16.7412 10.4009 16.7412 10.5962 16.5459L11.3033 15.8388C11.3034 15.8387 11.3036 15.8386 11.3037 15.8384L18.3744 8.76775C18.5696 8.57249 18.5696 8.25591 18.3744 8.06065L17.6672 7.35354Z"
            fill="white"
          />
        </mask>
        <g mask={`url(#${mask1ID})`}>
          <rect x="2" y="2" width="20" height="20" fill="white" />
        </g>
      </g>
    </svg>
  );
};
export default SuccessfulIconMedium;
