import React, { Fragment, memo } from "react";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./Module.theme";
import Title from "../../atoms/title";
import { Box, Cluster } from "../../atoms/layouts";

/*
  New (01/22) - updated <Module /> to use <Title /> atom
  Because <Title /> decouples size from element, also gave <Module />
  two new props: "as" and "fontSize"

  When present, <Module /> will use those values to dictate element type and font size.

  For backwards compatability, <Module /> still computes a themed font size and element type based on old
  <Heading /> variants. If "fontSize" or "as" is undefined, <Module /> will use themed values.
*/

const Module = ({
  variant = "default",
  as,
  disabled,
  heading,
  rightTitleContent,
  titleID = "",
  spacing = "1rem",
  padding = "0",
  margin = "0",
  spacingBottom = "2.5rem",
  fontSize,
  themeValues,
  children,
  allowContentOverflow = false
}) => {
  const themedFontSize =
    variant === "small"
      ? "1.25rem"
      : variant === "default"
      ? "1.375rem"
      : "2rem";
  const computedFontSize = fontSize || themedFontSize;
  const themedElemType =
    variant === "small" ? "h6" : variant === "default" ? "h5" : "h2";
  const computedElemType = as || themedElemType;
  const disabledStyles = "opacity: 0.40;";
  const headingText = (
    <Title
      weight={themeValues.fontWeight}
      color={themeValues.fontColor}
      margin={`${spacing} 0 ${themeValues.titleSpacing} 0`}
      textAlign={themeValues.textAlign}
      as={computedElemType}
      extraStyles={`font-size: ${computedFontSize};`}
      id={titleID}
    >
      {heading}
    </Title>
  );

  return (
    <Box
      aria-disabled={disabled}
      extraStyles={disabled && disabledStyles}
      padding="0"
      role={"group"}
    >
      {heading && !rightTitleContent && headingText}
      {heading && rightTitleContent && (
        <Cluster
          justify="space-between"
          align="center"
          overflow={allowContentOverflow}
          nowrap
        >
          {headingText}
          {rightTitleContent}
        </Cluster>
      )}
      <Box padding={`0 0 ${spacingBottom}`} extraStyles={`margin: ${margin}`}>
        <Box
          padding={padding}
          background={themeValues.backgroundColor}
          borderRadius={themeValues.borderRadius}
          boxShadow={themeValues.boxShadow}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(
  themeComponent(Module, "Module", fallbackValues, "default")
);
