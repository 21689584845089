import {
  createFormState,
  required,
  onlyIntegers,
  hasLength,
  matchesRegex,
  validateWhen,
  dateAfterToday,
  isValidMonth,
  onlyExpirationDate
} from "redux-freeform";

//TODO: Will make zip code able to have more than 5 digits once we add in the FormattedInput because it will have issues with format of 60606-1111.

const formConfig = {
  country: {
    defaultValue: "US",
    validators: [required()]
  },
  nameOnCard: {
    validators: [required()]
  },
  creditCardNumber: {
    validators: [required(), hasLength(15, 16)],
    constraints: [onlyIntegers(), hasLength(0, 16)]
  },
  expirationDate: {
    validators: [
      required(),
      hasLength(4, 4),
      isValidMonth(0),
      dateAfterToday("MMYY", "month", true)
    ],
    constraints: [onlyExpirationDate(), hasLength(0, 4)]
  },
  cvv: {
    validators: [required(), hasLength(3, 4)],
    constraints: [onlyIntegers(), hasLength(0, 4)]
  },
  zipCode: {
    validators: [
      required(),
      validateWhen(
        validateWhen(hasLength(5, 5), hasLength(0, 5)),
        matchesRegex("US"),
        "country"
      ),
      validateWhen(
        validateWhen(hasLength(9, 9), hasLength(6, 9)),
        matchesRegex("US"),
        "country"
      )
    ],
    constraints: [
      validateWhen(onlyIntegers(), matchesRegex("US"), "country"),
      validateWhen(hasLength(0, 9), matchesRegex("US"), "country")
    ]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
