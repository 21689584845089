import React from "react";
import { Alert } from "@thecb/components";
import {
  BrowserLogo,
  BrowserLink,
  BrowserLinkGroup,
  BrowserLinkText,
  OutdatedBrowserContainer,
  OutdatedBrowserText
} from "./outdated-browser.styled";

import { Helmet } from "react-helmet";

const OutdatedBrowser = () => (
  <OutdatedBrowserContainer>
    <Helmet>
      <title>Browser Not Supported</title>
    </Helmet>
    <Alert
      heading={`Please update your browser to use ${window.location.origin}`}
      showQuitLink={false}
      variant="warn"
    >
      <OutdatedBrowserText>
        In order to build you the most up-to-date experience, we used
        technologies that are not supported by older browsers.
      </OutdatedBrowserText>
      <OutdatedBrowserText>
        <em>
          Please update to one of these modern browsers for the complete
          experience:
        </em>
      </OutdatedBrowserText>
      <BrowserLinkGroup>
        <BrowserLink href="https://www.mozilla.org/en-US/firefox/new/">
          <BrowserLogo src="/firefox-browser-logo.svg" />
          <BrowserLinkText>Mozilla Firefox</BrowserLinkText>
        </BrowserLink>
        <BrowserLink href="https://www.google.com/chrome/">
          <BrowserLogo src="/chrome-browser-logo.svg" />
          <BrowserLinkText>Google Chrome</BrowserLinkText>
        </BrowserLink>
        <BrowserLink href="https://www.microsoft.com/en-us/windows/microsoft-edge">
          <BrowserLogo src="/edge-browser-logo.svg" />
          <BrowserLinkText>Microsoft Edge</BrowserLinkText>
        </BrowserLink>
        <BrowserLink href="https://support.apple.com/downloads/safari">
          <BrowserLogo src="/safari-browser-logo.svg" />
          <BrowserLinkText>Apple Safari</BrowserLinkText>
        </BrowserLink>
      </BrowserLinkGroup>
    </Alert>
  </OutdatedBrowserContainer>
);

export default OutdatedBrowser;
