import styled from "styled-components";
import { constants } from "@thecb/components";

export const PaymentPlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 0px 0px 5px 0px rgb(202, 206, 216);
  background-color: ${constants.colors.WHITE};
  padding: 8px 0px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;
`;

export const ErrorContainer = styled.div`
  color: ${constants.colors.ERROR_COLOR};
  font-weight: ${constants.fontWeights.FONT_WEIGHT_SEMIBOLD};
`;
