import React from "react";
import { AlertIcons, IconQuit } from "../../../deprecated/icons";
import { Box, Center, Cluster, Stack, Cover, Sidebar } from "../layouts";
import Text from "../text";
import { fallbackValues } from "./Alert.theme";
import { themeComponent } from "../../../util/themeUtils";
import { generateShadows } from "../../../util/generateShadows";

const Alert = ({
  heading,
  text,
  textOverride,
  variant,
  children,
  height,
  onLinkClick,
  padding = "0.5rem",
  showQuitLink = true,
  themeValues,
  extraStyles,
  maxContentWidth,
  noBorder = false,
  enableBoxShadow = false,
  enableSmallText = false,
  innerContentPadding = "1rem",
  iconPadding = "0 0 0 1rem",
  contentFullHeight = false,
  ariaRole = null,
  ariaAtomic = true,
  ariaLive = "polite"
}) => {
  const Icon = AlertIcons[variant];
  let contentPadding = maxContentWidth
    ? `${padding} 1rem`
    : innerContentPadding;

  const content = (
    <Sidebar width="24px" childGap="0rem">
      <Box padding={iconPadding} minHeight="100%">
        <Cover minHeight="100%" singleChild>
          <Box padding="0" width="1.5rem" minHeight="1.5rem">
            <Icon />
          </Box>
        </Cover>
      </Box>
      <Box padding="0">
        <Sidebar
          sidebarOnRight
          width="24px"
          childGap="0rem"
          fullHeight={contentFullHeight}
        >
          <Box padding={contentPadding}>
            <Cluster justify="flex-start" align="center">
              {textOverride ? (
                textOverride
              ) : (
                <Stack fullHeight childGap="0">
                  <Text
                    variant={enableSmallText ? "pS" : "p"}
                    color={themeValues.text}
                    weight="600"
                  >
                    {heading}
                  </Text>
                  <Text variant="pS" color={themeValues.text}>
                    {text}
                  </Text>
                  {children}
                </Stack>
              )}
            </Cluster>
          </Box>
          <Cluster justify="flex-end" align="flex-start">
            {showQuitLink && (
              <Box
                padding="0"
                minHeight="100%"
                onClick={onLinkClick}
                onKeyDown={e => e.key === "Enter" && onLinkClick()}
                role="button"
                tabIndex={0}
                aria-label={`Close Alert: ${heading}`}
                extraStyles="cursor: pointer;"
              >
                <IconQuit />
              </Box>
            )}
          </Cluster>
        </Sidebar>
      </Box>
    </Sidebar>
  );

  return (
    <Box
      padding={padding}
      width="100%"
      minHeight={height && parseInt(height) < 100 ? height : "100px"}
      height={height ? height : "auto"}
      background={themeValues.background}
      borderRadius="4px"
      borderColor={themeValues.border}
      borderSize={noBorder ? "0px" : "1px"}
      boxShadow={enableBoxShadow ? generateShadows()?.inset?.base : ""}
      extraStyles={extraStyles}
      aria-live={ariaLive}
      {...(ariaAtomic !== null ? { "aria-atomic": ariaAtomic } : {})}
      {...(ariaRole !== null ? { role: ariaRole } : {})}
    >
      {maxContentWidth ? (
        <Center maxWidth={maxContentWidth}>{content}</Center>
      ) : (
        content
      )}
    </Box>
  );
};

export default themeComponent(Alert, "Alert", fallbackValues, "info");
