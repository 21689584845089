const backgroundColor = "#3b414d";
const linkColor = "#ffffff";
const logoHeight = "30px";
const subfooterColor = "#6D717E";
const hoverFocusStyles = `outline: none; text-decoration: underline; color: ${linkColor};`;
const accessibilityColor = "#3181E3";

export const fallbackValues = {
  backgroundColor,
  linkColor,
  logoHeight,
  subfooterColor,
  hoverFocusStyles,
  accessibilityColor
};
