import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Cluster,
  ButtonWithLink,
  Heading,
  Paragraph,
  VerifiedEmailIcon,
  withWindowSize,
  constants
} from "@thecb/components";

const AccountVerificationSuccess = () => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Box
      padding="0"
      background={constants.colors.WHITE}
      borderRadius="4px"
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
      0px 3px 8px 0px rgb(202, 206, 216);"
      width={isMobile ? "auto" : "576px"}
    >
      <Box
        background={constants.colors.GRECIAN_GREY}
        minWidth="100%"
        padding="0.5rem"
      >
        <Cluster justify="center" align="center">
          <VerifiedEmailIcon variant="success" />
        </Cluster>
      </Box>
      <Box padding={isMobile ? "1rem" : "1rem 1.5rem"}>
        <Heading
          color={constants.colors.CHARADE_GREY}
          variant="h5"
          weight="600"
          as="h1"
        >
          Welcome!
        </Heading>
        <Paragraph
          color={constants.colors.CHARADE_GREY}
          margin="0.5rem 0"
          variant="pL"
          as="h2"
        >
          Your email has been verified. Click Log in to get started.
        </Paragraph>
        <Box padding="1rem 0rem 0.5rem" extraStyles={`margin: 0 -0.5rem`}>
          <Cluster justify="center" align="center">
            <ButtonWithLink
              url="/login"
              variant="primary"
              text="Log in"
              borderRadius="4px"
              extraStyles={`width: 100%;`}
              linkExtraStyles={`width: 100%;`}
            />
          </Cluster>
        </Box>
      </Box>
    </Box>
  );
};

export default withWindowSize(AccountVerificationSuccess);
