import {
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_SEMIBOLD,
  LINK_TEXT_DECORATION
} from "../../../constants/style_constants";

const backgroundColor = { default: "#ffffff", footer: "#ffffff" };
const linkColor = { default: "#3176AA", footer: "#ffffff" };
const border = { default: "#cdcdcd", footer: "#cdcdcd" };
const fontSize = { default: "1rem", footer: "0.875rem" };
const lineHeight = { default: "1.5rem", footer: "1.25rem" };
const fontWeight = {
  default: FONT_WEIGHT_REGULAR,
  footer: FONT_WEIGHT_SEMIBOLD
};
const standardInteractionStyles = `
    &:hover {
      outline: none; 
      text-decoration: underline;
    }
    &:focus {
      outline: 3px solid #3181E3;
      outline-offset: 2px;
    }
`;

const modalLinkHoverFocus = {
  default: standardInteractionStyles,
  footer: standardInteractionStyles
};

const modalLinkTextDecoration = {
  default: LINK_TEXT_DECORATION,
  footer: "none"
};

export const fallbackValues = {
  backgroundColor,
  linkColor,
  border,
  fontSize,
  lineHeight,
  fontWeight,
  modalLinkHoverFocus,
  modalLinkTextDecoration
};
