import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const ForgotPasswordIcon = ({ themeValues }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <defs>
        <path id="prefix__a" d="M0 0h100v100H0z" />
        <path
          d="M72.432 59.997l.305.004c6.587.162 11.878 5.552 11.878 12.178l-.004.305c-.162 6.587-5.553 11.878-12.179 11.878l-.304-.003c-6.588-.162-11.878-5.554-11.878-12.18 0-6.728 5.454-12.182 12.182-12.182zm-.092 16.098c-.85 0-1.482.578-1.482 1.36 0 .765.632 1.343 1.482 1.343s1.502-.578 1.502-1.343c0-.782-.652-1.36-1.502-1.36zM72.597 67c-1.502 0-2.708.612-3.597 1.479l1.304 1.02c.573-.51 1.206-.867 1.996-.867 1.008 0 1.601.544 1.601 1.326 0 1.513-3.004 2.482-2.589 4.947h2.036c-.257-2.176 2.806-2.924 2.806-5.083 0-1.853-1.482-2.822-3.557-2.822z"
          id="prefix__c"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill={themeValues.primaryColor}>
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M63 46a7 7 0 017 7v7.24c.786-.16 1.6-.243 2.432-.243l.305.004c6.587.162 11.878 5.552 11.878 12.178l-.004.305c-.162 6.587-5.553 11.878-12.179 11.878l-.304-.003a12.136 12.136 0 01-7.944-3.215L60.229 84H37.62L24 74.764V53a7 7 0 017-7h32z"
          fill={themeValues.primaryColor}
          mask="url(#prefix__b)"
        />
        <path
          d="M50 13c20.435 0 37 16.565 37 37 0 5.896-1.38 11.47-3.833 16.418A12.178 12.178 0 0072.737 60l-.305-.004c-1.708 0-3.334.352-4.81.987v-8.33a5.276 5.276 0 00-5.058-5.272l-.217-.004H31.653a5.276 5.276 0 00-5.271 5.058l-.004.217V59h24.14a1.5 1.5 0 01.145 2.993l-.144.007H26.377v8H46.52a1.5 1.5 0 01.145 2.993l-.145.007H26.377v1.453C32.494 80.363 40.822 84 50 84c4.997 0 9.742-1.078 14.015-3.014.823.787 1.757 1.46 2.775 1.993A36.835 36.835 0 0150 87c-20.435 0-37-16.565-37-37s16.565-37 37-37zm0 3c-18.778 0-34 15.222-34 34 0 1.699.125 3.368.365 5H23.5l.001-2.347a8.153 8.153 0 017.904-8.15l.25-.003 1.345-.001L33 34c0-7.732 6.268-14 14-14 7.628 0 13.83 6.1 13.997 13.687L61 34v10.499l1.347.001a8.153 8.153 0 018.15 7.904l.003.25V55H83c.216 0 .425.034.62.098.25-1.662.38-3.365.38-5.098 0-18.778-15.222-34-34-34zm-3 8c-5.43 0-9.848 4.327-9.996 9.72L37 34v10.499h20V34c0-5.523-4.477-10-10-10z"
          fill={themeValues.accentColor}
          mask="url(#prefix__b)"
        />
        <use fill={themeValues.subIconColor} xlinkHref="#prefix__c" />
      </g>
    </svg>
  );
};

export default themeComponent(
  ForgotPasswordIcon,
  "Icons",
  fallbackValues,
  "info"
);
