import * as R from "ramda";

// Constants to reprsent input error types
export const MIN_LENGTH_ERROR = "error/HAS_LENGTH";
export const MAX_LENGTH_ERROR = "max_length_error";
export const EXACT_LENGTH_ERROR = "exact_length_error";
export const MULTIPLE_LENGTHS_ERROR = "multiple_lengths_error";
export const EMAIL_ERROR = "email_error";
export const HAS_NUMBER_ERROR = "error/HAS_NUMBER";
export const HAS_UPPERCASE_LETTER_ERROR = "error/HAS_UPPERCASE_LETTER";
export const HAS_LOWERCASE_LETTER_ERROR = "error/HAS_LOWERCASE_LETTER";
export const HAS_SPECIAL_CHARACTER_ERROR = "error/HAS_SPECIAL_CHARACTER";
export const ONLY_NUMBERS_ERROR = "only_numbers_error";
export const ONLY_LETTERS_ERROR = "only_letters_error";
export const REQUIRED_FIELD_ERROR = "required_field_error";
export const NUM_GREATER_THAN_ERROR = "num_greater_than_error";
export const NUM_LESS_THAN_ERROR = "num_less_than_error";
export const MATCHES_FIELD_ERROR = "matches_field_error";
export const VALID_SELECT_OPTION_ERROR = "valid_select_option_error";

// Array<String>, Array<String> -> Object -> String
const genErrorMessage = (strings, ...argKeys) => inputState =>
  strings.reduce(
    (accum, current, index) =>
      `${accum}${current}${
        argKeys[index] ? R.pathOr("", [argKeys[index]], inputState) : ""
      }`,
    []
  );

/*
What's going on here? This looks weird, you may ask...
DEFAULT_ERROR_MESSAGES is a map between the error constants (which are returned by validators)
and some tagged template literals.
In this case, the template tag function is curried and returns a function which accepts an args object
I'm calling that object inputState, but it could really be anything.
When the error message string fucntion is called with that arg, it's reduced to produce a custom error message
with interpolation.
*/
export const DEFAULT_ERROR_MESSAGES = {
  [MIN_LENGTH_ERROR]: genErrorMessage`${`fieldLabel`} is too short`,
  [MAX_LENGTH_ERROR]: genErrorMessage`${`fieldLabel`} is too long`,
  [EXACT_LENGTH_ERROR]: genErrorMessage`${`fieldLabel`} is not the right length`,
  [MULTIPLE_LENGTHS_ERROR]: genErrorMessage`${`fieldLabel`} is not a valid length`,
  [EMAIL_ERROR]: genErrorMessage`${`fieldLabel`} is not a valid email address`,
  [HAS_NUMBER_ERROR]: genErrorMessage`${`fieldLabel`} needs a number`,
  [HAS_UPPERCASE_LETTER_ERROR]: genErrorMessage`${`fieldLabel`} needs an uppercase letter`,
  [HAS_LOWERCASE_LETTER_ERROR]: genErrorMessage`${`fieldLabel`} needs a lowercase letter`,
  [HAS_SPECIAL_CHARACTER_ERROR]: genErrorMessage`${`fieldLabel`} needs a special character`,
  [ONLY_NUMBERS_ERROR]: genErrorMessage`${`fieldLabel`} must be only numbers`,
  [ONLY_LETTERS_ERROR]: genErrorMessage`${`fieldLabel`} must be only letters`,
  [REQUIRED_FIELD_ERROR]: genErrorMessage`${`fieldLabel`} is required`,
  [NUM_GREATER_THAN_ERROR]: genErrorMessage`${`fieldLabel`} is too high`,
  [NUM_LESS_THAN_ERROR]: genErrorMessage`${`fieldLabel`} is too low`,
  [MATCHES_FIELD_ERROR]: genErrorMessage`${`fieldLabel`} must match ${`matchField`}`,
  [VALID_SELECT_OPTION_ERROR]: genErrorMessage`${`fieldLabel`} is not a valid option`
};

// Constants to represent an input's state
// Neutral - has not been validated
// Invalid - has been validated and has an error
// Valid - has been validated and has no error
export const INPUT_STATE_NEUTRAL = "NEUTRAL";
export const INPUT_STATE_INVALID = "INVALID";
export const INPUT_STATE_VALID = "VALID";

// Function to check the state of an input
// This function is intended to be used with inputs that have multiple validators
// Takes an input's state value, destructures for the input's error and showError booleans, list of errors
// Also takes a list of the specific error constants to look for
// If the input's showError property is false, the input has not been validated, and input state is NEUTRAL
// If the input has an error, and showError is true, check if the errors we care about are in the input's error list
// If yes, input state is INVALID
// If input has no error and showError is true, or the errors we're looking for aren't present in the list
// then input state is VALID
// { error: Boolean, showError: Boolean, errorsList: Array<String> }, Array<String> -> String
export const getInputState = (
  { dirty, hasErrors, errors, rawValue },
  errorsToCheck = []
) => {
  if (!dirty) {
    return INPUT_STATE_NEUTRAL;
  } else if (
    (dirty && rawValue === "") ||
    (hasErrors && errorsToCheck.some(error => errors.includes(error)))
  ) {
    return INPUT_STATE_INVALID;
  }
  return INPUT_STATE_VALID;
};
