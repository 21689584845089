import {
  createFormState,
  required,
  onlyIntegers,
  hasLength,
  validateWhen,
  matchesRegex
} from "redux-freeform";

//TODO: Will make zip code able to have more than 5 digits once we add in the FormattedInput because it will have issues with format of 60606-1111.

const formConfig = {
  street1: {
    validators: [required()]
  },
  street2: {
    validators: []
  },
  city: {
    validators: [required()]
  },
  stateProvince: {
    validators: [required()]
  },
  country: {
    defaultValue: "US",
    validators: [required()]
  },
  zip: {
    validators: [
      required(),
      validateWhen(
        validateWhen(hasLength(5, 5), hasLength(0, 5)),
        matchesRegex("US"),
        "country"
      ),
      validateWhen(
        validateWhen(hasLength(9, 9), hasLength(6, 9)),
        matchesRegex("US"),
        "country"
      )
    ],
    constraints: [
      validateWhen(onlyIntegers(), matchesRegex("US"), "country"),
      validateWhen(hasLength(0, 9), matchesRegex("US"), "country")
    ]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
