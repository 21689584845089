import React from "react";

const AccountWaterIcon = ({ color }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9627 35.3015C26.7537 36.0805 25.4328 36.47 24 36.47C22.5672 36.47 21.2463 36.0805 20.0373 35.3015C18.8284 34.5225 17.8731 33.4676 17.1717 32.1368C16.4702 30.8061 16.1194 29.3617 16.1194 27.8037C16.1194 26.5378 16.3881 25.2882 16.9254 24.0548C17.2537 23.3082 17.8806 22.2047 18.806 20.744C19.7313 19.2834 20.4328 18.1149 20.9104 17.2386C21.6567 15.778 22.2537 14.2362 22.7015 12.6133C22.8209 12.1589 23.0672 11.8424 23.4403 11.6639C23.8134 11.4854 24.1866 11.4854 24.5597 11.6639C24.9328 11.8424 25.1791 12.1589 25.2985 12.6133C25.7463 14.2362 26.3582 15.7942 27.1343 17.2873C27.5821 18.1636 28.2686 19.324 29.194 20.7684C30.1194 22.2128 30.7462 23.3082 31.0746 24.0548C31.6119 25.2557 31.8806 26.5054 31.8806 27.8037C31.8806 29.3617 31.5298 30.8061 30.8283 32.1368C30.1268 33.4676 29.1716 34.5225 27.9627 35.3015ZM21.4925 32.6237C22.2686 33.1106 23.1045 33.354 24 33.354C24.2089 33.354 24.3806 33.281 24.5149 33.1349C24.6492 32.9889 24.7164 32.8022 24.7164 32.575C24.7164 32.3478 24.6492 32.1612 24.5149 32.0151C24.3806 31.8691 24.2089 31.796 24 31.796C23.0149 31.796 22.1716 31.4146 21.4701 30.6519C20.7687 29.8891 20.4179 28.9722 20.4179 27.9011C20.4179 27.6739 20.3507 27.4872 20.2164 27.3412C20.0821 27.1951 19.9104 27.1221 19.7015 27.1221C19.4925 27.1221 19.3209 27.1951 19.1866 27.3412C19.0522 27.4872 18.9851 27.6739 18.9851 27.9011C18.9851 28.8748 19.209 29.7836 19.6567 30.6275C20.1045 31.4715 20.7164 32.1368 21.4925 32.6237Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default AccountWaterIcon;
