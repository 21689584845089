import React, { useState, useLayoutEffect } from "react";
import { util } from "@thecb/components";

const DEFAULT_WIDTH = 100;
const DEFAULT_HEIGHT = 100;
const MAX_CONTENT_WIDTH = 1348;

const ResponsiveIframe = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  horizontalPad = 0,
  ...props
}) => {
  const calculateWindowWidth = () => document.body.clientWidth - horizontalPad;
  const maxWidth = Math.min(width, MAX_CONTENT_WIDTH);
  const maxHeight = maxWidth * (height / width);
  const [windowWidth, setWindowWidth] = useState(() => calculateWindowWidth());
  const [iframeWidth, setIframeWidth] = useState(maxWidth);
  const [iframeHeight, setIframeHeight] = useState(maxHeight);

  const { throttle } = util.general;

  const onResize = throttle(25, () => setWindowWidth(calculateWindowWidth()));

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useLayoutEffect(() => {
    const windowLargerThanIframe = windowWidth > maxWidth;
    const newWidth = windowLargerThanIframe ? maxWidth : windowWidth;
    if (newWidth !== iframeWidth) {
      setIframeWidth(newWidth);
      setIframeHeight(newWidth * (maxHeight / maxWidth));
    }
  }, [windowWidth]);

  return <iframe {...props} width={iframeWidth} height={iframeHeight} />;
};

export default ResponsiveIframe;
