import "babel-polyfill";
import dayjs from "dayjs";

import { createStore, combineReducers, compose, applyMiddleware } from "redux";

import { createBrowserHistory } from "history";
import {
  connectRouter,
  routerMiddleware,
  onLocationChanged
} from "connected-react-router";
import createAuthState from "@thecb/redux-auth-refresh";
import globalReducer, {
  updateRouterHistory,
  REFRESH_TOKEN_EXPIRED,
  LOGOUT,
  CLEAR_STATE,
  ADMIN_LOGOUT,
  GUEST_CHECKOUT
} from "./reducer";
import WorkflowReducer from "../renderer/reducers";
import { reducer as checkoutReducer } from "../apps/checkout/pages/payment/Payment.state";
import { reducer as registrationReducer } from "../apps/profile/pages/registration/Registration.state";
import { reducer as loginReducer } from "../apps/profile/pages/login/Login.state";
import { reducer as adminLoginReducer } from "../apps/profile/pages/admin-login/AdminLogin.state";
import { reducer as accountVerificationReducer } from "../apps/profile/pages/account-verification/AccountVerification.state";
import { reducer as forgotPasswordReducer } from "../apps/profile/pages/forgot-password/ForgotPassword.state";
import { reducer as resetPasswordReducer } from "../apps/profile/pages/reset-password/ResetPassword.state";
import { reducer as cartReducer } from "../apps/checkout/pages/multi-cart/state/ShoppingCart.state";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import {
  workflowInitialStateEpic,
  workflowRouterChangeScreenEpic,
  workflowRouterResetStateRoutingEpic,
  resetDependentBoundDataEpic,
  updateWorkflowPageTitleEpic
} from "./epics/workflow";

import {
  submitLoginFormEpic,
  logoutEpic,
  onRefreshTokenExpireEpic
} from "../apps/profile/pages/login/Login.epics";
import {
  submitAdminLoginFormEpic,
  adminLogoutEpic,
  onAdminRefreshTokenExpireEpic
} from "../apps/profile/pages/admin-login/AdminLogin.epics";
import PaymentEpic from "../apps/checkout/pages/payment/Payment.epics";
import CartEpic from "../apps/checkout/pages/multi-cart/state/ShoppingCart.epics";
import MultiCartEpic from "../apps/checkout/pages/multi-cart/state/MultiCart.epics";
import { submitRegistrationEpic } from "../apps/profile/pages/registration/Registration.epics";
import {
  loadLocalStorageEpic,
  clearLocalStorageEpic,
  clearLocalStorageAndRedirectEpic
} from "./epics/localStorage";
import { checkoutLocalStorageKey } from "../apps/checkout/pages/payment";
import { resetErrorStateEpic, lookupEpic } from "./epics/workflow/lookup";
import { searchEpic } from "./epics/workflow/graphql";
import { restApiCallEpic } from "./epics/workflow/rest-api-call";

import {
  initializeTenantEpic,
  fetchThemeEpic,
  fetchSubClientConfigurationEpic
} from "./epics/themeRequests";
import { fetchWorkflowEpic } from "./epics/workflowRequests";

import {
  retrieveCMSContentEpic,
  retrieveRelatedContentEpic,
  retrieveSearchResultsEpic,
  retrieveActionPagesEpic,
  retrieveSectionPagesEpic,
  retrieveSubjectPagesEpic
} from "../apps/web/pages/cms/CMS.epics";
import { reducer as cmsReducer } from "../apps/web/pages/cms/CMS.state";

import { Notifier } from "@airbrake/browser";
import airbrakeMiddleware from "redux-airbrake";

import { googleAnalytics } from "./middlewares/reactGAMiddlewares";

import forgotPasswordSubmitEpic from "../apps/profile/pages/forgot-password/ForgotPassword.epic";
import resetPasswordSubmitEpic from "../apps/profile/pages/reset-password/ResetPassword.epic";

import { trackLocationChange } from "../util/routingEpics";

import {
  activateUserEpic,
  resendVerificationEpic
} from "../apps/profile/pages/account-verification/AccountVerification.epics";

// Auth Middleware Config
import { createQuery } from "../util/graphql";
import CreateAccessToken from "../apps/profile/pages/login/graphql/CreateAccessToken.graphql";
import { LOGIN_SUCCESS } from "../apps/profile/pages/login/Login.state";
import { LOGIN_SUCCESS as ADMIN_LOGIN_SUCCESS } from "../apps/profile/pages/admin-login/AdminLogin.state";
import { refreshTokenExpired } from "./reducer";
import {
  authStoreKey,
  getGraphqlServiceEndpoint,
  getClientSlug,
  profileState,
  adminStoreKey
} from "../util/state";
import { dissoc } from "ramda";

const {
  createMiddleware: createAuthMiddleware,
  reducer: authReducer
} = createAuthState(authStoreKey);
const {
  createMiddleware: createAdminMiddleware,
  reducer: adminReducer
} = createAuthState(adminStoreKey);
const { epic: profileEpic, reducer: profileReducer } = profileState;

const locationHistoryEpic = trackLocationChange(updateRouterHistory);

const rootEpic = combineEpics(
  workflowInitialStateEpic,
  initializeTenantEpic,
  fetchSubClientConfigurationEpic,
  fetchThemeEpic,
  fetchWorkflowEpic,
  workflowRouterChangeScreenEpic,
  updateWorkflowPageTitleEpic,
  workflowRouterResetStateRoutingEpic,
  resetDependentBoundDataEpic,
  lookupEpic,
  searchEpic,
  resetErrorStateEpic,
  logoutEpic,
  PaymentEpic,
  CartEpic,
  MultiCartEpic,
  restApiCallEpic,
  submitRegistrationEpic,
  profileEpic,
  loadLocalStorageEpic,
  clearLocalStorageEpic,
  clearLocalStorageAndRedirectEpic,
  submitLoginFormEpic,
  onRefreshTokenExpireEpic,
  forgotPasswordSubmitEpic,
  resetPasswordSubmitEpic,
  activateUserEpic,
  resendVerificationEpic,
  locationHistoryEpic,
  retrieveCMSContentEpic,
  submitAdminLoginFormEpic,
  adminLogoutEpic,
  onAdminRefreshTokenExpireEpic,
  retrieveRelatedContentEpic,
  retrieveSearchResultsEpic,
  retrieveActionPagesEpic,
  retrieveSectionPagesEpic,
  retrieveSubjectPagesEpic
);
const epicMiddleware = createEpicMiddleware();

const authConfig = {
  getAccessTokenRequest: (state, refreshToken) =>
    createQuery(getGraphqlServiceEndpoint(state), getClientSlug(state))(
      CreateAccessToken,
      {
        refreshToken,
        scopes: []
      }
    ),
  getAccessTokenFromResponse: res => res.createAccessToken.key,
  onRefreshTokenExpire: refreshTokenExpired,
  actionsToIgnore: [REFRESH_TOKEN_EXPIRED]
};
const adminAuthConfig = {
  ...authConfig,
  getAccessTokenRequest: (state, refreshToken) =>
    createQuery(getGraphqlServiceEndpoint(state), getClientSlug(state))(
      CreateAccessToken,
      {
        refreshToken,
        scopes: ["CITYBASE_CUSTOMER_MANAGEMENT"]
      }
    )
};
const authMiddleware = createAuthMiddleware(authConfig);
const adminMiddleware = createAdminMiddleware(adminAuthConfig);

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    })) ||
  compose;

export const history = createBrowserHistory();

const clearProfileStateOn = [LOGOUT, REFRESH_TOKEN_EXPIRED, CLEAR_STATE];

const rootReducer = combineReducers({
  router: connectRouter(history),
  global: globalReducer,
  workflow: WorkflowReducer,
  checkout: checkoutReducer,
  registration: registrationReducer,
  login: loginReducer,
  admin: adminLoginReducer,
  accountVerification: accountVerificationReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  config: (s = null) => s,
  ...profileReducer(clearProfileStateOn),
  ...adminReducer({
    refreshTokenReceived: ADMIN_LOGIN_SUCCESS,
    getRefreshTokenFromResponse: res => res.refreshToken,
    clearTokensOn: [ADMIN_LOGOUT, CLEAR_STATE]
  }),
  ...authReducer({
    refreshTokenReceived: LOGIN_SUCCESS,
    getRefreshTokenFromResponse: res => res.refreshToken,
    clearTokensOn: [LOGOUT, CLEAR_STATE, GUEST_CHECKOUT]
  }),
  cms: cmsReducer,
  cart: cartReducer
});

const saveState = state => {
  const expireDateTime = dayjs()
    .add(12, "hour")
    .toISOString();
  const globalState = JSON.stringify(state.global.localStorage);
  const serializedCheckout = JSON.stringify(state.checkout.localStorage);
  const serializedSession = JSON.stringify({
    isExpiredSession: state.checkout.isExpiredSession,
    expiredURL: state.checkout.expiredURL,
    expireDateTime
  });
  const cartConfigWithoutVisitId = dissoc("visitId", state?.cart?.config ?? {});
  const serializedCartState = JSON.stringify({
    ...{
      ...state.cart,
      config: cartConfigWithoutVisitId
    },
    cartSliderOpen: false
  });
  const serializedLaunchDarklyFlagsState = JSON.stringify(
    state.global.launchDarkly.flags
  );
  localStorage.setItem("global", globalState);
  localStorage.setItem(checkoutLocalStorageKey, serializedCheckout);
  localStorage.setItem("session", serializedSession);
  localStorage.setItem("cart", serializedCartState);
  localStorage.setItem("launchDarklyFlags", serializedLaunchDarklyFlagsState);
};

// THIS SHOULD ONLY GET CALLED ONCE
const createNavStore = config => {
  const { environment } = config;
  let errorMiddleware;

  if (environment === "local") {
    // Have errorMiddleware do nothing if local
    errorMiddleware = _store => next => action => next(action);
  } else {
    const airbrake = new Notifier({
      projectId: config.airbrakeProjectId,
      projectKey: config.airbrakeProjectKey
    });

    airbrake.addFilter(notice => {
      if (environment === undefined) {
        return null;
      }

      notice.context.environment = environment;

      return notice;
    });

    errorMiddleware = airbrakeMiddleware(airbrake, {
      noticeAnnotations: { context: { environment: config.environment } }
    });
  }

  const store = createStore(
    rootReducer,
    { config },
    composeEnhancers(
      applyMiddleware(
        adminMiddleware,
        authMiddleware,
        epicMiddleware,
        routerMiddleware(history),
        errorMiddleware,
        googleAnalytics
      )
    )
  );
  epicMiddleware.run(rootEpic);
  adminMiddleware.run(store);
  authMiddleware.run(store);
  store.subscribe(() => {
    saveState(store.getState());
  });
  const syncHistoryToStore = (location, action) =>
    store.dispatch(onLocationChanged(location, action));
  history.listen(syncHistoryToStore);
  syncHistoryToStore(history.location, history.action);
  return store;
};

export default createNavStore;
