import React, { forwardRef } from "react";
import styled from "styled-components";
import { Box } from "../../atoms/layouts";
import ButtonWithAction from "../../atoms/button-with-action";

const StyledFilterContainer = styled(Box)`
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  padding: 0;
`;

export const FilterContainer = forwardRef((props, ref) => (
  <StyledFilterContainer ref={ref} {...props} />
));

const StyledFilterDropdown = styled(Box)`
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  width: 18.4rem;
  background-color: white;
  z-index: 1000;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 0px 0px rgba(41, 42, 51, 0.1),
    0px 2px 4px 0px rgba(41, 42, 51, 0.2), 0px 1px 2px 0px rgba(41, 42, 51, 0.1);
  padding: 0;
  max-width: 18.625rem;
`;

export const FilterDropdownContainer = forwardRef((props, ref) => (
  <StyledFilterDropdown ref={ref} {...props} />
));

const FilterButton = styled(ButtonWithAction)`
  min-width: auto;
  min-height: 2.3125rem;
  margin: 0;
  padding: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 2px 0px rgba(41, 42, 51, 0.1);
  ${({ opened, textColor, textHoverColor }) => `
    color: ${opened ? textHoverColor : textColor};
    :hover,
    :active,
    :focus {
      color: ${textHoverColor};
      > * {
        color: ${textHoverColor};
      }
    }
  `}
  ${({ opened, backgroundColor, backgroundHoverColor }) => `
    background-color: ${opened ? backgroundHoverColor : backgroundColor};  
    :hover,
    :active,
    :focus {
      background-color: ${backgroundHoverColor};
    }
  `}
`;

export const StyledFilterButton = forwardRef((props, ref) => (
  <FilterButton ref={ref} {...props} />
));
