import React, { useContext, useCallback } from "react";
import {
  constants,
  Box,
  ButtonWithAction,
  ExternalLink,
  InternalLink,
  Paragraph,
  Stack,
  Text,
  Title,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import { isExternalLink } from "../../../../util/general";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { useNavigate } from "react-router-dom";
import RightArrowIcon from "../../../icons/RightArrowIcon";

const desktopMargin = 22;
const mobileMargin = 16;

const desktopDimensions = {
  cardHeight: 219,
  cardWidth: 294,
  cardMargin: desktopMargin
};

const mobileDimensions = {
  cardHeight: 189,
  cardWidth: window.innerWidth - 42,
  cardMargin: mobileMargin
};

export const getActionCardDimensions = isMobile => {
  return isMobile ? mobileDimensions : desktopDimensions;
};

const GridCard = ({
  type,
  title,
  description,
  href,
  linkText,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const navigate = useNavigate();
  const linkFontSize = isMobile ? "14px" : "18px";
  const descriptionBottomMargin = isMobile ? "48px" : "64px";
  const { cardWidth } = getActionCardDimensions(isMobile);

  const ellipsisStyling = `display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;`;

  // why style box as flex column instead of adding a Stack inside of the Box?
  // Within the content grid, the activity card height expands to match the grid row height. Need to ensure
  // that the link at the bottom of card is positioned based on that height (and not the card height
  // based on content)
  // The additional div (with a Stack inside of a Box) aligns link based on content height.
  const buttonContentContainerStyling = `
    border-top: 4px solid ${themeValues.linkColor};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  // Actual color definitons are from ButtonWithAction.Theme. These hardcoded
  // values are from devTools.
  const hoverStyles = `
    outline: none;
    background-color: rgb(225,230,249);
    border-color: ${themeValues.linkColor};
    color: ${themeValues.linkColor};
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(41, 42, 51, 0.15), 0px 1px 7px rgba(41, 42, 51, 0.2), 0px 7px 12px rgba(41, 42, 51, 0.15);
  `;

  const activeStyles = `
    outline: none;
    background-color: rgb(196,205,244);
    border-color: ${themeValues.linkColor};
    color: ${themeValues.linkColor};
    text-decoration: none;
    box-shadow: 0px 4px 7px rgba(41, 42, 51, 0.3), 0px 5px 12px rgba(41, 42, 51, 0.4), 0px 7px 12px rgba(41, 42, 51, 0.2);
  `;

  const externalLink = isExternalLink(href);
  const navigateTo = useCallback(
    () => (externalLink ? (window.location.href = href) : navigate(href)),
    [href]
  );

  return (
    <ButtonWithAction
      variant="ghost"
      contentOverride
      action={navigateTo}
      hoverStyles={hoverStyles}
      activeStyles={activeStyles}
      extraStyles={`text-align: left; height: 100%; padding:0; min-width: ${cardWidth}px; margin-top: 0; margin-left: 0;`}
    >
      <Box
        borderRadius={4}
        maxWidth={`${cardWidth}px`}
        minWidth={`${cardWidth}px`}
        minHeight="100%"
        extraStyles={buttonContentContainerStyling}
        padding={isMobile ? "16px" : "24px"}
        background={constants.colors.WHITE}
        boxShadow="0px 3px 6px -1px rgba(41, 42, 51, 0.33), 0px 1px 2px -2px rgba(41, 42, 51, 0.1), inset 0px 1px 0px #E5E7EC"
      >
        <Stack childGap="0.5rem">
          <Text
            color={constants.colors.STORM_GREY}
            variant="pS"
            extraStyles={`text-transform: uppercase; min-height:0.875rem;`}
          >
            {type}
          </Text>
          <Title
            variant="small"
            as="h3"
            weight={FONT_WEIGHT_SEMIBOLD}
            color={constants.colors.CHARADE_GREY}
            margin="0.5rem 0"
            extraStyles={ellipsisStyling}
          >
            {title}
          </Title>
          <Paragraph
            color={constants.colors.STORM_GREY}
            extraStyles={`
              margin-bottom: ${descriptionBottomMargin}; 
              ${ellipsisStyling}`}
          >
            {description}
          </Paragraph>
        </Stack>
        {externalLink ? (
          <ExternalLink
            href={href}
            fontSize={linkFontSize}
            fontWeight={FONT_WEIGHT_SEMIBOLD}
            extraStyles={`align-self: flex-end; color: ${themeValues.linkColor}; display: flex; align-items: center;`}
          >
            {linkText}&nbsp;
            <RightArrowIcon size="22" color={themeValues.linkColor} />
          </ExternalLink>
        ) : (
          <InternalLink
            to={href}
            fontSize={linkFontSize}
            fontWeight={FONT_WEIGHT_SEMIBOLD}
            extraStyles={`align-self: flex-end; color: ${themeValues.linkColor}; display: flex; align-items: center;`}
          >
            {linkText}&nbsp;
            <RightArrowIcon size="22" color={themeValues.linkColor} />
          </InternalLink>
        )}
      </Box>
    </ButtonWithAction>
  );
};

export default withWindowSize(GridCard);
