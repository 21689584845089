import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableHeading
} from "@thecb/components";
import { TransactionRow } from "./TransactionRow";
import { NoTransactionsFound } from "./NoTransactionsFound";
import {
  HEADING_ACCOUNT,
  HEADING_AMOUNT,
  HEADING_DATE,
  HEADING_TRANSACTION_ID,
  HEADING_PAYMENT_METHOD
} from "/constants/transaction_history_constants";
import { GHOST_GREY, WHITE } from "/constants/colors";
import { moveTo } from "/util/general";
import {
  flattenObligations,
  obligationForTransaction
} from "/util/transactionHistory";
import { configureEndpoint } from "/util/router-utils";

export const TransactionTable = ({
  isMobile,
  navigateToDetailedObligation,
  obligations,
  obligationsConfig,
  customerManagement,
  setDetailedObligation,
  transactions
}) => {
  const flatObligations = flattenObligations(obligations);

  const flatTransactions = Object.values(transactions);

  const onAccountClick = obligation => {
    setDetailedObligation(
      obligation.obligations,
      obligation.config,
      obligation.id
    );
    navigateToDetailedObligation(
      `/profile/accounts/details/${obligation.config.obligationSlug}`
    );
  };

  const navigate = useNavigate();
  const onTransactionClick = transaction => {
    navigate(
      configureEndpoint(
        customerManagement,
        `/profile/history/transaction/${transaction.id}`
      )
    );
  };

  const defaultHeadings = [
    HEADING_ACCOUNT,
    HEADING_DATE,
    HEADING_PAYMENT_METHOD,
    HEADING_TRANSACTION_ID,
    HEADING_AMOUNT
  ];

  const [headings, setHeadings] = useState(defaultHeadings);

  useEffect(() => {
    if (isMobile) {
      // In mobile, we re-order the headings so "Amount" comes after "Date".
      const fromIndex = headings.indexOf(HEADING_AMOUNT);
      const toIndex = headings.indexOf(HEADING_DATE) + 1;
      const mobileHeadings = moveTo(headings, fromIndex, toIndex);
      setHeadings(mobileHeadings);
    } else {
      setHeadings(defaultHeadings);
    }
  }, [isMobile]);

  return (
    <Box
      background={WHITE}
      borderRadius="4px"
      boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
      padding="0"
    >
      <Table>
        <TableHead>
          {headings.map((heading, index) => (
            <TableHeading
              padding={isMobile ? "12px 16px" : "16px 24px"}
              key={index}
            >
              {heading}
            </TableHeading>
          ))}
        </TableHead>
        <TableBody>
          {flatTransactions.length > 0 ? (
            flatTransactions.map(transaction => (
              <TransactionRow
                headings={headings}
                isMobile={isMobile}
                key={transaction.id}
                obligation={obligationForTransaction({
                  transaction,
                  obligations: flatObligations
                })}
                onAccountClick={onAccountClick}
                onTransactionClick={onTransactionClick}
                transaction={transaction}
                obligationsConfig={obligationsConfig}
              />
            ))
          ) : (
            <NoTransactionsFound colSpan={headings.length} />
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
