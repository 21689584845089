import React from "react";

const AccountElectricIcon = ({ color }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill={color}
    />
    <path
      d="M29.8438 19.2297V14.3844C29.8438 14.0094 29.7068 13.685 29.4328 13.411C29.1588 13.137 28.8343 13 28.4594 13C28.0844 13 27.76 13.137 27.486 13.411C27.212 13.685 27.075 14.0094 27.075 14.3844V19.2297H29.8438ZM21.5375 19.2297V14.3844C21.5375 14.0094 21.4005 13.685 21.1265 13.411C20.8525 13.137 20.5281 13 20.1531 13C19.7782 13 19.4537 13.137 19.1797 13.411C18.9057 13.685 18.7687 14.0094 18.7687 14.3844V19.2297H21.5375ZM25.6906 35.15V30.8671C27.2769 30.5498 28.5964 29.7567 29.6491 28.4877C30.7018 27.2187 31.2281 25.7478 31.2281 24.075V22.6906H31.9203C32.1222 22.6906 32.288 22.6257 32.4178 22.4959C32.5476 22.3662 32.6125 22.2003 32.6125 21.9984V20.6141C32.6125 20.4122 32.5476 20.2463 32.4178 20.1166C32.288 19.9868 32.1222 19.9219 31.9203 19.9219H16.6922C16.4903 19.9219 16.3245 19.9868 16.1947 20.1166C16.0649 20.2463 16 20.4122 16 20.6141V21.9984C16 22.2003 16.0649 22.3662 16.1947 22.4959C16.3245 22.6257 16.4903 22.6906 16.6922 22.6906H17.3844V24.075C17.3844 25.7478 17.9107 27.2187 18.9634 28.4877C20.0161 29.7567 21.3356 30.5498 22.9219 30.8671V35.15H25.6906Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default AccountElectricIcon;
