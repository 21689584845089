import AddressForm from "./AddressForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./AddressForm.state";

AddressForm.reducer = reducer;
AddressForm.mapStateToProps = mapStateToProps;
AddressForm.mapDispatchToProps = mapDispatchToProps;
export default AddressForm;
