import React from "react";

const RefundIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -496.000000)"
        fill="#317D4F"
        fillRule="nonzero"
      >
        <path
          d="M80,528 C88.836556,528 96,520.836556 96,512 C96,503.163444 88.836556,496 80,496 C71.163444,496 64,503.163444 64,512 C64,520.836556 71.163444,528 80,528 Z M82.3230241,504.25 L82.3230241,505.928718 C84.4879725,506.299892 85.8024055,507.750844 85.7766323,509.497047 L85.7766323,509.497047 L83.242268,509.539226 C83.0876289,508.459448 82.5893471,507.801458 81.266323,507.801458 C79.9003436,507.801458 79.0584192,508.408833 79.0584192,509.294589 C79.0584192,510.003194 79.4536082,510.357496 80.8024055,510.703362 L80.8024055,510.703362 L82.8041237,511.184201 C85.0635739,511.715654 86,513.048506 86,514.879067 C86,516.718065 84.5652921,518.000301 82.2714777,518.295553 L82.2714777,518.295553 L82.2714777,519.805556 L80.1065292,519.805556 L80.1065292,518.303989 C77.7525773,518.04248 76.2319588,516.895216 76,514.760966 L76,514.760966 L78.6116838,514.710352 C78.8780069,515.823873 79.8402062,516.414377 81.2147766,516.414377 C82.45189,516.414377 83.3367698,515.764823 83.3367698,514.895939 C83.3367698,513.993312 82.7611684,513.622138 81.5498282,513.352193 L81.5498282,513.352193 L79.5137457,512.921969 C77.6151203,512.54236 76.395189,511.454146 76.3694158,509.581405 C76.3522337,507.590564 77.9329897,506.249277 80.1494845,505.920282 L80.1494845,505.920282 L80.1494845,504.25 L82.3230241,504.25 Z M73.5,508.540312 C73.7761424,508.540312 74,508.76417 74,509.040312 L74,509.040312 L74,514.959688 C74,515.073223 73.9613596,515.183379 73.8904344,515.272035 C73.7179296,515.487666 73.4032834,515.522627 73.1876525,515.350122 L73.1876525,515.350122 L69.488043,512.390434 C69.4592107,512.367369 69.433022,512.34118 69.4099561,512.312348 C69.2374514,512.096717 69.272412,511.78207 69.488043,511.609566 L69.488043,511.609566 L73.1876525,508.649878 C73.276309,508.578953 73.3864643,508.540312 73.5,508.540312 Z"
          id="status-icon---refund"
        ></path>
      </g>
    </g>
  </svg>
);

export default RefundIcon;
