import React from "react";
import styled from "styled-components";

/* eslint-disable no-unused-vars */
export const ClusterWrapper = styled(({ overflow, ...props }) => (
  <div {...props} />
))`
  overflow: ${({ overflow }) => (overflow ? "visible" : "hidden")};
  box-sizing: border-box;
  justify-self: ${({ $justifySelf }) => $justifySelf};
  align-self: ${({ $alignSelf }) => $alignSelf};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  ${({ $extraStyles }) => $extraStyles};
`;

export const ClusterInnerWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${({ $nowrap }) => ($nowrap ? "nowrap" : "wrap")};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  margin: calc(${({ childGap }) => childGap} / 2 * -1);
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  > * {
    margin: calc(${({ childGap }) => childGap} / 2);
  }
`;
