import React from "react";

const IconAdd = ({ strokeWidth = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className="icon"
  >
    <defs>
      <path
        id="path-1"
        d="M7.91666623 4.78508747L4.78508747 4.78508747 4.78508747 7.91666623 3.74122788 7.91666623 3.74122788 4.78508747 0.609649123 4.78508747 0.609649123 3.74122788 3.74122788 3.74122788 3.74122788 0.609649123 4.78508747 0.609649123 4.78508747 3.74122788 7.91666623 3.74122788z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth={strokeWidth}>
      <g transform="translate(-407 -563)">
        <g transform="translate(408 562)">
          <g transform="translate(0 2)">
            <g transform="translate(3.94 3.858)">
              <mask fill="#fff">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use className="fill" xlinkHref="#path-1"></use>
            </g>
            <circle
              className="stroke"
              strokeWidth={strokeWidth}
              cx="8.155"
              cy="8.072"
              r="8"
            ></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconAdd;
