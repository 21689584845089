import React from "react";
import {
  BORDER_RADIUS,
  FONT_SIZE
} from "../../../../constants/style_constants";
import { noop } from "../../../../util/general";
import ButtonWithAction from "../../../atoms/button-with-action/ButtonWithAction";

export const CloseButton = ({
  buttonExtraStyles = "",
  closeButtonText = "",
  closeButtonVariant = "primary",
  hideModal = noop,
  isMobile = false
}) => {
  const fontSize = `font-size: ${isMobile ? FONT_SIZE.XS : FONT_SIZE.SM};`;
  const width = isMobile ? "width: 100%;" : "";

  return (
    <ButtonWithAction
      action={hideModal}
      borderRadius={BORDER_RADIUS.MD}
      className="modal-close-button"
      dataQa={closeButtonText}
      extraStyles={`${buttonExtraStyles}; margin: 0; ${width}`}
      name={closeButtonText}
      role="button"
      text={closeButtonText}
      textExtraStyles={`${fontSize}`}
      variant={closeButtonVariant}
    />
  );
};

export default CloseButton;
