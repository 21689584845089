import React from "react";
import {
  BORDER_RADIUS,
  FONT_SIZE
} from "../../../../constants/style_constants";
import { noop } from "../../../../util/general";
import ButtonWithAction from "../../../atoms/button-with-action/ButtonWithAction";
import ButtonWithLink from "../../../atoms/button-with-link/ButtonWithLink";

export const ContinueButton = ({
  buttonExtraStyles = "",
  continueAction = noop,
  continueButtonText = "",
  continueURL = "",
  continueButtonVariant = "primary",
  isContinueActionDisabled = false,
  isLoading = false,
  isMobile = false,
  useDangerButton = false
}) => {
  const ContinueButtonAtom = continueURL ? ButtonWithLink : ButtonWithAction;
  const fontSize = `font-size: ${isMobile ? FONT_SIZE.XS : FONT_SIZE.SM};`;
  const width = isMobile ? "width: 100%;" : "";

  return (
    <ContinueButtonAtom
      action={continueAction}
      borderRadius={BORDER_RADIUS.MD}
      className="modal-continue-button"
      dataQa={continueButtonText}
      disabled={isContinueActionDisabled}
      extraStyles={`${buttonExtraStyles}; margin: 0; ${width}`}
      isLoading={isLoading}
      linkExtraStyles={`display: inline-block; ${width}`}
      name={continueButtonText}
      role="button"
      text={continueButtonText}
      textExtraStyles={`${fontSize}`}
      url={continueURL}
      variant={useDangerButton ? "danger" : continueButtonVariant}
    />
  );
};

export default ContinueButton;
