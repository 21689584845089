import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const WalletIconSmall = ({ themeValues, iconIndex = 0, colorOverride }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3125 6.94643C16.0446 6.70536 15.7232 6.57143 15.3482 6.57143L5.14283 6.57143C5.0089 6.57143 4.90176 6.54464 4.8214 6.46429C4.74104 6.38393 4.71426 6.27678 4.71426 6.14286C4.71426 6.03571 4.74104 5.92857 4.8214 5.84821C4.90176 5.76786 5.0089 5.71428 5.14283 5.71428L15.4285 5.71428C15.5357 5.71428 15.6428 5.6875 15.7232 5.60714C15.8035 5.52678 15.8571 5.41964 15.8571 5.28571C15.8571 4.9375 15.7232 4.64286 15.4821 4.375C15.2143 4.13393 14.9196 4 14.5714 4L4.71426 4C4.23211 4 3.80354 4.1875 3.48211 4.50893C3.16068 4.83036 2.99997 5.23214 2.99997 5.71428V14.2857C2.99997 14.7679 3.16068 15.1964 3.48211 15.5179C3.80354 15.8393 4.23211 16 4.71426 16L15.3482 16C15.7232 16 16.0446 15.8929 16.3125 15.625C16.5803 15.3839 16.7143 15.0893 16.7143 14.7143V7.85714C16.7143 7.50893 16.5803 7.21428 16.3125 6.94643ZM12.7142 13.143C13.661 13.143 14.4285 12.3755 14.4285 11.4288C14.4285 10.482 13.661 9.71447 12.7142 9.71447C11.7674 9.71447 10.9999 10.482 10.9999 11.4288C10.9999 12.3755 11.7674 13.143 12.7142 13.143Z"
        fill={colorOverride || themeValues.singleIconColor}
      />
      <mask
        id={`mask0_503_574-${iconIndex}`}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="4"
        width="14"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3125 6.94643C16.0446 6.70536 15.7232 6.57143 15.3482 6.57143L5.14283 6.57143C5.0089 6.57143 4.90176 6.54464 4.8214 6.46429C4.74104 6.38393 4.71426 6.27678 4.71426 6.14286C4.71426 6.03571 4.74104 5.92857 4.8214 5.84821C4.90176 5.76786 5.0089 5.71428 5.14283 5.71428L15.4285 5.71428C15.5357 5.71428 15.6428 5.6875 15.7232 5.60714C15.8035 5.52678 15.8571 5.41964 15.8571 5.28571C15.8571 4.9375 15.7232 4.64286 15.4821 4.375C15.2143 4.13393 14.9196 4 14.5714 4L4.71426 4C4.23211 4 3.80354 4.1875 3.48211 4.50893C3.16068 4.83036 2.99997 5.23214 2.99997 5.71428V14.2857C2.99997 14.7679 3.16068 15.1964 3.48211 15.5179C3.80354 15.8393 4.23211 16 4.71426 16L15.3482 16C15.7232 16 16.0446 15.8929 16.3125 15.625C16.5803 15.3839 16.7143 15.0893 16.7143 14.7143V7.85714C16.7143 7.50893 16.5803 7.21428 16.3125 6.94643ZM12.7142 13.143C13.661 13.143 14.4285 12.3755 14.4285 11.4288C14.4285 10.482 13.661 9.71447 12.7142 9.71447C11.7674 9.71447 10.9999 10.482 10.9999 11.4288C10.9999 12.3755 11.7674 13.143 12.7142 13.143Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_503_574-${iconIndex})`}>
        <rect
          width="20"
          height="20"
          fill={colorOverride || themeValues.singleIconColor}
        />
      </g>
    </svg>
  );
};

export default themeComponent(
  WalletIconSmall,
  "Icons",
  fallbackValues,
  "primary"
);
