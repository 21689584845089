import React from "react";

const PropertyCommercialVehicleIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill="#3B5BDB"
    />
    <path
      d="M30.7656 35.15C31.9193 35.15 32.8999 34.7462 33.7074 33.9387C34.515 33.1311 34.9188 32.1505 34.9188 30.9969H36.9953C37.1972 30.9969 37.363 30.932 37.4928 30.8022C37.6226 30.6724 37.6875 30.5066 37.6875 30.3047V28.9203C37.6875 28.7184 37.6226 28.5526 37.4928 28.4228C37.363 28.293 37.1972 28.2281 36.9953 28.2281H36.3031V23.5559C36.3031 22.979 36.1012 22.4887 35.6975 22.085L31.3713 17.7588C30.9675 17.355 30.4772 17.1531 29.9004 17.1531H27.9969V15.0766C27.9969 14.4997 27.795 14.0094 27.3912 13.6057C26.9874 13.2019 26.4971 13 25.9203 13H12.0766C11.4997 13 11.0094 13.2019 10.6057 13.6057C10.2019 14.0094 10 14.4997 10 15.0766V28.9203C10 29.4971 10.2019 29.9874 10.6057 30.3912C11.0094 30.795 11.4997 30.9969 12.0766 30.9969H12.7688C12.7688 32.1505 13.1725 33.1311 13.9801 33.9387C14.7876 34.7462 15.7682 35.15 16.9219 35.15C18.0755 35.15 19.0561 34.7462 19.8637 33.9387C20.6712 33.1311 21.075 32.1505 21.075 30.9969H26.6125C26.6125 32.1505 27.0163 33.1311 27.8238 33.9387C28.6314 34.7462 29.612 35.15 30.7656 35.15ZM34.2266 24.075H27.9969V19.2297H29.9004L34.2266 23.5559V24.075ZM16.9219 33.0734C16.3451 33.0734 15.8548 32.8715 15.451 32.4678C15.0472 32.064 14.8453 31.5737 14.8453 30.9969C14.8453 30.4201 15.0472 29.9298 15.451 29.526C15.8548 29.1222 16.3451 28.9203 16.9219 28.9203C17.4987 28.9203 17.989 29.1222 18.3928 29.526C18.7965 29.9298 18.9984 30.4201 18.9984 30.9969C18.9984 31.5737 18.7965 32.064 18.3928 32.4678C17.989 32.8715 17.4987 33.0734 16.9219 33.0734ZM30.7656 33.0734C30.1888 33.0734 29.6985 32.8715 29.2947 32.4678C28.891 32.064 28.6891 31.5737 28.6891 30.9969C28.6891 30.4201 28.891 29.9298 29.2947 29.526C29.6985 29.1222 30.1888 28.9203 30.7656 28.9203C31.3424 28.9203 31.8327 29.1222 32.2365 29.526C32.6403 29.9298 32.8422 30.4201 32.8422 30.9969C32.8422 31.5737 32.6403 32.064 32.2365 32.4678C31.8327 32.8715 31.3424 33.0734 30.7656 33.0734Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default PropertyCommercialVehicleIcon;
