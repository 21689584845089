import React from "react";

const RejectedIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fillRule="none"
      fillule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -544.000000)"
        fill="#D11053"
      >
        <path
          d="M80,576 C88.836556,576 96,568.836556 96,560 C96,551.163444 88.836556,544 80,544 C71.163444,544 64,551.163444 64,560 C64,568.836556 71.163444,576 80,576 Z M76.7175144,553.282486 C76.9127766,553.087223 77.2293591,553.087223 77.4246212,553.282486 L78.131728,553.989592 C78.3269901,554.184855 78.3269901,554.501437 78.131728,554.696699 L73.8281068,558.999039 L89.5,559 C89.7761424,559 90,559.223858 90,559.5 L90,560.5 C90,560.776142 89.7761424,561 89.5,561 L73.8271068,560.999039 L78.131728,565.303301 C78.3269901,565.498563 78.3269901,565.815145 78.131728,566.010408 L77.4246212,566.717514 C77.2293591,566.912777 76.9127766,566.912777 76.7175144,566.717514 L70.3535534,560.353553 C70.1582912,560.158291 70.1582912,559.841709 70.3535534,559.646447 L76.7175144,553.282486 Z"
          id="status-icon---rejected"
        ></path>
      </g>
    </g>
  </svg>
);

export default RejectedIcon;
