import React from "react";

const ArrowUpCircleIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ArrowUpCircleIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52801 10.528C5.72315 10.7232 6.03949 10.7233 6.2348 10.5283L9.375 7.39375L9.375 14.5C9.375 14.7761 9.59886 15 9.875 15H10.125C10.4011 15 10.625 14.7761 10.625 14.5L10.625 7.39375L13.7652 10.5283C13.9605 10.7233 14.2768 10.7232 14.472 10.528L14.6464 10.3536C14.8417 10.1583 14.8417 9.84171 14.6464 9.64645L10.3536 5.35355C10.1583 5.15829 9.84171 5.15829 9.64645 5.35355L5.35355 9.64645C5.15829 9.84171 5.15829 10.1583 5.35355 10.3536L5.52801 10.528Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.52801 10.528C5.72315 10.7232 6.03949 10.7233 6.2348 10.5283L9.375 7.39375L9.375 14.5C9.375 14.7761 9.59886 15 9.875 15H10.125C10.4011 15 10.625 14.7761 10.625 14.5L10.625 7.39375L13.7652 10.5283C13.9605 10.7233 14.2768 10.7232 14.472 10.528L14.6464 10.3536C14.8417 10.1583 14.8417 9.84171 14.6464 9.64645L10.3536 5.35355C10.1583 5.15829 9.84171 5.15829 9.64645 5.35355L5.35355 9.64645C5.15829 9.84171 5.15829 10.1583 5.35355 10.3536L5.52801 10.528Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect
          x="2.5"
          y="17.5"
          width="15"
          height="15"
          transform="rotate(-90 2.5 17.5)"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ArrowUpCircleIconSmall;
