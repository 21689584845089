import React, { useContext, useEffect } from "react";
import * as R from "ramda";
import { push } from "connected-react-router";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeContext } from "styled-components";
import {
  Placeholder,
  Box,
  Stack,
  Obligation,
  AddObligation,
  Timeout,
  Loading,
  Title,
  Detail,
  withWindowSize,
  constants
} from "@thecb/components";
import AlertBar from "/components/alert-bar";
import { isAutoPayEnabled, retrieveAutoPayData } from "/util/general";
import { getNextAutopayDate as getDueDate } from "../../Profile.selectors";

const ProfileProperties = ({
  resourcesActions,
  alertBars,
  actions,
  autoPayData,
  obligationAssociations,
  canAddObligation,
  resources,
  onLogout
}) => {
  let navigate = useNavigate();
  const {
    createPaymentFromProfile,
    setDetailedObligation,
    deactivatePaymentSchedule,
    navigateToDetailedObligation,
    fetchObligations
  } = resourcesActions;
  const oblActions = {
    createPaymentFromProfile,
    setDetailedObligation,
    navigateToDetailedObligation
  };
  const { isMobile } = useContext(ThemeContext);
  const {
    achAccounts,
    addresses,
    creditCards,
    obligations,
    schedules
  } = resources;
  const getNextAutopayDate = getDueDate(schedules);
  const autoPayAvailable = R.and(
    R.or(R.not(R.isEmpty(achAccounts)), R.not(R.isEmpty(creditCards))),
    R.not(R.isEmpty(addresses))
  );
  const obligationsFetched = obligations?.isSuccess ?? false;
  useEffect(() => {
    if (!obligationsFetched) {
      fetchObligations();
    }
  }, [obligationsFetched]);
  const activeProperties = Object.entries(
    obligationAssociations?.properties?.active ?? {}
  ).map(([activeGroupName, associations]) => {
    const propertyObligations = associations.map(association => {
      if (association.config.collection) {
        return (
          <Obligation
            key={`${activeGroupName}-${association.id}-collection`}
            config={association.config}
            obligations={association.obligations}
            actions={oblActions}
            autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
            autoPayAvailable={autoPayAvailable}
            autoPaySchedule={
              retrieveAutoPayData(autoPayData, association.id).id
            }
            handleAutopayAction={() => navigate("set-up-autopay")}
            navigateToSettings={() => navigate("/profile/wallet")}
            deactivatePaymentSchedule={deactivatePaymentSchedule}
            isMobile={isMobile}
            nextAutopayDate={getNextAutopayDate(association.id)}
            obligationAssocID={association.id}
            dueDate={association.dueDate}
            agencyName={activeGroupName}
          />
        );
      }
      return association.obligations.map(obl => (
        <Obligation
          key={`${activeGroupName}-${association.id}-${obl.id}`}
          config={association.config}
          obligations={[obl]}
          actions={oblActions}
          autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
          autoPayAvailable={autoPayAvailable}
          autoPaySchedule={retrieveAutoPayData(autoPayData, association.id).id}
          handleAutopayAction={() => navigate("set-up-autopay")}
          navigateToSettings={() => navigate("/profile/wallet")}
          deactivatePaymentSchedule={deactivatePaymentSchedule}
          isMobile={isMobile}
          nextAutopayDate={getNextAutopayDate(association.id)}
          obligationAssocID={association.id}
          dueDate={association.dueDate}
          agencyName={activeGroupName}
        />
      ));
    });
    return (
      <Stack childGap="0.5rem" key={`active-properties-${activeGroupName}`}>
        <Box padding="0">
          <Detail as="h3" variant="regular">
            {activeGroupName}
          </Detail>
        </Box>
        <Box padding="0">
          <Stack childGap="1.5rem">{propertyObligations}</Stack>
        </Box>
      </Stack>
    );
  });
  const inactiveProperties = Object.entries(
    obligationAssociations?.properties?.inactive ?? {}
  ).map(([inactiveGroupName, associations]) => {
    const propertyObligations = associations.map(association => {
      if (association.config.collection) {
        return (
          <Obligation
            key={`${inactiveGroupName}-${association.id}-collection`}
            config={association.config}
            obligations={association.obligations}
            actions={oblActions}
            autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
            autoPayAvailable={autoPayAvailable}
            autoPaySchedule={
              retrieveAutoPayData(autoPayData, association.id).id
            }
            handleAutopayAction={() => navigate("set-up-autopay")}
            navigateToSettings={() => navigate("/profile/wallet")}
            deactivatePaymentSchedule={deactivatePaymentSchedule}
            isMobile={isMobile}
            nextAutopayDate={getNextAutopayDate(association.id)}
            obligationAssocID={association.id}
            dueDate={association.dueDate}
            agencyName={inactiveGroupName}
            inactive
            inactiveLookupTitle={
              association?.config?.inactiveLookupTitle ?? inactiveGroupName
            }
            inactiveLookupInput={association?.config?.inactiveLookupInput}
            inactiveLookupValue={
              association?.config?.inactiveLookupValue ?? association.account
            }
          />
        );
      }
      return association.obligations.map(obl => (
        <Obligation
          key={`${inactiveGroupName}-${association.id}-${obl.id}`}
          config={association.config}
          obligations={[obl]}
          actions={oblActions}
          autoPayEnabled={isAutoPayEnabled(autoPayData, association.id)}
          autoPayAvailable={autoPayAvailable}
          autoPaySchedule={retrieveAutoPayData(autoPayData, association.id).id}
          handleAutopayAction={() => navigate("set-up-autopay")}
          navigateToSettings={() => navigate("/profile/wallet")}
          deactivatePaymentSchedule={deactivatePaymentSchedule}
          isMobile={isMobile}
          nextAutopayDate={getNextAutopayDate(association.id)}
          obligationAssocID={association.id}
          dueDate={association.dueDate}
          agencyName={inactiveGroupName}
          inactive
          inactiveLookupTitle={
            association?.config?.inactiveLookupTitle ?? inactiveGroupName
          }
          inactiveLookupInput={association?.config?.inactiveLookupInput}
          inactiveLookupValue={
            association?.config?.inactiveLookupValue ?? association.account
          }
        />
      ));
    });
    return (
      <Stack childGap="0.5rem" key={`inactive-properties-${inactiveGroupName}`}>
        <Box padding="0">
          <Detail as="h3" variant="regular">
            {inactiveGroupName}
          </Detail>
        </Box>
        <Box padding="0">
          <Stack childGap="1.5rem">{propertyObligations}</Stack>
        </Box>
      </Stack>
    );
  });

  const { propertiesAlertBar: alertBar } = alertBars;
  const {
    alertBars: { propertiesAlertBar: alertBarActions }
  } = actions;

  const getStatus = obligations =>
    obligations.isLoading
      ? "loading"
      : obligations.isSuccess
      ? "success"
      : obligations.isFailure && obligations.error === "TIMEOUT"
      ? "timeout"
      : "not found";

  const renderObligations = obligations => {
    const status = getStatus(obligations);
    switch (status) {
      case "loading":
        return <Loading />;
      case "timeout":
        return <Timeout onLogout={onLogout} />;
      case "success":
        return (
          <Box
            padding="0"
            as="section"
            aria-labelledby="properties-section-heading"
          >
            {/*Box below with srOnly is to provide screen readers with an h1 - invisible to sighted users */}
            <Box padding="0" srOnly>
              <Title as="h1" variant="large" id="properties-section-heading">
                Properties
              </Title>
            </Box>
            {R.isEmpty(activeProperties) && (
              <Box
                background={constants.colors.WHITE}
                extraStyles={`box-shadow: 0px 2px 14px 0px ${constants.colors.ATHENS_GREY},
      0px 3px 8px 0px ${constants.colors.GHOST_GREY}; margin-bottom: 16px;`}
                key="placeholder-box"
              >
                <Placeholder
                  isLink
                  variant="large"
                  destination="/profile/find"
                  text="Add a Property"
                  largeIcon="properties"
                  key="placeholder"
                  isDisabled={isInCustomerManagement}
                />
              </Box>
            )}
            {R.or(
              !R.isEmpty(activeProperties),
              !R.isEmpty(inactiveProperties)
            ) && (
              <Stack childGap="16px" key="properties-stack">
                {!R.isEmpty(activeProperties) && (
                  <Box
                    padding="0"
                    as="section"
                    aria-labelledby="active-properties-section-heading"
                  >
                    <Stack childGap="0">
                      <Box padding="0">
                        <Title
                          variant="small"
                          as="h2"
                          weight={FONT_WEIGHT_SEMIBOLD}
                          id="active-properties-section-heading"
                        >
                          Active Properties
                        </Title>
                      </Box>
                      <Box padding="0">{activeProperties}</Box>
                    </Stack>
                  </Box>
                )}
                {canAddObligation &&
                  !R.isEmpty(activeProperties)(
                    <Stack childGap="0.5rem" direction="row">
                      <AddObligation
                        text="Add a property"
                        action={() => navigate("/profile/find")}
                      />
                    </Stack>
                  )}
                {!R.isEmpty(inactiveProperties) && (
                  <Box
                    padding="0"
                    as="section"
                    aria-labelledby="inactive-properties-section-heading"
                    extraStyles={`padding-top: 16px;`}
                  >
                    <Stack childGap="0">
                      <Box padding="0">
                        <Title
                          variant="small"
                          as="h2"
                          weight={FONT_WEIGHT_SEMIBOLD}
                          id="inactive-properties-section-heading"
                        >
                          Inactive Properties
                        </Title>
                      </Box>
                      <Box padding="0">{inactiveProperties}</Box>
                    </Stack>
                  </Box>
                )}
              </Stack>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box padding="0 0 16px" key="properties-box">
      <Stack fullHeight childGap="24px" key="properties-outer-stack">
        {!R.isEmpty(alertBar.alerts) && (
          <AlertBar {...alertBar} {...alertBarActions} key="alert-bar" />
        )}
        {renderObligations(obligations)}
      </Stack>
    </Box>
  );
};

export default connect(null, { push })(
  withWindowSize(React.memo(ProfileProperties))
);
