import styled from "styled-components";
import { WHITE, GHOST_GREY } from "../../../constants/colors";

export const EditableListItem = styled.div`
  box-sizing: border-box;
  background: ${({ disabled }) =>
    disabled ? `rgba(246, 246, 249, 0.7)` : WHITE};
  border-color: ${GHOST_GREY};
  height: ${({ listItemSize }) => (listItemSize === "big" ? "120px" : "72px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  :not(:last-child),
  :not(:first-child) {
    box-shadow: inset 0px -1px 0px 0px rgb(202, 206, 216);
  }
  :first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  :last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: none;
  }
`;

export const EditableListItemControls = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
