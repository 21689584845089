export const borderWrapper = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5
    }
  }
};
