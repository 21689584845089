import React, { useContext, forwardRef } from "react";
import styled, { ThemeContext } from "styled-components";
import posed from "react-pose";
import { linear } from "@popmotion/easing";
import Text from "../text";
import { Box, Center } from "../layouts";
import { fallbackValues } from "./ButtonWithAction.theme";

import SpinnerIcon from "../../../deprecated/spinner";
import { noop } from "../../../util/general";
import { createThemeValues } from "../../../util/themeUtils";

const rotate = posed.div({
  fixed: {
    rotate: "0deg"
  },
  rotate: {
    rotate: "360deg",
    transition: {
      rotate: {
        ease: linear,
        duration: 1000,
        loop: Infinity
      }
    }
  }
});

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SpinnerIconWrapper = styled(rotate)``;

const Spinner = ({ color, isMobile }) => (
  <SpinnerContainer>
    <SpinnerIconWrapper initialPose="fixed" pose="rotate">
      <SpinnerIcon color={color} isMobile={isMobile} />
    </SpinnerIconWrapper>
  </SpinnerContainer>
);

/* Most of the time, to use this button you'll only need to specify:
  - A variant (if different than primary)
  - An action
  - Text

  Optionally, you can also pass:
  - isLoading to show a spinner
  - dataQa for use with test cafe
  - contentOverride to use the button as a wrapper around arbitrary content
  - extraStyles if you need an escape hatch. Extra styles should be used sparingly.

  Note: the children prop is only used if contentOverride is set to true, in which case
  the text prop is ignored.

*/

const ButtonWithAction = forwardRef(
  (
    {
      action = noop,
      variant = "primary",
      text,
      textWrap = false,
      isLoading = false,
      disabled = false,
      loadingColor = "white",
      dataQa = null,
      textExtraStyles,
      contentOverride = false,
      extraStyles = "",
      tabIndex,
      children,
      extraDisabledStyles,
      ...rest
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);
    const themeValues = createThemeValues(
      themeContext,
      fallbackValues,
      "Button",
      variant
    );
    const { isMobile } = themeContext;

    const hoverStyles = `
      outline: none;
      background-color: ${themeValues.hoverBackgroundColor};
      border-color: ${themeValues.hoverBorderColor};
      color: ${themeValues.hoverColor};
      text-decoration: ${
        variant === "ghost" || variant === "smallGhost" ? "underline" : "none"
      };
      cursor: pointer;
    `;
    const activeStyles = `
      outline: none;
      background-color: ${themeValues.activeBackgroundColor};
      border-color: ${themeValues.activeBorderColor};
      color: ${themeValues.activeColor};
      text-decoration: ${
        variant === "ghost" || variant === "smallGhost" ? "underline" : "none"
      };
  `;
    const disabledStyles = `
      background-color: ${themeValues.disabledBackgroundColor};
      border-color: ${themeValues.disabledBorderColor};
      color: ${themeValues.disabledColor};
      cursor: default;
      &:focus {
        outline: 3px solid ${themeValues.disabledBorderColor};
        outline-offset: 2px;
      }
      ${extraDisabledStyles}
    `;

    return (
      <Box
        ref={ref}
        variant={variant}
        padding={themeValues.padding}
        minHeight={themeValues.height}
        minWidth={themeValues.minWidth}
        background={themeValues.backgroundColor}
        border={themeValues.border}
        hoverStyles={disabled ? disabledStyles : hoverStyles}
        activeStyles={disabled ? disabledStyles : activeStyles}
        disabledStyles={disabledStyles}
        aria-disabled={disabled}
        as="button"
        onClick={isLoading || disabled ? noop : action}
        borderRadius="2px"
        theme={themeContext}
        extraStyles={`margin: 0.5rem; ${extraStyles}`}
        dataQa={dataQa}
        tabIndex={tabIndex}
        {...rest}
      >
        {contentOverride ? (
          children
        ) : (
          <Center as="span" intrinsic>
            {isLoading ? (
              <Spinner color={loadingColor} isMobile={isMobile} />
            ) : (
              <Text
                weight={themeValues.fontWeight}
                variant={themeValues.fontSizeVariant}
                color={disabled ? themeValues.disabledColor : themeValues.color}
                textDecoration={themeValues.textDecoration}
                textWrap={textWrap}
                extraStyles={textExtraStyles}
              >
                {text}
              </Text>
            )}
          </Center>
        )}
      </Box>
    );
  }
);

export default ButtonWithAction;
