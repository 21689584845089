import styled from "styled-components";

export const ParagraphText = styled.p`
  --font-size: ${({ fontSize }) => fontSize};
  font-size: var(--font-size);
  line-height: calc(1.5 * var(--font-size));
  font-weight: ${({ weight }) => weight};
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};

  ${({ extraStyles }) => extraStyles}
`;
