import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const HistoryIconSmall = ({ themeValues }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 8.33333C3.33337 7.8731 3.70647 7.5 4.16671 7.5H15.8334C16.2936 7.5 16.6667 7.8731 16.6667 8.33333V16.6667C16.6667 17.1269 16.2936 17.5 15.8334 17.5H4.16671C3.70647 17.5 3.33337 17.1269 3.33337 16.6667V8.33333Z"
        fill={themeValues.singleIconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08337 4.75C4.66916 4.75 4.33337 5.08579 4.33337 5.5C4.33337 5.91421 4.66916 6.25 5.08337 6.25H14.9134C15.3276 6.25 15.6634 5.91421 15.6634 5.5C15.6634 5.08579 15.3276 4.75 14.9134 4.75H5.08337Z"
        fill={themeValues.singleIconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03337 2.25C5.64677 2.25 5.33337 2.5634 5.33337 2.95C5.33337 3.3366 5.64678 3.65 6.03337 3.65H13.9634C14.35 3.65 14.6634 3.3366 14.6634 2.95C14.6634 2.5634 14.35 2.25 13.9634 2.25H6.03337Z"
        fill={themeValues.singleIconColor}
      />
    </svg>
  );
};

export default themeComponent(
  HistoryIconSmall,
  "Icons",
  fallbackValues,
  "primary"
);
