import React, { useState } from "react";
import Modal from "../../modal";
import ButtonWithAction from "../../../atoms/button-with-action";
import { Box } from "../../../atoms/layouts";
import { noop } from "../../../../util/general";

const RemoveAccountModalModule = ({
  agencyName,
  obligations = [],
  removeAccount,
  accountType,
  isMobile,
  disableActions = false
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const lastItem = [...obligations].pop();
  const accounts = obligations.length
    ? obligations.reduce((acc, curr) => {
        const account = curr.details.description;
        const formattedAccount =
          curr !== lastItem ? `${account} and ` : `${account}`;
        return formattedAccount === agencyName
          ? agencyName
          : acc + formattedAccount;
      }, `${agencyName} - `)
    : "";
  const identifier =
    accountType === "Account" && obligations.length > 1
      ? "accounts"
      : accountType === "Property" && obligations.length > 1
      ? "properties"
      : accountType.toLowerCase();

  return (
    <Modal
      showModal={() => setModalIsOpen(true)}
      hideModal={() => setModalIsOpen(false)}
      modalOpen={modalIsOpen}
      modalHeaderText={`Remove ${accountType}`}
      modalBodyText={`Are you sure you want to remove the ${identifier} ${accounts}? Any autopay scheduled against ${
        obligations.length > 1 ? "them" : "it"
      } will be deactivated.`}
      continueButtonText="Remove"
      continueAction={() => {
        if (!disableActions) {
          removeAccount();
          setModalIsOpen(false);
        }
      }}
      useDangerButton
    >
      <Box padding="0" extraStyles={`flex-grow: 1;`}>
        <ButtonWithAction
          text="Remove"
          variant="secondary"
          action={disableActions ? noop : () => setModalIsOpen(true)}
          dataQa="Remove Account"
          extraStyles={
            isMobile ? `flex-grow: 1; width: 100%; margin: 0;` : `flex-grow: 1;`
          }
          disabled={disableActions}
        />
      </Box>
    </Modal>
  );
};
export default RemoveAccountModalModule;
