import React from "react";
import { ButtonWithAction, CloseIcon } from "@thecb/components";

const RemoveButton = ({ ariaLabel, isMobile, removeAction, ...rest }) => {
  return (
    <ButtonWithAction
      variant="smallGhost"
      action={removeAction}
      contentOverride
      extraStyles={`
        min-height: auto;
        min-width: auto;
        height: 1.5rem;
        margin: 0;
        &:focus {
            outline-offset: -3px;
        }
      `}
      aria-label={ariaLabel}
      {...rest}
    >
      <CloseIcon
        role="img"
        aria-hidden="true"
        iconWidth={isMobile ? "20px" : "24px"}
        iconHeight={isMobile ? "20px" : "24px"}
      />
    </ButtonWithAction>
  );
};

export default RemoveButton;
