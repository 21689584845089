import React from "react";
import { AutopayOnIcon, Cluster, Text } from "@thecb/components";
import { formatDate } from "/util/dateUtil";
import { CHARADE_GREY, SEA_GREEN } from "/constants/colors";
import TransactionAutopayPopover from "./TransactionAutopayPopover";

const TransactionDate = ({
  id,
  createdAt,
  fontSize,
  paymentInitiatorDetails
}) => {
  const isAutopayEnabled = paymentInitiatorDetails?.isAutoInitiated;
  const popoverID = `transaction-history-date-${id}`;
  const displayText = formatDate(createdAt);

  return isAutopayEnabled ? (
    <TransactionAutopayPopover
      id={popoverID}
      displayText={displayText}
      fontSize={fontSize}
      Icon={AutopayOnIcon}
      position={{
        top: "-43px",
        left: "-61px"
      }}
      textColor={SEA_GREEN}
    />
  ) : (
    <Cluster childGap={"5px"} align="center">
      <Text color={CHARADE_GREY} fontSize={fontSize}>
        {displayText}
      </Text>
    </Cluster>
  );
};

export default TransactionDate;
