import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  ButtonWithAction,
  Breadcrumb,
  ChangePasswordForm,
  Module,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import S from "/util/Sanctuary";
import { PASSWORD_SETTING } from "../../Profile.state";
import AlertBar from "/components/alert-bar";

const ChangePassword = ({
  resourcesActions,
  requests,
  forms,
  actions,
  alertBars,
  handleFocusErrors
}) => {
  let navigate = useNavigate();
  const { onSubmitChange } = resourcesActions;
  const requestStatusChangePassword =
    requests[PASSWORD_SETTING] || S.RemoteData.NotAsked;
  const { changePasswordForm: form } = forms;
  const {
    forms: { changePasswordForm: formActions },
    alertBars: { changePasswordAlertBar: alertBarActions }
  } = actions;
  const { changePasswordAlertBar: alertBar } = alertBars;
  const [errors, handleErrors] = useState(false);
  const { isMobile } = useContext(ThemeContext);

  const handleSubmit = util.general.generateClickHandler(
    form,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => onSubmitChange(PASSWORD_SETTING)
  );

  return (
    <Box padding="0 0 16px" width={isMobile ? "100%" : "576px"}>
      <Breadcrumb
        breadcrumbsList={[
          {
            linkText: "Settings",
            linkDestination: "/profile/settings",
            isActive: false
          },
          {
            linkText: "Change Password",
            linkDestination: "/profile/settings/change-password",
            isActive: true
          }
        ]}
      />
      {alertBar.alerts.length > 0 && (
        <Box padding="1rem 0 0">
          <AlertBar {...alertBar} {...alertBarActions} />
        </Box>
      )}
      <Module heading="Change Password">
        <ChangePasswordForm
          {...form}
          {...formActions}
          clearOnDismount
          showErrors={errors}
          handleSubmit={handleSubmit}
          isMobile={isMobile}
        />
      </Module>
      <Box
        padding="1.5rem 0 0 0"
        borderColor={constants.colors.GHOST_GREY}
        borderSize="1px"
        borderWidthOverride="1px 0 0 0"
      >
        {isMobile ? (
          <Stack childGap="1rem" direction="row">
            <Box width="100%" padding="0">
              <ButtonWithAction
                text="Cancel"
                action={() => navigate(-1)}
                variant={"secondary"}
                dataQa="Cancel"
                extraStyles={`width: 100%;`}
              />
            </Box>
            <Box width="100%" padding="0">
              <ButtonWithAction
                text="Save"
                action={handleSubmit}
                isLoading={requestStatusChangePassword.isLoading}
                variant="primary"
                dataQa="Save"
                extraStyles={`width: 100%;`}
              />
            </Box>
          </Stack>
        ) : (
          <Stack childGap="1rem" direction="row" justify="flex-end">
            <ButtonWithAction
              text="Cancel"
              action={() => navigate(-1)}
              variant={"secondary"}
              dataQa="Cancel"
            />
            <ButtonWithAction
              text="Change Password"
              action={handleSubmit}
              isLoading={requestStatusChangePassword.isLoading}
              variant="primary"
              dataQa="Change Password"
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default withWindowSize(ChangePassword);
