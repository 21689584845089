import React from "react";

const FilterIcon = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.990234C0 0.714092 0.223858 0.490234 0.5 0.490234H13.5C13.7761 0.490234 14 0.714092 14 0.990234V1.99023C14 2.26638 13.7761 2.49023 13.5 2.49023H0.5C0.223857 2.49023 0 2.26638 0 1.99023V0.990234ZM2 5.99023C2 5.71409 2.22386 5.49023 2.5 5.49023H11.5C11.7761 5.49023 12 5.71409 12 5.99023V6.99023C12 7.26638 11.7761 7.49023 11.5 7.49023H2.5C2.22386 7.49023 2 7.26638 2 6.99023V5.99023ZM5.5 10.4902C5.22386 10.4902 5 10.7141 5 10.9902V11.9902C5 12.2664 5.22386 12.4902 5.5 12.4902H8.5C8.77614 12.4902 9 12.2664 9 11.9902V10.9902C9 10.7141 8.77614 10.4902 8.5 10.4902H5.5Z"
      fill="#292A33"
    />
  </svg>
);

export default FilterIcon;
