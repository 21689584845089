import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../../content/Content.theme";
import { themeComponent } from "../../../util/themeUtils";
import { withWindowSize } from "@thecb/components";
import {
  Box,
  Center,
  Cover,
  Stack,
  Cluster,
  ButtonWithLink,
  Heading,
  Text,
  Title,
  constants
} from "@thecb/components";
import TableOfContents from "../../content/table-of-contents";
import {
  FONT_WEIGHT_SEMIBOLD,
  MAX_CONTENT_WIDTH
} from "../../../constants/style_constants";
import RightArrowIcon from "../../icons/RightArrowIcon";
import { getCallToActionInfo } from "../../../util/dataAdapters";
import useRelatedContent from "../../content/hooks/useRelatedContent";
import RelatedContentSection from "../../content/related-content-section";

const CMSSectionPage = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  maxContentWidth = MAX_CONTENT_WIDTH,
  fullWidthMobile = true,
  pageTitle,
  description,
  callToActionText,
  callToActionURL,
  callToActionLink,
  heroImage,
  content,
  showRelatedContent,
  parentPage,
  childPagesAndLinks,
  otherRelatedContent,
  toc,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const relatedContentCards = useRelatedContent(
    parentPage,
    childPagesAndLinks,
    otherRelatedContent
  );
  const gradient = heroImage
    ? `background: ${themeValues.gradient[0]};
    background: linear-gradient(-225deg, ${themeValues.gradient[2]} 0%, ${themeValues.gradient[3]} 99%), url(${heroImage.url}); background-size: cover;background-position:center;`
    : `background: ${themeValues.darkGradient[0]};
        background: linear-gradient(-225deg, ${themeValues.darkGradient[2]} 0%, ${themeValues.darkGradient[3]} 99%)`;

  const { ctaUrl, ctaText } = getCallToActionInfo(
    callToActionLink,
    callToActionText,
    callToActionURL
  );

  return (
    <Box padding="0" minWidth="100%" background={constants.colors.WHITE}>
      <Cover minHeight="100vh" fillCenter>
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box padding="0 0 8px 0" minWidth="100%" extraStyles={gradient}>
            <Cover
              minHeight={isMobile ? "200px" : "335px"}
              singleChild
              fillCenter
            >
              <Box padding={isMobile ? "0 16px" : "0 24px"}>
                <Center
                  maxWidth={
                    isMobile && fullWidthMobile ? "100%" : maxContentWidth
                  }
                  intrinsic={!isMobile}
                >
                  <Box
                    key="contents"
                    padding={`${isMobile ? "40px" : "80px"} 0 16px`}
                    borderColor="white"
                    borderSize="1px"
                    borderWidthOverride="0 0 1px 0"
                    minWidth="100%"
                  >
                    <Cluster justify="space-between" align="flex-end">
                      <Stack childGap="1rem">
                        <Box
                          padding="0"
                          minHeight="6px"
                          width="48px"
                          borderSize="6px"
                          borderColor={"#FFFFFF"}
                          borderWidthOverride="0 0 6px 0"
                          key="accent"
                        />
                        <Title
                          as="h1"
                          color="white"
                          weight={FONT_WEIGHT_SEMIBOLD}
                          key="heading"
                          margin={isMobile ? "0.5rem 0 0 0" : "1rem 0 0 0"}
                          extraStyles={`margin-top: 4px; line-height: 150%; font-size: ${
                            isMobile ? "28px" : "40px"
                          };`}
                        >
                          {pageTitle}
                        </Title>
                      </Stack>
                      {!isMobile && toc?.length <= 4 && (
                        <TableOfContents
                          toc={toc}
                          themeValues={themeValues}
                          isMobile={isMobile}
                        />
                      )}
                    </Cluster>
                  </Box>
                  <Box
                    key="desc"
                    padding={isMobile ? "40px 16px" : "72px 0"}
                    extraStyles={`display: flex; flex-grow: 1; flex-direction: column; justify-content: center;`}
                  >
                    <Stack>
                      {description && (
                        <Box key="desc-text" padding="0">
                          <Title
                            as="h2"
                            weight={FONT_WEIGHT_SEMIBOLD}
                            color="white"
                            textAlign="center"
                            extraStyles={`line-height: 150%; font-size: ${
                              isMobile ? "28px" : "48px"
                            };`}
                          >
                            {description}
                          </Title>
                        </Box>
                      )}
                      {ctaUrl && (
                        <Box key="cta" padding="0" minWidth="100%">
                          <Cluster justify="flex-end" align="center">
                            <ButtonWithLink
                              url={ctaUrl}
                              contentOverride
                              variant="ghost"
                              extraStyles={`span { color: white; }`}
                              key="cta-link"
                            >
                              <Cluster justify="flex-start" align="center">
                                <Text
                                  variant="pL"
                                  weight={FONT_WEIGHT_SEMIBOLD}
                                  color={constants.colors.WHITE}
                                  extraStyles={`font-size: ${
                                    isMobile ? "16px" : "20px"
                                  };`}
                                  key="cta-text"
                                >
                                  {ctaText}
                                </Text>
                                <RightArrowIcon
                                  color={constants.colors.WHITE}
                                  key="cta-arrow"
                                />
                              </Cluster>
                            </ButtonWithLink>
                          </Cluster>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Center>
              </Box>
            </Cover>
          </Box>
          <Box padding="0" extraStyles="margin: auto;">
            <Cover minHeight="100%" singleChild fillCenter>
              <Stack childGap={isMobile ? "56px" : "80px"}>
                {content}
                {showRelatedContent && (
                  <RelatedContentSection
                    relatedContentCards={relatedContentCards}
                    maxContentWidth={maxContentWidth}
                    mobileContentPadding="16px"
                    themeValues={themeValues}
                  />
                )}
              </Stack>
            </Cover>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
};

export default withWindowSize(
  themeComponent(CMSSectionPage, "Content", fallbackValues, "default")
);
