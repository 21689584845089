import React, { useContext, useState } from "react";
import {
  Box,
  ButtonWithLink,
  constants,
  Motion,
  Stack,
  Text,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import RichTextField from "../rich-text-field";
import CollapsibleSection, {
  CollapsibleSectionOpenCloseStyle
} from "../../../collapsible-section/CollapsibleSection";
import { getCallToActionInfo } from "../../../../util/dataAdapters";

const content = {
  open: {
    x: "0",
    opacity: 1
  },
  closed: {
    x: "-7.5%",
    opacity: 0
  }
};

const AccordionItem = ({ item, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const { ATHENS_GREY, GHOST_GREY, WHITE } = constants.colors;

  const handleToggleSection = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const { title, richTextDescription, url, callToActionLink } = item;
  const { ctaUrl, ctaText } = getCallToActionInfo(callToActionLink, title, url);

  return (
    <Box
      background={ATHENS_GREY}
      padding="0"
      borderSize="2px"
      borderColor={GHOST_GREY}
      borderWidthOverride={"0 0 2px 0"}
    >
      <CollapsibleSection
        title={
          <Box padding="0">
            <Text variant={isMobile ? "pL" : "pXL"}>{title}</Text>
          </Box>
        }
        customTitle
        toggleSection={handleToggleSection}
        initiallyOpen={false}
        isOpen={isOpen}
        customPadding={isMobile ? "12px 16px" : "16px 24px"}
        name={title}
        openCloseStyle={CollapsibleSectionOpenCloseStyle.PlusMinus}
      >
        <Motion
          variants={content}
          positionTransition
          transition={{ duration: 0.4 }}
        >
          <Box padding={isMobile ? "0 24px 24px" : "0"} background={WHITE}>
            <Stack
              direction={isMobile ? "column" : "row"}
              childGap={"0"}
              justify={isMobile ? "center" : "space-between"}
            >
              <Box
                padding={isMobile ? "0" : "32px"}
                extraStyles={`font-size: 18px; line-height: 150%;`}
              >
                <RichTextField
                  html={richTextDescription?.html ?? ""}
                  themeValues={themeValues}
                />
              </Box>
              {ctaUrl && ctaText && (
                <Box
                  padding={isMobile ? "24px 0 0" : "0 32px"}
                  extraStyles={`align-self: center; ${isMobile &&
                    "width: 100%;"}`}
                >
                  <ButtonWithLink
                    variant="secondary"
                    url={ctaUrl}
                    text={ctaText}
                    extraStyles={`border-radius: 5px; ${isMobile &&
                      "width: 100%; margin: 0;"}`}
                    textExtraStyles={isMobile && "font-size: 1rem;"}
                  />
                </Box>
              )}
            </Stack>
          </Box>
        </Motion>
      </CollapsibleSection>
    </Box>
  );
};

export default withWindowSize(AccordionItem);
