import React from "react";
import { pipe } from "ramda";
import { Cluster, Text, util, WarningIconXS } from "@thecb/components";
import TransactionStatusPopover from "./TransactionStatusPopover";
import { signedAmount } from "/util/transactionHistory";
import { convertMoneyDecimalToCents } from "/util/general";
import {
  CHARADE_GREY,
  STORM_GREY,
  SEA_GREEN,
  WARNING_ORANGE
} from "/constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "/constants/style_constants";
import {
  TRANSACTION_STATUS_SUCCESSFUL,
  TRANSACTION_STATUS_VOIDED,
  TRANSACTION_STATUS_ACH_RETURN,
  TRANSACTION_STATUS_CHARGEBACK,
  TRANSACTION_STATUS_REFUNDED,
  TRANSACTION_STATUS_CHARGEBACK_REVERSAL
} from "/constants/transaction_history_constants";

const { displayCurrency } = util.general;

const getAmount = pipe(
  signedAmount,
  convertMoneyDecimalToCents,
  displayCurrency
);

const TransactionAmount = ({ id, fontSize, status, totalAmount }) => {
  const {
    color = CHARADE_GREY,
    isItalic = false,
    popoverIcon = null,
    popoverColor = null
  } =
    {
      [TRANSACTION_STATUS_SUCCESSFUL]: {
        color: CHARADE_GREY
      },
      [TRANSACTION_STATUS_VOIDED]: {
        color: STORM_GREY,
        isItalic: true,
        popoverIcon: WarningIconXS,
        popoverColor: WARNING_ORANGE
      },
      [TRANSACTION_STATUS_CHARGEBACK]: {
        color: SEA_GREEN,
        popoverIcon: WarningIconXS,
        popoverColor: SEA_GREEN
      },
      [TRANSACTION_STATUS_REFUNDED]: {
        color: SEA_GREEN,
        popoverIcon: WarningIconXS,
        popoverColor: SEA_GREEN
      },
      [TRANSACTION_STATUS_ACH_RETURN]: {
        color: SEA_GREEN,
        popoverIcon: WarningIconXS,
        popoverColor: SEA_GREEN
      },
      [TRANSACTION_STATUS_CHARGEBACK_REVERSAL]: {
        color: WARNING_ORANGE,
        popoverIcon: WarningIconXS,
        popoverColor: WARNING_ORANGE
      }
    }[status] ?? {};

  return (
    <Cluster
      childGap={"8px"}
      align="center"
      justify="right"
      overflow={true}
      nowrap={true}
    >
      <Text
        color={color}
        weight={FONT_WEIGHT_SEMIBOLD}
        fontSize={fontSize}
        extraStyles={`${isItalic ? "font-style: italic;" : ""}`}
      >
        {getAmount(status, totalAmount)}
      </Text>
      {popoverIcon && (
        <TransactionStatusPopover
          id={id}
          status={status}
          Icon={popoverIcon}
          color={popoverColor}
        />
      )}
    </Cluster>
  );
};

export default TransactionAmount;
