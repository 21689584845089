import { GraphQLClient } from "graphql-request";
import {
  always,
  isNil,
  pipe,
  props,
  append,
  join,
  objOf,
  __,
  ifElse,
  reject
} from "ramda";

const inputSafetyCheck = gqlVariables => reject(isNil, gqlVariables);

export const makeAuth = ifElse(
  isNil,
  always({}),
  pipe(
    props(["username", "password"]),
    join(":"),
    window.btoa,
    append(__, ["Basic"]),
    join(" "),
    objOf("x-cb-authorization")
  )
);

export const createQuery = (endpoint, clientSlug, authJwt = "") => (
  Query,
  args
) => {
  const safeArgs = inputSafetyCheck(args);
  const devHeaders =
    process.env.NODE_ENV === "development"
      ? {
          "client-slug": clientSlug
        }
      : {};
  const headers = !authJwt
    ? {}
    : {
        Authorization: `Bearer ${authJwt}`
      };
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      ...devHeaders,
      ...headers
    }
  });
  return graphQLClient.request(Query, safeArgs);
};
