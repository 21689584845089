import React, { useEffect, useContext, Fragment } from "react";
import { isEmpty, isNil } from "ramda";
import { useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import HashLinkObserver from "../../../../util/hashLinkObserver";
import Footer from "../../../../components/footer";
import Header from "../../../../components/content/header";
import { history } from "../../../../state/store";
import { Box, CenterSingle, withWindowSize, Loading } from "@thecb/components";
import Content from "../../../../components/content/Content";
import LandingPageContent from "../../../../components/content/LandingPageContent";
import CMSLandingPage from "../../../../components/templates/CMS-landing-page";
import CMSSectionPage from "../../../../components/templates/CMS-section-page";
import CMSSubjectPage from "../../../../components/templates/CMS-subject-page";
import CMSActionPage from "../../../../components/templates/CMS-action-page";
import Search from "../../../../components/content/search";
import CMSCategoryGridPage from "../../../../components/templates/CMS-category-grid-page";
import NotFound from "../../../common/pages/not-found";
import { MAX_CONTENT_WIDTH } from "../../../../constants/style_constants";
import {
  CategoryGridType,
  CategoryGridFilterType
} from "../../../../components/content/category-grid/CategoryGrid";
import { getPageCallToActionLink } from "../../../../util/dataAdapters";

const CMS = ({
  footerLogoURL,
  onLogout,
  isLoggedIn,
  retrieving,
  retrieveCMSContent,
  content,
  clientMetadata: { data: meta },
  retrieveRelatedContent,
  relatedContent,
  retrieveSearchResults,
  searchResults,
  setSearchTerm,
  retrieveActionPages,
  actionPages,
  retrieveSectionPages,
  sectionPages,
  retrieveSubjectPages,
  subjectPages
}) => {
  const params = useParams();
  const pageSlug = params?.pageSlug ?? "home";
  const id = params?.id;

  useEffect(() => {
    if (pageSlug === "category") {
      const retrievalMethods = {
        activities: retrieveActionPages,
        sections: retrieveSectionPages,
        subjects: retrieveSubjectPages
      };
      const retrievalMethod = retrievalMethods[id] || retrieveCMSContent;
      retrievalMethod(pageSlug, meta);
    } else if (pageSlug === "preview" && id) {
      retrieveCMSContent(pageSlug, meta, id, true);
    } else {
      /*else if(id) {
      // do a thing
      // redirect to not found?
    }*/
      retrieveCMSContent(pageSlug, meta);
    }
  }, [pageSlug, id]);
  const { isMobile } = useContext(ThemeContext);
  const navigation = content?.navigation ?? {};
  const sitewideAlert = content?.sitewideAlerts?.[0] ?? {};
  let pageContent;
  if (pageSlug === "preview" && id) {
    pageContent =
      content?.landingPage ??
      content?.sectionPage ??
      content?.subjectPage ??
      content?.actionPage ??
      {};
  } else {
    pageContent = content?.slug?.page ?? {};
  }
  const returnedSlug =
    pageSlug === "preview" ? pageContent?.slug : content?.slug;
  const pageType = pageContent?.__typename ?? "";
  const pageTitle = pageContent?.title ?? "";
  // Used by the Landing and Section pages (has full width bg stripes)
  const landingPageBlocks =
    pageContent?.blocks?.map(({ __typename: type, id, ...rest }, index) => {
      const variant = "default";
      return (
        <LandingPageContent
          key={id}
          variant={variant}
          blockName={type}
          blockIndex={index}
          {...rest}
          relatedContent={relatedContent}
          retrieveRelatedContent={retrieveRelatedContent}
          meta={meta}
          pageType={pageType}
          pageSlug={pageSlug}
        />
      );
    }) ?? [];

  // Used by the Subject and Action pages (no full width bg stripes)
  const blocks =
    pageContent?.blocks?.map(({ __typename: type, id, ...rest }, index) => {
      const variant = "default";
      return (
        <Content
          key={id}
          variant={variant}
          blockName={type}
          blockIndex={index}
          {...rest}
          pageType={pageType}
          relatedContent={relatedContent}
          retrieveRelatedContent={retrieveRelatedContent}
          meta={meta}
          pageSlug={pageSlug}
        />
      );
    }) ?? [];
  // Used in the sidebar on Subject and Action pages
  const secondaryBlocks =
    pageContent?.secondaryBlocks?.map(
      ({ __typename: type, id, ...rest }, index) => (
        <Content
          key={id}
          variant="default"
          blockName={type}
          blockIndex={index}
          pageType={pageType}
          inSidebar={true}
          pageSlug={pageSlug}
          {...rest}
        />
      )
    ) ?? [];
  const otherPageProps = pageContent;
  const { metaTitle = "", metaDescription = "" } = pageContent;
  const makePageContents = blocks => {
    if (blocks.length > 1) {
      const contents = blocks.map(({ props }, index) => {
        const anchor = {
          id: `#${props.blockName}-${index}`,
          anchorText: `${props.title}`
        };
        switch (props.blockName) {
          case "Alert":
            return {
              ...anchor,
              anchorText: `Alert: ${props.heading}`
            };
          case "Article":
            return {
              ...anchor,
              anchorText: `${props.headline}`
            };
          case "AttachedFile":
            return {
              ...anchor,
              anchorText: `Download ${props.fileName}`
            };
          case "GetInTouch":
            return {
              ...anchor,
              anchorText: `Get in Touch`
            };
          case "NewsList":
            return {
              ...anchor,
              anchorText: `News`
            };
          case "QuickLinksList":
            return {
              ...anchor,
              anchorText: "Quick Links"
            };
          case "Tagline":
            return {};
          case "Task":
            return {
              ...anchor,
              anchorText: `${props.headline}`
            };
          default:
            return anchor;
        }
      });
      return contents;
    }
    return null;
  };

  const getUrlFromSlug = slug =>
    Array.isArray(slug) ? `/${slug[0]?.slug}` : "/";

  const mapPagesToGridCards = (type, pages) => {
    switch (type) {
      case "action":
        return pages.map(
          ({ actionType, subtitle, quickLinkText, slug, ...rest }) => {
            return {
              category: CategoryGridType.Action,
              type: actionType,
              description: subtitle,
              linkText: quickLinkText || "Start",
              url: getUrlFromSlug(slug),
              ...rest
            };
          }
        );
      case "subject":
        return pages.map(
          ({ subjectType, subtitle, quickLinkText, slug, ...rest }) => {
            return {
              category: CategoryGridType.Subject,
              type: subjectType,
              description: subtitle,
              linkText: quickLinkText || "Learn More",
              url: getUrlFromSlug(slug),
              ...rest
            };
          }
        );
      case "section":
        return pages.map(({ sectionType, quickLinkText, slug, ...rest }) => {
          return {
            category: CategoryGridType.Section,
            type: sectionType,
            linkText: quickLinkText || "Learn More",
            url: getUrlFromSlug(slug),
            ...rest
          };
        });
      default:
        break;
    }
  };

  const page = () => {
    if (pageSlug === "search") {
      return (
        <CMSActionPage
          header={
            <Header
              navigation={navigation}
              metaTitle={"Search"}
              metaDesc={metaDescription}
              retrieveSearchResults={retrieveSearchResults}
              searchResults={searchResults}
              setSearchTerm={setSearchTerm}
              sitewideAlert={sitewideAlert}
            />
          }
          content={
            <Box padding="0" minWidth={isMobile ? "100%" : MAX_CONTENT_WIDTH}>
              <Search
                retrieveSearchResults={retrieveSearchResults}
                searchResults={searchResults}
                meta={meta}
                isMobile={isMobile}
                setSearchTerm={setSearchTerm}
              />
            </Box>
          }
          footer={
            <Footer
              footerLogoURL={footerLogoURL}
              showLogout={isLoggedIn}
              onLogout={onLogout}
              footerWidth="1348px"
            />
          }
          pageTitle={"Search"}
          extraStyles={
            isMobile
              ? `margin-top: -100px; background-color: white;`
              : `margin-top: -150px; background-color: white;`
          }
          subtitle=""
          callToAction={{}}
          {...otherPageProps}
          numberOfBlocks={1}
        />
      );
    }
    if (pageSlug === "category" && id === "activities") {
      return (
        <CMSCategoryGridPage
          key="category-activities"
          header={
            <Header
              navigation={navigation}
              metaTitle="Activities"
              metaDesc="Search, find, and learn about city services"
              retrieveSearchResults={retrieveSearchResults}
              searchResults={searchResults}
              setSearchTerm={setSearchTerm}
              sitewideAlert={sitewideAlert}
            />
          }
          sidebarProps={{
            showSearch: true,
            filters: {
              [CategoryGridFilterType.Action]: {
                enabled: true,
                title: "I Want To..."
              },
              [CategoryGridFilterType.Tag]: { enabled: true, title: "Tags" }
            }
          }}
          pageTitle="I want to..."
          gridCards={mapPagesToGridCards("action", actionPages.pages)}
          footer={
            <Footer
              footerLogoURL={footerLogoURL}
              showLogout={isLoggedIn}
              onLogout={onLogout}
              footerWidth="1348px"
            />
          }
          extraStyles={
            isMobile
              ? `margin-top: -100px; background-color: white;`
              : `margin-top: -150px; background-color: white;`
          }
          {...otherPageProps}
        />
      );
    }
    if (pageSlug === "category" && id === "sections") {
      return (
        <CMSCategoryGridPage
          key="category-sections"
          header={
            <Header
              navigation={navigation}
              metaTitle="Sections"
              metaDesc="Search, find, and learn about city services"
              retrieveSearchResults={retrieveSearchResults}
              searchResults={searchResults}
              setSearchTerm={setSearchTerm}
              sitewideAlert={sitewideAlert}
            />
          }
          sidebarProps={{
            showSearch: true,
            filters: {
              [CategoryGridFilterType.Section]: {
                enabled: true,
                title: "Type"
              },
              [CategoryGridFilterType.Tag]: { enabled: true, title: "Tags" }
            }
          }}
          pageTitle="Departments and Divisions"
          gridCards={mapPagesToGridCards("section", sectionPages.pages)}
          footer={
            <Footer
              footerLogoURL={footerLogoURL}
              showLogout={isLoggedIn}
              onLogout={onLogout}
              footerWidth="1348px"
            />
          }
          extraStyles={
            isMobile
              ? `margin-top: -100px; background-color: white;`
              : `margin-top: -150px; background-color: white;`
          }
          {...otherPageProps}
        />
      );
    }
    if (pageSlug === "category" && id === "subjects") {
      return (
        <CMSCategoryGridPage
          key="category-subjects"
          header={
            <Header
              navigation={navigation}
              metaTitle="Subjects"
              metaDesc="Search, find, and learn about city services"
              retrieveSearchResults={retrieveSearchResults}
              searchResults={searchResults}
              setSearchTerm={setSearchTerm}
              sitewideAlert={sitewideAlert}
            />
          }
          sidebarProps={{
            showSearch: true,
            filters: {
              [CategoryGridFilterType.Subject]: {
                enabled: true,
                title: "Subjects"
              },
              [CategoryGridFilterType.Tag]: { enabled: true, title: "Tags" }
            }
          }}
          pageTitle="Learn about..."
          gridCards={mapPagesToGridCards("subject", subjectPages.pages)}
          footer={
            <Footer
              footerLogoURL={footerLogoURL}
              showLogout={isLoggedIn}
              onLogout={onLogout}
              footerWidth="1348px"
            />
          }
          extraStyles={
            isMobile
              ? `margin-top: -100px; background-color: white;`
              : `margin-top: -150px; background-color: white;`
          }
          {...otherPageProps}
        />
      );
    }
    // If the content from GraphCMS is loading, content will be undefined or an empty { }
    // If the page loads correctly, contentSlug is an object
    // If the page is not found, contentSlug is null (and the Not Found page will be displayed)
    if (!isEmpty(content) && !isNil(content) && isNil(returnedSlug)) {
      return (
        <CenterSingle
          header={
            <Header
              navigation={navigation}
              metaTitle={metaTitle}
              metaDesc={metaDescription}
              retrieveSearchResults={retrieveSearchResults}
              searchResults={searchResults}
              setSearchTerm={setSearchTerm}
              sitewideAlert={sitewideAlert}
            />
          }
          content={
            <Box
              padding="0"
              minWidth={isMobile ? "100%" : "1280px"}
              minHeight={`calc(100vh - 250px)`}
              extraStyles="display: flex; justify-content: center;"
            >
              <NotFound history={history} />
            </Box>
          }
          footer={
            <Footer
              footerLogoURL={footerLogoURL}
              showLogout={isLoggedIn}
              onLogout={onLogout}
              footerWidth="1348px"
            />
          }
        />
      );
    }
    switch (pageType) {
      case "LandingPage":
        const heroSearch =
          content?.slug?.page?.searchBoxPosition === "Center" ? true : false;
        return (
          <CMSLandingPage
            header={
              <Header
                navigation={navigation}
                metaTitle={metaTitle}
                metaDesc={metaDescription}
                showSearchLink={!heroSearch}
                retrieveSearchResults={retrieveSearchResults}
                searchResults={searchResults}
                sitewideAlert={sitewideAlert}
                setSearchTerm={setSearchTerm}
              />
            }
            content={landingPageBlocks}
            footer={
              <Footer
                footerLogoURL={footerLogoURL}
                showLogout={isLoggedIn}
                onLogout={onLogout}
                footerWidth="1348px"
              />
            }
            pageTitle={pageTitle}
            meta={meta}
            searchParams={{
              heroSearch: heroSearch,
              retrieveSearchResults: retrieveSearchResults,
              searchResults: searchResults,
              setSearchTerm: setSearchTerm
            }}
            {...otherPageProps}
          />
        );
      case "SectionPage":
        return (
          <CMSSectionPage
            header={
              <Header
                navigation={navigation}
                metaTitle={metaTitle}
                metaDesc={metaDescription}
                retrieveSearchResults={retrieveSearchResults}
                searchResults={searchResults}
                setSearchTerm={setSearchTerm}
                sitewideAlert={sitewideAlert}
              />
            }
            content={landingPageBlocks}
            footer={
              <Footer
                footerLogoURL={footerLogoURL}
                showLogout={isLoggedIn}
                onLogout={onLogout}
                footerWidth="1348px"
              />
            }
            pageTitle={pageTitle}
            {...otherPageProps}
            toc={makePageContents(blocks)}
            pageType={pageType}
            retrieveRelatedContent={retrieveRelatedContent}
          />
        );
      case "SubjectPage":
        return (
          <CMSSubjectPage
            header={
              <Header
                navigation={navigation}
                metaTitle={metaTitle}
                metaDesc={metaDescription}
                retrieveSearchResults={retrieveSearchResults}
                searchResults={searchResults}
                setSearchTerm={setSearchTerm}
                sitewideAlert={sitewideAlert}
              />
            }
            content={blocks}
            footer={
              <Footer
                footerLogoURL={footerLogoURL}
                showLogout={isLoggedIn}
                onLogout={onLogout}
                footerWidth="1348px"
              />
            }
            sidebarOnRight
            mainContent={[
              <Box key="test" padding="0">
                {blocks[0]}
              </Box>
            ]}
            sidebarContent={isEmpty(secondaryBlocks) ? null : secondaryBlocks}
            secondaryContent={blocks.slice(1)}
            mainContentMinWidth={isMobile ? "33rem" : "50rem"}
            pageTitle={pageTitle}
            {...otherPageProps}
            toc={makePageContents(blocks)}
            pageType={pageType}
          />
        );
      case "ActionPage":
        return (
          <CMSActionPage
            header={
              <Header
                navigation={navigation}
                metaTitle={metaTitle}
                metaDesc={metaDescription}
                retrieveSearchResults={retrieveSearchResults}
                searchResults={searchResults}
                setSearchTerm={setSearchTerm}
                sitewideAlert={sitewideAlert}
              />
            }
            footer={
              <Footer
                footerLogoURL={footerLogoURL}
                showLogout={isLoggedIn}
                onLogout={onLogout}
                footerWidth="1348px"
              />
            }
            sidebarOnRight
            content={blocks}
            mainContent={[
              <Box key="test" padding="0">
                {blocks[0]}
              </Box>
            ]}
            sidebarContent={isEmpty(secondaryBlocks) ? null : secondaryBlocks}
            secondaryContent={blocks.slice(1)}
            mainContentMinWidth={isMobile ? "11.5rem" : "47.5rem"}
            pageTitle={pageTitle}
            toc={makePageContents(blocks)}
            numberOfBlocks={blocks.length}
            subtitle={content?.slug?.page?.subtitle}
            callToAction={getPageCallToActionLink(
              content?.slug?.page?.callToActionLink
            )}
            {...otherPageProps}
          />
        );
      default:
        return <Loading />;
    }
  };

  return (
    <Fragment>
      <HashLinkObserver />
      <Box padding="0" key="loading-container">
        {retrieving ? <Loading key="loading" /> : page()}
      </Box>
    </Fragment>
  );
};

export default withWindowSize(CMS);
