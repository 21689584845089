import { createFormState, required } from "redux-freeform";

const formConfig = {
  firstName: {
    validators: [required()]
  },
  lastName: {
    validators: [required()]
  }
};

export const { reducer, mapStateToProps, mapDispatchToProps } = createFormState(
  formConfig
);
