import React, { useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = ({ redirectTo }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(redirectTo, { state: { pageType: "redirect" } });
  }, []);

  return <Fragment />;
};

export default Redirect;
