// Functions for reading and writing to a data store.

//

export function setSessionItem(key, value) {
  return setItemForStore(global.sessionStorage, "Session", key, value);
}

export function getSessionItem(key) {
  return getItemForStore(global.sessionStorage, key);
}

export function removeSessionItem(key) {
  return removeItemForStore(global.sessionStorage, key);
}

export function setLocalItem(key, value) {
  return setItemForStore(global.localStorage, "Local", key, value);
}

export function getLocalItem(key) {
  return getItemForStore(global.localStorage, key);
}

export function removeLocalItem(key) {
  return removeItemForStore(global.localStorage, key);
}

// Private functions

function setItemForStore(store, storeName, key, value) {
  let result = null;
  try {
    store.setItem(key, value);
    result = value;
  } catch (e) {
    console.error(
      `Could not store value for key '${key}'.  ${storeName} storage is unavailable: ${e.message}`
    );
  }
  return result;
}

function getItemForStore(store, key) {
  return store.getItem(key);
}

function removeItemForStore(store, key) {
  const result = getItemForStore(store, key);
  store.removeItem(key);
  return result;
}
