import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  LoginForm,
  Heading,
  Paragraph,
  CenterStack,
  Box,
  Cluster,
  ButtonWithAction,
  FormContainer,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";

const WelcomeHeader = ({ alertBar, alertBarActions, isMobile }) => (
  <Box padding={isMobile ? "0 16px" : "0 0 16px"}>
    {alertBar.alerts.length > 0 && (
      <Box padding="16px 0">
        <AlertBar {...alertBar} {...alertBarActions} />
      </Box>
    )}
    <Heading
      variant="h1"
      color={constants.colors.CHARADE_GREY}
      weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
    >
      Customer Management
    </Heading>
    <Paragraph
      weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
      color={constants.colors.CHARADE_GREY}
      as="h2"
    >
      Please log in with your Revenue Management credentials.
    </Paragraph>
  </Box>
);

const LoginFormBody = ({
  isMobile,
  forms,
  actions,
  showErrors,
  submitting,
  handleSubmit
}) => (
  <Box
    key="item2"
    padding="0"
    width={isMobile ? `auto` : `576px`}
    borderRadius="4px"
    boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
    0px 3px 8px 0px rgb(202, 206, 216)"
  >
    <FormContainer>
      <LoginForm
        {...forms.adminLoginForm}
        {...actions.forms.adminLoginForm}
        handleSubmit={handleSubmit}
        submitting={submitting}
        showErrors={showErrors}
        isMobile={isMobile}
        clearOnDismount
      />
      <Box padding={isMobile ? "1rem 0" : "1.5rem 0"}>
        <Cluster justify="center" align="center">
          <ButtonWithAction
            text={submitting ? "Loading..." : "Log In"}
            isLoading={submitting}
            variant="primary"
            action={handleSubmit}
            extraStyles={`width: 100%`}
            dataQa="Log In"
          />
        </Cluster>
      </Box>
    </FormContainer>
  </Box>
);

const Login = ({
  forms,
  actions,
  alertBars,
  submitting,
  submitLoginForm,
  handleFocusErrors
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [errors, handleErrors] = useState(false);
  const handleSubmit = util.general.generateClickHandler(
    forms.adminLoginForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitLoginForm()
  );

  return (
    <CenterStack
      content={
        <Box padding={isMobile ? "24px 0 48px" : "32px 0 64px"}>
          <WelcomeHeader
            key="WelcomeHeader"
            isMobile={isMobile}
            alertBar={alertBars.adminLoginAlertBar}
            alertBarActions={actions.alertBars.adminLoginAlertBar}
          />
          <LoginFormBody
            key="LoginFormBody"
            isMobile={isMobile}
            forms={forms}
            actions={actions}
            showErrors={errors}
            submitting={submitting}
            handleSubmit={handleSubmit}
          />
        </Box>
      }
    />
  );
};
export default withWindowSize(Login);
