import React from "react";

const CashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      fill="none"
      viewBox="0 0 36 24"
    >
      <rect width="36" height="24" fill="#E8FFEF" rx="1"></rect>
      <path
        fill="#317D4F"
        d="M17.178 5.464v1.372c-1.799.294-3.115 1.407-3.094 3.08.021 1.645 1.05 2.597 2.611 2.884l1.617.308c.924.182 1.365.406 1.365 1.085 0 .609-.553 1.092-1.484 1.092-1.036 0-1.743-.455-1.932-1.267l-2.478.021c.175 1.834 1.414 2.8 3.374 3.038v1.197h2.023V17.07c1.876-.266 3.024-1.33 3.024-2.877 0-1.617-.798-2.751-2.702-3.178l-1.603-.357c-.994-.231-1.267-.497-1.267-1.029 0-.546.511-1.015 1.596-1.015 1.015 0 1.302.511 1.393 1.267l2.415-.014c.05-1.484-1.043-2.695-2.835-3.024V5.464h-2.023z"
      ></path>
    </svg>
  );
};

export default CashIcon;
