import React from "react";
import Box from "../layouts/Box";
import { fallbackValues } from "./DisplayBox.theme";
import { themeComponent } from "../../../util/themeUtils";
import { RED } from "../../../constants/colors";

const DisplayBox = ({ children, themeValues, showError, dataQa = null }) => (
  <Box padding="0.5rem 0 1.5rem 0">
    <Box
      padding="1.5rem"
      background={themeValues.backgroundColor}
      borderSize={showError && "1px"}
      borderColor={showError && RED}
      borderRadius="4px"
      boxShadow={themeValues.boxShadow}
      dataQa={dataQa}
    >
      {children}
    </Box>
  </Box>
);

export default themeComponent(DisplayBox, "DisplayBox", fallbackValues);
