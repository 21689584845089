import React from "react";
import { Box, FormInput } from "../../../atoms";
import { GHOST_GREY } from "../../../../constants/colors";

const SearchBox = ({
  autocompleteValue,
  fields,
  actions,
  placeholder,
  disabled,
  showSearchBox
}) => {
  return (
    <Box padding="0 0 0.5rem">
      {showSearchBox && (
        <FormInput
          autocompleteValue={autocompleteValue}
          showFieldErrorRow={false}
          errorMessages={{}}
          field={fields.searchTerm}
          fieldActions={actions.fields.searchTerm}
          placeholder={placeholder}
          disabled={disabled}
          extraStyles={`
            height: 2.875rem;
            border: 0; 
            border-radius: 0;
            padding: 0.45rem;
            font-size: 0.875rem;
            border-bottom: 1px solid ${GHOST_GREY};
            :focus {
              outline-offset: -3px;
            }
          `}
        />
      )}
    </Box>
  );
};

export default SearchBox;
