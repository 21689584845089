import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../templates.theme";
import { themeComponent } from "../../../util/themeUtils";
import withWindowResize from "../../withWindowSize";
import { Box, Center, Cover } from "../../atoms/layouts";
import { COOL_GREY_05 } from "../../../constants/colors";

const CenterSingle = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = false,
  content,
  themeValues,
  maxWidth = "75rem",
  gutters = "2rem",
  fillPageVertical = false,
  background = COOL_GREY_05
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Box
      padding="0"
      minWidth="100%"
      background={background}
      extraStyles="flex-grow: 1;"
    >
      <Cover
        childGap="0"
        fillCenter
        style={{ minHeight: fillPageVertical ? "100vh" : "initial" }}
      >
        {header ? header : <Box padding="0" />}
        <Box
          padding="0"
          minWidth="100%"
          minHeight="100%"
          extraStyles={`z-index: 1;`}
        >
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Center maxWidth={maxWidth} gutters={gutters} role="main">
            {content}
          </Center>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
};

export default withWindowResize(
  themeComponent(CenterSingle, "Global", fallbackValues)
);
