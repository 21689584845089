import React from "react";
import styled from "styled-components";
import Box from "../layouts/Box";
import Text from "../../atoms/text/";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./Badge.theme";

const StyledBadgeContainer = styled(Box)`
  display: inline-flex;
  padding: 2px 8px;
  align-items: center;
  align-self: flex-start;
  gap: 4px;
  border-radius: 4px;
`;

const StyledBadge = styled(Text)`
  font-family: "Public Sans", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  letter-spacing: 0.2px;
  white-space: nowrap;

  @media screen and (min-width: 1049px) {
    font-size: 12px;
    line-height: 150%; /* 18px */
    letter-spacing: 0.24px;
  }
`;

const Badge = ({ label, Icon, themeValues, iconOnLeft = true, id }) => (
  <StyledBadgeContainer background={themeValues.background} id={id}>
    {iconOnLeft && Icon && (
      <Icon color={themeValues.color} fill={themeValues.color} />
    )}
    <StyledBadge color={themeValues.color}>{label}</StyledBadge>
    {!iconOnLeft && Icon && (
      <Icon color={themeValues.color} fill={themeValues.color} />
    )}
  </StyledBadgeContainer>
);

export default themeComponent(Badge, "Badge", fallbackValues, "success");
