import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { easeIn, easeOut } from "@popmotion/easing";
import { Box } from "../../atoms/layouts";
import { fallbackValues } from "./NavMenu.theme.js";
import { themeComponent } from "../../../util/themeUtils";

const menu = posed.div({
  invisible: {
    left: "-100vw",
    right: "100vw",
    transition: {
      right: {
        ease: easeOut,
        duration: 500
      },
      left: {
        ease: easeOut,
        duration: 500
      }
    }
  },
  visible: {
    left: "0",
    right: "0",
    transition: {
      left: {
        ease: easeIn,
        duration: 500
      },
      right: {
        ease: easeIn,
        duration: 500
      }
    }
  }
});

const ImposterMenu = styled(menu)`
  position: fixed;
  top: ${({ headerSize }) => headerSize};
`;

const NavMenuMobile = ({
  id,
  menuContent = [],
  visible = false,
  headerSize = "72px",
  themeValues
}) => {
  return (
    <ImposterMenu
      id={id}
      initialPose="invisible"
      pose={visible ? "visible" : "invisible"}
      headerSize={headerSize}
    >
      <Box
        width="100vw"
        padding="1rem 0.5rem"
        extraStyles={`position: relative; max-width: 400px; height: calc(100vh - 72px);`}
        background={themeValues.backgroundColor}
      >
        {menuContent}
      </Box>
    </ImposterMenu>
  );
};

export default themeComponent(
  NavMenuMobile,
  "NavMenu",
  fallbackValues,
  "profile"
);
