import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Sidebar,
  Heading,
  ImageBox,
  Paragraph,
  Text,
  ButtonWithLink,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { getCallToActionInfo } from "../../../../util/dataAdapters";

const ContentCardBlock = ({
  title,
  Content_description: description,
  callToActionText,
  callToActionURL,
  callToActionLink,
  image,
  blockPadding = "40px 0",
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);

  const { ctaUrl, ctaText } = getCallToActionInfo(
    callToActionLink,
    callToActionText,
    callToActionURL
  );

  return (
    <Box padding={blockPadding} minWidth="100%">
      <Sidebar
        sidebarOnRight
        fullHeight
        contentMinWidth={isMobile ? "100%" : "500px"}
        childGap="4.5rem"
      >
        <Box padding="0">
          <Stack childGap="1.5rem">
            <Box padding="0" extraStyles={`margin-bottom: 0.5rem;`}>
              <Stack childGap="0.5rem">
                <Box
                  padding="0"
                  minHeight="5px"
                  width={isMobile ? "24px" : "50px"}
                  borderSize={isMobile ? "3px" : "6px"}
                  borderColor={themeValues.lightAccentText}
                  borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
                  key="content-card-accent"
                />
                <Heading
                  variant="h3"
                  color={themeValues.darkText}
                  key="heading"
                  extraStyles={`margin-top: 16px;`}
                  weight={FONT_WEIGHT_SEMIBOLD}
                >
                  {title}
                </Heading>
              </Stack>
            </Box>
            <Box padding="0">
              <Paragraph color={themeValues.darkText} variant="pL">
                {description}
              </Paragraph>
            </Box>
            {ctaUrl && ctaText && (
              <Box padding="0">
                <ButtonWithLink variant="ghost" contentOverride url={ctaUrl}>
                  <Text
                    color={themeValues.linkColor}
                    extraStyles="text-decoration: underline;"
                  >
                    {ctaText}
                  </Text>
                </ButtonWithLink>
              </Box>
            )}
          </Stack>
        </Box>
        {image?.url && (
          <ImageBox
            minHeight="200px"
            minWidth="350px"
            image={image}
            objectFit="contain"
          />
        )}
      </Sidebar>
    </Box>
  );
};

export default withWindowSize(ContentCardBlock);
