import React, { useState, useRef, useEffect } from "react";
import { themeComponent } from "../../../util/themeUtils";
import Text from "../../atoms/text";
import { Box } from "../../atoms/layouts";
import PopupMenuItem from "./popup-menu-item/PopupMenuItem";
import { fallbackValues } from "./PopupMenu.theme";
import { PopupMenuContainer, PopupMenuTriggerButton } from "./PopupMenu.styled";

const PopupMenu = ({
  menuId = "popup-menu",
  menuItems = [],
  themeValues,
  triggerText = "trigger text",
  hasIcon = false,
  icon: Icon,
  iconHelpText = "", // for screen-readers, required if using an icon for trigger
  menuFocus,
  containerExtraStyles,
  textExtraStyles,
  minWidth = "250px",
  maxWidth = "300px",
  height = "auto",
  position,
  transform = "none",
  buttonExtraStyles,
  popupExtraStyles
}) => {
  const {
    hoverColor,
    activeColor,
    menuTriggerColor,
    backgroundColor
  } = themeValues;
  const { top = `${height}px`, right = "auto", bottom = "auto", left = "0" } =
    position ?? {};
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();
  const triggerRef = useRef();
  const toggleMenu = menuState => setIsMenuOpen(menuState);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu or the trigger
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(e.target)
      ) {
        toggleMenu(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <PopupMenuContainer extraStyles={containerExtraStyles}>
      <PopupMenuTriggerButton
        ref={triggerRef}
        action={() => {
          toggleMenu(!isMenuOpen);
        }}
        onKeyDown={e => {
          if (e.key === "Escape") {
            toggleMenu(false);
          }
        }}
        contentOverride
        variant="smallGhost"
        tabIndex="0"
        id={menuId}
        borderRadius="8px"
        aria-haspopup="true"
        aria-expanded={isMenuOpen}
        aria-controls={`${menuId}-container`}
        extraStyles={buttonExtraStyles}
      >
        {hasIcon && (
          <>
            <Icon />
            <Box padding="0" srOnly>
              <Text id={`btn${menuId}_info`}>{iconHelpText}</Text>
            </Box>
          </>
        )}
        {!hasIcon && (
          <Text
            color={menuTriggerColor}
            extraStyles={`&:active { color: ${activeColor}; } &:hover { color: ${hoverColor} }; ${textExtraStyles}`}
          >
            {triggerText}
          </Text>
        )}
      </PopupMenuTriggerButton>
      <Box
        as="div"
        id={`${menuId}-container`}
        ref={menuRef}
        onKeyDown={e => {
          if (e.key === "Escape") {
            toggleMenu(false);
          }
        }}
        background={backgroundColor}
        borderRadius="8px"
        boxShadow={`
          0px 7px 32px 0px rgba(41, 42, 51, 0.2),
          0px 1px 4px 0px rgba(41, 42, 51, 0.2),
          0px 1px 8px -1px rgba(41, 42, 51, 0.3);
        `}
        role="menu"
        aria-labelledby={menuId}
        tabIndex={menuFocus && isMenuOpen ? "0" : "-1"}
        minWidth={minWidth}
        maxWidth={maxWidth}
        extraStyles={`
          display: ${isMenuOpen ? "block" : "none"};
          position: absolute;
          padding: 8px 8px 3px 8px;
          top: ${top}; 
          left: ${left};
          right: ${right};
          bottom: ${bottom};
          height: ${height};
          transform: ${transform};
          ${popupExtraStyles};
        `}
      >
        {menuItems.map((item, index) => (
          <PopupMenuItem
            key={index}
            id={`${menuId}-item-${index}`}
            closeMenuCallback={() => {
              toggleMenu(false);
              // focus back to trigger button when menu closes
              triggerRef.current.focus();
            }}
            {...item}
          />
        ))}
      </Box>
    </PopupMenuContainer>
  );
};

export default themeComponent(PopupMenu, "PopupMenu", fallbackValues);
