import React, { useState, useEffect } from "react";
import { Box, Grid, Pagination, Stack } from "@thecb/components";
import GridCard, { getActionCardDimensions } from "../grid-card/GridCard";
import EmptyGridCard from "../grid-card/EmptyGridCard";
import { GHOST_GREY } from "../../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";

const DirectoryContainer = ({ isMobile, cardMargin, cardWidth, children }) => {
  if (isMobile) {
    return <Stack>{children}</Stack>;
  }

  return (
    <Grid
      columnGap={`${cardMargin}px`}
      minColWidth={`${cardWidth}px`}
      maxColWidth={`${cardWidth}px`}
    >
      {children}
    </Grid>
  );
};

const GridCardsDirectory = ({
  gridCards,
  paginationProps,
  isMobile,
  themeValues
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { cardMargin, cardWidth } = getActionCardDimensions(isMobile);

  const changePage = newPage => {
    setCurrentPage(newPage);
    if (paginationProps.scrollOnPageChange) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    setPageCount(Math.ceil(gridCards.length / paginationProps.itemsPerPage));
    changePage(1);
  }, [gridCards]);

  const renderPagination = () =>
    pageCount > 1 && (
      <Box
        borderRadius="4px"
        boxShadow={`0px 0px 5px 0px ${GHOST_GREY}`}
        padding={isMobile ? "16px" : "24px"}
        width={isMobile ? "100%" : "fit-content"}
        extraStyles="margin: auto;"
      >
        <Pagination
          activeBorderWidth={isMobile ? "2px" : "3px"}
          childGap="24px"
          borderRadius="4px"
          fontSize={isMobile ? "12px" : "14px"}
          buttonHeight={isMobile ? "32px" : "44px"}
          buttonWidth={isMobile ? "32px" : "44px"}
          fontWeight={FONT_WEIGHT_SEMIBOLD}
          currentPage={currentPage}
          pageCount={pageCount}
          setCurrentPage={({ pageNumber }) => {
            changePage(pageNumber);
          }}
          pageNext={() => changePage(currentPage + 1)}
          pagePrevious={() => changePage(currentPage - 1)}
        />
      </Box>
    );

  const renderPage = () => {
    const startIndex = (currentPage - 1) * paginationProps.itemsPerPage;
    const pageItems = gridCards.slice(
      startIndex,
      startIndex + paginationProps.itemsPerPage
    );

    return (
      <DirectoryContainer
        isMobile={isMobile}
        cardMargin={cardMargin}
        cardWidth={cardWidth}
      >
        {pageItems.map((page, index) => (
          <Stack key={`action-card-${index}`}>
            <GridCard
              type={page.type}
              title={page.title}
              description={page.description}
              linkText={page.linkText}
              href={page.url}
              themeValues={themeValues}
            />
          </Stack>
        ))}
      </DirectoryContainer>
    );
  };

  if (gridCards.length === 0) {
    return <EmptyGridCard />;
  }

  return (
    <Box padding="0 0 16px">
      <Stack fullHeight childGap="16px">
        {renderPage()}
        {renderPagination()}
      </Stack>
    </Box>
  );
};
export default GridCardsDirectory;
