import React from "react";

import { Popover, Text } from "@thecb/components";

import { transactionStatusText } from "/util/transactionHistory";
import { WHITE } from "/constants/colors";

const TransactionStatusPopover = ({ id, color, Icon, status }) => {
  const popoverID = `transaction-${id}`;
  const displayText = transactionStatusText(status);
  return (
    <Popover
      position={{
        top: "-48px",
        left: "auto",
        right: "calc(-50% - 2px)"
      }}
      popoverID={popoverID}
      minWidth={0}
      hasIcon={true}
      icon={() => <Icon color={color} />}
      iconHelpText={displayText}
      content={
        <Text color={WHITE} fontSize="14px" extraStyles="white-space: nowrap">
          {displayText}
        </Text>
      }
      buttonExtraStyles="min-width: 0; margin: 0"
      popoverExtraStyles="padding: 4px 8px"
      borderColor={color}
      backgroundColor={color}
    />
  );
};

export default TransactionStatusPopover;
