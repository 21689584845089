import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Cluster,
  Stack,
  Heading,
  Paragraph,
  withWindowSize
} from "@thecb/components";

const TaglineBlock = ({
  themeValues,
  headline = "",
  paragraph = "",
  blockPadding = "40px 0"
}) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Box padding={blockPadding} minWidth="100%">
      <Cluster
        justify={isMobile ? "flex-start" : "center"}
        align="center"
        minWidth="100%"
        childGap={isMobile ? "2rem" : "5rem"}
      >
        <Box padding="0" maxWidth="384px">
          <Heading variant="h3" color={themeValues.darkText}>
            {headline}
          </Heading>
        </Box>
        <Box padding="0" maxWidth="760px">
          <Stack childGap="16px">
            <Paragraph variant="pL" color={themeValues.darkText}>
              {paragraph}
            </Paragraph>
          </Stack>
        </Box>
      </Cluster>
    </Box>
  );
};

export default withWindowSize(TaglineBlock);
