import React from "react";

const RejectedVelocityIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -592.000000)"
        fill="#D11053"
      >
        <path
          d="M80,624 C88.836556,624 96,616.836556 96,608 C96,599.163444 88.836556,592 80,592 C71.163444,592 64,599.163444 64,608 C64,616.836556 71.163444,624 80,624 Z M77.4246212,601.282486 L78.131728,601.989592 C78.3269901,602.184855 78.3269901,602.501437 78.131728,602.696699 L73.8281068,606.999039 L81.5,607 C81.7761424,607 82,607.223858 82,607.5 L82,608.5 C82,608.776142 81.7761424,609 81.5,609 L73.8271068,608.999039 L78.131728,613.303301 C78.3269901,613.498563 78.3269901,613.815145 78.131728,614.010408 L77.4246212,614.717514 C77.2293591,614.912777 76.9127766,614.912777 76.7175144,614.717514 L70.3535534,608.353553 C70.1582912,608.158291 70.1582912,607.841709 70.3535534,607.646447 L76.7175144,601.282486 C76.9127766,601.087223 77.2293591,601.087223 77.4246212,601.282486 Z M89.75,607 C89.8880712,607 90,607.111929 90,607.25 L90,608.75 C90,608.888071 89.8880712,609 89.75,609 L88.25,609 C88.1119288,609 88,608.888071 88,608.75 L88,607.25 C88,607.111929 88.1119288,607 88.25,607 L89.75,607 Z M85.75,607 C85.8880712,607 86,607.111929 86,607.25 L86,608.75 C86,608.888071 85.8880712,609 85.75,609 L84.25,609 C84.1119288,609 84,608.888071 84,608.75 L84,607.25 C84,607.111929 84.1119288,607 84.25,607 L85.75,607 Z"
          id="status-icon---rejected-velocity"
        ></path>
      </g>
    </g>
  </svg>
);

export default RejectedVelocityIcon;
