import React from "react";

import FormSelect from "../form-select";
import { placeHolderOption, placeHolderOptionUS, getOptions } from "./options";

const FormStateDropdown = ({
  labelTextWhenNoError,
  errorMessages,
  field,
  fieldActions,
  showErrors,
  countryCode,
  isRequired = false
}) => {
  const placeholder =
    countryCode === "US" ? placeHolderOptionUS : placeHolderOption;
  const options = [placeholder, ...getOptions(countryCode)];
  return (
    <FormSelect
      options={options}
      field={field}
      fieldActions={fieldActions}
      labelTextWhenNoError={labelTextWhenNoError}
      errorMessages={errorMessages}
      showErrors={showErrors}
      autocompleteValue="address-level1"
      isRequired={isRequired}
    />
  );
};
export default FormStateDropdown;
