import React from "react";
import { ifElse, isNil, always } from "ramda";
import { fallbackValues } from "./ProcessingFee.theme";
import { themeComponent } from "../../../util/themeUtils";
import Box from "../layouts/Box";
import Alert from "../alert";
import { displayCurrency, formatPercent } from "../../../util/general";
const ProcessingFee = ({
  feeValue = 0,
  feeName = "",
  feeType,
  feeMinimum = 0,
  hide,
  themeValues
}) => (
  <Box
    padding="0 1.5rem 1.5rem"
    background={themeValues.backgroundColor}
    hiddenStyles={hide}
  >
    <Alert
      heading="Processing Fee"
      text={`There is a processing fee of ${
        feeType === "FLAT"
          ? `${displayCurrency(feeValue)}`
          : `${formatPercent(feeValue * 100)}%`
      } ${ifElse(
        isNil,
        always(""),
        a => `with a minimum of ${displayCurrency(a)} `
      )(feeMinimum)}on all ${feeName} payments.`}
      variant="info"
      showQuitLink={false}
    />
  </Box>
);

export default themeComponent(
  ProcessingFee,
  "ProcessingFee",
  fallbackValues,
  "default"
);
