import React from "react";

const TimeoutImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 458 308"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-492 -168)">
          <g transform="translate(492 168)">
            <path
              fill="#EAF4EB"
              d="M286.327 294.916c0 7.133-26.041 12.916-58.164 12.916-32.123 0-58.163-5.783-58.163-12.916S196.04 282 228.163 282s58.164 5.783 58.164 12.916"
            ></path>
            <g
              stroke="#45B770"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="8.362"
              transform="translate(204 268)"
            >
              <path d="M15.817 -2.13162821e-14L15.817 24.795 3.55271368e-15 24.795"></path>
              <path d="M31.3102 -2.30926389e-14L31.3102 25.574 47.1272 25.574"></path>
            </g>
            <path
              fill="#FEFEFE"
              d="M107.968 73h239.721a6.969 6.969 0 016.969 6.968v178.398a6.969 6.969 0 01-6.969 6.968H107.968a6.968 6.968 0 01-6.968-6.968V79.968A6.968 6.968 0 01107.968 73"
            ></path>
            <path fill="#E4E6EB" d="M101 99L355 99 355 73 101 73z"></path>
            <g
              stroke="#3B414D"
              strokeLinecap="round"
              strokeWidth="5.575"
              transform="translate(197.811 141.913)"
            >
              <path
                strokeLinejoin="round"
                d="M0 5.575C1.168 2.317 4.16 0 7.666 0c3.505 0 6.497 2.317 7.665 5.575"
              ></path>
              <path
                strokeLinejoin="round"
                d="M46 5.575C47.168 2.317 50.16 0 53.666 0c3.505 0 6.497 2.317 7.665 5.575"
              ></path>
              <path
                d="M50.38 44.087h0a6.886 6.886 0 01-6.886 6.886h-26.42a6.886 6.886 0 01-6.885-6.886"
                transform="matrix(1 0 0 -1 0 95.06)"
              ></path>
            </g>
            <path
              fill="#3B414D"
              d="M124 83a3 3 0 110 6 3 3 0 010-6zm-10 0a3 3 0 110 6 3 3 0 010-6zm20 0a3 3 0 110 6 3 3 0 010-6z"
            ></path>
            <path
              stroke="#45B770"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="8"
              d="M99.441 205.181L77.181 224.512"
            ></path>
            <path
              stroke="#45B770"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="8"
              d="M412.8955 190.764648L381.126953 224.790039 356 205.267859"
            ></path>
            <path
              stroke="#45B770"
              strokeLinecap="round"
              strokeWidth="8"
              d="M396.5 189.5L429.5 189.5"
            ></path>
            <path
              stroke="#45B770"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="8"
              d="M137.079 231.103a7.605 7.605 0 000 15.211h6.644"
            ></path>
            <g transform="translate(377 63)">
              <g>
                <path
                  fill="#FFF"
                  d="M66.669 116.462H5.122a5.122 5.122 0 110-10.243h61.547a5.122 5.122 0 110 10.243"
                ></path>
                <path
                  fill="#E4F4FD"
                  d="M15.126 71.521l20.77-13.29 20.769 13.29a19.123 19.123 0 018.815 16.106v18.591H6.311v-18.59c0-6.52 3.323-12.592 8.815-16.107z"
                ></path>
                <path
                  fill="#E4F4FD"
                  d="M56.665 44.94l-20.77 13.292L15.126 44.94a19.12 19.12 0 01-8.815-16.106V10.244H65.48v18.59a19.12 19.12 0 01-8.815 16.107z"
                ></path>
                <path
                  fill="#FFF"
                  d="M66.669 10.244H5.122A5.122 5.122 0 115.122 0h61.547a5.122 5.122 0 110 10.244z"
                ></path>
              </g>
              <g stroke="#3B414D" strokeLinecap="round" strokeWidth="6">
                <path d="M66.669 116.462H5.122a5.122 5.122 0 110-10.243h61.547a5.122 5.122 0 110 10.243z"></path>
                <path d="M15.126 71.521l20.77-13.29 20.769 13.29a19.123 19.123 0 018.815 16.106v18.591H6.311v-18.59c0-6.52 3.323-12.592 8.815-16.107z"></path>
                <path d="M56.665 44.94l-20.77 13.292L15.126 44.94a19.12 19.12 0 01-8.815-16.106V10.244H65.48v18.59a19.12 19.12 0 01-8.815 16.107z"></path>
                <path d="M66.669 10.244H5.122A5.122 5.122 0 115.122 0h61.547a5.122 5.122 0 110 10.244z"></path>
              </g>
            </g>
            <path
              stroke="#3B414D"
              strokeLinecap="round"
              strokeWidth="8"
              d="M27.663 247.376l-15.18.348M401 248l-44.96-.276m-256.445-.348l-52.372.348M454 248h-34"
            ></path>
            <path
              stroke="#3B414D"
              strokeWidth="6"
              d="M107.969 73H347.69a6.969 6.969 0 016.968 6.969v178.397a6.968 6.968 0 01-6.968 6.968H107.969a6.969 6.969 0 01-6.969-6.968V79.969A6.969 6.969 0 01107.969 73zM104 99h248"
            ></path>
            <path
              stroke="#45B770"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="8"
              d="M77.181 224.512L127.905 236.375"
            ></path>
            <path
              fill="#B8E4F4"
              d="M95.74 141.218c5.753 0 10.652 3.602 12.599 8.67a5.733 5.733 0 016.092 1.987 8.512 8.512 0 013.505-.757 8.557 8.557 0 110 17.114H81.002a8.557 8.557 0 111.69-16.945c1.523-5.792 6.78-10.069 13.049-10.069zM365.823 82c9.17 0 16.7 7.01 17.532 15.963a10.567 10.567 0 016.175-1.99c5.868 0 10.625 4.757 10.625 10.625 0 5.869-4.757 10.625-10.625 10.625h-48.094c-6.868 0-12.435-5.566-12.435-12.434 0-6.868 5.567-12.435 12.435-12.435 2.791 0 5.36.931 7.435 2.484C350.952 87.432 357.75 82 365.822 82zM23.296 75.456c5.752 0 10.651 3.602 12.598 8.669a5.733 5.733 0 011.559-.22c1.84 0 3.476.866 4.532 2.208a8.52 8.52 0 013.506-.757 8.557 8.557 0 110 17.114H8.557a8.557 8.557 0 111.69-16.945c1.523-5.792 6.78-10.07 13.049-10.07zM170.219 8.845c10.946 0 19.935 8.367 20.927 19.054a12.618 12.618 0 017.372-2.375c7.004 0 12.683 5.679 12.683 12.684 0 7.004-5.68 12.683-12.683 12.683h-57.41c-8.197 0-14.843-6.646-14.843-14.844 0-8.197 6.646-14.843 14.843-14.843 3.333 0 6.398 1.112 8.876 2.966 2.485-8.841 10.599-15.325 20.235-15.325zM297.892 0c5.752 0 10.651 3.602 12.598 8.669a5.728 5.728 0 011.56-.219c1.84 0 3.475.865 4.531 2.207a8.516 8.516 0 013.506-.757 8.557 8.557 0 110 17.114h-36.935a8.557 8.557 0 01-8.556-8.557 8.557 8.557 0 0110.248-8.388C286.366 4.277 291.622 0 297.892 0z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimeoutImage;
