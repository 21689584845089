import React from "react";

const IconQuitLarge = ({ fill = "#091325" }) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="quit-large-path-1"
        points="18.9999989 6.40999946 17.589999 4.9999997 11.9999993 10.5899995 6.40999946 4.9999997 4.9999997 6.40999946 10.5899995 11.9999993 4.9999997 17.589999 6.40999946 18.9999989 11.9999993 13.409999 17.589999 18.9999989 18.9999989 17.589999 13.409999 11.9999993"
      ></polygon>
    </defs>
    <g
      id="quit-large-[D]-Cart---Slideout"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="quit-large-[D]-Cart-Slideout---Standard"
        transform="translate(-741.000000, -34.000000)"
      >
        <g
          id="quit-large-Close-Icon"
          transform="translate(724.000000, 17.000000)"
        >
          <g id="quit-large-Group" transform="translate(12.000000, 12.000000)">
            <mask id="quit-large-mask-2" fill="white">
              <use xlinkHref="#quit-large-path-1"></use>
            </mask>
            <use
              id="quit-large-Fill-2"
              fill={fill}
              xlinkHref="#quit-large-path-1"
            ></use>
            <g mask="url(#quit-large-mask-2)" fill={fill} id="quit-large-⮑🎨">
              <g>
                <rect
                  id="quit-large-➝✏️"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconQuitLarge;
