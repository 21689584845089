import React, { useContext, memo, useRef } from "react";
import { matchRoutes } from "react-router";
import { Box, Modal, withWindowSize } from "@thecb/components";
import { ThemeContext } from "styled-components";
import { connect } from "react-redux";
import Slider from "../../../../components/slider";
import SliderPanel from "../../../../components/slider-panel";
import {
  SliderCloseButton,
  SliderPosition
} from "../../../../components/slider/Slider";
import MultiCart from "./index";
import {
  mapStateToProps,
  mapDispatchToProps
} from "./state/ShoppingCart.state";
import { checkoutPaths } from "../payment";
import { MultiCartButton } from "./components/MultiCartButton";

const MiniCart = ({
  cartEnabled,
  cartModalOpen,
  cartSliderOpen,
  checkoutPaymentComplete,
  clearMultiCartModalContent,
  closeCartSlider,
  closeMultiCartModal,
  isLoggedIn,
  items,
  modalContents,
  multiCartItemsCount,
  multiCartTotal,
  onCartPage,
  openCartSlider,
  openMultiCartModal,
  total
}) => {
  const { isMobile } = useContext(ThemeContext);
  const multiCartButtonRef = useRef();
  const location = window.location;
  const checkoutTestPaths = checkoutPaths.map(path => ({ path }));
  const isCheckoutPath = matchRoutes(checkoutTestPaths, location) !== null;
  const checkoutInProgress = isCheckoutPath && !checkoutPaymentComplete;

  return (
    <>
      {cartEnabled && (
        <>
          <Box
            padding="0"
            minHeight="65px"
            extraStyles={`display: flex; justify-content: center; align-items: center;`}
          >
            <MultiCartButton
              total={multiCartTotal ?? total}
              isMobile={isMobile}
              itemsCount={multiCartItemsCount ?? items?.length}
              openCart={openCartSlider}
              cartSliderOpen={cartSliderOpen}
              ref={multiCartButtonRef}
            />
          </Box>
          <Slider
            isOpen={cartSliderOpen}
            isMobile={isMobile}
            position={SliderPosition.DOCUMENT_TOP}
            triggerRef={multiCartButtonRef}
            closeSlider={closeCartSlider}
          >
            {!isMobile && (
              <SliderCloseButton
                closeAction={closeCartSlider}
                ariaLabel={"Close Cart Modal"}
              />
            )}
            <SliderPanel status="onScreen" key="cart-slider">
              {(!onCartPage || (onCartPage && cartSliderOpen)) && (
                <MultiCart
                  inSlider
                  checkoutInProgress={checkoutInProgress}
                  isLoggedIn={isLoggedIn}
                />
              )}
            </SliderPanel>
          </Slider>
          {modalContents && (
            <Modal
              blurUnderlay={true}
              cancelAction={closeMultiCartModal}
              cancelButtonText={"Cancel"}
              cancelButtonVariant={"primary"}
              continueAction={() => {
                if (modalContents.continueAction) {
                  modalContents.continueAction();
                }
                closeMultiCartModal();
                clearMultiCartModalContent();
              }}
              continueButtonText={"Start New Checkout"}
              continueButtonVariant={"secondary"}
              defaultWrapper={true}
              hideModal={closeMultiCartModal}
              modalBodyText={modalContents.body ?? ""}
              modalHeaderText={modalContents.heading ?? ""}
              modalOpen={cartModalOpen}
              onlyCloseButton={false}
              showCloseIconButton={true}
              showModal={openMultiCartModal}
              useDangerButton={false}
              version="v2"
            />
          )}
        </>
      )}
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWindowSize(memo(MiniCart)));
