import {
  HOVER_LIGHT_BLUE,
  MATISSE_BLUE,
  MATISSE_BLUE_DARK
} from "../../../constants/colors";

const selectedColor = `${MATISSE_BLUE}`;
const hoverColor = `${HOVER_LIGHT_BLUE}`;
const focusColor = `${MATISSE_BLUE_DARK}`;

export const fallbackValues = {
  selectedColor,
  hoverColor,
  focusColor
};
