import React from "react";

import {
  ArrowLeftCircleIconMedium,
  ArrowLeftCircleIconSmall,
  ChargebackIconMedium,
  ChargebackIconSmall,
  ChargebackReversalIconMedium,
  ChargebackReversalIconSmall,
  RefundIconMedium,
  RefundIconSmall,
  SuccessfulIconMedium,
  SuccessfulIconSmall,
  XCircleIconMedium,
  XCircleIconSmall
} from "@thecb/components";

import {
  TRANSACTION_STATUS_ACH_RETURN,
  TRANSACTION_STATUS_CHARGEBACK_REVERSAL,
  TRANSACTION_STATUS_CHARGEBACK,
  TRANSACTION_STATUS_REFUNDED,
  TRANSACTION_STATUS_SUCCESSFUL,
  TRANSACTION_STATUS_VOIDED
} from "/constants/transaction_history_constants";

const TransactionStatusIcon = ({ status, isMobile }) => {
  const TRANSACTION_STATUS_ICON_MAPPING = {
    [TRANSACTION_STATUS_SUCCESSFUL]: {
      icon: <SuccessfulIconMedium />,
      mobileIcon: <SuccessfulIconSmall />
    },
    [TRANSACTION_STATUS_VOIDED]: {
      icon: <XCircleIconMedium />,
      mobileIcon: <XCircleIconSmall />
    },
    [TRANSACTION_STATUS_REFUNDED]: {
      icon: <RefundIconMedium />,
      mobileIcon: <RefundIconSmall />
    },
    [TRANSACTION_STATUS_ACH_RETURN]: {
      icon: <ArrowLeftCircleIconMedium />,
      mobileIcon: <ArrowLeftCircleIconSmall />
    },
    [TRANSACTION_STATUS_CHARGEBACK]: {
      icon: <ChargebackIconMedium />,
      mobileIcon: <ChargebackIconSmall />
    },
    [TRANSACTION_STATUS_CHARGEBACK_REVERSAL]: {
      icon: <ChargebackReversalIconMedium />,
      mobileIcon: <ChargebackReversalIconSmall />
    }
  };
  const iconMapping = TRANSACTION_STATUS_ICON_MAPPING[status];
  return isMobile ? iconMapping?.mobileIcon : iconMapping?.icon;
};

export default TransactionStatusIcon;
