import React from "react";
import ModalControlV1 from "./ModalControlV1";
import ModalControlV2 from "./ModalControlV2";

const Modal = ({ version = "v1", ...rest }) => {
  const ModalControl = version === "v1" ? ModalControlV1 : ModalControlV2;
  return <ModalControl {...rest} />;
};

export default Modal;
