import React from "react";
import {
  constants,
  Box,
  ChevronIcon,
  Cluster,
  Motion,
  Title,
  util
} from "@thecb/components";
import PlusIcon from "../icons/PlusIcon";
import DashIcon from "../icons/DashIcon";
import {
  CollapsibleSectionOpenCloseStyle,
  COLLAPSIBLE_Z_INDEX
} from "./CollapsibleSection";

const chevronIconAnimation = {
  open: {
    rotate: "90deg"
  },
  closed: {
    rotate: "0deg"
  }
};

const iconPlusAnimation = {
  open: {
    rotate: "-90deg",
    opacity: 0
  },
  closed: {
    rotate: "0deg",
    opacity: 1
  }
};
const iconDashAnimation = {
  closed: {
    rotate: "90deg",
    opacity: 0
  },
  open: {
    rotate: "0deg",
    opacity: 1
  }
};

const DefaultStyleSectionTitleLine = ({
  customPadding,
  themeValues,
  isMobile,
  supportsTouch,
  toggleSection,
  isOpen,
  id,
  titleComponent,
  openCloseStyle
}) => {
  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      toggleSection();
    }
  };

  const focusBackground = `background: ${constants.colors.GRECIAN_GREY}`;

  return (
    <Box
      padding={customPadding ? customPadding : "0"}
      background={themeValues.headingBackgroundColor}
      onClick={isMobile && supportsTouch ? util.general.noop : toggleSection}
      onTouchEnd={isMobile && supportsTouch ? toggleSection : util.general.noop}
      key="header"
      hoverStyles={`cursor: pointer; ${focusBackground}`}
      activeStyles={`cursor: pointer; ${focusBackground}`}
      tabIndex="0"
      onKeyDown={handleKeyDown}
      extraStyles={`z-index: ${COLLAPSIBLE_Z_INDEX};`}
      role="button"
      aria-expanded={isOpen.toString()}
      aria-controls={id}
      id={`${id}-button`}
    >
      <Cluster justify="space-between" align="center">
        {titleComponent}
        {openCloseStyle === CollapsibleSectionOpenCloseStyle.Chevron ? (
          <Motion
            variants={chevronIconAnimation}
            extraStyles={`display: flex; align-items: center;`}
          >
            <ChevronIcon />
          </Motion>
        ) : (
          <>
            <Motion
              variants={iconPlusAnimation}
              transition={{ duration: 0.5 }}
              extraStyles={isOpen && "display:none;"}
            >
              <PlusIcon size={24} />
            </Motion>
            <Motion
              variants={iconDashAnimation}
              transition={{ duration: 0.5 }}
              extraStyles={!isOpen && "display:none;"}
            >
              <DashIcon size={24} />
            </Motion>
          </>
        )}
      </Cluster>
    </Box>
  );
};

export default DefaultStyleSectionTitleLine;
