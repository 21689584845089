import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { createPaymentScheduleAttempt } from "../../graphql/Queries";
import { push } from "connected-react-router";
import {
  CREATE_PAYMENT_SCHEDULE,
  createPaymentScheduleSuccess,
  createPaymentScheduleFailure,
  accountsAlertBarAction,
  setUpAutopayAlertBarAction,
  addSchedule
} from "../../Profile.state";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  getConstituentID,
  getPaymentInstrumentId,
  getDetailedObligationAssociationId,
  getCustomerManagement
} from "../../Profile.selectors";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const getCreatePaymentScheduleArgs = (key, selectors, state, action) => ({
  endpoint: selectors.getGraphqlServiceEndpoint(state),
  clientSlug: selectors.getClientSlug(state),
  authJwt: isInCustomerManagement
    ? selectors.getAdminAccessToken(state)
    : selectors.getAccessToken(state),
  schedule: {
    constituentID: getConstituentID(state),
    paymentInstrumentId: getPaymentInstrumentId(state[key]),
    subClientSlug:
      selectors
        .getSubclients(state)
        .find(subClient => subClient.path === action?.payload?.obligationSlug)
        ?.path ?? selectors.getSubclientPath(state),
    obligationAssociationId: getDetailedObligationAssociationId(state[key])
  }
});

const createPaymentScheduleEpic = (key, selectors) => (action$, state$) =>
  action$.ofType(CREATE_PAYMENT_SCHEDULE).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        createPaymentScheduleAttempt(
          getCreatePaymentScheduleArgs(key, selectors, state$.value, action)
        )
      ).pipe(
        RxOp.mergeMap(response =>
          Rx.of(
            accountsAlertBarAction(clearAlerts()),
            createPaymentScheduleSuccess([response.createPaymentSchedule]),
            addSchedule(response.createPaymentSchedule),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[key]),
                "/profile/accounts"
              )
            ),
            accountsAlertBarAction(
              addAlert({
                heading: "Autopay Enabled",
                text: "Autopay has been enabled for your account.",
                variant: "success"
              })
            )
          )
        )
      )
    ),
    RxOp.catchError(error =>
      Rx.of(
        setUpAutopayAlertBarAction(clearAlerts()),
        createPaymentScheduleFailure(error),
        setUpAutopayAlertBarAction(
          addAlert({
            heading: "Payment Schedule Error",
            text:
              "An error occurred when enabling autopay for your account. Please try again.",
            variant: "error"
          })
        )
      )
    )
  );

export default createPaymentScheduleEpic;
