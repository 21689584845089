import { createQuery } from "../../../../../util/graphql";
import CreateUser from "./CreateUser.graphql";

export const createUser = ({
  endpoint,
  clientSlug,
  email,
  password,
  firstName,
  lastName
}) =>
  createQuery(endpoint, clientSlug)(CreateUser, {
    user: {
      authentication: {
        email,
        password
      },
      person: {
        firstName,
        lastName
      }
    }
  });
