import PaymentFormCard from "./PaymentFormCard";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./PaymentFormCard.state";

PaymentFormCard.reducer = reducer;
PaymentFormCard.mapStateToProps = mapStateToProps;
PaymentFormCard.mapDispatchToProps = mapDispatchToProps;
export default PaymentFormCard;
