import React, { forwardRef } from "react";
import styled from "styled-components";
import { colors, styleConstants } from "../../../constants";

const { ROYAL_BLUE } = colors;
const { LINK_TEXT_DECORATION } = styleConstants;

/*
  The extracting of props and the disabling of the eslint rule is to stop React from complaining about
  unrecognized DOM attributes.
*/

/* eslint-disable no-unused-vars */
export const StyledExternalLink = styled(
  forwardRef(({ hoverColor, activeColor, extrastyles, ...props }, ref) => (
    <a {...props} ref={ref} />
  ))
)`
  display: flex;
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  font-family: ${({ fontFamily }) => fontFamily};
  line-height: ${({ lineheight }) => lineheight};
  text-decoration: ${LINK_TEXT_DECORATION};

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }

  &:focus {
    outline: 3px solid ${ROYAL_BLUE};
    outline-offset: 2px;
  }

  &:active {
    color: ${({ activeColor }) => activeColor};
  }

  ${({ extrastyles }) => extrastyles}
`;
/* eslint-enable no-unused-vars */
