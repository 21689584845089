import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const VerifiedEmailIcon = ({ themeValues }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <defs>
        <path id="prefix__a" d="M0 0h100v100H0z" />
        <path
          d="M58.462.577c6.37 0 11.533 5.164 11.533 11.533 0 6.37-5.163 11.534-11.533 11.534-1.146 0-2.253-.167-3.298-.479v21.598a3.347 3.347 0 01-3.348 3.348H6.87A34.073 34.073 0 01.192 27.799a34.073 34.073 0 014.26-16.55H46.96C47.4 5.281 52.382.577 58.46.577z"
          id="prefix__c"
        />
        <path
          d="M61.716 33.11c0 6.728 5.454 12.184 12.182 12.184 6.728 0 12.183-5.456 12.183-12.183 0-6.728-5.455-12.182-12.183-12.182s-12.182 5.454-12.182 12.182zm17.206-4.604l1.436 1.436-7.178 7.179-1.436 1.435-4.307-4.307 1.436-1.435 2.87 2.87 7.18-7.178z"
          id="prefix__d"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill={themeValues.primaryColor}>
          <use xlinkHref="#prefix__a" />
        </mask>
        <g mask="url(#prefix__b)">
          <use
            fill={themeValues.primaryColor}
            xlinkHref="#prefix__c"
            transform="translate(15.687 21.252)"
          />
        </g>
        <path
          d="M50.109 12.788a36.58 36.58 0 0124.135 9.041h-.095c-1.259 0-2.47.2-3.604.574a33.797 33.797 0 00-20.436-6.836c-11.968 0-22.49 6.19-28.538 15.544h41.265a11.59 11.59 0 00-.208 2.778H19.956a33.771 33.771 0 00-1.667 3.745l-.235.645.134.083 22.932 14.25c.315.196.639.217.956.065l.118-.065L64.09 39.007c.459.815 1.013 1.57 1.649 2.246L43.66 54.972c-1.19.739-2.604.776-3.817.11l-.19-.11-22.432-13.94a34 34 0 00-1.071 8.493 33.8 33.8 0 005.444 18.45l45.91-.001a1.958 1.958 0 001.953-1.813l.005-.146V43.903c.874.39 1.805.673 2.778.835v21.277a4.737 4.737 0 01-4.52 4.732l-.217.005H23.6c6.224 7.762 15.786 12.731 26.509 12.731 18.754 0 33.958-15.204 33.958-33.958 0-2.982-.387-5.901-1.126-8.698a11.537 11.537 0 001.93-3.208 36.737 36.737 0 011.974 11.906c0 20.289-16.448 36.736-36.736 36.736-20.29 0-36.738-16.447-36.738-36.736 0-20.29 16.448-36.737 36.738-36.737z"
          fill={themeValues.accentColor}
          fillRule="nonzero"
          mask="url(#prefix__b)"
        />
        <use fill={themeValues.subIconColor} xlinkHref="#prefix__d" />
      </g>
    </svg>
  );
};

export default themeComponent(
  VerifiedEmailIcon,
  "Icons",
  fallbackValues,
  "info"
);
