import React, { useLayoutEffect, useState, useEffect, Fragment } from "react";
import {
  BoxWithShadow,
  Center,
  Box,
  PeriscopeFailedIcon,
  Heading,
  Paragraph,
  Cover,
  Spinner
} from "../../atoms";
import { DashboardIframe } from "./PeriscopeDashboardIframe.styled";

/**
 * Component: PeriscopeDashboardIframe
 *
 * Used for rendering Citybase's periscope reporting dashboards in iframes.
 * Receives action props to trigger a request on mount for the iframe's url.
 * Receives a string prop if/when we receive the url result.
 * And receives action props to emit errors from failed iframe loading.
 *
 * The logic and api client methods to handle requests/response,
 * store the url, etc. should be provided from the app consuming this library.
 *
 * If the url request is pending, render a spinner.
 * If the url request failed, render a UI error state.
 **/

const DASHBOARD_SIZE_MESSAGE_TYPE = "dashboard_resize";
const PERISCOPE_ORIGIN = "https://app.periscopedata.com";
const isValidMessage = message =>
  message.isTrusted &&
  message.origin === PERISCOPE_ORIGIN &&
  message.data &&
  message.data.event_type === DASHBOARD_SIZE_MESSAGE_TYPE;

const PeriscopeDashboardIframe = ({
  url,
  requestDashboardUrl,
  periscopeDataPending,
  periscopeDataSuccess,
  periscopeDataFailure,
  periscopeDataRequestSuccess,
  periscopeDataRequestFailure,
  timerMS = 20000
}) => {
  const [height, setHeight] = useState(0);
  let time = { timer: null };

  useEffect(() => {
    time.timer = setTimeout(() => {
      if (!periscopeDataSuccess) {
        periscopeDataRequestFailure();
      }
    }, timerMS);
    return () => clearTimeout(time.timer);
  }, [periscopeDataSuccess]);

  const Dashboard = height => url => (
    <DashboardIframe
      src={url}
      width="100%"
      height={height}
      data-qa="DashboardIframe"
      onLoad={() => {
        let iframe = document.querySelector("iframe");
        iframe.style.display = "initial";
      }}
    />
  );

  const validatePeriscope = message => {
    if (isValidMessage(message)) {
      setHeight(message.data.dashboard_height + 100);
      if (!periscopeDataSuccess) {
        clearTimeout(time.timer);
        periscopeDataRequestSuccess();
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("message", validatePeriscope);
    requestDashboardUrl();
    return () => window.removeEventListener("message", validatePeriscope);
  }, [requestDashboardUrl]);

  return (
    <Fragment>
      <BoxWithShadow
        padding="0"
        minWidth="100%"
        minHeight="592px"
        variant="baseStandard"
        background="#fff"
        borderRadius="4px"
        extraStyles={`display: flex; justify-content: center; align-items: center; flex-direction: column;`}
      >
        {periscopeDataPending && !periscopeDataSuccess && (
          <Cover minHeight="100%" singleChild>
            <Center intrinsic>
              <Spinner size="100" />
            </Center>
          </Cover>
        )}
        {periscopeDataFailure && !periscopeDataSuccess && (
          <Box padding="64px">
            <Center intrinsic>
              <Box padding="0 0 2rem">
                <PeriscopeFailedIcon />
              </Box>
              <Heading variant="h3" weight="700">
                Something Went Wrong
              </Heading>
              <Paragraph variant="p" extraStyles={`text-align: center;`}>
                There was an issue trying to load the dashboard.
              </Paragraph>
              <Paragraph variant="p" extraStyles={`text-align: center;`}>
                Your organization may not have a dashboard configured.
              </Paragraph>
            </Center>
          </Box>
        )}
        {!periscopeDataFailure && url && Dashboard(height)(url)}
      </BoxWithShadow>
    </Fragment>
  );
};

export default PeriscopeDashboardIframe;
