import styled from "styled-components";

export default styled.th`
  padding: ${({ padding = "24px" }) => padding};
  min-width: ${({ minWidth = "initial" }) => minWidth};
  text-align: left;
  &:last-child {
    text-align: right;
  }
  ${({ extraStyles }) => extraStyles}
`;
