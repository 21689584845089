import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Heading from "../../atoms/heading";
import Paragraph from "../../atoms/paragraph";
import { Box, Cluster } from "../../atoms/layouts";
import { CHARADE_GREY, WHITE } from "../../../constants/colors";
import ButtonWithLink from "../../atoms/button-with-link";
import { ForgotPasswordIcon } from "../../atoms/icons";
import withWindowSize from "../../withWindowSize";
import { FormContainer, FormInputColumn } from "../../atoms/form-layouts";
import { fallbackValues } from "./ResetConfirmationForm.theme";
import { createThemeValues } from "../../../util/themeUtils";

const ResetConfirmationForm = () => {
  const themeContext = useContext(ThemeContext);
  const themeValues = createThemeValues(
    themeContext,
    fallbackValues,
    "ResetConfirmationForm"
  );
  const { isMobile } = themeContext;

  return (
    <Box
      padding="0"
      width={isMobile ? `auto` : `576px`}
      borderRadius="4px"
      background={WHITE}
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
    >
      <Box
        background={themeValues.bannerBackgroundColor}
        minWidth="100%"
        padding="0.5rem"
      >
        <Cluster justify="center" align="center">
          <ForgotPasswordIcon />
        </Cluster>
      </Box>
      <FormContainer role="form" aria-label="Reset password confirmation">
        <FormInputColumn childGap="0.5rem">
          <Box padding="0">
            <Heading color={CHARADE_GREY} variant="h5" weight="600">
              Password reset link sent
            </Heading>
          </Box>
          <Box padding="0">
            <Paragraph variant="p" color={CHARADE_GREY}>
              Your password link was sent successfully. Check your email for a
              reset password link.
            </Paragraph>
          </Box>
        </FormInputColumn>
        <Box padding={isMobile ? "1rem 0 0" : "1.5rem 0 0"}>
          <Cluster justify="center" align="center">
            <ButtonWithLink
              url="/login"
              text="Back to Login"
              variant="primary"
              extraStyles={`width: 100%;`}
              linkExtraStyles={`width: 100%;`}
            />
          </Cluster>
        </Box>
      </FormContainer>
    </Box>
  );
};
export default withWindowSize(ResetConfirmationForm);
