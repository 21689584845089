import { MATISSE_BLUE, BRIGHT_GREY, WHITE } from "../../../constants/colors";

const fontWeight = "600";
const fontColor = WHITE;
const textAlign = "left";
const headerBackgroundColor = BRIGHT_GREY;
const imageBackgroundColor = MATISSE_BLUE;

export const fallbackValues = {
  fontWeight,
  fontColor,
  textAlign,
  headerBackgroundColor,
  imageBackgroundColor
};
