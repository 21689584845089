import React from "react";
import { SPACING } from "../../../../constants/style_constants";
import { Box, Stack } from "../../../atoms/layouts";

export const ButtonLayoutWrapper = ({ children = [], isMobile = false }) => {
  const safeChildren = Array.isArray(children) ? children : [children];
  const flexGrow = isMobile ? "flex-grow: 1;" : "";

  return (
    <Box padding={SPACING.MD}>
      <Stack childGap="1rem" direction="row" justify="flex-end">
        {safeChildren.map((child, index) => {
          return (
            <Box padding="0" extraStyles={flexGrow} key={index}>
              {child}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default ButtonLayoutWrapper;
