import React, { useContext } from "react";
import {
  Box,
  Center,
  Cover,
  Heading,
  Stack,
  Text,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import { CHARADE_GREY } from "../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../constants/style_constants";
import NoResultsIcon from "../icons/NoResultsIcon";

/*
 Original code - 
 if you run this branch, note that the stack height matches the sidebar content height
 and the no results info is centered based on that height.
 But one thing to also notice, if I make the outer container/wrapper a Box on desktop
 I lose that height (but after experimenting post PR, I found I can get it by adding 
 extraStyles to the wrapper Box height: 100% )

const Container = ({ isMobile, children }) =>
  isMobile ? (
    <Box padding="50px">{children}</Box>
  ) : (
    <>
      {children}
    </>
  )

const NoResultsFound = () => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Container isMobile={isMobile}>
      <Stack align="center" justify="center" childGap="24px" fullHeight>
        <Box textAlign="center">
          <NoResultsIcon />
        </Box>
        <Box padding={0} textAlign="center">
          <Heading
            color={CHARADE_GREY}
            weight={FONT_WEIGHT_SEMIBOLD}
            extraStyles="font-size: 40px;"
          >
            No Results Found
          </Heading>
          <Text variant="pL" color={CHARADE_GREY}>
            Please try your search again
          </Text>
        </Box>
      </Stack>
    </Container>
  );
};
*/

const NoResultsFound = () => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Box
      minHeight="100%"
      padding={isMobile ? "50px" : 0}
      extraStyles={`height: 100%;`}
    >
      <Center intrinsic={true}>
        <Box>
          <Cover singleChild>
            <Box>
              <Stack>
                <Box textAlign="center">
                  <NoResultsIcon />
                </Box>
                <Box padding={0} textAlign="center">
                  <Heading
                    color={CHARADE_GREY}
                    weight={FONT_WEIGHT_SEMIBOLD}
                    extraStyles="font-size: 40px;"
                  >
                    No Results Found
                  </Heading>
                  <Text variant="pL" color={CHARADE_GREY}>
                    Please try your search again
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Cover>
        </Box>
      </Center>
    </Box>
  );
};
export default withWindowSize(NoResultsFound);
