import React, { forwardRef } from "react";
import { FilterDropdownContainer } from "../MultipleSelectFilter.styled";

const FilterDropdown = forwardRef(
  ({ id, ariaOwns, ariaControls, opened, extraStyles, children }, ref) => {
    return (
      <>
        {opened && (
          <FilterDropdownContainer
            ref={ref}
            id={id}
            role="combobox"
            aria-expanded={opened}
            aria-haspopup="listbox"
            aria-owns={ariaOwns}
            aria-controls={ariaControls}
            extraStyles={extraStyles}
          >
            {children}
          </FilterDropdownContainer>
        )}
      </>
    );
  }
);

export default FilterDropdown;
