import React from "react";
import { fallbackValues } from "./SolidDivider.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Box } from "../layouts";

const SolidDivider = ({ borderColor, borderSize, themeValues }) => (
  <Box
    padding="0"
    minWidth="100%"
    minHeight="1px"
    borderColor={borderColor || themeValues.borderColor}
    borderSize={borderSize || themeValues.borderSize}
    borderWidthOverride={`0px 0px ${borderSize || themeValues.borderSize} 0px`}
  />
);

export default themeComponent(
  SolidDivider,
  "SolidDivider",
  fallbackValues,
  "default"
);
