import React from "react";
import { fallbackValues } from "./TableHead.theme";
import { themeComponent } from "../../../util/themeUtils";
import StyledTableHead from "./TableHead.styled";
import TableRow from "./TableRow";

const TableHead = ({ children, extraStyles = "", themeValues }) => (
  <StyledTableHead
    backgroundColor={themeValues.backgroundColor}
    borderColor={themeValues.borderColor}
  >
    <TableRow extraStyles={extraStyles} hoverEffect={false}>
      {children}
    </TableRow>
  </StyledTableHead>
);

export default themeComponent(TableHead, "TableHead", fallbackValues);
