import React from "react";

const CustomerSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="366"
      height="209"
      viewBox="0 0 366 209"
    >
      <defs>
        <rect
          id="customer-search-path-1"
          width="64"
          height="64"
          x="39"
          y="40"
          rx="6"
        ></rect>
        <filter
          id="customer-search-filter-2"
          width="178.1%"
          height="178.1%"
          x="-39.1%"
          y="-35.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.0823529412 0 0 0 0 0.454901961 0 0 0 0 0.615686275 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-537 -434)">
          <g transform="translate(132 300)">
            <g transform="translate(405 134)">
              <path
                fill="#FFF"
                d="M73 25h224v144.75a2.25 2.25 0 01-2.25 2.25H75.25a2.25 2.25 0 01-2.25-2.25V25z"
              ></path>
              <path
                fill="#292A33"
                fillRule="nonzero"
                d="M294.625 0A6.376 6.376 0 01301 6.377V157h19a3 3 0 01.176 5.995L320 163h-19v6.623a6.376 6.376 0 01-6.375 6.377H75.374A6.375 6.375 0 0169 169.623V163H33a3 3 0 01-.176-5.995L33 157h36V6.377A6.375 6.375 0 0175.374 0h219.251zM297 25H73v144.623c0 1.259.976 2.288 2.211 2.372l.163.005h219.251a2.376 2.376 0 002.37-2.214l.005-.163V25zm39.953 132l26.112.082a2.952 2.952 0 012.935 2.969 2.953 2.953 0 01-2.78 2.945l-.173.004-26.112-.082a2.952 2.952 0 01-2.935-2.969 2.953 2.953 0 012.78-2.945l.173-.004zM17 157a3 3 0 01.176 5.995L17 163H3a3 3 0 01-.176-5.995L3 157h14zm186-52.514a2.515 2.515 0 012.514 2.514c0 4.633-3.761 8.376-8.422 8.51l-.26.004h-23.664c-4.78 0-8.683-3.795-8.683-8.514a2.515 2.515 0 015.024-.165l.006.165c0 1.848 1.517 3.383 3.457 3.48l.196.005h23.664c1.968 0 3.549-1.475 3.648-3.301l.006-.184a2.515 2.515 0 012.514-2.514zM216 39c13.255 0 24 10.745 24 24 0 5.67-1.967 10.881-5.255 14.988l.012.01.13.114 8.51 8.049a5.002 5.002 0 015.193.818l.18.162 17.668 16.728a5 5 0 01-6.696 7.424l-.18-.162-17.667-16.728a5.001 5.001 0 01-1.26-5.348l-8.496-8.037a2.025 2.025 0 01-.132-.137A23.909 23.909 0 01216 87c-13.255 0-24-10.745-24-24C192 49.746 202.745 39 216 39zm0 5c-10.493 0-19 8.507-19 19s8.507 19 19 19 19-8.507 19-19-8.507-19-19-19zm-66 13.986c4.212 0 7.914 2.692 9.362 6.65a2.514 2.514 0 01-4.662 1.88l-.062-.153c-.734-2.009-2.576-3.349-4.639-3.349-1.988 0-3.772 1.246-4.554 3.136l-.083.213a2.515 2.515 0 01-4.724-1.726c1.446-3.958 5.148-6.651 9.361-6.651zM294.624 4H75.374a2.375 2.375 0 00-2.369 2.214L73 6.377V21h224V6.377a2.376 2.376 0 00-2.212-2.372L294.625 4zM81.5 10a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
              ></path>
              <path
                fill="#E4E6EB"
                d="M294.75 4A2.25 2.25 0 01297 6.25V21H73V6.25A2.25 2.25 0 0175.25 4zM81.5 10a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
              ></path>
              <path
                fill="#959CA8"
                fillRule="nonzero"
                d="M216 58c4.207 0 7.903 2.689 9.348 6.641a2.5 2.5 0 01-4.635 1.87l-.061-.152c-.737-2.016-2.583-3.359-4.651-3.359-1.995 0-3.784 1.25-4.57 3.145l-.083.214a2.5 2.5 0 01-4.696-1.718c1.445-3.952 5.142-6.641 9.349-6.641z"
              ></path>
              <use
                fill="#000"
                fillOpacity="1"
                filter="url(#customer-search-filter-2)"
                xlinkHref="#customer-search-path-1"
              ></use>
              <path
                fill="#E4F4FD"
                d="M185 185c28.719 0 52 5.373 52 12s-23.281 12-52 12c-28.718 0-52-5.373-52-12s23.282-12 52-12zM97 44a2 2 0 012 2v52a2 2 0 01-2 2H45a2 2 0 01-2-2V46a2 2 0 012-2h52zM80 70c-3.535 0-6.145 1.941-7.827 5.824C70.192 73.274 67.633 72 64.5 72c-5.912 0-9.773 4.536-11.585 13.608a2 2 0 001.83 2.388l.13.004h19.25l.148-.005a2 2 0 001.812-2.387 37.807 37.807 0 00-.733-3.045L75.184 82h11.893l.15-.005a2 2 0 001.797-2.451C87.534 73.18 84.526 70 80 70zm136-26c10.493 0 19 8.507 19 19s-8.507 19-19 19-19-8.507-19-19 8.507-19 19-19zM64.5 56a7 7 0 100 14 7 7 0 000-14zM216 58c-4.206 0-7.903 2.689-9.348 6.641a2.5 2.5 0 004.635 1.87l.061-.152.083-.214c.786-1.896 2.575-3.145 4.57-3.145 1.994 0 3.782 1.249 4.568 3.145l.083.214.061.152a2.5 2.5 0 004.635-1.87C223.903 60.69 220.207 58 216.001 58zM80 57a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
              ></path>
              <path
                fill="#15749D"
                fillRule="nonzero"
                d="M182 176v20a4 4 0 01-3.8 3.995l-.2.005h-14a4 4 0 01-.2-7.995l.2-.005h10v-16h8zm14 0v16h10a4 4 0 013.995 3.8l.005.2a4 4 0 01-3.8 3.995l-.2.005h-14a4 4 0 01-3.995-3.8L188 196v-20h8zM69 128.5v9l-7.875 4.429 32.876 6.953c1.81-4.055 5.877-6.882 10.604-6.882a4 4 0 01.2 7.995l-.2.005a3.605 3.605 0 00-3.605 3.593v.035-.023a3.606 3.606 0 003.414 3.601l.191.005h6.644a4 4 0 01.2 7.995l-.2.005h-6.644c-5.246 0-9.679-3.48-11.115-8.259l-44.708-9.457c-3.58-.757-4.322-5.493-1.242-7.336l.175-.1L69 128.5zM263.644 96c6.184 0 11.24 4.838 11.586 10.936l38.713 7.13c3.304.609 4.407 4.749 1.927 6.93l-.15.125L301 133v-10.5l2.891-2.151-31.014-5.712a11.586 11.586 0 01-8.881 4.569l-.352.005H257l-.2-.005a4 4 0 010-7.99l.2-.005h6.644l.191-.005a3.606 3.606 0 003.414-3.601c0-1.99-.358-3.259-2.403-5.238a361.04 361.04 0 00-4.65-4.395A3.998 3.998 0 01263.644 96zM97 40a6 6 0 016 6v52a6 6 0 01-6 6H45a6 6 0 01-6-6V46a6 6 0 016-6h52zm0 4H45a2 2 0 00-1.995 1.85L43 46v52a2 2 0 001.85 1.995L45 100h52a2 2 0 001.995-1.85L99 98V46a2 2 0 00-1.85-1.995L97 44zM80 70c4.526 0 7.534 3.181 9.024 9.544a2 2 0 01-1.798 2.45l-.149.006H75.184c.342 1.11.643 2.313.901 3.608a2 2 0 01-1.812 2.387l-.149.005H54.876a2 2 0 01-1.961-2.392C54.727 76.536 58.588 72 64.5 72c3.134 0 5.692 1.275 7.673 3.824C73.855 71.941 76.465 70 80 70zM64.5 56a7 7 0 110 14 7 7 0 010-14zM80 57a5.5 5.5 0 110 11 5.5 5.5 0 010-11z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CustomerSearchIcon;
