import { connect } from "react-redux";
import Payment from "./Payment";
import {
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
} from "./Payment.state";

export {
  checkoutPaths,
  checkoutConfirmationPaths,
  checkoutLocalStorageKey
} from "./constants";
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Payment);
