import React, { Fragment, useContext } from "react";
import { Box, Cluster } from "../layouts";
import ButtonWithAction from "../button-with-action";
import { Center } from "../layouts";
import Text from "../text/Text";
import { ThemeContext } from "styled-components";
import Module from "../../molecules/module/Module";
import Spinner from "../spinner/Spinner";
import { CHARADE_GREY } from "../../../constants/colors";

const WalletName = ({
  mainText, // left side text
  action, // right side link/button action
  text = null, // right side non-hyperlinked text
  actionText = null, // right side hyperlinked text
  disableAction = false,
  linkButtonExtraStyles = "", // hyperlinked text extraStyles
  isLoading = false, // shows a spinner on the left when true
  dataQa = null,
  actionTextPositionMobile = "outside" // whether action text is outside/below box or inside it
}) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Fragment>
      <Module spacingBottom={isMobile ? "0" : "1.5rem"}>
        <Cluster
          align="center"
          justify={!!text || !!actionText ? "space-between" : "flex-start"}
          extraStyles={`
            box-shadow: 0px 1px 2px 0px rgba(${CHARADE_GREY}, 0.1);
            box-shadow: 0px 2px 6px 0px rgba(${CHARADE_GREY}, 0.2);
            box-shadow: 0px -1px 0px 0px rgba(${CHARADE_GREY}, 0.1) inset;
            padding: 1.5rem;
            ${isMobile ? `span {text-align: right;}` : ``}
          `}
        >
          <Box padding="0">
            {isLoading ? (
              <Center as="span" style={{ padding: "24px 24px 0" }} intrinsic>
                <Spinner
                  strokeWidth="2"
                  size="24"
                  radius="10"
                  cx="12"
                  cy="12"
                />
              </Center>
            ) : (
              mainText && <Text>{mainText}</Text>
            )}
          </Box>
          {(actionTextPositionMobile === "inside" || !isMobile) && (
            <Box padding="0">
              {text && <Text variant="pXS">{text}</Text>}
              {(text || actionText) && <>&nbsp;</>}
              {action && actionText && (
                <ButtonWithAction
                  disabled={disableAction}
                  text={actionText}
                  dataQa={dataQa}
                  action={action}
                  variant="smallGhost"
                  extraStyles={`
                    margin: 0;
                    min-width: 0;
                    span {font-size: 0.75rem;}
                    ${linkButtonExtraStyles}
                `}
                />
              )}
            </Box>
          )}
        </Cluster>
      </Module>
      {!!isMobile && actionTextPositionMobile === "outside" && (
        <Cluster
          align="center"
          justify={text || actionText ? "flex-end" : "flex-start"}
          extraStyles="margin-top: 0.5rem;"
        >
          {text && <Text extraStyles="font-size: 0.857rem;">{text}</Text>}
          {(text || actionText) && <>&nbsp;</>}
          {action && actionText && (
            <ButtonWithAction
              text={actionText}
              action={action}
              variant="smallGhost"
              disabled={disableAction}
              extraStyles={`
                margin: 0;
                min-width: 0;
                span {font-size: 0.857rem;}
                ${linkButtonExtraStyles}
              `}
            />
          )}
        </Cluster>
      )}
    </Fragment>
  );
};

export default WalletName;
