import React, { useContext } from "react";
import { Box, withWindowSize } from "@thecb/components";
import { ThemeContext } from "styled-components";
import { PEWTER_GREY } from "../../../../constants/colors";
import { getActionCardDimensions } from "./GridCard";

const EmptyGridCard = () => {
  const { isMobile } = useContext(ThemeContext);
  const dimensions = getActionCardDimensions(isMobile);

  return (
    <Box
      borderRadius={4}
      maxWidth={`${dimensions.cardWidth}px`}
      minWidth={`${dimensions.cardWidth}px`}
      minHeight={`${dimensions.cardHeight}px`}
      background={PEWTER_GREY}
    />
  );
};

export default withWindowSize(EmptyGridCard);
