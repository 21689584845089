import React from "react";

import { AutopayOnIcon, Box } from "@thecb/components";
import { SEA_GREEN, WHITE } from "/constants/colors";

const TransactionAutopayIcon = () => (
  <Box
    padding="0"
    borderRadius="12px"
    background={SEA_GREEN}
    minHeight="24px"
    width="24px"
    extraStyles={`display: flex; justify-content: center; align-items: center;`}
  >
    <AutopayOnIcon color={WHITE} />
  </Box>
);

export default TransactionAutopayIcon;
