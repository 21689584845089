import React, { forwardRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, styleConstants } from "../../../constants";

const { ROYAL_BLUE } = colors;
const { LINK_TEXT_DECORATION } = styleConstants;

/*
  The extracting of props and the disabling of the eslint rule is to stop React from complaining about
  unrecognized DOM attributes.
*/

/* eslint-disable no-unused-vars */
export const StyledInternalLink = styled(
  forwardRef(
    (
      { hoverColor, activeColor, active, color, extrastyles, ...props },
      ref
    ) => <Link {...props} ref={ref} />
  )
)`
  display: flex;
  color: ${({ color, active, activeColor }) =>
    active === "true" ? activeColor : color};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineheight }) => lineheight};
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: ${({ margin }) => margin};
  text-decoration: ${LINK_TEXT_DECORATION};

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }

  &:focus {
    outline: 3px solid ${ROYAL_BLUE};
    outline-offset: 2px;
  }

  &:active {
    color: ${({ activeColor }) => activeColor};
  }

  ${({ extrastyles }) => extrastyles}
`;
/* eslint-enable no-unused-vars */
