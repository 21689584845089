import React, { useState, useEffect, useRef } from "react";
import { fallbackValues } from "./MultipleSelectFilter.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Box } from "../../atoms/layouts";
import { GHOST_GREY, WHITE, CHARADE_GREY } from "../../../constants/colors";
import { noop } from "../../../util/general";
import { FilterContainer } from "./MultipleSelectFilter.styled";
import ActionLinkButton from "./__private__/ActionLinkButton";
import FilterButton from "./__private__/FilterButton";
import FilterDropdown from "./__private__/FilterDropdown";
import SearchBox from "./__private__/SearchBox";
import FilterableList from "./__private__/FilterableList";
import useOutsideClickHook from "../../../hooks/use-outside-click";

const MultipleSelectFilter = ({
  actions,
  autocompleteValue,
  btnContentOverride,
  btnExtraStyles,
  disabled,
  dropdownExtraStyles,
  extraStyles,
  fields,
  filterLabel,
  hasIcon = false,
  icon: Icon,
  maxSelections,
  name = "MultipleSelectFilter",
  onApply = noop,
  onClear = noop,
  options,
  placeholder = "Search",
  searchable = true,
  themeValues,
  truncateBtnTextWidth = "15rem"
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [appliedOptions, setAppliedOptions] = useState([]);
  const openedRef = useRef(opened);

  const handleOnClose = () => {
    if (openedRef.current) {
      setOpened(false);
      actions.fields.searchTerm.set("");
    }
  };
  const containerRef = useOutsideClickHook(() => handleOnClose());
  const dropdownRef = useRef(null);
  const filterButtonRef = useRef(null);
  const applyFilterButtonRef = useRef(null);
  const filterDropdownID = `${name}-filter-dropdown`;
  const listGroupID = `${name}-list-group`;

  useEffect(() => {
    openedRef.current = opened;
    if (!opened) {
      onApply(selectedOptions);
      setAppliedOptions(selectedOptions);
    }
  }, [opened]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
      /*
        Close the dropdown if we hit the Escape key, 
        or if we are tabbing forward away from the last button (apply button) 
        or tabbing backward past the filter button.
      */
      if (
        event.key === "Escape" ||
        (event.key === "Tab" &&
          !event.shiftKey &&
          applyFilterButtonRef.current &&
          applyFilterButtonRef.current.contains(event.target)) ||
        (event.key === "Tab" &&
          event.shiftKey &&
          filterButtonRef.current &&
          filterButtonRef.current.contains(event.target))
      ) {
        handleOnClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <FilterContainer ref={containerRef} extraStyles={`${extraStyles}`}>
      <FilterButton
        ref={filterButtonRef}
        btnContentOverride={btnContentOverride}
        action={() => {
          actions.fields.searchTerm.set("");
          setOpened(!opened);
        }}
        opened={opened}
        backgroundHoverColor={
          appliedOptions?.length
            ? themeValues.secondaryHoverColor
            : themeValues.primaryHoverColor
        }
        backgroundColor={
          appliedOptions?.length
            ? themeValues.secondaryColor
            : themeValues.primaryColor
        }
        textColor={appliedOptions?.length ? WHITE : CHARADE_GREY}
        textHoverColor={
          opened && !appliedOptions?.length ? CHARADE_GREY : WHITE
        }
        name={name}
        filterDropdownID={filterDropdownID}
        hasIcon={hasIcon}
        icon={Icon}
        truncateBtnTextWidth={truncateBtnTextWidth}
        filterLabel={filterLabel}
        selectedOptions={selectedOptions}
        extraStyles={btnExtraStyles}
      ></FilterButton>
      <FilterDropdown
        id={filterDropdownID}
        ref={dropdownRef}
        ariaOwns={listGroupID}
        ariaControls={listGroupID}
        opened={opened}
        extraStyles={dropdownExtraStyles}
      >
        <SearchBox
          showSearchBox={searchable && options?.length > 8}
          autocompleteValue={autocompleteValue}
          fields={fields}
          actions={actions}
          placeholder={placeholder}
          disabled={disabled}
        ></SearchBox>
        <FilterableList
          id={listGroupID}
          options={options}
          appliedOptions={appliedOptions}
          themeValues={themeValues}
          selectedOptions={selectedOptions}
          maxSelections={maxSelections}
          name={name}
          setSelectedOptions={setSelectedOptions}
          searchTerm={fields?.searchTerm}
        ></FilterableList>
        <Box
          padding="0 0.5rem 0.0625rem 0.5rem"
          extraStyles={`
            max-height: 100px; 
            display: flex; 
            flex-flow: row; 
            justify-content: space-between;
            border-top: 1px solid ${GHOST_GREY};
          `}
        >
          <ActionLinkButton
            action={() => {
              setSelectedOptions([]);
              handleOnClose();
              onClear();
            }}
            text="Clear"
            dataQa={`${name}-clear-filters`}
            ariaLabel={"Clear all filters"}
          ></ActionLinkButton>
          <ActionLinkButton
            ref={applyFilterButtonRef}
            action={() => handleOnClose()}
            text="Apply"
            dataQa={`${name}-apply-filters`}
            ariaLabel={"Apply all filters"}
          ></ActionLinkButton>
        </Box>
      </FilterDropdown>
    </FilterContainer>
  );
};

export default themeComponent(
  MultipleSelectFilter,
  "MultipleSelectFilter",
  fallbackValues
);
