import styled from "styled-components";

export const CoverOuterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding};
  min-width: ${({ fillCenter }) => (fillCenter ? "100%" : "auto")};

  > * {
    margin-top: ${({ childGap }) => childGap};
    margin-bottom: ${({ childGap }) => childGap};
  }

  > :first-child {
    margin-top: 0;
  }

  ${({ fillCenter }) => (fillCenter ? "> :nth-child(2) {flex-grow: 1;}" : "")}

  ${({ centerOverride }) =>
    centerOverride
      ? `> :nth-child(2) {margin-top: 0; margin-bottom: auto;}`
      : `> :nth-child(2) {margin-top: auto; margin-bottom: auto;}`}

  > :last-child {
    margin-bottom: 0;
  }
`;
