import React, { Fragment } from "react";
import { safeChildren } from "../../../util/general";
import { CoverOuterContainer } from "./Cover.styled";

/*
  The Cover component is used when you want to have one center element
  and an optional top/header element and/or an optional bottom/footer element.
  The classic use case is for an "above the fold" page layout in which you have a header, footer, and center content of variable size. When the center content is smaller than
  minHeight, the cover expands to minHeight and centers the content, while anchoring
  the optional top element to the top, and the optional bottom element to the bottom.

  If the center content is taller than minHeight, then the entire cover exapnds, while
  still anchorhing the top and bottom elements where they belong.

  Note: This component is only concerned with vertical centering. To also
  horizontally center your content, combine it with the Center component.
  Therefore childGap only sets vertical margins in this component.

  Props:
  fillCenter: 
      If true instead of centering the middle content it will grow to fill the space
      WARNING: May act unexpectedly if children have flex-grow property on them
*/

const Cover = ({
  minHeight = "100%",
  childGap,
  padding,
  fillCenter,
  singleChild,
  centerOverride,
  children,
  ...rest
}) => (
  <CoverOuterContainer
    minHeight={minHeight}
    padding={padding}
    childGap={childGap}
    centerOverride={centerOverride}
    fillCenter={fillCenter}
    {...rest}
  >
    {singleChild ? <div /> : <Fragment />}
    {safeChildren(children, <Fragment />)}
    {singleChild ? <div /> : <Fragment />}
  </CoverOuterContainer>
);

export default Cover;
