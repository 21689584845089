import ForgotPasswordForm from "./ForgotPasswordForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./ForgotPasswordForm.state";

ForgotPasswordForm.reducer = reducer;
ForgotPasswordForm.mapStateToProps = mapStateToProps;
ForgotPasswordForm.mapDispatchToProps = mapDispatchToProps;
export default ForgotPasswordForm;
