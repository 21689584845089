import React from "react";
import { ALTO_GREY } from "../../constants/colors";

export const IconNeutral = ({
  fill = ALTO_GREY,
  width = "18",
  height = "18",
  margin = "0"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ margin }}
    aria-label="Neutral"
  >
    <g fill={fill} fillRule="nonzero" stroke={fill}>
      <g>
        <g>
          <circle cx="9" cy="9" r="8.5"></circle>
        </g>
      </g>
    </g>
  </svg>
);
