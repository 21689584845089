import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box, Cluster, Stack } from "../../atoms/layouts";
import BoxWithShadow from "../../atoms/box-with-shadow";
import { themeComponent } from "../../../util/themeUtils";
import Paragraph from "../../atoms/paragraph";
import Title from "../../atoms/title";
import { fallbackValues } from "./Banner.theme";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";

const Banner = ({
  themeValues,
  heading,
  subHeading,
  image: Image,
  padding = "0.5rem 1.5rem",
  mobilePadding = "0.5rem 1rem",
  minWidth = "100%",
  minHeight = "auto",
  contentSpacing = "3rem",
  mobileContentSpacing = "0.5rem"
}) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <BoxWithShadow
      variant="baseStandard"
      borderRadius="4px"
      background={themeValues.backgroundColor}
      minWidth={minWidth}
      minHeight={minHeight}
      padding={isMobile ? mobilePadding : padding}
    >
      <Cluster
        childGap={isMobile ? mobileContentSpacing : contentSpacing}
        justify="center"
        align="center"
        nowrap
      >
        <Box padding="0" maxWidth={isMobile && "54%"}>
          <Stack childGap="0">
            <Title variant="small" as="h2" weight={FONT_WEIGHT_SEMIBOLD}>
              {heading}
            </Title>
            <Paragraph>{subHeading}</Paragraph>
          </Stack>
        </Box>
        <Box padding="0" extraStyles={isMobile && `> svg { width: 176px; }`}>
          <Image />
        </Box>
      </Cluster>
    </BoxWithShadow>
  );
};

export default themeComponent(Banner, "Banner", fallbackValues);
