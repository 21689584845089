import { createSelector } from "reselect";
import { map, is, max, ifElse, identity, always } from "ramda";
import dompurify from "dompurify";

import { get, prop, compose } from "partial.lenses";
import { selectors as profileSelectors } from "../../../profile/pages/user-profile";
import { round } from "../../../../util/general";
import {
  PARTIAL_AMOUNT,
  NEW_ACH,
  SAVED_ACH,
  NEW_CC,
  SAVED_CC
} from "./Payment.state";
import parse from "html-react-parser";
import { PROFILE_KEY } from "../../../../util/state";

const {
  getSavedBankAccounts,
  getSavedCreditCards,
  getSavedAddresses,
  getSavedEmails,
  getSavedPhoneNumbers
} = profileSelectors;

export const getState = state => state;
export const getPayment = state => state.checkout.payment;
export const getForms = state => state.checkout.forms;
export const getSubClientSlugFromProfile = state =>
  state?.global?.subClientPath?.data ?? null;
export const getSubClientSlugFromPayment = state =>
  state?.checkout?.payment?.subClientSlug ?? null;
export const getProfilePaymentStatus = state =>
  state?.checkout?.payment?.isProfilePayment ?? false;
export const getWallet = state =>
  state?.global?.settings?.data?.walletEnabled ?? null;
export const getWalletFromLocalStorage = state =>
  state?.checkout?.localStorage?.walletEnabled ?? null;
export const getProfilePaymentStatusFromStorage = state =>
  state?.checkout?.localStorage?.isProfilePayment ?? false;
export const getExpiredSession = state =>
  state?.checkout?.isExpiredSession ?? false;
export const getIsGuestCheckout = state =>
  state?.checkout?.payment?.isGuestCheckout ?? false;
export const getVisitIdFromState = state =>
  state?.checkout?.visitId?.value?.visitId;
export const getCartId = state => state?.checkout?.payment?.cartId;

/* Compound Selectors */

export const getPaymentComplete = createSelector(
  [getPayment],
  payment => payment.complete
);
const updateLineItemsWithPartialAmounts = (payment, lineItems) => {
  return lineItems.map(
    ({ amount, description, subDescription, customAttributes, id }) => ({
      amount:
        payment.paymentAmount === PARTIAL_AMOUNT
          ? parseInt(payment.partialAmountForm[id].rawValue)
          : amount,
      quantity: 1,
      customAttributes,
      description,
      subDescription
    })
  );
};
// gets the line items from state (payment.lineItems before payment is complete, processedLineItems after)
// we have two sets of line items to resolve isssues with accidental double payments after expired sessions
// or to prevent a user from refreshing the receipt page and paying twice
export const getProcessedLineItems = createSelector([getPayment], payment =>
  updateLineItemsWithPartialAmounts(payment, payment?.processedLineItems ?? [])
);
export const getLineItems = createSelector([getPayment], payment =>
  updateLineItemsWithPartialAmounts(payment, payment.lineItems)
);

// selects the approrpiate line items for calculating subtotal and total
const getLineItemsForCalculation = createSelector(
  [getPaymentComplete, getLineItems, getProcessedLineItems],
  (paymentComplete, lineItems, processedLineLitems) =>
    paymentComplete ? processedLineLitems : lineItems
);

export const getSubtotal = createSelector(
  [getPayment, getLineItemsForCalculation],
  (payment, lineItems) => {
    const sum =
      payment.paymentAmount === PARTIAL_AMOUNT
        ? Object.values(payment.partialAmountForm)
            .filter(is(Object))
            .filter(o => !o.error)
            .filter(o => o.rawValue)
            .map(formLineItem => parseInt(formLineItem.rawValue) || 0)
            .reduce((pre, cur) => pre + cur, 0)
        : lineItems
            .filter(item => is(Object, item))
            .reduce((pre, cur) => pre + cur.amount, 0);
    return sum;
  }
);

export const getTermsAndConditions = createSelector([getPayment], payment => {
  const termsKey =
    payment.selectedPaymentMethodType === NEW_ACH ||
    payment.selectedPaymentMethodType === SAVED_ACH
      ? "ach"
      : "creditCard";
  const terms =
    typeof payment.terms === "object" ? payment.terms[termsKey] : payment.terms;
  return {
    extended: parse(dompurify.sanitize(terms.extended)),
    summary: parse(dompurify.sanitize(terms.summary))
  };
});

export const getFees = createSelector([getPayment], payment => {
  if (!payment.selectedPaymentMethodType) {
    return [];
  }
  const feeKey =
    payment.selectedPaymentMethodType === NEW_ACH ||
    payment.selectedPaymentMethodType === SAVED_ACH
      ? "ach"
      : "creditCard";
  return payment.potentialFees?.[feeKey] ?? [];
});

export const calculateFeeAmount = (fee, subtotal) =>
  max(
    fee.type === "PERCENTAGE" ? round(fee.value * subtotal, 2) : fee.value,
    ifElse(isNaN, always(0), identity)(parseInt(fee.minimumInCents))
  );

export const getCalculatedFees = createSelector(
  [getFees, getSubtotal],
  (fees, subtotal) =>
    map(
      fee => ({
        label: fee.label,
        amount: calculateFeeAmount(fee, subtotal)
      }),
      fees
    )
);

export const getFeesTotal = createSelector(
  [getCalculatedFees],
  calculatedFees =>
    round(
      calculatedFees.reduce((pre, cur) => pre + cur.amount, 0),
      0
    )
);

export const getTotal = createSelector(
  [getFeesTotal, getSubtotal],
  (feesTotal, subtotal) => feesTotal + subtotal
);

export const getFullAmountTotal = createSelector(
  [getFeesTotal, getLineItemsForCalculation],
  (feesTotal, lineItems) => {
    const lineItemsSum = lineItems
      .filter(item => is(Object, item))
      .reduce((pre, cur) => pre + cur.amount, 0);
    return lineItemsSum + feesTotal;
  }
);

export const getAllowFullPayment = createSelector(
  [getPayment, getFullAmountTotal],
  (payment, total) => {
    return (
      !payment.paymentMaximumInCents || payment.paymentMaximumInCents >= total
    );
  }
);

export const getBillingAddress = createSelector(
  [getPayment, getSavedAddresses(PROFILE_KEY), getForms],
  (payment, savedAddresses, forms) =>
    payment.newAddressSelected
      ? forms.addressForm
      : get(
          [prop(payment.selectedSavedAddress.addressId || "")],
          savedAddresses
        )
);

export const getPayerName = createSelector(
  [
    getPayment,
    getSavedCreditCards(PROFILE_KEY),
    getSavedBankAccounts(PROFILE_KEY),
    getForms
  ],
  (payment, savedCreditCards, savedBankAccounts, forms) => {
    switch (payment.selectedPaymentMethodType) {
      case NEW_CC:
        return forms.creditCardForm.nameOnCard.rawValue;
      case NEW_ACH:
        return forms.achForm.name.rawValue;
      case SAVED_CC:
        return get(
          [payment.selectedSavedCreditCardId || "", "nameOnCard"],
          savedCreditCards
        );
      case SAVED_ACH:
        return get(
          [payment.selectedSavedACHId || "", "nameOnAccount"],
          savedBankAccounts
        );
      default:
        return null;
    }
  }
);

export const getLineItemsWithNonzeroAmounts = createSelector(
  [getLineItems],
  lineItems => lineItems.filter(({ amount }) => amount !== 0)
);

export const getLineItemsForPaymentSubmission = createSelector(
  [getLineItemsWithNonzeroAmounts],
  lineItems =>
    lineItems.map(
      ({ amount, customAttributes, quantity, description, subDescription }) => {
        // Only profile payments should already have these custom attributes
        // For invoice/workflow payments, we use description/subDescription
        const lineItemDesc =
          customAttributes.filter(
            attr => attr.key === "line_item_description"
          )?.[0]?.value ?? description ?? "";
        const lineItemSubDesc =
          customAttributes.filter(
            attr => attr.key === "line_item_sub_description"
          )?.[0]?.value ?? subDescription ?? "";
        // remove duplicate line_item_description/sub_description
        const filteredAttributes = customAttributes.filter(
          attr =>
            attr.key !== "line_item_description" &&
            attr.key !== "line_item_sub_description" &&
            !!attr.value
        );
        return {
          amount,
          customAttributes: [
            ...filteredAttributes,
            { key: "line_item_description", value: lineItemDesc },
            { key: "line_item_sub_description", value: lineItemSubDesc }
          ],
          quantity
        };
      }
    )
);

export const getSavedCard = createSelector(
  [getPayment, getSavedCreditCards(PROFILE_KEY)],
  (payment, savedCreditCards) =>
    payment.selectedPaymentMethodType === SAVED_CC
      ? get(prop(payment.selectedSavedCreditCardId || ""), savedCreditCards)
      : null
);

export const getSavedBank = createSelector(
  [getPayment, getSavedBankAccounts(PROFILE_KEY)],
  (payment, savedBankAccounts) =>
    payment.selectedPaymentMethodType === SAVED_ACH
      ? get(compose(prop(payment.selectedSavedACHId || "")), savedBankAccounts)
      : null
);

export const getSavedCCField = state => fieldName =>
  get(prop(fieldName), getSavedCard(state));

export const getSavedACHField = state => fieldName =>
  get(prop(fieldName), getSavedBank(state));

export const getPhoneNumber = createSelector(
  [getPayment, getForms, getSavedPhoneNumbers(PROFILE_KEY)],
  (payment, forms, savedPhoneNumbers) =>
    payment.newPhoneSelected
      ? forms.phoneForm.phone.rawValue
      : get(
          compose(
            prop(payment.selectedSavedPhone.contactId || ""),
            prop("value")
          ),
          savedPhoneNumbers
        )
);

export const getEmailAddress = createSelector(
  [getPayment, getForms, getSavedEmails(PROFILE_KEY)],
  (payment, forms, savedEmails) =>
    payment.newEmailSelected
      ? forms.emailForm.email.rawValue
      : get(
          compose(
            prop(payment.selectedSavedEmail.contactId || ""),
            prop("value")
          ),
          savedEmails
        )
);

export const getPartialAmountFormPropsFromPayment = payment => [
  payment.lineItems,
  payment.paymentMaximumInCentsForPaymentType,
  payment.paymentMinimumInCents,
  payment.blockPartialPaymentOverpay
];

export const getPartialAmountFormProps = createSelector(
  [getPayment],
  getPartialAmountFormPropsFromPayment
);
