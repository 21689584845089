import React from "react";

import {
  Box,
  constants,
  Stack,
  Cluster,
  Paragraph,
  Text,
  InternalLink
} from "@thecb/components";

const TableOfContents = ({
  toc = [],
  sidebarLayout = false,
  themeValues,
  isMobile
}) =>
  toc !== null ? (
    sidebarLayout ? (
      <Box padding="0">
        <Stack>
          <Box padding="0">
            <Paragraph variant="pS" color={themeValues.darkAccentText}>
              Contents
            </Paragraph>
          </Box>
          <Box padding="0">
            <Stack>
              {toc?.map((anchor, index) =>
                anchor?.anchorText ? (
                  <InternalLink key={`${anchor.id}-${index}`} to={anchor.id}>
                    <Paragraph
                      color={themeValues.linkColor}
                      variant="pL"
                      extraStyles={`display: list-item; list-style: inside; text-align: left; ::marker { color: ${themeValues.linkColor}; }`}
                    >
                      {anchor.anchorText}
                    </Paragraph>
                  </InternalLink>
                ) : null
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    ) : (
      <Box padding="0">
        <Cluster justify="flex-start" align="center" minHeight="40px">
          {toc?.map((anchor, index) =>
            anchor.anchorText ? (
              <>
                <InternalLink to={anchor.id} key={`${anchor.id}`}>
                  <Text
                    color="white"
                    weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                    variant={isMobile ? "pS" : "p"}
                    extraStyles={`margin-left: 16px;`}
                  >
                    {anchor.anchorText}
                  </Text>
                </InternalLink>
                {index < toc.length - 1 && (
                  <Text
                    color="white"
                    variant={isMobile ? "pS" : "p"}
                    extraStyles={`margin-left: 16px;`}
                    weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                  >
                    |
                  </Text>
                )}
              </>
            ) : null
          )}
        </Cluster>
      </Box>
    )
  ) : (
    <></>
  );

export default TableOfContents;
