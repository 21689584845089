import React from "react";
import { LoadingPill } from "./LoadingPill.styled";

const LoadingLine = ({
  minWidth,
  maxWidth,
  exactWidth = null,
  height = "16px",
  margin = "0px"
}) => {
  const calculatedWidth = Math.floor(
    Math.random() * (parseInt(maxWidth) - parseInt(minWidth) + 1) +
      parseInt(minWidth)
  );

  return (
    <LoadingPill
      margin={margin}
      height={height}
      width={exactWidth || calculatedWidth}
    />
  );
};

export default LoadingLine;
