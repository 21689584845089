import React, { useContext } from "react";
import {
  constants,
  Box,
  Stack,
  Text,
  Title,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import AccordionItem from "./AccordionItem";

const AccordionBlock = ({
  themeValues,
  description = "",
  title = "",
  displayTitle = true,
  accordionItems
}) => {
  const { isMobile } = useContext(ThemeContext);

  const renderAccordionTitle = () => {
    const accentBorderHeight = isMobile ? "5px" : "6px";
    return (
      <>
        <Box
          minHeight={accentBorderHeight}
          width={"50px"}
          borderSize={accentBorderHeight}
          borderColor={themeValues.lightAccentText}
          borderWidthOverride={`0 0 ${accentBorderHeight} 0`}
          padding="40px 0 0"
        />
        <Title
          margin="8px 0 0"
          color={constants.colors.CHARADE_GREY}
          extraStyles={`font-size: ${isMobile ? "1.143rem" : "1.5rem"};`}
        >
          {title}
        </Title>
      </>
    );
  };

  return (
    <Box padding={0} minWidth="100%" maxWidth="100%">
      <Stack childGap={"24px"}>
        <Stack childGap="16px">
          {displayTitle && title && renderAccordionTitle()}
          {description && (
            <Text variant={isMobile ? "p" : "pL"}>{description}</Text>
          )}
        </Stack>
        {accordionItems.map(item => (
          <AccordionItem key={uuidv4()} item={item} themeValues={themeValues} />
        ))}
      </Stack>
    </Box>
  );
};

export default withWindowSize(AccordionBlock);
