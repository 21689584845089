import CaptchaForm from "./CaptchaForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./CaptchaForm.state";

CaptchaForm.reducer = reducer;
CaptchaForm.mapStateToProps = mapStateToProps;
CaptchaForm.mapDispatchToProps = mapDispatchToProps;
export default CaptchaForm;
