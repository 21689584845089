import React from "react";
import { Box } from "../layouts";
import { Image } from "./ImageBox.styled";

const ImageBox = ({
  image,
  minHeight,
  minWidth,
  maxWidth,
  borderRadius,
  imgWidth,
  imgHeight,
  objectFit,
  objectPosition
}) => {
  const boxMaxWidth = maxWidth || minWidth;
  const { url = "", altText = "" } = image;

  return (
    <Box
      padding="0"
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={boxMaxWidth}
      borderRadius={borderRadius}
      extraStyles={`height: ${minHeight};`}
    >
      <Image
        width={imgWidth}
        height={imgHeight}
        objectFit={objectFit}
        objectPosition={objectPosition}
        src={url}
        alt={altText}
      />
    </Box>
  );
};

export default ImageBox;
