import * as RemoteData from "../util/remoteData";
import * as R from "ramda";

const SET_LOCAL_STORAGE = "SET_LOCAL_STORAGE";
export const setLocalStorage = payload => ({
  type: SET_LOCAL_STORAGE,
  payload
});

export const CLEAR_LOCAL_STORAGE = "CLEAR_LOCAL_STORAGE";
export const clearLocalStorage = () => ({
  type: CLEAR_LOCAL_STORAGE
});

const CLEAR_LOCAL_STORAGE_SUCCESS = "CLEAR_LOCAL_STORAGE_SUCCESS";
export const clearLocalStorageSuccess = () => ({
  type: CLEAR_LOCAL_STORAGE_SUCCESS
});

export const CLEAR_LOCAL_STORAGE_AND_REDIRECT =
  "CLEAR_LOCAL_STORAGE_AND_REDIRECT";
export const clearLocalStorageAndRedirect = payload => ({
  type: CLEAR_LOCAL_STORAGE_AND_REDIRECT,
  payload
});

export const SET_TENANT_SLUG = "SET_TENANT_SLUG";
export const setTenantSlug = tenantSlug => ({
  type: SET_TENANT_SLUG,
  payload: { tenantSlug }
});

export const FETCH_TENANT_CONFIGURATION = "FETCH_TENANT_CONFIGURATION";
export const fetchTenantConfiguration = () => ({
  type: FETCH_TENANT_CONFIGURATION
});

export const FETCH_TENANT_CONFIGURATION_SUCCESS =
  "FETCH_TENANT_CONFIGURATION_SUCCESS";
export const fetchTenantConfigurationSuccess = data => ({
  type: FETCH_TENANT_CONFIGURATION_SUCCESS,
  payload: { data }
});

export const FETCH_TENANT_CONFIGURATION_ERROR =
  "FETCH_TENANT_CONFIGURATION_ERROR";
export const fetchTenantConfigurationError = error => ({
  type: FETCH_TENANT_CONFIGURATION_ERROR,
  payload: { error }
});

export const FETCH_SUB_CLIENT_CONFIGURATION = "FETCH_SUB_CLIENT_CONFIGURATION";
export const fetchSubClientConfiguration = subClientSlug => ({
  type: FETCH_SUB_CLIENT_CONFIGURATION,
  payload: { subClientSlug }
});

export const FETCH_SUB_CLIENT_CONFIGURATION_SUCCESS =
  "FETCH_SUB_CLIENT_CONFIGURATION_SUCCESS";
export const fetchSubClientConfigurationSuccess = data => ({
  type: FETCH_SUB_CLIENT_CONFIGURATION_SUCCESS,
  payload: { data }
});

export const FETCH_SUB_CLIENT_CONFIGURATION_ERROR =
  "FETCH_SUB_CLIENT_CONFIGURATION_ERROR";

export const fetchSubClientConfigurationError = error => ({
  type: FETCH_SUB_CLIENT_CONFIGURATION_ERROR,
  payload: { error }
});

export const LOGOUT = "LOGOUT";
export const logout = () => ({ type: LOGOUT });

export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const adminLogout = () => ({ type: ADMIN_LOGOUT });

export const REFRESH_TOKEN_EXPIRED = "auth/REFRESH_TOKEN_EXPIRED";
export const refreshTokenExpired = () => ({ type: REFRESH_TOKEN_EXPIRED });

export const ADMIN_REFRESH_TOKEN_EXPIRED = "adminAuth/REFRESH_TOKEN_EXPIRED";
export const adminRefreshTokenExpired = () => ({
  type: ADMIN_REFRESH_TOKEN_EXPIRED
});

const UPDATE_ROUTER_HISTORY = "UPDATE_ROUTER_HISTORY";
export const updateRouterHistory = payload => ({
  type: UPDATE_ROUTER_HISTORY,
  payload
});

const SET_INITIAL_LOCATION = "SET_INITIAL_LOCATION";
export const setInitialLocation = payload => ({
  type: SET_INITIAL_LOCATION,
  payload
});

const FETCH_THEME = "FETCH_THEME";
export const fetchTheme = () => ({
  type: FETCH_THEME
});

const FETCH_THEME_SUCCESS = "FETCH_THEME_SUCCESS";
export const fetchThemeSuccess = payload => ({
  type: FETCH_THEME_SUCCESS,
  payload
});

const FETCH_THEME_ERROR = "FETCH_THEME_ERROR";
export const fetchThemeError = error => ({
  type: FETCH_THEME_ERROR,
  payload: { error }
});

export const FETCH_WORKFLOW = "FETCH_WORKFLOW";
export const fetchWorkflow = payload => ({
  type: FETCH_WORKFLOW,
  payload
});

const FETCH_WORKFLOW_SUCCESS = "FETCH_WORKFLOW_SUCCESS";
export const fetchWorkflowSuccess = payload => ({
  type: FETCH_WORKFLOW_SUCCESS,
  payload
});

const FETCH_WORKFLOW_ERROR = "FETCH_WORKFLOW_ERROR";
export const fetchWorkflowError = error => ({
  type: FETCH_WORKFLOW_ERROR,
  payload: { error }
});

export const CLEAR_STATE = "CLEAR_STATE";
export const clearState = () => ({
  type: CLEAR_STATE
});

export const GUEST_CHECKOUT = "GUEST_CHECKOUT";
export const guestCheckout = () => ({
  type: GUEST_CHECKOUT
});

export const CLEAR_PAYMENT_CONTACT_INFORMATION =
  "CLEAR_PAYMENT_CONTACT_INFORMATION";
export const clearPaymentContactInformation = () => ({
  type: CLEAR_PAYMENT_CONTACT_INFORMATION
});

export const OKTA_LOGIN_SUCCESS = "adminLogin/OKTA_LOGIN_SUCCESS";
export const oktaLoginSuccess = accessToken => ({
  type: OKTA_LOGIN_SUCCESS,
  payload: {
    accessToken
  }
});

export const SET_OKTA_AUTH_FROM_LOCAL_STORAGE =
  "adminLogin/SET_OKTA_AUTH_FROM_LOCAL_STORAGE";
export const setOktaAuthFromLocalStorage = payload => ({
  type: SET_OKTA_AUTH_FROM_LOCAL_STORAGE,
  payload: {
    accessToken: payload?.accessToken?.accessToken
  }
});

export const CLEAR_OKTA_AUTH = "adminLogin/CLEAR_OKTA_AUTH";
export const clearOktaAuth = () => ({
  type: CLEAR_OKTA_AUTH
});

export const SET_LAUNCH_DARKLY_FLAGS = "SET_LAUNCH_DARKLY_FLAGS";
export const setLaunchDarklyFlags = payload => ({
  type: SET_LAUNCH_DARKLY_FLAGS,
  payload
});

export const SET_LAUNCH_DARKLY_CONTEXT = "SET_LAUNCH_DARKLY_CONTEXT";
export const setLaunchDarklyContext = payload => ({
  type: SET_LAUNCH_DARKLY_CONTEXT,
  payload
});

const initialState = {
  tenantSlug: null,
  theme: RemoteData.notAsked(),
  clientMetadata: RemoteData.notAsked(),
  routesConfig: RemoteData.notAsked(),
  landingPage: RemoteData.notAsked(),
  localStorage: {},
  locationHistory: {
    lastRouterAction: "",
    paths: []
  },
  launchDarkly: {
    flags: [],
    context: null
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCAL_STORAGE:
      return {
        ...state,
        localStorage: { ...state.localStorage, ...payload }
      };
    case CLEAR_LOCAL_STORAGE:
      return {
        ...state,
        localStorage: {}
      };
    case CLEAR_LOCAL_STORAGE_AND_REDIRECT:
      return {
        ...state,
        localStorage: {}
      };
    case SET_TENANT_SLUG:
      return { ...state, tenantSlug: payload.tenantSlug };

    case FETCH_TENANT_CONFIGURATION:
      return {
        ...state,
        clientMetadata: RemoteData.loading(),
        settings: RemoteData.loading(),
        subClientPath: RemoteData.loading(),
        subClientSlug: RemoteData.loading(),
        subClients: RemoteData.loading(),
        payment: RemoteData.loading()
      };

    case FETCH_TENANT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        clientMetadata: RemoteData.success(payload.data.meta),
        settings: RemoteData.success(payload.data.settings),
        subClientPath: RemoteData.success(payload.data.subClientPath),
        subClientSlug: RemoteData.success(payload.data.subClientSlug),
        subClients: RemoteData.success(payload.data.subClients),
        payment: RemoteData.success(payload.data.payment)
      };

    case FETCH_TENANT_CONFIGURATION_ERROR:
      return {
        ...state,
        clientMetadata: RemoteData.error(payload.error),
        settings: RemoteData.error(payload.error),
        subClientPath: RemoteData.error(payload.error),
        subClientSlug: RemoteData.error(payload.error),
        subClients: RemoteData.error(payload.error),
        payment: RemoteData.error(payload.error)
      };

    case FETCH_THEME:
      return { ...state, theme: RemoteData.loading() };

    case FETCH_THEME_SUCCESS:
      return { ...state, theme: RemoteData.success(payload) };

    case FETCH_THEME_ERROR:
      return { ...state, theme: RemoteData.error(payload.error) };

    case FETCH_WORKFLOW:
      return { ...state, workflows: RemoteData.loading() };

    case FETCH_WORKFLOW_SUCCESS:
      return { ...state, workflows: RemoteData.success(payload) };

    case FETCH_WORKFLOW_ERROR:
      return { ...state, workflows: RemoteData.error(payload.error) };

    case SET_INITIAL_LOCATION:
      return {
        ...state,
        locationHistory: {
          lastRouterAction: "POP",
          paths: [payload]
        }
      };

    case UPDATE_ROUTER_HISTORY:
      if (R.last(state.locationHistory.paths) !== R.last(payload.paths)) {
        return {
          ...state,
          locationHistory: {
            lastRouterAction: payload.lastRouterAction,
            paths: [...state.locationHistory.paths, R.last(payload.paths)]
          }
        };
      }
      return state;
    case FETCH_SUB_CLIENT_CONFIGURATION:
      return {
        ...state,
        subClientMetadata: RemoteData.loading()
      };

    case FETCH_SUB_CLIENT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        subClientMetadata: RemoteData.success(payload.data.meta)
      };

    case FETCH_SUB_CLIENT_CONFIGURATION_ERROR:
      return {
        ...state,
        subClientMetadata: RemoteData.error(payload.error)
      };

    case OKTA_LOGIN_SUCCESS:
      return {
        ...state,
        oktaAuth: {
          accessToken: payload.accessToken
        }
      };

    case CLEAR_OKTA_AUTH:
      return {
        ...state,
        oktaAuth: {}
      };

    case SET_OKTA_AUTH_FROM_LOCAL_STORAGE:
      return {
        ...state,
        oktaAuth: {
          accessToken: payload.accessToken
        }
      };

    case SET_LAUNCH_DARKLY_FLAGS:
      return {
        ...state,
        launchDarkly: {
          ...state.launchDarkly,
          flags: payload
        }
      };

    case SET_LAUNCH_DARKLY_CONTEXT:
      return {
        ...state,
        launchDarkly: {
          ...state.launchDarkly,
          context: payload
        }
      };
    default:
      return state;
  }
};

export default reducer;
