import React from "react";

import { disconnectMutationObserver, resetLoadingObserver } from "./observer";

const HashLinkObserver = ({ isPageLoading, smoothScroll = true }) => {
  const scrollIntoViewOptions = smoothScroll
    ? { behavior: "smooth" }
    : undefined;

  React.useEffect(() => {
    const OBSERVER_TIMEOUT_MS = 50000;
    const hash = window.location.hash;
    let loadingObserver;
    let observerTimeout;

    if (!hash || isPageLoading) {
      return;
    }

    const elementId = hash.slice(1);
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView(scrollIntoViewOptions);
      return;
    }

    loadingObserver = new MutationObserver((_, observer) => {
      const missingElement = document.getElementById(elementId);
      if (missingElement) {
        missingElement.scrollIntoView(scrollIntoViewOptions);
        resetLoadingObserver(observer, observerTimeout);
      }
    });

    observerTimeout = window.setTimeout(
      () => disconnectMutationObserver(loadingObserver),
      OBSERVER_TIMEOUT_MS
    );

    return () => {
      resetLoadingObserver(loadingObserver, observerTimeout);
    };
  }, [window.location.href, isPageLoading]);
  return null;
};

export default HashLinkObserver;
