import React from "react";
import { WHITE, FOREST_GREEN } from "../../constants/colors";

export const IconValid = ({
  bgFill = FOREST_GREEN,
  iconFill = WHITE,
  width = "18",
  height = "18",
  margin = "0"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ margin }}
    aria-label="Valid"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-538.000000, -996.000000)">
        <g transform="translate(457.000000, 938.000000)">
          <g transform="translate(81.000000, 20.000000)">
            <g transform="translate(0.000000, 38.000000)">
              <circle
                stroke={bgFill}
                fill={bgFill}
                fillRule="nonzero"
                cx="9"
                cy="9"
                r="8.5"
              ></circle>
              <g transform="translate(2.000000, 2.000000)">
                <polygon points="0 0 14 0 14 14 0 14"></polygon>
                <polygon
                  fill={iconFill}
                  points="5.25 9.4325 2.8175 7 1.98916667 7.8225 5.25 11.0833333 12.25 4.08333333 11.4275 3.26083333"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
