import styled from "styled-components";
import { constants } from "@thecb/components";

export const ScreendoorFormContent = styled.form`
  background-color: ${constants.colors.WHITE};
  box-sizing: border-box;
  font-family: Source Sans Pro;
  margin: 5rem auto;
  max-width: 1280px;
  width: 100%;
  padding: 0;

  label:not(.themePrimaryButton), th, .fr_bottom, .fr_text, .fr_units {
    color: ${constants.colors.STORM_GREY};
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: ${constants.fontWeights.FONT_WEIGHT_REGULAR};
    margin: 0.25rem 0;
    word-break: break-word;
    font-family: Source Sans Pro;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .js-add-entry {
    color: #fff;
    background-color: var(--primary-button-color);
  }

  a {
    font-size: 1.125rem;
    font-weight: 400;
    color: #27A9E1;
    line-height: 1rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    font-family: Source Sans Pro;
    color: ${constants.colors.BRIGHT_GREY};
    line-height: 1.5rem;
    letter-spacing: 0.29px;
    margin: 3rem 0 1rem;
  }

  input[type="text"], input[type="email"], input[type="password"], textarea {
    border: 1px solid ${constants.colors.GHOST_GREY};
    border-radius: 2px;
    height: 3rem;
    width: 100%;
    padding: 1rem;
    min-width: 60px;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    font-size: 1.1rem;
    line-height: 2rem;
    font-weight: ${constants.fontWeights.FONT_WEIGHT_REGULAR};
    background-color: ${constants.colors.WHITE};
    color: ${constants.colors.MINESHAFT_GREY};
    box-shadow: none;

    &:focus {
      border: 1px solid ${constants.colors.CURIOUS_BLUE};
    }
  }

  select {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 0.75rem 1.75rem 0.75rem 1rem;
    line-height: 1.5rem;
    border: 1px solid ${constants.colors.GHOST_GREY};
    box-shadow: none;
    background-color: ${constants.colors.WHITE};
    height: 3rem;
    width: 100%;
    min-width: auto;
    border-radius: 2px;
    border-width: 1px;
    appearance: none;
    background-color: ${constants.colors.WHITE};
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20overflow%3D%22visible%22%20preserveAspectRatio%3D%22none%22%20viewBox%3D%220%200%2014%2010%22%20height%3D%228%22%20width%3D%2212%22%3E%3Cg%20transform%3D%22translate%281%201%29%22%3E%3Cg%20fill-rule%3D%22evenodd%22%20fill%3D%22none%22%20id%3D%22Symbols_1548197283918%22%3E%3Cg%20fill%3D%22%23333%22%20transform%3D%22translate%28-155%20-22%29%22%20id%3D%22Dropdown%2FClosed_1548197283918%22%3E%3Cpolygon%20points%3D%22165.59%2022.59%20161%2027.17%20156.41%2022.59%20155%2024%20161%2030%20167%2024%22%20id%3D%22Path_1548197283918%22%20vector-effect%3D%22non-scaling-stroke%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    color: ${constants.colors.MINESHAFT_GREY};
    font-size: 1.1rem;
}
  }

  .fr_description {
    font-size: 1rem;
    font-weight: 400;
    font-family: Source Sans Pro;
    color: ${constants.colors.REGENT_GREY};
    line-height: 1.5rem;
    font-style: italic;
  }

  .fr_field_wrapper {
    position: relative;
    clear: both;
  }

  .fr_map_wrapper {
    width: 100%;
    height: 15rem;
    position: relative;
  }

  .fr_map_map {
    width: 100%;
    height: 13.5rem;
  }

  .fr_field_wrapper {
    .fr_option {
      margin: 0.625rem 0;
      line-height: 1.5rem;

      input[type="text"] {
        padding: 0.25rem 0.75rem;
        font-size: 0.75rem;
        line-height: 1rem;
        height: 1.5rem;
        margin-left: 0.5rem;
        float: left;
      }

      &::before, &::after {
        display: table;
        content: " ";
      }

      &::after {
        clear: both;
      }
    }

    label.fr_option {
      display: block;
    }

    .fr_option label {
      display: inline-block;
      margin: 0;
      float: left;
    }

    label.fr_option, .fr_option label {
      position: relative;
      line-height: 1.5rem;

      input[type="radio"] /*, input[type="checkbox"]*/ {
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 1rem;
        width: 1rem;
        background-color: ${constants.colors.BOSTON_BLUE};
        border-radius: 0.5rem;
        margin: 0.25rem;

        &:not(:checked) {
          display: none;
        }
      }
    }
  }

  .fr_response_field_radio {
    label.fr_option::before, .fr_option label::before {
      border-radius: 0.75rem;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 1.5rem;
      width: 1.5rem;
      border: 1px solid ${constants.colors.GHOST_GREY};
      float: left;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-right: 0.75rem;
    }
  }

  .fr_response_field_checkboxes, .fr_response_field_confirm {
    label.fr_option, .fr_option label {
      input[type="checkbox"] {
        /* top: 0.25rem; */
        margin-right: 0.75rem;
        appearance: none;
        display: block;
        width: 1rem;
        height: 1rem;
        border-radius: 0.125rem;
        box-sizing: border-box;
        content: "";
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        border: 1px solid ${constants.colors.GHOST_GREY};
        float: left;
        min-width: 1.5rem;
        min-height: 1.5rem;
        margin: 0 0.75rem 0 0;
        outline: none;

        &:checked {
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22check%22%20class%3D%22svg-inline--fa%20fa-check%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%23357FB8%22%20d%3D%22M173.898%20439.404l-166.4-166.4c-9.997-9.997-9.997-26.206%200-36.204l36.203-36.204c9.997-9.998%2026.207-9.998%2036.204%200L192%20312.69%20432.095%2072.596c9.997-9.997%2026.207-9.997%2036.204%200l36.203%2036.204c9.997%209.997%209.997%2026.206%200%2036.204l-294.4%20294.401c-9.998%209.997-26.207%209.997-36.204-.001z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat, repeat;
          background-size: 85%;
          background-position: center;
        }
      }
    }
  }

  .fr_response_field_paragraph textarea.size_medium {
    width: 100%;
  }

  .leaflet-container .leaflet-control-attribution a {
    color: #404040;
  }

  .fr_map_clear, .fr_map_toolbar, .leaflet-container .leaflet-control-attribution a {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .fr_bottom_l {
    padding-right: 1rem;
  }

  .js-add-row {
    background: linear-gradient( to right, ${constants.colors.GREY_CHATEAU} 50%, rgba(255,255,255,0) 0% ), linear-gradient(${constants.colors.GREY_CHATEAU} 50%,rgba(255,255,255,0) 0%), linear-gradient(to right,${constants.colors.GREY_CHATEAU} 50%,rgba(255,255,255,0) 0%), linear-gradient(${constants.colors.GREY_CHATEAU} 50%,rgba(255,255,255,0) 0%);
    background-position: top,right,bottom,left;
    background-repeat: repeat-x,repeat-y;
    background-size: 0.625rem 1px,1px 0.625rem;
    min-height: 3rem;
    min-width: auto;
    border-radius: 0.25rem;
    border-width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 700;
    font-family: Source Sans Pro;
    color: ${constants.colors.REGENT_GREY};
    line-height: 1.5rem;
    text-align: center;
    padding: 2px;
  }

  .js-remove-row {
    display: inline-block;
    background: ${constants.colors.GHOST_GREY};
    width: 0.75rem;
    height: 0.75rem;
    line-height: 0.625rem;
    font-weight: 600;
    color: ${constants.colors.WHITE};
    border-radius: 0.375rem;
  }

  .fr_pagination a {
    line-height: 1.25rem;
  }

  .fr_error_alert_bar {
    background-color: ${constants.colors.RAZZMATAZZ_RED};
  }

  .fr_response_field.error input, .fr_response_field.error textarea {
    border-color: ${constants.colors.RAZZMATAZZ_RED};
  }

  .fr_error {
    color: ${constants.colors.RAZZMATAZZ_RED};
  }

  @media (max-width: 768px) {
    .fr_bottom_l, .fr_bottom_r {
      display: block;
    }

    .fr_bottom_l {
      padding: 1rem 0;
    }

    .fr_bottom_r {
      padding: 0;
    }

    .btn {
      padding: 1rem;
      display: block;
      margin: 1rem 0;
      width: 100%;

      &::last-child {
        margin-bottom: 0;
      }
    }

    .fr_response_field_text input.size_medium {
      width: 100%;
      min-width: 100px;
    }
  }
`;
