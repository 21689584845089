import React, { useEffect } from "react";
import {
  required,
  hasLength,
  matchesField,
  hasNumber,
  hasLowercaseLetter,
  hasUppercaseLetter,
  hasSpecialCharacter,
  isProbablyEmail,
  validName
} from "redux-freeform";
import PasswordRequirements from "../../atoms/password-requirements";
import { Box } from "../../atoms/layouts";
import { FormInput, FormInputColumn } from "../../atoms/form-layouts";
import { noop } from "../../../util/general";

const RegistrationForm = ({
  clearOnDismount,
  fields,
  actions,
  handleSubmit = noop,
  showErrors,
  isMobile
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const firstNameErrorMessages = {
    [required.error]: "First name is required",
    [validName.error]: "First name contains invalid characters"
  };
  const lastNameErrorMessages = {
    [required.error]: "Last name is required",
    [validName.error]: "Last name contains invalid characters"
  };
  const emailErrorMessages = {
    [required.error]: "Email is required",
    [isProbablyEmail.error]: "Invalid email address"
  };
  const passwordErrorMessages = {
    [required.error]: "Password is required",
    [hasLength.error]: "Password must have at least 8 characters",
    [hasNumber.error]: "Password must contain at least one number",
    [hasLowercaseLetter.error]:
      "Password must contain at least one lowercase letter",
    [hasUppercaseLetter.error]:
      "Password must contain at least one uppercase letter",
    [hasSpecialCharacter.error]:
      "Password must contain at least one special character (!@#$%^&*.?)"
  };
  const confirmPasswordErrorMessages = {
    [matchesField.error]: "Confirm password must match password"
  };

  return (
    <FormInputColumn role="form" aria-label="Registration">
      <FormInput
        labelTextWhenNoError="First name"
        errorMessages={firstNameErrorMessages}
        field={fields.firstName}
        fieldActions={actions.fields.firstName}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        autocompleteValue="given-name"
        dataQa="First name"
      />
      <FormInput
        labelTextWhenNoError="Last name"
        errorMessages={lastNameErrorMessages}
        field={fields.lastName}
        fieldActions={actions.fields.lastName}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        autocompleteValue="family-name"
        dataQa="Last name"
      />
      <FormInput
        labelTextWhenNoError="Email address"
        errorMessages={emailErrorMessages}
        field={fields.email}
        fieldActions={actions.fields.email}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        type="email"
        isEmail
        autocompleteValue="email"
        dataQa="Email address"
      />
      <FormInput
        labelTextWhenNoError="Password"
        errorMessages={passwordErrorMessages}
        field={fields.password}
        fieldActions={actions.fields.password}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        type="password"
        autocompleteValue="new-password"
        dataQa="Password"
      />
      <FormInput
        labelTextWhenNoError="Confirm password"
        errorMessages={confirmPasswordErrorMessages}
        field={fields.confirmPassword}
        fieldActions={actions.fields.confirmPassword}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        type="password"
        autocompleteValue="new-password"
        dataQa="Confirm password"
      />
      <Box padding={isMobile ? "0" : "0.5rem 0 0"}>
        <PasswordRequirements password={fields.password} isMobile={isMobile} />
      </Box>
    </FormInputColumn>
  );
};

export default RegistrationForm;
