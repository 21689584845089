import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  ButtonWithLink,
  Cluster,
  Heading,
  Stack,
  Switcher,
  Text,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import RightArrowIcon from "../../../icons/RightArrowIcon";

const QuickLinksBlock = ({
  QuickLinks_title: title,
  pages,
  blockPadding = "40px 0",
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const hoverStyles = `background: ${themeValues.linkColorAlpha}`;
  const activeStyles = `background: ${themeValues.linkColor}; * { color: white; fill: white;}`;

  const breakpointsByNumberLinks = {
    // based on ensuring minimum width and  horizontal gap
    1: "260px",
    2: "772px",
    3: "927px",
    4: "1160px"
  };

  return (
    <Box minWidth="100%" padding={blockPadding}>
      <Stack>
        {title && (
          <Box padding="0">
            <Stack childGap="1rem">
              <Box
                padding="0"
                minHeight="5px"
                width={isMobile ? "24px" : "50px"}
                borderSize={isMobile ? "3px" : "6px"}
                borderColor={themeValues.lightAccentText}
                borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
                key="quick-links-accent"
              />
              <Heading
                variant="h3"
                color={themeValues.darkText}
                key="heading"
                extraStyles={`margin-top: 16px;`}
                weight={FONT_WEIGHT_SEMIBOLD}
              >
                {title}
              </Heading>
            </Stack>
          </Box>
        )}
        <Switcher
          childGap={isMobile ? "2px" : "48px"}
          breakpoint={breakpointsByNumberLinks[pages.length]}
        >
          {pages.map(
            (
              {
                LandingPage_title,
                SectionPage_title,
                SubjectPage_title,
                ActionPage_title,
                slug
              },
              index
            ) => (
              <ButtonWithLink
                key={`page-${index}`}
                url={`/${slug[0].slug}`}
                contentOverride
                variant="ghost"
                extraStyles={`margin-left: 0; width: 100%;`}
              >
                <Box
                  background={"white"}
                  borderRadius={4}
                  padding={isMobile ? "30px 24px" : "2rem"}
                  maxWidth="100%"
                  minHeight="100%"
                  minWidth={"250px"}
                  hoverStyles={hoverStyles}
                  activeStyles={activeStyles}
                  extraStyles={`display: flex; align-items: center; border-top: 4px solid ${
                    themeValues.linkColor
                  };
                    ${isMobile &&
                      "height: 100%; > div { justify-content: space-between;}"}`}
                  boxShadow="0px 2px 6px rgba(41, 42, 51, 0.2), 0px 1px 2px rgba(41, 42, 51, 0.1), inset 0px 1px 0px rgba(41, 42, 51, 0.1)"
                >
                  <Cluster
                    minWidth="100%"
                    justify="space-between"
                    align="center"
                    extraStyles="min-width: 100%"
                  >
                    <Box padding={0} extraStyles={"flex: 1; text-align: left;"}>
                      <Text
                        variant="pXL"
                        color={themeValues.linkColor}
                        weight={FONT_WEIGHT_SEMIBOLD}
                      >
                        {LandingPage_title ||
                          SectionPage_title ||
                          SubjectPage_title ||
                          ActionPage_title}
                      </Text>
                    </Box>
                    <RightArrowIcon color={themeValues.linkColor} />
                  </Cluster>
                </Box>
              </ButtonWithLink>
            )
          )}
        </Switcher>
      </Stack>
    </Box>
  );
};

export default withWindowSize(QuickLinksBlock);
