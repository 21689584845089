import React from "react";
import { v4 as uuidv4 } from "uuid";

const PlusIcon = ({ size = 20 }) => {
  const mask = `mask-${uuidv4()}`;
  const height = size;
  const width = height * 1.05;
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2197 16.0609C11.4959 16.0609 11.7197 15.8371 11.7197 15.5609L11.7197 11.0102L16.2705 11.0102C16.5467 11.0102 16.7705 10.7863 16.7705 10.5102L16.7705 9.48987C16.7705 9.21373 16.5467 8.98987 16.2705 8.98987L11.7197 8.98987L11.7197 4.43911C11.7197 4.16297 11.4959 3.93911 11.2197 3.93911L10.1994 3.93911C9.9233 3.93911 9.69944 4.16297 9.69944 4.43911L9.69944 8.98987L5.14868 8.98987C4.87254 8.98987 4.64868 9.21373 4.64868 9.48987L4.64868 10.5102C4.64868 10.7863 4.87254 11.0102 5.14868 11.0102L9.69944 11.0102L9.69944 15.5609C9.69944 15.8371 9.9233 16.0609 10.1994 16.0609H11.2197Z"
        fill="#3B414D"
      />
      <mask
        id={mask}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="13"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2197 16.0609C11.4959 16.0609 11.7197 15.8371 11.7197 15.5609L11.7197 11.0102L16.2705 11.0102C16.5467 11.0102 16.7705 10.7863 16.7705 10.5102L16.7705 9.48987C16.7705 9.21373 16.5467 8.98987 16.2705 8.98987L11.7197 8.98987L11.7197 4.43911C11.7197 4.16297 11.4959 3.93911 11.2197 3.93911L10.1994 3.93911C9.9233 3.93911 9.69944 4.16297 9.69944 4.43911L9.69944 8.98987L5.14868 8.98987C4.87254 8.98987 4.64868 9.21373 4.64868 9.48987L4.64868 10.5102C4.64868 10.7863 4.87254 11.0102 5.14868 11.0102L9.69944 11.0102L9.69944 15.5609C9.69944 15.8371 9.9233 16.0609 10.1994 16.0609H11.2197Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${mask})`}>
        <path d="M0.709595 20L0.709595 0H20.7096V20H0.709595Z" fill="#3B414D" />
      </g>
    </svg>
  );
};

export default PlusIcon;
