import React from "react";

const ArrowDownCircleIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ArrowDownCircleIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6696 9.47199C14.4745 9.27685 14.1581 9.27671 13.9628 9.47167L10.8226 12.6062V5.5C10.8226 5.22386 10.5988 5 10.3226 5H10.0726C9.79649 5 9.57263 5.22386 9.57263 5.5V12.6062L6.43244 9.47167C6.23712 9.27671 5.92078 9.27685 5.72564 9.47199L5.55119 9.64645C5.35592 9.84171 5.35592 10.1583 5.55119 10.3536L9.84408 14.6464C10.0393 14.8417 10.3559 14.8417 10.5512 14.6464L14.8441 10.3536C15.0393 10.1583 15.0393 9.84171 14.8441 9.64645L14.6696 9.47199Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6696 9.47199C14.4745 9.27685 14.1581 9.27671 13.9628 9.47167L10.8226 12.6062V5.5C10.8226 5.22386 10.5988 5 10.3226 5H10.0726C9.79649 5 9.57263 5.22386 9.57263 5.5V12.6062L6.43244 9.47167C6.23712 9.27671 5.92078 9.27685 5.72564 9.47199L5.55119 9.64645C5.35592 9.84171 5.35592 10.1583 5.55119 10.3536L9.84408 14.6464C10.0393 14.8417 10.3559 14.8417 10.5512 14.6464L14.8441 10.3536C15.0393 10.1583 15.0393 9.84171 14.8441 9.64645L14.6696 9.47199Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect
          x="17.6976"
          y="2.5"
          width="15"
          height="15"
          transform="rotate(90 17.6976 2.5)"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ArrowDownCircleIconSmall;
