import React, { useState, useContext } from "react";
import { themeComponent } from "../../../util/themeUtils";
import Text from "../../atoms/text";
import Paragraph from "../../atoms/paragraph";
import { Box } from "../../atoms/layouts";
import ButtonWithAction from "../../atoms/button-with-action";
import { useOutsideClick } from "../../../hooks";
import { noop } from "../../../util/general";
import { ESCAPE } from "../../../constants/keyboard";
import { fallbackValues } from "./Popover.theme";

const arrowBorder = (borderColor, direction, width = "8px") => {
  const angle = `${width} solid transparent`;
  const straight = `${width} solid ${borderColor}`;
  if (direction == "down") {
    return `border-left: ${angle}; border-right: ${angle}; border-top: ${straight}`;
  } else if (direction == "up") {
    return `border-left: ${angle}; border-right: ${angle}; border-bottom: ${straight}`;
  } else if (direction == "left") {
    return `border-top: ${angle}; border-bottom: ${angle}; border-right: ${straight}`;
  } else if (direction == "right") {
    return `border-top: ${angle}; border-bottom: ${angle}; border-left: ${straight}`;
  }
};

const Popover = ({
  themeValues,
  triggerText = "",
  content = "",
  hasIcon = false,
  icon: Icon,
  iconHelpText = "", // for screen-readers, required if using an icon for trigger
  popoverID = 0,
  popoverFocus = false,
  extraStyles,
  textExtraStyles,
  minWidth = "250px",
  maxWidth = "300px",
  height = "auto",
  position, // { top: string, right: string, bottom: string, left: string }
  arrowPosition, // { top: string, right: string, bottom: string, left: string }
  arrowDirection = "down",
  transform = "none",
  buttonExtraStyles,
  backgroundColor = "white",
  borderColor = "rgba(255, 255, 255, 0.85)",
  popoverExtraStyles
}) => {
  const { hoverColor, activeColor, popoverTriggerColor } = themeValues;
  const { top = "-110px", right = "auto", bottom = "auto", left = "-225px" } =
    position ?? {};
  const {
    arrowTop = "auto",
    arrowRight = "10px",
    arrowBottom = "-8px",
    arrowLeft = "auto"
  } = arrowPosition ?? {};

  const [popoverOpen, togglePopover] = useState(false);

  const handleTogglePopover = popoverState => {
    if (popoverOpen !== popoverState) {
      togglePopover(popoverState);
    }
  };

  const triggerRef = useOutsideClick(() => handleTogglePopover(false));

  return (
    <Box padding="0" extraStyles={`position: relative; ${extraStyles}`}>
      <ButtonWithAction
        action={() => noop}
        onFocus={() => {
          handleTogglePopover(true);
        }}
        onBlur={() => {
          handleTogglePopover(false);
        }}
        onKeyDown={e => {
          if (e.keyCode === ESCAPE) {
            handleTogglePopover(false);
          }
        }}
        onTouchStart={() => handleTogglePopover(true)}
        onTouchEnd={() => handleTogglePopover(false)}
        onMouseEnter={() => handleTogglePopover(true)}
        onMouseLeave={() => handleTogglePopover(false)}
        contentOverride
        variant="smallGhost"
        tabIndex="0"
        id={`btnPopover${popoverID}`}
        aria-expanded={popoverOpen}
        aria-labelledby={`btnPopover${popoverID}_info Disclosure${popoverID}`}
        aria-describedby={`Disclosure${popoverID}`}
        aria-controls={`Disclosed${popoverID}`}
        ref={triggerRef}
        extraStyles={buttonExtraStyles}
      >
        {hasIcon && (
          <>
            <Icon />
            <Box padding="0" srOnly>
              <Text id={`btnPopover${popoverID}_info`}>{iconHelpText}</Text>
            </Box>
          </>
        )}
        {!hasIcon && (
          <Text
            color={popoverTriggerColor}
            extraStyles={`&:active { color: ${activeColor}; } &:hover { color: ${hoverColor} }; ${textExtraStyles}`}
          >
            {triggerText}
          </Text>
        )}
      </ButtonWithAction>
      <Box
        background={backgroundColor}
        borderRadius="4px"
        boxShadow="0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
        id={`Disclosed${popoverID}`}
        role={"region"}
        aria-describedby={`Disclosure${popoverID}`}
        tabIndex={popoverFocus && popoverOpen ? "0" : "-1"}
        minWidth={minWidth}
        maxWidth={maxWidth}
        extraStyles={`
          display: ${popoverOpen ? "block" : "none"}; 
          position: absolute; 
          top: ${top}; 
          right: ${right}; 
          bottom: ${bottom}; 
          left: ${left};
          height: ${height};
          transform: ${transform};
          ${popoverExtraStyles};
          `}
      >
        <Paragraph>{content}</Paragraph>
        <Box
          padding="0"
          extraStyles={`
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            ${arrowBorder(borderColor, arrowDirection, "8px")};
            filter: drop-shadow(2px 8px 14px black);
            bottom: ${arrowBottom};
            right: ${arrowRight};
            top: ${arrowTop};
            left: ${arrowLeft};
          `}
        />
      </Box>
    </Box>
  );
};

export default themeComponent(Popover, "Popover", fallbackValues);
