const lightText = {
  default: "#FFFFFF"
};

const darkText = {
  default: "#000000"
};

const darkAccentText = {
  default: "#0E506D"
};

const lightAccentText = {
  default: "#31A9E1"
};

const linkColor = {
  default: "#31A9E1"
};

const linkColorAlpha = {
  default: "#EBEFFB"
};

const pageBackground = "#F6F6F9";

const gradient = {
  default: [
    "#0E506D",
    "#31A9E1",
    "rgba(14, 80, 109, 0.2)",
    "rgba(49, 169, 225, 0.2)"
  ]
};

const darkGradient = {
  default: ["#0E506D", "#31A9E1", "rgba(14, 80, 109)", "rgba(49, 169, 225)"]
};

const accents = {
  default: "#31A9E1"
};

const logoHeight = {
  default: "50px"
};

const iconsColor = {
  default: "#3B5BDB"
};

const iconsColorAlpha = {
  default: "rgb(235, 238, 251)"
};

export const fallbackValues = {
  lightText,
  darkText,
  lightAccentText,
  darkAccentText,
  pageBackground,
  linkColor,
  linkColorAlpha,
  accents,
  gradient,
  darkGradient,
  logoHeight,
  iconsColor,
  iconsColorAlpha
};
