import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../templates.theme";
import { themeComponent } from "../../../util/themeUtils";
import withWindowResize from "../../withWindowSize";
import { Box, Center, Cover, Sidebar } from "../../atoms/layouts";
import { COOL_GREY_05 } from "../../../constants/colors";

const SidebarSingleContent = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  sidebarWrapperPadding = "0",
  sidebarWrapperMaxWidth = "auto",
  sidebarContent,
  mainContent,
  sidebarOnRight = false,
  sidebarTargetWidth = "10rem",
  sidebarContentGap = "1rem",
  mainContentMinWidth = "50%",
  sidebarVerticalCenter = false,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Box
      padding="0"
      minWidth="100%"
      background={COOL_GREY_05}
      extraStyles="flex-grow: 1;"
    >
      <Cover centerOverride={!sidebarVerticalCenter}>
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box padding={sidebarWrapperPadding}>
            <Center maxWidth={sidebarWrapperMaxWidth}>
              <Sidebar
                width={sidebarTargetWidth}
                childGap={sidebarContentGap}
                sidebarOnRight={sidebarOnRight}
                contentMinWidth={mainContentMinWidth}
              >
                {sidebarOnRight ? <Fragment /> : sidebarContent}
                {mainContent}
                {sidebarOnRight ? sidebarContent : <Fragment />}
              </Sidebar>
            </Center>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
};

export default withWindowResize(
  themeComponent(SidebarSingleContent, "Global", fallbackValues)
);
