import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const TrashIconV2 = ({ themeValues, iconFill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 4H14V6H4V4H6.5L7.21429 3H10.7857L11.5 4ZM6.99048 15C6.25714 15 5.65714 14.4857 5.65714 13.8571L4.85714 7H12.8571L12.0571 13.8571C12.0571 14.4857 11.4571 15 10.7238 15H6.99048Z"
        fill={iconFill ?? themeValues.singleIconColor}
      />
      <mask
        id="mask0_4292_11876"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="10"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 4H14V6H4V4H6.5L7.21429 3H10.7857L11.5 4ZM6.99048 15C6.25714 15 5.65714 14.4857 5.65714 13.8571L4.85714 7H12.8571L12.0571 13.8571C12.0571 14.4857 11.4571 15 10.7238 15H6.99048Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4292_11876)"></g>
    </svg>
  );
};

export default themeComponent(TrashIconV2, "Icons", fallbackValues, "primary");
