import React from "react";
import { Box, Imposter } from "../../atoms/layouts";
import { fallbackValues } from "./NavMenu.theme.js";
import { themeComponent } from "../../../util/themeUtils";

const NavMenuDesktop = ({
  id,
  top = "115%",
  left = "-165%",
  menuContent = [],
  visible = true,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  themeValues
}) => {
  const menuCarat = `&:after { bottom: 100%; right: 10px; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: ${themeValues.backgroundColor}00; border-bottom-color: ${themeValues.backgroundColor}; border-width: 10px; margin-left: -10px; }`;

  return (
    <Imposter id={id} breakout top={top} left={left} visible={visible}>
      <Box
        minWidth="240px"
        padding="1rem 0.5rem"
        extraStyles={`position: relative; ${menuCarat}`}
        background={themeValues.backgroundColor}
        borderRadius="5px"
        boxShadow={`0px 1px 8px -1px rgba(0, 0, 0, 0.3), 0px 1px 4px 0px ${themeValues.shadowColor}, 0px 7px 32px 0px ${themeValues.shadowColor}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {menuContent}
      </Box>
    </Imposter>
  );
};

export default themeComponent(
  NavMenuDesktop,
  "NavMenu",
  fallbackValues,
  "profile"
);
