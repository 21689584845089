import CreateStoredInstrumentPaymentAttempt from "./CreateStoredInstrumentPaymentAttempt.graphql";
import CreateKeyedPaymentAttemptMutation from "./CreateKeyedPaymentAttempt.graphql";
import CreateBankPaymentAttemptMutation from "./CreateBankPaymentAttempt.graphql";
import GetInvoiceById from "./GetInvoice.graphql";
import Obligations from "./GetVisitId.graphql";
import { createQuery } from "../../../../../util/graphql";

export const createStoredInstrumentPaymentAttempt = ({
  endpoint,
  clientSlug,
  authToken,
  items,
  customAttributes,
  serviceFee,
  routingKey,
  payingWith,
  paymentInstrumentId,
  accountId,
  payer,
  traceNumber,
  captchaToken,
  subClientSlug,
  paymentInitiator,
  visitId,
  invoiceId
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authToken
  )(CreateStoredInstrumentPaymentAttempt, {
    items,
    traceNumber,
    customAttributes,
    serviceFee,
    routingKey,
    payingWith,
    paymentInstrumentId,
    accountId,
    captchaToken,
    ...payer,
    subClientSlug,
    paymentInitiator,
    visitId,
    invoiceId
  });

export const createKeyedPaymentAttempt = ({
  endpoint,
  clientSlug,
  authToken,
  items,
  customAttributes,
  serviceFee,
  routingKey,
  cardNumber,
  cvv,
  expirationMonth,
  expirationYear,
  accountId,
  payer,
  traceNumber,
  captchaToken,
  subClientSlug,
  paymentInitiator,
  visitId,
  invoiceId
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authToken
  )(CreateKeyedPaymentAttemptMutation, {
    items,
    traceNumber,
    customAttributes,
    serviceFee,
    routingKey,
    cardNumber,
    cvv,
    expirationMonth,
    expirationYear,
    accountId,
    captchaToken,
    ...payer,
    subClientSlug,
    paymentInitiator,
    visitId,
    invoiceId
  });

export const createBankPaymentAttempt = ({
  endpoint,
  clientSlug,
  authToken,
  items,
  customAttributes,
  serviceFee,
  routingKey,
  accountType,
  routingNumber,
  accountNumber,
  accountId,
  payer,
  traceNumber,
  captchaToken,
  subClientSlug,
  paymentInitiator,
  visitId,
  invoiceId
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authToken
  )(CreateBankPaymentAttemptMutation, {
    endpoint,
    traceNumber,
    clientSlug,
    authToken,
    items,
    customAttributes,
    serviceFee,
    routingKey,
    accountType,
    routingNumber,
    accountNumber,
    accountId,
    captchaToken,
    ...payer,
    subClientSlug,
    paymentInitiator,
    visitId,
    invoiceId
  });

export const getInvoice = ({ endpoint, clientSlug, authToken, invoiceId }) =>
  createQuery(
    endpoint,
    clientSlug,
    authToken
  )(GetInvoiceById, {
    id: invoiceId
  });

export const getVisitId = ({
  endpoint,
  client,
  clientSlug,
  authToken,
  accountId
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authToken
  )(Obligations, {
    client,
    accountId
  });
