import React from "react";
import { ROYAL_BLUE_VIVID } from "../../../constants/colors";

const ArrowRightIcon = ({ color = ROYAL_BLUE_VIVID, labelledBy = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-labelledby={labelledBy}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.17828 4.15501C8.98314 4.35015 8.983 4.66649 9.17796 4.8618L13.475 9.16659H3.83337C3.55723 9.16659 3.33337 9.39044 3.33337 9.66659V10.3333C3.33337 10.6094 3.55723 10.8333 3.83337 10.8333H13.475L9.17796 15.138C8.983 15.3333 8.98314 15.6497 9.17828 15.8448L9.64649 16.313C9.84175 16.5083 10.1583 16.5083 10.3536 16.313L16.3132 10.3535C16.5084 10.1582 16.5084 9.84163 16.3132 9.64637L10.3536 3.6868C10.1583 3.49154 9.84175 3.49154 9.64649 3.68681L9.17828 4.15501Z"
        fill={color}
      />
      <mask
        id="mask0_6329_1483"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.17828 4.15501C8.98314 4.35015 8.983 4.66649 9.17796 4.8618L13.475 9.16659H3.83337C3.55723 9.16659 3.33337 9.39044 3.33337 9.66659V10.3333C3.33337 10.6094 3.55723 10.8333 3.83337 10.8333H13.475L9.17796 15.138C8.983 15.3333 8.98314 15.6497 9.17828 15.8448L9.64649 16.313C9.84175 16.5083 10.1583 16.5083 10.3536 16.313L16.3132 10.3535C16.5084 10.1582 16.5084 9.84163 16.3132 9.64637L10.3536 3.6868C10.1583 3.49154 9.84175 3.49154 9.64649 3.68681L9.17828 4.15501Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6329_1483)">
        <rect width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};
export default ArrowRightIcon;
