/* 
  Hook that takes an ID selector for an element on the screen
  And optionally values for top position, left position, smooth behavior
  Finds element on screen and scrolls it to the provided coordinates

  (string, number, number, string, number) => undefined;
*/

const useScrollTo = (id, top = 0, left = 0, behavior = "auto", delay) => {
  let scrollItem;
  if (delay) {
    setTimeout(() => {
      scrollItem = document.getElementById(id);
      scrollItem?.scrollTo({ top, left, behavior });
    }, delay);
  } else {
    scrollItem = document.getElementById(id);
    scrollItem?.scrollTo({ top, left, behavior });
  }
};

export default useScrollTo;
