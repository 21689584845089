import {
  WHITE,
  TRANSPARENT,
  GHOST_GREY,
  SEASHELL_WHITE,
  MATISSE_BLUE,
  RED,
  CHARADE_GREY,
  STORM_GREY,
  ROYAL_BLUE
} from "../../../constants/colors";

const backgroundColor = { default: `${TRANSPARENT}` };
const textFontSize = { default: "1.1rem" };
const textFontWeight = { default: "400" };
const textLineHeight = { default: "2rem" };
const textColor = { default: `${CHARADE_GREY}` };
const disabledCheckColor = { default: `${WHITE};` };
const checkColor = { default: `${WHITE};` };
const errorStyles = { default: `border: 1px solid ${RED};` };
const focusedStyles = {
  default: `
    outline: 3px solid ${ROYAL_BLUE}; 
    outline-offset: 3px;
  `
};
const disabledCheckedStyles = {
  default: `
  background: #6d717e;
  border: 1px solid #6d717e;
`
};

const disabledStyles = {
  default: `
  background: ${SEASHELL_WHITE};
  border: 1px solid ${STORM_GREY};
`
};
const checkedStyles = {
  default: `
  background: ${MATISSE_BLUE};    
  border: 1px solid ${MATISSE_BLUE};
`
};
const defaultStyles = {
  default: `
  background: ${WHITE}; 
  border: 1px solid ${STORM_GREY};
`
};

export const fallbackValues = {
  backgroundColor,
  textFontSize,
  textFontWeight,
  textLineHeight,
  textColor,
  disabledCheckColor,
  checkColor,
  errorStyles,
  focusedStyles,
  disabledStyles,
  checkedStyles,
  defaultStyles,
  disabledCheckedStyles
};
