import React from "react";
import { ROYAL_BLUE_VIVID } from "../../../constants/colors";

const PlusCircleIcon = ({ color = ROYAL_BLUE_VIVID, labelledBy = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-labelledby={labelledBy}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25ZM2.75 10C2.75 5.99594 5.99594 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10ZM10.75 9.25V5.25H9.25V9.25H5.25V10.75H9.25V14.75H10.75V10.75H14.75V9.25H10.75Z"
        fill={color}
      />
    </svg>
  );
};
export default PlusCircleIcon;
