import React, { useState, useContext, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import dompurify from "dompurify";
import { matchesRegex } from "redux-freeform";
import { ThemeContext } from "styled-components";
import {
  Breadcrumb,
  ButtonWithAction,
  PaymentFormCard,
  PaymentFormACH,
  ButtonWithLink,
  RadioSection,
  Module,
  Box,
  Stack,
  Motion,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import S from "/util/Sanctuary";
import {
  CREDIT_CARD_RESOURCE,
  SUBMIT_OPERATIONS,
  ACH_RESOURCE,
  BANK_ACCOUNT_KIND,
  CREDIT_CARD_KIND
} from "../../Profile.state";
import AlertBar from "/components/alert-bar";
import { configureEndpoint } from "/util/router-utils";

const AddPayment = ({
  requests,
  actions,
  forms,
  resourcesActions,
  alertBars,
  selectedPaymentForm,
  customerManagement,
  handleFocusErrors,
  deniedCardsForClient
}) => {
  let navigate = useNavigate();
  const requestStatusCard =
    requests[CREDIT_CARD_RESOURCE] || S.RemoteData.NotAsked;
  const requestStatusAch = requests[ACH_RESOURCE] || S.RemoteData.NotAsked;
  const {
    forms: { addCreditCardForm: actionsCard, addAchForm: actionsAch },
    alertBars: {
      addCreditCardAlertBar: alertBarActionsCard,
      addAchAlertBar: alertBarActionsAch
    }
  } = actions;
  const { addCreditCardForm: formCard, addAchForm: formAch } = forms;
  const { onSubmitChange, setPaymentFormType } = resourcesActions;
  const {
    addCreditCardAlertBar: alertBarCard,
    addAchAlertBar: alertBarAch
  } = alertBars;
  const isAllowedCardType = cardType =>
    !deniedCardsForClient?.find(deniedCard => deniedCard === cardType);

  const cardTypeData = {
    VISA: {
      img: "/Visa.svg",
      enabled:
        !formCard.fields.creditCardNumber.dirty ||
        util.general.checkCardBrand(
          formCard.fields.creditCardNumber.rawValue
        ) == "VISA"
    },
    MASTERCARD: {
      img: "/MasterCardDark.svg",
      enabled:
        !formCard.fields.creditCardNumber.dirty ||
        util.general.checkCardBrand(
          formCard.fields.creditCardNumber.rawValue
        ) == "MASTERCARD"
    },
    DISCOVER: {
      img: "/DiscoverDark.svg",
      enabled:
        !formCard.fields.creditCardNumber.dirty ||
        util.general.checkCardBrand(
          formCard.fields.creditCardNumber.rawValue
        ) == "DISCOVER"
    },
    AMEX: {
      img: "/AmEx.svg",
      enabled:
        !formCard.fields.creditCardNumber.dirty ||
        util.general.checkCardBrand(
          formCard.fields.creditCardNumber.rawValue
        ) == "AMEX"
    }
  };

  const cardIcons = Object.keys(cardTypeData)
    .map(cardType => {
      if (isAllowedCardType(cardType)) {
        return cardTypeData[cardType];
      }
    })
    ?.filter(item => item !== undefined);

  const [errors, handleErrors] = useState(false);
  const { isMobile, metadata } = useContext(ThemeContext);

  const isNewCreditCard = selectedPaymentForm === CREDIT_CARD_KIND;
  const form = isNewCreditCard ? formCard : formAch;
  const paymentResource = isNewCreditCard ? CREDIT_CARD_RESOURCE : ACH_RESOURCE;

  const terms = metadata?.data?.termsAndConditions;
  const termsContent = parse(dompurify.sanitize(terms?.content));
  const termsTitle = terms?.title;

  const handleSubmit = util.general.generateClickHandler(
    form,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => onSubmitChange(paymentResource, SUBMIT_OPERATIONS.ADD)
  );

  useEffect(() => {
    if (deniedCardsForClient) {
      deniedCardsForClient.map(card =>
        actions.forms.addCreditCardForm.actions.fields.creditCardNumber.addValidator(
          matchesRegex(util.general.checkDeniedCards(card))
        )
      );
    }
  }, []);

  return (
    <Box padding="0 0 16px" width={isMobile ? "100%" : "576px"}>
      <Breadcrumb
        breadcrumbsList={[
          {
            linkText: "Wallet",
            linkDestination: "/profile/wallet",
            isActive: false
          },
          {
            linkText: "Add Payment Method",
            linkDestination: "/profile/wallet/add-credit-card",
            isActive: true
          }
        ]}
      />
      {(alertBarCard.alerts.length > 0 || alertBarAch.alerts.length > 0) && (
        <Box padding="1rem 0 0">
          <AlertBar {...alertBarCard} {...alertBarActionsCard} />
          <AlertBar {...alertBarAch} {...alertBarActionsAch} />
        </Box>
      )}
      <Module
        heading="Add New Payment Method"
        padding="1.5rem"
        color={constants.colors.WHITE}
      >
        <RadioSection
          sections={[
            {
              title: "Checking Account",
              id: BANK_ACCOUNT_KIND,
              content: (
                <Motion
                  variants={{
                    open: { opacity: 1, transition: { duration: 0.1 } },
                    closed: { opacity: 0, transition: { duration: 0.1 } }
                  }}
                  extraStyles={`transform-origin: 0% 50%;`}
                >
                  <PaymentFormACH
                    clearOnDismount
                    {...formAch}
                    {...actionsAch}
                    showErrors={errors}
                    handleSubmit={handleSubmit}
                    variant="collapsible"
                    termsContent={termsContent}
                    termsTitle={termsTitle}
                  />
                </Motion>
              )
            },
            {
              title: "Credit Card",
              id: CREDIT_CARD_KIND,
              rightIcons: cardIcons,
              content: (
                <Motion
                  variants={{
                    open: { opacity: 1, transition: { duration: 0.1 } },
                    closed: { opacity: 0, transition: { duration: 0.1 } }
                  }}
                  extraStyles={`transform-origin: 0% 50%;`}
                >
                  <PaymentFormCard
                    clearOnDismount
                    {...formCard}
                    {...actionsCard}
                    showErrors={errors}
                    handleSubmit={handleSubmit}
                    isMobile={isMobile}
                    variant="collapsible"
                    termsContent={termsContent}
                    termsTitle={termsTitle}
                    deniedCards={deniedCardsForClient}
                  />
                </Motion>
              )
            }
          ]}
          toggleOpenSection={id => setPaymentFormType(id)}
          openSection={selectedPaymentForm}
          staggeredAnimation
          isMobile={isMobile}
        />
      </Module>
      <Box
        padding="1.5rem 0 0 0"
        borderColor={constants.colors.GHOST_GREY}
        borderSize="1px"
        borderWidthOverride="1px 0 0 0"
      >
        {isMobile ? (
          <Fragment>
            {selectedPaymentForm === CREDIT_CARD_KIND && (
              <Stack childGap="1rem" direction="row">
                <Box width="100%" padding="0">
                  <ButtonWithLink
                    text="Cancel"
                    url={configureEndpoint(
                      customerManagement,
                      "/profile/wallet"
                    )}
                    variant="secondary"
                    dataQa="Cancel"
                    extraStyles={`width: 100%;`}
                  />
                </Box>
                <Box width="100%" padding="0">
                  <ButtonWithAction
                    text={"Add Credit Card"}
                    action={handleSubmit}
                    isLoading={requestStatusCard.isLoading}
                    variant="primary"
                    dataQa={"Add Credit Card"}
                    extraStyles={`width: 100%;`}
                  />
                </Box>
              </Stack>
            )}
            {selectedPaymentForm === BANK_ACCOUNT_KIND && (
              <Stack childGap="1rem" direction="row">
                <Box width="100%" padding="0">
                  <ButtonWithLink
                    text="Cancel"
                    url={configureEndpoint(
                      customerManagement,
                      "/profile/wallet"
                    )}
                    variant="secondary"
                    dataQa="Cancel"
                    extraStyles={`width: 100%;`}
                  />
                </Box>
                <Box width="100%" padding="0">
                  <ButtonWithAction
                    text={"Add Account"}
                    action={handleSubmit}
                    isLoading={requestStatusAch.isLoading}
                    variant="primary"
                    dataQa={"Add Account"}
                    extraStyles={`width: 100%;`}
                  />
                </Box>
              </Stack>
            )}
          </Fragment>
        ) : (
          <Stack childGap="1rem" direction="row" justify="flex-end">
            {selectedPaymentForm === CREDIT_CARD_KIND && (
              <Stack childGap="1rem" direction="row">
                <ButtonWithAction
                  text="Cancel"
                  action={() => navigate(-1)}
                  variant={"secondary"}
                  dataQa="Cancel"
                />
                <ButtonWithAction
                  text={"Add Credit Card"}
                  action={handleSubmit}
                  isLoading={requestStatusCard.isLoading}
                  variant="primary"
                  dataQa={"Add Credit Card"}
                  extraStyles={`width: 100%;`}
                />
              </Stack>
            )}
            {selectedPaymentForm === BANK_ACCOUNT_KIND && (
              <Stack childGap="1rem" direction="row">
                <ButtonWithAction
                  text="Cancel"
                  action={() => navigate(-1)}
                  variant={"secondary"}
                  dataQa="Cancel"
                />
                <ButtonWithAction
                  text={"Add Bank Account"}
                  action={handleSubmit}
                  isLoading={requestStatusAch.isLoading}
                  variant="primary"
                  dataQa={"Add Bank Account"}
                />
              </Stack>
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default withWindowSize(AddPayment);
