import React from "react";
import styled from "styled-components";
import { noop } from "../../../util/general";

const Hamburger = styled.button`
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:focus {
    outline: none;
  }
`;

const HamburgerBox = styled.span`
  width: 34px;
  height: 34px;
  display: inline-block;
  position: relative;
`;

const HamburgerInner = styled.span`
  display: block;
  top: auto;
  bottom: 6px;
  left: 2px;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  margin-top: 12px;
  background-color: ${({ inactiveColor }) => inactiveColor};

  &, &::before, &::after {
    background-color: ${({ inactiveColor }) => inactiveColor};
    width: 30px;
    height: 3px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before, &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -10px;
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), 
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::after {
    bottom: -10px;
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), 
                opacity 0.1s linear;
  }

  &.active, &.active::before, &.active::after {
    background-color: ${({ activeColor }) => activeColor};
  }

  &.active {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &.active::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), 
                opacity: 0.1s 0.22s linear;
  }

  &.active::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), 
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const HamburgerButton = ({
  activeColor,
  inactiveColor,
  isActive,
  onClick = noop,
  controls
}) => (
  <Hamburger
    className={isActive === true ? "active" : ""}
    onClick={onClick}
    type="button"
    aria-label="Menu"
    aria-controls={controls}
  >
    <HamburgerBox>
      <HamburgerInner
        className={isActive === true ? "active" : ""}
        inactiveColor={inactiveColor}
        activeColor={activeColor}
      />
    </HamburgerBox>
  </Hamburger>
);

export default HamburgerButton;
