import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { push } from "connected-react-router";
import {
  getCMSContent,
  getCMSPreviewContent,
  getRelatedContent,
  getSearchResults,
  getCMSActionPages,
  getCMSSectionPages,
  getCMSSubjectPages
} from "./graphql/Queries";
import {
  RETRIEVE_CMS_CONTENT,
  RETRIEVE_RELATED_CONTENT,
  RETRIEVE_SEARCH_RESULTS,
  RETRIEVE_ACTION_PAGES,
  RETRIEVE_SECTION_PAGES,
  RETRIEVE_SUBJECT_PAGES,
  retrieveCMSContentSuccess,
  retrieveCMSContentError,
  retrieveRelatedContentSuccess,
  retrieveRelatedContentError,
  retrieveSearchResultsSuccess,
  retrieveSearchResultsError,
  retrieveActionPagesSuccess,
  retrieveActionPagesError,
  retrieveSectionPagesSuccess,
  retrieveSectionPagesError,
  retrieveSubjectPagesSuccess,
  retrieveSubjectPagesError
} from "./CMS.state";
import { getClientSlug } from "../../../../util/state";
import getDynamicEnvVariable from "../../../../util/getDynamicEnvVariable";

const getEndpoint = action => {
  const url = action.payload.meta.graphCMSUrl;
  const environment = getDynamicEnvVariable("GRAPH_CMS_ENV", "master");
  const endpoint = url + environment;
  return endpoint;
};

const createGetCMSContentQuery = (action, state) => {
  const endpoint = getEndpoint(action);
  const preview = action.payload.preview;
  if (preview === true) {
    return getCMSPreviewContent({
      endpoint,
      clientSlug: getClientSlug(state),
      id: action.payload.id
    });
  }
  return getCMSContent({
    endpoint,
    clientSlug: getClientSlug(state),
    pageSlug: action.payload.pageSlug
  });
};

const createGetRelatedContentQuery = (action, state) => {
  const endpoint = getEndpoint(action);
  return getRelatedContent({
    endpoint,
    clientSlug: getClientSlug(state),
    search: action.payload.search,
    pageSlug: action.payload.pageSlug
  });
};

const createGetSearchResultsQuery = (action, state) => {
  const endpoint = getEndpoint(action);
  return getSearchResults({
    endpoint,
    clientSlug: getClientSlug(state),
    search: action.payload.search
  });
};

const createGetActionPagesQuery = (action, state) => {
  const endpoint = getEndpoint(action);
  return getCMSActionPages({
    endpoint,
    clientSlug: getClientSlug(state)
  });
};

const createGetSectionPagesQuery = (action, state) => {
  const endpoint = getEndpoint(action);
  return getCMSSectionPages({
    endpoint,
    clientSlug: getClientSlug(state)
  });
};

const createGetSubjectPagesQuery = (action, state) => {
  const endpoint = getEndpoint(action);
  return getCMSSubjectPages({
    endpoint,
    clientSlug: getClientSlug(state)
  });
};

export const retrieveCMSContentEpic = (action$, state$) =>
  action$.ofType(RETRIEVE_CMS_CONTENT).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetCMSContentQuery(action, state$.value)).pipe(
        RxOp.flatMap(response => Rx.of(retrieveCMSContentSuccess(response))),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(retrieveCMSContentError(error));
        })
      )
    )
  );

export const retrieveRelatedContentEpic = (action$, state$) =>
  action$.ofType(RETRIEVE_RELATED_CONTENT).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetRelatedContentQuery(action, state$.value)).pipe(
        RxOp.flatMap(response =>
          Rx.of(
            retrieveRelatedContentSuccess({
              key: action.payload.key,
              content: response
            })
          )
        ),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(
            retrieveRelatedContentError({ key: action.payload.key, error })
          );
        })
      )
    )
  );

export const retrieveSearchResultsEpic = (action$, state$) =>
  action$.ofType(RETRIEVE_SEARCH_RESULTS).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetSearchResultsQuery(action, state$.value)).pipe(
        RxOp.flatMap(response => {
          const queryParams = encodeURIComponent(
            action.payload.userSearchString.replace(/\+/g, " ")
          ).replace(/%20/g, "+");
          return action.payload.redirect
            ? Rx.of(
                retrieveSearchResultsSuccess(response),
                push(`/search?q=${queryParams}`)
              )
            : Rx.of(retrieveSearchResultsSuccess(response));
        }),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(retrieveSearchResultsError(error));
        })
      )
    )
  );

export const retrieveActionPagesEpic = (action$, state$) =>
  action$.ofType(RETRIEVE_ACTION_PAGES).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetActionPagesQuery(action, state$.value)).pipe(
        RxOp.flatMap(response => {
          return Rx.of(retrieveActionPagesSuccess(response));
        }),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(retrieveActionPagesError(error));
        })
      )
    )
  );

export const retrieveSectionPagesEpic = (action$, state$) =>
  action$.ofType(RETRIEVE_SECTION_PAGES).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetSectionPagesQuery(action, state$.value)).pipe(
        RxOp.flatMap(response => {
          return Rx.of(retrieveSectionPagesSuccess(response));
        }),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(retrieveSectionPagesError(error));
        })
      )
    )
  );

export const retrieveSubjectPagesEpic = (action$, state$) =>
  action$.ofType(RETRIEVE_SUBJECT_PAGES).pipe(
    RxOp.flatMap(action =>
      Rx.from(createGetSubjectPagesQuery(action, state$.value)).pipe(
        RxOp.flatMap(response => {
          return Rx.of(retrieveSubjectPagesSuccess(response));
        }),
        RxOp.catchError(error => {
          console.error(`Error: ${error}`);
          return Rx.of(retrieveSubjectPagesError(error));
        })
      )
    )
  );
