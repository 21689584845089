import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import parse from "html-react-parser";
import dompurify from "dompurify";
import {
  Heading,
  Paragraph,
  RegistrationForm,
  ButtonWithAction,
  Box,
  Cluster,
  CenterStack,
  FormContainer,
  FormFooterPanel,
  TermsAndConditions,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";

const RegistrationHeader = ({ alertBar, alertBarActions, isMobile }) => (
  <Box padding={isMobile ? "0 16px" : "0 0 16px"}>
    {alertBar.alerts.length > 0 && (
      <Box padding="16px 0">
        <AlertBar {...alertBar} {...alertBarActions} />
      </Box>
    )}
    <Heading
      variant="h1"
      color={constants.colors.CHARADE_GREY}
      weight="600"
      as="h1"
    >
      Welcome
    </Heading>
    <Paragraph
      variant="p"
      weight="600"
      color={constants.colors.CHARADE_GREY}
      as="h2"
    >
      Get started by providing your name, email and password.
    </Paragraph>
  </Box>
);

const RegistrationBody = ({
  isMobile,
  forms,
  actions,
  handleSubmit,
  submitting,
  showErrors,
  meta
}) => {
  const [termsAccepted, toggleTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const terms = meta?.termsAndConditions?.content;
  return (
    <Box
      padding="0"
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
    0px 3px 8px 0px rgb(202, 206, 216)"
      width={isMobile ? `auto` : `576px`}
    >
      <FormContainer>
        <RegistrationForm
          {...forms.registrationForm}
          {...actions.forms.registrationForm}
          isMobile={isMobile}
          handleSubmit={
            !termsAccepted
              ? e => {
                  e.preventDefault();
                  setTermsError(true);
                }
              : handleSubmit
          }
          submitting={submitting}
          showErrors={showErrors || termsError}
        />
        <Box padding="32px 0 8px">
          <TermsAndConditions
            version="v2"
            description="I agree to the"
            hasError={!termsAccepted && termsError}
            isChecked={termsAccepted}
            onCheck={() => {
              if (termsError) {
                setTermsError(false);
              }
              toggleTermsAccepted(!termsAccepted);
            }}
            terms={parse(dompurify.sanitize(terms))}
          />
        </Box>
        <Box
          padding={isMobile ? "0.5rem 0 0" : "1rem 0 0"}
          extraStyles={`margin: 0 -0.5rem;`}
        >
          <Cluster justify="center" align="center">
            <ButtonWithAction
              text={submitting ? "Loading..." : "Register"}
              variant="primary"
              action={!termsAccepted ? () => setTermsError(true) : handleSubmit}
              isLoading={submitting}
              extraStyles={`width: 100%;`}
              borderRadius="4px"
              dataQa={submitting ? "Loading..." : "Register Account"}
            />
          </Cluster>
        </Box>
      </FormContainer>
      <FormFooterPanel link="/login" linkText="Already registered? Log in." />
    </Box>
  );
};

const Registration = ({
  forms,
  actions,
  alertBars,
  submitRegistrationForm,
  submitting,
  handleFocusErrors
}) => {
  const { isMobile, metadata } = useContext(ThemeContext);
  const [errors, handleErrors] = useState(false);

  const handleSubmit = util.general.generateClickHandler(
    forms.registrationForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitRegistrationForm()
  );

  return (
    <CenterStack
      content={
        <Box padding={isMobile ? "24px 0 48px" : "32px 0 64px"}>
          <RegistrationHeader
            alertBar={alertBars.registrationAlertBar}
            alertBarActions={actions.alertBars.registrationAlertBar}
            isMobile={isMobile}
            key="registration header"
          />
          <Box padding="0" key="registration content">
            <RegistrationBody
              isMobile={isMobile}
              forms={forms}
              actions={actions}
              handleSubmit={handleSubmit}
              submitting={submitting}
              showErrors={errors}
              meta={metadata?.data}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default withWindowSize(Registration);
