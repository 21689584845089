import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import { ResetPasswordForm } from "@thecb/components";
import * as Nested from "../../../../util/Nested";

export const SUBMIT_RESET_PASSWORD_FORM =
  "resetPassword/SUBMIT_RESET_PASSWORD_FORM";
const submitResetPasswordForm = () => ({ type: SUBMIT_RESET_PASSWORD_FORM });

export const RESET_PASSWORD_SUCCESS = "resetPassword/RESET_PASSWORD_SUCCESS";
export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });

export const RESET_PASSWORD_ERROR = "resetPassword/RESET_PASSWORD_ERROR";
export const resetPasswordError = error => ({
  type: RESET_PASSWORD_ERROR,
  payload: error
});

export const SET_AUTHENTICATION_ID = "resetPassword/SET_AUTHENTICATION_ID";
export const setAuthenticationId = ({ authenticationId }) => ({
  type: SET_AUTHENTICATION_ID,
  payload: { authenticationId }
});

export const SET_PASSWORD_RESET_KEY = "resetPassword/SET_PASSWORD_RESET_KEY";
export const setPasswordResetKey = ({ passwordResetKey }) => ({
  type: SET_PASSWORD_RESET_KEY,
  payload: { passwordResetKey }
});

const initialState = {
  submitting: false,
  error: false
};

export const _reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBMIT_RESET_PASSWORD_FORM:
      return {
        ...state,
        submitting: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false
      };

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        submitting: false,
        error: payload
      };
    case SET_AUTHENTICATION_ID:
      return {
        ...state,
        ...payload
      };
    case SET_PASSWORD_RESET_KEY:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export const _mapStateToProps = state => state.resetPassword;
export const _mapDispatchToProps = dispatch => ({
  submitResetPasswordForm: () => dispatch(submitResetPasswordForm()),
  redirectToLogin: () => push("/login"),
  setAuthenticationId: ({ authenticationId }) =>
    dispatch(setAuthenticationId({ authenticationId })),
  setPasswordResetKey: ({ passwordResetKey }) =>
    dispatch(setPasswordResetKey({ passwordResetKey }))
});

export const ALERT_BAR_ACTION = "resetPassword/ALERT_BAR_ACTION";
export const resetPasswordAlertBarAction = Nested.nestedAction(
  ALERT_BAR_ACTION
);

export const RESET_PASSWORD_FORM_ACTION =
  "resetPassword/RESET_PASSWORD_FORM_ACTION";
export const resetPasswordFormAction = Nested.nestedAction(
  RESET_PASSWORD_FORM_ACTION
);

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    forms: {
      resetPasswordForm: {
        ...ResetPasswordForm,
        actionType: RESET_PASSWORD_FORM_ACTION
      }
    },
    alertBars: {
      resetPasswordAlertBar: {
        ...AlertBarState,
        actionType: ALERT_BAR_ACTION
      }
    }
  },
  "resetPassword"
);

const reducer = (state, action) => nestedReducer(state, action);

export { reducer, mapStateToProps, mapDispatchToProps, actions };
