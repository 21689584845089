import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import ButtonWithLink from "../button-with-link";
import { Box, Cluster, Center, Cover, Switcher } from "../layouts";
import Heading from "../heading";
import Title from "../title";
import withWindowSize from "../../withWindowSize";

const Jumbo = ({
  showButton,
  heading,
  buttonLink,
  subHeading,
  buttonText,
  slug,
  largeSide = "right",
  largeSideSize = "2",
  cartStatus: CartStatus,
  total,
  itemsCount,
  showCartStatus = false,
  openCartSlider,
  extraStyles
}) => {
  const { isMobile } = useContext(ThemeContext);
  return (
    <Box
      padding="0 20px"
      minHeight="65px"
      id={`heroImage-${slug}`}
      className="themeJumboBackground"
      extraStyles={extraStyles}
    >
      <Center maxWidth="76.5rem">
        <Cover singleChild minHeight="65px">
          <Switcher
            breakpoint={isMobile ? "999px" : "700px"}
            largeChild={largeSide === "right" ? "2" : "1"}
            largeChildSize={largeSideSize}
            childGap="0.25rem"
          >
            <Cluster
              minHeight="100%"
              justify={
                isMobile && !showCartStatus
                  ? `center`
                  : showButton || showCartStatus
                  ? `space-between`
                  : `left`
              }
              align="center"
              nowrap={showCartStatus}
            >
              <Title
                variant={isMobile ? "small" : "large"}
                as="h1"
                color="#ffffff"
                className="themeJumboHeading"
                extraStyles={showCartStatus && isMobile && `max-width: 60%;`}
              >
                {heading}
              </Title>
              {subHeading && (
                <Heading
                  variant={isMobile ? "h6" : "h2"}
                  color="#ffffff"
                  className="themeJumboSubheading"
                >
                  {subHeading}
                </Heading>
              )}
              {showButton && (
                <ButtonWithLink
                  url={buttonLink}
                  text={buttonText || "Browse Services"}
                  variant="primary"
                  extraStyles={`margin: 0 0 65px 0; min-width: 320px;`}
                />
              )}
              {showCartStatus && (
                <CartStatus
                  total={total}
                  itemsCount={itemsCount}
                  openCart={openCartSlider}
                />
              )}
            </Cluster>
          </Switcher>
        </Cover>
      </Center>
    </Box>
  );
};

export default withWindowSize(Jumbo);
