import styled from "styled-components";

export const ReelStyled = styled.div`
  display: flex;
  overflow-x: ${({ disableScroll }) => (disableScroll ? "hidden" : "auto")};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  justify-content: ${({ justifyContent }) => justifyContent};

  > * + * {
    margin-left: ${({ childGap }) => childGap};
  }

  > * {
    flex: 0 0 ${({ childWidth }) => childWidth};
  }

  ${({ useOrderedList, useUnorderedList }) =>
    useOrderedList || useUnorderedList
      ? `
      margin: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
      list-style-type: none;
    `
      : ``}
`;
