import React from "react";
import {
  required,
  numberGreaterThanOrEqualTo,
  numberLessThanOrEqualTo
} from "redux-freeform";

import PartialAmountField from "./PartialAmountField";
import { displayCurrency } from "../../../util/general";
import Text from "../../atoms/text";
import { FormInputColumn, FormContainer } from "../../atoms/form-layouts";
import { moneyFormat } from "../../../util/formats";

const PartialAmountForm = ({
  variant = "default",
  lineItems,
  maximum,
  minimum = 1,
  blockPartialPaymentOverpay,
  clearOnDismount,
  fields,
  actions,
  showErrors = false,
  dataQa
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }

  const amountErrors = {
    [required.error]: "Amount is required",
    [numberGreaterThanOrEqualTo.error]: `There is a minimum payment of ${displayCurrency(
      minimum
    )}`,
    [numberLessThanOrEqualTo.error]: `There is a maximum payment of ${displayCurrency(
      maximum
    )}`
  };

  const getPartialAmountFormErrors = itemAmount => {
    let errorMessages = amountErrors;
    if (blockPartialPaymentOverpay) {
      const maxAmountError = `There is a maximum payment of ${displayCurrency(
        itemAmount
      )}`;
      return {
        ...errorMessages,
        [numberLessThanOrEqualTo.error]: maxAmountError
      };
    }
    return errorMessages;
  };

  const lineItemsNew = Array.isArray(lineItems) ? lineItems : [];
  return (
    <FormContainer
      variant={variant}
      role="form"
      aria-label="Other amount"
      data-qa={dataQa}
    >
      <Text variant="p">
        Pay a different amount. The total balance will still be due on the same
        due date.
      </Text>
      <div style={{ height: "16px" }}></div>
      <FormInputColumn>
        {lineItemsNew.map(li => (
          <PartialAmountField
            key={li.id}
            lineItem={li}
            field={fields[li.id]}
            actions={actions}
            showErrors={showErrors}
            moneyFormat={moneyFormat}
            fieldActions={actions.fields[li.id]}
            errorMessages={getPartialAmountFormErrors(li.amount)}
          />
        ))}
      </FormInputColumn>
    </FormContainer>
  );
};

export default PartialAmountForm;
