import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  Fragment
} from "react";
import { isEmpty } from "ramda";
import { ThemeContext } from "styled-components";
import {
  Box,
  Cover,
  Cluster,
  Stack,
  Heading,
  NavHeader,
  ExternalLink,
  InternalLink,
  withWindowSize,
  Motion,
  Center,
  HamburgerButton,
  NavMenuMobile,
  Alert,
  ButtonWithLink
} from "@thecb/components";
import Search from "../search";
import { Helmet } from "react-helmet";
import { fallbackValues } from "../Content.theme";
import { themeComponent } from "../../../util/themeUtils";
import { getCallToActionInfo } from "../../../util/dataAdapters";
import LinkMenu from "./LinkMenu";
import NavLinks from "./NavLinks";
import { borderWrapper } from "./style";
import SearchIcon from "../../icons/SearchIcon";
import { COLLAPSIBLE_Z_INDEX } from "../../collapsible-section/CollapsibleSection";

const NO_SELECTED_MENU = 100;

const MAX_HEADER_WIDTH_IN_PIXELS = 1348;

const Header = ({
  navigation,
  title,
  themeValues,
  metadata: { data: meta },
  metaTitle,
  metaDesc,
  showSearchLink = true,
  retrieveSearchResults,
  searchResults,
  setSearchTerm,
  sitewideAlert
}) => {
  const { isMobile } = useContext(ThemeContext);
  const navContainerRef = useRef(null);
  const [hamburgerMenuStyle, setHamburgerMenuStyle] = useState(isMobile);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [hamburgerOpenSection, setHamburgerOpenSection] = useState();
  const [selectedNavMenu, setSelectedMenu] = useState(NO_SELECTED_MENU);
  const [navMenuOpen, setNavMenuOpen] = useState();
  const [keyboardNavUsed, setKeyboardNavUsed] = useState(false);
  const [isAnimating, setIsAnimating] = useState();
  const [searchMenuOpen, setSearchMenuOpen] = useState(
    showSearchLink ? true : false
  );

  const headerLogoSrc = meta.headerLogo;
  const headerLogoLink = meta.headerLogoLink;
  const favicon = meta.favicon;

  useLayoutEffect(() => {
    const SEARCH_BOX_SIZE = 140;
    const MINIMUM_GAP = 64;
    let navContainerWidth = 0;
    const isNeedHamburger = () => {
      if (!navContainerRef) {
        return false;
      }
      if (!navContainerWidth) {
        navContainerWidth = Math.max(
          navContainerWidth,
          navContainerRef.current.offsetWidth
        );
      }
      const maxViewWidth = Math.min(
        window.innerWidth,
        MAX_HEADER_WIDTH_IN_PIXELS
      );
      return navContainerWidth + SEARCH_BOX_SIZE + MINIMUM_GAP > maxViewWidth;
    };
    const onResize = () => {
      setHamburgerMenuStyle(isNeedHamburger());
    };
    if (!isMobile) {
      setHamburgerMenuStyle(isNeedHamburger());
      window.addEventListener("resize", onResize);
    }
    return () => {
      if (!isMobile) {
        window.removeEventListener("resize", onResize);
      }
    };
  }, []);

  useEffect(() => {
    const elem = document.querySelector("#root");
    if (isHamburgerOpen && hamburgerMenuStyle) {
      elem.style.overflow = "hidden";
    } else {
      elem.style.overflow = "visible";
    }
    return () => (elem.style.overflow = "visible");
  }, [isHamburgerOpen]);

  const HeaderLogoWrapper = ({ children }) =>
    headerLogoLink ? (
      false ? (
        <ExternalLink href={headerLogoLink}>{children}</ExternalLink>
      ) : (
        <InternalLink to={headerLogoLink}>{children}</InternalLink>
      )
    ) : (
      <Fragment>{children}</Fragment>
    );

  const headerLogo = (
    <HeaderLogoWrapper>
      <Box padding="0" minHeight="100%">
        <Cover singleChild minHeight="100%">
          <Box padding="0">
            <img
              src={headerLogoSrc}
              height={isMobile ? "42px" : themeValues.logoHeight}
              alt="header logo"
            />
          </Box>
        </Cover>
      </Box>
    </HeaderLogoWrapper>
  );

  const SearchMenu = () => (
    <Box padding="0">
      <Cover singleChild minHeight="100%">
        <Box minWidth="100%" padding={hamburgerMenuStyle ? "0" : "1rem"}>
          <Search
            searchResults={searchResults}
            retrieveSearchResults={retrieveSearchResults}
            setSearchTerm={setSearchTerm}
            meta={meta}
            themeValues={themeValues}
            isMobile={isMobile}
            isInHamburger={hamburgerMenuStyle}
            inHeaderMenu
            onClose={() => {
              setNavMenuOpen(false);
              setSearchMenuOpen(false);
            }}
          />
        </Box>
      </Cover>
    </Box>
  );

  const hamburgerStackMenu = (
    <Fragment>
      <Box padding="0" extraStyles={`height: 100%; overflow-y: auto;`}>
        {showSearchLink && (
          <Box padding="0" extraStyles={!isMobile && `margin: 0 0.5rem 1rem;`}>
            <Box padding="0 1rem">
              <SearchMenu />
            </Box>
          </Box>
        )}
        <NavLinks
          navigation={navigation}
          hamburgerMenuStyle={hamburgerMenuStyle}
          themeValues={themeValues}
          navMenuOpen={navMenuOpen}
          hamburgerOpenSection={hamburgerOpenSection}
          selectedNavMenu={selectedNavMenu}
          setSearchMenuOpen={setSearchMenuOpen}
          setSelectedMenu={setSelectedMenu}
          setNavMenuOpen={setNavMenuOpen}
          setIsAnimating={setIsAnimating}
          setKeyboardNavUsed={setKeyboardNavUsed}
          setHamburgerOpenSection={setHamburgerOpenSection}
        />
      </Box>
    </Fragment>
  );

  const SearchLink = () => {
    const onMenuFocus = useCallback(() => {
      if (navMenuOpen !== true) {
        setSelectedMenu(NO_SELECTED_MENU);
        setSearchMenuOpen(true);
        setNavMenuOpen(true);
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
        }, 600);
      } else if (!searchMenuOpen) {
        setSearchMenuOpen(true);
        setSelectedMenu(NO_SELECTED_MENU);
      }
    }, []);
    return (
      <Cover singleChild minHeight={hamburgerMenuStyle ? "0" : "100%"}>
        <Box
          tabIndex="0"
          as="button"
          background="transparent"
          padding="1.5rem"
          onClick={() => {
            setSearchMenuOpen(true);
            setSelectedMenu(NO_SELECTED_MENU);
            setNavMenuOpen(!navMenuOpen);
            setIsAnimating(true);
            setTimeout(() => {
              setIsAnimating(false);
            }, 600);
          }}
          onMouseEnter={onMenuFocus}
          onFocus={onMenuFocus}
        >
          <Cluster justify="center" align="center">
            <Heading
              variant="h6"
              color={isMobile ? "#FFFFFF" : themeValues.linkColor}
              extraStyles={`font-size: 1rem; margin-right: ${
                hamburgerMenuStyle ? 0 : "1rem"
              }`}
            >
              Search
            </Heading>
            <SearchIcon color={themeValues.linkColor} size={20} />
          </Cluster>
        </Box>
        {!hamburgerMenuStyle && (
          <Motion
            padding="0"
            minWidth="100%"
            variants={borderWrapper}
            animate={navMenuOpen ? "open" : "closed"}
            layoutTransition
            extraStyles={`border-bottom: 3px solid ${
              searchMenuOpen ? `${themeValues.linkColor}` : `transparent`
            }`}
          />
        )}
      </Cover>
    );
  };

  const backdropVariants = {
    open: {
      opacity: 1,
      display: "block",
      backgroundColor: "rgba(0, 0, 0, 0.5)"
    },
    closed: {
      opacity: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
      transitionEnd: {
        display: "none"
      }
    }
  };

  const navLinksContainer = hamburgerMenuStyle ? (
    <Box padding="0" minWidth="100%" extraStyles={"position: relative;"}>
      <Box padding={isMobile ? "0" : "1.5rem 1.5rem 1.5rem 0.5rem"}>
        {headerLogo}
      </Box>
      <Motion
        position="absolute"
        key="nav-backdrop"
        variants={backdropVariants}
        initial="closed"
        animate={isHamburgerOpen ? "open" : "closed"}
        exit="closed"
        padding="0"
        extraStyles={`width: 100vw; height: 100vh; top: 104px; left: 0;`}
      />
      <NavMenuMobile
        menuContent={hamburgerStackMenu}
        visible={isHamburgerOpen}
        variant="cms"
        headerSize="74px"
      />
    </Box>
  ) : (
    <div ref={navContainerRef}>
      <Box padding="0" minWidth="100%">
        <Cluster justify="space-between" align="stretch">
          <Box padding={isMobile ? "0" : "1.5rem 1.5rem 1.5rem 0.5rem"}>
            {headerLogo}
          </Box>
          <Box
            padding="0"
            borderWidthOverride="0 0 0 1px"
            borderColor={themeValues.linkColor}
            extraStyles={`margin: 16px;`}
          ></Box>
          <NavLinks
            navigation={navigation}
            hamburgerMenuStyle={hamburgerMenuStyle}
            themeValues={themeValues}
            navMenuOpen={navMenuOpen}
            hamburgerOpenSection={hamburgerOpenSection}
            selectedNavMenu={selectedNavMenu}
            setSearchMenuOpen={setSearchMenuOpen}
            setSelectedMenu={setSelectedMenu}
            setNavMenuOpen={setNavMenuOpen}
            setIsAnimating={setIsAnimating}
            setKeyboardNavUsed={setKeyboardNavUsed}
            setHamburgerOpenSection={setHamburgerOpenSection}
          />
        </Cluster>
      </Box>
    </div>
  );

  const onChangeMenu = useCallback(
    nextPrevious => {
      const numberNavItems = navigation.sections.length;
      const increment = nextPrevious === "next" ? 1 : -1;
      let nextMenu = selectedNavMenu + increment;
      if (nextMenu >= 0 && nextMenu < numberNavItems) {
        setSelectedMenu(nextMenu);
      } else {
        setSelectedMenu(NO_SELECTED_MENU);
        setSearchMenuOpen(true);
        setNavMenuOpen(true);
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
        }, 600);
      }
    },
    [selectedNavMenu]
  );

  const navItems =
    navigation?.sections?.map((section, index) => {
      return (
        <LinkMenu
          key={`linkMenu-${index}`}
          link={section}
          hamburgerMenuStyle={hamburgerMenuStyle}
          themeValues={themeValues}
          onChangeMenu={onChangeMenu}
          kbNavUsed={keyboardNavUsed}
        />
      );
    }) ?? [];

  const menuWrapper = {
    open: {
      top: "0",
      transition: {
        duration: 0.5
      }
    },
    closed: {
      top: "-650px",
      transition: {
        duration: 0.5
      }
    }
  };

  const menu = (
    <Motion
      padding="0"
      variants={menuWrapper}
      animate={navMenuOpen ? "open" : "closed"}
      extraStyles={`position: absolute; z-index: 3;`}
      positionTransition
      minWidth="100%"
      boxShadow={`0px 4px 2px 0px rgba(0, 0, 0, 0.19);`}
      initial="closed"
    >
      <Box
        minWidth="100%"
        background={"#f6f6f9"}
        padding="0"
        minHeight={searchMenuOpen ? "9.75rem" : "10rem"}
        onMouseLeave={() => {
          if (isAnimating === false) {
            setNavMenuOpen(false);
            setSearchMenuOpen(false);
          }
        }}
        extraStyles={`> div > div { min-width: 100%; }`}
      >
        <Center
          maxWidth={isMobile ? "100%" : "84.375rem"}
          intrinsic={!isMobile}
        >
          <Cluster justify="center" align="center" minWidth="100%">
            <Box
              minHeight={searchMenuOpen ? "9.75rem" : "10rem"}
              padding="24px 0"
              minWidth="100%"
            >
              {searchMenuOpen ? <SearchMenu /> : navItems[selectedNavMenu]}
            </Box>
          </Cluster>
        </Center>
      </Box>
    </Motion>
  );

  const hamburger = (
    <Box padding="0">
      <Cover singleChild fillCenter>
        <HamburgerButton
          isActive={isHamburgerOpen}
          onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
          activeColor={themeValues.linkColor}
          inactiveColor={themeValues.linkColor}
        />
      </Cover>
    </Box>
  );

  const searchBox = () => {
    return showSearchLink ? (
      <Box padding="0" extraStyles={`justify-self: flex-end; height: 102px;`}>
        <SearchLink />
      </Box>
    ) : null;
  };

  const renderAlert = () => {
    const mainText = sitewideAlert?.text ?? "";
    const { ctaUrl, ctaText } = getCallToActionInfo(
      sitewideAlert?.callToActionLink || {},
      "",
      ""
    );

    return (
      <Alert
        height="70px"
        padding={0}
        variant="warn"
        showQuitLink={false}
        textOverride={
          <Cluster
            justify="space-between"
            align="center"
            minWidth="100%"
            flexGrow="1"
            childGap={0}
            nowrap
          >
            <p>{mainText}</p>
            {ctaUrl && ctaText && (
              <ButtonWithLink
                variant="primary"
                url={ctaUrl}
                text={ctaText}
                linkExtraStyles={`margin: 0;padding:${
                  isMobile ? "16px 8px" : "24px 12px"
                }`}
                extraStyles={`margin: 0; border-radius: 8px;`}
                textExtraStyles={isMobile && "font-size: 1rem;"}
              />
            )}
          </Cluster>
        }
        extraStyles={`min-height: 63px; display: flex; justify-content: center; border-color: #b34a00; border-width: 1px 0 0 0; border-radius: 0 0 4px 4px; > div { width: 100%; max-width: 84.25rem; padding: 0 1rem;}`}
      />
    );
  };

  return (
    <Box padding="0" role="banner">
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="shortcut icon" href={favicon} />
        <meta name="description" content={metaDesc} />
      </Helmet>
      <Stack childGap="0">
        <Box
          padding="0"
          extraStyles={`z-index: ${COLLAPSIBLE_Z_INDEX + 1};`}
          boxShadow={!navMenuOpen && `0px 4px 2px 0px rgba(0, 0, 0, 0.19);`}
        >
          <NavHeader
            backgroundColor="#FFFFFF"
            leftContent={navLinksContainer}
            rightContent={hamburgerMenuStyle ? hamburger : searchBox()}
            isMobile={isMobile}
            headerWidth={`${MAX_HEADER_WIDTH_IN_PIXELS}px`}
          />
        </Box>
      </Stack>
      {!hamburgerMenuStyle && (
        <Box padding="0" minWidth="100%">
          {menu}
        </Box>
      )}
      {!isEmpty(sitewideAlert) && renderAlert()}
    </Box>
  );
};

export default withWindowSize(
  themeComponent(Header, "Content", fallbackValues, "default")
);
