import {
  MANATEE_GREY,
  GRECIAN_GREY,
  CORNFLOWER_BLUE,
  HALF_COLONIAL_WHITE,
  HINT_GREEN,
  INFO_BLUE,
  MATISSE_BLUE,
  ROYAL_BLUE_VIVID,
  SEA_GREEN,
  ZEST_ORANGE
} from "../../../constants/colors";

const background = {
  info: `${INFO_BLUE}`,
  warn: `${HALF_COLONIAL_WHITE}`,
  primary: `${CORNFLOWER_BLUE}`,
  success: `${HINT_GREEN}`,
  disabled: `${GRECIAN_GREY}`
};

const color = {
  info: `${MATISSE_BLUE}`,
  warn: `${ZEST_ORANGE}`,
  primary: `${ROYAL_BLUE_VIVID}`,
  success: `${SEA_GREEN}`,
  disabled: `${MANATEE_GREY}`
};

export const fallbackValues = {
  background,
  color
};
