import React, { Fragment, forwardRef } from "react";
import { BoxWrapper } from "./Box.styled";
import { safeChildren, screenReaderOnlyStyle } from "../../../util/general";

/*
  Box component to create generic boxes
  Supply padding, border, background, and color values

  srOnly prop enables special screen-reader style that is used to position content
  completely off screen (only for users of screen readers)
*/

const Box = forwardRef(
  (
    {
      autocompleteValue,
      padding = "16px",
      borderSize = "0px",
      borderColor = "transparent",
      borderRadius,
      boxShadow = "none",
      background,
      color,
      minHeight,
      width,
      minWidth,
      maxWidth,
      borderWidthOverride,
      border,
      textAlign,
      hoverStyles,
      activeStyles,
      disabledStyles,
      focusStyles,
      variant,
      as,
      onClick,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      onBlur,
      onTouchEnd,
      theme,
      hiddenStyles,
      extraStyles,
      srOnly = false,
      dataQa,
      children,
      ...rest
    },
    ref
  ) => (
    <BoxWrapper
      autoComplete={autocompleteValue}
      padding={padding}
      borderSize={borderSize}
      borderColor={borderColor}
      boxShadow={boxShadow}
      color={color}
      minHeight={minHeight}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      background={background}
      borderRadius={borderRadius}
      borderWidthOverride={borderWidthOverride}
      border={border}
      hoverStyles={hoverStyles}
      activeStyles={activeStyles}
      disabledStyles={disabledStyles}
      focusStyles={focusStyles}
      variant={variant}
      as={as}
      onClick={onClick}
      hiddenStyles={hiddenStyles}
      onKeyDown={onKeyDown}
      extraStyles={
        srOnly ? `${screenReaderOnlyStyle}${extraStyles}` : extraStyles
      }
      theme={theme}
      textAlign={textAlign}
      data-qa={dataQa}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      onTouchEnd={onTouchEnd}
      ref={ref}
      {...rest}
    >
      {children && safeChildren(children, <Fragment />)}
    </BoxWrapper>
  )
);

export default Box;
