import styled from "styled-components";

export const DetailText = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  line-height: 1.5;
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};
  font-family: ${({ fontFamily }) => fontFamily};

  ${({ extraStyles }) => extraStyles};
`;
