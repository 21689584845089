import React, { useState, Fragment } from "react";
import { Stack, Box, Cluster } from "../../atoms/layouts";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./Tabs.theme";
import Tab from "../../atoms/tab";

const HORIZONTAL = "horizontal";

const Tabs = ({
  tabsConfig,
  tabsDisplayMode // can be either HORIZONTAL or VERTICAL
}) => {
  const [activeTab, toggleActiveTab] = useState(tabsConfig.tabs[0].label);

  const createTabs = (tabConfig, activeTab) => {
    return tabConfig.tabs.map(tab => {
      return (
        <Tab
          activeTab={activeTab}
          key={tab.label}
          label={tab.label}
          setActiveTab={() => toggleActiveTab(tab.label)}
        />
      );
    });
  };

  const showHorozontal = (tabsConfig, activeTab) => {
    return (
      <Cluster justify={"space-around"}>
        {createTabs(tabsConfig, activeTab)}
      </Cluster>
    );
  };

  const showVertical = (tabsConfig, activeTab) => {
    return <Stack>{createTabs(tabsConfig, activeTab)}</Stack>;
  };

  return (
    <Box className="tabs">
      <Box className="tab-list">
        {tabsDisplayMode == HORIZONTAL
          ? showHorozontal(tabsConfig, activeTab)
          : showVertical(tabsConfig, activeTab)}
      </Box>
      <Box className="tab-content">
        <Box>
          {tabsConfig.tabs.map((tab, idx) => {
            if (tab.label !== activeTab) return undefined;
            return <Fragment key={idx}>{tab.content}</Fragment>;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default themeComponent(Tabs, "NavigationTab", fallbackValues);
