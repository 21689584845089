import React from "react";

const SuccessfulIconSmall = ({ iconIndex = 0 }) => {
  const mask0ID = `SuccessfulIconSmall-mask0-${iconIndex}`;
  const mask1ID = `SuccessfulIconSmall-mask1-${iconIndex}`;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#317D4F" />
      <mask
        id={mask0ID}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <circle cx="10" cy="10" r="10" fill="white" />
      </mask>
      <g mask={`url(#${mask0ID})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7817 6.18688C14.5864 5.99162 14.2698 5.99162 14.0746 6.18688L8.53558 11.7259L6.53211 9.72242C6.33685 9.52715 6.02027 9.52715 5.825 9.72242L5.3536 10.1938C5.15834 10.3891 5.15834 10.7057 5.3536 10.9009L7.71062 13.2579L8.18203 13.7294C8.37729 13.9246 8.69387 13.9246 8.88913 13.7294L9.36054 13.258C9.36068 13.2578 9.36082 13.2577 9.36096 13.2575L15.2531 7.36539C15.4484 7.17013 15.4484 6.85355 15.2531 6.65829L14.7817 6.18688Z"
          fill="white"
        />
        <mask
          id={mask1ID}
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="6"
          width="11"
          height="8"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7817 6.18688C14.5864 5.99162 14.2698 5.99162 14.0746 6.18688L8.53558 11.7259L6.53211 9.72242C6.33685 9.52715 6.02027 9.52715 5.825 9.72242L5.3536 10.1938C5.15834 10.3891 5.15834 10.7057 5.3536 10.9009L7.71062 13.2579L8.18203 13.7294C8.37729 13.9246 8.69387 13.9246 8.88913 13.7294L9.36054 13.258C9.36068 13.2578 9.36082 13.2577 9.36096 13.2575L15.2531 7.36539C15.4484 7.17013 15.4484 6.85355 15.2531 6.65829L14.7817 6.18688Z"
            fill="white"
          />
        </mask>
        <g mask={`url(#${mask1ID})`}>
          <rect
            x="1.66669"
            y="1.66666"
            width="16.6667"
            height="16.6667"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default SuccessfulIconSmall;
