import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "./FormLayouts.theme.js";
import { themeComponent } from "../../../util/themeUtils";
import { Box } from "../layouts";
import withWindowSize from "../../withWindowSize";

const FormContainer = ({ themeValues, children, ...rest }) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <Box
      padding={isMobile ? "1rem" : "1.5rem"}
      background={themeValues.formBackgroundColor}
      borderRadius="4px"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default themeComponent(
  withWindowSize(FormContainer),
  "FormContainer",
  fallbackValues,
  "default"
);
