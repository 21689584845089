import GetUserQuery from "./GetUser.graphql";
import GetUserFakeObligationsQuery from "./GetUserFakeObligations.graphql";
import GetUserFakeObligationsForCustomerManagementQuery from "./GetUserFakeObligationsForCustomerManagement.graphql";
import ChangePasswordQuery from "./ChangePassword.graphql";
import UpdateNameQuery from "./UpdateName.graphql";
import CreateTokenizedCreditCard from "./CreateTokenizedCreditCard.graphql";
import CreateAddressQuery from "./CreateAddress.graphql";
import CreateEmailQuery from "./CreateEmail.graphql";
import CreatePhoneQuery from "./CreatePhone.graphql";
import UpdateContactQuery from "./UpdateContact.graphql";
import UpdateAddressQuery from "./UpdateAddress.graphql";
import CreateBankAccount from "./CreateBankAccount.graphql";
import GetAccounts from "./GetAccounts.graphql";
import CreateObligationAssociation from "./CreateObligationAssociation.graphql";
import CreatePaymentSchedule from "./CreatePaymentSchedule.graphql";
import DeactivateSchedule from "./DeactivateSchedule.graphql";
import DeactivatePaymentInstrument from "./DeactivatePaymentInstrument.graphql";
import GetConstituentObligations from "./GetConstituentObligations.graphql";
import DeleteConstituentObligationAssoc from "./DeleteConstituentObligationAssoc.graphql";
import GetConstituentPayments from "./GetConstituentPayments.graphql";
import GetConstituentPayment from "./GetConstituentPayment.graphql";
import GetAccountBillURL from "./GetAccountBillURL.graphql";
import { createQuery } from "../../../../../util/graphql";

export const getUser = ({
  endpoint,
  clientSlug,
  authJwt,
  userID,
  subClientSlugs
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetUserQuery, {
    userID,
    subClientSlugs,
    getAllBy: { workflowType: "OBLIGATION_ASSOCIATION" }
  });

export const getUserFakeObligations = ({
  endpoint,
  clientSlug,
  authJwt,
  userID,
  subClientSlugs
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetUserFakeObligationsQuery, {
    userID,
    subClientSlugs,
    getAllBy: { workflowType: "OBLIGATION_ASSOCIATION" }
  });

export const getUserFakeObligationsForCustomerManagement = ({
  endpoint,
  clientSlug,
  authJwt,
  userID
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetUserFakeObligationsForCustomerManagementQuery, {
    userID
  });

export const changePassword = ({
  endpoint,
  clientSlug,
  authJwt,
  userID,
  currentPassword,
  newPassword
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(ChangePasswordQuery, {
    userID,
    currentPassword,
    newPassword
  });

export const updateName = ({
  endpoint,
  clientSlug,
  authJwt,
  userID,
  firstName,
  lastName
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(UpdateNameQuery, {
    userID,
    firstName,
    lastName
  });

export const createEmail = ({
  endpoint,
  clientSlug,
  authJwt,
  constituentID,
  email
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreateEmailQuery, {
    constituentID,
    value: email
  });

export const createPhone = ({
  endpoint,
  clientSlug,
  authJwt,
  constituentID,
  phone
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreatePhoneQuery, {
    constituentID,
    value: phone
  });

export const updateContact = ({
  endpoint,
  clientSlug,
  authJwt,
  contactId,
  value
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(UpdateContactQuery, {
    contactId,
    value
  });

export const updateAddress = ({
  endpoint,
  clientSlug,
  authJwt,
  addressId,
  country,
  city,
  stateProvince,
  street1,
  street2,
  zip
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(UpdateAddressQuery, {
    addressId,
    country,
    city,
    stateProvince,
    street1,
    street2,
    zip
  });

export const createAddress = ({
  endpoint,
  clientSlug,
  authJwt,
  constituentID,
  city,
  country,
  isPrimary,
  stateProvince,
  street1,
  street2,
  zip
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreateAddressQuery, {
    constituentID,
    city,
    country,
    isPrimary,
    stateProvince,
    street1,
    street2,
    zip
  });

export const createTokenizedCreditCard = ({
  endpoint,
  clientSlug,
  authJwt,
  userID,
  nameOnCard,
  cardNumber,
  cvv,
  expiryMonth,
  expiryYear,
  zipCode,
  country
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreateTokenizedCreditCard, {
    userID,
    nameOnCard,
    cardNumber,
    cvv,
    expiryMonth,
    expiryYear,
    zipCode,
    country
  });

export const createBankAccount = ({
  endpoint,
  clientSlug,
  authJwt,
  userID,
  accountNumber,
  accountType,
  routingNumber,
  nameOnAccount
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreateBankAccount, {
    userID,
    accountNumber,
    accountType,
    routingNumber,
    nameOnAccount
  });

export const getAccounts = ({ endpoint, clientSlug, getAllBy }) =>
  createQuery(
    endpoint,
    clientSlug,
    ""
  )(GetAccounts, {
    getAllBy
  });

export const createObligationAssociation = ({
  endpoint,
  clientSlug,
  authJwt,
  subClientSlug,
  constituentID,
  verificationKey,
  client
}) => {
  return createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreateObligationAssociation, {
    input: {
      clientSlug: client,
      subClientSlug,
      constituentID,
      verificationKey
    }
  });
};

export const createPaymentScheduleAttempt = ({
  endpoint,
  clientSlug,
  authJwt,
  schedule
}) => {
  return createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(CreatePaymentSchedule, {
    schedule
  });
};

export const deactivateScheduleAttempt = ({
  endpoint,
  clientSlug,
  authJwt,
  schedule
}) => {
  return createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(DeactivateSchedule, {
    schedule
  });
};

export const deactivatePaymentInstrumentAttempt = ({
  endpoint,
  clientSlug,
  authJwt,
  paymentInstrument
}) => {
  return createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(DeactivatePaymentInstrument, {
    id: paymentInstrument
  });
};

export const getConstituentObligations = ({
  endpoint,
  clientSlug,
  authJwt,
  subClientSlugs,
  constituentID,
  client
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetConstituentObligations, {
    obligationAssocInput: {
      clientSlug: client,
      subClientSlugs,
      constituentID
    }
  });

export const deleteConstituentObligationAssoc = ({
  endpoint,
  clientSlug,
  authJwt,
  id,
  client
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(DeleteConstituentObligationAssoc, {
    id,
    client
  });

export const getConstituentPayments = ({
  agencies,
  authJwt,
  client,
  clientSlug,
  constituentId,
  endpoint,
  page,
  perPage,
  statuses
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetConstituentPayments, {
    agencies,
    client,
    constituentId,
    page,
    perPage,
    statuses
  });

export const getConstituentPayment = ({
  authJwt,
  clientSlug,
  constituentId,
  endpoint,
  paymentId
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetConstituentPayment, {
    constituentId,
    paymentId
  });

export const getAccountBillURL = ({
  authJwt,
  endpoint,
  accountId,
  clientSlug,
  subClientSlug
}) =>
  createQuery(
    endpoint,
    clientSlug,
    authJwt
  )(GetAccountBillURL, {
    accountId,
    clientSlug,
    subClientSlug
  });
