import React from "react";
import {
  BankIcon,
  BankIconLarge,
  GenericCard,
  GenericCardLarge
} from "@thecb/components";
import { PAYMENT_METHOD_CREDIT_CARD } from "/constants/transaction_history_constants";

export const PaymentMethodIcon = ({ paymentType, large = false }) => {
  if (paymentType === PAYMENT_METHOD_CREDIT_CARD) {
    return large ? <GenericCardLarge /> : <GenericCard />;
  } else {
    return large ? <BankIconLarge /> : <BankIcon />;
  }
};
