import React from "react";

const DropdownIcon = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 14 10"
    height="8"
    width="12"
  >
    <g transform="translate(1 1)">
      <g fillRule="evenodd" fill="none">
        <g fill="#333" transform="translate(-155 -22)">
          <polygon
            points="165.59 22.59 161 27.17 156.41 22.59 155 24 161 30 167 24"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DropdownIcon;
