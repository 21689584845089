import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const PropertiesIconSmall = ({ themeValues }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22px"
      height="22px"
      viewBox="-1 0 22 15"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ display: "inline-block", verticalAlign: "text-bottom" }}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill={themeValues.singleIconColor}
          fillRule="nonzero"
          d="M16.875 8.5a.297.297 0 00.25-.125l.781-.969a.389.389 0 00.078-.281.397.397 0 00-.109-.25L15.25 4.719V.375a.362.362 0 00-.11-.266.362.362 0 00-.265-.109h-1.75a.362.362 0 00-.266.11.362.362 0 00-.109.265v2.281L9.969.344A1.531 1.531 0 009 0c-.354 0-.667.115-.938.344L.125 6.875a.397.397 0 00-.11.25.389.389 0 00.079.281l.781.969a.297.297 0 00.25.125.457.457 0 00.281-.094L8.75 2.375A.416.416 0 019 2.281c.083 0 .167.032.25.094l7.344 6.031a.457.457 0 00.281.094zM7.125 14a.362.362 0 00.266-.11.362.362 0 00.109-.265v-3.5c0-.104.036-.193.11-.266a.362.362 0 01.265-.109h2.25c.104 0 .193.036.266.11.073.072.109.16.109.265v3.5c0 .104.036.193.11.266.072.073.16.109.265.109H14.5a.723.723 0 00.531-.219.723.723 0 00.219-.531V8.781a.424.424 0 00-.125-.312L9.25 3.656A.416.416 0 009 3.563a.416.416 0 00-.25.093L2.875 8.47a.424.424 0 00-.125.312v4.469c0 .208.073.385.219.531A.723.723 0 003.5 14h3.625z"
        ></path>
      </g>
    </svg>
  );
};

export default themeComponent(
  PropertiesIconSmall,
  "Icons",
  fallbackValues,
  "primary"
);
