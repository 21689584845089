import { INFO_BLUE, MATISSE_BLUE, WHITE } from "../../../constants/colors";

const primaryColor = WHITE;
const primaryHoverColor = INFO_BLUE;
const secondaryColor = MATISSE_BLUE;
const secondaryHoverColor = "#115D7E";

export const fallbackValues = {
  primaryColor,
  primaryHoverColor,
  secondaryColor,
  secondaryHoverColor
};
