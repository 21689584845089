import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const ClipboardIcon = ({ themeValues }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1104_623"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="16"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.83929 3.43753H14.1607V5.59825H9.83929L9.83929 3.43753ZM8.75893 3.43753C8.75893 2.84087 9.24262 2.35718 9.83929 2.35718H14.1607C14.7574 2.35718 15.2411 2.84087 15.2411 3.43753V5.59825C15.2411 6.19491 14.7574 6.67861 14.1607 6.67861H9.83929C9.24262 6.67861 8.75893 6.19491 8.75893 5.59825V3.43753ZM6.59821 3.70762H7.94866V5.32816H6.59821C6.29988 5.32816 6.05804 5.57001 6.05804 5.86834V17.4822C6.05804 17.7805 6.29988 18.0224 6.59821 18.0224H17.4018C17.7001 18.0224 17.942 17.7805 17.942 17.4822V5.86834C17.942 5.57001 17.7001 5.32816 17.4018 5.32816H16.0513V3.70762H17.4018C18.5951 3.70762 19.5625 4.67501 19.5625 5.86834V17.4822C19.5625 18.6755 18.5951 19.6429 17.4018 19.6429H6.59821C5.40488 19.6429 4.4375 18.6755 4.4375 17.4822V5.86834C4.4375 4.67501 5.40488 3.70762 6.59821 3.70762Z"
          fill={themeValues.singleIconColor}
        />
      </mask>
      <g mask="url(#mask0_1104_623)">
        <rect width="24" height="24" fill={themeValues.singleIconColor} />
      </g>
    </svg>
  );
};

export default themeComponent(
  ClipboardIcon,
  "Icons",
  fallbackValues,
  "primary"
);
