import styled from "styled-components";
import { ButtonWithAction } from "../../atoms";
import { Box } from "../../atoms";

export const PopupMenuContainer = styled(Box)`
  display: flex;
  position: relative;
  padding: 0;
`;

export const PopupMenuTriggerButton = styled(ButtonWithAction)`
  padding: 10px 15px;
  min-width: auto;
  &:active,
  &:focus {
    outline: none;
    border: 1px solid rgba(196, 206, 244, 1);
    background-color: rgba(235, 239, 251, 1);
  }
`;
