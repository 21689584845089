import React from "react";
import { SEA_GREEN, HINT_GREEN } from "../../../constants/colors";

const CheckmarkIcon = () => (
  <svg
    width="98px"
    height="98px"
    viewBox="0 0 98 98"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    role="img"
    aria-label="Successful payment, green checkmark"
  >
    <g
      id="Cityville-Checkout---Desktop---Logged-In"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Confirmation" transform="translate(-672.000000, -167.000000)">
        <g id="hero---thank-you" transform="translate(1.000000, 104.000000)">
          <g id="icon---checkmark" transform="translate(672.000000, 64.000000)">
            <circle
              id="Oval-2"
              stroke={SEA_GREEN}
              strokeWidth="2"
              fill={HINT_GREEN}
              cx="48"
              cy="48"
              r="48"
            ></circle>
            <g
              id="Group-2"
              transform="translate(20.800000, 28.400000)"
              fill={SEA_GREEN}
            >
              <polygon
                id="Rectangle-2"
                transform="translate(12.800000, 30.000000) rotate(-45.000000) translate(-12.800000, -30.000000) "
                points="8 18 17.6 18 17.6 42 8 42"
              ></polygon>
              <polygon
                id="Rectangle-2-Copy-2"
                transform="translate(32.036753, 20.922035) rotate(45.000000) translate(-32.036753, -20.922035) "
                points="27.2367532 -3.74558441 36.8367532 -3.74558441 36.8367532 45.5896536 27.2367532 45.5896536"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckmarkIcon;
