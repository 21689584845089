import React from "react";

const DisabledAccountsAddIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.0408 78.1002V32.5154C77.0408 31.9007 76.5506 31.4039 75.9474 31.4039H32.3148C31.7106 31.4039 31.2213 31.9007 31.2213 32.5154V84.5326C31.2213 84.5326 55.7247 98.7028 77.0408 78.1002Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.0002 51.4983C88.0002 72.4847 70.9876 89.4983 50.0002 89.4983C29.0128 89.4983 12.0002 72.4847 12.0002 51.4983C12.0002 30.5109 29.0128 13.4983 50.0002 13.4983C59.0261 13.4983 67.3177 16.6447 73.8366 21.902C82.475 28.8674 88.0002 39.5368 88.0002 51.4983Z"
        stroke="#878E9B"
        strokeWidth="2.59255"
        strokeLinejoin="round"
      />
      <path
        d="M77.0408 78.1002V32.5154C77.0408 31.9007 76.5506 31.4039 75.9474 31.4039H32.3148C31.7106 31.4039 31.2213 31.9007 31.2213 32.5154V81.3853"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3362 78.1002V11.6448C23.3362 11.0302 23.8264 10.5324 24.4306 10.5324H68.0622C68.6664 10.5324 69.1556 11.0302 69.1556 11.6448V84.1973C69.1556 84.1973 58.4947 90.719 43.9673 88.1179C32.3336 86.0346 23.3362 78.1002 23.3362 78.1002Z"
        fill="white"
      />
      <path
        d="M69.156 84.1973V11.6448C69.156 11.0302 68.6658 10.5324 68.0625 10.5324H24.43C23.8258 10.5324 23.3365 11.0302 23.3365 11.6448V78.1002"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3362 19.3115H69.1556"
        stroke="#878E9B"
        strokeWidth="1.9285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.156 40.1818H77.041"
        stroke="#878E9B"
        strokeWidth="1.9285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 36.5974H62.672V31.4038H29.82V36.5974Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 36.5974H62.672V31.4038H29.82V36.5974Z"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 45.9207H62.672V40.7271H29.82V45.9207Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 45.9207H62.672V40.7271H29.82V45.9207Z"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8201 26.2602H46.2466"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 63.0901H62.672V57.8964H29.82V63.0901Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 63.0901H62.672V57.8964H29.82V63.0901Z"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 72.4143H62.672V67.2207H29.82V72.4143Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.82 72.4143H62.672V67.2207H29.82V72.4143Z"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3403 83.2719H62.672V79.298H48.3403V83.2719Z"
        fill="#878E9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3403 83.2719H62.672V79.298H48.3403V83.2719Z"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8201 52.7535H46.2466"
        stroke="#878E9B"
        strokeWidth="2.0653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.8364 21.9017C82.4748 28.8671 88 39.5365 88 51.498C88 72.4844 70.9874 89.498 50 89.498C29.0126 89.498 12 72.4844 12 51.498"
        stroke="#878E9B"
        strokeWidth="2.59255"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.1557 40.1818H77.0407V31.4038H69.1557V40.1818Z"
        fill="#878E9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3363 19.3112H69.1557V10.5323H23.3363V19.3112Z"
        fill="#878E9B"
      />
      <path
        d="M72.0041 90.1C79.3495 90.1 85.3041 84.1454 85.3041 76.8C85.3041 69.4546 79.3495 63.5 72.0041 63.5C64.6587 63.5 58.7041 69.4546 58.7041 76.8C58.7041 84.1454 64.6587 90.1 72.0041 90.1Z"
        fill="#878E9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.2041 77.5H73.2041V83.5H71.2041V77.5H65.2041V75.5H71.2041V69.5H73.2041V75.5H79.2041V77.5Z"
        fill="white"
      />
      <mask
        id="mask0_7610_37196"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="65"
        y="69"
        width="15"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.2041 77.5H73.2041V83.5H71.2041V77.5H65.2041V75.5H71.2041V69.5H73.2041V75.5H79.2041V77.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7610_37196)">
        <rect x="60.2041" y="64.5" width="24" height="24" fill="white" />
      </g>
    </svg>
  );
};

export default DisabledAccountsAddIcon;
