import React from "react";
import CardRegistryCard from "./CardRegistryCard";

export const cardRegistry = {
  accounts: props => (
    <CardRegistryCard
      icon="accounts"
      heading="Find Your Account"
      buttonText="Find Account"
      text="Find and attach your accounts to make your payments simple."
      cardAction="/profile/accounts"
      {...props}
    />
  ),
  properties: props => (
    <CardRegistryCard
      icon="properties"
      heading="Find Your Property"
      buttonText="Find Property"
      text="Find and attach your properties to make your tax payments simple."
      cardAction="/profile/properties"
      {...props}
    />
  ),
  payment: props => (
    <CardRegistryCard
      icon="payment"
      heading="Manage Your Wallet"
      buttonText="Go to Wallet"
      text="Add your personal information and payment methods to make fast payments."
      cardAction="/profile/wallet"
      {...props}
    />
  )
};

export default cardRegistry;
