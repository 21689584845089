import { useEffect, useState } from "react";

const initialToastState = {
  isOpen: false,
  variant: "",
  message: ""
};

const useToastNotification = ({ timeout = 5000 } = {}) => {
  const [toastState, setToastState] = useState(initialToastState);

  useEffect(() => {
    if (toastState.isOpen && timeout > 0) {
      setTimeout(() => {
        setToastState(initialToastState);
      }, timeout);
    }
  }, [timeout, toastState.isOpen]);

  const showToast = ({ message, variant }) =>
    setToastState({
      isOpen: true,
      variant,
      message
    });

  const hideToast = () => setToastState(initialToastState);

  return {
    isToastOpen: toastState.isOpen,
    toastVariant: toastState.variant,
    toastMessage: toastState.message,
    showToast,
    hideToast
  };
};

export default useToastNotification;
