import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Cluster,
  Paragraph,
  ExternalLink
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { IoMdArrowRoundForward } from "@react-icons/all-files/io/IoMdArrowRoundForward";

const AttachedFileBlock = ({ fileName, fileType, file, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);
  return (
    <Box background="transparent" minWidth="100%" padding="0">
      <Stack childGap="24px">
        <Paragraph key="title">Attached Files</Paragraph>
        <ExternalLink
          href={`https://media.graphassets.com/${file.url}`}
          extraStyles="text-decoration: none;"
        >
          <Box
            padding={isMobile ? "24px 16px" : "24px 16px 24px 24px"}
            background="white"
            borderRadius="1px"
            boxShadow={`inset 0px 3px 0px 0px ${themeValues.lightAccentText},
0px 1px 3px 0px rgba(0, 0, 0, 0.5)`}
            key="file container"
            minWidth={"100%"}
          >
            <Stack childGap="0.5rem">
              <Paragraph
                variant="pL"
                weight={FONT_WEIGHT_SEMIBOLD}
                color={themeValues.darkText}
              >
                {fileName}
              </Paragraph>
              <Box minWidth="100%" padding="0">
                <Cluster justify="space-between" align="center">
                  <Paragraph color={themeValues.darkAccentText}>
                    {fileType}
                  </Paragraph>
                  <IoMdArrowRoundForward
                    size="29px"
                    color={themeValues.linkColor}
                  />
                </Cluster>
              </Box>
            </Stack>
          </Box>
        </ExternalLink>
      </Stack>
    </Box>
  );
};

export default AttachedFileBlock;
