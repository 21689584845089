import React from "react";

const WarningIconXS = ({
  color = "#B34A00",
  title,
  titleID = "warning-icon-title",
  iconIndex = 0
}) => (
  <svg
    width="16px"
    height="13px"
    viewBox="0 0 16 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-labelledby={title && `#${titleID}-${iconIndex}`}
    role="img"
  >
    {title && <title id={`${titleID}-${iconIndex}`}>{title}</title>}
    <defs>
      <path
        d="M11.0106463,4.4659633 C11.3063934,4.63914978 11.5526739,4.88543032 11.7258604,5.18117739 L16.7999622,13.846119 C17.3581267,14.7992854 17.0379144,16.0244612 16.0847481,16.5826257 C15.7781918,16.762142 15.4293522,16.8567653 15.0741018,16.8567653 L4.92589823,16.8567653 C3.82132873,16.8567653 2.92589823,15.9613348 2.92589823,14.8567653 C2.92589823,14.5015149 3.02052159,14.1526753 3.20003784,13.846119 L8.27413962,5.18117739 C8.83230416,4.22801102 10.0574799,3.90779877 11.0106463,4.4659633 Z M10.6666667,13.3333333 L9.33333333,13.3333333 L9.33333333,14.6666667 L10.6666667,14.6666667 L10.6666667,13.3333333 Z M10.6666667,6.66666667 L9.33333333,6.66666667 L9.33333333,12 L10.6666667,12 L10.6666667,6.66666667 Z"
        id={`WarningIconXs-path-1-${iconIndex}`}
      ></path>
    </defs>
    <g
      id={`WarningIconXs-Group-1-${iconIndex}`}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id={`WarningIconXs-Group-2-${iconIndex}`}
        transform="translate(-135.000000, -483.000000)"
      >
        <g
          id={`WarningIconXs-Group-3-${iconIndex}`}
          transform="translate(133.000000, 479.000000)"
        >
          <mask id={`WarningIconXs-mask-2-${iconIndex}`} fill="white">
            <use xlinkHref={`#WarningIconXs-path-1-${iconIndex}`}></use>
          </mask>
          <use
            id={`WarningIconXs-Mask-${iconIndex}`}
            fill={color}
            fillRule="nonzero"
            xlinkHref={`#WarningIconXs-path-1-${iconIndex}`}
          ></use>
          <polygon
            id={`WarningIconXs-Path-${iconIndex}`}
            fill={color}
            fillRule="nonzero"
            mask={`url(#WarningIconXs-mask-2-${iconIndex})`}
            points="-2.84217094e-14 -4.26325641e-14 20 -4.26325641e-14 20 20 -2.84217094e-14 20"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export default WarningIconXS;
