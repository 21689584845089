const backgroundColor = { default: "transparent", small: "transparent" };
const lineItem = { default: "default", small: "small" };
const labeledAmountSubtotal = { default: "regular", small: "small" };
const labeledAmountTotal = { default: "large", small: "small" };

export const fallbackValues = {
  backgroundColor,
  lineItem,
  labeledAmountSubtotal,
  labeledAmountTotal
};
