import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import {
  ResetPasswordForm,
  Box,
  Cluster,
  ForgotPasswordIcon,
  Heading,
  ButtonWithAction,
  withWindowSize,
  CenterSingle,
  FormContainer,
  util,
  constants
} from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";

const ResetPasswordContent = ({
  forms,
  actions,
  alertBars,
  submitting,
  isMobile,
  handleSubmit,
  showErrors
}) => (
  <Box padding={isMobile ? "0 0 48px" : "32px 0 64px"}>
    <Box
      padding="0"
      width={isMobile ? `auto` : `576px`}
      borderRadius="4px"
      background={constants.colors.WHITE}
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
    >
      <Box
        background={constants.colors.GRECIAN_GREY}
        minWidth="100%"
        padding="0.5rem"
      >
        <Cluster justify="center" align="center">
          <ForgotPasswordIcon />
        </Cluster>
      </Box>
      <FormContainer>
        {alertBars.resetPasswordAlertBar.alerts.length > 0 && (
          <Box padding="0 0 1rem">
            <AlertBar
              {...alertBars.resetPasswordAlertBar}
              {...actions.alertBars.resetPasswordAlertBar}
            />
          </Box>
        )}
        <Box padding={isMobile ? "0 0 1rem" : "0 0 1.5rem"}>
          <Heading
            variant="h5"
            color={constants.colors.CHARADE_GREY}
            weight="600"
            as="h1"
          >
            Reset your password
          </Heading>
        </Box>
        <ResetPasswordForm
          {...forms.resetPasswordForm}
          {...actions.forms.resetPasswordForm}
          handleSubmit={handleSubmit}
          submitting={submitting}
          showErrors={showErrors}
          isMobile={isMobile}
        />
        <Box padding={isMobile ? "1rem 0 0" : "1.5rem 0 0"}>
          <ButtonWithAction
            text="Reset Password"
            variant="primary"
            isLoading={submitting}
            action={handleSubmit}
            extraStyles={`width: 100%;`}
            borderRadius="4px"
            dataQa="Reset Password"
          />
        </Box>
      </FormContainer>
    </Box>
  </Box>
);

const ResetPassword = ({
  forms,
  actions,
  alertBars,
  submitResetPasswordForm,
  setPasswordResetKey,
  setAuthenticationId,
  submitting,
  handleFocusErrors
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [errors, handleErrors] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  const query = queryString.parse(location.search, { decode: false });
  let authenticationId = query?.authentication_id ?? null;
  let passwordResetKey = query?.password_reset_key ?? null;
  if (authenticationId === null || passwordResetKey === null) {
    navigate("/login");
  }

  useEffect(() => {
    setAuthenticationId({ authenticationId });
    setPasswordResetKey({ passwordResetKey });
  }, []);

  const handleSubmit = util.general.generateClickHandler(
    forms.resetPasswordForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitResetPasswordForm()
  );

  return (
    <CenterSingle
      content={
        <ResetPasswordContent
          forms={forms}
          actions={actions}
          alertBars={alertBars}
          submitting={submitting}
          handleSubmit={handleSubmit}
          showErrors={errors}
          isMobile={isMobile}
        />
      }
    />
  );
};

export default withWindowSize(ResetPassword);
