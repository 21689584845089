import ResetPasswordForm from "./ResetPasswordForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./ResetPasswordForm.state";

ResetPasswordForm.reducer = reducer;
ResetPasswordForm.mapStateToProps = mapStateToProps;
ResetPasswordForm.mapDispatchToProps = mapDispatchToProps;
export default ResetPasswordForm;
