import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  grid-gap: ${({ columnGap }) => columnGap};
  grid-template-columns: ${({ aboveMin, minColWidth, maxColWidth }) =>
    aboveMin
      ? `repeat(auto-fill, minmax(${minColWidth}, ${maxColWidth}))`
      : "100%"};

  @supports (width: min(${({ minColWidth }) => minColWidth}, 100%)) {
    & {
      grid-template-columns: repeat(
        auto-fill,
        minmax(
          ${({ minColWidth }) => minColWidth},
          ${({ maxColWidth }) => maxColWidth}
        )
      );
    }
  }
`;
