import React, { useRef, useState, useEffect } from "react";
import { isMaxSelectionReached } from "./util";

const useKeyboardNavigation = ({
  options,
  appliedOptions,
  selectedOptions,
  maxSelections
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const itemRefs = useRef([]);
  const totalItemsLength = options.length + appliedOptions.length;

  const handleArrowUp = event => {
    event.preventDefault();
    setFocusedIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : totalItemsLength - 1
    );
  };

  const handleArrowDown = event => {
    event.preventDefault();
    setFocusedIndex(prevIndex =>
      prevIndex < totalItemsLength - 1 ? prevIndex + 1 : 0
    );
  };

  const handleSpacebar = event => {
    event.preventDefault();
    const validFocusedIndex = focusedIndex < 0 ? 0 : focusedIndex;
    // Select option on spacebar press if the maximum selection hasn't been reached.
    if (
      !isMaxSelectionReached(maxSelections, selectedOptions) &&
      itemRefs.current &&
      itemRefs.current[validFocusedIndex]
    ) {
      const nestedInput = itemRefs.current[validFocusedIndex].querySelector(
        "input"
      );
      if (nestedInput) {
        nestedInput.click();
      }
    }
  };

  const handleTab = event => {
    // Reset focus when tabbing out of the list.
    setFocusedIndex(-1);
  };

  const keyActions = {
    " ": event => handleSpacebar(event),
    Space: event => handleSpacebar(event),
    Tab: event => handleTab(event),
    ArrowUp: event => handleArrowUp(event),
    ArrowDown: event => handleArrowDown(event)
  };

  const handleKeyDown = event => {
    const eventKey = event.code || event.key;
    const action = keyActions[eventKey];
    if (action) {
      action(event);
    }
  };

  useEffect(() => {
    if (
      focusedIndex !== -1 &&
      itemRefs.current &&
      itemRefs.current[focusedIndex]
    ) {
      itemRefs.current[focusedIndex].focus(); // move focus to the active option
    }
  }, [focusedIndex]);

  return {
    itemRefs,
    focusedIndex,
    handleKeyDown
  };
};

export default useKeyboardNavigation;
