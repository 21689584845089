import React from "react";
import { repeat } from "ramda";

import { fallbackValues } from "./HighlightTabRow.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Reel, Box, Center } from "../../atoms/layouts";
import Text from "../../atoms/text";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";

// this component needs some fix'n
const HighlightTabRow = ({
  tabs,
  highlightIndex,
  themeValues,
  isMobile,
  useOrderedList = true,
  useUnorderedList = false
}) => {
  const itemsAfterIndex = tabs.slice(highlightIndex).length - 1;
  const itemsBeforeIndex = tabs.slice(0, highlightIndex).length;
  const boxesBefore =
    itemsAfterIndex > itemsBeforeIndex && isMobile
      ? itemsAfterIndex - itemsBeforeIndex
      : 0;
  const boxesAfter =
    itemsAfterIndex < itemsBeforeIndex && isMobile
      ? itemsBeforeIndex - itemsAfterIndex
      : 0;
  return (
    <Box
      padding="0"
      background={themeValues.backgroundColor}
      margin="0"
      boxShadow="0px 0px 4px 0px rgb(110, 114, 126)"
      role="region"
      aria-label="Payment step"
    >
      <Box srOnly padding="0">
        <Text>{`Current step: ${tabs[highlightIndex]}`}</Text>
      </Box>
      <Center maxWidth="76.5rem">
        <Reel
          padding="0"
          childGap="0"
          childWidth="11rem"
          justifyContent="space-evenly"
          disableScroll
          useOrderedList={useOrderedList}
          useUnorderedList={useUnorderedList}
        >
          {repeat(<Box />, boxesBefore)}
          {tabs.map((t, i) => (
            <Box
              key={t}
              borderSize="3px"
              borderColor={
                highlightIndex == i ? themeValues.textColor : "transparent"
              }
              borderWidthOverride="0 0 3px 0"
              extraStyles="text-align: center; display: block;"
              as="li"
              aria-current={highlightIndex == i ? "step" : ""}
              id={`${t?.toLowerCase()?.replace(/\s/g, "-")}-tab`}
            >
              <Text
                variant="p"
                textAlign="center"
                color={themeValues.textColor}
                weight={FONT_WEIGHT_SEMIBOLD}
                extraStyles="display: block; white-space: nowrap;"
              >
                {t}
              </Text>
            </Box>
          ))}
          {repeat(<Box />, boxesAfter)}
        </Reel>
      </Center>
    </Box>
  );
};

export default themeComponent(
  HighlightTabRow,
  "HighlightTabRow",
  fallbackValues
);
