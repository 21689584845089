/*
Need to add a new color? Visit http://chir.ag/projects/name-that-color to generate a color name.

Name already taken? If you can't use the existing color, then use thesaurus.com to pick a similar name
to the one generated by name-that-color.
*/

const TRANSPARENT = "transparent";
// WHITE
const WHITE = "#FFFFFF";
const SOLITUDE_WHITE = "#EBEDF1";
const SEASHELL_WHITE = "#F1F1F1";
const ALABASTER_WHITE = "#F7F7F7";
const AQUA_HAZE_WHITE = "#F7F9FA";
const BLEACH_WHITE = "#FEF4d7";
const CATSKILL_WHITE = "#EAF2F6";
// GREY
const ATHENS_GREY = "#F6F6F9"; // CB-10 (cool)
const ALTO_GREY = "#d1d1d1";
const SILVER_GREY = "#cdcdcd";
const PEWTER_GREY = "#DFE1E4";
const ASH_GREY = "#979797";
const IRON_GREY = "#d5d8dc";
const GHOST_GREY = "#CACED8"; // CB-30 (cool)
const DUSTY_GREY = "#9B9B9B";
const REGENT_GREY = "#959EA7";
const STORM_GREY = "#6D717E"; // CB-70 (cool)
const TROUT_GREY = "#515660";
const MINESHAFT_GREY = "#333333";
const SOOT_GREY = "#999999";
const FIREFLY_GREY = "#091325";
const BRIGHT_GREY = "#3B414D"; // CB-80 (cool)
const CHARADE_GREY = "#292A33"; // CB-90 (cool)
const GRECIAN_GREY = "#E5E7EC"; // CB-20 (cool)
const BLACK_SQUEEZE = "#EAF2F7";
const GREY_CHATEAU = "#959CA8"; // CB-50 (cool)
const MANATEE_GREY = "#878E9B"; // CB-60 (cool)
const VIVID_GREY = "#303646";
// BLUE
const CLOUDBURST_BLUE = "#26395c";
const ZODIAC_BLUE = "#14284b";
const CONGRESS_BLUE = "#005095";
const SCIENCE_BLUE = "#0074D9";
const MARINER_BLUE = "#2E75D2";
const CURIOUS_BLUE = "#27A9E1";
const SELAGO_BLUE = "#F2F8FD";
const ONAHAU_BLUE = "#D1ECFF";
const PICKLED_BLUE = "#2C3E50";
const CERULEAN_BLUE = "#0D8DC4";
const FOAM_BLUE = "#EFF4FD";
const CELLO_BLUE = "#214566";
const BOSTON_BLUE = "#357fb8";
const INFO_BLUE = "#E4F4FD";
const HOVER_LIGHT_BLUE = "#EFFAFF";
const MATISSE_BLUE = "#15749D";
const ROYAL_BLUE_VIVID = "#3B5BDB";
const CORNFLOWER_BLUE = "#EBEFFB";
const LINK_WATER = "#C4CEF4";

// GREEN
const FOREST_GREEN = "#19b03F";
const MEADOW_GREEN = "#16C98D";
const POLAR_GREEN = "#DCF7EE";
const ICE_GREEN = "#A4F6DF";
const HAZE_GREEN = "#05A670";
const EMERALD_GREEN = "#47B872";
const HINT_GREEN = "#E8FFEF";
const SEA_GREEN = "#317D4F";
// YELLOW
const SELECTIVE_YELLOW = "#F8B700";
const MUSTARD_YELLOW = "#FFD459";
// ORANGE
const CARROT_ORANGE = "#ED9620";
const ZEST_ORANGE = "#F47820";
const APRICOT_ORANGE = "#FFE8D8";
const WARNING_ORANGE = "#B34A00";
// RED
const RED = "#FF0000";
const CRIMSON_RED = "#ED1C24";
const THUNDERBIRD_RED = "#C3191F";
const RAZZMATAZZ_RED = "#ED125F";
const FANTASY_RED = "#FCF4F4";
const COSMOS_RED = "#FFD0D3";
const BLUSH_RED = "#FFF0F5";

// Second level color constants
const ERROR_COLOR = RAZZMATAZZ_RED;

// These colors are sequestered so that the alert component can reference them // by type of alert
const ALERT_COLORS = {
  warn: {
    background: APRICOT_ORANGE,
    border: ZEST_ORANGE,
    iconBackground: MUSTARD_YELLOW,
    iconFill: CARROT_ORANGE
  },
  info: {
    background: INFO_BLUE,
    border: CURIOUS_BLUE,
    iconBackground: ONAHAU_BLUE,
    iconFill: CONGRESS_BLUE
  },
  error: {
    background: BLUSH_RED,
    border: RAZZMATAZZ_RED,
    iconBackground: COSMOS_RED,
    iconFill: THUNDERBIRD_RED
  },
  success: {
    background: HINT_GREEN,
    border: EMERALD_GREEN,
    iconBackground: ICE_GREEN,
    iconFill: HAZE_GREEN
  },
  text: ZODIAC_BLUE,
  link: SCIENCE_BLUE
};

export {
  TRANSPARENT,
  WHITE,
  SOLITUDE_WHITE,
  SEASHELL_WHITE,
  ALABASTER_WHITE,
  AQUA_HAZE_WHITE,
  BLEACH_WHITE,
  CATSKILL_WHITE,
  ATHENS_GREY,
  ALTO_GREY,
  SILVER_GREY,
  PEWTER_GREY,
  ASH_GREY,
  IRON_GREY,
  GHOST_GREY,
  DUSTY_GREY,
  REGENT_GREY,
  STORM_GREY,
  TROUT_GREY,
  MINESHAFT_GREY,
  FIREFLY_GREY,
  SOOT_GREY,
  BRIGHT_GREY,
  CHARADE_GREY,
  GRECIAN_GREY,
  MANATEE_GREY,
  VIVID_GREY,
  BLACK_SQUEEZE,
  GREY_CHATEAU,
  CLOUDBURST_BLUE,
  INFO_BLUE,
  ZODIAC_BLUE,
  CONGRESS_BLUE,
  SCIENCE_BLUE,
  MARINER_BLUE,
  CURIOUS_BLUE,
  SELAGO_BLUE,
  ONAHAU_BLUE,
  PICKLED_BLUE,
  CERULEAN_BLUE,
  FOAM_BLUE,
  CELLO_BLUE,
  BOSTON_BLUE,
  HOVER_LIGHT_BLUE,
  MATISSE_BLUE,
  ROYAL_BLUE_VIVID,
  CORNFLOWER_BLUE,
  LINK_WATER,
  FOREST_GREEN,
  MEADOW_GREEN,
  POLAR_GREEN,
  ICE_GREEN,
  HAZE_GREEN,
  EMERALD_GREEN,
  HINT_GREEN,
  SEA_GREEN,
  SELECTIVE_YELLOW,
  MUSTARD_YELLOW,
  CARROT_ORANGE,
  ZEST_ORANGE,
  APRICOT_ORANGE,
  WARNING_ORANGE,
  RED,
  CRIMSON_RED,
  THUNDERBIRD_RED,
  RAZZMATAZZ_RED,
  FANTASY_RED,
  COSMOS_RED,
  BLUSH_RED,
  ALERT_COLORS,
  ERROR_COLOR
};
