import React from "react";
import { noop } from "../../../../util/general";
import ButtonWithAction from "../../../atoms/button-with-action/ButtonWithAction";
import { CloseIcon } from "../../../atoms/icons/CloseIcon";

export const CloseIconButton = ({
  buttonExtraStyles = "",
  hideModal = noop,
  iconWidth = "24px",
  iconHeight = "24px",
  ariaLabel = "Close Modal"
}) => {
  return (
    <ButtonWithAction
      action={hideModal}
      aria-label={ariaLabel}
      contentOverride
      extraStyles={`
        min-height: auto;
        min-width: auto;
        height: 1.5rem;
        margin: 0 0 0 0.5rem;
        &:focus {
            outline-offset: -3px;
        }
     `}
      variant="smallGhost"
    >
      <CloseIcon
        role="img"
        aria-hidden="true"
        iconWidth={iconWidth}
        iconHeight={iconHeight}
      />
    </ButtonWithAction>
  );
};

export default CloseIconButton;
