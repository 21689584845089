import React from "react";
export const AlertWarningIcon = () => (
  <svg
    width="24px"
    height="21px"
    viewBox="0 0 24 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Alert/Warning"
        transform="translate(-24.000000, -36.000000)"
        fill="#F47820"
        fillRule="nonzero"
      >
        <g
          id="Icon/Warning-Container"
          transform="translate(24.000000, 34.000000)"
        >
          <path
            d="M13.0106463,2.58294345 C13.3063934,2.75612993 13.5526739,3.00241046 13.7258604,3.29815753 L23.0814452,19.2745017 C23.6396097,20.2276681 23.3193975,21.4528439 22.3662311,22.0110084 C22.0596748,22.1905246 21.7108352,22.285148 21.3555848,22.285148 L2.64441521,22.285148 C1.53984571,22.285148 0.64441521,21.3897175 0.64441521,20.285148 C0.64441521,19.9298976 0.739038571,19.581058 0.918554829,19.2745017 L10.2741396,3.29815753 C10.8323042,2.34499116 12.0574799,2.02477891 13.0106463,2.58294345 Z M13,17 L11,17 L11,19 L13,19 L13,17 Z M13,7 L11,7 L11,15 L13,15 L13,7 Z"
            id="icon---warning"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
