import React, { forwardRef } from "react";

const ScreenreaderOnlyText = forwardRef(({ children, ...rest }, ref) => (
  <span
    ref={ref}
    style={{
      position: "absolute",
      height: "1px",
      width: "1px",
      top: "-9999px",
      left: "-9999px",
      whiteSpace: "nowrap",
      textIndent: "-9999px",
      display: "inline-block"
    }}
    {...rest}
  >
    {children}
  </span>
));

export default ScreenreaderOnlyText;
