import React from "react";
import { v4 as uuidv4 } from "uuid";

const RightArrowIcon = ({
  color = "#3B5BDB",
  size = 25,
  paddingBottom = "2px"
}) => {
  const adjustedSize = Math.floor((size * 24) / 25);
  const maskWidth = Math.ceil(size * 0.68);
  const maskHeight = Math.ceil(size * 0.64);
  const maskId = `mask_${uuidv4()}`;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ paddingBottom, paddingLeft: "8px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2767 6.047C11.0816 6.24214 11.0815 6.55847 11.2764 6.75379L16.5035 11.9902H4.8335C4.55735 11.9902 4.3335 12.2141 4.3335 12.4902V13.4902C4.3335 13.7664 4.55735 13.9902 4.8335 13.9902H16.5035L11.2764 19.2267C11.0815 19.422 11.0816 19.7383 11.2767 19.9335L11.9799 20.6367C12.1752 20.8319 12.4918 20.8319 12.687 20.6367L19.9799 13.3438C20.1752 13.1485 20.1752 12.8319 19.9799 12.6367L12.687 5.34379C12.4918 5.14853 12.1752 5.14853 11.9799 5.34379L11.2767 6.047Z"
        fill={color}
      />
      <mask
        id={maskId}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width={maskWidth}
        height={maskHeight}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2767 6.047C11.0816 6.24214 11.0815 6.55847 11.2764 6.75379L16.5035 11.9902H4.8335C4.55735 11.9902 4.3335 12.2141 4.3335 12.4902V13.4902C4.3335 13.7664 4.55735 13.9902 4.8335 13.9902H16.5035L11.2764 19.2267C11.0815 19.422 11.0816 19.7383 11.2767 19.9335L11.9799 20.6367C12.1752 20.8319 12.4918 20.8319 12.687 20.6367L19.9799 13.3438C20.1752 13.1485 20.1752 12.8319 19.9799 12.6367L12.687 5.34379C12.4918 5.14853 12.1752 5.14853 11.9799 5.34379L11.2767 6.047Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect
          x="0.333496"
          y="0.990234"
          width={adjustedSize}
          height={adjustedSize}
          fill={color}
        />
      </g>
    </svg>
  );
};

export default RightArrowIcon;
