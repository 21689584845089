import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import parse from "html-react-parser";
import dompurify from "dompurify";
import {
  Box,
  Cover,
  Text,
  FormContainer,
  Module,
  constants,
  withWindowSize
} from "@thecb/components";

const Support = () => {
  const { isMobile, metadata } = useContext(ThemeContext);
  const supportContent = metadata?.data?.contactAndSupport?.content ?? "";

  return (
    <Box padding="0 0 16px" width={isMobile ? "100%" : "576px"}>
      <Module heading="Contact and Support">
        <Cover singleChild>
          <FormContainer>
            <Text
              color={constants.colors.CHARADE_GREY}
              extraStyles={`a { color: ${constants.colors.CHARADE_GREY}; }`}
            >
              {parse(dompurify.sanitize(supportContent))}
            </Text>
          </FormContainer>
        </Cover>
      </Module>
    </Box>
  );
};

export default withWindowSize(Support);
