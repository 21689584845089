import React, { Fragment } from "react";
import styled from "styled-components";
import GenericCard from "../icons/GenericCard";
import Text from "../text";
import { Box, Stack } from "../layouts";
import { fallbackValues } from "./FormattedCreditCard.theme";
import { themeComponent } from "../../../util/themeUtils";
import { renderCardStatus } from "../../../util/formats";

export const CreditCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CCIconWrapper = styled.div`
  margin-right: 16px;
  width: 30px;
  height: auto;
  display: flex;
`;

const FormattedCreditCard = ({
  lastFour,
  autoPay,
  expireDate,
  expirationStatus,
  themeValues
}) => (
  <CreditCardWrapper>
    <CCIconWrapper>
      <GenericCard />
    </CCIconWrapper>
    <Stack childGap="0">
      <Box padding="0">
        <Text
          variant="p"
          padding="0 0 0 8px"
          color={themeValues.textColor}
          textAlign="left"
          extraStyles={`display: inline-block;`}
        >
          {`Card ending in ${lastFour}`}
        </Text>
        {expireDate && (
          <Fragment>
            {renderCardStatus(expirationStatus, expireDate, "left", "p")}
          </Fragment>
        )}
      </Box>
      {autoPay && (
        <Text
          variant="p"
          color={themeValues.autopayTextColor}
          extraStyles={`font-style: italic;`}
        >{`Autopay Enabled`}</Text>
      )}
    </Stack>
  </CreditCardWrapper>
);
export default themeComponent(
  FormattedCreditCard,
  "FormattedCreditCard",
  fallbackValues
);
