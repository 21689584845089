import React from "react";

const PropertyStorefrontIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill="#3B5BDB"
    />
    <path
      d="M36.5244 20.7878C36.8705 20.7878 37.1734 20.6724 37.4329 20.4417C37.6925 20.2109 37.8439 19.9297 37.8872 19.5981C37.9305 19.2664 37.8656 18.9419 37.6925 18.6247L33.972 13.0872C33.7124 12.6834 33.3375 12.4815 32.8472 12.4815H15.2829C14.7926 12.4815 14.4177 12.6834 14.1581 13.0872L10.4809 18.6247C10.279 18.9419 10.1997 19.2664 10.243 19.5981C10.2862 19.9297 10.4376 20.2109 10.6972 20.4417C10.9568 20.6724 11.2596 20.7878 11.6057 20.7878H36.5244ZM25.4495 34.6315C25.8244 34.6315 26.1488 34.4945 26.4228 34.2205C26.6968 33.9465 26.8338 33.6221 26.8338 33.2471V22.1721H24.0651V29.094H15.7588V22.1721H12.9901V33.2471C12.9901 33.6221 13.1271 33.9465 13.4011 34.2205C13.6751 34.4945 13.9995 34.6315 14.3745 34.6315H25.4495ZM34.4479 34.6315C34.6498 34.6315 34.8156 34.5666 34.9454 34.4368C35.0752 34.307 35.1401 34.1412 35.1401 33.9393V22.1721H32.3713V33.9393C32.3713 34.1412 32.4362 34.307 32.566 34.4368C32.6958 34.5666 32.8616 34.6315 33.0635 34.6315H34.4479Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default PropertyStorefrontIcon;
