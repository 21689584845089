import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import parse from "html-react-parser";
import dompurify from "dompurify";
import { values, isEmpty } from "ramda";
import {
  Breadcrumb,
  Text,
  Detail,
  Stack,
  Center,
  Box,
  Module,
  ButtonWithAction,
  TermsAndConditions,
  RadioSection,
  Alert,
  BankIcon,
  GenericCard,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import {
  CREDIT_CARD_RESOURCE,
  ACH_RESOURCE,
  CREDIT_CARD_KIND
} from "../../Profile.state";
import {
  SetUpAutopayWrapper,
  FormContainer,
  ErrorContainer
} from "./SetUpAutopay.styled";
import AlertBar from "/components/alert-bar";
import {
  getCreditCardsForDisplay,
  getBankAccountsForDisplay
} from "../../Profile.selectors";
import {
  ACH_METHOD,
  CASH_METHOD,
  CC_METHOD
} from "../../../../../../constants/data_constants";
import AutopayDateSelect from "./AutopayDateSelect";
import { useFlags } from "launchdarkly-react-client-sdk";
import { AUTOPAY_DATE_SELECTION_FLAG } from "../../../../../../util/launchDarklyUtils";

const SetUpAutoPay = ({
  resources,
  resourcesActions,
  selectedAutopaymentMethodId,
  termsAndConditionsAgreedTo,
  alertBars,
  detailedObligation,
  actions,
  hasPaymentFlag,
  paymentConfigs
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const ldFlags = useFlags();
  const enableAutopayDateSelection = ldFlags[AUTOPAY_DATE_SELECTION_FLAG];
  const { displayCurrency, formatPercent } = util.general;
  const { renderCardStatus } = util.formats;
  let topLevel = location.pathname
    .split("/")
    .filter((pathPart, index) => index <= 2)
    .join("/");
  const {
    setAutoPaymentSelectedPaymentMethod,
    createPaymentSchedule,
    toggleTermsAndConditions,
    clearAutoPayInfo
  } = resourcesActions;
  const { setUpAutopayAlertBar: alertBar } = alertBars;
  const {
    alertBars: { setUpAutopayAlertBar: alertBarActions }
  } = actions;
  const obligationSlug = detailedObligation?.config?.obligationSlug;
  const clientPaymentConfig =
    paymentConfigs?.[obligationSlug] ?? paymentConfigs.default;
  const CCFee = clientPaymentConfig.options.fees.creditCard;
  const ACHFee = clientPaymentConfig.options.fees.ach;
  const showACHFee = false;
  const hideFee = clientPaymentConfig.options?.hideFeeOnAutopay ?? false;
  const clearOnDismount = true;
  const { isMobile, metadata } = useContext(ThemeContext);
  const [terms, setTerms] = useState(CREDIT_CARD_RESOURCE);
  const [termsError, setTermsError] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [processingFee, setProcessingFee] = useState(CCFee);
  const [processingFeeAlert, setProcessingFeeAlert] = useState();
  const creditCards = getCreditCardsForDisplay(resources);
  const bankAccounts = getBankAccountsForDisplay(resources);
  const paymentMethods = [...creditCards, ...bankAccounts];
  const firstObligation = detailedObligation.obligations[0];
  const subClientName = detailedObligation.name;
  const {
    allowedPaymentInstruments = [CASH_METHOD, CC_METHOD, ACH_METHOD]
  } = firstObligation;
  const canUseCC = allowedPaymentInstruments.includes(CC_METHOD);
  const canUseACH = allowedPaymentInstruments.includes(ACH_METHOD);
  useEffect(() => {
    if (clearOnDismount) {
      return () => clearAutoPayInfo();
    }
  }, [clearOnDismount]);
  const breadcrumbText = topLevel.split("/").includes("accounts")
    ? "Accounts"
    : "Properties";
  const validateForm = () => {
    const termsValid = !termsAndConditionsAgreedTo ? setTermsError(true) : true;
    const paymentValid = !selectedAutopaymentMethodId
      ? setPaymentError(true)
      : true;

    if (termsValid && paymentValid) {
      createPaymentSchedule({ obligationSlug });
    }
  };

  const handleToggleSection = paymentType => {
    setAutoPaymentSelectedPaymentMethod(paymentType);
    handlePaymentMethod(paymentType);
    setPaymentError(false);
    if (termsAndConditionsAgreedTo) {
      toggleTermsAndConditions();
    }
  };

  const handlePaymentMethod = id => {
    const isCredit = values(resources[CREDIT_CARD_RESOURCE]).filter(
      card => card.id === id
    );
    if (isCredit.length > 0) {
      setProcessingFeeAlert(true);
      setTerms(CREDIT_CARD_RESOURCE);
    } else {
      setProcessingFeeAlert(false);
      setTerms(ACH_RESOURCE);
      // If we ever need to use a fee message for an ACH autopay fee
      if (showACHFee) {
        setProcessingFee(ACHFee);
        setProcessingFeeAlert(true);
      }
    }
  };

  return (
    <SetUpAutopayWrapper>
      <Box padding={isMobile ? "0" : "0px 4px"}>
        <Breadcrumb
          breadcrumbsList={[
            {
              linkText: breadcrumbText,
              linkDestination: topLevel,
              isActive: false
            },
            {
              linkText: "Set Up Autopay",
              linkDestination: `${topLevel}/set-up-autopay`,
              isActive: true
            }
          ]}
        />
        {!isEmpty(alertBar.alerts) && (
          <Box padding="1rem 0 0 0">
            <AlertBar {...alertBar} {...alertBarActions} />
          </Box>
        )}
        <Box padding="0">
          <Module heading="Set Up Autopay" as="h1" fontSize="1.25rem">
            <FormContainer>
              <Center maxWidth="800px">
                <Stack bottomItem={0} childGap="1.5rem">
                  <Box padding="0">
                    <Text variant="p">
                      By selecting this option you are enabling automatic
                      recurring payments that will process monthly.
                    </Text>
                  </Box>
                  <Box padding="0">
                    <Box padding="0px">
                      <Detail
                        color={constants.colors.STORM_GREY}
                        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                        as="h2"
                      >
                        Select a saved payment method
                      </Detail>
                      <Box padding="0px">
                        {paymentError && (
                          <ErrorContainer id="setup-autopay-error">
                            Please select a saved payment method.
                          </ErrorContainer>
                        )}
                      </Box>
                    </Box>
                    <Box padding="0">
                      <RadioSection
                        sections={paymentMethods.map(method =>
                          method.kind === CREDIT_CARD_KIND
                            ? {
                                id: method.id,
                                titleIcon: <GenericCard />,
                                title: `Card ending in ${method.lastFour}`,
                                rightTitleContent:
                                  !!method.expirationStatus &&
                                  renderCardStatus(
                                    method.expirationStatus,
                                    method.expireDate
                                  ),
                                expirationStatus: method.expirationStatus,
                                expireDate: method.expireDate,
                                disabled:
                                  !canUseCC ||
                                  method.expirationStatus === "EXPIRED"
                              }
                            : {
                                id: method.id,
                                titleIcon: <BankIcon />,
                                title: `${
                                  method.accountType === "CHECKING"
                                    ? "Checking"
                                    : "Savings"
                                } Account ending in ${method.lastFour}`,
                                disabled: !canUseACH
                              }
                        )}
                        toggleOpenSection={id => handleToggleSection(id)}
                        openSection={
                          selectedAutopaymentMethodId
                            ? selectedAutopaymentMethodId
                            : false
                        }
                        staggeredAnimation
                        isMobile={isMobile}
                        ariaDescribedBy={
                          paymentError ? "setup-autopay-error" : ""
                        }
                      />
                    </Box>
                  </Box>
                  {hasPaymentFlag && (!canUseCC || !canUseACH) && (
                    <Alert
                      showQuitLink={false}
                      variant="warn"
                      text={`This account is restricted from making payments using ${
                        !canUseACH ? `checking accounts` : `credit/debit cards`
                      }. For more information, please contact support using the link below.`}
                    />
                  )}
                  {!hasPaymentFlag && (!canUseCC || !canUseACH) && (
                    <Alert
                      showQuitLink={false}
                      variant="warn"
                      text={`Saved ${
                        !canUseCC
                          ? "debit and credit cards"
                          : "checking accounts"
                      } not available for ${subClientName} payments.`}
                      extraStyles={`display: flex;`}
                    />
                  )}
                  {enableAutopayDateSelection && <AutopayDateSelect />}
                  {processingFeeAlert &&
                    !hideFee &&
                    processingFee.length > 0 && (
                      <Alert
                        heading="Processing Fee"
                        showQuitLink={false}
                        variant="info"
                        text={`There is a processing fee of ${
                          processingFee[0].type === "FLAT"
                            ? `${displayCurrency(processingFee[0].value)}`
                            : `${formatPercent(processingFee[0].value * 100)}%`
                        } ${
                          processingFee[0].minimumInCents
                            ? `with a minimum of ${displayCurrency(
                                processingFee[0].minimumInCents
                              )}`
                            : ""
                        } on all card payments.`}
                      />
                    )}
                  <TermsAndConditions
                    version="v2"
                    onCheck={() => {
                      if (termsError) {
                        setTermsError(false);
                      }
                      toggleTermsAndConditions();
                    }}
                    isChecked={termsAndConditionsAgreedTo}
                    hasError={termsError}
                    description="I agree to the"
                    displayInline={false}
                    terms={
                      terms === CREDIT_CARD_RESOURCE
                        ? parse(
                            dompurify.sanitize(
                              metadata?.data?.autopayTermsAndConditions
                                ?.creditCard?.extended ?? ""
                            )
                          )
                        : parse(
                            dompurify.sanitize(
                              metadata?.data?.autopayTermsAndConditions?.ach
                                ?.extended ?? ""
                            )
                          )
                    }
                  />
                </Stack>
              </Center>
            </FormContainer>
          </Module>
        </Box>
        <Box
          padding="1.5rem 0 0 0"
          borderColor={constants.colors.GHOST_GREY}
          borderSize="1px"
          borderWidthOverride="1px 0 0 0"
        >
          {isMobile ? (
            <Stack childGap="1rem" direction="row">
              <Box width="100%" padding="0">
                <ButtonWithAction
                  text="Cancel"
                  action={() => navigate(-1)}
                  variant={"secondary"}
                  dataQa="Cancel"
                  extraStyles={`width: 100%;`}
                />
              </Box>
              <Box width="100%" padding="0">
                <ButtonWithAction
                  text="Confirm"
                  variant="primary"
                  action={validateForm}
                  dataQa="Confirm"
                  extraStyles={`width: 100%;`}
                />
              </Box>
            </Stack>
          ) : (
            <Stack childGap="1rem" direction="row" justify="flex-end">
              <ButtonWithAction
                text="Cancel"
                action={() => navigate(-1)}
                variant={"secondary"}
                dataQa="Cancel"
              />
              <ButtonWithAction
                text="Confirm"
                variant="primary"
                action={validateForm}
                dataQa="Confirm"
              />
            </Stack>
          )}
        </Box>
      </Box>
    </SetUpAutopayWrapper>
  );
};

export default withWindowSize(React.memo(SetUpAutoPay));
