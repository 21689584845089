import React, { useReducer, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { values, isEmpty, includes } from "ramda";
import {
  Banner,
  Box,
  CollapsibleSection,
  FormattedAddress,
  Stack,
  Motion,
  EditableList,
  Placeholder,
  FormattedCreditCard,
  FormattedBankAccount,
  WalletBannerIcon,
  util,
  constants
} from "@thecb/components";
import { format } from "formatted-input";
import {
  PHONE_RESOURCE,
  EMAIL_RESOURCE,
  ADDRESS_RESOURCE,
  CREDIT_CARD_KIND,
  BANK_ACCOUNT_KIND
} from "../../Profile.state";
import { RemovePaymentModal } from "./RemovePaymentModal";
import AlertBar from "/components/alert-bar";
import { configureEndpoint } from "/util/router-utils";
import {
  getCreditCardsForDisplay,
  getBankAccountsForDisplay
} from "../../Profile.selectors";
import { isInCustomerManagement } from "../../../../../../util/router-utils";

const ProfileWalletSettings = ({
  actions,
  resources,
  resourcesActions,
  autoPayData,
  alertBars,
  profileRouting,
  customerManagement
}) => {
  const { metadata } = useContext(ThemeContext);
  const clientName = metadata?.data?.clientDisplayName ?? "";
  const { profileWalletSettingsAlertBar: alertBar } = alertBars;
  const {
    alertBars: { profileWalletSettingsAlertBar: alertBarActions }
  } = actions;
  const creditCards = getCreditCardsForDisplay(resources);
  const bankAccounts = getBankAccountsForDisplay(resources);
  useEffect(() => {
    if (resources.creditCards) {
      resourcesActions.checkPaymentExpiration();
    }
  }, [resources.creditCards]);
  const paymentInstruments = [...creditCards, ...bankAccounts];
  const autoPayMethods =
    Object.entries(autoPayData)
      ?.filter(
        ([key]) =>
          key !== "selectedAutoPaymentMethodId" &&
          key !== "termsAndConditionsAgreedTo"
      )
      .map(([, autoPayRecord]) => autoPayRecord?.paymentInstrument?.id) ?? [];
  const redirectLink = includes("accounts", profileRouting)
    ? configureEndpoint(customerManagement, "/profile/accounts")
    : configureEndpoint(customerManagement, "/profile/properties");
  const modalProps = {
    autoPayMethods,
    modalDestination: redirectLink,
    removePaymentMethod: resourcesActions.deactivatePaymentInstrument
  };

  const SET_BILLING_INFO = "SET_BILLING_INFO";
  const SET_PAYMENT = "SET_PAYMENT";

  const reducer = (walletSettingsPage, action) => {
    switch (action) {
      case SET_BILLING_INFO:
        return {
          ...walletSettingsPage,
          billingInfoSection: !walletSettingsPage.billingInfoSection
        };
      case SET_PAYMENT:
        return {
          ...walletSettingsPage,
          paymentSection: !walletSettingsPage.paymentSection
        };
      default:
        return walletSettingsPage;
    }
  };

  const [walletSettingsPage, dispatch] = useReducer(reducer, {
    billingInfoSection: true,
    paymentSection: true
  });

  const content = {
    open: {
      x: "0",
      opacity: 1
    },
    closed: {
      x: "-7.5%",
      opacity: 0
    }
  };
  const formatPhone = format(util.formats.phoneFormat);

  return (
    <Stack childGap="24px">
      {!isEmpty(alertBar.alerts) && (
        <AlertBar {...alertBar} {...alertBarActions} />
      )}
      <Banner
        heading={`Welcome to ${clientName} Wallet`}
        subHeading={"Manage your saved payment methods for checkout"}
        image={WalletBannerIcon}
      />
      <CollapsibleSection
        title="Payment Methods"
        toggleSection={() => dispatch(SET_PAYMENT)}
        isOpen={walletSettingsPage.paymentSection}
        key="payment-methods-section"
      >
        <Motion
          padding="0"
          variants={content}
          transition={{ duration: 0.3 }}
          positionTransition
          extraStyles={`transform-origin: 100% 0;`}
        >
          {isEmpty(paymentInstruments) ? (
            <Box
              background={constants.colors.WHITE}
              extraStyles={`box-shadow: 0px 2px 14px 0px ${constants.colors.ATHENS_GREY},
      0px 3px 8px 0px ${constants.colors.GHOST_GREY}; margin-bottom: 16px;`}
              key="placeholder-box"
            >
              <Placeholder
                isLink
                variant="large"
                destination="add-payment"
                text="Add a Payment Method"
                largeIcon="payments"
                key="placeholder"
                dataQa="add payment"
                isDisabled={isInCustomerManagement}
              />
            </Box>
          ) : (
            <Box padding="0 5px">
              <EditableList
                addItem={resourcesActions.onAddPayment(customerManagement)}
                canRemove={!customerManagement}
                useModal
                modal={RemovePaymentModal}
                modalProps={modalProps}
                canEdit={false}
                itemName="Payment Method"
                items={paymentInstruments}
                autoPayMethods={autoPayMethods}
                disablePlaceholder={isInCustomerManagement}
                renderItem={i => (
                  <Stack>
                    {i.kind === BANK_ACCOUNT_KIND && (
                      <FormattedBankAccount
                        lastFour={i.lastFour}
                        accountType={i.accountType}
                        autoPay={autoPayMethods.some(
                          methodID => methodID === i.id
                        )}
                      />
                    )}
                    {i.kind === CREDIT_CARD_KIND && (
                      <FormattedCreditCard
                        lastFour={i.lastFour}
                        autoPay={autoPayMethods.some(
                          methodID => methodID === i.id
                        )}
                        expireDate={i.expireDate}
                        expirationStatus={i.expirationStatus}
                      />
                    )}
                  </Stack>
                )}
                qaPrefix="Payment Method"
              />
            </Box>
          )}
        </Motion>
      </CollapsibleSection>
      <CollapsibleSection
        title="Billing Information"
        toggleSection={() => dispatch(SET_BILLING_INFO)}
        isOpen={walletSettingsPage.billingInfoSection}
        key="contact-section"
      >
        <Motion
          padding="0"
          variants={content}
          transition={{ duration: 0.3 }}
          positionTransition
          extraStyles={`transform-origin: 100% 0;`}
        >
          <Box padding="0 5px">
            <EditableList
              title="Mailing Address"
              addItem={resourcesActions.onAddAddress(customerManagement)}
              canRemove={false}
              editItem={resourcesActions.onEditAddress(customerManagement)}
              itemName="Mailing Address"
              canEdit={!customerManagement}
              renderItem={item => <FormattedAddress {...item} />}
              listItemSize={"big"}
              maxItems={1}
              items={values(resources[ADDRESS_RESOURCE]).map(a => ({
                id: a.id,
                street1: a.street1,
                street2: a.street2,
                city: a.city,
                country: a.country,
                stateProvince: a.stateProvince,
                zip: a.zip,
                isPrimary: a.isPrimary
              }))}
              qaPrefix="Address"
              disablePlaceholder={isInCustomerManagement}
            />
          </Box>
        </Motion>
        <Motion
          padding="0"
          variants={content}
          transition={{ duration: 0.3 }}
          positionTransition
          extraStyles={`transform-origin: 100% 0;`}
        >
          <Box padding="0 5px">
            <EditableList
              canRemove={false}
              canEdit={!customerManagement}
              title="Phone Number"
              addItem={resourcesActions.onAddPhone(customerManagement)}
              editItem={resourcesActions.onEditPhone(customerManagement)}
              itemName="Phone Number"
              renderItem={phone => formatPhone(phone.value)}
              maxItems={1}
              items={values(resources[PHONE_RESOURCE])}
              qaPrefix="Phone Number"
              disablePlaceholder={isInCustomerManagement}
            />
          </Box>
        </Motion>
        <Motion
          padding="0"
          variants={content}
          transition={{ duration: 0.3 }}
          positionTransition
          extraStyles={`transform-origin: 100% 0;`}
        >
          <Box padding="0 5px">
            <EditableList
              canRemove={false}
              title="Email for Receipts"
              canEdit={!customerManagement}
              addItem={resourcesActions.onAddEmail(customerManagement)}
              removeItem={resourcesActions.onRemoveEmail}
              editItem={resourcesActions.onEditEmail(customerManagement)}
              itemName="Email"
              renderItem={email => email.value}
              maxItems={1}
              items={values(resources[EMAIL_RESOURCE])}
              qaPrefix="Email"
              disablePlaceholder={isInCustomerManagement}
            />
          </Box>
        </Motion>
      </CollapsibleSection>
    </Stack>
  );
};

export default connect(null, { push })(React.memo(ProfileWalletSettings));
