import React, { forwardRef } from "react";
import Checkbox from "../../../atoms/checkbox";
import { GHOST_GREY } from "../../../../constants/colors";
import CheckboxCheckmarkIcon from "../../../atoms/icons/CheckboxCheckmarkIcon";

const FilterableListItem = forwardRef(
  (
    {
      index,
      option,
      checked,
      selectOption,
      disabled,
      tabIndex,
      name,
      showDivider,
      extraStyles,
      themeValues
    },
    ref
  ) => {
    const dividerStyles = `
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      display: block;
      height: 1px;
      background-color: ${GHOST_GREY};
      bottom: -0.5rem;
      left: 0;
    }`;
    return (
      <Checkbox
        key={index}
        ref={ref}
        title={option.name}
        name={option.name}
        role="option"
        aria-selected={checked}
        tabIndex={tabIndex}
        dataQa={`${name}-option-${index}`}
        checked={checked}
        onChange={() => selectOption(option)}
        textExtraStyles={`font-size: 0.875rem; margin: 0;`}
        disabled={disabled}
        extraStyles={`
          padding: 0.375rem 0.625rem; 
          margin: 0;
          :hover,
          :active,
          :focus {
            background-color: ${themeValues.primaryHoverColor};
          }
          ${showDivider && dividerStyles}
          ${extraStyles}
        `}
        checkboxMargin={showDivider ? "0 0 1rem" : "0"}
        hasIconOverride={true}
        icon={CheckboxCheckmarkIcon}
        checkboxExtraStyles={`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 20px;
          width: 20px;
          ${
            checked && !disabled
              ? `background: ` + themeValues.secondaryColor + `;`
              : ""
          }
       `}
      />
    );
  }
);

export default FilterableListItem;
