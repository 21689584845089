import React from "react";
import {
  Box,
  Grid,
  Cluster,
  Heading,
  ImageBox,
  Paragraph
} from "@thecb/components";

const HighlightBlock = ({
  themeValues,
  highlightComponents = []
  //variant = "placeholder"
}) => {
  const componentBlocks = highlightComponents.map(component => (
    <Box padding="16px" key={component.title}>
      <Cluster justify="flex-start" align="flex-start">
        {component.image?.url && (
          <Box padding="16px">
            <ImageBox
              borderRadius="50%"
              url={component.image}
              minHeight="124px"
              minWidth="124px"
              objectFit="cover"
            />
          </Box>
        )}
        <Box padding="0" maxWidth="300px">
          <Heading variant="h4" color={themeValues.lightAccentText}>
            {component.title}
          </Heading>
          <Paragraph variant="pS" color={themeValues.lightText}>
            {component.description}
          </Paragraph>
        </Box>
      </Cluster>
    </Box>
  ));
  return (
    <Box
      minWidth="100%"
      minHeight="500px"
      background={themeValues.pageBackground}
    >
      <Grid columnGap="1px" minColWidth={"400px"}>
        {componentBlocks}
      </Grid>
    </Box>
  );
};

export default HighlightBlock;
