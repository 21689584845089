import React, { Fragment } from "react";
import { EditableTableContainer, TableWrapper } from "./EditableTable.styled";
import { Box } from "../../atoms/layouts";
import Paragraph from "../../atoms/paragraph";
import { GHOST_GREY } from "../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { safeChildren } from "../../../util/general";

const EditableTable = ({ title, renderItem, items, isMobile }) => {
  const titleChild = title && (
    <Box
      padding={"0 0 0.5rem 0.5rem"}
      borderSize="1px"
      borderColor={GHOST_GREY}
      borderWidthOverride="0 0 1px 0"
    >
      <Paragraph variant="pL" weight={FONT_WEIGHT_SEMIBOLD}>
        {title}
      </Paragraph>
    </Box>
  );
  return (
    <EditableTableContainer isMobile={isMobile}>
      {safeChildren(titleChild, <Fragment />)}
      <TableWrapper>{renderItem(items)}</TableWrapper>
    </EditableTableContainer>
  );
};

export default EditableTable;
