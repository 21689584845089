/* 

A utility function that can generate box-shadow values for components
Takes a string representing an rgb color value and returns an object
with values for standard, inset, and overlay shadows.

The objects for standard and inset shadows contain versions approiate
for base, hover, and active interaction states.

*/

/* 
  Function to convert string representing rgb color to rgba value with provided opacity
  ("rgb(41, 42, 51)", "0.1") => "rgba(41, 42, 51, 0.1)"

*/
const rgbToRgba = (rgbValue = "", opacity = "") => {
  if (
    typeof rgbValue !== "string" ||
    typeof opacity !== "string" ||
    rgbValue.charAt(0) === "#"
  ) {
    return "";
  }
  return `${rgbValue.slice(0, 3)}a${rgbValue.slice(
    3,
    -1
  )}, ${opacity}${rgbValue.slice(-1)}`;
};

export const generateShadows = (baseColorRGB = "rgb(41, 42, 51)") => {
  const colorTen = rgbToRgba(baseColorRGB, "0.1") || "rgba(41, 42, 51, 0.1)";
  const colorTwenty = rgbToRgba(baseColorRGB, "0.2") || "rgba(41, 42, 51, 0.2)";
  const colorTwentyFive =
    rgbToRgba(baseColorRGB, "0.25") || "rgba(41, 42, 51, 0.25)";
  const colorThirty = rgbToRgba(baseColorRGB, "0.3") || "rgba(41, 42, 51, 0.3)";
  const blackTwentyFive = "rgba(0, 0, 0, 0.25)";

  const standard = {
    base: `0px 1px 2px 0px ${colorTen}, 0px 2px 6px 0px ${colorTwenty}, inset 0px 1px 0px 0px ${colorTen}`,
    hover: `0px 1px 2px 0px ${colorTwenty}, 0px 4px 8px 0px ${blackTwentyFive}, 0px 6px 12px 0px ${colorTen}`,
    active: `0px 2px 8px 0px ${colorTwenty}, 0px 4px 8px 0px ${colorThirty}, 0px 6px 12px 0px ${colorTwentyFive}`
  };

  const inset = {
    base: `0px 1px 2px 0px ${colorTen}, 0px 2px 4px 0px ${colorTwenty}, inset 0px 1px 0px 0px ${colorTen}`,
    hover: `0px 1px 2px 0px ${colorTen}, 0px 2px 4px 0px ${colorTwentyFive}, 0px 4px 8px 0px ${colorTen}`,
    active: `0px 1px 2px 2px ${colorTwenty}, 0px 3px 6px 0px ${colorThirty}, 0px 4px 8px 0px ${colorTwenty}`
  };

  const overlay = {
    base: `0px 7px 32px 0px ${colorTwenty}, 0px 1px 4px 0px ${colorTwenty}, 0px 1px 8px -1px ${colorThirty}`
  };

  return {
    standard,
    inset,
    overlay
  };
};
