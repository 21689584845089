import styled from "styled-components";

export const HeadingText = styled.h1`
  --font-size: ${({ fontSize }) => fontSize};
  font-size: var(--font-size);
  line-height: calc(1.5 * var(--font-size));
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};
  font-family: ${({ fontFamily }) => fontFamily};

  ${({ extraStyles }) => extraStyles};
`;
