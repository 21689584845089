import {
  SAPPHIRE_BLUE,
  PEACOCK_BLUE,
  MATISSE_BLUE
} from "../../../constants/colors";

const fontFamily = {
  primary: "Public Sans",
  secondary: "Open Sans"
};

const hoverColor = SAPPHIRE_BLUE;
const activeColor = PEACOCK_BLUE;
const externalLinkColor = MATISSE_BLUE;

export const fallbackValues = {
  fontFamily,
  hoverColor,
  activeColor,
  externalLinkColor
};
