import React, { useContext } from "react";
import {
  Box,
  Stack,
  Heading,
  withWindowSize,
  constants
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import RichTextField from "../rich-text-field";

const ArticleBlock = ({
  themeValues,
  description = "",
  headline = "",
  blockPadding = "40px 0"
}) => {
  const { html } = description ?? "";
  const { isMobile } = useContext(ThemeContext);

  const rem1 = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const paddingSize = isMobile ? "1rem" : "0";
  const paddingCSS = `padding-left: ${paddingSize}; padding-right: ${paddingSize};`;
  const horizontalPad = isMobile ? 2 * rem1 : 0;

  return (
    <Box maxWidth="100%" padding={blockPadding} extraStyles={`${paddingCSS}`}>
      <Stack childGap="1rem">
        <Box padding="0">
          <Stack childGap="1rem">
            <Heading
              variant={"h4"}
              color={constants.colors.CHARADE_GREY}
              key="heading"
            >
              {headline}
            </Heading>
          </Stack>
        </Box>
        <Box padding="0">
          <Box
            padding="0"
            background="transparent"
            borderRadius="4px"
            extraStyles={`
              p, ul, ol {
                font-size: 1.125rem;
                line-height: 1.5;
                color: ${constants.colors.CHARADE_GREY};
                margin-bottom: 2.25rem;
              }
              th p,
              td p,
              td ul, 
              td ol {
                font-size: unset;
                line-height: unset;
                color: unset;
                margin-bottom: unset;
              }
              a {
                color: ${themeValues.linkColor}
              }
            `}
          >
            <RichTextField
              html={html}
              horizontalPad={horizontalPad}
              themeValues={themeValues}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default withWindowSize(ArticleBlock);
