import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Modal,
  ButtonWithAction,
  ToggleSwitch,
  Box,
  Text,
  util
} from "@thecb/components";

export const AutopayLink = (
  autoPayActive,
  toggleModal,
  isMobile,
  disabled = false
) =>
  autoPayActive ? (
    <Box padding="0px">
      <ToggleSwitch
        isOn={autoPayActive}
        label={"On"}
        onToggle={() => {
          disabled ? util.general.noop : toggleModal(true);
        }}
        isMobile={isMobile}
        dataQa="Autopay Toggle"
        disabled={disabled}
        name="Autopay"
        labelAs="h4"
      />
    </Box>
  ) : (
    <ButtonWithAction
      text="Set Up Autopay"
      variant="ghost"
      action={() => {
        disabled ? util.general.noop : toggleModal(true);
      }}
      dataQa="Set Up Autopay"
      textExtraStyles={
        isMobile ? `font-size: 1.094rem; line-height: 1.641rem;` : ``
      }
      width="100%"
      extraStyles={`margin: 0;`}
      disabled={disabled}
    />
  );

export const AccountDetailsModal = ({
  autoPayActive,
  autoPaySchedule,
  toggleModal,
  modalOpen,
  modalDestination,
  deactivatePaymentSchedule,
  isMobile,
  disabled = false
}) => {
  let navigate = useNavigate();
  const modalExtraProps = {
    modalHeaderText: autoPayActive ? "Stop autopay" : "Set up autopay",
    modalBodyText: autoPayActive
      ? `Are you sure you want to stop autopay?`
      : "To set up autopay you must save a payment method and address. Do you want to save these now?",
    continueButtonText: autoPayActive ? "Stop Autopay" : "Add",
    useDangerButton: autoPayActive,
    continueAction: disabled
      ? util.general.noop
      : autoPayActive
      ? () => {
          deactivatePaymentSchedule(autoPaySchedule);
          toggleModal(false);
        }
      : () => {
          navigate(modalDestination);
        }
  };

  return (
    <Modal
      showModal={disabled ? util.general.noop : () => toggleModal(true)}
      hideModal={disabled ? util.general.noop : () => toggleModal(false)}
      modalOpen={modalOpen}
      {...modalExtraProps}
    >
      {AutopayLink(autoPayActive, toggleModal, isMobile, disabled)}
    </Modal>
  );
};

export const RemoveAccountModal = ({
  agency,
  obligations,
  deleteObligationAssoc,
  accountType,
  assocID,
  isMobile,
  detailedObligation,
  disabled = false
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const lastItem = [...obligations].pop();
  const accounts = obligations.reduce((acc, curr) => {
    const account = curr.details.description;
    const formattedAccount =
      curr !== lastItem ? `${account} and ` : `${account}`;
    return formattedAccount === agency ? agency : acc + formattedAccount;
  }, `${agency} - `);
  const identifier =
    accountType === "Account" && obligations.length > 1
      ? "accounts"
      : accountType === "Property" && obligations.length > 1
      ? "properties"
      : accountType.toLowerCase();
  const obligationSlug = detailedObligation?.config?.obligationSlug;

  return (
    <Modal
      showModal={() => setModalIsOpen(true)}
      hideModal={() => setModalIsOpen(false)}
      modalOpen={modalIsOpen}
      modalHeaderText={`Remove ${accountType}`}
      modalBodyText={`Are you sure you want to remove the ${identifier} ${accounts}? Any autopay scheduled against ${
        obligations.length > 1 ? "them" : "it"
      } will be deactivated.`}
      continueButtonText="Remove"
      continueAction={
        disabled
          ? util.general.noop
          : () => {
              deleteObligationAssoc({ id: assocID, obligationSlug });
              setModalIsOpen(false);
            }
      }
      useDangerButton
    >
      <ButtonWithAction
        text="Remove This Account"
        action={disabled ? util.general.noop : () => setModalIsOpen(true)}
        dataQa="Remove This Account"
        textExtraStyles={!isMobile && `font-weight: 600;`}
        variant="smallGhost"
        disabled={disabled}
      />
    </Modal>
  );
};

export const PaperlessBillingInfoModal = ({
  agencyName = "",
  modalContent = "",
  modalIsOpen = false,
  setModalIsOpen
}) => {
  const defaultModalContent = (
    <>
      <Text extraStyles="color: #0B0C15; word-wrap: break-word;">
        Paperless billing reduces waste by providing you with an electronic copy
        of your bill instead of a paper bill in the mail. This option is free
        and cancelable at any time.
      </Text>
      <ul
        style={{
          color: "#0B0C15",
          wordWrap: "break-word",
          lineHeight: "1.5rem"
        }}
      >
        <li>No more paper waste</li>
        <li>Pay online quickly</li>
      </ul>
      <Text extraStyles="color: #0B0C15; word-wrap: break-word;">
        Note: Some notifications may still be required to be sent via printed
        mail.
      </Text>
    </>
  );

  return (
    <>
      {modalIsOpen && (
        <Modal
          blurUnderlay={true}
          showModal={() => setModalIsOpen(true)}
          hideModal={() => setModalIsOpen(false)}
          defaultWrapper={false}
          modalBodyText={
            modalContent?.length ? modalContent : defaultModalContent
          }
          modalHeaderText={`Paperless Billing${
            agencyName ? ` - ${agencyName}` : ""
          }`}
          modalOpen={modalIsOpen}
          onlyCloseButton={true}
          showCloseIconButton={true}
          closeButtonVariant="secondary"
          buttonExtraStyles="min-width: 100px;"
          useDangerButton={false}
          version="v2"
        />
      )}
    </>
  );
};

export const PaperlessBillingConfirmModal = ({
  agencyName = "",
  isLoading = false,
  modalContent = "",
  modalIsOpen = false,
  paperlessBillingEnabled,
  setIsLoading,
  setModalIsOpen,
  setPaperlessBillingEnabled
}) => {
  const defaultModalConfirmContent = (
    <>
      <Text extraStyles="word-wrap: break-word;">
        You will receive your bills digitally instead of receiving a paper bill.
      </Text>
      <br />
      <br />
      <Text extraStyles="font-style: italic; word-wrap: break-word;">
        Note: Some notifications may still be required to be sent via printed
        mail and your next bill may still be mailed if enrollment occurs too
        close to the send date.
      </Text>
    </>
  );

  const defaultModalCancelContent = (
    <Text extraStyles="word-wrap: break-word;">
      You will resume receiving printed bills for this account.
    </Text>
  );

  const enablePaperlessBillingCallback = () => {
    // Temporarily mocking the loading state.
    // The enable paperless billing redux action will go here.
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setModalIsOpen(!modalIsOpen);
      setPaperlessBillingEnabled(!paperlessBillingEnabled);
    }, 1000);
  };

  return (
    <>
      {modalIsOpen && (
        <Modal
          blurUnderlay={true}
          buttonExtraStyles={`
            min-width: 8.25rem; 
            min-height: 2.875rem;
            &.modal-continue-button {
              min-width: 9.313rem;
            }`}
          cancelAction={() => setModalIsOpen(false)}
          cancelButtonText="Close"
          closeButtonVariant="secondary"
          continueAction={() => enablePaperlessBillingCallback()}
          continueButtonText={
            paperlessBillingEnabled ? "Cancel Paperless" : "Go Paperless"
          }
          defaultWrapper={false}
          showModal={() => setModalIsOpen(true)}
          hideModal={() => setModalIsOpen(false)}
          isLoading={isLoading}
          modalBodyText={
            modalContent?.length
              ? modalContent
              : paperlessBillingEnabled
              ? defaultModalCancelContent
              : defaultModalConfirmContent
          }
          modalHeaderText={`Paperless Billing${
            agencyName ? ` - ${agencyName}` : ""
          }`}
          modalOpen={modalIsOpen}
          showCloseIconButton={true}
          useDangerButton={paperlessBillingEnabled}
          version="v2"
        />
      )}
    </>
  );
};
