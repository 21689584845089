import React from "react";
import { rgba } from "polished";
import { fallbackValues } from "./BoxWithShadow.theme";
import Box from "../layouts/Box";
import { themeComponent } from "../../../util/themeUtils";
import { BLACK } from "../../../constants/colors";

const BoxWithShadow = ({ variant, themeValues, children, ...props }) => {
  const shadowRegistry = {
    baseStandard: `0px 3px 7px 2px ${rgba(BLACK, 0.1)}, 0px 1px 2px 1px ${rgba(
      BLACK,
      0.1
    )};`,
    baseHover: `0px 1px 7px 0px ${rgba(BLACK, 0.3)}, 0px 1px 4px 0px ${rgba(
      BLACK,
      0.2
    )}, 0px 7px 12px 0px ${rgba(BLACK, 0.2)};`,
    baseClick: `0px 4px 7px 0px ${rgba(BLACK, 0.3)}, 0px 1px 4px 0px ${rgba(
      BLACK,
      0.2
    )}, 0px 7px 12px 0px ${rgba(BLACK, 0.2)};`,
    insetStandard: `0px 1px 4px 2px ${rgba(BLACK, 0.1)}, 0px 2px 1px 0px ${rgba(
      BLACK,
      0.15
    )};`,
    insetHover: `0px 1px 4px 0px ${rgba(BLACK, 0.3)}, 0px 1px 2px 2px ${rgba(
      BLACK,
      0.2
    )}, 0px 4px 8px 0px ${rgba(BLACK, 0.2)};`,
    insetClick: `0px 4px 4px 0px ${rgba(BLACK, 0.3)}, 0px 1px 2px 2px ${rgba(
      BLACK,
      0.2
    )}, 0px 4px 8px 0px ${rgba(BLACK, 0.2)};`,
    linkShadowFocus: `0px 0px 5px 2px ${rgba(
      themeValues.linkColor,
      0.3
    )}, 0px 1px 2px 0px ${rgba(
      themeValues.linkColor,
      0.3
    )}, 0px 4px 8px 0px ${rgba(themeValues.linkColor, 0.2)};`,
    overlayShadow: `0px 1px 8px -1px ${rgba(
      BLACK,
      0.3
    )}, 0px 1px 4px 0px ${rgba(BLACK, 0.2)}, 0px 7px 32px 0px ${rgba(
      BLACK,
      0.2
    )};`
  };
  return (
    <Box boxShadow={shadowRegistry[variant]} {...props}>
      {children}
    </Box>
  );
};
export default themeComponent(BoxWithShadow, "BoxWithShadow", fallbackValues);
