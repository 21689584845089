import React, { Fragment, useContext } from "react";
import { ThemeContext } from "styled-components";
import { useNavigate } from "react-router-dom";
import { format } from "formatted-input";
import {
  Alert,
  PaymentDetails,
  FormattedAddress,
  DisplayBox,
  CenterStack,
  Box,
  Center,
  Stack,
  Heading,
  Paragraph,
  Text,
  PaymentButtonBar,
  DefaultPageTemplate,
  CheckmarkIcon,
  BankIcon,
  WalletIcon,
  Cluster,
  Module,
  ButtonWithAction,
  constants,
  GenericCardLarge,
  Title,
  util,
  MultiCartIcon
} from "@thecb/components";
import { NEW_ACH, NEW_CC, SAVED_ACH, SAVED_CC } from "../../Payment.state";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../../constants/style_constants";
import { showTestSiteAlert } from "../../../../../../util/domain";
import { formatDateObject } from "../../../../../../util/dateUtil";

const PaymentSuccessContent = ({
  payerName,
  newAddressSelected,
  subtotal,
  total,
  savedCreditCard,
  creditCardForm,
  achForm,
  savedACH,
  paymentVerificationNumber,
  paymentServiceContact: {
    agencyEmail = null,
    agencyPhone = null,
    agencyPhoneNumberLabel = "customer service"
  },
  selectedPaymentMethodType,
  contactEmail,
  processedLineItems,
  fees,
  paymentDate,
  billingAddress,
  isLoggedIn,
  authCode,
  serviceFeePaymentAuthCode,
  duplicate,
  returnURL,
  isMobile,
  walletEnabled,
  cartEnabled,
  cartsCount,
  openCartSlider
}) => {
  const { metadata, subClientMetadata } = useContext(ThemeContext);
  const clientDisplayName = metadata?.data?.clientDisplayName ?? "";
  const agencyContact =
    agencyEmail && agencyPhone
      ? ` ${agencyEmail} or ${agencyPhone} `
      : ` ${agencyEmail ? agencyEmail : agencyPhone} `;
  const defaultConfirmationMessage = `Please contact ${agencyPhoneNumberLabel} at ${agencyContact}
         if you have questions about your payment.`;
  const customConfirmationMessage =
    subClientMetadata?.data?.confirmationMessage;
  const confirmationMessage =
    customConfirmationMessage ?? defaultConfirmationMessage;
  const formatZip = format(util.formats.zipFormat);
  let navigate = useNavigate();
  const headerText = duplicate =>
    duplicate ? (
      <Box padding="1.5rem 0 0 0">
        <Paragraph
          variant="pL"
          weight="400"
          extraStyles="text-align: center;"
          dataQa="subheader-1"
        >
          If you didn&#39;t receive a receipt, please contact
          {agencyPhoneNumberLabel} at {agencyContact}.
        </Paragraph>
      </Box>
    ) : (
      <Fragment>
        <Box padding="0.125rem 0 0 0" textAlign="center">
          <Title variant="large" as="p" weight="600" dataQa="header">
            Your transaction number is {paymentVerificationNumber}.
          </Title>
        </Box>
        <Box padding="1.5rem 0 0 0">
          <Paragraph
            variant="pL"
            weight="400"
            extraStyles="text-align: center;"
            dataQa="subheader-1"
          >
            We&#39;ve sent a confirmation email to {`${contactEmail}`}. If you
            do not receive a receipt, check your spam folder.
          </Paragraph>
          {
            <Paragraph
              variant="pL"
              weight="400"
              extraStyles="text-align: center;"
              margin="0.5rem 0 0 0"
              dataQa="subheader-2"
            >
              {confirmationMessage}
            </Paragraph>
          }
        </Box>
      </Fragment>
    );

  const showRemainingCartsButton = cartEnabled && cartsCount > 0;
  const remainingCartsButton = ({ extraStyles }) => (
    <ButtonWithAction
      variant="primary"
      action={openCartSlider}
      extraStyles={extraStyles}
      aria-label={`${cartsCount} more cart${
        cartsCount > 1 ? "s" : ""
      } ready. Pay now?`}
      contentOverride
    >
      <Box
        as="span"
        padding="0"
        extraStyles={`
            display: flex;
            flex-direction: row;
            justify-content: center;
          `}
      >
        <Box
          as="span"
          padding="0"
          extraStyles={`
              display: block;
              height: ${isMobile ? "13px" : "18px"};
              margin-top: 2px;
            `}
        >
          <MultiCartIcon
            iconFill={constants.colors.WHITE}
            iconWidth={isMobile ? "14" : "18"}
            iconHeight={isMobile ? "13" : "18"}
            role="img"
            aria-hidden="true"
          />
          <Box
            padding="0"
            width="7px"
            borderRadius="50%"
            background={constants.colors.RAZZMATAZZ_RED}
            extraStyles={`
                position: absolute;
                height: 7px;
                top: ${isMobile ? "-1.5px" : "0"};
                right: ${isMobile ? "-2px" : "0"};
                box-sizing: content-box;
              `}
          />
        </Box>
        <Text
          variant="pS"
          weight={constants.FONT_WEIGHT_SEMIBOLD}
          color={constants.colors.WHITE}
          extraStyles={`padding: 0 ${isMobile ? "7px" : "9px"};`}
        >
          {`${cartsCount} more cart${
            cartsCount > 1 ? "s" : ""
          } ready. Pay now?`}
        </Text>
      </Box>
    </ButtonWithAction>
  );

  const content = (
    <Fragment>
      <Box
        background={constants.colors.WHITE}
        padding="4rem 0"
        minWidth="100vw"
      >
        <Center maxWidth="600px" style={{ marginTop: "30px" }}>
          <Box padding="0 1.5rem">
            <Stack childGap="0">
              <Center>
                <CheckmarkIcon />
              </Center>
              <Box
                padding="1.8125rem 0 0 0"
                maxWidth="77.5rem"
                textAlign="center"
              >
                <Heading
                  type="h1"
                  weight="700"
                  color={constants.colors.SEA_GREEN}
                >
                  Thanks for your payment!
                </Heading>
              </Box>
              {headerText(duplicate)}
              {showRemainingCartsButton && (
                <Center>
                  {remainingCartsButton({
                    extraStyles: `
                      max-width: ${isMobile ? "263px" : "293px"};
                      border-radius: 4px;
                      margin-top: 30px;
                    `
                  })}
                </Center>
              )}
            </Stack>
          </Box>
        </Center>
      </Box>
      <Box
        background={constants.colors.ATHENS_GREY}
        padding="2.5rem 1.5rem 1rem 1.5rem"
        minWidth="100vw"
      >
        <Center maxWidth="33.75rem">
          {!isLoggedIn && walletEnabled && (
            <Module padding="0" spacing="0" spacingBottom="1.5rem">
              <Box
                background={constants.colors.INFO_BLUE}
                padding={isMobile ? "1rem" : "1.5rem 1rem"}
                minWidth="100%"
              >
                <Stack childGap={isMobile ? "0" : "1.5rem"}>
                  <Cluster
                    justify="flex-start"
                    align={isMobile ? "flex-start" : "center"}
                    nowrap
                    childGap="1rem"
                  >
                    <Box padding="0" extraStyles={!isMobile && `flex: 2;`}>
                      <Stack childGap="0.5rem">
                        <Box padding="0">
                          <Heading
                            variant="h6"
                            as="h2"
                            weight={FONT_WEIGHT_SEMIBOLD}
                          >
                            Register for {clientDisplayName} Wallet
                          </Heading>
                        </Box>
                        {!isMobile && (
                          <Box padding="0">
                            <Paragraph>
                              Next time, use wallet to pay quicker with saved
                              payment methods and personal information
                            </Paragraph>
                          </Box>
                        )}
                      </Stack>
                    </Box>
                    <Box
                      padding="0"
                      extraStyles={`> svg { ${
                        isMobile
                          ? `width: 133px; height: 87px;`
                          : `width: 190px; height: 114px;`
                      } ${!isMobile && `flex: 1;`}`}
                    >
                      <WalletIcon />
                    </Box>
                  </Cluster>
                  {isMobile && (
                    <Box padding="0">
                      <Paragraph>
                        Next time, use wallet to pay quicker with saved payment
                        methods and personal information
                      </Paragraph>
                    </Box>
                  )}
                  <Box
                    padding={isMobile ? "1rem 0 0" : "0"}
                    extraStyles={`width: 100%;`}
                  >
                    <ButtonWithAction
                      text="Register Now"
                      variant="primary"
                      action={() => navigate("/registration")}
                      extraStyles={`width: 100%;`}
                      dataQa="Register Now Widget"
                    />
                  </Box>
                </Stack>
              </Box>
            </Module>
          )}
          {showTestSiteAlert(window.location.host) && (
            <Alert
              heading="Test site - You were not charged"
              variant="error"
              extraStyles="min-height: auto;margin-bottom: 24px;"
              innerContentPadding="12px"
              padding="4px"
              showQuitLink={false}
            />
          )}
          <Title variant="small" as="h2" weight="600">
            Payment details
          </Title>
          <DisplayBox>
            <PaymentDetails
              subtotal={subtotal}
              lineItems={processedLineItems}
              fees={fees}
              total={total}
              collapsibleOnMobile={false}
              initiallyOpen
              hideTitle
            />
          </DisplayBox>
          <Title variant="small" weight="600" as="h2">
            Payment method
          </Title>
          <DisplayBox>
            <Cluster justify="flex-start" align="center">
              <Box padding="0" extraStyles={"svg { margin-right: 1rem; }"}>
                {!(
                  selectedPaymentMethodType === NEW_ACH ||
                  selectedPaymentMethodType === SAVED_ACH
                ) ? (
                  <GenericCardLarge />
                ) : (
                  <BankIcon />
                )}
              </Box>
              <Box padding="0" dataQa="payment-method">
                <Text>
                  {((
                    selectedPaymentMethodType,
                    selectedSavedCreditCard,
                    selectedSavedACH,
                    creditCardForm,
                    achForm
                  ) => {
                    switch (selectedPaymentMethodType) {
                      case NEW_ACH:
                        return `Account number: *****${achForm.fields.accountNumber.rawValue.slice(
                          -4
                        )}`;
                      case NEW_CC:
                        return `Card ending in ${creditCardForm.fields.creditCardNumber.rawValue.slice(
                          -4
                        )}`;
                      case SAVED_CC:
                        return `Card ending in ${selectedSavedCreditCard?.lastFour ??
                          "****"}`;
                      case SAVED_ACH:
                        return `Account number: *****${selectedSavedACH?.lastFour ??
                          "****"}`;
                      default:
                        return "";
                    }
                  })(
                    selectedPaymentMethodType,
                    savedCreditCard,
                    savedACH,
                    creditCardForm,
                    achForm
                  )}
                </Text>
                {authCode && (
                  <Box padding="0" dataQa="auth-code">
                    {serviceFeePaymentAuthCode ? (
                      <Text variant="pS" color={constants.colors.STORM_GREY}>
                        {`Auth codes: ${authCode}, ${serviceFeePaymentAuthCode}`}
                      </Text>
                    ) : (
                      <Text variant="pS" color={constants.colors.STORM_GREY}>
                        {`Auth code: ${authCode}`}
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
            </Cluster>
          </DisplayBox>
          <Title variant="small" weight="600" as="h2">
            Payment date
          </Title>
          <DisplayBox>{formatDateObject(paymentDate)}</DisplayBox>
          <Title variant="small" weight="600" as="h2">
            Billing information
          </Title>
          <DisplayBox>
            {newAddressSelected ? (
              <FormattedAddress
                qaPrefix="billing-info"
                name={payerName}
                street1={billingAddress.street1.rawValue}
                street2={billingAddress.street2.rawValue}
                city={billingAddress.city.rawValue}
                country={billingAddress.country.rawValue}
                stateProvince={billingAddress.stateProvince.rawValue}
                zip={formatZip(billingAddress.zip.rawValue)}
              />
            ) : (
              <FormattedAddress
                qaPrefix="billing-info"
                {...billingAddress}
                name={payerName}
              />
            )}
          </DisplayBox>
          {!showRemainingCartsButton && walletEnabled && !isLoggedIn ? (
            <PaymentButtonBar
              cancelURL={returnURL ? returnURL.url : "/profile/"}
              cancelText={returnURL ? returnURL.label : "Return"}
              forwardButtonAction={() => navigate("/registration")}
              forwardButtonText="Register for Wallet"
              forwardButtonAriaRole="link"
              buttonFlexOverride="flex-end"
            />
          ) : (
            <PaymentButtonBar
              redirectURL={returnURL ? returnURL.url : "/profile/"}
              redirectText={returnURL ? returnURL.label : "Return"}
              forwardButtonAriaRole="link"
              forwardButtonVariant="secondary"
              additionalButton={remainingCartsButton({
                extraStyles: `
                  max-width: ${isMobile ? "263px" : "293px"};
                  border-radius: 4px;
                `
              })}
              hideAdditionalButton={!showRemainingCartsButton}
              buttonGroupStyles={
                showRemainingCartsButton &&
                isMobile &&
                `align-items: normal;
                  div {
                    flex-direction: column-reverse;
                  }
                  a, button {
                    max-width: none;
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                  }`
              }
            />
          )}
        </Center>
      </Box>
    </Fragment>
  );

  return (
    <CenterStack
      content={content}
      contentSpacing="0"
      maxContentWidth="100%"
      role="region"
    />
  );
};

const PaymentSuccess = ({ ...props }) => (
  <DefaultPageTemplate
    gutters="0"
    content={<PaymentSuccessContent {...props} />}
  />
);

export default PaymentSuccess;
