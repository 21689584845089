import React from "react";
import { Cluster, Paragraph } from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import RightArrowIcon from "../../icons/RightArrowIcon";

const HeaderItem = ({
  name,
  themeValues,
  color = null,
  showArrow = false,
  weight = FONT_WEIGHT_SEMIBOLD,
  variant = null
}) => {
  const textColor = color || themeValues.linkColor;
  let labelVariant = variant || "p";

  return (
    <Cluster justify="flex-start" align="center">
      <Paragraph variant={labelVariant} color={textColor} weight={weight}>
        {name}
      </Paragraph>
      {showArrow && <RightArrowIcon size={22} color={themeValues.linkColor} />}
    </Cluster>
  );
};

export default HeaderItem;
