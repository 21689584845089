import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { tint } from "polished";
import { ButtonWithLink, withWindowSize, constants } from "@thecb/components";
import HeaderItem from "./HeaderItem";

// NavLink levels
export const NavLinkLevels = {
  TOP_LEVEL: "topLevel",
  SUB_MENU: "subMenu",
  DETAIL: "detail"
};

const NavLink = ({ url, label, navLevel, themeValues }) => {
  const { isMobile } = useContext(ThemeContext);
  // only for mobile right now
  const styles = {
    [NavLinkLevels.TOP_LEVEL]: {
      variant: "pL",
      fontWeight: constants.fontWeights.FONT_WEIGHT_SEMIBOLD,
      spacingStyle: "margin-left: 16px; padding: 0 1rem;",
      height: "56px"
    },
    [NavLinkLevels.SUB_MENU]: {
      variant: "p",
      fontWeight: constants.fontWeights.FONT_WEIGHT_SEMIBOLD,
      spacingStyle: "margin-left: 0px; padding: 0 24px;",
      height: "53px"
    },
    [NavLinkLevels.DETAIL]: {
      variant: "p",
      fontWeight: constants.fontWeights.FONT_WEIGHT_REGULAR,
      spacingStyle: "margin-left: 0px; padding: 0 40px;"
    }
  };

  return (
    <ButtonWithLink
      variant="ghost"
      contentOverride
      url={url}
      extraStyles={`margin: 0;`}
      linkExtraStyles={`
        min-height: ${styles[navLevel].height};
        max-height: ${styles[navLevel].height};
        flex-grow: 1; ${styles[navLevel].spacingStyle} 
        &:hover {
          background: ${tint(0.9, themeValues.linkColor)};
        }; 
        &:active {
          background: ${tint(0.8, themeValues.linkColor)}; 
          color: ${constants.colors.CHARADE_GREY};
        };
      }`}
    >
      <HeaderItem
        name={label}
        showArrow={false}
        themeValues={themeValues}
        color={themeValues.linkColor}
        weight={styles[navLevel].fontWeight}
        variant={styles[navLevel].variant}
      />
    </ButtonWithLink>
  );
};

export default withWindowSize(NavLink);
