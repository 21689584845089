import styled from "styled-components";
import { constants } from "@thecb/components";

export const SetUpAutopayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 578px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(41, 42, 51, 0.2),
    0px 1px 2px rgba(41, 42, 51, 0.1), inset 0px 1px 0px rgba(41, 42, 51, 0.1);
  background-color: ${constants.colors.WHITE};
  padding: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;
`;

export const ErrorContainer = styled.div`
  color: ${constants.colors.ERROR_COLOR};
  font-weight: ${constants.fontWeights.FONT_WEIGHT_SEMIBOLD};
`;
