import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import parse from "html-react-parser";
import dompurify from "dompurify";
import { values, isEmpty } from "ramda";
import {
  Breadcrumb,
  Text,
  Stack,
  Center,
  Box,
  Cluster,
  Module,
  ButtonWithAction,
  TermsAndConditions,
  RadioSection,
  Alert,
  BankIcon,
  GenericCard,
  withWindowSize,
  util,
  Paragraph,
  Heading,
  constants
} from "@thecb/components";
import {
  CREDIT_CARD_RESOURCE,
  ACH_RESOURCE,
  CREDIT_CARD_KIND
} from "../../Profile.state";
import {
  PaymentPlanWrapper,
  FormContainer,
  ErrorContainer
} from "./PaymentPlan.styled";
import AlertBar from "/components/alert-bar";

const PaymentPlan = ({
  resources,
  resourcesActions,
  selectedAutopaymentMethodId,
  termsAndConditionsAgreedTo,
  alertBars,
  detailedObligation,
  actions,
  paymentConfigs
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const { displayCurrency, formatPercent } = util.general;
  let topLevel = location.pathname
    .split("/")
    .filter((pathPart, index) => index <= 2)
    .join("/");
  const {
    setAutoPaymentSelectedPaymentMethod,
    createPaymentSchedule,
    toggleTermsAndConditions,
    clearAutoPayInfo
  } = resourcesActions;
  const { setUpAutopayAlertBar: alertBar } = alertBars;
  const {
    alertBars: { setUpAutopayAlertBar: alertBarActions }
  } = actions;
  const obligationSlug = detailedObligation?.config?.obligationSlug;
  const clientPaymentConfig =
    paymentConfigs?.[obligationSlug] ?? paymentConfigs.default;
  const CCFee = clientPaymentConfig.options.fees.creditCard;
  const ACHFee = clientPaymentConfig.options.fees.ach;
  const showACHFee = false;
  const hideFee = clientPaymentConfig.options?.hideFeeOnAutopay ?? false;
  const clearOnDismount = true;
  const { isMobile, metadata } = useContext(ThemeContext);
  const [terms, setTerms] = useState(CREDIT_CARD_RESOURCE);
  const [termsError, setTermsError] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [processingFee, setProcessingFee] = useState(CCFee);
  const [processingFeeAlert, setProcessingFeeAlert] = useState();
  const paymentMethods = [
    ...values(resources[ACH_RESOURCE]),
    ...values(resources[CREDIT_CARD_RESOURCE])
  ];
  const canUseCreditPayment =
    detailedObligation?.obligations?.[0]?.customAttributes?.cc_eligible ??
    "true";
  const cannotUseBankPayment =
    detailedObligation?.obligations?.[0]?.customAttributes?.cash_only ??
    "false";
  useEffect(() => {
    if (clearOnDismount) {
      return () => clearAutoPayInfo();
    }
  }, [clearOnDismount]);
  const breadcrumbText = topLevel.split("/").includes("accounts")
    ? "Accounts"
    : "Properties";
  const validateForm = () => {
    const termsValid = !termsAndConditionsAgreedTo ? setTermsError(true) : true;
    const paymentValid = !selectedAutopaymentMethodId
      ? setPaymentError(true)
      : true;

    if (termsValid && paymentValid) {
      createPaymentSchedule({ obligationSlug });
    }
  };

  const handleToggleSection = paymentType => {
    setAutoPaymentSelectedPaymentMethod(paymentType);
    handlePaymentMethod(paymentType);
    setPaymentError(false);
    if (termsAndConditionsAgreedTo) {
      toggleTermsAndConditions();
    }
  };

  const handlePaymentMethod = id => {
    const isCredit = values(resources[CREDIT_CARD_RESOURCE]).filter(
      card => card.id === id
    );
    if (isCredit.length > 0) {
      setProcessingFeeAlert(true);
      setTerms(CREDIT_CARD_RESOURCE);
    } else {
      setProcessingFeeAlert(false);
      setTerms(ACH_RESOURCE);
      // If we ever need to use a fee message for an ACH autopay fee
      if (showACHFee) {
        setProcessingFee(ACHFee);
        setProcessingFeeAlert(true);
      }
    }
  };

  return (
    <PaymentPlanWrapper>
      <Box padding={isMobile ? "0" : "0px 4px"}>
        <Breadcrumb
          breadcrumbsList={[
            {
              linkText: breadcrumbText,
              linkDestination: topLevel,
              isActive: false
            },
            {
              linkText: "Payment Plan",
              linkDestination: `${topLevel}/payment-plan`,
              isActive: true
            }
          ]}
        />
        {!isEmpty(alertBar.alerts) && (
          <Box padding="1rem 0 0 0">
            <AlertBar {...alertBar} {...alertBarActions} />
          </Box>
        )}
        <Box padding="0">
          <Stack childGap="0.5rem">
            <Box padding="0">
              <Module heading="Payment Plans">
                <Box padding="1.5rem 2rem">
                  <Center maxWidth="100%">
                    <Cluster
                      justify="space-around"
                      align="center"
                      childGap="1rem"
                    >
                      <Box padding="0" maxWidth="35rem">
                        <Text>
                          By enrolling in this option, we will process a monthly
                          recurring payment that will pay down your total tax
                          bill for this property. These payments post the first
                          of each month using the payment method you select. You
                          will receive an email receipt following each automatic
                          transaction.
                        </Text>
                      </Box>
                      <Box
                        background={constants.colors.ATHENS_GREY}
                        boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                        padding="1.125rem 2rem"
                      >
                        <Cluster justify="center" align="center">
                          <Stack childGap="0.5rem">
                            <Paragraph
                              variant="pS"
                              extraStyles={`text-align: center;`}
                              weight={
                                constants.fontWeights.FONT_WEIGHT_SEMIBOLD
                              }
                              color={constants.colors.CHARADE_GREY}
                            >
                              Monthly Payment Estimate
                            </Paragraph>
                            <Heading
                              variant="h6"
                              extraStyles={`text-align: center`}
                              weight={
                                constants.fontWeights.FONT_WEIGHT_SEMIBOLD
                              }
                              color={constants.colors.CHARADE_GREY}
                            >
                              $312.63
                            </Heading>
                            <Paragraph
                              variant="pS"
                              extraStyles={`text-align: center; font-style: italic;`}
                              weight={constants.fontWeights.FONT_WEIGHT_REGULAR}
                              color={constants.colors.STORM_GREY}
                            >
                              Withdraws October 1, 2020
                            </Paragraph>
                          </Stack>
                        </Cluster>
                      </Box>
                    </Cluster>
                  </Center>
                </Box>
              </Module>
            </Box>
            <Module>
              <FormContainer>
                <Center maxWidth="100%">
                  <Box>
                    <Text variant="p"></Text>
                  </Box>
                  <Stack bottomItem={0} childGap={0}>
                    <Box>
                      <Box padding="0px">
                        <Text
                          variant="pL"
                          color={constants.colors.STORM_GREY}
                          as="h2"
                        >
                          Select a saved payment method
                        </Text>
                        <Box padding="0px">
                          {paymentError && (
                            <ErrorContainer>
                              Please select a saved payment method.
                            </ErrorContainer>
                          )}
                        </Box>
                      </Box>
                      <Box padding="0">
                        <RadioSection
                          sections={paymentMethods.map(method =>
                            method.kind === CREDIT_CARD_KIND
                              ? {
                                  id: method.id,
                                  titleIcon: <GenericCard />,
                                  title: `Card ending in ${method.lastFour}`,
                                  disabled: canUseCreditPayment === "false"
                                }
                              : {
                                  id: method.id,
                                  titleIcon: <BankIcon />,
                                  title: `${
                                    method.accountType === "CHECKING"
                                      ? "Checking"
                                      : "Savings"
                                  } Account ending in ${method.lastFour}`,
                                  disabled: cannotUseBankPayment === "true"
                                }
                          )}
                          toggleOpenSection={id => handleToggleSection(id)}
                          openSection={
                            selectedAutopaymentMethodId
                              ? selectedAutopaymentMethodId
                              : false
                          }
                          staggeredAnimation
                          isMobile={isMobile}
                        />
                      </Box>
                    </Box>
                    {(cannotUseBankPayment === "true" ||
                      canUseCreditPayment === "false") && (
                      <Box padding="0 1rem 0.5rem">
                        <Alert
                          showQuitLink={false}
                          variant="warn"
                          text={`This account is restricted from making payments using ${
                            cannotUseBankPayment === "true"
                              ? `checking accounts`
                              : `credit/debit cards`
                          }. For more information, please contact support using the link below.`}
                        />
                      </Box>
                    )}
                    {processingFeeAlert &&
                      !hideFee &&
                      processingFee.length > 0 && (
                        <Box padding="0 1rem 0.5rem">
                          <Alert
                            heading="Processing Fee"
                            showQuitLink={false}
                            variant="info"
                            text={`There is a processing fee of ${
                              processingFee[0].type === "FLAT"
                                ? `${displayCurrency(processingFee[0].value)}`
                                : `${formatPercent(
                                    processingFee[0].value * 100
                                  )}%`
                            } ${
                              processingFee[0].minimumInCents
                                ? `with a minimum of ${displayCurrency(
                                    processingFee[0].minimumInCents
                                  )}`
                                : ""
                            } on all card payments.`}
                          />
                        </Box>
                      )}
                    <Box padding="16px 16px 0">
                      <Box padding="0px">
                        {termsError && (
                          <ErrorContainer>
                            Please accept Terms and Conditions.
                          </ErrorContainer>
                        )}
                      </Box>
                      <TermsAndConditions
                        noMargin
                        linkVariant="pS"
                        onCheck={() => {
                          if (termsError) {
                            setTermsError(false);
                          }
                          toggleTermsAndConditions();
                        }}
                        isChecked={termsAndConditionsAgreedTo}
                        html={
                          terms === CREDIT_CARD_RESOURCE
                            ? parse(
                                dompurify.sanitize(
                                  metadata?.data?.autopayTermsAndConditions
                                    ?.creditCard?.summary ?? ""
                                )
                              )
                            : parse(
                                dompurify.sanitize(
                                  metadata?.data?.autopayTermsAndConditions?.ach
                                    ?.summary ?? ""
                                )
                              )
                        }
                        terms={
                          terms === CREDIT_CARD_RESOURCE
                            ? parse(
                                dompurify.sanitize(
                                  metadata?.data?.autopayTermsAndConditions
                                    ?.creditCard?.extended ?? ""
                                )
                              )
                            : parse(
                                dompurify.sanitize(
                                  metadata?.data?.autopayTermsAndConditions?.ach
                                    ?.extended ?? ""
                                )
                              )
                        }
                      />
                    </Box>
                  </Stack>
                </Center>
              </FormContainer>
            </Module>
          </Stack>
        </Box>
        <Box
          padding="1.5rem 0 0 0"
          borderColor={constants.colors.GHOST_GREY}
          borderSize="1px"
          borderWidthOverride="1px 0 0 0"
        >
          {isMobile ? (
            <Stack childGap="1rem" direction="row">
              <Box width="100%" padding="0">
                <ButtonWithAction
                  text="Cancel"
                  action={() => navigate(-1)}
                  variant={"secondary"}
                  dataQa="Cancel"
                  extraStyles={`width: 100%;`}
                />
              </Box>
              <Box width="100%" padding="0">
                <ButtonWithAction
                  text="Confirm"
                  variant="primary"
                  action={validateForm}
                  dataQa="Confirm"
                  extraStyles={`width: 100%;`}
                />
              </Box>
            </Stack>
          ) : (
            <Stack childGap="1rem" direction="row" justify="flex-end">
              <ButtonWithAction
                text="Cancel"
                action={() => navigate(-1)}
                variant={"secondary"}
                dataQa="Cancel"
              />
              <ButtonWithAction
                text="Confirm"
                variant="primary"
                action={validateForm}
                dataQa="Confirm"
              />
            </Stack>
          )}
        </Box>
      </Box>
    </PaymentPlanWrapper>
  );
};

export default withWindowSize(React.memo(PaymentPlan));
