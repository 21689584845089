import React from "react";

const AgencyIcon = ({ color = "#292A33", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    {...props}
  >
    <g id="Agency / Agency - S">
      <path
        id="Icon - Agency"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5625 4.375H3.875L3.875 16.625H9.5625V7.875V7V4.375ZM10.4375 7.875V16.625H16.125V7.875H10.4375ZM10.4375 7H16.125C16.6082 7 17 7.39175 17 7.875V16.625C17 17.1082 16.6082 17.5 16.125 17.5H10.4375H9.5625H3.875C3.39175 17.5 3 17.1082 3 16.625V4.375C3 3.89175 3.39175 3.5 3.875 3.5H9.5625C10.0457 3.5 10.4375 3.89175 10.4375 4.375V7ZM8.46875 6.5625H4.96875V7.4375H8.46875V6.5625ZM4.96875 8.3125H8.46875V9.1875H4.96875V8.3125ZM8.46875 10.0625H4.96875V10.9375H8.46875V10.0625ZM4.96875 11.8125H8.46875V12.6875H4.96875V11.8125ZM4.96875 13.5625H8.46875V14.4375H4.96875V13.5625ZM11.5312 10.0625H15.0312V10.9375H11.5312V10.0625ZM11.5312 11.8125H15.0312V12.6875H11.5312V11.8125ZM15.0312 13.5625H11.5312V14.4375H15.0312V13.5625Z"
        fill={color}
      />
    </g>
  </svg>
);

export default AgencyIcon;
