import React from "react";

const AccountMedicalIcon = ({ color }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.875 36C32.1875 36 32.4531 35.8906 32.6719 35.6719C32.8906 35.4531 33 35.1875 33 34.875V19.5H26.625C26.3125 19.5 26.0469 19.3906 25.8281 19.1719C25.6094 18.9531 25.5 18.6875 25.5 18.375V12H16.125C15.8125 12 15.5469 12.1094 15.3281 12.3281C15.1094 12.5469 15 12.8125 15 13.125V34.875C15 35.1875 15.1094 35.4531 15.3281 35.6719C15.5469 35.8906 15.8125 36 16.125 36H31.875ZM33 18V17.7188C33 17.4063 32.8906 17.1406 32.6718 16.9219L28.0781 12.3281C27.8593 12.1094 27.5937 12 27.2812 12H27V18H33ZM22.875 31.5H25.125C25.2187 31.5 25.3047 31.4609 25.3828 31.3828C25.4609 31.3047 25.5 31.2188 25.5 31.125V28.5H28.125C28.2187 28.5 28.3047 28.4609 28.3828 28.3828C28.4609 28.3047 28.5 28.2188 28.5 28.125V25.875C28.5 25.7812 28.4609 25.6953 28.3828 25.6172C28.3047 25.5391 28.2187 25.5 28.125 25.5H25.5V22.875C25.5 22.7812 25.4609 22.6953 25.3828 22.6172C25.3047 22.5391 25.2187 22.5 25.125 22.5H22.875C22.7812 22.5 22.6953 22.5391 22.6172 22.6172C22.539 22.6953 22.5 22.7812 22.5 22.875V25.5H19.875C19.7812 25.5 19.6953 25.5391 19.6172 25.6172C19.539 25.6953 19.5 25.7812 19.5 25.875V28.125C19.5 28.2188 19.539 28.3047 19.6172 28.3828C19.6953 28.4609 19.7812 28.5 19.875 28.5H22.5V31.125C22.5 31.2188 22.539 31.3047 22.6172 31.3828C22.6953 31.4609 22.7812 31.5 22.875 31.5Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default AccountMedicalIcon;
