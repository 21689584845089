import React from "react";
import { fallbackValues } from "./LabeledAmount.theme";
import { themeComponent } from "../../../util/themeUtils";
import LabeledAmountV1 from "./LabeledAmountV1";
import LabeledAmountV2 from "./LabeledAmountV2";

const LabeledAmount = ({ version = "v1", ...rest }) => {
  const LabeledAmountComponent =
    version === "v1" ? LabeledAmountV1 : LabeledAmountV2;

  return <LabeledAmountComponent {...rest} />;
};

export default themeComponent(
  LabeledAmount,
  "LabeledAmount",
  fallbackValues,
  "default"
);
