import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";

import ButtonWithLink from "../../atoms/button-with-link";
import ButtonWithAction from "../../atoms/button-with-action";
import { Box, Cluster } from "../../atoms/layouts";
import SolidDivider from "../../atoms/solid-divider";
import { kebabCaseString } from "../../../util/general";

const PaymentButtonBar = ({
  isForwardButtonDisabled = false,
  forwardButtonText = "Next",
  forwardButtonAction,
  forwardButtonLoading,
  forwardButtonVariant = "primary",
  forwardButtonAriaRole = "button",
  backButtonVariant = "secondary",
  backButtonAction,
  cancelURL,
  cancelText = "Cancel",
  redirectURL,
  redirectText = "Return",
  buttonFlexOverride,
  hideForwardButton = false,
  hideBackButton = false,
  buttonGroupStyles,
  hideAdditionalButton = false,
  additionalButton,
  nextButtonTestId = null,
  backButtonTestId = null
}) => {
  const { isMobile } = useContext(ThemeContext);

  const backButton =
    !!cancelURL && !!cancelText ? (
      <ButtonWithLink
        text={cancelText}
        url={cancelURL}
        variant={backButtonVariant}
        extraStyles={isMobile && "flex-grow: 1"}
        dataQa={backButtonTestId || cancelText}
        aria-labelledby={`${kebabCaseString(cancelText)}-button`}
        role="link"
      />
    ) : (
      backButtonAction && (
        <ButtonWithAction
          text="Back"
          variant={backButtonVariant}
          action={backButtonAction}
          extraStyles={isMobile && "flex-grow: 1"}
          dataQa="Back"
          aria-labelledby="back-button"
          role="link"
        />
      )
    );

  const forwardButton = !!redirectURL ? (
    <ButtonWithLink
      url={redirectURL}
      text={redirectText}
      variant={forwardButtonVariant}
      extraStyles={isMobile && "flex-grow: 1"}
      dataQa={nextButtonTestId || redirectText}
      disabled={isForwardButtonDisabled}
      aria-labelledby={`${kebabCaseString(redirectText)}-button`}
      role={forwardButtonAriaRole}
    />
  ) : (
    forwardButtonAction && (
      <ButtonWithAction
        text={forwardButtonText}
        variant={forwardButtonVariant}
        action={forwardButtonAction}
        isLoading={forwardButtonLoading}
        extraStyles={isMobile && "flex-grow: 1"}
        dataQa={nextButtonTestId || forwardButtonText}
        disabled={isForwardButtonDisabled}
        aria-labelledby={`${kebabCaseString(forwardButtonText)}-button`}
        role={forwardButtonAriaRole}
      />
    )
  );

  return (
    <Fragment>
      <Box padding="1.25rem 0" />
      <SolidDivider />
      <Box padding="2.5rem 0 3.125rem 0">
        <Cluster
          justify={
            buttonFlexOverride
              ? buttonFlexOverride
              : !!backButton
              ? "space-between"
              : "flex-end"
          }
          align="center"
          extraStyles={buttonGroupStyles}
        >
          {!hideBackButton && <Fragment>{backButton}</Fragment>}
          {!hideForwardButton && <Fragment>{forwardButton}</Fragment>}
          {!hideAdditionalButton && <Fragment>{additionalButton}</Fragment>}
        </Cluster>
      </Box>
    </Fragment>
  );
};

export default PaymentButtonBar;
