import styled from "styled-components";
import {
  GHOST_GREY,
  ERROR_COLOR,
  MATISSE_BLUE
} from "../../../constants/colors";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  > * + * {
    margin-top: 0.25rem;
  }
`;

export const SelectField = styled.select`
  border: 1px solid
    ${({ field, showErrors }) =>
      (field.dirty && field.hasErrors) || (field.hasErrors && showErrors)
        ? ERROR_COLOR
        : GHOST_GREY};
  border-radius: 2px;
  height: 48px;
  width: 100%;
  padding: 0.75rem 1rem;
  min-width: 100px;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 1.1rem;
  font-family: Public Sans;
  line-height: 2rem;
  font-weight: ${FONT_WEIGHT_REGULAR};
  background-color: ${({ themeValues }) =>
    themeValues.inputBackgroundColor && themeValues.inputBackgroundColor};
  color: ${({ themeValues }) => themeValues.color && themeValues.color};
  box-shadow: none;

  &:focus {
    border: 1px solid ${MATISSE_BLUE};
  }
`;

export const SelectOption = styled.option``;
