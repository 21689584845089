import React, { useEffect } from "react";
import { required, isProbablyEmail } from "redux-freeform";
import { FormInput } from "../../atoms/form-layouts";
import { noop } from "../../../util/general";

const ForgotPasswordForm = ({
  fields,
  actions,
  clearOnDismount,
  showErrors,
  handleSubmit = noop
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const EmailErrorMessages = {
    [required.error]: "Email address is required",
    [isProbablyEmail.error]: "Email address is not valid"
  };

  return (
    <FormInput
      labelTextWhenNoError="Email address"
      errorMessages={EmailErrorMessages}
      field={fields.email}
      fieldActions={actions.fields.email}
      showErrors={showErrors}
      onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
      isEmail
      autocompleteValue="email"
    />
  );
};

export default ForgotPasswordForm;
