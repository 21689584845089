import React from "react";

import { themeComponent } from "../../../util/themeUtils";
import withWindowSize from "../../withWindowSize";

import { fallbackValues } from "./CardText.theme";

import Box from "../layouts/Box";
import Cover from "../layouts/Cover";
import Paragraph from "../paragraph";
import Stack from "../layouts/Stack";
import Title from "../title";
import IconQuitLarge from "../../atoms/icons/IconQuitLarge";
import { Cluster } from "../layouts";

export const CardText = ({
  showQuitLink,
  onQuitClick,
  titleAs,
  padding,
  text,
  textAs = "p",
  titleText,
  titleVariant = "small",
  themeValues
}) => {
  return (
    <Box padding={padding}>
      <Cover>
        <Stack>
          <Cluster
            justify="space-between"
            align="center"
            overflow={true}
            nowrap
          >
            {titleText && (
              <Title
                as={titleAs}
                variant={titleVariant}
                color={themeValues.titleColor}
                weight={themeValues.titleWeight}
              >
                {titleText}
              </Title>
            )}
            {showQuitLink && (
              <Box
                padding="0"
                onClick={onQuitClick}
                onKeyDown={e => e.key === "Enter" && onQuitClick()}
                role="button"
                tabIndex={0}
                aria-label={`Close Card: ${titleText}`}
                extraStyles="cursor: pointer;"
              >
                <IconQuitLarge />
              </Box>
            )}
          </Cluster>
          <Paragraph as={textAs} color={themeValues.textColor}>
            {text}
          </Paragraph>
        </Stack>
      </Cover>
    </Box>
  );
};

export default themeComponent(
  withWindowSize(CardText),
  "CardText",
  fallbackValues
);
