import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  ResetConfirmationForm,
  ResetPasswordSuccess,
  CenterSingle,
  withWindowSize,
  Box
} from "@thecb/components";

const ResetConfirmationContent = ({ showMessage, isMobile }) => (
  <Box padding={isMobile ? "0 0 48px" : "32px 0 64px"}>
    {showMessage === "reset-confirm" ? (
      <ResetConfirmationForm />
    ) : (
      <ResetPasswordSuccess />
    )}
  </Box>
);

const ResetConfirmation = ({ showMessage }) => {
  const { isMobile } = useContext(ThemeContext);

  return (
    <CenterSingle
      content={
        <ResetConfirmationContent
          showMessage={showMessage}
          isMobile={isMobile}
        />
      }
    />
  );
};
export default withWindowSize(ResetConfirmation);
