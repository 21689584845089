import styled from "styled-components";
import { constants } from "@thecb/components";

export const StyledTable = styled.table`
  margin-top: 32px;
  max-width: 100%;
  border-collapse: collapse;
`;

export const TableCellContentContainer = styled.div`
  margin: 24px 0;
`;

export const TableHeaderCell = styled.th`
  background: ${({ background }) => background};
  border: 1px solid ${constants.colors.GHOST_GREY};
  padding: 0 24px;
  text-align: left;
`;

export const TableDataCell = styled.td`
  border: 1px solid ${constants.colors.GHOST_GREY};
  padding: 0 24px;
`;
