import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import {
  fetchTenantConfiguration,
  fetchTenantConfigurationSuccess,
  fetchTenantConfigurationError,
  setInitialLocation,
  fetchTheme,
  fetchThemeSuccess,
  fetchThemeError,
  FETCH_SUB_CLIENT_CONFIGURATION,
  fetchSubClientConfigurationSuccess,
  fetchSubClientConfigurationError
} from "../reducer";
import * as R from "ramda";

const doTenantConfigurationHTTPCall = async (
  slug,
  app,
  configMetaEndpoint,
  configSettingsEndpoint,
  configPaymentsEndpoint
) => {
  let url = "";
  let settingsUrl = "";
  let paymentUrl = "";
  if (configMetaEndpoint === "http://localhost:8080/metadata") {
    url = `${configMetaEndpoint}/${slug}/${app}/meta.json`;
    settingsUrl = `${configSettingsEndpoint}/${slug}/${app}/settings.json`;
    paymentUrl = `${configPaymentsEndpoint}/${slug}/${app}/config.json`;
  } else {
    url = `${configMetaEndpoint}/meta.json`;
    settingsUrl = `${configSettingsEndpoint}/settings.json`;
    paymentUrl = `${configPaymentsEndpoint}/config.json`;
  }

  const metaResponse = await fetch(url, { cache: "no-store" });
  const metaData = await metaResponse.json();

  const settingsResponse = await fetch(settingsUrl, { cache: "no-store" });
  const settingsData = await settingsResponse.json();

  const subClients = (settingsData?.subClients ?? []).map(sc => {
    return {
      path: Object.keys(sc)[0],
      slug: Object.values(sc)[0]
    };
  });

  // Legacy handling of single sub-client as we transition to multiple sub-client support.
  const subClientPath = subClients[0]?.path;
  const subClientSlug = subClients[0]?.slug;

  const paymentResponse = await fetch(paymentUrl, { cache: "no-store" });
  const paymentData = await paymentResponse.json();

  if (metaResponse.errors) {
    throw new Error(`Frontend config service meta request error`);
  }

  if (settingsResponse.errors) {
    throw new Error(`Frontend config service settings request error`);
  }

  if (paymentResponse.errors) {
    throw new Error(`Backend config service payment request error`);
  }

  // Make sure useFakeData is false, unless it is specified as true in settings.json
  const modifiedSettingsData = {
    useFakeData: false,
    ...settingsData
  };

  return {
    meta: metaData,
    settings: modifiedSettingsData,
    subClientPath,
    subClientSlug,
    subClients,
    payment: paymentData
  };
};

/**
 * Upon initializing this epic we do three things:
 * 1. Set the tenant slug in the state
 * 2. Inform the state that we are fetching tenant config
 * 3. Do the tenant config call
 */
export const initializeTenantEpic = (action$, state$) => {
  const {
    defaultClientSlug,
    defaultClientApp,
    configMetaEndpoint,
    configSettingsEndpoint,
    configPaymentsEndpoint
  } = state$.value.config;
  return Rx.merge(
    Rx.of(setInitialLocation(state$.value.router.location.pathname)),
    Rx.of(fetchTenantConfiguration()),
    Rx.from(
      doTenantConfigurationHTTPCall(
        defaultClientSlug,
        defaultClientApp,
        configMetaEndpoint,
        configSettingsEndpoint,
        configPaymentsEndpoint
      )
    ).pipe(
      RxOp.map(response => fetchTenantConfigurationSuccess(response)),
      RxOp.catchError(error => {
        return Rx.of(
          fetchTenantConfigurationError(
            R.pathOr(error.message, ["response", "statusText"], error)
          )
        );
      })
    )
  );
};

const fetchThemeFromConfigService = async state => {
  const { defaultClientSlug, configThemesEndpoint } = state.config;

  let url;

  if (configThemesEndpoint === "http://localhost:8080/themes") {
    url = `${configThemesEndpoint}/${defaultClientSlug}/theme.json`;
  } else {
    url = `${configThemesEndpoint}/theme.json`;
  }

  const response = await fetch(url, { cache: "no-store" });

  return response;
};

/* This code fetches our newer flat file themes.
 * The other theme approach will be deprecated.
 */
export const fetchThemeEpic = (action$, state$) =>
  Rx.merge(
    Rx.of(fetchTheme()),
    Rx.from(fetchThemeFromConfigService(state$.value)).pipe(
      RxOp.mergeMap(response => Rx.from(response.json())),
      RxOp.map(body => fetchThemeSuccess(body)),
      RxOp.catchError(error => Rx.of(fetchThemeError(error)))
    )
  );

const fetchSubClientConfiguration = async ({
  subClientSlug,
  defaultClientSlug,
  configMetaEndpoint
}) => {
  const metaUrl =
    configMetaEndpoint === "http://localhost:8080/metadata"
      ? `${configMetaEndpoint}/${defaultClientSlug}/${subClientSlug}/meta.json`
      : `${configMetaEndpoint}/${subClientSlug}/meta.json`;

  const metaResponse = await fetch(metaUrl, { cache: "no-store" });
  const metaData = await metaResponse.json();

  if (metaResponse.errors) {
    throw new Error(`Frontend config service meta request error`);
  }

  return {
    meta: metaData
  };
};

const getSubClientArgs = ({ subClientSlug }, state) => ({
  subClientSlug,
  defaultClientSlug: state.config.defaultClientSlug,
  configMetaEndpoint: state.config.configMetaEndpoint
});

export const fetchSubClientConfigurationEpic = (action$, state$) =>
  action$.ofType(FETCH_SUB_CLIENT_CONFIGURATION).pipe(
    RxOp.flatMap(action =>
      Rx.from(
        fetchSubClientConfiguration(
          getSubClientArgs(action.payload, state$.value)
        )
      ).pipe(
        RxOp.map(response => fetchSubClientConfigurationSuccess(response)),
        RxOp.catchError(error =>
          Rx.of(
            fetchSubClientConfigurationError(
              R.pathOr(error.message, ["response", "statusText"], error)
            )
          )
        )
      )
    )
  );
