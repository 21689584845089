import React, { useState } from "react";
import styled from "styled-components";
import { Box, Detail, Dropdown, constants } from "@thecb/components";

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

const options = [...Array(28).keys()]
  .map(n => n + 1)
  .map(dayNumber => ({
    value: dayNumber.toString(),
    text: ordinalSuffixOf(dayNumber)
  }));

const AutopayDateSelectWrapper = styled(Box)`
  input {
    cursor: pointer;
    font-size: 16px;
  }
`;

const AutopayDateSelect = () => {
  const [date, setDate] = useState("1");
  const [open, setOpen] = useState(false);

  return (
    <AutopayDateSelectWrapper padding="0px">
      <Detail
        color={constants.colors.STORM_GREY}
        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
        as="h2"
      >
        Select payment date
      </Detail>
      <Dropdown
        options={options}
        onSelect={value => setDate(value)}
        onClick={() => setOpen(!open)}
        isOpen={open}
        value={date}
      />
    </AutopayDateSelectWrapper>
  );
};

export default AutopayDateSelect;
