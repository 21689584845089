import React, { Fragment, useState, createRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "formatted-input";
import {
  TermsAndConditions,
  FormattedAddress,
  Title,
  Stack,
  PaymentButtonBar,
  Box,
  EditableList,
  FormattedBankAccount,
  FormattedCreditCard,
  Cluster,
  util,
  constants
} from "@thecb/components";
import { NEW_ACH, NEW_CC, SAVED_ACH } from "../../Payment.state";
import CaptchaForm from "../../../../../../components/captcha-form";
import Redirect from "../../../../../common/pages/redirect";
import { formatDateObject } from "../../../../../../util/dateUtil";

const PaymentConfirmation = ({
  savedCreditCards,
  savedBankAccounts,
  selectedPaymentMethodType,
  selectedSavedCreditCardId,
  selectedSavedACHId,
  paymentDate,
  newAddressSelected,
  billingAddress,
  termsAndConditions,
  termsAndConditionsAgreedTo,
  toggleTermsAndConditions,
  submitPayment,
  creditCardForm,
  achForm,
  navigate,
  paymentSubmitting,
  contactEmail,
  contactPhone,
  goToPaymentPage,
  goToBillingInfoPage,
  gotToWalletPage,
  captchaForm,
  captchaFormActions,
  captchaSiteKey,
  isMobile,
  isExpiredSession,
  clearAlerts,
  total,
  stepCounter,
  walletScreenV2Enabled,
  usesCustomerInformationEmail,
  newEmailSelected,
  emailForm
}) => {
  useEffect(() => {
    return () => clearAlerts();
  }, []);
  let params = useParams();
  const [errors, handleErrors] = useState(false);
  const { serviceName } = params;
  const captchaRef = createRef();
  const { displayCurrency } = util.general;

  const isACH =
    selectedPaymentMethodType === NEW_ACH ||
    selectedPaymentMethodType === SAVED_ACH;

  const selectedSavedACH = savedBankAccounts?.[selectedSavedACHId] ?? "";
  const selectedSavedCreditCard =
    savedCreditCards?.[selectedSavedCreditCardId] ?? "";
  const [termsError, setTermsError] = useState(false);
  const formatPhone = format(util.formats.phoneFormat);
  const formatZip = format(util.formats.zipFormat);
  const receiptEmail =
    newEmailSelected && walletScreenV2Enabled
      ? emailForm.fields.email.rawValue
      : contactEmail;

  /**
   * computeFormHasErrors
   * @returns {boolean} whether errors were found in the form's fields
   */
  const computeFormHasErrors = () => {
    const termsNotAccepted = termsAndConditions && !termsAndConditionsAgreedTo;
    const captchaNotSolved =
      captchaSiteKey &&
      Object.values(captchaForm.fields).reduce(
        (acc, curr) => acc || curr.hasErrors,
        false
      );
    return captchaNotSolved || termsNotAccepted;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formHasErrors = computeFormHasErrors();
    const showErrors = () => {
      if (termsNotAccepted) {
        setTermsError(true);
      }
      handleErrors(true);
    };
    const submitForm = () => {
      submitPayment();
      captchaRef.current?.resetCaptcha();
    };

    return formHasErrors ? showErrors() : submitForm();
  };

  if (!selectedPaymentMethodType) {
    return <Redirect redirectTo={`/payment/service/${serviceName}/payment`} />;
  }

  // Payment data is cleared when session expires
  // useEffect redirect in Payment.js runs after initial render
  // Checking isExpiredSession prevents app crash (from missing data) if user navigates back/forward in browser

  return isExpiredSession ? (
    <Stack childGap="1.5rem">
      <Box padding={isMobile ? "0 0 1rem" : "1rem 0"} width="100%">
        <Title
          weight={constants.fontWeights.FONT_WEIGHT_BOLD}
          extraStyles={`font-size: 1.75rem;`}
          id="checkout-section-title"
          as="h1"
        >
          Review &amp; Confirm
        </Title>
      </Box>
    </Stack>
  ) : (
    <Stack childGap="0">
      <Cluster justify="space-between" align="center" nowrap>
        <Box
          padding={isMobile ? "0 0 1rem" : "1rem 0"}
          width={isMobile ? "60%" : "100%"}
        >
          <Title
            weight={constants.fontWeights.FONT_WEIGHT_BOLD}
            extraStyles={`font-size: 1.75rem;`}
            id="checkout-section-title"
            as="h1"
          >
            Review &amp; Confirm
          </Title>
        </Box>
        {isMobile && (
          <Box padding={isMobile ? "0 0 1rem 0" : "1rem 0"} width={"40%"}>
            {stepCounter}
          </Box>
        )}
      </Cluster>
      <Stack childGap="1.5rem">
        <EditableList
          title="Payment method"
          as="h2"
          titleWeight="600"
          canRemove={false}
          editItem={goToPaymentPage}
          editItemAriaRole="link"
          itemName="Payment method"
          listPadding="0"
          items={[selectedPaymentMethodType]}
          renderItem={i => (
            <Stack>
              {isACH ? (
                <FormattedBankAccount
                  lastFour={
                    i === NEW_ACH
                      ? achForm.fields.accountNumber.rawValue.slice(-4)
                      : selectedSavedACH.lastFour
                  }
                  accountType={
                    i === NEW_ACH
                      ? achForm.fields.accountType.rawValue
                      : selectedSavedACH.accountType
                  }
                />
              ) : (
                <FormattedCreditCard
                  lastFour={
                    i === NEW_CC
                      ? creditCardForm.fields.creditCardNumber.rawValue.slice(
                          -4
                        )
                      : selectedSavedCreditCard.lastFour
                  }
                />
              )}
            </Stack>
          )}
          maxItems={1}
          qaPrefix="Payment method"
          ariaLabel="Payment method"
        />
        <EditableList
          title="Payment date"
          as="h2"
          titleWeight="600"
          canRemove={false}
          canEdit={false}
          itemName="Payment date"
          listPadding="0"
          renderItem={formatDateObject}
          maxItems={1}
          items={[paymentDate]}
          qaPrefix="Payment date"
        />
        <EditableList
          title="Billing address"
          as="h2"
          titleWeight="600"
          canRemove={false}
          editItem={goToBillingInfoPage}
          editItemAriaRole="link"
          itemName="Billing address"
          listPadding="0"
          renderItem={billingAddress =>
            newAddressSelected ? (
              <FormattedAddress
                street1={billingAddress.street1.rawValue}
                street2={billingAddress.street2.rawValue}
                city={billingAddress.city.rawValue}
                stateProvince={billingAddress.stateProvince.rawValue}
                zip={formatZip(billingAddress.zip.rawValue)}
              />
            ) : (
              <FormattedAddress {...billingAddress} />
            )
          }
          listItemSize="big"
          maxItems={1}
          items={[billingAddress]}
          qaPrefix="Billing address"
          ariaLabel="Billing address"
        />
        <EditableList
          as="h2"
          canRemove={false}
          title="Contact phone number"
          titleWeight="600"
          editItem={goToBillingInfoPage}
          editItemAriaRole="link"
          itemName="Contact phone number"
          listPadding="0"
          renderItem={contactPhone => formatPhone(contactPhone)}
          maxItems={1}
          items={[contactPhone]}
          qaPrefix="Contact phone number"
          ariaLabel="Contact phone number"
        />
        <EditableList
          as="h2"
          canRemove={false}
          title="Contact email"
          titleWeight="600"
          editItem={
            walletScreenV2Enabled && !usesCustomerInformationEmail
              ? gotToWalletPage
              : goToBillingInfoPage
          }
          editItemAriaRole="link"
          itemName="Contact email"
          listPadding="0"
          renderItem={
            walletScreenV2Enabled
              ? receiptEmail => receiptEmail
              : contactEmail => contactEmail
          }
          maxItems={1}
          items={walletScreenV2Enabled ? [receiptEmail] : [contactEmail]}
          qaPrefix="Contact email"
          ariaLabel="Contact email"
        />
        {termsAndConditions && (
          <Fragment>
            <TermsAndConditions
              version="v2"
              onCheck={() => {
                if (termsError) {
                  setTermsError(false);
                }
                toggleTermsAndConditions();
              }}
              isChecked={termsAndConditionsAgreedTo}
              hasError={termsError}
              terms={termsAndConditions.extended}
              description="I agree to the"
              displayInline={false}
              initialFocusSelector={"[name='Close']"}
            />
          </Fragment>
        )}
        {captchaSiteKey && (
          <CaptchaForm
            siteKey={captchaSiteKey}
            ref={captchaRef}
            {...captchaForm}
            {...captchaFormActions}
            showErrors={errors}
          />
        )}
        <PaymentButtonBar
          backButtonAction={() => {
            navigate(-1);
          }}
          forwardButtonText={`Pay ${displayCurrency(total)}`}
          forwardButtonAction={handleSubmit}
          forwardButtonAriaRole="button"
          forwardButtonLoading={paymentSubmitting}
          isForwardButtonDisabled={computeFormHasErrors()}
        />
      </Stack>
    </Stack>
  );
};

export default PaymentConfirmation;
