import styled from "styled-components";
import Heading from "../../atoms/heading";
import Paragraph from "../../atoms/paragraph";
import Stack from "../../atoms/layouts/Stack";
import { Box } from "../../atoms/layouts";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  ${({ isDisabled, theme }) => `
    background-color: ${
      isDisabled ? theme.disabledBackgroundColor : theme.backgroundColor
    };
    border: 1px solid ${
      isDisabled ? theme.disabledBorderColor : theme.borderColor
    };
  `}
  transition: all .2s ease-in-out;

  ${({ isDisabled, theme }) =>
    isDisabled
      ? `
        &:hover,
        &:active {
          cursor: default;
          box-shadow: none;
          border: 1px solid ${theme.disabledBorderColor};
        }
      `
      : `
        &:hover,
        &:active {
          cursor: pointer;
          box-shadow: 0px 0px 0px 0px rgba(41, 42, 51, 0.1),
            0px 5px 11px 0px rgba(41, 42, 51, 0.1),
            0px 4px 19px 0px rgba(41, 42, 51, 0.09),
            0px 27px 26px 0px rgba(41, 42, 51, 0.05),
            0px 56px 31px 0px rgba(41, 42, 51, 0.01),
            0px 80px 33px 0px rgba(41, 42, 51, 0);
        }

        &:hover:not(:active) {
          border: 1px solid ${theme.borderColor};
        }

        &:active {
          background-color: ${theme.activeBackgroundColor};
          border: 1px solid ${theme.borderColor};
        }
      `}
`;

export const Title = styled(Heading)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 150%;
  background-color: transparent;
  font-weight: ${FONT_WEIGHT_SEMIBOLD};
  ${({ isDisabled, theme }) =>
    `color: ${isDisabled ? theme.disabledColor : theme.color};`};
`;

export const Subtitle = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.14px;
  font-weight: ${FONT_WEIGHT_SEMIBOLD};
  ${({ isDisabled, theme }) =>
    `color: ${isDisabled ? theme.disabledColor : theme.color};`};
`;

export const Footer = styled(Stack)`
  align-items: center;
  width: 100%;
`;
