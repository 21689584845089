const backgroundColor = "#3b414d";
const textColor = "#ffffff";
const logoHeight = "50px";
const menuAccentColor = "#979797";
const iconVariant = "darkMode";
const linkColor = "#15749D";
const userTextColor = "#ffffff";
const accessibilityColor = "#3181E3";

export const fallbackValues = {
  backgroundColor,
  textColor,
  logoHeight,
  menuAccentColor,
  iconVariant,
  linkColor,
  userTextColor,
  accessibilityColor
};
