import React from "react";
import { STORM_GREY } from "../../../constants/colors";

const PendingIcon = ({ fill = STORM_GREY }) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icons" transform="translate(-64.000000, -448.000000)" fill={fill}>
        <path
          d="M80,480 C88.836556,480 96,472.836556 96,464 C96,455.163444 88.836556,448 80,448 C71.163444,448 64,455.163444 64,464 C64,472.836556 71.163444,480 80,480 Z M87,466 C88.1045695,466 89,465.104569 89,464 C89,462.895431 88.1045695,462 87,462 C85.8954305,462 85,462.895431 85,464 C85,465.104569 85.8954305,466 87,466 Z M80,462 C81.1045695,462 82,462.895431 82,464 C82,465.104569 81.1045695,466 80,466 C78.8954305,466 78,465.104569 78,464 C78,462.895431 78.8954305,462 80,462 Z M73,462 C74.1045695,462 75,462.895431 75,464 C75,465.104569 74.1045695,466 73,466 C71.8954305,466 71,465.104569 71,464 C71,462.895431 71.8954305,462 73,462 Z"
          id="status-icon---pending"
        ></path>
      </g>
    </g>
  </svg>
);

export default PendingIcon;
