import React, { Fragment } from "react";
import {
  EditableTableListItem,
  EditableListAction,
  ItemWrapper,
  ActionWrapper,
  TableItemKey,
  TableItemValue
} from "./EditableTable.styled";
import { Box } from "../../atoms/layouts";
import Text from "../../atoms/text";
import { CHARADE_GREY, GHOST_GREY } from "../../../constants/colors";

const TableListItem = ({
  title,
  value,
  canEdit = false,
  canRemove = false,
  isMobile,
  borderTopItem
}) => (
  <Box
    padding="0px"
    extraStyles={`&:last-child {
    > * {
      border-bottom: none;
    }
  }
  ${isMobile &&
    borderTopItem &&
    `&:first-child {
    > * {
      border-top: 1px solid ${GHOST_GREY};
    }
  }`}
  `}
  >
    <ItemWrapper>
      <EditableTableListItem isMobile={isMobile}>
        <TableItemKey isMobile={isMobile}>
          <Text variant="pS" color={CHARADE_GREY}>
            {title}
          </Text>
        </TableItemKey>
        <TableItemValue isMobile={isMobile}>
          <Text variant="p" color={CHARADE_GREY}>
            {value}
          </Text>
        </TableItemValue>
        {canRemove || canEdit ? (
          <ActionWrapper isMobile={isMobile}>
            <EditableListAction
              hide={!canRemove}
              onClick={() => console.log("Remove Item Coming Soon...")}
            >
              Remove
            </EditableListAction>
            <EditableListAction
              hide={!canEdit}
              onClick={() => console.log("Edit Item Coming Soon...")}
            >
              Edit
            </EditableListAction>
          </ActionWrapper>
        ) : (
          <Fragment />
        )}
      </EditableTableListItem>
    </ItemWrapper>
  </Box>
);

export default TableListItem;
