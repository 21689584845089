import React from "react";
import Detail from "../detail";
import Title from "../title";

const LabeledAmountV2 = ({
  variant = "regular",
  label,
  amount,
  themeValues,
  as,
  extraStyles
}) => {
  const mappedDetailVariants = {
    regular: "large",
    small: "regular",
    extraSmall: "small"
  };
  const LabeledAmountContainer = variant === "large" ? Title : Detail;
  const containerVariant =
    variant === "large" ? "small" : mappedDetailVariants[variant];
  return (
    <LabeledAmountContainer
      variant={containerVariant}
      as={as}
      weight={themeValues.fontWeight}
      extraStyles={`display: flex; justify-content: space-between; ${extraStyles}`}
    >
      <span>{label}</span>
      <span>{amount}</span>
    </LabeledAmountContainer>
  );
};

export default LabeledAmountV2;
