import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import { LoginForm } from "@thecb/components";
import * as Nested from "../../../../util/Nested";

export const SUBMIT_LOGIN_FORM = "login/SUBMIT_LOGIN_FORM";
export const submitLoginForm = (options = {}) => {
  const { inWallet = false } = options;
  return {
    type: SUBMIT_LOGIN_FORM,
    payload: { inWallet }
  };
};

export const LOGIN_SUCCESS = "login/LOGIN_SUCCESS";
export const loginSuccess = refreshToken => ({
  type: LOGIN_SUCCESS,
  payload: { refreshToken }
});

export const LOGIN_ERROR = "login/LOGIN_ERROR";
export const loginError = error => ({ type: LOGIN_ERROR, payload: { error } });

const initialState = {
  submitting: false,
  error: false
};

export const _reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBMIT_LOGIN_FORM:
      return { ...state, submitting: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false
      };
    case LOGIN_ERROR:
      return {
        ...state,
        submitting: false,
        error: payload
      };
    default:
      return state;
  }
};

export const _mapStateToProps = state => ({ ...state.login, auth: state.auth });
export const _mapDispatchToProps = dispatch => ({
  submitLoginForm: () => dispatch(submitLoginForm())
});

export const ALERT_BAR_ACTION = "login/ALERT_BAR_ACTION";
export const loginAlertBarAction = Nested.nestedAction(ALERT_BAR_ACTION);

export const LOGIN_FORM_ACTION = "login/LOGIN_FORM_ACTION";
export const loginFormAction = Nested.nestedAction(LOGIN_FORM_ACTION);

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    forms: {
      loginForm: {
        ...LoginForm,
        actionType: LOGIN_FORM_ACTION
      }
    },
    alertBars: {
      loginAlertBar: {
        ...AlertBarState,
        actionType: ALERT_BAR_ACTION
      }
    }
  },
  "login"
);

const reducer = (state, action) => nestedReducer(state, action);

export { reducer, mapStateToProps, mapDispatchToProps, actions };
