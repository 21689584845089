import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Cluster,
  Switcher,
  Heading,
  Text,
  Title,
  ButtonWithLink,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { CHARADE_GREY } from "../../../../constants/colors";
import RichTextField from "../rich-text-field";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import { getCallToActionInfo } from "../../../../util/dataAdapters";

const NewsListBlock = ({
  newsListTitle,
  themeValues,
  newsItems,
  blockPadding = "40px 0",
  title = newsListTitle ? newsListTitle : "Featured News"
}) => {
  const { isMobile } = useContext(ThemeContext);

  const renderNewsItemTitle = (newsItemHeadline, ctaUrl) => (
    <Box padding="0">
      {ctaUrl ? (
        <>
          <Title as="h4" aria-label={newsItemHeadline} />
          <ButtonWithLink
            url={ctaUrl}
            contentOverride
            variant="smallGhost"
            extraStyles="margin: 0; text-align: left;"
          >
            <Cluster justify="space-between" nowrap align="center">
              <Text
                variant="pXL"
                extraStyles="margin:0;"
                color={themeValues.lightAccentText}
                weight={FONT_WEIGHT_SEMIBOLD}
              >
                {newsItemHeadline}
              </Text>
              <Box padding={0}>
                <RightArrowIcon color={themeValues.lightAccentText} />
              </Box>
            </Cluster>
          </ButtonWithLink>
        </>
      ) : (
        <Text
          as="h4"
          variant="pXL"
          color={CHARADE_GREY}
          extraStyles="margin:0;"
        >
          {newsItemHeadline}
        </Text>
      )}
    </Box>
  );

  return (
    <Box
      minWidth="100%"
      maxWidth="100%"
      minHeight="500px"
      padding={blockPadding}
    >
      <Stack childGap="20px">
        <Box padding="0">
          <Stack childGap="1rem">
            <Box
              padding="0"
              minHeight="5px"
              width={isMobile ? "24px" : "50px"}
              borderSize={isMobile ? "3px" : "6px"}
              borderColor={themeValues.lightAccentText}
              borderWidthOverride={isMobile ? "0 0 3px 0" : "0 0 6px 0"}
              key="news-list-accent"
            />
            <Heading
              variant="h3"
              color={themeValues.darkText}
              key="heading"
              extraStyles={`margin-top: 16px;`}
              weight={FONT_WEIGHT_SEMIBOLD}
            >
              {title}
            </Heading>
          </Stack>
        </Box>
        <Box padding="0">
          <Box
            padding="20px 0 0 0"
            background="transparent"
            borderRadius="4px"
            minHeight="100%"
          >
            <Stack childGap="2rem" fullHeight>
              {newsItems.map(
                (
                  { headline, description, url, callToActionLink, image },
                  index
                ) => {
                  const { ctaUrl } = getCallToActionInfo(
                    callToActionLink,
                    "",
                    url
                  );
                  return (
                    <Box
                      minWidth="100%"
                      minHeight="248px"
                      boxShadow={`inset 0px 3px 0px 0px ${themeValues.accents},
0px 1px 3px 0px rgba(0, 0, 0, 0.5)`}
                      background="white"
                      borderRadius="4px"
                      padding="3px 0 0 0"
                      key={`news-item-${index}`}
                    >
                      <Switcher
                        breakpoint="924px"
                        childGap="1px"
                        largeChild="2"
                        largeChildSize="1.8"
                      >
                        {image?.url && (
                          <div
                            style={{
                              minHeight: "248px",
                              backgroundImage: `url(${image.url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center"
                            }}
                          />
                        )}
                        <Box
                          minHeight="100%"
                          padding={isMobile ? "24px" : "40px"}
                          background="white"
                        >
                          <Stack childGap="16px">
                            {renderNewsItemTitle(headline, ctaUrl)}
                            <Box
                              padding="0"
                              color={CHARADE_GREY}
                              extraStyles="line-height: 1.5;"
                            >
                              <RichTextField
                                html={description?.html ?? ""}
                                themeValues={themeValues}
                                extraStyles={`*:first-child { margin-top: 0px;};`}
                              />
                            </Box>
                          </Stack>
                        </Box>
                      </Switcher>
                    </Box>
                  );
                }
              )}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default withWindowSize(NewsListBlock);
