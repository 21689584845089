// Comments assume desktop base font size of 16px, mobile base font size of 14px

const fontSize = {
  large: "1.5rem", // 24px (at base font size of 16px)
  small: "1.25rem" // 20px
};

const fontFamily = {
  large: "Public Sans",
  small: "Public Sans"
};

const mobileFontSize = {
  large: "1.5rem", // 21px (at base font size of 14px)
  small: "1.2142rem" // 17px
};

export const fallbackValues = {
  fontFamily,
  fontSize
};

export const mobileFallbackValues = {
  fontFamily,
  fontSize: mobileFontSize
};

export const MOBILE_BREAKPOINT = 768;
