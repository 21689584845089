import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Loading } from "@thecb/components";

const OktaLogin = ({ oktaLoginSuccess }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const after = params.get("after") ?? "/";

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      oktaAuth.setOriginalUri(`${location.pathname}?after=${after}`);
      oktaAuth.signInWithRedirect();
    } else if (authState && authState?.accessToken?.accessToken) {
      oktaLoginSuccess(authState?.accessToken?.accessToken);
      navigate(after);
    }
  }, [oktaAuth, authState]);

  return <Loading />;
};

export default OktaLogin;
