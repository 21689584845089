import { useEffect } from "react";

function useConditionallyAddValidator(
  condition,
  validatorFn,
  addValidator,
  removeValidator
) {
  useEffect(() => {
    if (condition) {
      addValidator(validatorFn());
    }
    return () => {
      // Remove validator when component unmounts
      removeValidator(validatorFn());
    };
  }, [condition, addValidator, removeValidator]);
}

export default useConditionallyAddValidator;
