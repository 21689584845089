import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Cluster,
  Heading,
  Paragraph,
  ButtonWithLink,
  withWindowSize
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { getCallToActionInfo } from "../../../../util/dataAdapters";

const TaskBlock = ({
  themeValues,
  headline,
  text,
  callToAction,
  url,
  callToActionLink
}) => {
  const { isMobile } = useContext(ThemeContext);

  const { ctaUrl, ctaText } = getCallToActionInfo(
    callToActionLink,
    callToAction,
    url
  );

  return (
    <Box padding="0">
      <Box
        padding={isMobile ? "16px" : "0"}
        boxShadow={`inset 0px 3px 0px 0px ${themeValues.accents},
0px 2px 4px 0px rgba(41, 42, 51, 0.4);`}
        borderRadius="4px"
        background="#ffffff"
        maxWidth="100%"
      >
        <Stack childGap="1.5rem">
          <Heading
            variant="h3"
            color={themeValues.darkText}
            weight={FONT_WEIGHT_SEMIBOLD}
          >
            {headline}
          </Heading>
          <Paragraph color={themeValues.darkText}>{text}</Paragraph>
          <Box padding="0" minWidth="100%">
            <Cluster justify="flex-end" align="center">
              {ctaUrl && (
                <ButtonWithLink
                  variant="secondary"
                  url={ctaUrl}
                  text={ctaText}
                />
              )}
            </Cluster>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default withWindowSize(TaskBlock);
