/**
 * A helper function to access environment variables at runtime.
 *
 * When running in k8s, the script `/app/setEnvConstants.sh` is invoked on
 * startup to set overwrite the values in `/app/envConstants.js`. `/app/envConstants.js`
 * is loaded in the head of `/app/index.html` in order to set the values on the window
 * object, allowing access.
 *
 * When running locally, the invocation of `/app/envConstants.js` from the `/app/index.html`
 * will fail, falling back to the localhost environment variables available on `process.env`.
 * The environment variable can be set using `direnv` or similar. There is an example `direnv`
 * file that can be copied from `/.envrc.example` to `/.envrc`.
 *
 * If neither value exists, the default value is used.
 *
 * @param {string} ENV_VARIABLE - The environment variable on the host machine
 * @param {string} defaultValue - Default fallback value
 * @returns {string|undefined}
 */
const getDynamicEnvVariable = (ENV_VARIABLE, defaultValue = undefined) =>
  window?.envConstants[ENV_VARIABLE] ||
  process.env[ENV_VARIABLE] ||
  defaultValue;

export default getDynamicEnvVariable;
