import React from "react";

const ShortageIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#D11053" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 9.83331C11.3333 9.55717 11.5572 9.33331 11.8333 9.33331H20.1667C20.4428 9.33331 20.6667 9.55717 20.6667 9.83331V10.8333C20.6667 11.1095 20.4428 11.3333 20.1667 11.3333H17V21.3709L20.8319 17.539C21.0272 17.3437 21.3437 17.3437 21.539 17.539L22.2461 18.2461C22.4414 18.4414 22.4414 18.7579 22.2461 18.9532L16.3535 24.8458C16.1583 25.041 15.8417 25.041 15.6464 24.8458L9.75388 18.9532C9.55862 18.7579 9.55862 18.4414 9.75388 18.2461L10.461 17.539C10.6563 17.3437 10.9728 17.3437 11.1681 17.539L15 21.3709V11.3333H11.8333C11.5572 11.3333 11.3333 11.1095 11.3333 10.8333V9.83331Z"
      fill="white"
    />
  </svg>
);

export default ShortageIcon;
