import React from "react";
import Modal from "../modal";
import { Box, Motion, Stack } from "../../atoms/layouts";
import Paragraph from "../../atoms/paragraph";

const IdleModal = ({
  modalOpen,
  toggleModal,
  timeLeftToLogout = 0,
  totalLogoutTime = 30,
  logout,
  cancelLogout,
  useCheckoutContent
}) => {
  const percent =
    (totalLogoutTime - timeLeftToLogout) * (100 / totalLogoutTime);
  const transition = {
    ease: "easeInOut",
    duration: totalLogoutTime
  };
  const variants = {
    enter: {
      x: "-100%",
      backgroundColor: "#317D4F"
    },
    animate: {
      x: ["-100%", `${percent}%`],
      backgroundColor: ["#317D4F", "#93A441", "#F4CB32", "#E36E43", "#D11053"],
      times: [0, 0.4, 0.5, 0.6, 0.8],
      transition
    }
  };

  const continueText = useCheckoutContent ? "Continue" : "Stay Logged In";

  const cancelText = useCheckoutContent ? "End Session" : "Log Off";

  const paragraphText = useCheckoutContent
    ? `Are you still there? Your payment will be cancelled in ${timeLeftToLogout} seconds.`
    : `Are you still there? For your security, you will be logged out in ${timeLeftToLogout} seconds.`;

  const modalBody = (
    <Box padding="0">
      <Stack>
        <Paragraph>{paragraphText}</Paragraph>
        <Motion>
          <Box
            padding="0 0 1px 0"
            minHeight="10px"
            width="100%"
            borderRadius="6px"
            extraStyles="position: relative; overflow: hidden;"
          >
            <Motion
              padding="0 0 1px 0"
              height="10px"
              width="100%"
              borderRadius="6px"
              extraStyles="position: absolute;"
              variants={variants}
              initial="enter"
              animate={modalOpen ? "animate" : "enter"}
              exit="enter"
            />
          </Box>
        </Motion>
      </Stack>
    </Box>
  );
  const modalExtraProps = {
    modalHeaderText: "Your session is about to expire!",
    modalBodyText: modalBody,
    continueButtonText: continueText,
    cancelButtonText: cancelText,
    continueAction: () => {
      cancelLogout();
      toggleModal(false);
    },
    cancelAction: () => {
      logout();
      toggleModal(false);
    }
  };
  return (
    <Modal
      showModal={() => toggleModal(true)}
      hideModal={() => {
        cancelLogout();
        toggleModal(false);
      }}
      modalOpen={modalOpen}
      defaultWrapper={false}
      underlayClickExits={false}
      {...modalExtraProps}
    >
      <Box padding="0" />
    </Modal>
  );
};

export default IdleModal;
