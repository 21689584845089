import React from "react";

const ArrowRightCircleIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ArrowRightCircleIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47199 5.52801C9.27685 5.72315 9.27671 6.03949 9.47167 6.2348L12.6062 9.375H5.5C5.22386 9.375 5 9.59886 5 9.875V10.125C5 10.4011 5.22386 10.625 5.5 10.625H12.6062L9.47167 13.7652C9.27671 13.9605 9.27685 14.2768 9.47199 14.472L9.64645 14.6464C9.84171 14.8417 10.1583 14.8417 10.3536 14.6464L14.6464 10.3536C14.8417 10.1583 14.8417 9.84171 14.6464 9.64645L10.3536 5.35355C10.1583 5.15829 9.84171 5.15829 9.64645 5.35355L9.47199 5.52801Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.47199 5.52801C9.27685 5.72315 9.27671 6.03949 9.47167 6.2348L12.6062 9.375H5.5C5.22386 9.375 5 9.59886 5 9.875V10.125C5 10.4011 5.22386 10.625 5.5 10.625H12.6062L9.47167 13.7652C9.27671 13.9605 9.27685 14.2768 9.47199 14.472L9.64645 14.6464C9.84171 14.8417 10.1583 14.8417 10.3536 14.6464L14.6464 10.3536C14.8417 10.1583 14.8417 9.84171 14.6464 9.64645L10.3536 5.35355C10.1583 5.15829 9.84171 5.15829 9.64645 5.35355L9.47199 5.52801Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="2.5" y="2.5" width="15" height="15" fill="white" />
      </g>
    </svg>
  );
};
export default ArrowRightCircleIconSmall;
