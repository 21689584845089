import React from "react";

const ReversalNeededIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#D11053" />
    <path
      d="M14.4167 14.2222H13.5833C13.4453 14.2222 13.3333 14.1103 13.3333 13.9722V11.6667C13.3333 11.1144 13.781 10.6667 14.3333 10.6667H23C23.5523 10.6667 24 11.1144 24 11.6667V20.3333C24 20.8856 23.5523 21.3333 23 21.3333H14.3333C13.781 21.3333 13.3333 20.8856 13.3333 20.3333V18.0278C13.3333 17.8897 13.4453 17.7778 13.5833 17.7778H14.4167C14.5547 17.7778 14.6667 17.8897 14.6667 18.0278V20H22.6667V11.8518H14.6667V13.9722C14.6667 14.1103 14.5547 14.2222 14.4167 14.2222Z"
      fill="white"
    />
    <path
      d="M9.8706 11.4627L5.51011 15.8232C5.41248 15.9209 5.41248 16.0791 5.51011 16.1768L9.8706 20.5373C9.96823 20.6349 10.1265 20.6349 10.2242 20.5373L10.8134 19.948C10.911 19.8504 10.911 19.6921 10.8134 19.5945L7.39573 16.1768C7.2981 16.0791 7.2981 15.9209 7.39573 15.8232L10.8134 12.4055C10.911 12.3079 10.911 12.1496 10.8134 12.052L10.2242 11.4627C10.1265 11.3651 9.96823 11.3651 9.8706 11.4627Z"
      fill="white"
    />
    <path
      d="M6.66667 15.5833C6.66667 15.4453 6.7786 15.3333 6.91667 15.3333L18.4167 15.3333C18.5547 15.3333 18.6667 15.4453 18.6667 15.5833L18.6667 16.4167C18.6667 16.5547 18.5547 16.6667 18.4167 16.6667L6.91667 16.6667C6.7786 16.6667 6.66667 16.5547 6.66667 16.4167L6.66667 15.5833Z"
      fill="white"
    />
  </svg>
);

export default ReversalNeededIcon;
