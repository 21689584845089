import React, { useEffect } from "react";
import {
  required,
  hasLength,
  matchesField,
  hasNumber,
  hasLowercaseLetter,
  hasUppercaseLetter,
  hasSpecialCharacter
} from "redux-freeform";
import PasswordRequirements from "../../atoms/password-requirements";
import { Box } from "../../atoms/layouts";
import { FormInput, FormInputColumn } from "../../atoms/form-layouts";
import { noop } from "../../../util/general";

const ResetPasswordForm = ({
  handleSubmit = noop,
  clearOnDismount,
  fields,
  actions,
  showErrors,
  isMobile
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const passwordErrorMessages = {
    [required.error]: "Password is required",
    [hasLength.error]: "Password must have at least 8 characters",
    [hasNumber.error]: "Password must contain at least one number",
    [hasLowercaseLetter.error]:
      "Password must contain at least one lowercase letter",
    [hasUppercaseLetter.error]:
      "Password must contain at least one uppercase letter",
    [hasSpecialCharacter.error]:
      "Password must contain at least one special character (!@#$%^&*.?)"
  };
  const confirmPasswordErrorMessages = {
    [matchesField.error]: "Confirm password must match password"
  };

  return (
    <FormInputColumn role="form" aria-label="Reset password">
      <FormInput
        labelTextWhenNoError="Password"
        errorMessages={passwordErrorMessages}
        field={fields.password}
        fieldActions={actions.fields.password}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        type="password"
        autocompleteValue="new-password"
      />
      <FormInput
        labelTextWhenNoError="Confirm password"
        errorMessages={confirmPasswordErrorMessages}
        field={fields.confirmPassword}
        fieldActions={actions.fields.confirmPassword}
        showErrors={showErrors}
        onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
        type="password"
        autocompleteValue="new-password"
      />
      <Box padding="0">
        <PasswordRequirements password={fields.password} isMobile={isMobile} />
      </Box>
    </FormInputColumn>
  );
};

export default ResetPasswordForm;
