import React from "react";

const ChargebackReversalIconSmall = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ChargebackReversalIconSmall-${iconIndex}`;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="14"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16669 6.83325C4.16669 6.28097 4.6144 5.83325 5.16669 5.83325H13.1667C13.719 5.83325 14.1667 6.28097 14.1667 6.83325V8.33325V9.99992V13.1666C14.1667 13.7189 13.719 14.1666 13.1667 14.1666H5.16669C4.6144 14.1666 4.16669 13.7189 4.16669 13.1666V9.99992V8.33325V6.83325ZM13.5417 6.5277V8.33325H4.79169V8.26381V6.5277H13.5417ZM4.79169 13.4721V9.99992H13.5417V13.4721H4.79169ZM15 11.2928C15 11.7383 15.5386 11.9613 15.8536 11.6464L17.1465 10.3535C17.3417 10.1582 17.3417 9.84163 17.1465 9.64636L15.8536 8.35347C15.5386 8.03849 15 8.26157 15 8.70703V11.2928ZM10.9896 11.6666C10.7595 11.6666 10.5729 11.8531 10.5729 12.0833C10.5729 12.3134 10.7595 12.4999 10.9896 12.4999H12.0313C12.2614 12.4999 12.4479 12.3134 12.4479 12.0833C12.4479 11.8531 12.2614 11.6666 12.0313 11.6666H10.9896Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect x="2.5" y="2.5" width="15" height="15" fill="white" />
      </g>
    </svg>
  );
};

export default ChargebackReversalIconSmall;
