import React, { Fragment, memo } from "react";
import styled from "styled-components";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./WelcomeModule.theme";
import Heading from "../../atoms/heading";
import { Box, Cluster } from "../../atoms/layouts";

const WelcomeImage = styled.img`
  width: auto;
  height: 215px;
`;

const WelcomeModule = ({ heading, isMobile, themeValues }) => {
  const welcomeImage =
    "https://cb-public-assets.s3-us-west-2.amazonaws.com/profile-assets/profile-welcome-image.png";

  return (
    <Fragment>
      <Box padding="0">
        {isMobile && (
          <Box padding="0" background={themeValues.imageBackgroundColor}>
            <Cluster justify="center">
              <WelcomeImage src={welcomeImage} />
            </Cluster>
          </Box>
        )}
        <Box
          padding={isMobile ? "0.875rem 1rem" : "0.875rem 1.5rem"}
          background={themeValues.headerBackgroundColor}
        >
          <Heading
            variant="h6"
            weight={themeValues.fontWeight}
            color={themeValues.fontColor}
            textAlign={themeValues.textAlign}
            as="h6"
          >
            {heading}
          </Heading>
        </Box>
        {!isMobile && (
          <Box padding="0" background={themeValues.imageBackgroundColor}>
            <Cluster justify="center" align="flex-end">
              <WelcomeImage src={welcomeImage} />
            </Cluster>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default memo(
  themeComponent(WelcomeModule, "WelcomeModule", fallbackValues)
);
