import React from "react";

const StatusUnknownIcon = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-3203"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons"
        transform="translate(-64.000000, -736.000000)"
        fill="#6D717E"
      >
        <path
          d="M80,736 C88.836556,736 96,743.163444 96,752 C96,760.836556 88.836556,768 80,768 C71.163444,768 64,760.836556 64,752 C64,743.163444 71.163444,736 80,736 Z M80.85,757 L78.85,757 L78.85,759 L80.85,759 L80.85,757 Z M80.1460007,745 C78.0670004,745 76.4650001,746.017 75.7,747.799 L75.7,747.799 L77.5900003,748.294001 L77.6622133,748.125985 C78.1059448,747.191195 79.1250006,746.557 80.1280007,746.557 C81.2800009,746.557 82.099001,747.187 82.099001,748.195001 C82.099001,750.301001 78.9670005,751.093001 78.9670005,754.459002 L78.9670005,754.459002 L80.7670008,754.459002 L80.7792965,754.169347 C81.0061162,751.541289 84.2950014,750.971966 84.2950014,748.087001 C84.2950014,746.341 82.6480011,745 80.1460007,745 Z"
          id="status-icon---default-/-unknown"
        ></path>
      </g>
    </g>
  </svg>
);

export default StatusUnknownIcon;
