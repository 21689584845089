import { useMemo } from "react";
import {
  getRelatedContentType,
  RelatedContentType
} from "../../../util/dataAdapters";
import { isExternalLink } from "../../../util/general";

const EXTERNAL_LINK_TYPE_LABEL = "External";
const INTERNAL_LINK_TYPE_LABEL = "Link";
const LANDING_PAGE_TYPE_LABEL = "Landing";

export default function useRelatedContent(
  parentPage = {},
  childPagesAndLinks = [],
  otherRelatedContent = []
) {
  const buildCardFromItem = relatedContentItem => {
    const {
      id,
      title,
      text,
      actionType,
      subjectType,
      sectionType,
      subtitle,
      description,
      subtitleDescription,
      quickLinkText,
      slug,
      externalUrl,
      tags
    } = relatedContentItem;

    let type = "";
    if (externalUrl) {
      type = isExternalLink(externalUrl)
        ? EXTERNAL_LINK_TYPE_LABEL
        : INTERNAL_LINK_TYPE_LABEL;
    } else {
      // must be a slug
      type =
        actionType || subjectType || sectionType || LANDING_PAGE_TYPE_LABEL;
    }

    return {
      id,
      category: getRelatedContentType(relatedContentItem),
      type,
      title: title || text || "",
      description: subtitle || description || subtitleDescription || "",
      linkText: quickLinkText || "Learn More",
      url: Array.isArray(slug) ? `/${slug[0]?.slug}` : externalUrl || "/",
      tags: tags || []
    };
  };

  return useMemo(() => {
    const buildRelatedContentGridCards = relatedContentItems => {
      relatedContentItems.forEach(relatedContentItem => {
        let itemType = getRelatedContentType(relatedContentItem);
        if (itemType === RelatedContentType.Tags) {
          const {
            id,
            subjectPage,
            landingPage,
            actionPage,
            slug
          } = relatedContentItem;
          if (Array.isArray(slug)) {
            relatedItemCardList[id] = buildCardFromItem(relatedContentItem);
          }
          if (Array.isArray(subjectPage)) {
            subjectPage.forEach(subjectPageItem => {
              relatedItemCardList[subjectPageItem.id] = buildCardFromItem(
                subjectPageItem
              );
            });
          }
          if (Array.isArray(landingPage)) {
            landingPage.forEach(landingPageItem => {
              relatedItemCardList[landingPageItem.id] = buildCardFromItem(
                landingPageItem
              );
            });
          }
          if (Array.isArray(actionPage)) {
            actionPage.forEach(actionPageItem => {
              relatedItemCardList[actionPageItem.id] = buildCardFromItem(
                actionPageItem
              );
            });
          }
        } else if (itemType === RelatedContentType.LinkList) {
          relatedContentItem.links.forEach(linkItem => {
            relatedItemCardList[linkItem.id] = buildCardFromItem(linkItem);
          });
        } else {
          relatedItemCardList[relatedContentItem.id] = buildCardFromItem(
            relatedContentItem
          );
        }
      });
    };

    const relatedItemCardList = {};
    if (parentPage) {
      relatedItemCardList[parentPage.id] = buildCardFromItem(parentPage);
    }
    buildRelatedContentGridCards([
      ...childPagesAndLinks,
      ...otherRelatedContent
    ]);
    return Object.values(relatedItemCardList);
  }, [parentPage, childPagesAndLinks, otherRelatedContent]);
}
