import React from "react";
import { fallbackValues } from "./RadioButton.theme";
import { Motion } from "../../../atoms/layouts";
import styled from "styled-components";
import { themeComponent } from "../../../../util/themeUtils";

const HiddenRadioButton = styled.input`
  opacity: 0;
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

const RadioButton = ({
  radioOn = false,
  radioFocused = false,
  toggleRadio,
  name,
  disabled = false,
  ariaDescribedBy,
  themeValues,
  ariaLabelledBy = "",
  ariaLabel = null,
  isRequired = false
}) => {
  const buttonBorder = {
    onFocused: {
      borderColor: themeValues.activeColor,
      outline: `3px solid ${themeValues.activeColor}`,
      outlineOffset: "2px"
    },
    offFocused: {
      borderColor: themeValues.activeColor,
      outline: `3px solid ${themeValues.activeColor}`,
      outlineOffset: "2px"
    },
    on: {
      borderColor: themeValues.activeColor,
      outline: "0"
    },
    off: {
      borderColor: themeValues.inactiveColor,
      outline: "0"
    }
  };

  const buttonCenter = {
    on: {
      backgroundColor: themeValues.activeColor,
      height: "16px",
      width: "16px"
    },
    onFocused: {
      backgroundColor: themeValues.activeColor,
      height: "16px",
      width: "16px"
    },
    offFocused: {
      backgroundColor: "transparent",
      height: "0px",
      width: "0px"
    },
    off: {
      backgroundColor: "transparent",
      height: "0px",
      width: "0px"
    }
  };
  const extraProps = {};
  if (ariaLabelledBy && ariaLabelledBy.length) {
    extraProps["aria-labelledby"] = ariaLabelledBy;
  } else if (ariaLabel && ariaLabel !== null) {
    extraProps["aria-label"] = ariaLabel;
  }

  return (
    <Motion
      position="relative"
      padding="0"
      initial="off"
      animate={
        radioOn
          ? radioFocused
            ? "onFocused"
            : "on"
          : radioFocused
          ? "offFocused"
          : "off"
      }
    >
      <HiddenRadioButton
        type="radio"
        id={`radio-${name}`}
        disabled={disabled}
        onClick={toggleRadio}
        aria-describedby={ariaDescribedBy}
        tabIndex="-1"
        required={isRequired}
        {...extraProps}
      />
      <Motion
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="12px"
        margin="6px 14px 6px 6px"
        height="24px"
        width="24px"
        variants={buttonBorder}
        display="flex"
        extraStyles={`justify-content: center; align-items: center;`}
      >
        <Motion variants={buttonCenter} borderRadius="8px" />
      </Motion>
    </Motion>
  );
};

export default themeComponent(RadioButton, "RadioButton", fallbackValues);
