import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../../content/Content.theme";
import { themeComponent } from "../../../util/themeUtils";
import { withWindowSize } from "@thecb/components";
import {
  Box,
  ButtonWithLink,
  Center,
  Cluster,
  Cover,
  Stack,
  Sidebar,
  Text,
  Title,
  constants
} from "@thecb/components";
import useRelatedContent from "../../content/hooks/useRelatedContent";
import RelatedContentSection from "../../content/related-content-section";
import { MAX_CONTENT_WIDTH } from "../../../constants/style_constants";

const CMSActionPage = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  contentSpacing = "64px",
  maxContentWidth = MAX_CONTENT_WIDTH,
  fullWidthMobile = true,
  content,
  mainContent,
  mainContentMinWidth = "47.5rem",
  sidebarContent = null,
  sidebarTargetWidth = "24rem",
  sidebarContentGap = "80px",
  secondaryContent,
  secondaryContentGap = "1.5rem",
  callToAction,
  themeValues,
  pageTitle,
  numberOfBlocks,
  showRelatedContent,
  parentPage,
  otherRelatedContent,
  subtitle
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const relatedContentCards = useRelatedContent(
    parentPage,
    [],
    otherRelatedContent
  );

  const renderPageTitle = () => (
    <>
      <Box
        minHeight="5px"
        width={"50px"}
        borderSize="5px"
        borderColor={themeValues.lightAccentText}
        borderWidthOverride={"0 0 5px 0"}
        padding="0"
      />
      <Stack
        childGap={0}
        direction={isMobile ? "column" : "row"}
        justify={isMobile ? "flex-start" : "space-between"}
        align="center"
      >
        <Box padding={0} maxWidth="810px">
          <Stack childGap={0}>
            <Title
              color={themeValues.darkText}
              weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
              key="heading"
              margin={`8px 0 0`}
              extraStyles={
                isMobile
                  ? `line-height: 150%; font-size: 28px;`
                  : `line-height: 150%; font-size: 40px;`
              }
            >
              {pageTitle}
            </Title>
            <Text as="h2" variant="pXL" color={constants.colors.STORM_GREY}>
              {subtitle}
            </Text>
          </Stack>
        </Box>
        {callToAction.label && callToAction.link && (
          <Stack justify="center">
            <ButtonWithLink
              url={callToAction.link}
              extraStyles={`padding: 0; border-radius: 48px; text-align: center; 
            ${isMobile && "margin: 56px auto 12px;"}
            ${isMobile && "width: 100%;"}`}
              contentOverride
            >
              <Box padding={isMobile ? "16px" : "24px 80px"} minWidth="300px">
                <Text
                  variant="pL"
                  weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                  color={constants.colors.WHITE}
                >
                  {callToAction.label.substring(0, 35)}
                </Text>
              </Box>
            </ButtonWithLink>
          </Stack>
        )}
      </Stack>
    </>
  );

  const PageStack = (
    <Box padding="0" minWidth="100%" background={constants.colors.WHITE}>
      <Cover minHeight="100vh" fillCenter>
        {header ? header : <Box padding="0" key="header" />}
        <Box padding="0" minWidth="100%" role="main" key="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box
            padding={isMobile ? "48px 16px 0" : "80px 24px 0 0"}
            minWidth="100%"
            minHeight={
              isMobile || numberOfBlocks === 1 ? `calc(100vh - 250px);` : `100%`
            }
          >
            <Center
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            >
              <Stack childGap="0">
                <Box
                  padding={isMobile ? "0 0 24px 0" : "0 0 48px 0"}
                  borderSize="1px"
                  borderColor="#cfd4de"
                  borderWidthOverride="0 0 1px 0"
                  key="page-container"
                >
                  {renderPageTitle()}
                </Box>
                <Cluster justify="center" extraStyles="width:100%;">
                  <Box
                    maxWidth={
                      isMobile && fullWidthMobile ? "100%" : maxContentWidth
                    }
                    width="100%"
                    padding={0}
                  >
                    <Cover minHeight="100%" singleChild fillCenter>
                      <>
                        <Stack childGap={isMobile ? "40px" : contentSpacing}>
                          {content}
                        </Stack>
                        {showRelatedContent && (
                          <Box
                            padding={0}
                            width={maxContentWidth}
                            extraStyles={`margin-left: ${
                              isMobile ? 0 : "1.5rem"
                            };`}
                          >
                            <RelatedContentSection
                              relatedContentCards={relatedContentCards}
                              maxContentWidth={maxContentWidth}
                              mobileContentPadding={0}
                              themeValues={themeValues}
                            />
                          </Box>
                        )}
                      </>
                    </Cover>
                  </Box>
                </Cluster>
              </Stack>
            </Center>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );

  const PageStackSidebar = (
    <Box padding="0" minWidth="100%" background={constants.colors.WHITE}>
      <Cover minHeight="100vh">
        {header ? header : <Box padding="0" />}
        <Box padding="0" minWidth="100%" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box
            padding={isMobile ? "56px 16px 0" : "80px 24px 0 0"}
            minHeight={`calc(100vh - 300px);`}
            background="white"
          >
            <Center
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            >
              <Stack childGap="0">
                <Box
                  padding={isMobile ? "0 0 24px 0" : "0 0 48px 0"}
                  borderSize="1px"
                  borderColor="#cfd4de"
                  borderWidthOverride="0 0 1px 0"
                  key="page-container"
                >
                  {renderPageTitle()}
                </Box>
                <Cover minHeight="100%" singleChild fillCenter>
                  <Stack childGap={secondaryContentGap}>
                    <Fragment key="secondary-content">
                      <Sidebar
                        width={sidebarTargetWidth}
                        childGap={isMobile ? "0" : sidebarContentGap}
                        contentMinWidth={mainContentMinWidth}
                        key="content-sidebar"
                        sidebarOnRight
                        fullHeight
                      >
                        <Box
                          key="content-stack"
                          padding="0"
                          extraStyles={`> div > div:last-child { padding-bottom: 0; }`}
                        >
                          {mainContent}
                        </Box>
                        <Box
                          padding="0"
                          borderSize="1px"
                          borderColor="#cfd4de"
                          borderWidthOverride={isMobile ? "1px 0 0 0" : "0"}
                        >
                          <Box
                            key="sidebar-content"
                            background="white"
                            padding="40px 0 0 0"
                          >
                            <Stack childGap="2.5rem">{sidebarContent}</Stack>
                          </Box>
                        </Box>
                      </Sidebar>
                    </Fragment>
                  </Stack>
                </Cover>
              </Stack>
            </Center>
            <Box padding="0" minWidth="100%">
              <Center
                maxWidth={
                  isMobile && fullWidthMobile ? "100%" : maxContentWidth
                }
                intrinsic={!isMobile}
              >
                <Box padding="64px 0 0 0" minWidth="100%">
                  <Stack childGap={isMobile ? "40px" : "64px"}>
                    {secondaryContent}
                    {showRelatedContent && (
                      <RelatedContentSection
                        relatedContentCards={relatedContentCards}
                        maxContentWidth={maxContentWidth}
                        mobileContentPadding={0}
                        themeValues={themeValues}
                      />
                    )}
                  </Stack>
                </Box>
              </Center>
            </Box>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );

  return sidebarContent !== null ? PageStackSidebar : PageStack;
};

export default withWindowSize(
  themeComponent(CMSActionPage, "Content", fallbackValues, "default")
);
