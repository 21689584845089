import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { get, compose, prop } from "partial.lenses";
import { createAddress, updateAddress } from "../../graphql/Queries";
import { push } from "connected-react-router";
import { combineEpics } from "redux-observable";
import { util } from "@thecb/components";
import { format } from "formatted-input";
import { addAlert, clearAlerts } from "/components/alert-bar/AlertBar.state";
import {
  ADDRESS_SETTING,
  SUBMIT_OPERATIONS,
  SUBMIT_CHANGE,
  upsertAddress,
  requestSuccess,
  requestFailure,
  addAddressAlertBarAction
} from "../../Profile.state";
import {
  getAddressForm,
  getConstituentID,
  getCustomerManagement
} from "../../Profile.selectors";
import { isInCustomerManagement, configureEndpoint } from "/util/router-utils";

const _zipFormat = format(util.formats.zipFormat);
const CHECKOUT_KEY = "checkout";

const _formFieldRaw = fieldName =>
  compose(getAddressForm, prop(fieldName), prop("rawValue"));

const getCreateAddressArgs = (key, selectors, state) => {
  const zip = get(_formFieldRaw("zip"), state[key]);
  return {
    endpoint: selectors.getGraphqlServiceEndpoint(state),
    clientSlug: selectors.getClientSlug(state),
    authJwt: isInCustomerManagement
      ? selectors.getAdminAccessToken(state)
      : selectors.getAccessToken(state),
    constituentID: getConstituentID(state),
    isPrimary: false,
    city: get(_formFieldRaw("city"), state[key]),
    country: get(_formFieldRaw("country"), state[key]),
    stateProvince: get(_formFieldRaw("stateProvince"), state[key]),
    street1: get(_formFieldRaw("street1"), state[key]),
    street2: get(_formFieldRaw("street2"), state[key]),
    zip:
      get(_formFieldRaw("country"), state[key]) === "US"
        ? _zipFormat(zip.toUpperCase())
        : zip.toUpperCase()
  };
};

const getUpdateAddressArgs = (key, selectors, state, id) => {
  const zip = get(_formFieldRaw("zip"), state[key]);
  return {
    endpoint: selectors.getGraphqlServiceEndpoint(state),
    clientSlug: selectors.getClientSlug(state),
    authJwt: isInCustomerManagement
      ? selectors.getAdminAccessToken(state)
      : selectors.getAccessToken(state),
    addressId: id,
    city: get(_formFieldRaw("city"), state[key]),
    country: get(_formFieldRaw("country"), state[key]),
    stateProvince: get(_formFieldRaw("stateProvince"), state[key]),
    street1: get(_formFieldRaw("street1"), state[key]),
    street2: get(_formFieldRaw("street2"), state[key]),
    zip:
      get(_formFieldRaw("country"), state[key]) === "US"
        ? _zipFormat(zip.toUpperCase())
        : zip.toUpperCase()
  };
};

export const addAddressEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === ADDRESS_SETTING && operation === SUBMIT_OPERATIONS.ADD
    ),
    RxOp.flatMap(action =>
      Rx.from(
        createAddress(
          action.payload.inWallet
            ? getCreateAddressArgs(CHECKOUT_KEY, selectors, state$.value)
            : getCreateAddressArgs(profileKey, selectors, state$.value)
        )
      ).pipe(
        RxOp.mergeMap(result =>
          Rx.of(
            addAddressAlertBarAction(clearAlerts()),
            requestSuccess(ADDRESS_SETTING, SUBMIT_OPERATIONS.ADD),
            upsertAddress(result.createAddress),
            !action.payload.inWallet &&
              push(
                configureEndpoint(
                  getCustomerManagement(state$.value[profileKey]),
                  "/profile/wallet"
                )
              )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addAddressAlertBarAction(clearAlerts()),
            addAddressAlertBarAction(
              addAlert({
                heading: "Unable to Create Address",
                text:
                  "We are sorry, we were unable to add your address at this time. Please try again later.",
                variant: "error"
              })
            ),
            requestFailure(ADDRESS_SETTING, err)
          )
        )
      )
    )
  );

const updateAddressEpic = (profileKey, selectors) => (action$, state$) =>
  action$.ofType(SUBMIT_CHANGE).pipe(
    RxOp.filter(
      ({ payload: { settingName, operation } }) =>
        settingName === ADDRESS_SETTING &&
        operation === SUBMIT_OPERATIONS.UPDATE
    ),
    RxOp.flatMap(({ payload: { id } }) =>
      Rx.from(
        updateAddress(
          getUpdateAddressArgs(profileKey, selectors, state$.value, id)
        )
      ).pipe(
        RxOp.mergeMap(result =>
          Rx.of(
            addAddressAlertBarAction(clearAlerts()),
            requestSuccess(ADDRESS_SETTING, SUBMIT_OPERATIONS.UPDATE),
            upsertAddress(result.updateAddress),
            push(
              configureEndpoint(
                getCustomerManagement(state$.value[profileKey]),
                "/profile/wallet"
              )
            )
          )
        ),
        RxOp.catchError(err =>
          Rx.of(
            addAddressAlertBarAction(clearAlerts()),
            addAddressAlertBarAction(
              addAlert({
                heading: "Unable to Update Address",
                text:
                  "We are sorry, we were unable to update your address at this time. Please try again later.",
                variant: "error"
              })
            ),
            requestFailure(ADDRESS_SETTING, err)
          )
        )
      )
    )
  );

export default (key, selectors) =>
  combineEpics(
    addAddressEpic(key, selectors),
    updateAddressEpic(key, selectors)
  );
