import React from "react";

const SearchIconBig = () => (
  <svg
    width="120px"
    height="94px"
    viewBox="0 0 120 94"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Nav-/-Search---MWW"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="MWW-styled---Search---No-Results"
        transform="translate(-686.000000, -542.000000)"
      >
        <g
          id="Search-Big-Group-2"
          transform="translate(568.000000, 543.000000)"
        >
          <g
            id="illustration---no-results"
            transform="translate(119.000000, 0.000000)"
          >
            <path
              d="M75.9654,23.2472 L0.5004,23.2472 L0.5004,4.2492 C0.5004,2.1792 2.1784,0.5002 4.2504,0.5002 L72.2154,0.5002 C74.2864,0.5002 75.9654,2.1792 75.9654,4.2492 L75.9654,23.2472 Z"
              id="Search-Big-Stroke-1"
              stroke="#CACED8"
              strokeWidth="3"
            ></path>
            <polygon
              id="Search-Big-Stroke-3"
              stroke="#CACED8"
              strokeWidth="3"
              points="0.5 45.994 75.965 45.994 75.965 23.247 0.5 23.247"
            ></polygon>
            <line
              x1="10.3204"
              y1="34.6207"
              x2="55.6964"
              y2="34.6207"
              id="Search-Big-Stroke-5"
              stroke="#CACED8"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
            <line
              x1="10.3204"
              y1="11.8737"
              x2="66.1444"
              y2="11.8737"
              id="Search-Big-Stroke-6"
              stroke="#CACED8"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
            <polygon
              id="Search-Big-Stroke-7"
              stroke="#CACED8"
              strokeWidth="3"
              points="0.5 68.741 75.965 68.741 75.965 45.994 0.5 45.994"
            ></polygon>
            <path
              d="M72.2154,91.4879 L4.2504,91.4879 C2.1784,91.4879 0.5004,89.8089 0.5004,87.7379 L0.5004,68.7409 L75.9654,68.7409 L75.9654,87.7379 C75.9654,89.8089 74.2864,91.4879 72.2154,91.4879 Z"
              id="Search-Big-Stroke-8"
              stroke="#CACED8"
              strokeWidth="3"
            ></path>
            <line
              x1="10.3204"
              y1="80.1149"
              x2="64.2734"
              y2="80.1149"
              id="Search-Big-Stroke-9"
              stroke="#CACED8"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
            <line
              x1="10.3204"
              y1="57.3673"
              x2="38.2324"
              y2="57.3673"
              id="Search-Big-Stroke-10"
              stroke="#CACED8"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
            <path
              d="M82.0133,15.2975 C91.8113,25.0955 91.8113,40.9805 82.0133,50.7785 C72.2153,60.5765 56.3303,60.5765 46.5323,50.7785 C36.7343,40.9805 36.7343,25.0955 46.5323,15.2975 C56.3303,5.4995 72.2153,5.4995 82.0133,15.2975 Z"
              id="Search-Big-Stroke-11"
              stroke="#6D717E"
              strokeWidth="4.91"
            ></path>
            <line
              x1="82.0133"
              y1="50.7785"
              x2="87.2223"
              y2="55.9875"
              id="Search-Big-Stroke-12"
              stroke="#6D717E"
              strokeWidth="3.928"
            ></line>
            <path
              d="M114.9347,83.6998 L114.9347,83.6998 C112.8477,85.7868 109.4637,85.7868 107.3767,83.6998 L87.2227,63.5458 C85.1357,61.4588 85.1357,58.0748 87.2227,55.9878 C89.3097,53.9008 92.6937,53.9008 94.7807,55.9878 L114.9347,76.1418 C117.0217,78.2288 117.0217,81.6128 114.9347,83.6998 Z"
              id="Search-Big-Stroke-13"
              stroke="#6D717E"
              strokeWidth="3.928"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SearchIconBig;
