import React from "react";

const NotFoundIcon = () => (
  <svg
    width="548px"
    height="308px"
    viewBox="0 0 548 308"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="STOR-2683"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="404-Page" transform="translate(-446.000000, -168.000000)">
        <g
          id="not-found-illustration"
          transform="translate(449.000000, 168.000000)"
        >
          <g
            id="baseline"
            transform="translate(0.483800, 246.050200)"
            stroke="#3B414D"
            strokeLinecap="round"
            strokeWidth="8"
          >
            <line
              x1="463.3123"
              y1="1.326"
              x2="399.5563"
              y2="1.674"
              id="baseline-right---01"
            ></line>
            <line
              x1="512.9402"
              y1="1.326"
              x2="483.1052"
              y2="1.674"
              id="baseline-right---02"
            ></line>
            <line
              x1="143.1111"
              y1="1.326"
              x2="90.7391"
              y2="1.674"
              id="baseline-left---01"
            ></line>
            <line
              x1="71.179"
              y1="1.326"
              x2="0.5162"
              y2="0.9498"
              id="baseline-left---02"
            ></line>
          </g>
          <path
            d="M151.968,73 L391.689,73 C395.538,73 398.658,76.12 398.658,79.968 L398.658,258.366 C398.658,262.214 395.538,265.334 391.689,265.334 L151.968,265.334 C148.119,265.334 145,262.214 145,258.366 L145,79.968 C145,76.12 148.119,73 151.968,73"
            id="browser-background"
            fill="#FEFEFE"
          ></path>
          <g id="binoculars" transform="translate(209.811300, 116.913100)">
            <path
              d="M97.5616,55.749 C112.9566,55.749 125.4356,43.269 125.4356,27.874 C125.4356,12.479 112.9566,0 97.5616,0 C82.1656,0 69.6866,12.479 69.6866,27.874 C69.6866,43.269 82.1656,55.749 97.5616,55.749 Z"
              id="binocular-right"
              stroke="#3B414D"
              strokeWidth="5.575"
              fill="#E4F4FD"
            ></path>
            <g id="binocular-center" transform="translate(49.189100, 8.087300)">
              <path
                d="M2.22044605e-13,3.484 C4.718,1.161 9.085,-2.84217094e-14 13.102,-2.84217094e-14 C17.117,-2.84217094e-14 21.112,1.161 25.087,3.484 L21.2,11.743 L21.603,19.512 L3.484,19.512 L3.854,11.601 L2.22044605e-13,3.484 Z"
                id="Fill-1"
                fill="#3B414D"
              ></path>
              <path
                d="M15.1055,8.7316 C15.1055,10.1936 13.9205,11.3786 12.4595,11.3786 C10.9975,11.3786 9.8115,10.1936 9.8115,8.7316 C9.8115,7.2696 10.9975,6.0846 12.4595,6.0846 C13.9205,6.0846 15.1055,7.2696 15.1055,8.7316"
                id="Fill-4"
                fill="#FEFEFE"
              ></path>
            </g>
            <path
              d="M27.875,55.749 C43.27,55.749 55.749,43.269 55.749,27.874 C55.749,12.479 43.27,0 27.875,0 C12.48,0 -1.13686838e-13,12.479 -1.13686838e-13,27.874 C-1.13686838e-13,43.269 12.48,55.749 27.875,55.749 Z"
              id="binocular-left"
              stroke="#3B414D"
              strokeWidth="5.575"
              fill="#E4F4FD"
            ></path>
            <path
              d="M20.1887,30.6619 C21.3567,27.4039 24.3487,25.0869 27.8547,25.0869 C31.3597,25.0869 34.3517,27.4039 35.5197,30.6619"
              id="eye-left"
              stroke="#3B414D"
              strokeWidth="5.575"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M90.1887,30.6619 C91.3567,27.4039 94.3487,25.0869 97.8547,25.0869 C101.3597,25.0869 104.3517,27.4039 105.5197,30.6619"
              id="eye-right"
              stroke="#3B414D"
              strokeWidth="5.575"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
          <path
            d="M292.191,186 L292.191,186 C292.191,189.803 289.108,192.886 285.305,192.886 L258.885,192.886 C255.083,192.886 252,189.803 252,186"
            id="mouth"
            stroke="#3B414D"
            strokeWidth="5.575"
            strokeLinecap="round"
          ></path>
          <g id="browser-top" transform="translate(145.000000, 73.000000)">
            <polygon
              id="background"
              fill="#E4E6EB"
              points="0 26 254 26 254 0 0 0"
            ></polygon>
            <circle id="dot" fill="#3B414D" cx="13" cy="13" r="3"></circle>
            <circle id="dot" fill="#3B414D" cx="23" cy="13" r="3"></circle>
            <circle id="dot" fill="#3B414D" cx="33" cy="13" r="3"></circle>
            <line
              x1="3"
              y1="26"
              x2="251"
              y2="26"
              id="line"
              stroke="#3B414D"
              strokeWidth="6"
            ></line>
          </g>
          <g
            id="sign-post"
            transform="translate(427.000000, 88.999700)"
            strokeWidth="6"
          >
            <polyline
              id="sign-post---left"
              stroke="#3B414D"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="39.196 47.0005 7.06 50.5815 5.68434189e-14 64.7845 7.06 77.6745 54.679 72.3625 54.679 175.7975 40.451 175.7975"
            ></polyline>
            <polyline
              id="sign-post---right"
              stroke="#3B414D"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="54.6794 54.6792 107.8214 60.9392 114.3504 49.0202 107.8214 35.8872 54.6794 29.6262 54.6794 13.4842"
            ></polyline>
            <path
              d="M62.9412,7.971 C62.9412,12.373 59.3732,15.941 54.9702,15.941 C50.5682,15.941 47.0002,12.373 47.0002,7.971 C47.0002,3.569 50.5682,1.59872116e-14 54.9702,1.59872116e-14 C59.3732,1.59872116e-14 62.9412,3.569 62.9412,7.971"
              id="sign-post---top"
              stroke="#27A9E1"
              fill="#E4F4FD"
            ></path>
          </g>
          <path
            d="M330.327,294.916 C330.327,302.049 304.286,307.832 272.163,307.832 C240.04,307.832 214,302.049 214,294.916 C214,287.783 240.04,282 272.163,282 C304.286,282 330.327,287.783 330.327,294.916"
            id="shadow"
            fill="#EAF4EB"
          ></path>
          <g
            id="legs"
            transform="translate(248.000000, 268.000000)"
            stroke="#45B770"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8.362"
          >
            <polyline
              id="Stroke-1"
              points="15.817 -2.13162821e-14 15.817 24.795 3.55271368e-15 24.795"
            ></polyline>
            <polyline
              id="Stroke-3"
              points="31.3102 -2.30926389e-14 31.3102 25.574 47.1272 25.574"
            ></polyline>
          </g>
          <line
            x1="143.441"
            y1="205.181"
            x2="121.181"
            y2="224.512"
            id="arm-left---top"
            stroke="#45B770"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="399.988281"
            y1="213.830078"
            x2="416.9162"
            y2="196.8489"
            id="right-right---bottom"
            stroke="#45B770"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <path
            d="M151.969,73 L391.69,73 C395.538,73 398.658,76.12 398.658,79.969 L398.658,258.366 C398.658,262.214 395.538,265.334 391.69,265.334 L151.969,265.334 C148.12,265.334 145,262.214 145,258.366 L145,79.969 C145,76.12 148.12,73 151.969,73 Z"
            id="browser-border"
            stroke="#3B414D"
            strokeWidth="6"
          ></path>
          <line
            x1="121.1812"
            y1="224.512"
            x2="171.9052"
            y2="236.375"
            id="arm-left---bottom"
            stroke="#45B770"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="416.916"
            y1="196.8493"
            x2="348.522"
            y2="153.6733"
            id="arm-right---top"
            stroke="#45B770"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <path
            d="M344.1914,160.9806 C346.1454,156.3596 347.2264,151.2786 347.2264,145.9456 C347.2264,143.5286 347.0044,141.1636 346.5794,138.8686"
            id="hand-left"
            stroke="#45B770"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <g
            id="map"
            transform="translate(183.000000, 203.000000)"
            strokeLinejoin="round"
          >
            <polygon
              stroke="#3B414D"
              strokeWidth="6"
              fill="#E4F4FD"
              points="69.3333333 0 34.6666667 8.12833265 0 0 0 69.8706676 34.6666667 78 69.3333333 69.8706676 104 78 104 8.12833265"
            ></polygon>
            <line
              x1="34.5"
              y1="62.8644"
              x2="34.5"
              y2="55.5804"
              id="map-line---left---02"
              stroke="#28A7DF"
              strokeWidth="4.878"
              strokeLinecap="round"
            ></line>
            <line
              x1="34.5"
              y1="45.03"
              x2="34.5"
              y2="22"
              id="map-line---left---01"
              stroke="#28A7DF"
              strokeWidth="4.878"
              strokeLinecap="round"
            ></line>
            <line
              x1="69.4391"
              y1="54.9698"
              x2="69.4391"
              y2="37.1358"
              id="map-line---right---02"
              stroke="#28A7DF"
              strokeWidth="4.878"
              strokeLinecap="round"
            ></line>
            <line
              x1="69.4391"
              y1="27.5738"
              x2="69.4391"
              y2="17.4388"
              id="map-line---right---01"
              stroke="#28A7DF"
              strokeWidth="4.878"
              strokeLinecap="round"
            ></line>
          </g>
          <g id="left-hand" transform="translate(173.473700, 226.000000)">
            <path
              d="M7.605,5.1029 C3.405,5.1029 -1.0658141e-13,8.5079 -1.0658141e-13,12.7079 C-1.0658141e-13,16.9089 3.405,20.3139 7.605,20.3139 L14.249,20.3139"
              id="hand"
              stroke="#45B770"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <rect
              id="cover"
              fill="#3B414D"
              x="6.5263"
              y="0"
              width="6"
              height="9"
            ></rect>
          </g>
          <g
            id="clouds"
            transform="translate(44.000000, 0.000000)"
            fill="#B8E4F4"
          >
            <path
              d="M396.6868,87.0406 C394.3808,87.0406 392.2528,87.7836 390.5118,89.0306 C389.6798,80.0776 382.1498,73.0676 372.9798,73.0676 C364.9078,73.0676 358.1098,78.4996 356.0278,85.9056 C353.9528,84.3526 351.3838,83.4216 348.5928,83.4216 C341.7248,83.4216 336.1578,88.9886 336.1578,95.8566 C336.1578,102.7246 341.7248,108.2906 348.5928,108.2906 L396.6868,108.2906 C402.5548,108.2906 407.3118,103.5346 407.3118,97.6656 C407.3118,91.7976 402.5548,87.0406 396.6868,87.0406"
              id="cloud---05"
            ></path>
            <path
              d="M320.0872,9.9 C318.8362,9.9 317.6522,10.175 316.5812,10.657 C315.5252,9.315 313.8902,8.45 312.0492,8.45 C311.5082,8.45 310.9862,8.529 310.4902,8.669 C308.5432,3.602 303.6442,5.68434189e-14 297.8922,5.68434189e-14 C291.6222,5.68434189e-14 286.3662,4.277 284.8442,10.069 C284.2972,9.959 283.7322,9.9 283.1522,9.9 C278.4272,9.9 274.5962,13.731 274.5962,18.457 C274.5962,23.183 278.4272,27.014 283.1522,27.014 L320.0872,27.014 C324.8132,27.014 328.6442,23.183 328.6442,18.457 C328.6442,13.731 324.8132,9.9 320.0872,9.9"
              id="cloud---04"
            ></path>
            <path
              d="M198.5179,25.524 C195.7659,25.524 193.2259,26.41 191.1459,27.899 C190.1539,17.212 181.1649,8.845 170.2189,8.845 C160.5829,8.845 152.4689,15.329 149.9839,24.17 C147.5059,22.316 144.4409,21.204 141.1079,21.204 C132.9109,21.204 126.2649,27.85 126.2649,36.047 C126.2649,44.245 132.9109,50.891 141.1079,50.891 L198.5179,50.891 C205.5219,50.891 211.2009,45.212 211.2009,38.208 C211.2009,31.203 205.5219,25.524 198.5179,25.524"
              id="cloud---03"
            ></path>
            <path
              d="M117.9359,151.1183 C116.6859,151.1183 115.5009,151.3933 114.4309,151.8753 C113.3739,150.5333 111.7379,149.6683 109.8979,149.6683 C109.3569,149.6683 108.8359,149.7473 108.3389,149.8873 C106.3919,144.8203 101.4929,141.2183 95.7409,141.2183 C89.4719,141.2183 84.2149,145.4953 82.6919,151.2873 C82.1459,151.1773 81.5809,151.1183 81.0019,151.1183 C76.2759,151.1183 72.4449,154.9493 72.4449,159.6753 C72.4449,164.4013 76.2759,168.2323 81.0019,168.2323 L117.9359,168.2323 C122.6619,168.2323 126.4929,164.4013 126.4929,159.6753 C126.4929,154.9493 122.6619,151.1183 117.9359,151.1183"
              id="cloud---02"
            ></path>
            <path
              d="M45.491,85.3556 C44.241,85.3556 43.056,85.6306 41.985,86.1126 C40.929,84.7706 39.293,83.9056 37.453,83.9056 C36.912,83.9056 36.391,83.9846 35.894,84.1246 C33.947,79.0576 29.048,75.4556 23.296,75.4556 C17.027,75.4556 11.77,79.7326 10.247,85.5246 C9.701,85.4146 9.136,85.3556 8.557,85.3556 C3.831,85.3556 -1.13686838e-13,89.1866 -1.13686838e-13,93.9126 C-1.13686838e-13,98.6386 3.831,102.4696 8.557,102.4696 L45.491,102.4696 C50.217,102.4696 54.048,98.6386 54.048,93.9126 C54.048,89.1866 50.217,85.3556 45.491,85.3556"
              id="cloud---01"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NotFoundIcon;
