import React from "react";
import { tint } from "polished";
import { Link } from "react-router-dom";
import Text from "../text";
import { Box, Switcher, Center, Cluster, Cover } from "../layouts";
import { fallbackValues } from "./Placeholder.theme";
import { themeComponent } from "../../../util/themeUtils";
import { TRANSPARENT, MANATEE_GREY } from "../../../constants/colors";
import {
  AccountsAddIcon,
  PropertiesAddIcon,
  PlusCircleIcon,
  PaymentMethodAddIcon,
  DisabledAccountsAddIcon,
  DisabledPropertiesAddIcon,
  DisabledPaymentMethodsAddIcon
} from "../icons";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";

const getLargeIcon = (iconName, isDisabled) => {
  switch (iconName) {
    case "accounts":
      return isDisabled ? <DisabledAccountsAddIcon /> : <AccountsAddIcon />;
    case "properties":
      return isDisabled ? <DisabledPropertiesAddIcon /> : <PropertiesAddIcon />;
    case "payments":
      return isDisabled ? (
        <DisabledPaymentMethodsAddIcon />
      ) : (
        <PaymentMethodAddIcon />
      );
    default:
      return isDisabled ? <DisabledAccountsAddIcon /> : <AccountsAddIcon />;
  }
};

const renderDisabledContent = ({ children }) => (
  <Box
    padding="0"
    minHeight="100%"
    extraStyles="cursor: default;"
    aria-disabled={true}
  >
    {children}
  </Box>
);

const renderContent = ({ isLink, destination, dataQa, children, action }) =>
  isLink ? (
    <Link to={destination} data-qa={dataQa} style={{ textDecoration: "none" }}>
      <Box padding="0" minHeight="100%" extraStyles="cursor: pointer;">
        {children}
      </Box>
    </Link>
  ) : (
    <Box
      onClick={action}
      padding="0"
      minHeight="100%"
      extraStyles="cursor: pointer;"
      dataQa={dataQa}
      tabIndex="0"
      onKeyPress={e => e.key === "Enter" && action()}
    >
      {children}
    </Box>
  );

const PlaceholderContentWrapper = ({
  isLink,
  action,
  destination,
  children,
  isDisabled = false,
  dataQa
}) =>
  isDisabled
    ? renderDisabledContent({ children })
    : renderContent({
        children,
        action,
        isLink,
        destination,
        dataQa
      });

const Placeholder = ({
  text,
  action,
  visible = true,
  isLink = false,
  destination,
  variant,
  largeIcon,
  themeValues,
  isDisabled = false,
  dataQa
}) => {
  const borderColor = isDisabled ? MANATEE_GREY : themeValues.color;
  return (
    <PlaceholderContentWrapper
      isLink={isLink}
      action={action}
      destination={destination}
      dataQa={dataQa}
      isDisabled={isDisabled}
    >
      <Box
        padding="0"
        borderRadius="4px"
        border="none"
        minHeight={themeValues.height}
        hiddenStyles={!visible}
        extraStyles={`
          display: flex;
          justify-content: center;
          align-items:center;
          background-image: repeating-linear-gradient(0deg, ${borderColor}, ${borderColor} 2px, transparent 2px, transparent 4px, ${borderColor} 4px), repeating-linear-gradient(90deg, ${borderColor}, ${borderColor} 2px, transparent 2px, transparent 4px, ${borderColor} 4px), repeating-linear-gradient(180deg, ${borderColor}, ${borderColor} 2px, transparent 2px, transparent 4px, ${borderColor} 4px), repeating-linear-gradient(270deg, ${borderColor}, ${borderColor} 2px, transparent 2px, transparent 4px, ${borderColor} 4px);
          background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
          background-position: 0 0, 0 0, 100% 0, 0 100%;
          background-repeat: no-repeat;
        `}
        hoverStyles={`background-color: ${
          isDisabled ? TRANSPARENT : tint(0.9, themeValues.color)
        };`}
        focusStyles={`background-color: ${
          isDisabled ? TRANSPARENT : tint(0.9, themeValues.color)
        };`}
        activeStyles={`background-color: ${
          isDisabled ? TRANSPARENT : tint(0.8, themeValues.color)
        }; ${!isDisabled &&
          `background-image:none; border: 2px solid ${borderColor};}`}`}
      >
        <Center maxWidth="300px">
          <Box padding="0">
            <Cluster justify="center" align="center" minHeight="100%">
              <Switcher maxChildren={2} childGap="0">
                {variant === "large" && <div></div>}
                <Box
                  padding="0"
                  aria-disabled={isDisabled}
                  extraStyles={`.fill { 
                    fill: ${isDisabled ? MANATEE_GREY : themeValues.color}; 
                  } .stroke { 
                    stroke: ${isDisabled ? MANATEE_GREY : themeValues.color}; 
                  } `}
                >
                  {variant === "large" ? (
                    <Center intrinsic>
                      {getLargeIcon(largeIcon, isDisabled)}
                      <Text
                        variant="pS"
                        color={isDisabled ? MANATEE_GREY : themeValues.color}
                        weight={FONT_WEIGHT_SEMIBOLD}
                        extraStyles={`text-align: center;`}
                      >
                        {text}
                      </Text>
                    </Center>
                  ) : (
                    <Cover singleChild minHeight="100%">
                      <Cluster justify="center" align="center">
                        <PlusCircleIcon
                          color={isDisabled ? MANATEE_GREY : themeValues.color}
                        />
                        <Center intrinsic>
                          <Text
                            variant="pS"
                            color={
                              isDisabled ? MANATEE_GREY : themeValues.color
                            }
                            weight={FONT_WEIGHT_SEMIBOLD}
                            extraStyles={`padding: 0 0 0 8px; text-align: center;`}
                          >
                            {text}
                          </Text>
                        </Center>
                      </Cluster>
                    </Cover>
                  )}
                </Box>
              </Switcher>
            </Cluster>
          </Box>
        </Center>
      </Box>
    </PlaceholderContentWrapper>
  );
};

export default themeComponent(
  Placeholder,
  "Placeholder",
  fallbackValues,
  "default"
);
