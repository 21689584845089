import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as L from "partial.lenses";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Breadcrumb,
  AddressForm,
  Module,
  ButtonWithAction,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import {
  ADDRESS_SETTING,
  SUBMIT_OPERATIONS,
  ADDRESS_RESOURCE
} from "../../Profile.state";
import S from "/util/Sanctuary";
import AlertBar from "/components/alert-bar";

const AddAddress = ({
  actions,
  forms,
  resourcesActions,
  alertBars,
  resources,
  requests,
  handleFocusErrors
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const addressID = params?.id ?? "";
  const {
    forms: { addressForm: formActions }
  } = actions;
  const { addressForm: form } = forms;
  const { onSubmitChange } = resourcesActions;
  const { addressAlertBar: alertBar } = alertBars;
  const {
    alertBars: { addressAlertBar: alertBarActions }
  } = actions;
  const action = params.id ? SUBMIT_OPERATIONS.UPDATE : SUBMIT_OPERATIONS.ADD;
  const requestStatusAddress =
    requests[ADDRESS_SETTING] || S.RemoteData.NotAsked;
  const address = L.get(
    L.compose(L.prop(ADDRESS_RESOURCE), L.prop(addressID)),
    resources
  );
  const [errors, handleErrors] = useState(false);
  const { isMobile } = useContext(ThemeContext);

  const handleSubmit = util.general.generateClickHandler(
    form,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => onSubmitChange(ADDRESS_SETTING, action, address ? address.id : "")
  );

  if (action === SUBMIT_OPERATIONS.UPDATE) {
    useEffect(() => {
      formActions.actions.fields.country.set(address.country);
      formActions.actions.fields.street1.set(address.street1);
      if (address.street2) {
        formActions.actions.fields.street2.set(address.street2);
      }
      formActions.actions.fields.city.set(address.city);
      formActions.actions.fields.stateProvince.set(address.stateProvince);
      const zip =
        address.country === "US" ? address.zip.replace("-", "") : address.zip;
      formActions.actions.fields.zip.set(zip);
    }, [addressID]);
  }

  const actionText =
    action === SUBMIT_OPERATIONS.ADD ? "Add Address" : "Edit Address";

  return (
    <Box padding="0 0 16px" width={isMobile ? "100%" : "576px"}>
      <Breadcrumb
        breadcrumbsList={[
          {
            linkText: "Wallet",
            linkDestination: "/profile/wallet",
            isActive: false
          },
          {
            linkText: actionText,
            linkDestination: "#",
            isActive: true
          }
        ]}
      />
      {alertBar.alerts.length > 0 && (
        <Box padding="1rem 0 0">
          <AlertBar {...alertBar} {...alertBarActions} />
        </Box>
      )}
      <Module heading={actionText}>
        <AddressForm
          clearOnDismount
          {...form}
          {...formActions}
          showErrors={errors}
          handleSubmit={handleSubmit}
          isMobile={isMobile}
        />
      </Module>
      <Box
        padding="1.5rem 0 0 0"
        borderColor={constants.colors.GHOST_GREY}
        borderSize="1px"
        borderWidthOverride="1px 0 0 0"
      >
        {isMobile ? (
          <Stack childGap="1rem" direction="row">
            <Box width="100%" padding="0">
              <ButtonWithAction
                text="Cancel"
                action={() => navigate(-1)}
                variant={"secondary"}
                dataQa="Cancel"
                extraStyles={`width: 100%;`}
              />
            </Box>
            <Box width="100%" padding="0">
              <ButtonWithAction
                text="Save"
                action={handleSubmit}
                isLoading={requestStatusAddress.isLoading}
                variant="primary"
                dataQa="Save"
                extraStyles={`width: 100%;`}
              />
            </Box>
          </Stack>
        ) : (
          <Stack childGap="1rem" direction="row" justify="flex-end">
            <ButtonWithAction
              text="Cancel"
              action={() => navigate(-1)}
              dataQa="Cancel"
              variant={"secondary"}
            />
            <ButtonWithAction
              text="Save"
              isLoading={requestStatusAddress.isLoading}
              action={handleSubmit}
              variant="primary"
              dataQa="Save"
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default withWindowSize(AddAddress);
