import React from "react";

export const CloseIcon = ({
  iconFill = "#3B414D",
  iconWidth = "24px",
  iconHeight = "24px",
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      style={{ width: iconWidth, height: iconHeight }}
      {...rest}
    >
      <title>Close Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3033 6.48982C17.108 6.29456 16.7915 6.29456 16.5962 6.48982L12 11.086L7.40381 6.48982C7.20854 6.29456 6.89196 6.29456 6.6967 6.48982L5.98959 7.19693C5.79433 7.39219 5.79433 7.70878 5.98959 7.90404L10.5858 12.5002L5.98959 17.0964C5.79433 17.2917 5.79433 17.6083 5.98959 17.8035L6.6967 18.5106C6.89196 18.7059 7.20854 18.7059 7.40381 18.5106L12 13.9144L16.5962 18.5106C16.7915 18.7059 17.108 18.7059 17.3033 18.5106L18.0104 17.8035C18.2057 17.6083 18.2057 17.2917 18.0104 17.0964L13.4142 12.5002L18.0104 7.90404C18.2057 7.70878 18.2057 7.39219 18.0104 7.19693L17.3033 6.48982Z"
        fill={iconFill}
      />
    </svg>
  );
};

export default CloseIcon;
