import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  ButtonWithAction,
  Cluster,
  IconQuitLarge,
  Stack,
  Text,
  withWindowSize
} from "@thecb/components";
import { ThemeContext } from "styled-components";
import CollapsibleFilterSection from "./CollapsibleFilterSection";
import { CHARADE_GREY, GHOST_GREY } from "../../../../constants/colors";
import SearchBox from "../search-box";
import Slider, { SliderPosition } from "../../../slider";
import SliderPanel from "../../../slider-panel";
import FilterIcon from "../../../icons/FilterIcon";
import { CategoryGridFilterType } from "../CategoryGrid";

const SliderTitleBar = ({ onClose }) => (
  <Box
    borderColor={GHOST_GREY}
    borderWidthOverride="0 0 1px 0"
    extraStyles={`margin-bottom: 16px;`}
  >
    <Cluster padding={0} justify="space-between" align="center">
      <Text color={CHARADE_GREY}>FILTERS</Text>
      <ButtonWithAction
        variant="smallGhost"
        contentOverride
        action={onClose}
        extraStyles="min-width: 0; margin: 0;"
      >
        <IconQuitLarge />
      </ButtonWithAction>
    </Cluster>
  </Box>
);

const SliderOpenButton = ({ onOpen }) => (
  <Box padding="0">
    <ButtonWithAction
      variant="smallGhost"
      contentOverride
      action={onOpen}
      extraStyles="text-align: left; margin-left: 0; margin-bottom: 16px;"
    >
      <FilterIcon />
      <Text color={CHARADE_GREY} extraStyles="margin-left: 8px;">
        FILTERS
      </Text>
    </ButtonWithAction>
  </Box>
);

const FilterSelectionContainer = ({
  isMobile,
  filtersOpen,
  setFiltersOpen,
  children
}) => {
  if (isMobile) {
    return filtersOpen ? (
      <Slider
        isOpen={filtersOpen}
        isMobile={isMobile}
        direction="right"
        position={SliderPosition.VIEWPORT_TOP}
      >
        <SliderPanel status="onScreen" sliderKey="filters">
          <Box padding={0} width="85%" background="white" minHeight="100%">
            <SliderTitleBar onClose={() => setFiltersOpen(false)} />
            <Box padding="0 16px">{children}</Box>
          </Box>
        </SliderPanel>
      </Slider>
    ) : null;
  }
  return (
    <Box
      padding="0 16px 0 0"
      minHeight="100%"
      extraStyles={`margin-top: 32px; position:sticky; top:0;border-right: 1px solid ${GHOST_GREY}`}
    >
      <Box padding={0} minWidth="214px">
        <Stack childGap="40px">{children}</Stack>
      </Box>
    </Box>
  );
};

const CategoryGridSidebar = ({
  cardFilters,
  showSearch,
  actionChoices,
  tagChoices,
  subjectChoices,
  sectionChoices,
  onUpdate,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [actionSelections, setActionSelections] = useState([]);
  const [sectionSelections, setSectionSelections] = useState([]);
  const [subjectSelections, setSubjectSelections] = useState([]);
  const [tagSelections, setTagSelections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    onUpdate(
      actionSelections,
      subjectSelections,
      sectionSelections,
      tagSelections,
      searchTerm
    );
  }, [
    onUpdate,
    actionSelections,
    subjectSelections,
    sectionSelections,
    tagSelections,
    searchTerm
  ]);

  const updateSelection = (value, selections, setMethod) => {
    const index = selections.findIndex(selected => selected === value);
    const updatedSelections = [...selections];
    if (index >= 0) {
      updatedSelections.splice(index, 1);
    } else {
      updatedSelections.push(value);
    }
    setMethod(updatedSelections);
  };

  const handleTagSelected = ev =>
    updateSelection(ev.target.name, tagSelections, setTagSelections);

  const handleActionSelected = ev => {
    updateSelection(ev.target.name, actionSelections, setActionSelections);
  };

  const handleSubjectSelected = ev => {
    updateSelection(ev.target.name, subjectSelections, setSubjectSelections);
  };

  const handleSectionSelected = ev => {
    updateSelection(ev.target.name, sectionSelections, setSectionSelections);
  };

  const handleClearFilters = () => {
    setTagSelections([]);
    setActionSelections([]);
    setSubjectSelections([]);
    setSectionSelections([]);
    setSearchTerm("");
    onUpdate([], [], [], [], "");
  };

  const handleSearch = value => {
    setSearchTerm(value);
    onUpdate(
      actionSelections,
      subjectSelections,
      sectionSelections,
      tagSelections,
      value
    );
  };

  return (
    <div>
      <Box padding={0} width={isMobile ? "auto" : "255px"}>
        {isMobile && <SliderOpenButton onOpen={() => setFiltersOpen(true)} />}
        <FilterSelectionContainer
          isMobile={isMobile}
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
        >
          {showSearch && (
            <SearchBox
              searchTerm={searchTerm}
              themeValues={themeValues}
              onSearch={handleSearch}
            />
          )}

          {cardFilters[CategoryGridFilterType.Action]?.enabled &&
            actionChoices.length > 0 && (
              <Box padding={0}>
                <CollapsibleFilterSection
                  title={cardFilters[CategoryGridFilterType.Action].title}
                  name="actions"
                  choices={actionChoices}
                  selected={actionSelections}
                  onSelected={handleActionSelected}
                  themeValues={themeValues}
                />
              </Box>
            )}

          {cardFilters[CategoryGridFilterType.Subject]?.enabled &&
            subjectChoices.length > 0 && (
              <Box
                padding={0}
                extraStyles={`margin-top: ${isMobile ? "16" : "40"}px;`}
              >
                <CollapsibleFilterSection
                  title={cardFilters[CategoryGridFilterType.Subject].title}
                  name="subjects"
                  choices={subjectChoices}
                  selected={subjectSelections}
                  onSelected={handleSubjectSelected}
                  themeValues={themeValues}
                />
              </Box>
            )}

          {cardFilters[CategoryGridFilterType.Section]?.enabled &&
            sectionChoices.length > 0 && (
              <Box
                padding={0}
                extraStyles={`margin-top: ${isMobile ? "16" : "40"}px;`}
              >
                <CollapsibleFilterSection
                  title={cardFilters[CategoryGridFilterType.Section].title}
                  name="sections"
                  choices={sectionChoices}
                  selected={sectionSelections}
                  onSelected={handleSectionSelected}
                  themeValues={themeValues}
                />
              </Box>
            )}

          {cardFilters[CategoryGridFilterType.Tag]?.enabled &&
            tagChoices.length > 0 && (
              <Box
                padding={0}
                extraStyles={`margin-top: ${isMobile ? "16" : "40"}px;`}
              >
                <CollapsibleFilterSection
                  title={cardFilters[CategoryGridFilterType.Tag].title}
                  name="tags"
                  choices={tagChoices}
                  selected={tagSelections}
                  onSelected={handleTagSelected}
                  themeValues={themeValues}
                />
              </Box>
            )}

          <Box
            padding={0}
            extraStyles={`margin-top:${isMobile ? "32px" : "40px"};`}
          >
            <ButtonWithAction
              text="Clear Filters"
              action={handleClearFilters}
              extraStyles="border-radius: 4px; margin: 0; width: 100%;"
              variant="secondary"
            />
          </Box>
        </FilterSelectionContainer>
      </Box>
    </div>
  );
};

export default withWindowSize(CategoryGridSidebar);
