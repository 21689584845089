import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Box,
  Stack,
  Cover,
  Center,
  Cluster,
  Paragraph,
  InternalLink,
  Spinner,
  Pagination
} from "@thecb/components";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { WHITE } from "../../../constants/colors";
import RightArrowIcon from "../../icons/RightArrowIcon";

const SearchResults = ({
  pages,
  isLoading,
  showDescription = false,
  showPagination = false,
  themeValues
}) => {
  const [pagesCount, setPagesCount] = useState(0);
  const [pagesByNumber, setPagesByNumber] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // remove any results with missing slugs
    const validPages = Object.fromEntries(
      Object.entries(pages).filter(p => p[1].slug?.length)
    );
    setCurrentPage(1);
    setPagesCount(Math.ceil(Object.entries(validPages).length / 5));
    setPagesByNumber(
      Object.entries(validPages).reduce((acc, curr, index) => {
        let pageNumber = Math.ceil((index + 1) / 5);
        return {
          ...acc,
          [pageNumber]: [...(acc?.[pageNumber] ?? []), curr[1]]
        };
      }, {})
    );
  }, [pages]);

  const { isMobile } = useContext(ThemeContext);

  return (
    <Box padding={isMobile ? "0" : "0 1px 0 0"}>
      {isLoading ? (
        <Cover minHeight="100%" singleChild>
          <Center intrinsic>
            <Box>
              <Spinner size="100" />
            </Box>
          </Center>
        </Cover>
      ) : (
        <Stack>
          {pagesByNumber[currentPage]?.map((page, index) => (
            <InternalLink
              to={`/${page.slug[0].slug}`}
              key={`page-${page.slug[0].slug}-${index}`}
            >
              <Box
                padding={isMobile ? "1rem" : "2rem 2.25rem"}
                background="white"
                borderRadius="4px"
                boxShadow={`inset 0px 3px 0px 0px ${themeValues.accents},
0px 1px 3px 0px rgba(0, 0, 0, 0.5)`}
                minWidth="100%"
              >
                <Stack>
                  <Box padding="0" minWidth="100%">
                    <Paragraph
                      variant="pL"
                      weight={FONT_WEIGHT_SEMIBOLD}
                      color={themeValues.darkText}
                    >
                      {page?.title ?? page?.metaTitle ?? ""}
                    </Paragraph>
                  </Box>
                  {showDescription && (
                    <Box padding="0" minWidth="100%">
                      <Paragraph color="#6D717E">
                        {page?.description ?? page?.metaDescription ?? ""}
                      </Paragraph>
                    </Box>
                  )}
                  <Box
                    minWidth="100%"
                    padding={isMobile ? "16px 0 0 0" : "16px"}
                  >
                    <Cluster justify="flex-end" align="center">
                      <Paragraph color={themeValues.linkColor}>
                        Learn more
                      </Paragraph>
                      <RightArrowIcon color={themeValues.linkColor} />
                    </Cluster>
                  </Box>
                </Stack>
              </Box>
            </InternalLink>
          ))}
          {showPagination && (
            <Pagination
              pagePrevious={() => setCurrentPage(currentPage - 1)}
              pageNext={() => setCurrentPage(currentPage + 1)}
              setCurrentPage={({ pageNumber }) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
              pageCount={pagesCount}
              numberColor={themeValues.accents}
              arrowColor={WHITE}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};

export default SearchResults;
