import { get } from "partial.lenses";
import { createSelector } from "reselect";
import jwtDecode from "jwt-decode";
import { createProfileState } from "../apps/profile/pages/user-profile";
import { WALLET_SCREEN_V2_FLAG } from "./launchDarklyUtils";

// Config
export const getGraphqlServiceEndpoint = state =>
  get(["config", "graphqlServiceEndpoint"], state);
export const getGraphCMSEndpoint = state =>
  get(["config", "graphCMSEndpoint"], state);
export const getGraphCMSToken = state =>
  get(["config", "graphCMSToken"], state);
export const getOIDC = state => get(["config", "oidc"], state);

// Global
// export const getGlobal = state => get(["global"], state);

// Client Slug is normally set via FCS or Ambassador Auth
// For development, can use the below selector to retrieve the value in config.json instead

// uncomment if you need to change the client slug using config.json
// export const getClientSlug = state => get(["config", "defaultClientSlug"], state);

// comment out if you need to change the client slug using config.json
export const getClientSlug = state =>
  get(["global", "clientMetadata", "data", "slug"], state);
export const getClientBrand = state =>
  get(["global", "clientMetadata", "data", "brand"], state);
export const getSubclientPath = state =>
  get(["global", "subClientPath", "data"], state);
export const getSubclientSlug = state =>
  get(["global", "subClientSlug", "data"], state);
export const getSubclients = state =>
  get(["global", "subClients", "data"], state);
export const getPaymentConfigs = state =>
  get(["global", "payment", "data"], state);

// Settings
export const getUserFakeData = state =>
  get(["global", "settings", "data", "useFakeData"], state);
export const getFakeObligationData = state =>
  get(["global", "settings", "data", "fakeObligationData"], state);
export const getRoutes = state =>
  get(["global", "settings", "data", "routes"], state);
export const getObligationConfigs = state =>
  get(["global", "settings", "data", "obligations"], state);

export const getLaunchDarklyFlags = state =>
  get(["global", "launchDarkly", "flags"], state);
export const getWalletScreenV2EnabledFlag = state =>
  get(["global", "launchDarkly", "flags", WALLET_SCREEN_V2_FLAG], state);

// Workflows
export const getObligationAssociation = state =>
  get(["global", "workflows", "data", "obligationAssociation"], state);
export const getFindableAccounts = state =>
  get(["global", "workflows", "data", "findableAccounts"], state);
export const getWorkflowClient = state =>
  get(["workflow", "workflowMetadata", "client"], state);
export const getWorkflowSubClient = state =>
  get(["workflow", "workflowMetadata", "sub_client"], state);
export const getEmplid = state =>
  get(["workflow", "boundData", "emplid"], state);
export const getEmplssn = state =>
  get(["workflow", "boundData", "emplssn"], state);

// Auth
export const authStoreKey = "auth";
export const getAccessToken = state =>
  get([authStoreKey, "accessToken"], state);
export const getUserID = createSelector(
  getAccessToken,
  accessToken => jwtDecode(accessToken).sub
);

// Admin
export const adminStoreKey = "adminLogin";
export const getAdminAccessToken = state =>
  get([adminStoreKey, "accessToken"], state) ||
  get(["global", "oktaAuth", "accessToken"], state);

// Profile
export const PROFILE_KEY = "profile";
const profileConfig = {
  getGraphqlServiceEndpoint,
  getClientSlug,
  getClientBrand,
  getAccessToken,
  getUserID,
  getRoutes,
  getSubclientPath,
  getSubclientSlug,
  getSubclients,
  getPaymentConfigs,
  getUserFakeData,
  getFakeObligationData,
  getObligationAssociation,
  getFindableAccounts,
  getEmplid,
  getEmplssn,
  getAdminAccessToken,
  getObligationConfigs,
  getWorkflowClient,
  getWorkflowSubClient,
  getLaunchDarklyFlags
};
export const profileState = createProfileState(PROFILE_KEY, profileConfig);
