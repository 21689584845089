import RegistrationForm from "./RegistrationForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./RegistrationForm.state";

RegistrationForm.reducer = reducer;
RegistrationForm.mapStateToProps = mapStateToProps;
RegistrationForm.mapDispatchToProps = mapDispatchToProps;
export default RegistrationForm;
