import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const AccountsIcon = ({ themeValues }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <defs>
        <path id="a" d="M0 0H100V100H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <mask id="b" fill={themeValues.primaryColor}>
          <use xlinkHref="#a"></use>
        </mask>
        <path
          fill={themeValues.primaryColor}
          d="M66.728 11.967c.566 0 1.024.466 1.024 1.042v18.508h6.362c.527 0 .962.406 1.018.928l.006.114v42.7c-2.433 2.351-4.911 4.22-7.386 5.687v.024s-.311.19-.898.49c-17.526 9.718-34.635-.176-34.635-.176v-1.02c-4.533-2.49-7.386-5.006-7.386-5.006V13.01c0-.576.459-1.042 1.025-1.042h40.87z"
          mask="url(#b)"
        ></path>
        <path
          fill={themeValues.accentColor}
          fillRule="nonzero"
          d="M66.728 11a2 2 0 011.986 1.86l.006.15v8.085L68.746 18a37.09 37.09 0 014.464 3.095A36.994 36.994 0 0187 49.95C87 70.412 70.435 87 50 87S13 70.412 13 49.95a36.96 36.96 0 0110.865-26.227V13.01c0-1.056.81-1.927 1.843-2.004l.15-.005h40.87zm.057 9.19H25.8v53.82C31.978 80.24 40.539 84.1 50 84.1c6.102 0 11.83-1.605 16.786-4.417l-.001-59.492zm-5.106 56.19v3.723H48.254V76.38H61.68zM74.17 39.74h-5.451l.001 38.758a34.276 34.276 0 005.45-4.458v-34.3zM68.72 21.4l-.001 9.536c.185-.227.477-.387.89-.387h4.505c1.053 0 1.91.822 1.986 1.86l.005.149.001 39.364a34.039 34.039 0 007.997-21.973A34.09 34.09 0 0071.39 23.353a34.268 34.268 0 00-2.67-1.952zm-52.823 28.55a34.039 34.039 0 007.968 21.938v-43.88a34.047 34.047 0 00-7.968 21.941zM61.68 64.1c.534 0 .967.432.967.966v4.865a.967.967 0 01-.967.968H30.906a.967.967 0 01-.967-.968v-4.865c0-.534.433-.967.967-.967zm-.968 1.933H31.873v2.931h28.838v-2.931zm.968-10.668c.534 0 .967.433.967.967v4.865a.967.967 0 01-.967.968H30.906a.967.967 0 01-.967-.968v-4.865c0-.534.433-.967.967-.967zM60.71 57.3H31.873v2.931h28.838V57.3zm-14.418-6.751a.967.967 0 01.113 1.928l-.113.006H30.906a.967.967 0 01-.113-1.928l.113-.006h15.387zm15.386-11.266c.534 0 .967.433.967.968v4.865a.967.967 0 01-.967.967H30.906a.967.967 0 01-.967-.967V40.25c0-.535.433-.968.967-.968zm-.968 1.934H31.873v2.931h28.838v-2.931zm.968-10.667c.534 0 .967.433.967.967v4.865a.967.967 0 01-.967.968H30.906a.967.967 0 01-.967-.968v-4.865c0-.534.433-.967.967-.967zm-.968 1.934H31.873v2.931h28.838v-2.931zm-14.418-6.752a.967.967 0 01.113 1.928l-.113.007H30.906a.967.967 0 01-.113-1.928l.113-.007h15.387z"
          mask="url(#b)"
        ></path>
      </g>
    </svg>
  );
};

export default themeComponent(AccountsIcon, "Icons", fallbackValues, "info");
