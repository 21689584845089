export const checkoutPaths = [
  "/payment/service/:serviceName/*",
  "/payment/invoice/:invoiceId/*",
  "/payment/invoice/:invoiceId"
];

export const checkoutConfirmationPaths = [
  "/payment/service/:serviceName/confirmation",
  "/payment/invoice/:invoiceId/confirmation"
];

export const expiredSessionPaths = [
  "/payment/service/:serviceName/expired-session",
  "/payment/invoice/:invoiceId/expired-session"
];

/*
  Used for checkout idle timer
  Timer fires on any checkout page except success and expired-session
*/
export const checkoutIdlePaths = [
  "/payment/service/:serviceName/wallet",
  "/payment/service/:serviceName/payment",
  "/payment/service/:serviceName/customer-information",
  "/payment/service/:serviceName/confirmation",
  "/payment/invoice/:invoiceId/wallet",
  "/payment/invoice/:invoiceId/payment",
  "/payment/invoice/:invoiceId/customer-information",
  "/payment/invoice/:invoiceId/confirmation"
];

export const checkoutLocalStorageKey = "checkout";
