/*
03/30/2022

For new pages/applications, please us <Title /> and <Detail /> atoms (specify "as" prop with value of h1/h2/h3/h4/h5/h6/p)

*/
import React from "react";
import { FONT_WEIGHT_REGULAR } from "../../../constants/style_constants";
import { FIREFLY_GREY } from "../../../constants/colors";
import { HeadingText } from "./Heading.styled";
import { safeChildren } from "../../../util/general";
import { fallbackValues } from "./Heading.theme";
import { themeComponent } from "../../../util/themeUtils";

const Heading = ({
  themeValues,
  weight = FONT_WEIGHT_REGULAR,
  color = FIREFLY_GREY,
  margin = 0,
  textAlign,
  extraStyles = ``,
  className,
  variant = "h1",
  as = variant,
  dataQa,
  children,
  ...rest
}) => (
  <HeadingText
    variant={variant}
    as={as}
    weight={weight}
    color={color}
    margin={margin}
    textAlign={textAlign}
    extraStyles={extraStyles}
    className={className}
    fontFamily={themeValues.fontFamily}
    fontSize={themeValues.fontSize}
    data-qa={dataQa}
    {...rest}
  >
    {safeChildren(children, <span />)}
  </HeadingText>
);

export default themeComponent(Heading, "Heading", fallbackValues, "h1");
