import styled, { css } from "styled-components";
import { ROYAL_BLUE } from "../../../constants/colors";

export const TextSpan = styled.span`
  --font-size: ${({ fontSize }) => fontSize};
  font-size: var(--font-size);
  line-height: calc(1.5 * var(--font-size));
  font-weight: ${({ weight }) => weight};
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ color }) => color};
  text-decoration: ${({ textDecoration }) => textDecoration};
  white-space: ${({ $textWrap }) => ($textWrap ? "initial" : "nowrap")};

  &:hover {
    ${({ hoverStyles }) =>
      css`
        ${hoverStyles}
      `}
  }

  &:focus {
    outline: 3px solid ${ROYAL_BLUE};
    outline-offset: 2px;
  }

  ${({ disabled, disabledStyles }) =>
    disabled &&
    css`
      ${disabledStyles}
    `}

  ${({ extraStyles }) => extraStyles}
`;
