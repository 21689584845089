import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../../content/Content.theme";
import { themeComponent } from "../../../util/themeUtils";
import { withWindowSize } from "@thecb/components";
import { Box, Center, Cover, Stack, Title, constants } from "@thecb/components";
import CategoryGrid from "../../content/category-grid";
import {
  FONT_WEIGHT_SEMIBOLD,
  MAX_CONTENT_WIDTH
} from "../../../constants/style_constants";

const CMSCategoryGridPage = ({
  header,
  sidebarProps,
  footer,
  pageTitle,
  contentSpacing = "64px",
  maxContentWidth = MAX_CONTENT_WIDTH,
  fullWidthMobile = true,
  gridCards,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const accentBorderHeight = isMobile ? "5px" : "6px";
  const PageStack = (
    <Box
      padding="0"
      minWidth="100%"
      maxWidth={MAX_CONTENT_WIDTH}
      background={constants.colors.ATHENS_GREY}
    >
      <Cover minHeight="100vh" fillCenter>
        {header ? header : <Box padding="0" key="header" />}
        <Box
          padding="0"
          minWidth="100%"
          maxWidth={MAX_CONTENT_WIDTH}
          role="main"
          key="main"
        >
          <Box
            padding={isMobile ? "48px 16px 16px 16px" : "120px 24px 80px 64px"}
            minHeight={isMobile || `calc(100vh - 250px);`}
          >
            <Center
              maxWidth={isMobile && fullWidthMobile ? "100%" : maxContentWidth}
            >
              <Stack childGap="0">
                <Box
                  padding={isMobile ? "0 0 24px 0" : "0 0 8px 0"}
                  borderSize="1px"
                  borderColor="#cfd4de"
                  borderWidthOverride="0 0 0px 0"
                  key="page-container"
                >
                  <Stack childGap="0">
                    <Box
                      minHeight={accentBorderHeight}
                      width={"50px"}
                      borderSize={accentBorderHeight}
                      borderColor={themeValues.lightAccentText}
                      borderWidthOverride={`0 0 ${accentBorderHeight} 0`}
                      key="grid-page-accent"
                      padding="0"
                    />
                    <Title
                      color={themeValues.darkText}
                      weight={FONT_WEIGHT_SEMIBOLD}
                      key="heading"
                      margin={isMobile ? "0.5rem 0 0 0" : "1rem 0 0 0"}
                      extraStyles={
                        isMobile
                          ? `line-height: 150%; font-size: 28px;`
                          : `line-height: 150%; font-size: 40px;`
                      }
                    >
                      {pageTitle}
                    </Title>
                  </Stack>
                </Box>
                <Cover minHeight="100%" singleChild fillCenter>
                  <Stack childGap={isMobile ? "40px" : contentSpacing}>
                    <Box padding="0">
                      <CategoryGrid
                        sidebarProps={sidebarProps}
                        gridCards={gridCards}
                        themeValues={themeValues}
                        paginationProps={{
                          scrollOnPageChange: true,
                          itemsPerPage: 30
                        }}
                      />
                    </Box>
                  </Stack>
                </Cover>
              </Stack>
            </Center>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" />}
      </Cover>
    </Box>
  );
  return PageStack;
};

export default withWindowSize(
  themeComponent(CMSCategoryGridPage, "Content", fallbackValues, "default")
);
