import React from "react";

const AccountDentalIcon = ({ color }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 9.51472 0 5.27208 2.63604 2.63604C5.27208 0 9.51472 0 18 0H30C38.4853 0 42.7279 0 45.364 2.63604C48 5.27208 48 9.51472 48 18V30C48 38.4853 48 42.7279 45.364 45.364C42.7279 48 38.4853 48 30 48H18C9.51472 48 5.27208 48 2.63604 45.364C0 42.7279 0 38.4853 0 30V18Z"
      fill={color}
    />
    <path
      d="M28.1548 35.14C28.4576 35.14 28.7315 35.0463 28.9766 34.8589C29.2217 34.6714 29.373 34.4191 29.4307 34.102L29.7767 32.6314C30.1516 30.9879 30.4543 28.9695 30.685 26.5763C30.858 24.6444 31.377 23.1017 32.242 21.9484C32.7322 21.2852 33.0638 20.5427 33.2368 19.7209C33.4098 18.8992 33.3954 18.0413 33.1936 17.1475C32.9629 16.1671 32.4871 15.3093 31.7663 14.574C31.0454 13.8388 30.1948 13.3558 29.2145 13.1251C28.0323 12.8079 26.7491 13.154 25.3651 14.1631C25.2786 14.2208 25.1633 14.2785 25.0191 14.3362L24.8893 14.3794L26.1436 15.2012C26.2878 15.2877 26.3815 15.4246 26.4248 15.6121C26.468 15.7995 26.4392 15.9725 26.3382 16.1311C26.2373 16.2897 26.0932 16.3906 25.9057 16.4338C25.7183 16.4771 25.5381 16.441 25.3651 16.3257L21.04 13.5576C20.0308 13.0098 19.0648 12.8656 18.1421 13.1251C17.1618 13.3558 16.3112 13.8388 15.5903 14.574C14.8695 15.3093 14.3937 16.1671 14.163 17.1475C13.9612 18.0413 13.9468 18.8992 14.1198 19.7209C14.2928 20.5427 14.6244 21.2852 15.1146 21.9484C15.9796 23.1017 16.4986 24.6444 16.6716 26.5763C16.9023 28.9695 17.205 30.9879 17.5799 32.6314L17.9259 34.102C17.9835 34.4191 18.1349 34.6714 18.38 34.8589C18.6251 35.0463 18.899 35.14 19.2018 35.14C19.5046 35.14 19.7713 35.0463 20.0019 34.8589C20.2326 34.6714 20.3912 34.4336 20.4777 34.1452L21.9482 28.1333C22.0636 27.7585 22.2798 27.4413 22.597 27.1818C22.9142 26.9223 23.2746 26.7925 23.6783 26.7925C24.082 26.7925 24.4424 26.9223 24.7596 27.1818C25.0768 27.4413 25.293 27.7585 25.4083 28.1333L26.8789 34.1452C26.9654 34.4336 27.124 34.6714 27.3547 34.8589C27.5853 35.0463 27.852 35.14 28.1548 35.14Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default AccountDentalIcon;
