import React from "react";

const ArrowLeftCircleIconMedium = ({ color = "#15749D", iconIndex = 0 }) => {
  const id = `ArrowLeftCircleIconMedium-${iconIndex}`;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7043 17.2957C12.8994 17.1006 12.8995 16.7843 12.7046 16.5889L8.8725 12.75H17.5C17.7761 12.75 18 12.5261 18 12.25V11.75C18 11.4739 17.7761 11.25 17.5 11.25H8.8725L12.7046 7.41105C12.8995 7.21574 12.8994 6.8994 12.7043 6.70426L12.3536 6.35355C12.1583 6.15829 11.8417 6.15829 11.6464 6.35355L6.35355 11.6464C6.15829 11.8417 6.15829 12.1583 6.35355 12.3536L11.6464 17.6464C11.8417 17.8417 12.1583 17.8417 12.3536 17.6464L12.7043 17.2957Z"
        fill="white"
      />
      <mask
        id={id}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="12"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7043 17.2957C12.8994 17.1006 12.8995 16.7843 12.7046 16.5889L8.8725 12.75H17.5C17.7761 12.75 18 12.5261 18 12.25V11.75C18 11.4739 17.7761 11.25 17.5 11.25H8.8725L12.7046 7.41105C12.8995 7.21574 12.8994 6.8994 12.7043 6.70426L12.3536 6.35355C12.1583 6.15829 11.8417 6.15829 11.6464 6.35355L6.35355 11.6464C6.15829 11.8417 6.15829 12.1583 6.35355 12.3536L11.6464 17.6464C11.8417 17.8417 12.1583 17.8417 12.3536 17.6464L12.7043 17.2957Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect
          x="21"
          y="21"
          width="18"
          height="18"
          transform="rotate(180 21 21)"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ArrowLeftCircleIconMedium;
