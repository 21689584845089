import styled from "styled-components";

export const WholeBodyErrorWrapper = styled.div`
  position: relative;
  z-index: 0;
  min-width: 320px;
  max-width: 1024px;
  width: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;
