import React from "react";
import { iconsMap } from "../icons";
import { Box, Cluster } from "../../../atoms/layouts";

const IconsModule = ({
  icon,
  iconDefault,
  configIconMap,
  iconValue,
  customAttributes,
  iconColor
}) => {
  let Icon;
  if (typeof icon === "object") {
    const iconID = customAttributes?.[iconValue];
    const iconMapValue = configIconMap?.[iconID] ?? iconDefault;
    Icon = iconsMap[iconMapValue];
  } else {
    Icon = iconsMap[icon];
  }
  return (
    <Box padding="0 1rem 0 0">
      <Cluster justify="center" align="center">
        <Icon color={iconColor} />
      </Cluster>
    </Box>
  );
};

export default IconsModule;
