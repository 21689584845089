import React, { useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import { blockRegistry } from "./blocks";
import { themeComponent } from "../../util/themeUtils";
import { fallbackValues } from "./Content.theme";
import { history } from "../../state/store";
import { Box, Cluster, constants, withWindowSize } from "@thecb/components";
import { MAX_CONTENT_WIDTH } from "../../constants/style_constants";

const ScrollToTop = () => {
  useEffect(() => {
    const scroll = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => scroll();
  }, []);

  return null;
};

const LandingPageContent = ({
  blockName,
  defaultBlockName = "TextBlock",
  blockIndex,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const Block = blockRegistry?.[blockName] ?? blockRegistry[defaultBlockName];
  const containerPadding = blockIndex % 2 === 0 ? 0 : isMobile ? 16 : 24;
  const blockPadding = isMobile ? "24px 0" : "40px 0";

  return (
    <Box
      id={`${blockName}-${blockIndex}`}
      key={`${blockName}-${blockIndex}`}
      extraStyles={`
        &:nth-child(odd) { background-color: white; }
        &:nth-child(even) { background-color: ${
          constants.colors.ATHENS_GREY
        }; padding: ${containerPadding}px ${isMobile ? 16 : 24}px; }
        &:first-child { padding-top: ${isMobile ? 56 : 80}px; }
        &:last-child { padding-bottom: ${containerPadding +
          (isMobile ? 80 : 120)}px; }
      `}
      padding={isMobile ? "0 16px" : "0 24px"}
    >
      <Cluster
        justify="center"
        extraStyles={`margin: auto; max-width: ${
          isMobile ? "100%" : MAX_CONTENT_WIDTH
        };`}
      >
        <ScrollToTop />
        <Block {...rest} blockIndex={blockIndex} blockPadding={blockPadding} />
      </Cluster>
    </Box>
  );
};

export default withWindowSize(
  themeComponent(LandingPageContent, "Content", fallbackValues, "default")
);
