import styled from "styled-components";

export const ErrorContainer = styled.div`
  display: grid;
  min-height: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`;

export const ErrorContent = styled.main``;
