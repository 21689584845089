import React from "react";
export const AlertSuccessIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 Z M16.9497475,7.46446609 L18.363961,8.87867966 L9.87867966,17.363961 L5.63603897,13.1213203 L7.05025253,11.7071068 L9.87803897,14.5354661 L16.9497475,7.46446609 Z"
        id="alertSuccess"
      ></path>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Alert/Success" transform="translate(-24.000000, -34.000000)">
        <g
          id="Icon/Success-Container"
          transform="translate(24.000000, 34.000000)"
        >
          <mask id="mask-2" fill="white">
            <use xlinkHref="#alertSuccess"></use>
          </mask>
          <use
            id="background-2"
            fill="#47B872"
            fillRule="nonzero"
            xlinkHref="#alertSuccess"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);
