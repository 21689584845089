export const getCallToActionInfo = (
  callToActionLinkField,
  backupText = "",
  backupUrl = ""
) => {
  const { slug, title = "", externalUrl = "", text = "" } =
    callToActionLinkField || {};

  if (Array.isArray(slug) && slug[0]?.slug) {
    return { ctaText: title, ctaUrl: `/${slug[0].slug}` || "" };
  }
  return externalUrl
    ? { ctaText: text || "", ctaUrl: externalUrl || "" }
    : { ctaText: backupText || "", ctaUrl: backupUrl || "" };
};

export const getPageCallToActionLink = contentCallToActionLink => {
  if (!contentCallToActionLink) {
    return { label: "", link: "" };
  }
  const { title, text, slug, externalUrl } = contentCallToActionLink;
  const label = title || text || "";

  let link = externalUrl || "";
  if (Array.isArray(slug) && slug[0]?.slug) {
    link = `/${slug[0].slug}`;
  }
  return { label, link };
};

export const RelatedContentType = {
  Action: "action",
  Subject: "subject",
  Section: "section",
  External: "external",
  LinkList: "linklist",
  Tags: "tags"
};

export const getRelatedContentType = relatedContentItem => {
  const {
    actionType,
    subjectType,
    sectionType,
    externalUrl,
    links
  } = relatedContentItem;
  if (Array.isArray(links)) {
    return RelatedContentType.LinkList;
  } else if (actionType) {
    return RelatedContentType.Action;
  } else if (subjectType) {
    return RelatedContentType.Subject;
  } else if (sectionType) {
    return RelatedContentType.Section;
  } else if (externalUrl) {
    return RelatedContentType.External;
  } else return RelatedContentType.Tags;
};
