import React, { useEffect, useState } from "react";
import { LoadingContainer } from "./LoadingSuspense.styled";
import { Box, Center, Cover, Spinner } from "@thecb/components";
import { Helmet } from "react-helmet";

const LoadingSuspense = ({ title = "Loading...", faviconURL }) => {
  const delay = 500;
  const [showLoadingPage, setShowLoadingPageVisibility] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoadingPageVisibility(true), delay);

    return () => {
      clearTimeout(timer);
    };
  });

  return showLoadingPage ? (
    <LoadingContainer>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={faviconURL} />
      </Helmet>
      <Cover singleChild>
        <Box>
          <Cover minHeight="100%" singleChild>
            <Center intrinsic>
              <Box>
                <Spinner size="100" />
              </Box>
            </Center>
          </Cover>
        </Box>
      </Cover>
    </LoadingContainer>
  ) : null;
};

export default LoadingSuspense;
