import React, { useRef, useEffect } from "react";
import { Box, Cluster } from "../../atoms/layouts";
import { Paragraph } from "../../atoms";
import {
  SuccessfulIconMedium,
  ErroredIcon,
  IconQuitLarge
} from "../../atoms/icons";
import {
  ERROR_BACKGROUND_COLOR,
  HINT_GREEN,
  WHITE
} from "../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { generateShadows } from "../../../util/generateShadows";
import { SCREEN_READER_ONLY } from "../../../constants/style_constants";

const VARIANTS = {
  SUCCESS: "success",
  ERROR: "error"
};

const ToastNotification = ({
  variant = VARIANTS.SUCCESS,
  message = "",
  toastOpen,
  closeToastNotification,
  extraStyles,
  minWidth = "112px",
  maxWidth = "350px",
  height = "56px",
  childGap = "1rem",
  backgroundColor,
  role = "status",
  ariaLive = "polite",
  screenReaderMessage,
  showScreenReaderMessage = true,
  ...rest
}) => {
  const screenReaderMessageRef = useRef();
  const LIVEREGION_TRANSITION_DELAY = 1000;

  useEffect(() => {
    if (showScreenReaderMessage) {
      const timeout = setTimeout(() => {
        if (screenReaderMessageRef.current) {
          screenReaderMessageRef.current.textContent = `${
            screenReaderMessage ? screenReaderMessage : message ? message : ""
          }`;
        }
      }, LIVEREGION_TRANSITION_DELAY);

      return () => clearTimeout(timeout);
    }
  }, [showScreenReaderMessage, screenReaderMessage, message]);

  return (
    <>
      <Box
        onClick={closeToastNotification}
        onKeyDown={e => e.key === "Enter" && closeToastNotification()}
        background={
          backgroundColor
            ? backgroundColor
            : variant === VARIANTS.SUCCESS
            ? HINT_GREEN
            : variant === VARIANTS.ERROR
            ? ERROR_BACKGROUND_COLOR
            : WHITE
        }
        minWidth={minWidth}
        minHeight={height && parseInt(height) < 100 ? height : "100px"}
        height={height ? height : "auto"}
        tabIndex={toastOpen ? "-1" : "0"}
        padding="0rem 1rem"
        borderRadius="4px"
        boxShadow={generateShadows().standard.base}
        extraStyles={`
      display: ${toastOpen ? "block" : "none"};
      position: fixed; bottom: 4rem; left: 4rem;
      ${extraStyles};
      cursor: pointer;
    `}
        {...rest}
      >
        <Cluster align="center" childGap={childGap} justify="space-between">
          <Cluster align="center" childGap={childGap}>
            {variant === VARIANTS.SUCCESS && <SuccessfulIconMedium />}
            {variant === VARIANTS.ERROR && <ErroredIcon />}
            <Box padding="1rem 0" maxWidth={maxWidth}>
              <Paragraph
                weight={FONT_WEIGHT_SEMIBOLD}
                extraStyles={"word-break: break-word;"}
              >
                {message}
              </Paragraph>
            </Box>
          </Cluster>
          <IconQuitLarge />
        </Cluster>
      </Box>
      {showScreenReaderMessage && (
        <span
          ref={screenReaderMessageRef}
          style={SCREEN_READER_ONLY}
          role={role}
          aria-live={ariaLive}
          aria-atomic={true}
        ></span>
      )}
    </>
  );
};

export default ToastNotification;
