import ChangePasswordForm from "./ChangePasswordForm";
import {
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "./ChangePasswordForm.state";

ChangePasswordForm.reducer = reducer;
ChangePasswordForm.mapStateToProps = mapStateToProps;
ChangePasswordForm.mapDispatchToProps = mapDispatchToProps;
export default ChangePasswordForm;
