import React, { useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { themeComponent } from "/util/themeUtils";
import {
  Box,
  Cluster,
  Stack,
  ForgotPasswordForm,
  ForgotPasswordIcon,
  CenterSingle,
  withWindowSize,
  ButtonWithAction,
  ButtonWithLink,
  Heading,
  Paragraph,
  FormContainer,
  FormInputColumn,
  util,
  constants
} from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";
import { fallbackValues } from "../../ProfileApp.theme";

const ForgotPasswordContent = ({
  isMobile,
  alertBars,
  actions,
  forms,
  handleSubmit,
  submitting,
  showErrors,
  themeValues
}) => (
  <Box padding={isMobile ? "0 0 48px" : "32px 0 64px"}>
    {alertBars.forgotPasswordAlertBar.alerts.length > 0 && (
      <Box padding="8px 0">
        <AlertBar
          {...alertBars.forgotPasswordAlertBar}
          {...actions.alertBars.forgotPasswordAlertBar}
        />
      </Box>
    )}
    <Box
      padding="0"
      width={isMobile ? `auto` : `576px`}
      borderRadius="4px"
      background={constants.colors.WHITE}
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249), 0px 3px 8px 0px rgb(202, 206, 216)"
    >
      <Box
        background={themeValues.bannerBackgroundColor}
        minWidth="100%"
        padding="8px"
      >
        <Cluster justify="center" align="center">
          <ForgotPasswordIcon />
        </Cluster>
      </Box>
      <FormContainer>
        <FormInputColumn childGap="8px">
          <Box padding="0">
            <Heading
              variant="h5"
              color={constants.colors.CHARADE_GREY}
              weight="600"
              as="h1"
            >
              Forgot your password?
            </Heading>
          </Box>
          <Box padding="0">
            <Paragraph variant="p">
              Enter your email address to receive a link to create a new
              password.
            </Paragraph>
          </Box>
          <Box padding={isMobile ? "8px 0 0" : "16px 0 0"}>
            <Stack
              childGap={isMobile ? "16px" : "24px"}
              role="form"
              aria-label="Forgot password"
            >
              <ForgotPasswordForm
                {...forms.forgotPasswordForm}
                {...actions.forms.forgotPasswordForm}
                clearOnDismount
                handleSubmit={handleSubmit}
                showErrors={showErrors}
                isMobile
              />
              <ButtonWithAction
                text="Reset Password"
                isLoading={submitting}
                variant="primary"
                action={handleSubmit}
                dataQa="Reset Password"
                extraStyles={`width: 100%; margin-left: 0px;`}
              />
            </Stack>
          </Box>
        </FormInputColumn>
      </FormContainer>
      <Box
        background={themeValues.bannerBackgroundColor}
        minWidth="100%"
        padding={isMobile ? "24px" : "32px"}
      >
        <Cluster justify="center" align="center">
          <ButtonWithLink
            url="/login"
            variant="smallGhost"
            text="Cancel and return to login"
          />
        </Cluster>
      </Box>
    </Box>
  </Box>
);

const ForgotPassword = ({
  submitForgotPasswordForm,
  forms,
  actions,
  alertBars,
  submitting,
  handleFocusErrors,
  themeValues
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [errors, handleErrors] = useState(false);

  const handleSubmit = util.general.generateClickHandler(
    forms.forgotPasswordForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitForgotPasswordForm()
  );

  return (
    <CenterSingle
      content={
        <ForgotPasswordContent
          isMobile={isMobile}
          alertBars={alertBars}
          actions={actions}
          forms={forms}
          handleSubmit={handleSubmit}
          submitting={submitting}
          showErrors={errors}
          themeValues={themeValues}
        />
      }
    />
  );
};

export default withWindowSize(
  themeComponent(ForgotPassword, "ProfileApp", fallbackValues)
);
