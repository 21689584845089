import React, { useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../templates.theme";
import { themeComponent } from "../../../util/themeUtils";
import withWindowResize from "../../withWindowSize";
import { Box, Center, Cover, Sidebar, Stack } from "../../atoms/layouts";
import { COOL_GREY_05 } from "../../../constants/colors";

const SidebarStackContent = ({
  header,
  footer,
  subHeader,
  hideMobileSubHeader = true,
  sidebarWrapperPadding = "0",
  sidebarWrapperMaxWidth = "auto",
  sidebarContent,
  mainContent,
  sidebarOnRight = false,
  sidebarTargetWidth = "10rem",
  sidebarContentGap = "1rem",
  contentStackGap = "1rem",
  mainContentMinWidth = "50%",
  sidebarVerticalCenter = false,
  themeValues
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Box
      padding="0"
      minWidth="100%"
      background={COOL_GREY_05}
      key="page-bg"
      extraStyles="flex-grow: 1;"
    >
      <Cover centerOverride={!sidebarVerticalCenter} key="page-cover">
        {header ? header : <Box padding="0" key="header-box" />}
        <Box padding="0" minWidth="100%" key="content-box" role="main">
          {subHeader && !(isMobile && hideMobileSubHeader) ? (
            subHeader
          ) : (
            <Fragment />
          )}
          <Box padding={sidebarWrapperPadding} key="content-wrapper">
            <Center maxWidth={sidebarWrapperMaxWidth} key="content-center">
              <Sidebar
                width={sidebarTargetWidth}
                childGap={sidebarContentGap}
                sidebarOnRight={sidebarOnRight}
                contentMinWidth={mainContentMinWidth}
                key="content-sidebar"
              >
                {sidebarOnRight ? (
                  <Fragment key="sidebar-fragment" />
                ) : (
                  sidebarContent
                )}
                <Stack key="content-stack" childGap={contentStackGap}>
                  {mainContent}
                </Stack>
                {sidebarOnRight ? (
                  sidebarContent
                ) : (
                  <Fragment key="sidebar-fragment" />
                )}
              </Sidebar>
            </Center>
          </Box>
        </Box>
        {footer ? footer : <Box padding="0" key="footer-box" />}
      </Cover>
    </Box>
  );
};

export default withWindowResize(
  themeComponent(SidebarStackContent, "Global", fallbackValues)
);
