import GetCMSContentQuery from "./GetCMSContentv2.graphql";
import GetRelatedContentQuery from "./GetRelatedContent.graphql";
import GetSearchResultsQuery from "./GetSearchResults.graphql";
import GetCMSPreviewContentQuery from "./GetCMSPreviewContent.graphql";
import GetActionPageDirectory from "./GetActionPageDirectory.graphql";
import GetSectionPageDirectory from "./GetSectionPageDirectory.graphql";
import GetSubjectPageDirectory from "./GetSubjectPageDirectory.graphql";

import { createQuery } from "../../../../../util/graphql";

export const getCMSContent = ({ endpoint, clientSlug, pageSlug }) =>
  createQuery(endpoint, clientSlug)(GetCMSContentQuery, { slug: pageSlug });

export const getRelatedContent = ({ endpoint, clientSlug, search, pageSlug }) =>
  createQuery(endpoint, clientSlug)(GetRelatedContentQuery, {
    search,
    pageSlug
  });

export const getSearchResults = ({ endpoint, clientSlug, search }) =>
  createQuery(endpoint, clientSlug)(GetSearchResultsQuery, {
    tagSearch: search,
    actionPageSearch: search,
    landingPageSearch: search,
    sectionPageSearch: search,
    subjectPageSearch: search,
    articleSearch: search
  });

export const getCMSPreviewContent = ({ endpoint, clientSlug, id }) =>
  createQuery(endpoint, clientSlug)(GetCMSPreviewContentQuery, { id });

export const getCMSActionPages = ({ endpoint, clientSlug }) =>
  createQuery(endpoint, clientSlug)(GetActionPageDirectory, {});

export const getCMSSectionPages = ({ endpoint, clientSlug }) =>
  createQuery(endpoint, clientSlug)(GetSectionPageDirectory, {});

export const getCMSSubjectPages = ({ endpoint, clientSlug }) =>
  createQuery(endpoint, clientSlug)(GetSubjectPageDirectory, {});
