import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  LoginForm,
  Heading,
  Paragraph,
  CenterStack,
  Box,
  Cluster,
  ButtonWithAction,
  ButtonWithLink,
  FormContainer,
  FormFooterPanel,
  withWindowSize,
  util,
  constants
} from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";
import Redirect from "../../../common/pages/redirect";

const WelcomeHeader = ({ alertBar, alertBarActions, isMobile }) => (
  <Box padding={isMobile ? "0 16px" : "0 0 16px"}>
    {alertBar.alerts.length > 0 && (
      <Box padding="16px 0">
        <AlertBar {...alertBar} {...alertBarActions} />
      </Box>
    )}
    <Heading
      variant="h1"
      color={constants.colors.CHARADE_GREY}
      weight="600"
      as="h1"
    >
      Welcome
    </Heading>
    <Paragraph
      variant="p"
      weight="600"
      color={constants.colors.CHARADE_GREY}
      as="h2"
    >
      Please log in to continue.
    </Paragraph>
  </Box>
);

const LoginFormBody = ({
  isMobile,
  forms,
  actions,
  showErrors,
  submitting,
  handleSubmit
}) => (
  <Box
    key="item2"
    padding="0"
    width={isMobile ? `auto` : `576px`}
    borderRadius="4px"
    boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
    0px 3px 8px 0px rgb(202, 206, 216)"
  >
    <FormContainer>
      <LoginForm
        {...forms.loginForm}
        {...actions.forms.loginForm}
        handleSubmit={handleSubmit}
        submitting={submitting}
        showErrors={showErrors}
        isMobile={isMobile}
        clearOnDismount
      />
      <Box
        padding={isMobile ? "0.5rem 0" : "1rem 0"}
        extraStyles={`margin: 0 -0.5rem;`}
      >
        <Cluster justify="center" align="center">
          <ButtonWithAction
            text="Log In"
            isLoading={submitting}
            variant="primary"
            action={handleSubmit}
            extraStyles={`width: 100%;`}
            dataQa="Log In"
          />
        </Cluster>
      </Box>
      <Box padding="0" extraStyles={`margin: 0 -0.5rem 0 0`}>
        <Cluster justify="flex-end" align="center">
          <ButtonWithLink
            url="/forgot-password"
            variant="smallGhost"
            text="Forgot Password?"
            extraStyles={`&:hover { text-decoration: underline; }`}
          />
        </Cluster>
      </Box>
    </FormContainer>
    <FormFooterPanel
      link="/registration"
      linkText="Don't have an account? Register here."
    />
  </Box>
);

const Login = ({
  forms,
  actions,
  alertBars,
  submitting,
  submitLoginForm,
  auth,
  handleFocusErrors
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [errors, handleErrors] = useState(false);
  const { accessToken, refreshToken } = auth;
  const handleSubmit = util.general.generateClickHandler(
    forms.loginForm,
    () => {
      handleErrors(true);
      handleFocusErrors(true);
    },
    () => submitLoginForm()
  );

  return accessToken && refreshToken ? (
    <Box minWidth="100%" extraStyles="flex-grow: 1;">
      <Redirect redirectTo="/welcome" />
    </Box>
  ) : (
    <CenterStack
      content={
        <Box padding={isMobile ? "24px 0 48px" : "32px 0 64px"}>
          <WelcomeHeader
            key="WelcomeHeader"
            isMobile={isMobile}
            alertBar={alertBars.loginAlertBar}
            alertBarActions={actions.alertBars.loginAlertBar}
          />
          <LoginFormBody
            key="LoginFormBody"
            isMobile={isMobile}
            forms={forms}
            actions={actions}
            showErrors={errors}
            submitting={submitting}
            handleSubmit={handleSubmit}
          />
        </Box>
      }
    />
  );
};
export default withWindowSize(Login);
