import React from "react";
import { fallbackValues } from "./Icons.theme";
import { themeComponent } from "../../../util/themeUtils";

const PencilIcon = ({ ariaLabel = "Edit", themeValues }) => (
  <svg
    aria-label={ariaLabel}
    width="24px"
    height="24px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.74 6.84a.885.885 0 0 1 0 1.253l-1.626 1.626-3.333-3.333 1.626-1.626a.885.885 0 0 1 1.253 0l2.08 2.08ZM4 20.5v-3.333l9.83-9.83 3.333 3.333-9.83 9.83H4Z"
      fill={themeValues.subIconColor}
    />
  </svg>
);

export default themeComponent(PencilIcon, "Icons", fallbackValues, "info");
