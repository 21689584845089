import React from "react";
import { themeComponent } from "../../../../util/themeUtils";
import { Box, Text } from "../../../atoms";
import { fallbackValues } from "./PopupMenuItem.theme";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../constants/style_constants";
import { PopupMenuItemContainer } from "./PopupMenuItem.styled";

const PopupMenuItem = ({
  id,
  closeMenuCallback,
  action,
  themeValues,
  text,
  hasIcon = false,
  isDeleteAction = false,
  icon: Icon,
  textExtraStyles,
  hoverStyles,
  activeStyles,
  extraStyles,
  ...rest
}) => {
  return (
    <PopupMenuItemContainer
      id={id}
      role="menuItem"
      text={text}
      action={() => {
        action();
        closeMenuCallback();
      }}
      variant="smallGhost"
      isDeleteAction={isDeleteAction}
      theme={themeValues}
      contentOverride
      textExtraStyles={textExtraStyles}
      hoverStyles={hoverStyles}
      extraStyles={extraStyles}
      activeStyles={`outline: none; ${activeStyles}`}
      {...rest}
    >
      <Box
        extraStyles={`
            display: flex;
            gap: 8px;
            justify-content: center;
            padding: 0;
        `}
      >
        {hasIcon && (
          <Icon
            iconFill={
              isDeleteAction
                ? themeValues.menuItemColorDelete
                : themeValues.menuItemColor
            }
          />
        )}
        {text && (
          <Text
            variant="pS"
            weight={FONT_WEIGHT_SEMIBOLD}
            fontFamily="Public Sans, sans-serif"
            color={
              isDeleteAction
                ? themeValues.menuItemColorDelete
                : themeValues.menuItemColor
            }
            extraStyles={textExtraStyles}
          >
            {text}
          </Text>
        )}
      </Box>
    </PopupMenuItemContainer>
  );
};

export default themeComponent(PopupMenuItem, "PopupMenuItem", fallbackValues);
